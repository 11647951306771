import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function inviteResearchScholar(formData: BasicFormFields, userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(urls.inviteResearchScholar(userId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useInviteResearchScholar() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;
  return useMutation(async (formData: BasicFormFields) => {
    if (userId) {
      return await inviteResearchScholar(formData, userId);
    }
  });
}
