import { create } from "zustand";
import { produce } from "immer";
import { persist } from "zustand/middleware";

export type SidebarDataStore = {
  selectedSideMenu: SidebarItem;
  selectedUserType: number | null;
  selectedUserStatusType: number | null;
  selectedFormId: number | null;
};

export const useSidebarDataStore = create<SidebarDataStore>()(
  persist(
    (set) => ({
      selectedSideMenu: { type: "global.dashboard" },
      selectedUserType: null,
      selectedUserStatusType: null,
      selectedFormId: null,
    }),
    {
      name: "sidebar-data",
      getStorage: () => sessionStorage,
    }
  )
);

export function setSelectedSideMenu(sideMenuData: SidebarItem) {
  useSidebarDataStore.setState(
    produce((state: SidebarDataStore) => {
      state.selectedSideMenu = sideMenuData;
    })
  );
}

export function setSelectedUserType(userType?: number) {
  useSidebarDataStore.setState(
    produce((state: SidebarDataStore) => {
      state.selectedUserType = userType ?? null;
    })
  );
}

export function setSelectedUserStatusType(userStatusType?: number) {
  useSidebarDataStore.setState(
    produce((state: SidebarDataStore) => {
      state.selectedUserStatusType = userStatusType ?? null;
    })
  );
}

export function setSelectedFormId(formId: number | null) {
  useSidebarDataStore.setState(
    produce((state: SidebarDataStore) => {
      state.selectedFormId = formId;
    })
  );
}

export function clearSidebarDataStore() {
  useSidebarDataStore.setState(
    produce((state: SidebarDataStore) => {
      state.selectedSideMenu = { type: "global.dashboard" };
      state.selectedUserType = null;
      state.selectedUserStatusType = null;
      state.selectedFormId = null;
    })
  );
}
