import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { Title, SecondaryButton, LoaderModal } from "components";
import { showErrorToastMessage } from "utils/toasterMessage";
import ContactForm from "features/Organization/Challenge/CreateChallenge/Contacts/ContactForm";
import { defaultPrimaryContact } from "store/useChallengeStore";
import {
  setContactsInfo,
  setContactsInfoItem,
  useCollaborationDataStore,
} from "store/useCollaborationDataStore";
import { useGetCollaborationContacts } from "features/Collaboration/api/useGetCollaborationContacts";
import { CHALLENGE_STATUS } from "utils/constants";

type ContactsProps = {
  validationErrors: Array<Record<string, string>> | null;
};

const Contacts = ({ validationErrors }: ContactsProps) => {
  const { t } = useTranslation();
  const { data: contactsDetails, isLoading: isContactsLoading } = useGetCollaborationContacts();
  const { contacts } = useCollaborationDataStore().contacts;
  const { collaborationInfo } = useCollaborationDataStore();
  const isPending = collaborationInfo.collaborationStatusId === CHALLENGE_STATUS.Pending;

  const handleAddContact = () => {
    if (contacts.length < 5) {
      const collaborationContacts = { ...defaultPrimaryContact, isPrimary: false };
      setContactsInfoItem([...contacts, collaborationContacts], "contacts");
    } else {
      showErrorToastMessage({
        message: t("organization.maximum_number_of_contacts"),
      });
    }
  };

  const handleDelete = (index: number) => {
    const filteredContacts = [...contacts.slice(0, index), ...contacts.slice(index + 1)];
    setContactsInfoItem(filteredContacts, "contacts");
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    key: keyof Contact,
    index: number
  ) => {
    const value = event?.target?.value;
    setContactsInfoItem(value, key, index);
  };

  const handleCountryCodeSelection = (value: number | string, index: number) => {
    setContactsInfoItem(value, "countryCodeId", index);
  };

  useEffect(() => {
    if (contactsDetails && contactsDetails?.contacts?.length > 0) {
      setContactsInfo(contactsDetails);
    } else {
      setContactsInfoItem([defaultPrimaryContact], "contacts");
    }
  }, [contactsDetails]);

  return (
    <div>
      <div className="flex items-center justify-between mb-6 flex-wrap pb-8 lg:pb-5 p-5 py-3 border-b border-gray-light">
        <div className="flex items-center ltr:mr-auto rtl:ml-auto">
          <SVGIcon name="contactCard" />
          <Title
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            title={t("organization.contacts")}
          />
        </div>
        <SecondaryButton
          title={"organization.add_contact"}
          handleClick={handleAddContact}
          startIcon="addMilestone"
          className=" !h-10 !min-h-0 !w-[150px] !min-w-[150px] !text-sm !px-0 font-medium bg-white hover:!text-white flex gap-2"
          disabled={isPending}
        />
      </div>
      {contacts?.map((item, index) => (
        <ContactForm
          item={item}
          index={index}
          key={index}
          onDeleteContact={handleDelete}
          validationErrors={validationErrors}
          handleInputChange={handleInputChange}
          handleCountryCodeSelection={handleCountryCodeSelection}
          isDisabled={isPending}
        />
      ))}
      {isContactsLoading ? <LoaderModal /> : null}
    </div>
  );
};

export default Contacts;
