import { useQuery } from "@tanstack/react-query";
import { useChallengeStore } from "store/useChallengeStore";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getChallengeContacts(challengeId: number) {
  try {
    if (challengeId === 0) return [];
    const tokenInfo = useUserDataStore.getState().tokenInfo;
    const token = tokenInfo?.token;
    const res = await apiClient.get(urls.challengeContacts(challengeId), {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 400) {
      throw new Error("error");
    }
    return res.data?.data.contacts as ChallengeContact[];
  } catch (error) {
    return [];
  }
}

export function useGetChallengeContacts(id?: number) {
  const challengeId = id ? id : useChallengeStore.getState().challengeInfo.id;
  return useQuery(
    ["challenge-contacts", challengeId],
    async () => {
      if (challengeId) {
        const result = await getChallengeContacts(challengeId);
        return result;
      } else return;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
