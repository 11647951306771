import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getOrganizationUserList(userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.organizationUserList(userId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (res.status === 400) {
    throw new Error("error");
  }
  return res.data?.data as OrganizationDetails | ApiError;
}

export function useGetOrganizationUserList() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;
  return useQuery(
    ["organization-user-list", userId],
    async () => {
      if (userId) {
        const result = await getOrganizationUserList(userId);
        return result;
      } else return;
    },
    {
      staleTime: Infinity,
    }
  );
}
