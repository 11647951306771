import { useTranslation } from "react-i18next";
import { InlineLoader } from "components/InlineLoader";

type NoResultsProps = {
  isLoading: boolean;
  dataLength: number;
  isFilterApplied: boolean;
};

export function NoResults({ isLoading, dataLength, isFilterApplied }: NoResultsProps) {
  const { t } = useTranslation();

  if (isLoading) {
    return <InlineLoader className="my-[85px]" />;
  }

  if (!dataLength) {
    return (
      <div
        className={` flex flex-col w-full h-auto xl:h-full items-center rounded-md justify-start bg-white mb-[45px] noData`}
      >
        <div className=" flex flex-col items-center justify-start my-[102px]">
          <p>
            {isFilterApplied
              ? `${t("global.no")} ${t("organization.results_found")}`
              : t("admin.no_data_to_show")}
          </p>
        </div>
      </div>
    );
  }

  return null;
}
