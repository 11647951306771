import { useTranslation } from "react-i18next";
import { LoaderModal } from "components";
import { useGetViewFundingInterest } from "features/Organization/api/useGetViewFundingInterest";
import { convertJSONStringAsHtml } from "utils/helpers";

type FundingInterestMessageProps = {
  challengeId: number;
  interestId: number;
};
const FundingInterestMessage = ({ challengeId, interestId }: FundingInterestMessageProps) => {
  const { t } = useTranslation();
  const { data: fundingInterest, isLoading } = useGetViewFundingInterest(challengeId, interestId);

  return (
    <>
      <div className=" pt-6">
        <p className="text-gray-109 text-base font-medium WB_BW">
          {t("organization.subject")}: {fundingInterest?.subject ?? ""}
        </p>
        <div className="mt-6 text-gray-109">
          <p
            className="Word-break dangerously-set-html max-[1025px]:mt-4 max-[1025px]:max-w-full text-sm editorText"
            dangerouslySetInnerHTML={{
              __html: convertJSONStringAsHtml(fundingInterest?.message ?? ""),
            }}
          />
        </div>
      </div>
      {isLoading ? <LoaderModal /> : null}
    </>
  );
};

export default FundingInterestMessage;
