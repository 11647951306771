import { useTranslation } from "react-i18next";
import { DatePickerField, TextArea, DividerWithText, PrimaryButton, InputField } from "components";
import { showConfirmationModal } from "utils/confirmationModals";
import { CHALLENGE_STATUS, DETAIL_DESCRIPTION_MAX_CHAR_LENGTH, EMAIL_MAX_CHAR_LENGTH } from "utils/constants";
import { useChallengeStore, setChallengeMilestone, removeMilestone } from "store/useChallengeStore";

type MileStoneFormProps = {
  index: number;
  validationErrors: Array<Record<string, string>> | null;
};

const AddMileStoneForm = ({ index, validationErrors }: MileStoneFormProps) => {
  const { t } = useTranslation();
  const { milestones, challengeInfo } = useChallengeStore();
  const isPending = challengeInfo?.challengeStatusId === CHALLENGE_STATUS.Pending
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    key: keyof ChallengeMileStone
  ) => {
    const value = event?.target?.value;
    setChallengeMilestone(value, key, index);
  };

  const handleDateInputChange = (key: keyof ChallengeMileStone) => (date: string) => {
    setChallengeMilestone(date, key, index);
  };

  const handleDeleteMilestone = async () => {
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "organization.confirm_delete_milestone",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      modalIcon: "confirmDelete",
      isAlert: true,
    });
    if (!result) {
      return;
    }
    removeMilestone(index);
  };

  return (
    <div className="px-4 lg:px-5 pt-0 pb-6">
      <DividerWithText text={`${t("organization.milestone")} ${index + 1}`} />

      <div className="flex w-full gap-3 mt-6">
        <div className="w-full">
          <DatePickerField
            labelText={t("global.start_date")}
            selectedDate={milestones[index].startDate}
            setSelectedDate={handleDateInputChange("startDate")}
            errorMessage={validationErrors?.[index]?.startDate}
            showFutureDate={true}
            isDisabled={isPending}
            isRequired
            maxDate={milestones[index].endDate}
          />
        </div>
        <div className="w-full">
          <DatePickerField
            labelText={t("global.end_date")}
            selectedDate={milestones[index].endDate}
            setSelectedDate={handleDateInputChange("endDate")}
            errorMessage={validationErrors?.[index]?.endDate}
            showFutureDate={true}
            isDisabled={isPending}
            isRequired
            minDate={milestones[index].startDate}
          />
        </div>
      </div>
      <div className="mt-[22px]">
        <InputField
          value={milestones[index].milestone}
          hintText={t("organization.milestone_title_placeholder")}
          labelText={""}
          handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleInputChange(event, "milestone");
          }}
          fieldName="title"
          errorMessage={validationErrors?.[index]?.milestone}
          maxCharLength={EMAIL_MAX_CHAR_LENGTH}
          isDisabled={isPending}
        />
      </div>
      <div className="mt-[22px]">
        <TextArea
          textContent={milestones[index].description}
          handleChange={(event) => handleInputChange(event, "description")}
          labelText={t("organization.milestone_description")}
          hintText={t("organization.milestone_description_placeholder")}
          fieldName="summary"
          maxCharLength={DETAIL_DESCRIPTION_MAX_CHAR_LENGTH}
          errorMessage={validationErrors?.[index]?.description}
          isDisabled={isPending}
          isRequired
        />
      </div>

      <div className="flex justify-center items-center mt-6">
        <PrimaryButton
          title={"organization.remove_milestone"}
          handleClick={handleDeleteMilestone}
          startIcon="trashV1"
          className=" !h-10 !min-h-0 !min-w-[150px] !rounded-sm !mb-8 !text-xs !px-3 font-medium bg-white !text-red-dark !border-red-dark mx-auto hover:!bg-red-dark/10 hover:!border-red-dark/30"
          disabled={isPending}
        />
      </div>
    </div>
  );
};
export default AddMileStoneForm;
