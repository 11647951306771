import { memo } from "react";
import DefaultCoverImage from "assets/images/challengeDefault.png";
import { DETAIL_DESCRIPTION_MAX_CHAR_LENGTH } from "utils/constants";
import { convertJSONStringAsHtml, getPlainText } from "utils/helpers";

type SliderCardProps = {
  challengeId: number;
  coverImage: string | null;
  title: string;
  description: string;
  researchArea: string;
  handleClick: (challengeId: number) => void;
};

const SliderCard = ({
  challengeId,
  coverImage,
  title,
  description,
  researchArea,
  handleClick,
}: SliderCardProps) => {
  return (
    <div
      className="bg-white rounded-md slider-card-main-container  h-[386px] overflow-hidden pb-[20px] mt-[15px] mb-[15px] max-[1023px]:w-[297px] cursor-pointer"
      onClick={() => {
        handleClick(challengeId);
      }}
    >
      <div className="w-full h-[160px] overflow-hidden rounded-md">
        <img
          src={coverImage ? coverImage : DefaultCoverImage}
          alt="case-study-image"
          className="!h-40 w-full rounded-md object-cover"
        />
      </div>
      <div className="pt-5 pl-[10px] pr-[10px] w-full  px-[10px]">
        {researchArea ? (
          <div className=" w-full flex">
            <span className=" text-sm font-medium leading-5 tracking-[-0.1px] rounded-[5px] px-3 py-1 w-fit rtl:ml-auto bg-skin-primaryLight text-skin-primaryMedium truncate dirRTL_LTR">
              {researchArea}
            </span>
          </div>
        ) : null}
        {title ? (
          <h2 className="text-skin-textBase text-[17px] font-semibold leading-[23px] mt-3 mb-2 max-h-[46px] w-full card-slider-title">
            {title}
          </h2>
        ) : null}
        {description ? (
          <p
            className="text-skin-textMutedDark text-[15px] font-normal leading-6 card-slider-content WB_BW line-clamp-3 max-h-[116px] overflow-hidden"
            dangerouslySetInnerHTML={{
              __html:
                getPlainText(description) > DETAIL_DESCRIPTION_MAX_CHAR_LENGTH
                  ? convertJSONStringAsHtml(description).slice(
                      0,
                      DETAIL_DESCRIPTION_MAX_CHAR_LENGTH
                    )
                  : convertJSONStringAsHtml(description),
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export const MemoizedSliderCard = memo(SliderCard);
