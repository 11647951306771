import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function uploadFile(eventId: number, file: File, isCoverImage: boolean) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("isCoverImage", isCoverImage ? "true" : "false");
  const response = await apiClient.post(urls.eventFileUpload(eventId), formData, {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
  });

  return response?.data?.data as FileDetailsItem | ApiError;
}

export function useFileUpload() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;

  return useMutation(
    async ({
      file,
      isCoverImage,
      eventId,
    }: {
      file: File;
      isCoverImage: boolean;
      eventId: number;
    }) => {
      if (userId) {
        const result = await uploadFile(eventId, file, isCoverImage);
        return result;
      }
    }
  );
}
