import React from "react";
import { useTranslation } from "react-i18next";

type TitleProps = {
  title: string;
  children?: React.ReactNode;
  className?: string;
};

export function TradeMark({ title, children, className }: TitleProps) {
  const { t } = useTranslation();

  return (
    <div>
      <h3 className={className}>{t(title)}<span className=" relative -top-[5px] text-[80%]  !font-normal !pl-[5px]">™</span></h3>
      {children}
    </div>
  );
}
