import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
const lang = navigator.language;

i18n
  // Enables the i18next backend
  .use(Backend)
  // Enable automatic language detection
  .use(LanguageDetector)
  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    // Standard language used
    fallbackLng: lang === "ar" ? "ar" : "en",
    debug: true,
    // Detects and caches a cookie from the language provided
    detection: {
      order: ["queryString", "cookie"],
      cache: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

// Set language change listener to update localStorage
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("selectedLanguage", lng);
});

// Get the stored language or fallback to the default language
const storedLanguage = localStorage.getItem("selectedLanguage");
const initialLanguage = storedLanguage || i18n.options.fallbackLng;
i18n.changeLanguage(initialLanguage);

export default i18n;
