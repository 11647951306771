import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { Divider } from "components";

type ReplyOptionsProps = {
  handleReply: () => void;
  handlePrivateReply: () => void;
  isOpen: boolean;
};

export default function ReplyOptions({
  handleReply,
  handlePrivateReply,
  isOpen,
}: ReplyOptionsProps) {
  const { t } = useTranslation();

  return (
    <div
      className={`${isOpen ? "" : "hidden"
        } absolute min-w-[170px] w-full h-[90px] bg-white top-auto bottom-full mb-[2px] ltr:right-0 rtl:left-0 text-black rounded-md shadow-400`}
    >
      <div
        className="flex gap-[10px] cursor-pointer px-[18px] py-3 hover:bg-gradient-blueDark hover:text-white rounded-md"
        onClick={handleReply}
      >
        <SVGIcon name="replies" />
        <span className="font-normal text-[13px] ">{t("forum.reply_here")}</span>
      </div>
      <Divider className=" text-[#E8F1F6] px-[18px]" />
      <div
        className="flex gap-[10px] cursor-pointer px-[18px] py-3 hover:bg-gradient-blueDark hover:text-white rounded-md"
        onClick={handlePrivateReply}
      >
        <SVGIcon name="privateReply" />
        <span className="font-normal text-[13px] ">{t("forum.reply_privately")}</span>
      </div>
    </div>
  );
}
