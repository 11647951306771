import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function deleteFile(challengeId: number, fileDetails: FileDetailsItem) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(urls.forumFileUpload(challengeId), fileDetails, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response?.data as ApiStatus | ApiError;
}

export function useDeleteFile() {
  return useMutation(
    async ({ challengeId, fileDetails }: { challengeId: number; fileDetails: FileDetailsItem }) => {
      const result = await deleteFile(challengeId, fileDetails);
      return result;
    }
  );
}
