import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SVGIcon from "assets/Icons";
import {
  InputField,
  SingleSelect,
  Chip,
  Title,
  TextButton,
  DatePickerField,
  TextArea,
  ProfilePicture,
  MultiSelect,
  FileUpload,
  FileUploadList,
  LoaderModal,
  Label,
} from "components";
import { useFileUpload } from "features/ResearchScholar/api/useFileUpload";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { useDeleteFileUpload } from "features/ResearchScholar/api/useDeleteProfileFileUpload";
import { useGetProfileDetails } from "features/ResearchScholar/api/useGetProfileDetails";
import {
  ADDRESS_MAX_CHAR_LENGTH,
  DEFAULT_MAX_CHAR_LENGTH,
  MAX_FILE_SIZE,
  PAGE_LIMIT,
  PROFILE_DESCRIPTION_MAX_CHAR_LENGTH,
  USER_STATUS,
  INITIAL_SEARCH_PARAMS,
} from "utils/constants";
import { showProfilePicUploadModal, showConfirmationModal } from "utils/confirmationModals";
import { phoneMaxLimitChecker, setMasterDataValue } from "utils/helpers";
import {
  ToasterIconType,
  showErrorToastMessage,
  showSuccessToastMessage,
} from "utils/toasterMessage";
import { useMasterDataStore } from "store/useMasterDataStore";
import {
  setPersonalInfoItem,
  setPersonalInfo,
  useScholarProfileDataStore,
} from "store/useScholarProfileDataStore";

type PersonalDetailsFormProps = {
  validationErrors: Record<string, string> | null;
  setIsUploading: (status: boolean) => void;
};

export default function PersonalDetails({
  validationErrors,
  setIsUploading,
}: PersonalDetailsFormProps) {
  const { t, i18n } = useTranslation();
  const { researchScholarId } = useParams();

  const { mutateAsync: fileUpload, isLoading: isFileUploading } = useFileUpload();
  const { data: personalData, isLoading: isPersonalDataLoading } = useGetProfileDetails(
    researchScholarId ?? ""
  );

  const { countryCode, title } = useMasterDataStore();
  const { mutateAsync: deleteFileUpload, isLoading: isDeleteUploadLoading } = useDeleteFileUpload();

  const { personInfo } = useScholarProfileDataStore();

  const { masterData } = useMasterDataStore();

  const isReadOnly = personInfo?.profileStatusId === USER_STATUS.PendingApproval;

  const countryCodeOptions = useMemo(() => {
    return countryCode?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [countryCode, i18n.language]);

  useEffect(() => {
    if (personalData) {
      setPersonalInfo(personalData);
    }
  }, [personalData, i18n.language]);

  useEffect(() => {
    searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
  }, [personInfo]);

  const handleInputChange = (key: keyof PersonalDetails) => (e: any) => {
    const value = e.target.value;
    setPersonalInfoItem(value, key);
  };

  const handleDateInputChange = (key: keyof PersonalDetails) => (date: string) => {
    setPersonalInfoItem(date, key);
  };

  const handleSelectChange = (key: keyof PersonalDetails) => (value: string | number) => {
    setPersonalInfoItem(value, key);
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target?.files;
    if (selectedFiles && selectedFiles?.length > 0) {
      if (selectedFiles?.[0].size > MAX_FILE_SIZE) {
        event.target.value = "";
        return showErrorToastMessage({
          message: t("research_scholar.uploaded_files_size_error_message"),
        });
      }
      const fileName = selectedFiles?.[0].name;
      const isFileExist = personInfo?.fileDetails?.find((item) => item.name === fileName);
      if (isFileExist) {
        showErrorToastMessage({
          message: t("research_scholar.multiple_same_name_file_error_message"),
        });
        event.target.value = "";
        return;
      }
      try {
        setIsUploading(true);
        const fileDetails = personInfo?.fileDetails;
        setPersonalInfoItem(
          fileDetails ? [...fileDetails, selectedFiles[0]] : [selectedFiles[0]],
          "fileDetails"
        );
        const result = await fileUpload(selectedFiles);
        if (result) {
          setPersonalInfoItem(
            fileDetails ? [...fileDetails, ...result] : [...result],
            "fileDetails"
          );

          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 1000);
        }
      } catch (error) {
        const fileDetails = personInfo?.fileDetails;
        setPersonalInfoItem(fileDetails ? [...fileDetails] : [], "fileDetails");
        showErrorToastMessage({
          iconType: ToasterIconType.ALERT,
          message: t("global.something_went_wrong"),
        });
      } finally {
        setIsUploading(false);
        event.target.value = "";
      }
    }
  };

  const countryOptions = useMemo(() => {
    return masterData?.country?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [masterData, i18n.language]);

  const nationalityOptions = useMemo(() => {
    return masterData?.nationality?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [masterData, i18n.language]);

  const handleSelect = (id: number | string, key: keyof PersonalDetails) => {
    setPersonalInfoItem(id, key);
  };

  const handleRemove = async () => {
    if (personInfo && personInfo?.profileActualImage) {
      setPersonalInfoItem(null, "profileActualImage");
    }
  };

  const handleProfileClick = async () => {
    await showProfilePicUploadModal({
      setProfileUrl: (url: string) => {
        setPersonalInfoItem(url, "profileActualImage");
        setPersonalInfoItem("", "profileImage");
      },
      url: personInfo?.profileActualImage ?? "",
      handleRemove,
    });
  };

  const handleFileRemove = async (
    event: React.MouseEvent<HTMLButtonElement>,
    item: FileDetails
  ) => {
    event.stopPropagation();
    event.preventDefault();
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.deletion_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      if (item.id) {
        const formData = {
          id: item.id,
          name: item.name,
          fileUrl: item.fileUrl,
        };
        try {
          const response = await deleteFileUpload(formData);
          const files = personInfo?.fileDetails;
          const newFiles = files?.filter((file) => file.fileUrl !== item?.fileUrl);
          if (newFiles) {
            setPersonalInfoItem(newFiles, "fileDetails");
          }
          showSuccessToastMessage({
            message: response.message,
          });
        } catch (error: ApiError) {
          showErrorToastMessage({
            iconType: ToasterIconType.ALERT,
            message:
              error.response.data.status === 400
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
        }
      } else {
        const files = personInfo?.fileDetails;
        const newFiles = files?.filter((file) => file.fileUrl !== item?.fileUrl);
        if (newFiles) {
          setPersonalInfoItem(newFiles, "fileDetails");
        }
        showSuccessToastMessage({
          message: t("global.document_deleted_successfully"),
        });
      }
    }
  };

  const handleRemoveResearchArea = (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    if (personInfo?.researchArea) {
      const tempOptions = personInfo?.researchArea ? structuredClone(personInfo?.researchArea) : [];
      tempOptions.splice(index, 1);
      setPersonalInfoItem(tempOptions, "researchArea");
    }
  };

  const disableUpload = () => {
    if (isReadOnly) {
      return true;
    } else if (personInfo?.fileDetails && personInfo?.fileDetails?.length >= 5) {
      return true;
    } else if (isFileUploading) {
      return true;
    } else {
      return false;
    }
  };

  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);

  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  const titleOptions = useMemo(() => {
    return title?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [title, i18n.language]);

  const genderOptions = useMemo(() => {
    return masterData?.gender?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [masterData, i18n.language]);

  return (
    <>
      <div className=" p-4 lg:p-5 flex items-center border-b border-gray-light">
        <SVGIcon name="personalDetails" />
        <Title
          className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
          title={t("research_scholar.personal_details")}
        />
      </div>

      <form className="space-y-6 px-4 lg:px-5 pt-6 pb-8 ">
        <div className=" flex flex-col bg-gray-light/60 py-[30px] justify-center rounded items-center mb-[34px]">
          <ProfilePicture
            url={personInfo?.profileActualImage ? personInfo.profileActualImage : ""}
            key={
              personInfo?.profileActualImage
                ? `${personInfo.profileActualImage}?${Math.random()}`
                : "profile"
            }
            handleClick={handleProfileClick}
            disabled={isReadOnly}
          />
          <TextButton
            title={personInfo?.profileActualImage ? "global.change_image" : "global.upload_image"}
            className="text-primary-medium mt-6 text-sm"
            handleClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleProfileClick();
            }}
            isDisabled={isReadOnly}
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-7 gap-4 w-full">
          <div className="">
            <SingleSelect
              options={titleOptions ?? []}
              labelText={t("global.title")}
              hintText={t("global.select")}
              handleChange={handleSelectChange("titleId")}
              isRequired
              selectOption={personInfo?.titleId}
              errorMessage={validationErrors?.titleId}
              isDisabled={isReadOnly}
            />
          </div>
          <div className="lg:col-span-3 xl:col-span-2">
            <InputField
              hintText={t("global.enter_first_name")}
              labelText={t("global.first_name")}
              handleInputChange={handleInputChange("firstName")}
              fieldName="firstName"
              isRequired
              value={personInfo?.firstName}
              errorMessage={validationErrors?.firstName}
              maxCharLength={DEFAULT_MAX_CHAR_LENGTH}
              isDisabled={isReadOnly}
            />
          </div>
          <div className="lg:col-span-2">
            <InputField
              hintText={t("global.enter_middle_name")}
              labelText={t("global.middle_name")}
              handleInputChange={handleInputChange("middleName")}
              fieldName="middleName"
              value={personInfo?.middleName}
              maxCharLength={DEFAULT_MAX_CHAR_LENGTH}
              isDisabled={isReadOnly}
            />
          </div>
          <div className="lg:col-span-2">
            <InputField
              hintText={t("global.enter_last_name")}
              labelText={t("global.last_name")}
              handleInputChange={handleInputChange("lastName")}
              fieldName="lastName"
              isRequired
              value={personInfo?.lastName}
              errorMessage={validationErrors?.lastName}
              maxCharLength={DEFAULT_MAX_CHAR_LENGTH}
              isDisabled={isReadOnly}
            />
          </div>
        </div>
        <TextArea
          textContent={personInfo?.about}
          handleChange={handleInputChange("about")}
          labelText={t("global.about")}
          hintText={t("global.tell_us_more_about_you")}
          fieldName="about"
          isRequired
          maxCharLength={PROFILE_DESCRIPTION_MAX_CHAR_LENGTH}
          errorMessage={validationErrors?.about}
          isDisabled={isReadOnly}
        />
        <div className="relative w-full ">
          <MultiSelect
            labelText={t("global.research_area")}
            hintText={t("global.search")}
            setSelectedOptions={(options: Array<OptionItem> | null) => {
              setPersonalInfoItem(options, "researchArea");
              searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
            }}
            selectedOptions={personInfo?.researchArea ?? []}
            isDisabled={isReadOnly}
            addNew
            loadOptions={loadResearchAreaOptions}
            errorToastMessage={"global.create_research_area_min_error_message"}
            createLabel={"research_scholar.new_research_area"}
            resetKey={Math.random().toString()}
            onMenuOpen={() => {
              searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
            }}
          />
        </div>
        <div>
          {personInfo && personInfo?.researchArea && personInfo?.researchArea?.length > 0 ? (
            <div className=" flex  flex-wrap">
              {personInfo?.researchArea.map((item: OptionItem, i: number) => (
                <Chip
                  key={item.id ? item.id + "" : i + ""}
                  text={i18n.language === "ar" ? item.name.ar : item.name.en}
                  handleClose={(event: React.MouseEvent<HTMLElement>) => {
                    handleRemoveResearchArea(event, i);
                    searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
                  }}
                  disabled={isReadOnly}
                />
              ))}
            </div>
          ) : null}
        </div>
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4 gap-y-6">
          <SingleSelect
            options={genderOptions ?? []}
            labelText={t("global.gender")}
            hintText={t("global.select")}
            isRequired
            handleChange={handleSelectChange("genderId")}
            errorMessage={validationErrors?.genderId}
            selectOption={personInfo?.genderId}
            isDisabled={isReadOnly}
          />
          <div className="datePicker  relative">
            <DatePickerField
              labelText={t("global.date_of_birth")}
              isRequired
              selectedDate={personInfo?.dateOfBirth || null}
              setSelectedDate={handleDateInputChange("dateOfBirth")}
              errorMessage={validationErrors?.dateOfBirth}
              showFutureDate={false}
              isDisabled={isReadOnly}
            />
          </div>
          <InputField
            hintText={t("global.enter_email_address")}
            labelText={t("global.email_address")}
            handleInputChange={handleInputChange("email")}
            fieldName="email"
            isRequired
            value={personInfo?.email}
            errorMessage={validationErrors?.email}
            isDisabled
          />
          <InputField
            hintText={
              personInfo?.isAdvisor ? t("research_scholar.advisor_institute_placeholder") : ""
            }
            labelText={t("research_scholar.research_institute_name")}
            handleInputChange={handleInputChange("researchInstituteName")}
            fieldName="researchInstituteName"
            value={personInfo?.researchInstituteName}
            errorMessage={validationErrors?.email}
            isDisabled
            isRequired
          />
          <SingleSelect
            options={countryCodeOptions || []}
            labelText={t("global.country_code")}
            hintText={t("global.select")}
            handleChange={(id) => handleSelect(id, "countryCodeId")}
            isRequired
            errorMessage={validationErrors?.countryCode}
            selectOption={personInfo?.countryCodeId}
            isDisabled={isReadOnly}
          />
          <InputField
            value={personInfo?.contactNumber}
            hintText={t("global.enter_contact_number")}
            labelText={t("global.contact_number")}
            handleInputChange={handleInputChange("contactNumber")}
            fieldName="contactNumber"
            isRequired
            errorMessage={validationErrors?.contactNumber}
            maxCharLength={phoneMaxLimitChecker(personInfo?.contactNumber ?? "")}
            isDisabled={isReadOnly}
          />
          <SingleSelect
            options={countryOptions || []}
            labelText={t("global.country")}
            hintText={t("global.select")}
            isRequired
            handleChange={(id) => handleSelect(id, "countryId")}
            errorMessage={validationErrors?.countryId}
            selectOption={personInfo?.countryId}
            isDisabled={isReadOnly}
          />

          <SingleSelect
            options={nationalityOptions || []}
            labelText={t("global.nationality")}
            hintText={t("global.select")}
            isRequired
            handleChange={(id) => handleSelect(id, "nationalityId")}
            errorMessage={validationErrors?.nationalityId}
            selectOption={personInfo?.nationalityId}
            isDisabled={isReadOnly}
          />
        </div>
        <TextArea
          textContent={personInfo?.address}
          handleChange={handleInputChange("address")}
          labelText={t("global.address")}
          hintText={t("global.enter_address")}
          fieldName="address"
          maxCharLength={ADDRESS_MAX_CHAR_LENGTH}
          isDisabled={isReadOnly}
        />
        <div className="fileUpload w-full flex flex-col">
          <Label
            className="label p-0 pb-[10px] text-primary-light text-sm"
            text={t("global.other_personal_artifacts")}
            tooltipContent="research_scholar.you_can_upload_a_maximum_of_5_documents"
          />
          <FileUpload
            handleFileUpload={handleFileUpload}
            icon={<SVGIcon name={"upload"} />}
            disabled={disableUpload()}
          />
          {personInfo?.fileDetails?.length ? (
            <FileUploadList
              fileList={personInfo?.fileDetails}
              handleRemove={handleFileRemove}
              disabled={isReadOnly}
              isFileUploading={isFileUploading}
            />
          ) : null}
        </div>
      </form>
      {isDeleteUploadLoading || isPersonalDataLoading ? <LoaderModal /> : null}
    </>
  );
}
