import { useTranslation } from "react-i18next";
import {
  CreatableSingleSelect,
  DatePickerField,
  InputField,
  RadioButton,
  TextArea,
  Title,
} from "components";
import CoverImageArea from "features/Organization/Challenge/CreateChallenge/CoverImageArea";
import {
  EMAIL_MAX_CHAR_LENGTH,
  GENERAL_DATA_MAX_LENGTH,
  SUMMARY_MAX_CHAR_LENGTH,
  INITIAL_SEARCH_PARAMS,
  PAGE_LIMIT,
  CHALLENGE_TYPES,
} from "utils/constants";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";

type AboutFormProps = {
  data: CollaborationAbout;
  validationErrors: Record<string, string> | null;
  searchParamsRef: React.MutableRefObject<{
    pageLimit: number;
    pageNumber: number;
    searchKeyword: string;
  }>;
  isEditDisabled: boolean;
  eventHandlers: {
    handleProfileClick: () => void;
    handleInputChange: (
      event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
      key: keyof ChallengeBasic
    ) => void;
    handleDeleteClick: () => void;
    handleRadioButtonSelection: (value: boolean) => void;
    handleDateInputChange: (key: keyof ChallengeBasic) => (date: string) => void;
    handleTargetIndustrySelection: (value: OptionItem, key: keyof ChallengeBasic) => void;
  };
};

export default function AboutForm({
  data,
  validationErrors,
  searchParamsRef,
  isEditDisabled,
  eventHandlers,
}: AboutFormProps) {
  const { t } = useTranslation();

  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  return (
    <div className="px-5 bg-white">
      <div className=" flex flex-col pt-6 justify-center rounded items-center mb-[34px] ">
        <Title
          className="  text-primary-light text-sm font-normal text-left w-full pb-2"
          title={t("organization.cover_image")}
        />
        <CoverImageArea
          errorMessage={""}
          url={data?.coverImage}
          handleClick={eventHandlers.handleProfileClick}
          handleDeleteClick={eventHandlers.handleDeleteClick}
          isDisabled={isEditDisabled}
        />
        <div className="mt-[30px] w-full h-full">
          <InputField
            hintText={t("research_institute.enter_collaboration_title")}
            labelText={t("global.title")}
            value={data.title}
            handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              eventHandlers.handleInputChange(event, "title");
            }}
            fieldName="title"
            isRequired
            errorMessage={validationErrors?.title}
            maxCharLength={GENERAL_DATA_MAX_LENGTH}
            isDisabled={isEditDisabled}
          />
        </div>
        <div className="flex items-start flex-col mt-[22px] w-full justify-center">
          <span className="label-text text-primary-light text-sm font-normal leading-4 whitespace-nowrap">
            {t("organization.challenge_type")}
          </span>
          <div className=" mt-[11px] flex items-center space-x-6 rtl:space-x-reverse ">
            <RadioButton
              className="mr-10 p-2 text-black"
              label={t("organization.public")}
              value={CHALLENGE_TYPES.Public}
              checked={!data.isPrivate}
              onChange={() => eventHandlers.handleRadioButtonSelection(false)}
              disabled={isEditDisabled}
            />
            <RadioButton
              className="mr-10 p-2 text-black"
              label={t("organization.private")}
              value={CHALLENGE_TYPES.Private}
              checked={data.isPrivate}
              onChange={() => eventHandlers.handleRadioButtonSelection(true)}
              disabled={isEditDisabled}
            />
          </div>
        </div>

        <div className="flex w-full gap-3 mt-[22px] targetIndustry">
          <CreatableSingleSelect
            className="text-sm font-normal flex-grow w-full"
            labelText={t("organization.target_industry")}
            hintText={t("organization.target_industry_placeholder")}
            selectedOptions={Array(data.targetIndustry) as OptionItem[]}
            setSelectedOptions={function (option: OptionItem | null): void {
              if (option) {
                eventHandlers.handleTargetIndustrySelection(option, "targetIndustry");
              }
              searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
            }}
            addNew
            errorToastMessage="global.create_target_industry_min_character"
            createLabel={"global.new_target_industry"}
            loadOptions={loadResearchAreaOptions}
            errorMessage={validationErrors?.targetIndustry}
            resetKey={Math.random().toString()}
            onMenuOpen={() => {
              searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
            }}
            isDisabled={isEditDisabled}
          />

          <InputField
            hintText={t("research_institute.enter_collaboration_location")}
            labelText={t("organization.location")}
            handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              eventHandlers.handleInputChange(event, "location");
            }}
            fieldName="location"
            isRequired
            errorMessage={validationErrors?.location}
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
            className=" w-full !h-12 flex-grow-0"
            value={data.location}
            isDisabled={isEditDisabled}
          />
        </div>
        <div className="flex w-full gap-3 mt-[22px]">
          <div className="w-full flex-grow">
            <DatePickerField
              labelText={t("global.start_date")}
              isRequired
              selectedDate={data.startDate}
              setSelectedDate={eventHandlers.handleDateInputChange("startDate")}
              errorMessage={validationErrors?.startDate}
              showFutureDate={true}
              isDisabled={isEditDisabled}
              maxDate={data.endDate}
            />
          </div>
          <div className="w-full flex-grow">
            <DatePickerField
              labelText={t("global.end_date")}
              isRequired
              selectedDate={data.endDate}
              setSelectedDate={eventHandlers.handleDateInputChange("endDate")}
              errorMessage={validationErrors?.endDate}
              showFutureDate={true}
              isDisabled={isEditDisabled}
              minDate={data?.startDate}
            />
          </div>
        </div>
        <div className="mt-[22px] w-full">
          <TextArea
            textContent={data?.summary}
            handleChange={(event) => eventHandlers.handleInputChange(event, "summary")}
            labelText={t("organization.challenge_summary")}
            hintText={t("research_institute.enter_collaboration_summary")}
            fieldName="summary"
            isRequired
            maxCharLength={SUMMARY_MAX_CHAR_LENGTH}
            errorMessage={validationErrors?.summary}
            isDisabled={isEditDisabled}
          />
        </div>
      </div>
    </div>
  );
}
