import React, { useEffect, useState, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useGetMasterData } from "hooks/useGetMasterData";
import { InputField, Divider, PrimaryButton, LoaderModal, Header } from "components";
import { useMasterSystemConfiguration } from "features/Admin/api/useMasterSystemConfiguration";
import { EMAIL_MAX_CHAR_LENGTH } from "utils/constants";
import { showErrorToastMessage, ToasterIconType, showInfoToastMessage } from "utils/toasterMessage";
import { systemConfigurationValidation } from "utils/validation/masterSystemConfigurationValidation";
import { setMasterData, useMasterDataStore } from "store/useMasterDataStore";
import { setSelectedSideMenu } from "store/useSidebarStore";

export default function Settings() {
  const { t } = useTranslation();
  const { mutateAsync: masterSystemConfiguration, isLoading: isSystemConfigLoading } =
    useMasterSystemConfiguration();
  const { data: masterInfo, isLoading: isMasterDataLoading } = useGetMasterData();
  const { masterData } = useMasterDataStore();
  const systemConfigurationInitState: SystemConfigurationFields = {
    organizationUserLimit: masterData?.systemConfiguration?.organizationUserLimit ?? 5,
    supportEmail: masterData?.systemConfiguration?.supportEmail ?? "",
  };
  const [systemConfigFormData, setSystemConfigFormData] = useState<SystemConfigurationFields>(
    systemConfigurationInitState
  );
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const value = event.target.value;
    setSystemConfigFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldName === "organizationUserLimit" ? parseInt(value) : value,
    }));
  };
  const prepareFormData = () => {
    const data: SystemConfigurationFields = {
      organizationUserLimit: systemConfigFormData?.organizationUserLimit,
      supportEmail: systemConfigFormData?.supportEmail?.trim(),
    };
    return data;
  };
  const isDataChanged = () => {
    return (
      systemConfigFormData.organizationUserLimit !==
      systemConfigurationInitState.organizationUserLimit ||
      systemConfigFormData.supportEmail !== systemConfigurationInitState.supportEmail
    );
  };

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (!isDataChanged()) {
      return;
    }
    const errors = systemConfigurationValidation(systemConfigFormData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors(null);
    try {
      const formData = prepareFormData();
      const systemConfigResponse = await masterSystemConfiguration(formData);
      showInfoToastMessage({
        iconType: ToasterIconType.UPDATE,
        message: systemConfigResponse?.message,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  useEffect(() => {
    if (masterInfo) {
      setMasterData(masterInfo);
    }
  }, [masterInfo]);
  useEffect(() => {
    if (masterData === undefined && masterInfo !== undefined) {
      setMasterData(masterInfo);
    }
  }, [masterData, masterInfo]);

  const handleInputPaste = () => (e: SyntheticEvent) => {
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.settings",
      isOpen: true,
      subMenuType: "admin.master",
    });
  }, []);

  return (
    <div className={`flex  min-h-screen w-full`}>
      <Header title={"admin.master"} className="!py-[26px] !h-[90px] fixed stickyHeader" />

      <div className=" adminDetails w-full pt-[118px] pb-10items-baseline px-10 min-h-screen flex flex-col  overflow-auto">
        <div className=" flex-col bg-white w-full lg:h-full rounded-md flex mb-auto space-y-6 px-4 lg:px-5 pt-5 pb-8">
          <div className=" flex items-start flex-col">
            <div className=" flex flex-col">
              <h1 className="!justify-start text-gray-109 font-semibold  pb-2.5 text-[18px] leading-[26px]">
                {t("admin.support_email")}
                <span className=" rtl:mr-1 ltr:ml-1 text-red-200">*</span>
              </h1>
              <p className=" text-gray-109 text-sm leading-[22px] pb-2">
                {t("admin.support_email_content")}
              </p>
            </div>
            <div>
              <InputField
                hintText={t("admin.enter_support_email")}
                labelText=""
                handleInputChange={handleInputChange}
                errorMessage={validationErrors?.supportEmail}
                fieldName="supportEmail"
                value={systemConfigFormData.supportEmail}
                maxCharLength={EMAIL_MAX_CHAR_LENGTH}
                className=" min-w-[500px]"
              />
            </div>
          </div>
          <Divider className=" flex-grow-0" />
          <div className=" flex items-start flex-col mb-auto">
            <div className=" flex flex-col">
              <h1 className="!justify-start text-gray-109 font-semibold  pb-2.5 text-[18px] leading-[26px]">
                {t("admin.maximum_users")}
                <span className=" rtl:mr-1 ltr:ml-1 text-red-200">*</span>
              </h1>
              <p className="text-gray-109 text-sm leading-[22px] pb-2">
                {t("admin.maximum_users_content")}
              </p>
            </div>
            <div className="min-w-[100px]">
              <InputField
                hintText=""
                labelText=""
                inputType="number"
                value={systemConfigFormData.organizationUserLimit}
                handleInputChange={handleInputChange}
                errorMessage={validationErrors?.organizationUserLimit}
                fieldName="organizationUserLimit"
                className="text-start"
              />
            </div>
          </div>
          <PrimaryButton
            title={"global.update"}
            handleClick={onSubmit}
            className="min-w-[120px] !mt-[32px] w-[192px]"
          />
        </div>
      </div>
      {isSystemConfigLoading || isMasterDataLoading ? <LoaderModal /> : null}
    </div>
  );
}
