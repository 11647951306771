import { useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import {
  Chip,
  SecondaryButton,
  RadioButton,
  PrimaryButton,
  InputField,
  LoaderModal,
} from "components";
import { emailValidation } from "utils/validation/authValidation";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import { useChallengeEmailInvite } from "features/Organization/api/useChallengeEmailInvite";
import { EMAIL_MAX_CHAR_LENGTH, USERS } from "utils/constants";
import { useUserDataStore } from "store/useUserDataStore";

type InviteUserProps = {
  onClose: () => void;
  challengeId: number;
  isChallengeGlobal: boolean;
};

const InviteUser = ({ onClose, challengeId, isChallengeGlobal = false }: InviteUserProps) => {
  const { t } = useTranslation();
  const { userInfo } = useUserDataStore();

  const { mutateAsync: challengeEmailInvite, isLoading: isInviteLoading } =
    useChallengeEmailInvite(challengeId);

  const [selected, setSelected] = useState(USERS.ResearchScholar);
  const [enteredValue, setEnteredValue] = useState<EmailData>({ email: "" });
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();
  const [emails, setEmails] = useState<Array<string>>([]);

  const handleClose = () => {
    onClose();
  };

  const handleSendInvite = async () => {
    if (emails?.length <= 0) {
      showErrorToastMessage({
        iconType: ToasterIconType.ALERT,
        message: t("admin.at_least_one_email_required_error_message"),
      });
      return;
    }
    try {
      const result = await challengeEmailInvite({
        formData: emails,
        isFundingOrganization: selected === USERS.Funding,
      });
      showInfoToastMessage({
        iconType: ToasterIconType.MAIL,
        message: result?.message,
      });
      onClose();
    } catch (error: ApiError) {
      showErrorToastMessage({
        iconType: ToasterIconType.ALERT,
        message:
          error.response.data.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value?.trim();
    setEnteredValue({ email: value });
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      const error = emailValidation(enteredValue);
      if (error) {
        setValidationErrors(error);
        return;
      }
      setValidationErrors(null);
      if (emails?.length === 0) {
        setEmails([enteredValue.email?.trim()]);
      } else {
        const isExist = [...emails]?.find((item) => item === enteredValue?.email?.trim());
        if (!isExist) {
          const tempEmails = [...emails, enteredValue?.email?.trim()];
          setEmails([...tempEmails]);
        }
      }
      setEnteredValue({ email: "" });
    }
  };

  const handleRemove = (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    const tempEmails = [...emails];
    tempEmails?.splice(index, 1);
    setEmails(tempEmails);
  };

  return (
    <div className="max-w-[100vw] w-[100vw] min-w-[100vw]  lg:w-auto lg:max-w-[470px] mx-0 lg:mx-2  lg:min-w-[470px]  bg-white rounded-md rounded-b-none lg:rounded-b-md px-4 pb-4 pt-12 lg:pt-[50px]  lg:p-[50px] lg:pb-[50px] lg:!px-8 sm:px-[50px] sm:p-[50px] flex flex-col relative text-center animate__animated animate__zoomIn max-h-[calc(100vh-50px)] overflow-auto">
      <div className=" flex justify-center flex-col items-center">
        <SVGIcon name="bulkInvite" />
        <h3 className="mt-10 text-black font-semibold text-xl mb-1.5">
          {t("organization.invite")}
        </h3>
        <p className="font-normal text-base text-primary-text text-center mx-8">
          {t(
            !isChallengeGlobal
              ? "organization.challenge_invite_message"
              : "organization.global_challenge_invite_message"
          )}
        </p>
        <p className="mb-1.5 text-gray-200 text-base leading-normal font-normal">
          {t("organization.challenge_invite_note")}
        </p>
      </div>
      <div className="mt-8 w-full">
        <div className=" mt-[8px] mb-6 flex items-center space-x-6 rtl:space-x-reverse">
          <RadioButton
            className="mr-10 !text-sm leading-normal font-normal text-gray-200 invite-radio-buttons"
            label={t("organization.researcher_advisor")}
            value={selected}
            checked={selected === USERS.ResearchScholar}
            onChange={() => {
              setSelected(USERS.ResearchScholar);
            }}
            disabled={false}
          />
          {!isChallengeGlobal ? (
            <RadioButton
              className="mr-10  !text-sm leading-normal font-normal text-gray-200 invite-radio-buttons"
              label={t("funding_organization.funding_organization")}
              value={selected}
              checked={selected === USERS.Funding}
              onChange={() => {
                setSelected(USERS.Funding);
              }}
              disabled={false}
            />
          ) : null}
        </div>
        <InputField
          hintText={
            emails?.length >= 50
              ? t("organization.you_can_send_up_to_fifty_invites_at_once")
              : t("global.enter_email_address_and_press_enter")
          }
          labelText={t("global.email_address")}
          handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(event)
          }
          fieldName="email"
          isRequired
          className="col-span-full"
          maxCharLength={EMAIL_MAX_CHAR_LENGTH}
          isDisabled={emails?.length >= 50}
          handleKeyDown={handleEnterKeyPress}
          value={enteredValue?.email}
          errorMessage={validationErrors?.email}
        />
      </div>
      {/* tags */}
      <div className="">
        <div className="flex flex-wrap h-[80px] overflow-auto">
          {emails?.length > 0 ? (
            <div className=" mt-6  flex  flex-wrap justify-center">
              {emails?.map((item, index) => (
                <Chip
                  key={item + index}
                  text={item}
                  handleClose={(event: React.MouseEvent<HTMLElement>) => handleRemove(event, index)}
                />
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex justify-center items-center mt-[40px] gap-2">
        <div className="w-[120px]">
          <SecondaryButton title={"global.close"} handleClick={handleClose} className="w-full" />
        </div>
        <div className="w-[120px]">
          <PrimaryButton
            title={"global.send_invite"}
            handleClick={handleSendInvite}
            className="w-full"
          />
        </div>
      </div>
      {isInviteLoading ? <LoaderModal /> : null}
    </div>
  );
};

export default InviteUser;
