import Joi from "joi";
import { groupBy } from "utils/helpers";

const eventDetailsSchema = Joi.object({
  id: Joi.number().optional(),
  coverImage: Joi.string().empty("").allow(null).optional(),
  title: Joi.string().messages({ "string.empty": "required" }),
  startDate: Joi.string().isoDate().messages({
    "string.empty": "required",
    "string.isoDate": "required",
    "string.date.base": "required",
  }),
  endDate: Joi.string().isoDate().messages({
    "string.empty": "required",
    "string.isoDate": "required",
    "string.date.base": "required",
  }),
  location: Joi.string().messages({ "string.empty": "required" }),
  eventDescription: Joi.string().trim().max(10000).default(null).allow(null, "").messages({
    "string.max": "required",
  }),
  targetIndustry: Joi.object().messages({
    "object.null": "required",
    "object.base": "required",
    "object.allow": "required",
  }),
  websiteUrl: Joi.string()
    .uri()
    .optional()
    .default(null)
    .allow("", null)
    .messages({ "string.uri": "global.please_enter_a_valid_url" }),
  eventCompletionStatusId: Joi.number().optional(),
  eventStatusId: Joi.number().optional(),
  status: Joi.string().optional(),
}).options({ abortEarly: false });

export const eventDetailsValidation = (eventDetails: EventDetails) => {
  const result = eventDetailsSchema.validate(eventDetails);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
