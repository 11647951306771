import SVGIcon from "assets/Icons";

export function BackToTop({ className }: { className?: string }) {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };

  return (
    <div>
      <button
        id="to-top-button"
        onClick={goToTop}
        title="Go To Top"
        className={`w-14 h-14 flex items-center justify-center fixed z-200 bottom-10 right-10 p-3 border-0 w-13 h-13 rounded-full shadow-md border-primary-medium bg-primary-medium hover:bg-primary-700 text-white text-lg font-semibold transition-colors duration-300 ${
          className ? className : ""
        }`}
      >
        <SVGIcon name="upArrow" />
      </button>
    </div>
  );
}
