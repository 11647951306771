import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";

import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getRefreshToken() {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const refreshToken = tokenInfo?.refreshToken;
  const response = await apiClient.post(urls.refreshToken(), {
    token,
    refreshToken,
    isRefresh: true,
  });
  return response?.data?.data as TokenInfo | ApiError;
}

async function login(formData: LoginFormData) {
  const response = await apiClient.post(urls.login(), formData);
  return response?.data?.data as LoginResponseData | ApiError;
}

export function useLogin() {
  const qc = useQueryClient();
  return useMutation(async (formData: LoginFormData) => {
    const result = await login(formData);
    qc.clear();
    return result;
  });
}

async function validateEmail(formData: EmailVerificationData) {
  const response = await apiClient.post(urls.emailVerification(), formData);
  return response?.data as ApiStatus | ApiError;
}

export function useValidateEmail() {
  return useMutation(async (formData: EmailVerificationData) => {
    const result = await validateEmail(formData);
    return result;
  });
}

async function logout() {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const userInfo = useUserDataStore.getState().userInfo;
  const token = tokenInfo?.token;
  if (userInfo) {
    const formData = { username: userInfo.userName, token: token };
    const response = await apiClient.post(urls.logout(), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  }
}

export function useLogout() {
  const qc = useQueryClient();
  return useMutation(async () => {
    const result = await logout();
    qc.clear();
    return result;
  });
}

async function validateResetEmail(formData: EmailData) {
  const response = await apiClient.put(urls.resetConfirmationMail(), formData);
  return response?.data as ApiStatus | ApiError;
}

export function useValidateResetEmail() {
  return useMutation(async (formData: EmailData) => {
    const result = await validateResetEmail(formData);
    return result;
  });
}

async function resetPassword(formData: ResetPasswordData) {
  const response = await apiClient.put(urls.resetPassword(), formData);
  return response?.data as ApiStatus | ApiError;
}

export function useResetPassword() {
  return useMutation(async (formData: ResetPasswordData) => {
    const result = await resetPassword(formData);
    return result;
  });
}

async function verifyResetPasswordLink(formData: ResetPasswordData) {
  const response = await apiClient.put(urls.verifyResetPasswordLink(), formData);
  return response?.data as ApiStatus | ApiError;
}

export function useVerifyResetPasswordLink() {
  return useMutation(async (formData: ResetPasswordData) => {
    const result = await verifyResetPasswordLink(formData);
    return result;
  });
}

async function changePassword(formData: ChangePasswordData) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(urls.changePassword(), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useChangePassword() {
  return useMutation(async (formData: ChangePasswordData) => {
    const result = await changePassword(formData);
    return result;
  });
}
