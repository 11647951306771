import { useMutation } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function organizationSignUp(formData: OrganizationBasicFields) {
  const res = await apiClient.post(urls.organizationSignUp(), formData);
  return res.data as SignUpResponse | ApiError;
}

export function useOrganizationSignUp() {
  return useMutation(async (formData: OrganizationBasicFields) => {
    return await organizationSignUp(formData);
  });
}
