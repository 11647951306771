import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function validateEmailAddress(username: string) {
  const response = await apiClient.post(urls.validateEmail(), {
    username: username.trim(),
  });

  return response?.data?.data;
}
