import React from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { CheckBox, InlineLoader, InputField, PrimaryButton, SecondaryButton } from "components";
import { getMasterDataValue } from "utils/helpers";

type TableSearchFilterProps = {
  item: string;
  options: Array<MasterDataItem> | [];
  handleCheckBoxClick: (event: React.ChangeEvent<HTMLInputElement>, id: number) => void;
  handleSearchInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (item: string) => void;
  isLoading?: boolean;
  isSelected: (id: number) => boolean;
  selectedValues: Array<number | string>;
  isFilterActive?: boolean;
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
};

export function TableSearchFilter({
  item,
  options,
  handleCheckBoxClick,
  handleSearchInput,
  handleSubmit,
  isLoading,
  isSelected,
  selectedValues,
  isFilterActive,
  isOpen,
  setIsOpen,
}: TableSearchFilterProps) {
  const { t, i18n } = useTranslation();
  const handleFilterClick = () => {
    setIsOpen(!isOpen);
  };

  const handleApply = (item: string) => {
    handleSubmit(item);
    setIsOpen(false);
  };

  const getPlaceholderText = (item: string) => {
    if (item === "global.country") {
      return "admin.search_countries";
    } else if (item === "admin.research_areas") {
      return "admin.search_research_areas";
    } else if (item === "admin.type") {
      return "admin.search_types";
    } else if (item === "research_institute.research_institute") {
      return "research_institute.search_research_institute";
    } else {
      return "";
    }
  };

  return (
    <>
      <div
        onClick={handleFilterClick}
        className={`flex items-center cursor-pointer pt-1.5 -mb-1.5 ${isOpen ? "filterActive" : ""
          }`}
      >
        <SVGIcon name={isFilterActive ? "filterActive" : "filterIconV1"} />
        <span className=" pl-1">{t(item)}</span>
      </div>
      <div className="absolute z-[50] bg-white rounded-md shadow-700 top-10 -left-[22px] p-[18px] tableFilterDropdown">
        <InputField
          fieldName=""
          handleInputChange={handleSearchInput}
          hintText={t(getPlaceholderText(item))}
          labelText=""
          className=" !h-10 searchFiled !pl-10"
        />
        <div className=" flex flex-col justify-center space-x-[10px] rtl:space-x-reverse mt-[8px]">
          <div
            id="research-areas-dropdown"
            className=" grid grid-cols-1 gapy divide-y divide-gray-light mt-1.5 max-h-[200px] overflow-auto"
          >
            {options && options.length > 0 ? (
              options.map((item) => {
                return (
                  <CheckBox
                    key={item.id}
                    option={{
                      value: item.id,
                      label: getMasterDataValue(item, i18n.language) ?? item.name,
                    }}
                    handleChange={(event) => {
                      handleCheckBoxClick(event, item.id as number);
                    }}
                    className=" flex items-center space-x-2.5 rtl:space-reverse  text-[13px] text-black font-normal py-3 truncate"
                    checked={isSelected(item.id)}
                    disabled={!selectedValues.includes(item.id) && selectedValues.length > 4}
                  />
                );
              })
            ) : isLoading ? (
              <InlineLoader />
            ) : (
              <div>{t("global.no_results")}</div>
            )}
          </div>
          <div className=" flex justify-end space-x-2.5 rtl:space-reverse pt-[18px] border-t border-gray-light">
            <SecondaryButton
              title={"global.close"}
              handleClick={handleFilterClick}
              className=" flex-1 sm:flex-[0] sm:min-w-[74px] !min-h-[38px] !h-[38px]"
            />
            <PrimaryButton
              title={"global.apply"}
              handleClick={() => {
                handleApply(item);
              }}
              className=" flex-1 sm:flex-[0] sm:min-w-[74px] !min-h-[38px] !h-[38px]"
            />
          </div>
        </div>
      </div>
    </>
  );
}
