import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";
async function sendParticipationRequest(userId: string, challengeId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(
    urls.sendParticipationRequest(userId, challengeId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus | ApiError;
}

export function useSendParticipationRequest() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;
  const qc = useQueryClient();
  return useMutation(async (challengeId: number) => {
    if (userId) {
      const result = await sendParticipationRequest(userId, challengeId);
      qc.invalidateQueries(["challenge-preview-details", challengeId]);
      return result;
    }
  });
}
