import { Title } from "components";

type VideoProps = {
  url: string;
  fileName: string;
};

export default function Video({ url, fileName }: VideoProps) {
  return (
    <div>
      <Title
        className="text-base font-medium text-black p-5 px-4 ltr:pr-16 rtl:pl-16 leading-normal ltr:text-left rtl:text-right border-b border-gray-light truncate titleTextTruncate max-w-[calc(100vw-24px)]"
        title={fileName}
      />
      <div className="max-h-[calc(100vh-320px)] justify-center flex items-center flex-col">
        <video width="100%" height="100%" autoPlay controlsList="nodownload" controls>
          {/* <source src={`${url}#t=0,5`} type="video/mp4" /> */}
          <source src={`${url}`} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
