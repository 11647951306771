import { Chip, Descriptor, InlineLoader, Label, NoData, SubTitle, Title } from "components";
import coverImage from "assets/images/challengeAvatar.png";
import { useGetChallengeBasicDetails } from "features/Organization/api/useGetChallengeBasicDetails";
import {
  convertJSONStringAsHtml,
  displayDateFormat,
  getChallengeStatusBackground,
  getChallengeStatusTitle,
} from "utils/helpers";
import { useTranslation } from "react-i18next";
import { useGetViewFundingInterest } from "features/Organization/api/useGetViewFundingInterest";

type InterestsProps = {
  challengeId: number;
  interestId: number;
};

export default function Interests({ challengeId, interestId }: InterestsProps) {
  const { t } = useTranslation();
  const pathName = window.location.pathname;
  const isExplore = pathName.includes("explore");
  const { data: challengeData, isLoading } = useGetChallengeBasicDetails(challengeId, isExplore);
  const { data: fundingInterest, isLoading: isInterestLoading } = useGetViewFundingInterest(
    challengeId,
    interestId
  );

  return (
    <div
      className={` w-full bg-white rounded-md p-5  ${challengeId ? "active-interest" : ""}`}
      key={interestId}
    >
      {challengeData ? (
        <div className=" flex gap-2.5 items-start pb-5">
          <img
            src={challengeData?.coverImage ? challengeData?.coverImage : coverImage}
            alt="cover image"
            className="h-10 w-10 min-w-[40px] rounded-full object-cover "
          />
          <div className=" truncate block ">
            <Title
              title={challengeData?.title}
              className="text-sm text-black truncate font-semibold w-full"
            />
            <div className="flex space-x-4 rtl:space-x-reverse truncate items-center ">
              <Chip
                text={t(getChallengeStatusTitle(challengeData?.challengeStatusId))}
                className={`!text-gray-107 chipOpen !m-0 ${getChallengeStatusBackground(
                  challengeData?.challengeStatusId
                )}`}
              />
              <Descriptor
                title={`${t("global.start_date")}: ${displayDateFormat(challengeData?.startDate)}`}
                startIcon="date"
                className="info !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW date"
              />
              <div className=" w-[1px] h-3 bg-gray-200"></div>
              <Descriptor
                title={`${t("global.end_date")}: ${displayDateFormat(challengeData?.endDate)}`}
                startIcon="date"
                className="info !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
              />
            </div>
          </div>
        </div>
      ) : null}
      <div className=" bg-gray-111 p-5 rounded-md h-[calc(100vh-276px)] overflow-auto">
        {fundingInterest ? <InterestItem fundingInterest={fundingInterest} /> : null}
        {isLoading || isInterestLoading ? <InlineLoader /> : null}
        {!isInterestLoading && !isLoading && !interestId ? (
          <div className="p-5 bg-white w-full h-full rounded-md">
            <NoData
              className="!mb-0 bg-gray-111 justify-center py-0"
              iconName="noMsg"
              message="forum.select_an_interest_to_show_here"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}

const InterestItem = ({ fundingInterest }: { fundingInterest: ViewFundingInterest }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white rounded-2xl p-5 w-full flex flex-col mb-4  shadow-500 ">
      <Label className="text-gray-200 font-normal text-sm" text={t("global.title")} />
      <SubTitle
        title={fundingInterest?.subject}
        className="font-medium text-base text-gray-109 mt-2 mb-4 !justify-start"
      />
      <p
        className="text-gray-109 font-normal text-sm dangerously-set-html wB_BW"
        dangerouslySetInnerHTML={{
          __html: convertJSONStringAsHtml(fundingInterest?.message),
        }}
      />
    </div>
  );
};
