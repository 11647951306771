import React from "react";
import { useTranslation } from "react-i18next";

type FooterItemProps = {
  text: string;
  handleClick: () => void;
};

export default function FooterItem({ text, handleClick }: FooterItemProps) {
  const { t } = useTranslation();

  return (
    <li className="active w-[69]" onClick={handleClick}>
      <span className=" text-[15px] md:text-sm leading-[22px] md:leading-6 font-normal whitespace-nowrap">
        {t(text)}
      </span>
    </li>
  );
}
