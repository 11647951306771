import { useEffect, useRef, useState } from "react";

export function useOutsideClick() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropDownRef = useRef<HTMLDivElement>(null);

  function handleClickOutside(event: MouseEvent) {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target as Node) && isOpen) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return { isOpen, setIsOpen, dropDownRef };
}