import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";
async function manageResearchScholarRequest(
  collaborationId: number,
  scholarId: string,
  status: number
) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(
    urls.manageCollaborationScholarRequest(collaborationId, scholarId, status),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus | ApiError;
}

export function useManageResearchScholarRequest() {
  const qc = useQueryClient();
  return useMutation(
    async ({
      collaborationId,
      scholarId,
      status,
    }: {
      collaborationId: number;
      scholarId: string;
      status: number;
    }) => {
      const result = await manageResearchScholarRequest(collaborationId, scholarId, status);

      qc.resetQueries(["collaboration-users"]);
      qc.invalidateQueries(["collaboration-basic-details", collaborationId]);
      qc.invalidateQueries(["user-collaboration-status", collaborationId, scholarId]);
      qc.resetQueries(["research-scholar-details"]);
      return result;
    }
  );
}
