import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  TextButton,
  FilterTab,
  InlineLoader,
  NoData,
  Title,
  SecondaryButton,
  RadioButton,
} from "components";
import ResearchScholarInvites from "features/ResearchScholar/Invites";
import { NotificationItem } from "features/Notifications/NotificationItem";
import { useGetNotifications } from "features/Notifications/api/useGetNotifications";
import { useUserDataStore } from "store/useUserDataStore";
import { FORUM_TYPES, USERS } from "utils/constants";
import { useSetAllRead } from "features/Notifications/api/useSetAllRead";
import { useMasterDataStore } from "store/useMasterDataStore";
import CollaborationResearchScholarInvites from "features/Collaboration/Invites";

type notificationsProps = {
  closeModal: () => void;
};

export function Notifications({ closeModal }: notificationsProps) {
  const { t } = useTranslation();
  const [stepNumber, setStepNumber] = useState(FORUM_TYPES.challenges);

  const { userInfo } = useUserDataStore();
  const { permissions } = useMasterDataStore();
  const { data, fetchNextPage, hasNextPage, isLoading } = useGetNotifications();
  const { mutateAsync: setAllRead, isLoading: isAllReadLoading } = useSetAllRead();

  const notificationDetails = useMemo(() => {
    const tempData: Notifications = {
      notifications: [],
      totalCount: 0,
      totalPages: 0,
      unreadNotificationCount: 0,
    };
    if (data?.pages && data?.pages[0]) {
      tempData.totalCount = data.pages[0]?.totalCount;
      tempData.totalPages = data.pages[0]?.totalPages;
      tempData.unreadNotificationCount = data.pages[0]?.unreadNotificationCount;
      const notification = data.pages
        .map((item) => {
          return item.notifications;
        })
        .flat();
      tempData.notifications = notification;
    }
    return tempData;
  }, [data]);

  const [activeTab, setActiveTab] = useState<number>(1);
  const handleTabChange = (type: number) => {
    setActiveTab(type);
  };

  const challengeFilterItems =
    (userInfo?.userType === USERS.ResearchScholar || userInfo?.userType === USERS.Funding) &&
    permissions &&
    permissions.Viewinvites
      ? [
          {
            value: 1,
            label: "global.notifications",
          },
          {
            value: 2,
            label: "global.invites",
          },
        ]
      : [];

  const handleAllRead = async () => {
    await setAllRead();
  };

  const handleRadioButtonChange = (value: number) => {
    setStepNumber(value);
  };

  return (
    <div className="fixed lg:absolute bg-white w-full lg:w-[472px] top-0 lg:top-[60px] ltr:right-0 rtl:left-0 shadow-alertDropdown rounded-md  lg:ltr:pr-4 lg:rtl:pl-4 lg:pt-6 lg:pb-4 pt-7 notificationAlert">
      {challengeFilterItems?.length > 0 ? (
        <div className=" flex justify-between items-center   lg:ltr:ml-8 lg:rtl:mr-8">
          <div className="tabContainer w-full   flex items-baseline border-b border-primary-102/20  ">
            <FilterTab
              filterOptions={challengeFilterItems}
              activeTab={activeTab}
              handleClick={(value) => handleTabChange(value)}
            />
          </div>
          {activeTab === 1 && notificationDetails?.unreadNotificationCount > 0 ? (
            <div className="makeAllRead">
              <TextButton
                title={"global.mark_all_as_read"}
                handleClick={handleAllRead}
                className="btn !bg-transparent p-0 h-auto min-h-[auto] border-none text-primary-medium text-sm font-normal normal-case "
              />
            </div>
          ) : null}
        </div>
      ) : (
        <div className=" flex items-center justify-between ltr:pl-8 rtl:pr-8 pb-4 ltr:pr-6 rtl:pl-6 lg:ltr:pr-0 lg:rtl:pl-0">
          <Title className=" text-black text-sm font-semibold" title={t("global.notifications")} />
          {notificationDetails?.unreadNotificationCount > 0 ? (
            <div className="makeAllRead">
              <TextButton
                title={"global.mark_all_as_read"}
                handleClick={handleAllRead}
                className="btn !bg-transparent p-0 h-auto min-h-[auto] border-none text-primary-medium text-sm font-normal normal-case "
              />
            </div>
          ) : null}
        </div>
      )}
      {userInfo?.isGlobal && activeTab === 2 ? (
        <div className=" my-3 flex items-center space-x-6 rtl:space-x-reverse w-full pl-[32px] rtl:pr-[32px] collaborationSelect">
          <RadioButton
            className="p-1 text-black text-sm"
            label={t("home.challenges")}
            value={FORUM_TYPES.challenges}
            checked={stepNumber === FORUM_TYPES.challenges}
            onChange={() => handleRadioButtonChange(1)}
          />
          <RadioButton
            className="p-1 text-black text-sm"
            label={t("global.collaborations")}
            value={FORUM_TYPES.collaborations}
            checked={stepNumber === FORUM_TYPES.collaborations}
            onChange={() => handleRadioButtonChange(2)}
          />
        </div>
      ) : null}
      <div
        className=" lg:max-h-96 overflow-auto !overflow-x-hidden mt-2.5 lg:h-auto h-[calc(100vh-152px)]"
        id="notification-box"
      >
        {activeTab === 1 ? (
          <>
            {notificationDetails &&
            notificationDetails.totalCount &&
            notificationDetails.totalCount > 0 ? (
              <>
                <InfiniteScroll
                  dataLength={notificationDetails.notifications?.length}
                  next={fetchNextPage}
                  hasMore={!!hasNextPage}
                  scrollThreshold={1}
                  scrollableTarget="notification-box"
                  loader={hasNextPage || isLoading ? <InlineLoader /> : null}
                >
                  {notificationDetails?.notifications.map((item: NotificationDetails) => (
                    <NotificationItem
                      key={`${item?.id}`}
                      title={item?.title}
                      message={item?.message}
                      profileImage={item?.profileImage}
                      createdOn={item?.createdOn}
                      isRead={item?.isRead}
                    />
                  ))}
                </InfiniteScroll>
              </>
            ) : (
              <NoData message="global.no_notifications" />
            )}
          </>
        ) : null}
        {activeTab === 2 ? (
          <>
            {stepNumber === FORUM_TYPES.challenges ? (
              <ResearchScholarInvites />
            ) : (
              <CollaborationResearchScholarInvites />
            )}
          </>
        ) : null}
      </div>
      <div className=" w-full bg-white p-4 shadow-600 flex lg:hidden">
        <SecondaryButton className=" w-full" title={"global.close"} handleClick={closeModal} />
      </div>
    </div>
  );
}
