import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { Chip, Title, MultiSelect, LoaderModal } from "components";
import { PAGE_LIMIT, INITIAL_SEARCH_PARAMS, CHALLENGE_STATUS } from "utils/constants";
import { getTags } from "features/ResearchScholar/api/useGetTag";
import {
  setTagsInfo,
  setTagsInfoItem,
  useCollaborationDataStore,
} from "store/useCollaborationDataStore";
import { useGetCollaborationTags } from "features/Collaboration/api/useGetCollaborationTags";

const Tags = () => {
  const { t, i18n } = useTranslation();
  const { tags } = useCollaborationDataStore().tags;
  const tagSearchParamsRef = useRef(INITIAL_SEARCH_PARAMS);
  const { data: tagsData, isLoading: isTagsLoading } = useGetCollaborationTags();
  const { collaborationInfo } = useCollaborationDataStore();
  const isPending = collaborationInfo.collaborationStatusId === CHALLENGE_STATUS.Pending;

  async function loadTagOptions(searchKeyword: string) {
    let pageNumber = tagSearchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== tagSearchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getTags({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    tagSearchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  const handleDeleteTags = (index: number) => {
    if (tags) {
      const filteredTags = [...tags.slice(0, index), ...tags.slice(index + 1)];
      setTagsInfoItem(filteredTags, "tags");
      tagSearchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
    }
  };

  useEffect(() => {
    if (tagsData) {
      setTagsInfo(tagsData);
    }
    tagSearchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
  }, [tagsData]);

  return (
    <div className="p-4">
      <div className="flex items-center border-b border-gray-light">
        <SVGIcon name="tags" />
        <Title
          className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
          title={t("organization.tags")}
        />
      </div>

      <div>
        <MultiSelect
          labelText={""}
          hintText={t("global.search")}
          setSelectedOptions={(options: Array<OptionItem> | null) => {
            setTagsInfoItem(options, "tags");
            tagSearchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
          }}
          selectedOptions={tags || []}
          isDisabled={isPending}
          addNew
          errorToastMessage="global.create_tag_min_error_message"
          createLabel={"research_scholar.new_tag"}
          resetKey={Math.random().toString()}
          loadOptions={loadTagOptions}
        />
      </div>
      <div>
        <div className=" flex  flex-wrap mt-5 p-1">
          {tags?.map((item, index) => (
            <Chip
              key={item.id}
              text={i18n.language === "ar" ? item.name.ar : item.name.en}
              handleClose={() => {
                handleDeleteTags(index);
              }}
              disabled={isPending}
            />
          ))}
        </div>
      </div>
      {isTagsLoading ? <LoaderModal /> : null}
    </div>
  );
};

export default Tags;
