import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import SVGIcon from "assets/Icons";
import Profile from "assets/images/avatar.svg";
import webSite from "assets/images/webSite.png";
import linkedin from "assets/images/linkedin.png";
import { useMasterDataStore } from "store/useMasterDataStore";
import {
  SubTitle,
  Title,
  TextButton,
  Descriptor,
  ExternalURLLink,
  Popup,
  UploadedFileItem,
  LoaderModal,
  ContactDetailsCard,
  OfficeDetailsCard,
  Header,
} from "components";

import Navigator from "features/Admin/Users/Navigator";
import ProfilePicture from "features/Admin/ProfilePicture";
import { useManageOrganizationRequest } from "features/Admin/api/useManageOrganizationRequests";
import { useGetOrganizationUserDetails } from "features/Admin/api/useGetOrganizationUserDetails";
import { showConfirmationModal, showStatusChangeConfirmationModal } from "utils/confirmationModals";
import {
  DETAILS_DISPLAY_MAX_CHAR_LENGTH,
  ORGANIZATION_PROFILE_COMPLETION_STATUS,
  USERS,
  USER_STATUS,
} from "utils/constants";
import {
  getBreadCrumbTitle,
  getMasterDataValue,
  handleDisableOrEnableNavigation,
  handleLinkClick,
} from "utils/helpers";
import {
  ToasterIconType,
  showErrorToastMessage,
  showInfoToastMessage,
  showSuccessToastMessage,
} from "utils/toasterMessage";
import { useStickyScroll } from "hooks/useStickyScroll";
import { useSendResetPasswordLink } from "features/Admin/api/useSendResetPasswordLink";
import { useUserDataStore } from "store/useUserDataStore";
import { setSelectedSideMenu } from "store/useSidebarStore";

export default function OrganizationInfo() {
  const { t, i18n } = useTranslation();
  const { organizationId } = useParams();
  const { userInfo } = useUserDataStore();
  const navigate = useNavigate();
  const { scroll } = useStickyScroll();
  const pathname = window.location.pathname;
  const isSearchResult = pathname.includes("search-results");
  const organizationDetailsRef = useRef(null);
  const contactsRef = useRef(null);
  const officeRef = useRef(null);
  const documentsRef = useRef(null);

  const [isViewMore, setIsViewMore] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState<number>(0);

  const { mutateAsync: manageOrganizationRequest, isLoading: isManageRequestLoading } =
    useManageOrganizationRequest();
  const { mutateAsync: resetLinkPassword, isLoading: isResetLinkLoading } =
    useSendResetPasswordLink();
  const { data: userData, isLoading: userDataLoading } = useGetOrganizationUserDetails(
    organizationId ?? ""
  );
  const { masterData, countryCode, organizationTypes } = useMasterDataStore();

  const linkList: Array<LinkItem> = useMemo(() => {
    const items: Array<LinkItem> = isSearchResult
      ? [
          {
            title: "global.search_results",
          },
          {
            title: "global.view_profile",
          },
        ]
      : [
          {
            title: "admin.users",
          },
          {
            title: "global.organizations",
          },
          {
            title: getBreadCrumbTitle(userData?.profile?.profileStatusId),
          },
        ];

    return items;
  }, [userData, isSearchResult]);

  const handleScroll = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 100,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const handleNavigationClick = (value: number, index: number) => {
    setActiveMenu(index);
    if (value === ORGANIZATION_PROFILE_COMPLETION_STATUS.Details) {
      handleScroll(organizationDetailsRef);
    } else if (value === ORGANIZATION_PROFILE_COMPLETION_STATUS.Contacts) {
      handleScroll(contactsRef);
    } else if (value === ORGANIZATION_PROFILE_COMPLETION_STATUS.Offices) {
      handleScroll(officeRef);
    } else if (value === ORGANIZATION_PROFILE_COMPLETION_STATUS.Documents) {
      handleScroll(documentsRef);
    }
  };

  const handleDeclineAndRemove = async () => {
    const result = await showConfirmationModal({
      title: "global.confirm_decline",
      message: "global.decline_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.decline",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      try {
        const response = await manageOrganizationRequest({
          userId: organizationId ?? "",
          statusId: USER_STATUS.Declined,
        });
        if (response) {
          navigate(-1);
          return showErrorToastMessage({
            message: response.message,
            iconType: ToasterIconType.DECLINED,
          });
        }
      } catch (error: ApiError) {
        if (error.response.data.status === 400) {
          navigate(-1);
          return showErrorToastMessage({
            message: error.response.data.message ?? t("global.something_went_wrong"),
          });
        } else {
          return showErrorToastMessage({
            message: t("global.something_went_wrong"),
          });
        }
      }
    }
  };

  const handleApprove = async () => {
    try {
      const result = await manageOrganizationRequest({
        userId: organizationId ?? "",
        statusId: USER_STATUS.Approved,
      });
      if (result) {
        navigate(-1);
        return showInfoToastMessage({
          iconType: ToasterIconType.APPROVED,
          message: result.message,
        });
      }
    } catch (error: ApiError) {
      if (error.response.data.status === 400) {
        navigate(-1);
        return showErrorToastMessage({
          message: error.response.data.message ?? t("global.something_went_wrong"),
        });
      } else {
        return showErrorToastMessage({
          message: error.response.data.message ?? t("global.something_went_wrong"),
        });
      }
    }
  };

  const navigationItems: Array<RecordItem> = useMemo(() => {
    const tempItems = [
      {
        value: ORGANIZATION_PROFILE_COMPLETION_STATUS.Details,
        label: "organization.organization_details",
      },
      {
        value: ORGANIZATION_PROFILE_COMPLETION_STATUS.Contacts,
        label: "organization.contacts",
      },
    ];
    if (userData?.offices?.length) {
      tempItems.push({
        value: ORGANIZATION_PROFILE_COMPLETION_STATUS.Offices,
        label: "organization.office",
      });
    }
    if (userData?.documents?.length) {
      tempItems.push({
        value: ORGANIZATION_PROFILE_COMPLETION_STATUS.Documents,
        label: "organization.documents",
      });
    }

    return tempItems;
  }, [userData]);

  const settingsItems = useMemo(() => {
    if (userData?.profile?.profileStatusId === USER_STATUS.Approved) {
      return [
        { icon: "update", label: "global.update", type: "update" },
        { icon: "resetPassword", label: "global.reset_password", type: "reset" },
        { icon: "disable", label: "admin.disable", type: "disable" },
      ];
    } else {
      return [
        { icon: "update", label: "global.update", type: "update" },
        { icon: "resetPassword", label: "global.reset_password", type: "reset" },
        { icon: "enable", label: "admin.enable", type: "enable" },
      ];
    }
  }, [userData]);

  const selectedCountry = useMemo(() => {
    return masterData?.country?.filter((item) => item.id == userData?.profile?.countryId)?.[0];
  }, [userData, masterData]);

  const selectedCountryCode = useMemo(() => {
    return countryCode?.filter((item) => item.id == userData?.profile?.countryCodeId)?.[0];
  }, [countryCode, userData]);

  const selectedResearchArea = useMemo(() => {
    const lang = i18n.language;
    if (lang == "ar") {
      return userData?.profile?.researchArea?.map((item: OptionItem) => item?.name?.ar)?.join(", ");
    } else {
      return userData?.profile?.researchArea?.map((item: OptionItem) => item?.name?.en)?.join(", ");
    }
  }, [userData, i18n.language]);

  const selectedOrganizationType = useMemo(() => {
    return organizationTypes?.filter(
      (item) => item.id == userData?.profile?.organizationTypeId
    )?.[0];
  }, [organizationTypes, userData]);

  const handleViewClick = () => {
    setIsViewMore(!isViewMore);
    handleScroll(organizationDetailsRef);
  };

  const navigateSection = (isNavigate: boolean) => {
    if (!isNavigate) {
      return;
    } else {
      handleDisableOrEnableNavigation(isSearchResult, navigate, "/admin/users/organization");
    }
  };

  const handleSettingsMenuClick = async (type: string) => {
    if (type === "disable") {
      const isConfirm = await showStatusChangeConfirmationModal({
        title: t("admin.disable_this_user"),
        message: t("admin.this_action_will_disable_the_user_from_the_platform"),
        userId: organizationId ?? "",
        status: USER_STATUS.Disabled,
        userType: USERS.Organization,
      });
      navigateSection(isConfirm);
    } else if (type === "enable") {
      const isConfirm = await showStatusChangeConfirmationModal({
        title: t("admin.enable_this_user"),
        message: t("admin.enable_a_user_confirmation_message"),
        userId: organizationId ?? "",
        status: USER_STATUS.Approved,
        userType: USERS.Organization,
      });
      navigateSection(isConfirm);
    } else if (type === "reset") {
      const result = await showConfirmationModal({
        title: "global.reset_password",
        message: "admin.reset_password_link_message",
        cancelButtonText: "global.close",
        proceedButtonText: "global.proceed",
        modalIcon: "resetPasswordV1",
      });
      if (!result) {
        return;
      } else {
        try {
          const result = await await resetLinkPassword(organizationId ?? "");
          showSuccessToastMessage({
            message: result.message,
          });
        } catch (error: ApiError) {
          showErrorToastMessage({
            message:
              error?.response?.data?.status < 500
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
        }
      }
    } else if (type === "update") {
      navigate("profile");
    }
  };

  const handleDeclineWithReason = async () => {
    const isConfirm = await showStatusChangeConfirmationModal({
      title: t("global.confirm_decline"),
      message: t("admin.decline_with_reason_modal_content"),
      userId: organizationId ?? "",
      status: USER_STATUS.DeclineWithReason,
      userType: USERS.Organization,
    });
    if (!isConfirm) {
      return;
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "admin.users",
    });
  }, []);

  return (
    <>
      <Header
        title={"global.profile"}
        linkList={linkList}
        className={
          scroll
            ? "  bg-primary-medium lg:bg-white animate__animated animate__fadeInDown lg:shadow-sticky lg:!py-[26px] lg:!h-[90px] fixed stickyHeader mobile-sticky-header"
            : "fixed bg-primary-medium lg:bg-gray-light"
        }
      />
      <div
        className=" adminDetails w-full mt-[170px] mb-20 lg:mb-0 lg:mt-0 lg:pt-[118px] pb-[45px] items-baseline px-4 lg:px-10 lg:min-h-screen flex flex-col  overflow-auto lg:ltr:pr-[30px] lg:rtl:pl-[30px]"
        ref={organizationDetailsRef}
      >
        <div className="w-full flex flex-col ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 ltr:lg:pr-[292px] rtl:lg:pl-[292px] space-y-3">
          {/* Personal details start */}
          <div className="flex-col bg-white w-full h-full rounded-md flex">
            <div className="p-5 flex items-center border-b border-gray-light relative">
              <SVGIcon name="personalDetails" />
              <Title
                title={t("global.personal_details")}
                className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
              />
              {userData?.profile?.profileStatusId !== USER_STATUS.PendingApproval ? (
                <div className=" absolute ltr:right-[13px] rtl:left-[13px] top-[13px] flex items-center space-x-.5">
                  {userData?.profile?.profileStatusId === USER_STATUS.Disabled ? (
                    <div className="flex items-center justify-start  text-primary-medium bg-primary-500 !w-auto px-[12px] py-[6px] text-xs font-normal rounded-[12px] ">
                      <span className=" whitespace-nowrap">{t("global.disabled")}</span>
                    </div>
                  ) : null}
                  {userInfo?.userType === USERS.Admin ? (
                    <div className="dropdown dropdown-bottom dropdown-end ltr:ml-[18px] rtl:mr-[18px]">
                      <label
                        tabIndex={0}
                        className="btn text-sm font-medium !border-none  text-white normal-case flex-nowrap px-0 w-[38px] min-w-[38px] py-0 flex items-center rounded-md bg-secondary-medium h-[38px] min-h-[38px] m-0 relative z-10 hover:bg-primary-medium"
                      >
                        <SVGIcon name="settings" />
                      </label>
                      <ul
                        tabIndex={0}
                        className="dropdown-content !z-0 menu w-[170px] min-w-[170px] divide-y divide-solid divide-primary-medium/10 shadow-100 text-gray-200 text-sm font-normal normal-case rounded-lg mt-2 ltr:-right-[5px] rtl:-left-[5px] rtl:right-auto py-[10px] bg-white text-left px-4 pb-0"
                      >
                        <Popup
                          items={settingsItems}
                          handleClick={(type: string) => {
                            handleSettingsMenuClick(type);
                          }}
                        />
                      </ul>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="px-5 py-5 lg:py-[30px] flex flex-col lg:flex-row">
              <div className="w-[120px] h-[120px] mx-auto lg:mx-[0]  rounded-md  basis-[120px] grow-0 shrink-0 overflow-hidden mt-[6px]">
                <ProfilePicture
                  url={
                    userData?.profile?.profileActualImage
                      ? userData?.profile?.profileActualImage
                      : Profile
                  }
                />
              </div>
              <div className="text-section pt-5 lg:pt-0 lg:ltr:pl-[22px] lg:rtl:mr-[22px]">
                <div className=" flex items-center space-x-2 rtl:space-x-reverse mb-1 ">
                  <h3 className="text-lg font-semibold text-black WB_BW ">
                    {userData?.profile?.organizationName}
                  </h3>

                  {userData?.profile?.isGlobal &&
                  (userInfo?.userType === USERS.Admin || !userInfo?.isGlobal) ? (
                    <div className=" h-[20px] flex items-center px-3 ltr:!ml-1 rtl:!mr-1 rounded-xl text-xs text-white font-normal bg-primary-medium ">
                      {t("global.global")}
                    </div>
                  ) : null}
                </div>
                <SubTitle
                  title={
                    selectedOrganizationType
                      ? getMasterDataValue(selectedOrganizationType, i18n.language)
                      : ""
                  }
                  className=" text-gray-102 text-sm font-normal !justify-start WB_BW"
                />
                <div className="text-black text-sm font-normal max-w-[543px] mt-2 mb-[26px] WB_BW">
                  {userData?.profile?.about ? (
                    <>
                      {userData?.profile?.about?.length > DETAILS_DISPLAY_MAX_CHAR_LENGTH &&
                      !isViewMore ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: userData?.profile?.about
                              ?.slice(0, DETAILS_DISPLAY_MAX_CHAR_LENGTH)
                              .replace(/\n/g, "<br/>"),
                          }}
                        />
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: userData?.profile?.about.replace(/\n/g, "<br/>"),
                          }}
                        />
                      )}
                      {userData?.profile?.about?.length > DETAILS_DISPLAY_MAX_CHAR_LENGTH ? (
                        <TextButton
                          title={isViewMore ? t("global.view_less") : t("global.view_more")}
                          handleClick={handleViewClick}
                          className="text-primary-medium font-medium underline ml-1.5 cursor-pointer"
                        />
                      ) : null}
                    </>
                  ) : null}
                </div>
                <div className=" flex flex-col space-y-4 lg:space-y-5">
                  <div className="flex flex-col lg:flex-row lg:space-x-10 lg:rtl:space-x-reverse space-y-4 lg:space-y-0">
                    <Descriptor
                      startIcon="mapPin"
                      title={
                        selectedCountry ? getMasterDataValue(selectedCountry, i18n.language) : ""
                      }
                      className="min-w-[160px] !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
                    />
                    <Descriptor
                      startIcon="msg"
                      title={userData?.profile?.email ?? ""}
                      className=" !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
                    />
                  </div>

                  <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-10 md:rtl:space-x-reverse">
                    <Descriptor
                      startIcon="phone"
                      title={`${selectedCountryCode?.name?.code ?? ""} ${
                        userData?.profile?.contactNumber ?? ""
                      }`}
                      className=" !mb-0 min-w-[160px] WB_BW flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse"
                    />
                    {selectedResearchArea ? (
                      <Descriptor
                        startIcon="location"
                        className=" !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
                        title={selectedResearchArea}
                      />
                    ) : null}
                  </div>
                  <div className="flex">
                    <Descriptor
                      startIcon="tradeLicense"
                      className=" !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
                      title={userData?.profile?.tradeLicenseNumber ?? ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Personal details end */}

          {/* Contacts start */}
          <div className="flex-col bg-white w-full h-full rounded-md flex" ref={contactsRef}>
            <div className="p-5 flex items-center border-b border-gray-light">
              <SVGIcon name="contacts" />
              <Title
                title={t("organization.contacts")}
                className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
              />
            </div>
            <div className=" p-5 space-y-[22px]">
              {userData?.contacts?.map((item: OrganizationContact, index: number) => (
                <div key={index}>
                  <ContactDetailsCard
                    fullName={item.fullName}
                    contactNumber={item.contactNumber}
                    countryCodeId={item?.countryCodeId}
                    designation={item.designation}
                    email={item.email}
                  />
                </div>
              ))}
            </div>
          </div>
          {/* Contacts end */}

          {/* Office details start */}
          {userData?.offices?.length ? (
            <div className="flex-col bg-white w-full h-full rounded-md flex" ref={officeRef}>
              <div className="p-5 flex items-center border-b border-gray-light">
                <SVGIcon name="office" />
                <Title
                  title={t("organization.office")}
                  className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
                />
              </div>
              <div className=" p-5 space-y-[22px]">
                {userData?.offices?.map((item: OrganizationOffice, index: number) => (
                  <div key={index}>
                    <OfficeDetailsCard name={item?.name} address={item?.address} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {/* Office details end */}

          {/* Social profiles start */}
          {userData?.profile?.linkedInUrl || userData?.profile?.websiteUrl ? (
            <div className="flex-col bg-white w-full h-full rounded-md flex">
              <div className="p-5 flex items-center border-b border-gray-light">
                <SVGIcon name="socialProfiles" />
                <Title
                  title={t("global.social_profiles")}
                  className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
                />
              </div>

              <div className="p-5 items-center grid grid-cols-3 gap-2.5">
                {userData?.profile?.linkedInUrl ? (
                  <ExternalURLLink
                    label="admin.linkedin"
                    handleClick={() => handleLinkClick(userData?.profile?.linkedInUrl)}
                    imageUrl={linkedin}
                    imageAlt="linkedIn"
                    buttonClassName="cursor-pointer py-3 border-gray-light border px-5 rounded-md flex-1 truncate flex justify-center items-center"
                    labelClassName="text-sm font-medium px-[18px] text-black"
                  />
                ) : null}
                {userData?.profile?.websiteUrl ? (
                  <ExternalURLLink
                    label="organization.website"
                    handleClick={() => handleLinkClick(userData?.profile?.websiteUrl)}
                    imageUrl={webSite}
                    imageAlt="Website"
                    buttonClassName="cursor-pointer py-3 border-gray-light border px-5 rounded-md flex-1 truncate flex justify-center items-center"
                    labelClassName="text-sm font-medium px-[18px] text-black"
                  />
                ) : null}
              </div>
            </div>
          ) : null}
          {/* Social profiles end */}

          {/* Documents start */}
          {userData?.documents?.length ? (
            <div className="flex-col bg-white w-full h-full rounded-md flex" ref={documentsRef}>
              <div className="p-5 flex items-center border-b border-gray-light">
                <SVGIcon name="documentsIconV1" />
                <Title
                  title={t("organization.documents")}
                  className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
                />
              </div>
              <div className="p-5  grid grid-cols-1 md:grid-cols-2 gap-6 items-center  rtl:space-x-reverse">
                {userData?.documents?.map((item: FileDetails, index: number) => (
                  <div className=" flex-1 truncate" key={index}>
                    <UploadedFileItem key={item.fileUrl + new Date()} isPreview file={item} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {/* Documents end */}
        </div>
        <Navigator
          isButtonVisible={
            userData?.profile?.profileStatusId === USER_STATUS?.PendingApproval &&
            userInfo?.userType === USERS.Admin
          }
          handleApprove={handleApprove}
          handleNavigationClick={(value: number, index: number) =>
            handleNavigationClick(value, index)
          }
          navigationItems={navigationItems}
          scroll={scroll}
          activeMenu={activeMenu}
          handleDecline={handleDeclineAndRemove}
          handleDeclineWithReason={handleDeclineWithReason}
          isDeclineWithReason={true}
        />
        {userDataLoading || isManageRequestLoading || isResetLinkLoading ? <LoaderModal /> : null}
      </div>
    </>
  );
}
