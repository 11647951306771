import { useTranslation } from "react-i18next";

type ErrorTextProps = {
  errorMessage: string;
  className?: string;
};

export function ErrorText({ errorMessage, className }: ErrorTextProps) {
  const { t } = useTranslation();
  return (
    <span
      className={` text-sm text-red-100 mt-2 rtl:pr-[3px] w-full ltr:text-left rtl:text-right ${className}`}
    >
      {t(errorMessage)}
    </span>
  );
}
