import { useQuery } from "@tanstack/react-query";
import { useMasterDataStore } from "store/useMasterDataStore";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";

import { urls } from "utils/urls";

async function getUnreadCount() {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.notificationCount(), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data?.data as NotificationCount | ApiError;
}

export function useGetUnreadCount() {
  const { masterData } = useMasterDataStore();
  const fetchTime = masterData?.systemConfiguration?.notifCountFetchTime ?? 10;

  return useQuery(
    ["notification-count"],
    async () => {
      const result = await getUnreadCount();
      return result;
    },
    {
      refetchInterval: fetchTime * 1000,
    }
  );
}
