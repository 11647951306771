import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getResearchProjects(userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.getResearchProjects(userId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (res.status === 400) {
    throw new Error("error");
  }
  const response = res.data?.data;
  if (response?.length > 0) return response as Array<ResearchProject> | ApiError;
  else return [];
}

export function useGetResearchProjects(researchScholarId: string) {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = researchScholarId ? researchScholarId : userInfo?.userId;
  return useQuery(
    ["research-projects"],
    async () => {
      if (userId) {
        return await getResearchProjects(userId);
      } else return;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
