import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function deleteFile(collaborationId: number, fileDetails: FileDetailsItem) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(urls.collaborationForumFileUpload(collaborationId), fileDetails, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response?.data as ApiStatus | ApiError;
}

export function useDeleteForumFile() {
  return useMutation(
    async ({ collaborationId, fileDetails }: { collaborationId: number; fileDetails: FileDetailsItem }) => {
      const result = await deleteFile(collaborationId, fileDetails);
      return result;
    }
  );
}
