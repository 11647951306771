import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Title,
  Label,
  LoaderModal,
  InlineLoader,
  IconTypeButton,
  UploadedFileItem,
} from "components";
import profileAvatar from "assets/images/avatar.svg";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { getTimeDifference } from "utils/helpers";
import { useDeleteReply } from "features/Collaboration/api/useDeleteReply";
import { useGetReplies } from "features/Collaboration/api/useGetReplies";
import { showConfirmationModal } from "utils/confirmationModals";

type ReplyViewProps = {
  collaborationId: number;
  postId: number;
};

export default function ReplyView({ collaborationId, postId }: ReplyViewProps) {
  const { t, i18n } = useTranslation();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading: isRepliesLoading,
  } = useGetReplies({ collaborationId, postId });
  const { mutateAsync: deleteReply, isLoading: isDeleteLoading } = useDeleteReply();

  const replyDetails = useMemo(() => {
    const tempData: ReplyList = { forumReplies: [], totalCount: 0, totalPages: 0 };
    if (data?.pages && data?.pages[0]) {
      tempData.totalCount = data.pages[0]?.totalCount;
      tempData.totalPages = data.pages[0]?.totalPages;
      const forumReplies = data.pages
        .map((item) => {
          return item.forumReplies;
        })
        .flat();
      tempData.forumReplies = forumReplies;
    }
    return tempData;
  }, [data]);

  const handleDeleteReply = async (replyId: number) => {
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "forum.reply_delete_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      try {
        const result = await deleteReply({ collaborationId, postId, replyId });
        showSuccessToastMessage({ message: result?.message });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error?.response?.data?.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };

  useEffect(() => {
    const infiniteScroll = document.getElementById(`scrollableDiv-${postId}`);
    if (infiniteScroll) {
      const wrapperHeight = infiniteScroll.clientHeight;
      setInfiniteScrollHeight(wrapperHeight);
    }
  }, [replyDetails?.forumReplies, postId]);

  const setInfiniteScrollHeight = (height: number) => {
    const infiniteScroll = document.getElementById(`scrollableDiv-${postId}`);
    if (infiniteScroll) {
      infiniteScroll.style.height = `${height + 100}px`;
    }
  };

  return (
    <div className="overflow-auto">
      {replyDetails && replyDetails.totalCount && replyDetails.totalCount > 0 ? (
        <InfiniteScroll
          dataLength={replyDetails?.forumReplies?.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={isRepliesLoading ? <InlineLoader /> : null}
          scrollThreshold="500px"
          scrollableTarget={`scrollableDiv-${postId}`}
        >
          <div
            id={`scrollableDiv-${postId}`}
            className=" !h-auto max-h-96  overflow-auto space-y-5 mt-5"
          >
            {replyDetails?.forumReplies?.map((replyItem) => (
              <div className="w-full p-5 border border-gray-110 rounded-md" key={replyItem.id}>
                <div className="flex gap-2 items-center w-full">
                  <img
                    alt="profile picture"
                    className="h-8 w-8 rounded-full"
                    src={replyItem?.profileAvatarUrl ? replyItem?.profileAvatarUrl : profileAvatar}
                  />
                  <div className="flex flex-col truncate w-full ">
                    <Title
                      title={replyItem?.userName ?? ""}
                      className="font-semibold text-sm text-black truncate dirRTL_LTR"
                    />
                    <Label
                      text={getTimeDifference(replyItem?.createdOn, i18n.language, t)}
                      className="font-normal text-xs text-gray-107"
                    />
                  </div>
                </div>
                <div className="relative flex items-center justify-between my-4 gap-[10px]">
                  <p
                    className=" text-gray-109 font-normal text-sm dangerously-set-html WB_BW"
                    dangerouslySetInnerHTML={{
                      __html: replyItem.reply
                        ?.replace(/\n/g, "<br/>")
                        .replace(/ /g, () => "&nbsp;"),
                    }}
                  />
                  {replyItem?.canDelete ? (
                    <IconTypeButton
                      iconName="trashV1"
                      className=" cursor-pointer !m-0"
                      handleClick={() => handleDeleteReply(replyItem?.id)}
                    />
                  ) : null}
                </div>
                {replyItem?.attachments && replyItem?.attachments?.length > 0 ? (
                  <div>
                    <Title
                      title={t("global.attachments")}
                      className="text-sm text-primary-light font-normal mb-1"
                    />
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2.5 w-full">
                      {replyItem?.attachments.map((attaches) => (
                        <UploadedFileItem
                          key={attaches.name + new Date()}
                          isPreview
                          file={attaches}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </InfiniteScroll>
      ) : null}
      {isDeleteLoading ? <LoaderModal /> : null}
    </div>
  );
}
