import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function uploadFile(userId: string, file: File) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("isProfileImage", "true");
  const response = await apiClient.post(urls.uploadProfileFile(userId), formData, {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
  });
  return response.data.data as FileDetailsItem;
}

export function useProfilePicUpload() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;

  return useMutation(async (file: File) => {
    if (userId) {
      const result = await uploadFile(userId, file);
      return result;
    }
  });
}
