import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";

type NoDataProps = {
  message: string;
  iconName?: string;
  className?: string;

};

export function NoData({ message, className, iconName }: NoDataProps) {
  const { t } = useTranslation();

  return (
    <div className={` flex flex-col w-full h-auto xl:h-full items-center rounded-md justify-start bg-white mb-[45px] noData ${className}`} >
      <div className=" flex flex-col items-center justify-start my-[102px]">
        <SVGIcon name={iconName ? iconName : "noData"} />
        <span className=" text-primary-light text-sm font-medium mt-3">{t(message)}</span>
      </div>
    </div>
  );
}
