import { useTranslation } from "react-i18next";

type TextButtonProps = {
  title: string;
  isDisabled?: boolean;
  className?: string;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  key?: string | number;
};

export function TextButton({ title, isDisabled, className, handleClick, key }: TextButtonProps) {
  const { t } = useTranslation();
  return (
    <button className={className} disabled={isDisabled} onClick={handleClick} key={key}>
      {t(title)}
    </button>
  );
}
