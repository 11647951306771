import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function updateChallenge(challengeFormData: ChallengeSave) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(urls.challenge(), challengeFormData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useUpdateChallenge() {
  const queryClient = useQueryClient();
  return useMutation(async (formData: ChallengeSave) => {
    const result = await updateChallenge(formData);
    queryClient.invalidateQueries(["challenge-details", formData.id]);
    return result;
  });
}
