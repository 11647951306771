import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getMasterData() {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  const response = await apiClient.get(urls.masterData(), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data?.data as MasterData;
}

export function useGetMasterData() {
  return useQuery(["master-data"], async () => {
    const result = await getMasterData();
    return result;
  });
}
