import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { SubTitle, SecondaryButton, LoaderModal } from "components";
import OfficeForm from "features/Organization/Profile/Office/OfficeForm";
import { useGetOffices } from "features/FundingOrganization/api/useGetOffices";
import { setOfficeDetailsInfo, useOrganizationDataStore } from "store/useOrganizationDataStore";
import { OFFICE_DETAILS_INITIAL_STATE, USER_STATUS } from "utils/constants";
import { showErrorToastMessage } from "utils/toasterMessage";
import useInvalidateQuery from "hooks/useInvalidateQuery";

type OfficeProps = {
  validationErrors: Array<Record<string, string>> | null;
  removeValidationErrors: () => void;
};

export default function Office({ validationErrors, removeValidationErrors }: OfficeProps) {
  const { t, i18n } = useTranslation();
  const { fundingOrganizationId } = useParams();
  const { data: officeData, isLoading: isOfficeDetailsLoading } = useGetOffices(
    fundingOrganizationId ?? ""
  );
  useInvalidateQuery("funding-organization-details", fundingOrganizationId ?? "");

  const isReadOnly = officeData?.profileStatusId === USER_STATUS.PendingApproval;

  const { officeDetails } = useOrganizationDataStore();

  useEffect(() => {
    if (officeData) {
      setOfficeDetailsInfo(officeData);
    }
  }, [officeData, i18n.language]);

  const handleAddOffice = () => {
    const officeDetails = useOrganizationDataStore.getState().officeDetails;
    if (
      officeDetails &&
      officeDetails?.offices?.length > 0 &&
      officeDetails?.offices?.length <= 9
    ) {
      const tempOfficesInfo = structuredClone(officeDetails);
      tempOfficesInfo?.offices.push({ ...OFFICE_DETAILS_INITIAL_STATE });
      setOfficeDetailsInfo({
        ...tempOfficesInfo,
        offices: tempOfficesInfo?.offices,
      });
    } else if (officeDetails && officeDetails?.offices?.length > 9) {
      showErrorToastMessage({
        message: t("organization.max_office_add_limit_warning"),
      });
    } else if (!officeDetails || officeDetails?.offices?.length === 0) {
      setOfficeDetailsInfo({ offices: [{ ...OFFICE_DETAILS_INITIAL_STATE }] });
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-6 flex-wrap py-5 p-5 border-b border-gray-light">
        <div className="ltr:mr-auto rtl:ml-auto flex items-center justify-between">
          <SVGIcon name="OfficeIcon" />
          <SubTitle
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3 subtitle"
            title={t("organization.office")}
            tooltipContent="organization.office_tooltip_message"
          />
        </div>
        {officeDetails && officeDetails?.offices?.length > 0 ? (
          <SecondaryButton
            title={"organization.add_office"}
            handleClick={handleAddOffice}
            className="!-my-2 !h-10 !min-h-[40px] space-x-2 rtl:space-x-reverse !px-6"
            startIcon="plus"
            disabled={isReadOnly}
          />
        ) : null}
      </div>
      {!officeDetails || officeDetails?.offices?.length === 0 ? (
        <div className="p-5 pt-0 ">
          <SecondaryButton
            title={"organization.add_office"}
            handleClick={handleAddOffice}
            className=" w-full !bg-gray-light/60 py-3.5 !font-normal mb-6 space-x-4 rtl:space-x-reverse border-none h-16 flex items-center !text-primary-medium text-sm  hover:!bg-primary-medium hover:!text-white"
            endIcon="plusCircle"
            disabled={isReadOnly}
          />
        </div>
      ) : null}
      <OfficeForm
        validationErrors={validationErrors}
        removeValidationErrors={removeValidationErrors}
        isReadOnly={isReadOnly}
      />
      {isOfficeDetailsLoading ? <LoaderModal /> : null}
    </div>
  );
}
