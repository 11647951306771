import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

function useQueryManagementOnIndexChange(
  page: number | null | undefined,
  pathName: string | undefined,
  queryKey: string
) {
  const queryClient = useQueryClient();
  
  useEffect(() => {
    if (page || page === 0) {
      queryClient.invalidateQueries([queryKey], {
        refetchPage: (_, index) => index === page,
      });
    } else if (pathName && location.pathname !== pathName) {
      queryClient.resetQueries([queryKey]);
    }
  }, [page, pathName, queryClient, queryKey]);

  return {};
}

export default useQueryManagementOnIndexChange;
