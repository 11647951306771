import { PrimaryButton, TextButton, ProfileNavigationTimeline } from "components";
import { EVENT_CREATION_COMPLETION_STATUS } from "utils/constants";

type EventNavigatorProps = {
  handleDraft: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleNext: () => void;
  handleNavigationClick: (value: number, index: number) => void;
  handlePreview: () => void;
  navigationItems: Array<RecordItem>;
  stepNumber?: number;
  completionStatusId?: number;
  isEdit: boolean;
  isDisabled: boolean;
  isPreview: boolean;
  activeMenu?: number;
};

export const Navigator = ({
  handleDraft,
  handleNext,
  handleNavigationClick,
  handlePreview,
  navigationItems,
  completionStatusId,
  stepNumber,
  isEdit,
  isDisabled,
  isPreview,
  activeMenu,
}: EventNavigatorProps) => {
  const showPreview = stepNumber === EVENT_CREATION_COMPLETION_STATUS.LearnMore;

  return (
    <div className=" challengeNav bg-gray-light lg:bg-white lg:rounded-md pl-0 lg:pl-3 pt-0 lg:pt-4 lg:!p-[15px] lg:!pb-0 max-[1025px]:bg-white">
      {isPreview ? null : isEdit ? (
        <div className="flex space-x-2.5 rtl:space-x-reverse pb-[30px]">
          <PrimaryButton
            title=""
            handleClick={handlePreview}
            startIcon="eye"
            className="btn iconButton rounded-md hover:bg-primary-medium hover:!text-white bg-transparent border !border-primary-medium !text-primary-medium text-sm font-medium !min-w-[48px] !px-0 w-auto flex-0"
            disabled={isDisabled}
          />
          <TextButton
            title={"global.update"}
            handleClick={handleDraft}
            className="btn normal-case rounded-md w-full bg-secondary-medium border-none text-white text-sm font-medium  hover:bg-primary-medium hover:text-white flex-1"
            isDisabled={isDisabled}
          />
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-[10px] pb-[30px]">
          <TextButton
            title={"global.save_draft"}
            handleClick={handleDraft}
            className="btn normal-case rounded-md hover:bg-primary-medium hover:text-white w-full bg-transparent border border-primary-medium text-primary-medium text-sm font-medium"
            isDisabled={isDisabled}
          />
          <TextButton
            title={showPreview ? "organization.preview" : "global.next"}
            handleClick={handleNext}
            className="btn normal-case rounded-md w-full bg-secondary-medium border-none text-white text-sm font-medium  hover:bg-primary-medium hover:text-white"
            isDisabled={isDisabled}
          />
        </div>
      )}

      <div>
        <ProfileNavigationTimeline
          stepNumber={stepNumber}
          handleClick={handleNavigationClick}
          navigatorList={navigationItems}
          isViewOnly={isPreview ?? false}
          profileCompletionStatusId={completionStatusId}
          activeMenu={activeMenu}
        />
      </div>
    </div>
  );
};
