import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DefaultLoginImage from "assets/images/defaultLogin.png";
import {
  setCountryCode,
  setMasterData,
  setOrganizationTypes,
  setPermissions,
  setTitle,
} from "store/useMasterDataStore";
import { clearStores } from "store";
import {
  InputField,
  SubTitle,
  AppLogo,
  Title,
  TextButton,
  PrimaryButton,
  LoaderModal,
  Footer,
  LanguageSwitchButton,
} from "components";
import { setTokenInfo, setUserInfo } from "store/useUserDataStore";
import { getPublicMasterData } from "hooks/useGetPublicMasterData";
import { getMasterData } from "hooks/useGetMasterData";
import {
  showErrorToastMessage,
  showInfoToastMessage,
  showSuccessToastMessage,
  ToasterIconType,
} from "utils/toasterMessage";
import { loginSchemaValidation } from "utils/validation/authValidation";
import {
  ADMIN_PERMISSION_LIST,
  EMAIL_MAX_CHAR_LENGTH,
  PASSWORD_MAX_CHAR_LENGTH,
  USERS,
  USER_STATUS,
} from "utils/constants";
import { getOrganizationTypes } from "features/Admin/api/useGetOrganizationTypes";
import { useLogin, useValidateEmail } from "features/Login/api/useAuth";
import { setSelectedSideMenu } from "store/useSidebarStore";
import { getRolesAndPermissions } from "features/Admin/api/useGetRolesAndPermissions";

const Login = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { challengeId, collaborationId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const token = urlParams.get("token");
  const { mutateAsync: login, isLoading } = useLogin();

  const { mutateAsync: validateEmail, isLoading: isValidationLoading } = useValidateEmail();

  const prepareEmailVerificationData = () => {
    const data: EmailVerificationData = {
      email: email ? email?.trim() : "",
      token: token ? token?.trim() : "",
    };
    return data;
  };

  const validateEmailRequest = async () => {
    if (email && token) {
      try {
        const formData = prepareEmailVerificationData();
        const emailResponse = await validateEmail(formData);
        showSuccessToastMessage({
          message: emailResponse.message,
          position: i18n.language === "ar" ? "top-right" : "top-left",
        });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
          position: i18n.language === "ar" ? "top-right" : "top-left",
        });
      }
    }
  };
  useEffect(() => {
    if (email && token) validateEmailRequest();
  }, [email, token]);

  const initialState = {
    username: "",
    password: "",
  };

  const [loginFormData, setLoginFormData] = useState<LoginFormData>(initialState);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const value = event.target.value;
    setLoginFormData((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const prepareFormData = () => {
    const data: LoginFormData = {
      username: loginFormData.username.trim(),
      password: loginFormData.password.trim(),
    };
    return data;
  };

  const challengePreviewNavigation = (pathName: string) => {
    setSelectedSideMenu({
      type: "admin.challenges",
      isOpen: false,
    });
    navigate(pathName);
  };

  const collaborationPreviewNavigation = (pathName: string) => {
    setSelectedSideMenu({
      type: "global.collaborations",
      isOpen: false,
    });
    navigate(pathName);
  };

  const profileNavigation = (pathName: string) => {
    setSelectedSideMenu({
      type: "global.settings",
      isOpen: true,
      subMenuType: "global.profile",
    });
    navigate(pathName);
  };

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const error = loginSchemaValidation(loginFormData);
    if (error) {
      setValidationErrors(error);
      return;
    }
    setValidationErrors(null);
    clearStores();
    try {
      const formData = prepareFormData();
      const {
        email,
        expiresIn,
        firstName,
        lastName,
        refreshToken,
        token,
        tokenExpiresIn,
        userId,
        userName,
        freshLogin,
        userType,
        avatarUrl,
        profileStatusId,
        isGlobal,
        isAdvisor,
      } = await login(formData);
      setTokenInfo({ token, refreshToken, expiresIn, tokenExpiresIn });
      setUserInfo({
        email,
        firstName,
        lastName,
        userId,
        userName,
        freshLogin,
        userType,
        avatarUrl,
        profileStatusId,
        isGlobal,
        isAdvisor,
      });
      const masterData = await getMasterData();
      const publicMasterData = await getPublicMasterData();
      const organizationTypes = await getOrganizationTypes();

      if (userType !== USERS.Admin) {
        const permissions = await getRolesAndPermissions(userType);
        setPermissions(permissions);
      }

      if (masterData) {
        setMasterData(masterData);
      }
      if (publicMasterData) {
        setCountryCode(publicMasterData.countryCodes);
        setTitle(publicMasterData.title);
      }
      if (organizationTypes) {
        setOrganizationTypes(organizationTypes);
      }

      if (userType === USERS.Admin) {
        setPermissions(ADMIN_PERMISSION_LIST);
      }

      handleLoginNavigation(userType, profileStatusId, userId, freshLogin);
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
        position: i18n.language === "ar" ? "top-right" : "top-left",
      });
    }
  };

  const handleLoginNavigation = (
    userType: number,
    profileStatusId: number,
    userId: string,
    freshLogin: boolean
  ) => {
    if (userType === USERS.ResearchScholar) {
      if (challengeId && profileStatusId === USER_STATUS.Approved) {
        navigate(`/research-scholar/challenges`);
        challengePreviewNavigation(`/research-scholar/challenges/${challengeId}`);
      } else if (collaborationId && profileStatusId === USER_STATUS.Approved) {
        collaborationPreviewNavigation(`/research-scholar/collaborations/${collaborationId}`);
      } else if (profileStatusId === USER_STATUS.Approved) {
        setSelectedSideMenu({
          type: "research_scholar.home",
          isOpen: false,
        });
        navigate("/research-scholar/home");
      } else {
        profileNavigation(`/research-scholar/settings/profile/${userId}`);
      }
    } else if (userType === USERS.Admin) {
      if (challengeId) {
        navigate(`/admin/challenges`);
        challengePreviewNavigation(`/admin/challenges/${challengeId}`);
      } else {
        navigate("/admin/dashboard");
      }
    } else if (userType === USERS.Organization) {
      if (challengeId && profileStatusId === USER_STATUS.Approved) {
        navigate(`/organization/challenges/explore`);
        challengePreviewNavigation(`/organization/challenges/explore/${challengeId}`);
      } else if (collaborationId && profileStatusId === USER_STATUS.Approved) {
        navigate(`/organization/collaborations`);
        collaborationPreviewNavigation(`/organization/collaborations/${collaborationId}`);
      } else if (profileStatusId === USER_STATUS.Approved) {
        setSelectedSideMenu({
          type: "global.dashboard",
          isOpen: false,
        });
        navigate("/organization/dashboard");
      } else {
        profileNavigation(`/organization/settings/profile/${userId}`);
      }
    } else if (userType === USERS.Funding) {
      if (challengeId && profileStatusId === USER_STATUS.Approved) {
        navigate(`/funding-organization/challenges`);
        challengePreviewNavigation(`/funding-organization/challenges/${challengeId}`);
      } else if (profileStatusId === USER_STATUS.Approved) {
        setSelectedSideMenu({
          type: "research_scholar.home",
          isOpen: false,
        });
        navigate("/funding-organization/home");
      } else {
        profileNavigation(`/funding-organization/settings/profile/${userId}`);
      }
    } else if (userType === USERS.ResearchInstitute) {
      if (challengeId && profileStatusId === USER_STATUS.Approved) {
        navigate(`/research-institute/challenges`);
        challengePreviewNavigation(`/research-institute/challenges/${challengeId}`);
      } else if (collaborationId && profileStatusId === USER_STATUS.Approved) {
        navigate(`/research-institute/collaborations/explore`);
        collaborationPreviewNavigation(
          `/research-institute/collaborations/explore/${collaborationId}`
        );
      } else if (profileStatusId === USER_STATUS.Approved) {
        setSelectedSideMenu({
          type: "research_institute.requests",
          isOpen: false,
        });
        navigate("/research-institute/requests");
      } else {
        profileNavigation(`/research-institute/settings/profile/${userId}`);
      }
    }
    setTimeout(() => {
      if (freshLogin && userType !== USERS.Admin) {
        let message = "";
        if (userType === USERS.ResearchScholar) {
          message = t("global.welcome_toaster_message");
        } else if (userType === USERS.Organization) {
          message = t("organization.organization_fresh_login_welcome_message");
        } else if (userType === USERS.Funding) {
          message = t("funding_organization.funding_organization_welcome_message");
        } else if (userType === USERS.ResearchInstitute) {
          message = t("funding_organization.funding_organization_welcome_message");
        }
        showInfoToastMessage({
          iconType: ToasterIconType.WELCOME,
          message: message,
        });
      }
    }, 150);
  };

  return (
    <div className="h-screen min-w-screen flex overflow-hidden">
      <div className="h-full w-full">
        <div className="h-full w-full relative rtl:pl-[550px] ltr:pr-[550px] max-[1280px]:rtl:pl-[450px] max-[1280px]:ltr:pr-[450px] max-[1024px]:!p-0">
          <img
            src={DefaultLoginImage}
            alt="Login background"
            className="w-full h-full object-cover max-[1024px]:hidden"
          />
          {/*Login info section */}
          <div className="h-full bg-gradient-blueDark w-[551px] max-[1280px]:w-[451px]  absolute top-0 ltr:right-0 rtl:left-0 max-[1024px]:relative max-[1024px]:w-full">
            <div className="h-full w-full default-inputs-section">
              <div className="overflow-auto h-full">
                <div className="relative z-20 pt-3.5 ltr:pr-5 rtl:pl-5 mb-[65px] flex justify-end max-[1024px]:!p-5 max-[768px]:!px-5  max-[1024px]:!px-32 max-[1024px]:mb-0">
                  <LanguageSwitchButton className="language-selector min-w-[86px] h-[38px]" />
                </div>
                <div className="ltr:pr-[105px] rtl:pl-[105px] max-[1280px]:ltr:pr-[85px] max-[1280px]:rtl:pl-[85px] max-[768px]:!px-5 max-[1024px]:!px-32 relative z-10 h-[calc(100%-117px)]  max-[1024px]:h-[calc(100%-78px)] flex flex-col">
                  <div>
                    <AppLogo />
                    <div className="mt-[42px] max-[1024px]:mt-[20px]">
                      <Title
                        className="text-white text-xl font-semibold mb-8 max-[1024px]:text-base max-[1024px]:mb-5"
                        title={t("global.login")}
                      />
                      <form className="flex flex-col ">
                        <div className=" space-y-5">
                          <InputField
                            hintText={t("global.enter_email_address")}
                            labelText={t("global.email_address")}
                            handleInputChange={handleInputChange}
                            fieldName="username"
                            value={loginFormData.username}
                            errorMessage={validationErrors?.username}
                            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
                            className=" px-[18px]"
                          />
                          <InputField
                            hintText={t("global.enter_password")}
                            labelText={t("global.password")}
                            handleInputChange={handleInputChange}
                            fieldName="password"
                            value={loginFormData.password}
                            inputType="password"
                            errorMessage={validationErrors?.password}
                            maxCharLength={PASSWORD_MAX_CHAR_LENGTH}
                            className="px-[18px] ltr:pr-[35px] rtl:pl-[35px]"
                          />
                        </div>
                        <div className="mt-[56px] max-[1024px]:mt-[30px]">
                          <PrimaryButton
                            title={"global.login"}
                            handleClick={onSubmit}
                            className="default-primary-button w-full mb-[22px]"
                          />
                          <PrimaryButton
                            title={"global.forgot_password"}
                            handleClick={() => {
                              navigate("/forgot-password");
                            }}
                            className="default-secondary-button !border-secondary-medium  !text-secondary-medium hover:!bg-primary-extraDark hover:!text-white !bg-transparent w-full hover:!border-primary-extraDark "
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="mt-auto relative  z-10 pb-[30px] pt-[90px]  max-[1024px]:w-full  bg-primary-medium max-[1024px]:pt-[50px]">
                    <SubTitle
                      className="text-white text-sm mb-[47px] px-10 max-[1024px]:mb-5 max-[630px]:text-xs text-center font-normal"
                      title={t("research_scholar.sign_up_invite_text")}
                      childElement={
                        <TextButton
                          className=" font-medium text-sm text-secondary-medium underline ml-1 cursor-pointer"
                          title={"global.click_here"}
                          handleClick={() => {
                            navigate("/research-scholar/sign-up");
                          }}
                        />
                      }
                    />
                    <div>
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading || isValidationLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
};

export default Login;
