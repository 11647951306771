import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function triggerEmailNotification(formData: EmailNotificationRequest) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(urls.triggerEmailNotification(), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  
  return response?.data as ApiStatus | ApiError;
}

export function useTriggerEmailNotification() {
  return useMutation(async (FormData: EmailNotificationRequest) => {
    return await triggerEmailNotification(FormData);
  });
}
