import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getLearnMore(eventId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.get(urls.evenLearnMore(eventId), {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response?.data?.data as EventLearnMore | ApiError;
}

export function useGetLearnMore(eventId: number) {
  return useQuery(
    ["event-learn-more", eventId],
    async () => {
      if (eventId) {
        const result = await getLearnMore(eventId);
        return result;
      } else return null;
    },
    {
      staleTime: Infinity,
    }
  );
}
