import { SubTitle, Title } from "components";

type OfficeDetailsProps = {
  name: string;
  address: string;
};

export function OfficeDetailsCard({ name, address }: OfficeDetailsProps) {
  return (
    <div>
      <Title className=" text-black text-sm font-medium WB_BW" title={name} />

      <SubTitle
        className=" mt-1 flex items-center !justify-start text-gray-102 text-sm font-normal WB_BW"
        title={address}
      />
    </div>
  );
}
