import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveChallengeAdditionalInfo(
  formData: ChallengeAdditionalInfoSave,
  challengeId: number
) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(
    urls.challengeAdditonalInfoAndArtifacts(challengeId),
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus | ApiError;
}

export function useSaveChallengeAdditionalInfo() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      formData,
      challengeId,
    }: {
      formData: ChallengeAdditionalInfoSave;
      challengeId: number;
    }) => {
      const result = await saveChallengeAdditionalInfo(formData, challengeId);
      queryClient.invalidateQueries(["challenge-details", challengeId]);
      queryClient.invalidateQueries(["challenge-artifacts", challengeId]);
      queryClient.invalidateQueries(["challenge-preview-details", challengeId]);
      return result;
    }
  );
}
