import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SVGIcon from "assets/Icons";
import {
  InputField,
  SingleSelect,
  Chip,
  Title,
  TextButton,
  TextArea,
  ProfilePicture,
  MultiSelect,
  LoaderModal,
} from "components";
import { showProfilePicUploadModal } from "utils/confirmationModals";
import { phoneMaxLimitChecker, setMasterDataValue } from "utils/helpers";
import {
  DEFAULT_MAX_CHAR_LENGTH,
  EMAIL_MAX_CHAR_LENGTH,
  PAGE_LIMIT,
  PROFILE_DESCRIPTION_MAX_CHAR_LENGTH,
  URL_MAX_LENGTH,
  USER_STATUS,
  INITIAL_SEARCH_PARAMS,
} from "utils/constants";
import {
  setOrganizationInfo,
  setOrganizationInfoItem,
  useOrganizationDataStore,
} from "store/useOrganizationDataStore";
import { useMasterDataStore } from "store/useMasterDataStore";
import { useUserDataStore } from "store/useUserDataStore";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { useGetOrganizationTypes } from "features/Admin/api/useGetOrganizationTypes";
import { useGetOrganizationDetails } from "features/Organization/api/useGetOrganizationDetails";

type OrganizationDetailsProps = {
  validationErrors: Record<string, string> | null;
};

export default function OrganizationDetails({ validationErrors }: OrganizationDetailsProps) {
  const { t, i18n } = useTranslation();
  const { organizationId } = useParams();
  const { organizationInfo } = useOrganizationDataStore();
  const { countryCode, masterData } = useMasterDataStore();

  const { data: organizationTypes, isLoading: isOrganizationTypesLoading } =
    useGetOrganizationTypes();
  const { data: organizationData, isLoading: isOrganizationDataLoading } =
    useGetOrganizationDetails(organizationId ?? "");

  const isReadOnly = organizationData?.profileStatusId === USER_STATUS.PendingApproval;

  const handleRemove = async () => {
    if (organizationInfo && organizationInfo?.profileActualImage) {
      setOrganizationInfoItem(null, "profileActualImage");
    }
  };

  const handleProfileClick = async () => {
    await showProfilePicUploadModal({
      setProfileUrl: (url: string) => setOrganizationInfoItem(url, "profileActualImage"),
      url: organizationInfo?.profileActualImage ?? "",
      handleRemove,
    });
  };

  const countryCodeOptions = useMemo(() => {
    return countryCode?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [countryCode, i18n.language]);

  const organizationTypeOptions = useMemo(() => {
    return organizationTypes?.map((item) => {
      return {
        value: item.id ?? "",
        label: i18n.language === "ar" ? item?.name?.ar : item?.name?.en,
      };
    });
  }, [organizationTypes, i18n.language]);

  const countryOptions = useMemo(() => {
    return masterData?.country?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [masterData, i18n.language]);

  const handleInputChange =
    (key: keyof OrganizationDetails) =>
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event?.target?.value;
      setOrganizationInfoItem(value, key);
    };

  const handleSelect = (key: keyof OrganizationDetails) => (value: string | number) => {
    setOrganizationInfoItem(value, key);
  };

  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);

  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }
  
  const handleRemoveResearchArea = (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    if (organizationInfo?.researchArea) {
      const tempOptions = organizationInfo?.researchArea
        ? structuredClone(organizationInfo?.researchArea)
        : [];
      tempOptions.splice(index, 1);
      setOrganizationInfoItem(tempOptions, "researchArea");
      searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
    }
  };

  useEffect(() => {
    if (organizationData) {
      setOrganizationInfo(organizationData);
    }
  }, [organizationData, i18n.language]);

  useEffect(() => {
    searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
  }, [organizationInfo]);

  
  return (
    <div>
      <div className="p-5 flex items-center border-b border-gray-light">
        <SVGIcon name="personalDetails" />
        <Title
          className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
          title={t("organization.organization_details")}
        />
      </div>
      <div className="space-y-6 px-5 pt-6 pb-8 ">
        <div className=" flex flex-col bg-gray-light/60 py-[30px] justify-center rounded items-center mb-[34px]">
          <ProfilePicture
            url={organizationInfo?.profileActualImage ? organizationInfo.profileActualImage : ""}
            handleClick={handleProfileClick}
            disabled={isReadOnly}
          />
          <TextButton
            title={
              organizationInfo?.profileActualImage ? "global.change_image" : "global.upload_image"
            }
            className="text-primary-medium mt-6 text-sm"
            handleClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleProfileClick();
            }}
            isDisabled={isReadOnly}
          />
        </div>

        <InputField
          value={organizationInfo?.organizationName}
          hintText={t("organization.enter_organization_name")}
          labelText={t("organization.organization_name")}
          handleInputChange={handleInputChange("organizationName")}
          fieldName="organizationName"
          isRequired
          maxCharLength={EMAIL_MAX_CHAR_LENGTH}
          errorMessage={validationErrors?.organizationName}
          isDisabled={isReadOnly}
        />
        <TextArea
          textContent={organizationInfo?.about}
          handleChange={handleInputChange("about")}
          labelText={t("global.about")}
          hintText={t("global.tell_us_more_about_you")}
          fieldName="about"
          maxCharLength={PROFILE_DESCRIPTION_MAX_CHAR_LENGTH}
          errorMessage={validationErrors?.about}
          isDisabled={isReadOnly}
        />
        <MultiSelect
          labelText={t("global.research_area")}
          hintText={t("global.search")}
          setSelectedOptions={(options: Array<OptionItem> | null) => {
            setOrganizationInfoItem(options, "researchArea");
            searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
          }}
          selectedOptions={organizationInfo?.researchArea || []}
          // isDisabled={isReadOnly}
          addNew
          loadOptions={loadResearchAreaOptions}
          errorToastMessage={"global.create_research_area_min_error_message"}
          createLabel={"research_scholar.new_research_area"}
          resetKey={Math.random().toString()}
          isDisabled={isReadOnly}
          onMenuOpen={() => {
            searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
          }}
        />
        <div>
          {organizationInfo &&
          organizationInfo?.researchArea &&
          organizationInfo?.researchArea?.length > 0 ? (
            <div className=" flex  flex-wrap">
              {organizationInfo?.researchArea.map((item: OptionItem, i: number) => (
                <Chip
                  key={item.id ? item.id + "" : i + ""}
                  text={i18n.language === "ar" ? item.name.ar : item.name.en}
                  handleClose={(event: React.MouseEvent<HTMLElement>) =>
                    handleRemoveResearchArea(event, i)
                  }
                  disabled={isReadOnly}
                />
              ))}
            </div>
          ) : null}
        </div>
        <div className="relative w-full flex space-x-3 rtl:space-x-reverse">
          <SingleSelect
            selectOption={organizationInfo?.organizationTypeId}
            options={organizationTypeOptions || []}
            labelText={t("organization.organization_type")}
            hintText={t("global.search")}
            handleChange={handleSelect("organizationTypeId")}
            isRequired
            errorMessage={validationErrors?.organizationTypeId}
            isDisabled={isReadOnly}
            className="w-full"
          />
          <InputField
            value={organizationInfo?.tradeLicenseNumber}
            hintText={t("organization.enter_trade_license_number")}
            labelText={t("organization.trade_license_number")}
            handleInputChange={handleInputChange("tradeLicenseNumber")}
            fieldName="tradeLicenseNumber"
            isRequired
            errorMessage={validationErrors?.tradeLicenseNumber}
            isDisabled={isReadOnly}
            maxCharLength={DEFAULT_MAX_CHAR_LENGTH}
          />
        </div>
        <div className=" flex space-x-3 rtl:space-x-reverse">
          <SingleSelect
            selectOption={organizationInfo?.countryId}
            options={countryOptions || []}
            labelText={t("global.country")}
            hintText={t("global.select")}
            handleChange={handleSelect("countryId")}
            isRequired
            className=" w-full"
            errorMessage={validationErrors?.countryId}
            isDisabled={isReadOnly}
          />
          <InputField
            value={organizationInfo?.email}
            hintText={t("global.enter_email_address")}
            labelText={t("organization.contact_email")}
            handleInputChange={handleInputChange("email")}
            fieldName="email"
            isRequired
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
            errorMessage={validationErrors?.email}
            isDisabled
          />
        </div>
        <div className=" flex space-x-3 rtl:space-x-reverse">
          <SingleSelect
            selectOption={organizationInfo?.countryCodeId}
            options={countryCodeOptions || []}
            labelText={t("global.country_code")}
            hintText={t("global.select")}
            handleChange={handleSelect("countryCodeId")}
            isRequired
            className=" w-full"
            errorMessage={validationErrors?.countryCodeId}
            isDisabled={isReadOnly}
          />
          <InputField
            value={organizationInfo?.contactNumber}
            hintText={t("global.enter_contact_number")}
            labelText={t("global.contact_number")}
            handleInputChange={handleInputChange("contactNumber")}
            fieldName="contactNumber"
            isRequired
            maxCharLength={phoneMaxLimitChecker(organizationInfo?.contactNumber ?? "")}
            errorMessage={validationErrors?.contactNumber}
            isDisabled={isReadOnly}
          />
        </div>
        <div className=" flex space-x-3 rtl:space-x-reverse">
          <InputField
            value={organizationInfo?.linkedInUrl}
            hintText={t("research_scholar.enter_linkedIn_URL")}
            labelText={t("research_scholar.linked_in_url")}
            handleInputChange={handleInputChange("linkedInUrl")}
            fieldName="linkedInUrl"
            maxCharLength={URL_MAX_LENGTH}
            errorMessage={validationErrors?.linkedInUrl}
            isDisabled={isReadOnly}
            tooltipContent={[t("global.website_url_tooltip_message")]}
          />
          <InputField
            value={organizationInfo?.websiteUrl}
            hintText={t("global.enter_website_url")}
            labelText={t("global.website_url")}
            handleInputChange={handleInputChange("websiteUrl")}
            fieldName="websiteUrl"
            maxCharLength={URL_MAX_LENGTH}
            errorMessage={validationErrors?.websiteUrl}
            isDisabled={isReadOnly}
            tooltipContent={[t("global.website_url_tooltip_message")]}
          />
        </div>
      </div>
      {isOrganizationTypesLoading || isOrganizationDataLoading ? <LoaderModal /> : null}
    </div>
  );
}
