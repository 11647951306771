import { useTranslation } from "react-i18next";
import { Label } from "components";
import { CHALLENGE_STATUS } from "utils/constants";

type ChallengeStatusCardProps = {
  selectedChallengeId: number;
  title: string;
  description: string;
  currentStatus: number;
  selectedStatus: number;
  handleCardClick: (selectedChallengeId: number) => void;
  note?: string;
};

export default function ChallengeStatusCard({
  selectedChallengeId,
  title,
  description,
  currentStatus,
  selectedStatus,
  handleCardClick,
  note,
}: ChallengeStatusCardProps) {
  const { t } = useTranslation();

  const cardDisableChecker = () => {
    if (currentStatus === CHALLENGE_STATUS.Live && selectedChallengeId === CHALLENGE_STATUS.Open) {
      return "opacity-50 cursor-not-allowed";
    } else if (
      currentStatus === CHALLENGE_STATUS.Close &&
      (selectedChallengeId === CHALLENGE_STATUS.Open ||
        selectedChallengeId === CHALLENGE_STATUS.Live)
    ) {
      return "opacity-50 cursor-not-allowed";
    } else if (
      currentStatus === CHALLENGE_STATUS.Disable &&
      (selectedChallengeId === CHALLENGE_STATUS.Open ||
        selectedChallengeId === CHALLENGE_STATUS.Close ||
        selectedChallengeId === CHALLENGE_STATUS.Live)
    ) {
      return "opacity-50 cursor-not-allowed";
    } else {
      return "cursor-pointer";
    }
  };

  return (
    <div
      className={`w-full flex flex-col justify-start items-start text-left  px-4 py-4 pr-3 border-2 rounded-md border-[#E8F1F6]  ${
        selectedStatus === selectedChallengeId ? "current-status" : ""
      } ${cardDisableChecker()} `}
      onClick={() => handleCardClick(selectedChallengeId)}
    >
      <div className="flex justify-start items-center gap-3">
        <p className="font-semibold text-sm text-black mb-2">{title}</p>
        {currentStatus === selectedChallengeId ? (
          <Label
            text={t("organization.current_status")}
            className="bg-badge-open px-3 py-[6px] rounded-xl text-xs text-gray-107 mb-2"
          />
        ) : null}
      </div>
      <p className="text-gray-102 font-normal text-sm leading-normal">{description}</p>
      {selectedStatus === CHALLENGE_STATUS.Close ? (
        <p className="text-primary-light font-normal text-sm mt-1.5 leading-normal">{note}</p>
      ) : null}
    </div>
  );
}
