import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getCaseStudy(challengeId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  const response = await apiClient.get(urls.uploadCaseStudy(challengeId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data?.data as ApiStatus | ApiError;
}

export function useGetChallengeCaseStudy(challengeId: number, isEdit: boolean) {
  return useQuery(
    ["challenge-case-study", challengeId],
    async () => {
      if (challengeId && isEdit) {
        const result = await getCaseStudy(challengeId);
        return result;
      } else return;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
