import { SubTitle, Divider } from "components";

type DividerWithTextProps = {
  text: string;
};

export function DividerWithText({ text }: DividerWithTextProps) {
  return (
    <div className=" flex items-center -mx-4 lg:-mx-5 2xl:-mx-5 -mt-[36px] -mb-[7px] relative overflow-hidden">
      <div className=" flex items-center bg-white ltr:pl-5 ltr:pr-3 rtl:pr-5 rtl:pl-3">
        <SubTitle
          className="font-normal text-sm text-primary-medium subtitle whitespace-nowrap "
          title={text}
        />
      </div>
      <Divider className=" w-full !border-gray-light mt-[2px] !mb-0 " />
    </div>
  );
}
