type RadioButtonProps = {
  label: string;
  value: number;
  className: string;
  onChange: () => void;
  disabled?: boolean;
  errorMessage?: string;
  checked?: boolean;
};

export function RadioButton({
  className,
  label,
  value,
  checked,
  disabled,
  onChange,
  errorMessage,
}: RadioButtonProps) {
  return (
    <label
      className={`radioButton flex items-center cursor-pointer ${className} ${
        errorMessage ? "radioError " : ""
      }`}
    >
      <input
        type="radio"
        value={value}
        className="radio"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span>{label}</span>
    </label>
  );
}
