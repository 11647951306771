import { create } from "zustand";
import { produce } from "immer";
import { USER_STATUS } from "utils/constants";

export const HIGHEST_EDUCATION_INITIAL_STATE: HighestEducation = {
  id: 0,
  instituteName: "",
  courseName: "",
};

export const educationInitState: EducationDetails = {
  details: [{ ...HIGHEST_EDUCATION_INITIAL_STATE }],
  stillStudying: false,
  profileStatusId: USER_STATUS.Draft,
  profileCompletionStatusId: 1,
};

export const WORK_EXPERIENCE_INITIAL_STATE: WorkExperience = {
  id: 0,
  companyName: "",
  companyCountryId: 0,
  companyAddress: "",
};

const workExperienceInitState: WorkExperienceDetails = {
  details: [],
  notWorking: false,
  profileStatusId: USER_STATUS.Draft,
  profileCompletionStatusId: 2,
};

const socialInitialState: SocialProfile = {
  googleScholarURL: "",
  linkedInURL: "",
  researchGateURL: "",
  hIndex: 0,
  profileStatusId: USER_STATUS.Draft,
  profileCompletionStatusId: 3,
};

export type ScholarProfileDataStore = {
  personInfo?: PersonalDetails | undefined;
  educationInfo: EducationDetails | undefined;
  workInfo: WorkExperienceDetails | undefined;
  socialInfo: SocialProfile | undefined;
  researchProjectsInfo: Array<ResearchProject> | undefined;
};

export const useScholarProfileDataStore = create<ScholarProfileDataStore>()(() => ({
  personInfo: undefined,
  educationInfo: educationInitState,
  workInfo: workExperienceInitState,
  socialInfo: socialInitialState,
  researchProjectsInfo: undefined,
}));

export function setPersonalInfo(personInfo: PersonalDetails) {
  useScholarProfileDataStore.setState(
    produce((state: ScholarProfileDataStore) => {
      state.personInfo = personInfo;
    })
  );
}

export function setEducationInfo(educationInfo: EducationDetails) {
  useScholarProfileDataStore.setState(
    produce((state: ScholarProfileDataStore) => {
      state.educationInfo = educationInfo;
    })
  );
}

export function setWorkInfo(workInfo: WorkExperienceDetails) {
  useScholarProfileDataStore.setState(
    produce((state: ScholarProfileDataStore) => {
      state.workInfo = workInfo;
    })
  );
}

export function setPersonalInfoItem(value: any, key: keyof PersonalDetails) {
  useScholarProfileDataStore.setState(
    produce((state: any) => {
      if (state.personInfo) {
        state.personInfo[key] = value;
      }
    })
  );
}

export function setEducationInfoItem(
  value: string | boolean,
  key: keyof HighestEducation | "stillStudying",
  index = 0
) {
  useScholarProfileDataStore.setState(
    produce((state) => {
      if (state.educationInfo) {
        if (key === "stillStudying") {
          state.educationInfo[key] = value;
        } else {
          state.educationInfo.details[index][key] = value;
        }
      }
    })
  );
}

export function setWorkInfoItem(
  value: string | number | boolean,
  key: keyof WorkExperience | "notWorking",
  index = 0
) {
  useScholarProfileDataStore.setState(
    produce((state) => {
      if (state.workInfo) {
        if (key === "notWorking") state.workInfo[key] = value;
        else state.workInfo.details[index][key] = value;
      }
    })
  );
}

export function setSocialInfoItem(value: any, key: keyof SocialProfile) {
  useScholarProfileDataStore.setState(
    produce((state: any) => {
      if (state.socialInfo) {
        state.socialInfo[key] = value;
      }
    })
  );
}

export function setSocialInfo(socialInfo: SocialProfile) {
  useScholarProfileDataStore.setState(
    produce((state: any) => {
      state.socialInfo = socialInfo;
    })
  );
}

export function setResearchProjectsInfo(researchProjectsInfo: Array<ResearchProject>) {
  useScholarProfileDataStore.setState(
    produce((state: ScholarProfileDataStore) => {
      state.researchProjectsInfo = researchProjectsInfo;
    })
  );
}

export function setResearchProjectsInfoItem(value: any, key: string, index: number) {
  useScholarProfileDataStore.setState(
    produce((state: any) => {
      if (state.researchProjectsInfo) {
        state.researchProjectsInfo[index][key] = value;
      }
    })
  );
}

export function clearScholarProfileDataStore() {
  useScholarProfileDataStore.setState(
    produce((state: ScholarProfileDataStore) => {
      state.personInfo = undefined;
      state.educationInfo = undefined;
      state.workInfo = undefined;
      state.socialInfo = undefined;
      state.researchProjectsInfo = undefined;
    })
  );
}
