import Joi from "joi";
import { groupBy } from "utils/helpers";

const socialProfileSchema = Joi.object({
  googleScholarURL: Joi.string().uri().optional().allow("", null).messages({
    "string.uri": "global.please_enter_a_valid_url",
  }),
  linkedInURL: Joi.string()
    .uri()
    .optional()
    .allow("", null)
    .messages({ "string.uri": "global.please_enter_a_valid_url" }),
  researchGateURL: Joi.string()
    .uri()
    .optional()
    .allow("", null)
    .messages({ "string.uri": "global.please_enter_a_valid_url" }),
  hIndex: Joi.number().positive().allow(null, "").max(10000).messages({
    "number.base": "global.please_ensure_to_enter_positive_integer_value",
    "number.positive": "global.please_ensure_to_enter_positive_integer_value",
    "number.max": "global.please_specify_a_value_below_10,000",
    "number.unsafe": "global.please_specify_a_value_below_10,000",
  }),
  profileStatusId: Joi.number().optional().default(1).allow(null),
  profileCompletionStatusId: Joi.number().required().default(0).allow(null),
}).options({ abortEarly: false });

export const socialProfileValidation = (socialProfile: SocialProfile) => {
  const result = socialProfileSchema.validate(socialProfile);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
