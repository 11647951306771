import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InlineLoader, InvitesItem, LoaderModal, NoData } from "components";
import {
  COLLABORATION_USER_STATUS,
  RESEARCH_SCHOLAR_INVITES_PARAM,
  USERS,
} from "utils/constants";
import { getTimeDifference } from "utils/helpers";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import { useUserDataStore } from "store/useUserDataStore";
import { useGetCollaborationResearchInvites } from "features/Collaboration/api/useGetCollaborationInvites";
import { useCollaborationUserInviteUpdate } from "features/Collaboration/api/useCollaborationUserInviteUpdate";

const CollaborationResearchScholarInvites = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { userInfo } = useUserDataStore();
  const [researchScholarInviteParams, setRequestParamsInviteParams] =
    useState<ResearchInviteParams>(RESEARCH_SCHOLAR_INVITES_PARAM);

  const {
    data: invitations,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetCollaborationResearchInvites(researchScholarInviteParams);

  const { mutateAsync: updateInvite, isLoading: isUpdating } = useCollaborationUserInviteUpdate();

  const invitationsList = useMemo(() => {
    const tempData: CollaborationInvitationListingResponse = { collaborations: [], totalCount: 0, totalPages: 0 };
    if (invitations?.pages && invitations?.pages[0]) {
      tempData.totalCount = invitations.pages[0]?.totalCount;
      tempData.totalPages = invitations.pages[0]?.totalPages;
      const collaborations = invitations.pages
        .map((item) => {
          return item.collaborations;
        })
        .flat();
      tempData.collaborations = collaborations;
    }
    return tempData;
  }, [invitations]);
  const locale = i18n.language === "en" ? "en" : "ar";
  const handleAcceptInvite = async (collaborationId: number) => {
    try {
      const result = await updateInvite({
        collaborationId: collaborationId,
        statusId: COLLABORATION_USER_STATUS.Approved,
      });
      if (result) {
        showInfoToastMessage({
          iconType: ToasterIconType.UPDATE,
          message: result.data.message,
        });
      }
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };
  const handleDeclineInvite = async (collaborationId: number) => {
    try {
      const result = await updateInvite({
        collaborationId: collaborationId,
        statusId: COLLABORATION_USER_STATUS.Declined,
      });
      if (result) {
        showInfoToastMessage({
          iconType: ToasterIconType.UPDATE,
          message: result.data.message,
        });
      }
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };
  const handleNavigateToCollaborationDetails = (collaborationId: number) => {
    if (userInfo?.userType === USERS.ResearchScholar) {
      navigate(`/research-scholar/collaborations/${collaborationId}`);
    } 
  };
  return (
    <div className="invite-notification">
      {invitationsList?.collaborations?.length > 0 ? (
        <InfiniteScroll
          dataLength={invitationsList?.collaborations?.length}
          next={() => {
            fetchNextPage();
          }}
          hasMore={!!hasNextPage}
          loader={hasNextPage || isLoading ? <InlineLoader /> : null}
          scrollThreshold="200px"
        >
          {invitationsList?.collaborations?.map((item, index) => (
            <div key={index}>
              <InvitesItem
                imageUrl={item?.avatarImageUrl}
                title={item?.collaborationName}
                date={getTimeDifference(item?.createdOn, locale, t)}
                challengeId={item?.collaborationId}
                handleAcceptInvite={handleAcceptInvite}
                handleDeclineInvite={handleDeclineInvite}
                handleClick={() => {
                  handleNavigateToCollaborationDetails(item?.collaborationId);
                }}
              />
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <div>
          <NoData message={"admin.no_challenge_invites"} />
        </div>
      )}
      {isUpdating ? <LoaderModal /> : null}
    </div>
  );
};
export default CollaborationResearchScholarInvites;
