import { useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { SecondaryButton, PrimaryButton, LoaderModal } from "components";
import ChallengeStatusCard from "features/Organization/Challenge/ViewChallenge/UpdateChallengeStatus/ChallengeStatusCard";
import { CHALLENGE_STATUS } from "utils/constants";
import { showConfirmationModal } from "utils/confirmationModals";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { useUpdateCollaborationStatus } from "features/Collaboration/api/useUpdateCollaborationStatus";

type UpdateCollaborationStatusProps = {
  onClose: () => void;
  collaborationStatus: number;
  collaborationId: number;
};

const UpdateCollaborationStatus = ({
  onClose,
  collaborationStatus,
  collaborationId,
}: UpdateCollaborationStatusProps) => {
  const { t } = useTranslation();

  const [selectedStatus, setSelectedStatus] = useState<number>(collaborationStatus);
  const [currentStatus, setCurrentStatus] = useState<number>(collaborationStatus);
  const { mutateAsync: updateStatus, isLoading: isStatusChangeLoading } =
    useUpdateCollaborationStatus();

  const CollaborationStatusCardItems = [
    {
      id: CHALLENGE_STATUS.Open,
      title: t("organization.open"),
      description: t("research_institute.open_collaboration_description"),
    },
    {
      id: CHALLENGE_STATUS.Close,
      title: t("global.close"),
      description: t("research_institute.close_collaboration_description"),
    },
    {
      id: CHALLENGE_STATUS.Disable,
      title: t("admin.disable"),
      description: t("research_institute.disable_collaboration_description"),
    },
  ];

  const handleStatusUpdate = async (status: number) => {
    try {
      const result = await updateStatus({
        status: status,
        collaborationId: collaborationId,
      });
      setCurrentStatus(status);
      showSuccessToastMessage({
        message: result.message,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const handleCardClick = async (selectedCollaborationId: number) => {
    if (
      currentStatus === CHALLENGE_STATUS.Close &&
      selectedCollaborationId === CHALLENGE_STATUS.Open
    ) {
      return;
    } else if (
      currentStatus === CHALLENGE_STATUS.Disable &&
      (selectedCollaborationId === CHALLENGE_STATUS.Open ||
        selectedCollaborationId === CHALLENGE_STATUS.Close)
    ) {
      return;
    } else {
      setSelectedStatus(selectedCollaborationId);
    }
  };

  const handleUpdateClick = async () => {
    if (selectedStatus === currentStatus) {
      return;
    } else {
      if (selectedStatus === CHALLENGE_STATUS.Close) {
        const result = await showConfirmationModal({
          title: "organization.confirm_update",
          message: "research_institute.collaboration_close_status_confirmation_message",
          cancelButtonText: "global.close",
          proceedButtonText: "global.update",
          isAlert: true,
        });
        if (!result) {
          return;
        }
        if (result) {
          handleStatusUpdate(CHALLENGE_STATUS.Close);
        }
      } else if (selectedStatus === CHALLENGE_STATUS.Disable) {
        const result = await showConfirmationModal({
          title: "organization.confirm_update",
          message: "research_institute.collaboration_disabled_status_confirmation_message",
          cancelButtonText: "global.close",
          proceedButtonText: "global.update",
          isAlert: true,
        });
        if (!result) {
          return;
        }
        if (result) {
        handleStatusUpdate(CHALLENGE_STATUS.Disable);
        }
      }
    }
  };
  return (
    <>
      <div className=" max-w-[100vw] w-[100vw] min-w-[100vw]  md:w-auto md:max-w-[470px] mx-0 md:mx-2  md:min-w-[470px]  bg-white rounded-md rounded-b-none md:rounded-b-md px-4 pb-4 pt-12 md:pt-[50px]  md:p-[50px] md:pb-[50px] md:!px-8 sm:px-[50px] sm:p-[50px] flex flex-col  relative text-center animate__animated animate__zoomIn max-h-[calc(100vh-50px)] overflow-auto">
        <div className=" flex justify-center flex-col items-center">
          <SVGIcon name="updateChallengeStatus" className="h-[77px] w-[77px]" />
          <h3 className="mt-[41px] text-black font-semibold text-xl">
            {t("research_institute.update_collaboration_status")}
          </h3>{" "}
          <p className="font-normal text-base text-primary-text text-center mx-8 mb-10">
            {t("research_institute.update_collaboration_status_description")}
          </p>
        </div>
        <div className="">
          <div className="flex flex-col gap-2">
            <div className="space-y-3">
              {CollaborationStatusCardItems?.map((item: ChallengeStatusCardItem) => (
                <ChallengeStatusCard
                  key={item?.id}
                  selectedChallengeId={item?.id}
                  title={item?.title}
                  description={item?.description}
                  note={item?.note}
                  currentStatus={currentStatus}
                  selectedStatus={selectedStatus}
                  handleCardClick={(selectedCollaborationId: number) =>
                    handleCardClick(selectedCollaborationId)
                  }
                />
              ))}
            </div>
            <div className="flex justify-center items-center mt-[47px] gap-2">
              <div className="w-[120px]">
                <SecondaryButton title={"global.close"} handleClick={onClose} className="w-full" />
              </div>
              <div className="w-[120px]">
                <PrimaryButton
                  title={"global.update"}
                  handleClick={handleUpdateClick}
                  className="w-full"
                  disabled={currentStatus === selectedStatus}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isStatusChangeLoading ? <LoaderModal /> : null}
    </>
  );
};
export default UpdateCollaborationStatus;
