import React, { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SubTitle, Title, InlineLoader } from "components";
import { MemoizedSliderCard } from "features/Home/CaseStudies/SliderCard";
import { useGetLatestCaseStudies } from "features/Home/api/useGetLatestCaseStudy";
import { isGlobalWebsite } from "utils/helpers";

const CaseStudies = forwardRef(function CaseStudies(_, ref: React.Ref<HTMLDivElement>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isGlobal = isGlobalWebsite();
  const { data: caseStudies, isLoading } = useGetLatestCaseStudies(isGlobal);

  const handleNavigateToLogin = useMemo(() => {
    return (id: number) => {
      navigate(`/login/challenges/${id}`);
    };
  }, [navigate]);

  return (
    <>
      {caseStudies?.length > 0 ? (
        <div className="w-full h-full bg-skin-bgLight" ref={ref}>
          <div className=" py-10 xl:py-[60px] max-[1023px]:pb-[25px]">
            <div className="home-container">
              <SubTitle
                title={t("home.featured_and_latest")}
                className=" relative font-semibold text-skin-textSecondary01 uppercase !justify-start text-xs leading-4  md:text-xl md:leading-[26px] "
              />
              <Title
                title={t("home.case_studies")}
                className=" relative font-semibold text-skin-primaryDark text-[27px] leading-[34px] md:text-[43px] md:leading-[54px]"
              />
            </div>
            <div className="mt-10 xl:mt-[45px] max-[1023px]:mt-[17px] max-[1023px]:mb-0 relative w-full case-studies-slider case-studies-slider-container max-[1023px]:overflow-auto ">
              <div className="grid grid-cols-3 gap-[30px] max-[1023px]:gap-[20px]  max-[1023px]:pr-5 max-[1023px]:w-[951px]">
                {caseStudies.map((item: CaseStudy) => (
                  <MemoizedSliderCard
                    key={item.id}
                    coverImage={item.coverImage}
                    title={item.title}
                    description={item.description}
                    researchArea={item.researchArea}
                    challengeId={item.challengeId}
                    handleClick={handleNavigateToLogin}
                  />
                ))}
              </div>
            </div>
          </div>
          {isLoading ? <InlineLoader /> : null}
        </div>
      ) : null}
    </>
  );
});

export default CaseStudies;
