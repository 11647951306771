import { Routes, Route } from "react-router-dom";
import { PageNotFound } from "components";
import AppOutlet from "components/AppOutlet";

import { PrivateRoute } from "router/PrivateRoute";
import ChangePassword from "features/ChangePassword";
import SearchList from "features/GlobalSearch/SearchList";

/* Research Scholar */
import ResearchScholarPreview from "features/Organization/Challenge/ViewChallenge/ResearchScholarPreview";

/* Admin */
import ResearchScholarInfo from "features/Admin/Users/ResearchScholars/ResearchScholarInfo";
import OrganizationInfo from "features/Admin/Users/Organizations/OrganizationInfo";
import FundingOrganizationInfo from "features/Admin/Users/FundingOrganizations/FundingOrganizationInfo";

/*Organization */
import Organization from "features/Organization";
import OrganizationProfile from "features/Organization/Profile";
import OrganizationUsers from "features/Organization/Users";
import OrganizationDashboard from "features/Organization/Dashboard";
import CreateChallenge from "features/Organization/Challenge/CreateChallenge";
import ChallengeDetails from "features/Organization/Challenge/ViewChallenge/ChallengeDetails";
import ExploreChallenge from "features/Organization/Challenge/ExploreChallenge";
import ChallengeInviteHistory from "features/Organization/Challenge/InviteHistory";

/* Research Institute */
import ResearchInstituteInfo from "features/Admin/Users/ResearchInstitute/ResearchInstituteInfo";

/* Challenge */
import ChallengeDashboard from "features/Challenge/Dashboard";
import ChallengeDetailsPreview from "features/Challenge/Preview";

/* Events */
import EventDashboard from "features/Events/Dashboard";
import EventPreview from "features/Events/EventPreview";

/*Collaborations*/
import CollaborationListing from "features/Collaboration/CollaborationListing";
import CollaborationDetailsPreview from "features/Collaboration/CollaborationDetailsPreview";

import DiscussionForum from "features/DiscussionForum";
import Messages from "features/Messages";
import CollaborationDiscussionForum from "features/Collaboration/DiscussionForum";

export default function OrganizationRoutes() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <Organization />
          </PrivateRoute>
        }
      >
        <Route path="dashboard" element={<OrganizationDashboard />} index />
        <Route
          path="challenges"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<ChallengeDashboard />} index />
          <Route
            path="explore"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ExploreChallenge />} index />
            <Route
              path=":challengeId"
              element={
                <PrivateRoute>
                  <AppOutlet />
                </PrivateRoute>
              }
            >
              <Route element={<ChallengeDetailsPreview />} index />
              <Route path="discussion-forum" element={<DiscussionForum />} />
            </Route>
            <Route path="create" element={<CreateChallenge />} />
          </Route>
          <Route path="create" element={<CreateChallenge />} />
          <Route path="create/:challengeId" element={<CreateChallenge />} />
          <Route
            path=":challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route path="update" element={<CreateChallenge />} />
            <Route element={<ChallengeDetails />} index />
            <Route path="preview" element={<ChallengeDetailsPreview />}/>
            <Route path="discussion-forum" element={<DiscussionForum />} />
            <Route
              path="invite-history"
              element={
                <PrivateRoute>
                  <AppOutlet />
                </PrivateRoute>
              }
            >
              <Route element={<ChallengeInviteHistory />} index />
              <Route path="discussion-forum" element={<DiscussionForum />} />
            </Route>
            <Route
              path="explore"
              element={
                <PrivateRoute>
                  <AppOutlet />
                </PrivateRoute>
              }
            >
              <Route element={<ChallengeDetails />} index />
              <Route
                path="research-scholar/:researchScholarId"
                element={<ResearchScholarPreview />}
              />
              <Route
                path="invite-history"
                element={
                  <PrivateRoute>
                    <AppOutlet />
                  </PrivateRoute>
                }
              >
                <Route element={<ChallengeInviteHistory />} index />
                <Route path="discussion-forum" element={<DiscussionForum />} />
              </Route>
              <Route path="discussion-forum" element={<DiscussionForum />} />
            </Route>
            <Route
              path="research-scholar/:researchScholarId"
              element={<ResearchScholarPreview />}
            />
          </Route>
        </Route>
        <Route path="messages" element={<Messages />} />
        <Route
          path="settings"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route path="users" element={<OrganizationUsers />} />
          <Route path="profile/:organizationId" element={<OrganizationProfile />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
        <Route
          path="search-results"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<SearchList />} index />
          <Route
            path="challenges/:challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ChallengeDetailsPreview />} index />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>
          <Route path="challenges/view-challenge/:challengeId" element={<ChallengeDetails />} />
          <Route path="research-scholar/:researchScholarId" element={<ResearchScholarInfo />} />
          <Route path="organization/:organizationId" element={<OrganizationInfo />} />
          <Route
            path="funding-organization/:fundingOrganizationId"
            element={<FundingOrganizationInfo />}
          />
          <Route
            path="research-institute/:researchInstituteId"
            element={<ResearchInstituteInfo />}
          />
        </Route>
        <Route
          path="events"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route index element={<EventDashboard />} />
          <Route path=":eventId" element={<EventPreview />} />
        </Route>
        <Route
          path="collaborations"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route index element={<CollaborationListing />} />

          <Route
            path=":collaborationId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route index element={<CollaborationDetailsPreview />} />
            <Route path="discussion-forum" element={<CollaborationDiscussionForum />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
