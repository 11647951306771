import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import challengeDefault from "assets/images/challengeDefaultProfile.png";
import {
  Header,
  Label,
  Descriptor,
  PrimaryButton,
  Title,
  TableLoader,
  NoData,
  LoaderModal,
} from "components";
import { useNavigate, useParams } from "react-router-dom";
import { displayDateFormat, getChallengeStatusTitle, getMasterDataValue } from "utils/helpers";
import Table from "features/Organization/Challenge/InviteHistory/Table";
import { CHALLENGE_TRACK_INVITE_PARAMS, INVITE_HISTORY_TABLE_HEADERS } from "utils/constants";
import { useStickyScroll } from "hooks/useStickyScroll";
import { useGetChallengeInviteHistory } from "features/Organization/api/useGetInviteHistory";
import { useGetChallengeBasicDetails } from "features/Organization/api/useGetChallengeBasicDetails";
import { useMasterDataStore } from "store/useMasterDataStore";
import { useUserDataStore } from "store/useUserDataStore";
import { setSelectedSideMenu } from "store/useSidebarStore";

const ChallengeInviteHistory = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { permissions } = useMasterDataStore();
  const pathName = window.location.pathname;
  const isExplore = pathName.includes("explore");
  const { scroll, scrollValue } = useStickyScroll(12);
  const { challengeId } = useParams();
  const pathname = window.location.pathname;
  const [requestParams, setRequestParams] = useState<ChallengeTrackInviteParams>({
    ...CHALLENGE_TRACK_INVITE_PARAMS,
    challengeId: Number(challengeId ?? 0),
  });
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const { userInfo } = useUserDataStore();

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const { data: challengeData, isLoading: isLoadingChallengeData } = useGetChallengeBasicDetails(
    Number(challengeId),
    isExplore
  );
  const {
    data: challengeHistoryData,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetChallengeInviteHistory(requestParams);

  const challengeTrackInvite = useMemo(() => {
    const tempData: ChallengeTrackInvites = {
      data: [],
      totalCount: 0,
      totalPages: 0,
    };
    if (challengeHistoryData?.pages && challengeHistoryData?.pages[0]) {
      tempData.totalCount = challengeHistoryData.pages[0]?.totalCount;
      tempData.totalPages = challengeHistoryData.pages[0]?.totalPages;
      const data = challengeHistoryData.pages
        .map((item) => {
          return item.data;
        })
        .flat();
      tempData.data = data;
    }
    return tempData;
  }, [challengeHistoryData]);

  const getChallengeTags = () => {
    const tags = challengeData?.challengeTags?.map((challenge) => {
      return i18n.language === "ar" ? challenge?.name.ar : challenge?.name.en;
    });
    return tags?.length ? tags?.toString().replace(/,/g, ", ") : "";
  };

  const getBreadCrumbPath = (): Array<LinkItem> => {
    if (pathname.includes("explore")) {
      return [
        {
          title: "organization.explore_challenge",
        },
        {
          title: "organization.view_challenge",
        },
        {
          title: "organization.invite_history",
        },
      ];
    } else {
      return [
        {
          title: "admin.challenges",
        },
        {
          title: "organization.view_challenge",
        },
        {
          title: "organization.invite_history",
        },
      ];
    }
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "admin.challenges",
    });
  }, []);

  return (
    <div
      className={`w-full h-full flex flex-col  ${!isOpenSideMenu ? "miniSidebar" : ""} ${
        scrollValue > 0 ? "window-scroll" : ""
      }`}
    >
      <Header
        linkList={getBreadCrumbPath()}
        title={challengeData?.title ?? ""}
        handleCollapseClick={onToggleClick}
        className={
          scroll
            ? " bg-white animate__animated animate__fadeInDown !py-[26px] !h-[90px] fixed stickyHeader z-[41]"
            : "fixed"
        }
        displayGlobalTag={challengeData?.isGlobal && !userInfo?.isGlobal}
      />
      <div className="adminDetails w-full pt-[118px] pb-[45px] items-baseline px-10 ltr:pr-[30px] rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto ">
        <div className="w-full flex flex-col h-full space-y-3">
          {/* card of challenge details */}
          <div
            className={
              scroll
                ? " bg-white rounded-md p-4 flex challengeInfo sticky animate__animated animate__fadeInUp shadow-sticky  z-40"
                : "bg-white rounded-md p-4 flex challengeInfo relative z-40"
            }
          >
            <div className="  min-w-[120px] max-w-[120px] h-[130px] rounded-md overflow-hidden imageHolder">
              <img
                alt="profile picture"
                className=" w-full h-[130px] object-cover "
                src={
                  challengeData && challengeData?.coverImage?.length > 0
                    ? challengeData?.coverImage
                    : challengeDefault
                }
              />
            </div>
            <div className=" px-5 dataHolder overflow-hidden">
              <div className="flex gap-4 items-center truncate">
                <div className=" flex badgeBLock space-x-2 rtl:space-x-reverse">
                  <Label
                    className=" !w-auto text-gray-107 text-xs bg-green-500 rounded-xl px-3 py-1.5 "
                    text={t(getChallengeStatusTitle(challengeData?.challengeStatusId))}
                  />
                  <Label
                    className=" !w-auto text-gray-107 text-xs bg-green-500 rounded-xl px-3 py-1.5 "
                    text={t(
                      challengeData?.isPrivate ? "organization.private" : "organization.public"
                    )}
                  />
                </div>
                <div className=" h-[14px] bg-gray-200 w-[1px]"></div>

                <div className="truncate">
                  <Label
                    className=" !w-auto text-gradient-blueDark text-xs !block bg-primary-500 rounded-xl px-3 py-1.5  h-[30px] truncate max-w-[200px]"
                    text={t(
                      challengeData?.targetIndustry
                        ? getMasterDataValue(challengeData?.targetIndustry, i18n.language)
                        : ""
                    )}
                  />
                </div>
              </div>
              <div className=" flex flex-col space-y-3 pt-3 dataWrapper truncate">
                <div className="flex space-x-10 rtl:space-x-reverse truncate sessionOne ">
                  <Descriptor
                    title={challengeData?.location ?? ""}
                    startIcon="mapPin"
                    className="info !mb-0 min-w-[176px] flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse truncate"
                  />
                </div>
                <div className="flex space-x-10 rtl:space-x-reverse truncate sessionTwo">
                  <Descriptor
                    title={`${t("global.start_date")}: ${displayDateFormat(
                      challengeData?.startDate ?? ""
                    )}`}
                    startIcon="date"
                    className="info !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW date"
                  />
                  <Descriptor
                    title={`${t("global.end_date")}: ${displayDateFormat(
                      challengeData?.endDate ?? ""
                    )}`}
                    startIcon="date"
                    className="info !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
                  />
                </div>
                <div className="flex space-x-10 rtl:space-x-reverse truncate sessionThree">
                  {challengeData?.challengeTags?.length ? (
                    <Descriptor
                      title={getChallengeTags()}
                      startIcon="tag"
                      className="info !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW truncate"
                    />
                  ) : null}
                </div>
              </div>
            </div>
            {permissions?.Viewpost && challengeData?.isDiscussionForumEligible ? (
              <div className="buttonHolder ltr:ml-auto rtl:mr-auto flex min-w-[155px] justify-end ">
                <PrimaryButton
                  title={"forum.discussion_forum"}
                  handleClick={() => {
                    navigate("discussion-forum");
                  }}
                  className=" !h-[38px] min-h-max"
                />
              </div>
            ) : null}
          </div>
          <div className=" pt-4">
            <Title
              title={t("organization.invite_history")}
              className=" text-base text-black font-semibold mb-4"
            />
            {challengeTrackInvite &&
            challengeTrackInvite.data.length > 0 &&
            challengeTrackInvite.totalPages &&
            challengeTrackInvite.totalCount > 0 ? (
              <InfiniteScroll
                dataLength={challengeTrackInvite?.data?.length}
                next={fetchNextPage}
                hasMore={!!hasNextPage}
                loader={hasNextPage || isLoading ? <TableLoader /> : null}
                scrollThreshold="200px"
              >
                <Table
                  challengeData={challengeTrackInvite.data ?? []}
                  headers={INVITE_HISTORY_TABLE_HEADERS}
                />
              </InfiniteScroll>
            ) : (
              <div>
                <NoData message="admin.no_data_to_show" />
              </div>
            )}
          </div>
        </div>
        {isLoadingChallengeData ? <LoaderModal /> : null}
      </div>
    </div>
  );
};
export default ChallengeInviteHistory;
