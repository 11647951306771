import { create } from "zustand";
import { produce } from "immer";
import { persist } from "zustand/middleware";

export type UseMasterDataStore = {
  masterData: MasterData | undefined;
  countryCode: Array<CountryCodeItem> | undefined;
  title: Array<MasterDataItem> | undefined;
  organizationTypes: Array<MasterDataItem> | undefined;
  permissions: PermissionList | undefined;
};

export const useMasterDataStore = create<UseMasterDataStore>()(
  persist(
    (set) => ({
      masterData: undefined,
      countryCode: undefined,
      title: undefined,
      organizationTypes: undefined,
      permissions: undefined,
    }),
    {
      name: "master-data",
      getStorage: () => sessionStorage,
    }
  )
);

export function setMasterData(masterData: MasterData) {
  useMasterDataStore.setState(
    produce((state: UseMasterDataStore) => {
      state.masterData = masterData;
    })
  );
}

export function setCountryCode(countryCode: Array<CountryCodeItem>) {
  useMasterDataStore.setState(
    produce((state: UseMasterDataStore) => {
      state.countryCode = countryCode;
    })
  );
}

export function setTitle(title: Array<MasterDataItem>) {
  useMasterDataStore.setState(
    produce((state: UseMasterDataStore) => {
      state.title = title;
    })
  );
}

export function setOrganizationTypes(organizationTypes: Array<MasterDataItem>) {
  useMasterDataStore.setState(
    produce((state: UseMasterDataStore) => {
      state.organizationTypes = organizationTypes;
    })
  );
}

export function setPermissions(permissions: PermissionList) {
  useMasterDataStore.setState(
    produce((state: UseMasterDataStore) => {
      state.permissions = permissions;
    })
  );
}

export function clearMasterDataStore() {
  useMasterDataStore.setState(
    produce((state: UseMasterDataStore) => {
      state.masterData = undefined;
      state.countryCode = undefined;
      state.title = undefined;
      state.organizationTypes = undefined;
      state.permissions = undefined;
    })
  );
}
