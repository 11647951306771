export const checkUnsavedDetails = (
  currentData: CollaborationAbout | CollaborationMileStone | CollaborationLearnMore,
  savedData: CollaborationAbout | CollaborationMileStone | CollaborationLearnMore | null | undefined
) => {
  if (JSON.stringify(currentData) === JSON.stringify(savedData)) {
    return false;
  } else {
    return true;
  }
};

export const checkUnsavedTextContent = (
  currentTextContent: string | undefined | null,
  savedTextContent: string | undefined | null
) => {
  try {
    if (!savedTextContent || !currentTextContent) return true;
    const currentData = JSON.parse(currentTextContent);
    const savedData = JSON.parse(savedTextContent);
    return JSON.stringify(currentData) !== JSON.stringify(savedData);
  } catch (error) {
    return true;
  }
};

export const checkUnsavedFundingData = (
  currentTextContent: string | null,
  savedTextContent: string | null | undefined
) => {
  try {
    if (savedTextContent?.length === 0 && currentTextContent?.length === 0) {
      return false;
    }
    if (!savedTextContent || !currentTextContent) return true;
    const currentData = JSON.parse(currentTextContent);
    const savedData = JSON.parse(savedTextContent);
    return JSON.stringify(currentData) !== JSON.stringify(savedData);
  } catch (error) {
    return true;
  }
};

export const checkUnsavedAdditionalInfo = (
  currentTextContent: CollaborationAdditionalInfo,
  savedTextContent: CollaborationAdditionalInfo | undefined | null
) => {
  if (!savedTextContent) return true;
  const currentData = JSON.parse(currentTextContent.information);
  const savedData = JSON.parse(savedTextContent.information);
  if (
    JSON.stringify(currentData) === JSON.stringify(savedData) &&
    currentTextContent.documents.length === savedTextContent.documents.length
  ) {
    return false;
  } else if (JSON.stringify(currentData) !== JSON.stringify(savedData)) {
    return true;
  } else if (
    currentTextContent.documents &&
    currentTextContent.documents.length > 0 &&
    currentTextContent.documents.length === savedTextContent.documents.length
  ) {
    const currentDocumentNames = currentTextContent.documents.map((item) => item.name);
    const savedDocumentNames = savedTextContent.documents.map((item) => item.name);
    const result = currentDocumentNames.every((name) => savedDocumentNames.includes(name));
    return !result;
  }
  return true;
};

export const prepareAboutFormData = (collaborationAbout: CollaborationAbout) => {
  const formData = {
    id: collaborationAbout?.id,
    coverImage: collaborationAbout?.coverImage,
    title: collaborationAbout?.title.trim(),
    location: collaborationAbout?.location.trim(),
    startDate: collaborationAbout?.startDate,
    endDate: collaborationAbout?.endDate,
    summary: collaborationAbout?.summary.trim(),
    targetIndustry: collaborationAbout?.targetIndustry,
    isPrivate: collaborationAbout?.isPrivate,
    challengeStatusId: collaborationAbout?.collaborationStatusId,
    challengeCompletionStatusId: collaborationAbout?.collaborationCompletionStatusId,
  };
  return formData;
};

export const prepareMileStoneFormData = (milestones: Array<MileStone>) => {
  const formData = {
    milestones: milestones?.map((item: MileStone) => {
      const newItem = structuredClone(item);
      return {
        id: newItem.id,
        startDate: newItem.startDate,
        endDate: newItem.endDate,
        milestone: newItem?.milestone.trim(),
        description: newItem?.description.trim(),
      };
    }),
  };
  return formData;
};

export const prepareLearnMoreFormData = (learnMore: CollaborationLearnMore) => {
  const learnMoreData = {
    overview: learnMore?.overview?.trim(),
    description: learnMore?.description?.trim(),
    resources: learnMore?.resources?.trim(),
    targets: learnMore?.targets?.trim(),
  };

  return learnMoreData;
};

export const prepareFundingFormData = (fundingData: CollaborationFunding) => {
  const fundingFormData = {
    funding: fundingData?.funding ?? "",
  };
  return fundingFormData;
};

export const prepareAdditionalInfoFormData = (additionalInfo: CollaborationAdditionalInfo) => {
  const formData = {
    information: additionalInfo.information.trim(),
    documents: additionalInfo.documents,
  };
  return formData;
};

export const prepareContactFormData = (contacts: CollaborationContacts) => {
  const contactFormData = {
    contacts: contacts?.contacts.map((contact: Contact) => ({
      id: contact.id ?? 0,
      fullName: contact.fullName,
      designation: contact.designation,
      email: contact.email,
      countryCodeId: contact.countryCodeId,
      contactNumber: contact.contactNumber,
      isPrimary: contact.isPrimary,
    })),
  };
  return contactFormData;
};

export const prepareContactTagsFormData = (tags: CollaborationTags) => {
  const formData: CollaborationTags = { tags: [] };

  if (tags?.tags.length > 0) {
    formData.tags = tags?.tags?.map(
      (item): OptionItem => ({
        id: item.id,
        name: item.name,
      })
    );
    return formData;
  }

  return formData;
};
