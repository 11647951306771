import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function updateCaseStudy(caseStudyFormData: UploadCaseStudyFormData, challengeId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  const response = await apiClient.put(urls.uploadCaseStudy(challengeId), caseStudyFormData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useUpdateChallengeCaseStudy() {
  return useMutation(
    async ({
      formData,
      challengeId,
    }: {
      formData: UploadCaseStudyFormData;
      challengeId: number;
    }) => {
      return await updateCaseStudy(formData, challengeId);
    }
  );
}
