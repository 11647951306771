import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveResearchProject(formData: Array<ResearchProject>, userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  const response = await apiClient.put(urls.addResearchProject(userId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response;
}

export function useSaveResearchProject() {
  const qc = useQueryClient();
  return useMutation(
    async ({
      formData,
      researchScholarId,
    }: {
      formData: Array<ResearchProject>;
      researchScholarId: string;
    }) => {
      const userInfo = useUserDataStore.getState().userInfo;
      const userId = researchScholarId ? researchScholarId : userInfo?.userId;
      if (userId) {
        const result = await saveResearchProject(formData, userId);
        qc.invalidateQueries({ queryKey: ["research-projects"] });
        qc.invalidateQueries({ queryKey: ["personal-details", userId] });
        return result;
      }
    }
  );
}
