import { useNavigate } from "react-router-dom";
import { PrimaryButton, LanguageSwitchButton, Title } from "components";
import MainLogo from "assets/images/home-images/headerMainLogo.svg";
import { HeaderItem } from "features/Home/Header/HeaderItem";
import { HOME_MENU_IDS } from "utils/constants";
import { useGetLatestCaseStudies } from "features/Home/api/useGetLatestCaseStudy";
import { isGlobalWebsite } from "utils/helpers";
import globalConsortiumLogo from "assets/images/home-images/GCIEI.svg";

type HeaderProps = {
  className?: string;
  handleClick: (id: number) => void;
  selectedMenu: number;
};

export default function Header({ className, handleClick, selectedMenu }: HeaderProps) {
  const isGlobal = isGlobalWebsite();
  const { data: caseStudies } = useGetLatestCaseStudies(isGlobal);
  const navigate = useNavigate();
  return (
    <div className={`bg-white w-full fixed top-0 left-0 right-0 z-50 ${className}`}>
      <div className="border-b border-gray-1200 h-auto logoHolder">
        <div className="flex items-center justify-between py-[14px] home-container">
          {isGlobal ? (
            <div className="max-w-[200px] sm:max-w-[208px] md:max-w-[267px] xl:max-w-[313px] max-[340px]:w-[136px] max-[390px]:w-[160px]">
              <img
                src={globalConsortiumLogo}
                alt="Higher Colleges of technology Logo"
                className=" h-16"
              />
            </div>
          ) : (
            <div className="max-w-[200px] sm:max-w-[208px] md:max-w-[267px] xl:max-w-[313px] max-[340px]:w-[136px] max-[390px]:w-[160px]">
              <img
                src={MainLogo}
                alt="Higher Colleges of technology Logo"
                className="w-full h-full  "
              />
            </div>
          )}
          <div className="flex items-center space-x-[5px]">
            <LanguageSwitchButton className="home-page-language-button ltr:mr-2 rtl:ml-2  max-[1023px]:!mr-0" />
            <PrimaryButton
              className="!bg-transparent hover:!bg-skin-primary hover:!border-skin-primary hover:!text-white border border-skin-primary py-3 px-[30px] !text-skin-primary !text-[15px] font-semibold leading-6 min-h-[44px] h-[44px] flex flex-col items-center justify-center ltr:ml-2 rtl:!mr-2 rtl:!ml-0 max-[767px]:ltr:ml-0 max-[767px]:rtl:mr-0 home-login-button"
              title={"global.login"}
              startIcon="hProfile"
              handleClick={() => navigate("/login")}
            />
          </div>
        </div>
      </div>
      <div className="border-b border-gray-1200 h-[64px] menuHolder">
        <div className="home-container py-5 max-[767px]:py-[18px] ltr:max-[1023px]:!pr-0 rtl:max-[1023px]:!pl-0 ">
          <ul className="w-full flex items-center justify-center gap-x-[50px] home-navigation-items max-[1023px]:gap-x-[40px] max-[1023px]:justify-start max-[1023px]:overflow-auto max-[767px]:gap-x-[30px]">
            <HeaderItem
              text={"home.about_us"}
              handleClick={() => handleClick(HOME_MENU_IDS.AboutUs)}
              isActive={selectedMenu === HOME_MENU_IDS.AboutUs}
            />
            <HeaderItem
              text={"home.join_us"}
              handleClick={() => handleClick(HOME_MENU_IDS.JoinUs)}
              isActive={selectedMenu === HOME_MENU_IDS.JoinUs}
            />
            <HeaderItem
              text={"home.data_insights"}
              handleClick={() => handleClick(HOME_MENU_IDS.DataInsights)}
              isActive={selectedMenu === HOME_MENU_IDS.DataInsights}
            />
            <HeaderItem
              text={"admin.challenges"}
              handleClick={() => handleClick(HOME_MENU_IDS.Challenges)}
              isActive={selectedMenu === HOME_MENU_IDS.Challenges}
            />
            <HeaderItem
              text={"home.how_works"}
              handleClick={() => handleClick(HOME_MENU_IDS.HowItWorks)}
              isActive={selectedMenu === HOME_MENU_IDS.HowItWorks}
            />
            {caseStudies?.length > 0 ? (
              <HeaderItem
                text={"home.case_studies"}
                handleClick={() => handleClick(HOME_MENU_IDS.CaseStudies)}
                isActive={selectedMenu === HOME_MENU_IDS.CaseStudies}
              />
            ) : null}
            {/* <HeaderItem text={"home.news_events"} handleClick={() => handleClick(HOME_MENU_IDS.NewsAndEvents)} isActive={selectedMenu ===HOME_MENU_IDS.NewsAndEvents}/> */}
            <HeaderItem
              text={"home.contact_us"}
              handleClick={() => handleClick(HOME_MENU_IDS.ContactUs)}
              isActive={selectedMenu === HOME_MENU_IDS.ContactUs}
            />
          </ul>
        </div>
      </div>
    </div>
  );
}
