import axios from "axios";
import i18next from "i18next";
import { urls } from "utils/urls";
import { getRefreshToken } from "features/Login/api/useAuth";
import { setAvatarUrl, setTokenInfo, useUserDataStore } from "store/useUserDataStore";
import { clearStores } from "store";
import { showErrorToastMessage } from "utils/toasterMessage";
import { getRedirectionPath } from "utils/helpers";

const apiClient = axios.create({
  baseURL: urls.baseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error?.response?.status;
    if (status === 401) {
      try {
        // anonymous user
        const tokenInfo = await getRefreshToken();
        if (!tokenInfo) {
          throw new Error("Session Expired. Logging out.");
        }
        setTokenInfo(tokenInfo);
        setAvatarUrl(tokenInfo.avatarUrl ?? "");
        error.config.headers["Authorization"] =
          "Bearer " + useUserDataStore.getState().tokenInfo?.token;
        apiClient.request(error.config);
      } catch (e) {
        clearStores();
      }
    } else if (status === 423) {
      showErrorToastMessage({
        message: error?.response?.data?.message,
      });
      setTimeout(() => {
        location.assign(getRedirectionPath(useUserDataStore?.getState()?.userInfo?.userType ?? 1));
      }, 1500);
    }
    return Promise.reject(error);
  }
);
apiClient.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = i18next.language === "ar" ? "ar-AE" : "en-US";
  return config;
});

export default apiClient;
