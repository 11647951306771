import React, { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { InputField, CheckBox, SubTitle } from "components";
import { PASSWORD_MAX_CHAR_LENGTH, PASSWORD_RULES } from "utils/constants";
import { isRuleVerified } from "utils/helpers";

type PasswordFormProps = {
  passwordFormData: PasswordFormFields;
  handleChange: (value: string, fieldName: string) => void;
  validationErrors: Record<string, string> | null | undefined;
};

function PasswordForm({ handleChange, passwordFormData, validationErrors }: PasswordFormProps) {
  const { t } = useTranslation();

  const handlePasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const value = event.target.value;
    handleChange(value, fieldName);
  };

  const handleInputPaste = (e: SyntheticEvent) => {
    e.preventDefault();
    return false;
  };
  return (
    <div className="  flex flex-col h-full mb-auto">
      <div className=" space-y-5">
        <InputField
          value={passwordFormData.password}
          hintText={t("global.create_password")}
          labelText={t("global.create_password")}
          handleInputChange={handlePasswordInputChange}
          fieldName="password"
          inputType="password"
          isRequired
          errorMessage={validationErrors?.password}
          maxCharLength={PASSWORD_MAX_CHAR_LENGTH}
        />
        <InputField
          value={passwordFormData.confirmPassword}
          hintText={t("global.confirm_password")}
          labelText={t("global.confirm_password")}
          handleInputChange={handlePasswordInputChange}
          fieldName="confirmPassword"
          inputType="password"
          isRequired
          errorMessage={validationErrors?.confirmPassword}
          handleInputPaste={handleInputPaste}
          maxCharLength={PASSWORD_MAX_CHAR_LENGTH}
        />
      </div>
      <SubTitle
        className="font-normal text-white !justify-start text-sm mb-2 mt-10 yourPasswordMust"
        title={t("global.your_password_must")}
      />
      <div className="passwordMust">
        {PASSWORD_RULES.map((item, index) => (
          <CheckBox
            option={{
              label: t(`global.passwordRule${index + 1}`),
              value: item,
            }}
            key={item}
            value={item}
            className={`  text-sm text-gray-100 font-normal password-must-icons ${
              isRuleVerified(index + 1, passwordFormData.password)
                ? "password-success"
                : "password-fail"
            }`}
          />
        ))}
      </div>
    </div>
  );
}

export default PasswordForm;
