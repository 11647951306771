import Joi from "joi";
import { groupBy } from "utils/helpers";

export const challengeDetailsSchema = Joi.object({
  id: Joi.number().optional(),
  coverImage: Joi.string().empty("").allow(null).optional(),
  title: Joi.string().messages({ "string.empty": "required" }),
  startDate: Joi.string().isoDate().messages({
    "string.empty": "required",
    "string.isoDate": "required",
    "string.date.base": "required",
  }),
  endDate: Joi.string().isoDate().messages({
    "string.empty": "required",
    "string.isoDate": "required",
    "string.date.base": "required",
  }),
  location: Joi.string().messages({ "string.empty": "required" }),
  type: Joi.number().optional(),
  summary: Joi.string().messages({ "string.empty": "required" }),
  targetIndustry: Joi.object().messages({
    "object.null": "required",
    "object.base": "required",
    "object.allow": "required",
  }),
  isPrivate: Joi.boolean(),
  isDiscussionForumEligible: Joi.boolean(),
}).options({ abortEarly: false });

const challengeDetails = challengeDetailsSchema.keys({
  challengeCompletionStatusId: Joi.number().optional(),
  challengeStatusId: Joi.number().optional(),
  isGlobal: Joi.boolean(),
});

export const challengeDetailsValidation = (challengeInfo: ChallengeInfo) => {
  const result = challengeDetails.validate(challengeInfo);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
