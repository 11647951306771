import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQueryClient } from "@tanstack/react-query";
import challengeDefault from "assets/images/challengeDefaultProfile.png";
import {
  Title,
  Descriptor,
  TableLoader,
  StatisticsDataCard,
  DropDown,
  PrimaryButton,
  NoData,
  ImageLoader,
  InlineLoader,
  Label,
  Header,
  SecondaryButton,
  ToggleButton,
  NoResults,
} from "components";
import {
  CHALLENGE_USERS_INITIAL_PARAMS,
  CHALLENGE_USERS_TABLE_HEADERS,
  CHALLENGE_USER_STATUS,
  CHALLENGE_STATUS,
  CHALLENGE_FUNDING_TABLE_HEADERS,
  CHALLENGE_SUMMARY_TYPES,
  ADVISOR_TOGGLE_BUTTON,
} from "utils/constants";
import {
  showChallengePreviewModal,
  showContactDetailsModal,
  showFundingInterestModal,
  showInviteUserModal,
  showUpdateChallengeStatus,
  showUploadCaseStudyModal,
} from "utils/confirmationModals";
import { displayDateFormat, getChallengeStatusTitle, getMasterDataValue } from "utils/helpers";
import Table from "features/Organization/Challenge/ViewChallenge/Table";
import { useGetUsersOnChallenge } from "features/Organization/api/useGetUsersOnChallenge";
import { useGetChallengeBasicDetails } from "features/Organization/api/useGetChallengeBasicDetails";
import FundingInterestTable from "features/Organization/Challenge/ViewChallenge/FundingInterestTable";
import { useGetChallengeFundingInterests } from "features/Organization/api/useGetFundingInterests";
import { useOutsideClick } from "hooks/useOutsideClick";
import { useStickyScroll } from "hooks/useStickyScroll";
import { useMasterDataStore } from "store/useMasterDataStore";
import { useUserDataStore } from "store/useUserDataStore";
import {
  clearFilterDataStore,
  setAdvisorFilterInfo,
  useFilterDataStore,
} from "store/useFilterDataStore";
import {
  setSelectedSideMenu,
  setSelectedUserType,
  useSidebarDataStore,
} from "store/useSidebarStore";

const ChallengeDetails = () => {
  const navigate = useNavigate();
  const { challengeId } = useParams();
  const { permissions } = useMasterDataStore();
  const pathName = window.location.pathname;
  const isExplore = pathName.includes("explore");
  const { scroll, scrollValue } = useStickyScroll(12);
  const challengeDataId = Number(challengeId);
  const { t, i18n } = useTranslation();
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const { isOpen, setIsOpen, dropDownRef } = useOutsideClick();
  const [isImageLoading, setImageLoading] = useState(false);
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId ?? "";
  const { organizationFilterInfo, advisorFilterInfo } = useFilterDataStore();
  const { selectedUserType } = useSidebarDataStore();
  const queryClient = useQueryClient();

  const enableFiltering = pathName === organizationFilterInfo?.pathName;

  const [requestParams, setRequestParams] = useState<ChallengeUserParams>({
    ...CHALLENGE_USERS_INITIAL_PARAMS,
    challengeId: challengeDataId,
    userId: userId ?? "",
    researchInstitute: enableFiltering ? organizationFilterInfo?.researchInstitutes : "",
    statusId: selectedUserType ? selectedUserType : CHALLENGE_USERS_INITIAL_PARAMS.statusId,
    isAdvisor: advisorFilterInfo?.isAdvisor ? advisorFilterInfo?.isAdvisor : false,
  });

  const { data: challengeData, isLoading: isLoadingChallengeData } = useGetChallengeBasicDetails(
    challengeDataId,
    isExplore
  );
  const {
    data,
    isLoading: isLoadingChallengeUsers,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useGetUsersOnChallenge(requestParams);

  const {
    data: fundingInterestsData,
    isLoading: isFundingInterestLoading,
    fetchNextPage: fetchNextInterests,
    hasNextPage: hasNextFundingInterest,
  } = useGetChallengeFundingInterests(requestParams);

  useEffect(() => {
    refetch();
  }, [requestParams.statusId, i18n.language, refetch, requestParams.researchInstitute]);

  const challengeUsers = useMemo(() => {
    const tempData: ChallengeUsersResponse = {
      researchers: [],
      totalCount: 0,
      totalPages: 0,
      summary: [],
    };
    if (data?.pages && data?.pages[0]) {
      tempData.summary = data.pages[0]?.summary;
      tempData.totalCount = data.pages[0]?.totalCount;
      tempData.totalPages = data.pages[0]?.totalPages;
      tempData.advisorCount = data.pages[0]?.advisorCount;
      const researchers = data.pages
        .map((item) => {
          return item.researchers;
        })
        .flat();
      tempData.researchers = researchers;
    }
    return tempData;
  }, [data]);

  const challengeFundingInterests = useMemo(() => {
    const tempData: FundingInterests = {
      interests: [],
      totalCount: 0,
      totalPages: 0,
    };
    if (fundingInterestsData?.pages && fundingInterestsData?.pages[0]) {
      tempData.totalCount = fundingInterestsData.pages[0]?.totalCount;
      tempData.totalPages = fundingInterestsData.pages[0]?.totalPages;
      const interests = fundingInterestsData.pages
        .map((item) => {
          return item.interests;
        })
        .flat();
      tempData.interests = interests;
    }
    return tempData;
  }, [fundingInterestsData]);

  const isOptionDisabled =
    challengeData?.challengeStatusId === CHALLENGE_STATUS.Close ||
    challengeData?.challengeStatusId === CHALLENGE_STATUS.Disable;

  const isUploadCaseStudyDisabled = challengeData?.challengeStatusId !== CHALLENGE_STATUS.Close;

  const inviteDropDownItems: Array<DropdownListItem> = useMemo(() => {
    const tempItems = [
      { type: "preview", icon: "dropEye", label: "global.preview" },
      {
        type: "edit",
        icon: "dropEdit",
        label: "global.edit_challenge",
        disabled: isOptionDisabled,
      },
    ];
    if (permissions) {
      if (permissions.Invitescholars) {
        tempItems.push({
          type: "inviteScholar",
          icon: "dropInvite",
          label: "organization.invite",
          disabled: isOptionDisabled,
        });
      }
      tempItems.push({
        type: "inviteHistory",
        icon: "dropInviteHistory",
        label: "organization.invite_history",
      });
      tempItems.push({
        type: "contactDetails",
        icon: "dropContact",
        label: "global.contact_details",
      });

      if (permissions.Managechallengestatus) {
        tempItems.push({
          type: "updateStatus",
          icon: "dropUpdateStatus",
          label: "global.update_status",
          disabled: challengeData?.challengeStatusId === CHALLENGE_STATUS.Disable,
        });
      }
      if (permissions.Uploadcasestudytochallenge) {
        tempItems.push({
          type: "uploadCaseStudy",
          icon: "dropUpload",
          label: challengeData?.isCaseStudyUploaded
            ? "global.edit_case_study"
            : "global.upload_case_study",
          disabled: isUploadCaseStudyDisabled,
        });
      }
    }

    return tempItems;
  }, [permissions, challengeData]);

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const getBreadCrumbPath = (): Array<LinkItem> => {
    if (pathName.includes("explore")) {
      return [
        {
          title: "home.challenges",
          path: "/organization/challenges",
        },
        {
          title: "organization.explore_challenge",
          path: "/organization/challenges",
        },
        {
          title: "organization.view_challenge",
          path: "",
        },
      ];
    } else {
      return [
        {
          title: "admin.challenges",
          path: "/organization/challenges",
        },
        {
          title: "organization.view_challenge",
          path: "",
        },
      ];
    }
  };

  const linkList: Array<LinkItem> = getBreadCrumbPath();

  const handleItemSelection = async (type: string) => {
    if (type === "preview") {
      if (challengeData) {
        await showChallengePreviewModal({
          challengeId: challengeData?.id,
          showPrimaryButton: false,
        });
      }
    } else if (type === "inviteScholar") {
      if (challengeData) {
        await showInviteUserModal({
          challengeId: challengeData?.id,
          isChallengeGlobal: challengeData.isGlobal,
        });
      }
    } else if (type === "contactDetails") {
      await showContactDetailsModal(challengeData?.id ?? 0);
    } else if (type === "updateStatus") {
      if (challengeData) {
        await showUpdateChallengeStatus({
          challengeStatus: challengeData?.challengeStatusId,
          challengeId: challengeData?.id,
        });
      }
    } else if (type === "uploadCaseStudy") {
      if (challengeData?.id) {
        await showUploadCaseStudyModal(challengeData?.id, challengeData.isCaseStudyUploaded);
      }
    } else if (type === "edit") {
      navigate(`/organization/challenges/${challengeId}/update`);
    } else if (type === "inviteHistory") {
      navigate("invite-history");
    }
  };

  const handleCardClick = (id: number) => {
    if (id !== requestParams.statusId) {
      clearFilterDataStore(false);
      setAdvisorFilterInfo({ isAdvisor: false });
      setRequestParams((prevState) => ({
        ...prevState,
        statusId: id,
        researchInstitute: "",
        isAdvisor: false,
      }));
      setSelectedUserType(id);
    }
  };

  const handleFundingInterestNavigation = (interestId: number) => {
    showFundingInterestModal({ challengeId: requestParams.challengeId, interestId: interestId });
  };

  const getChallengeTags = () => {
    const tags = challengeData?.challengeTags?.map((challenge) => {
      return i18n.language === "ar" ? challenge?.name.ar : challenge?.name.en;
    });
    return tags?.length ? tags?.toString().replace(/,/g, ", ") : "";
  };

  const getSubTitle = () => {
    if (requestParams?.statusId === CHALLENGE_USER_STATUS.PendingApproval) {
      return "global.pending_approvals";
    } else if (requestParams?.statusId === CHALLENGE_USER_STATUS.Approved) {
      return "global.accepted_scholars";
    } else {
      return "organization.funding_interests";
    }
  };

  const handleChallengeStatus = () => {
    if (permissions?.Viewchallengerequests) {
      setRequestParams((prevState) => ({
        ...prevState,
        statusId: selectedUserType ? selectedUserType : CHALLENGE_USER_STATUS.PendingApproval,
      }));
    } else if (permissions?.Viewapprovedscholars) {
      setRequestParams((prevState) => ({
        ...prevState,
        statusId: selectedUserType ? selectedUserType : CHALLENGE_USER_STATUS.Approved,
      }));
    } else if (permissions?.ViewfundingorganizationInterests) {
      setRequestParams((prevState) => ({
        ...prevState,
        statusId: selectedUserType ? selectedUserType : CHALLENGE_USER_STATUS.FundingOrganization,
      }));
    }
  };

  useEffect(() => {
    handleChallengeStatus();
  }, []);

  useEffect(() => {
    if (challengeData && challengeData && challengeData?.coverImage?.length > 0) {
      setImageLoading(true);
    }
  }, [challengeData]);

  const onLoaded = () => {
    setImageLoading(false);
  };

  const updateRequestParams = (data: FilterParams) => {
    setRequestParams({
      ...requestParams,
      researchInstitute: data.type ?? "",
    });
    refetch();
  };

  const handleClearFilter = () => {
    clearFilterDataStore();
    setRequestParams({
      ...CHALLENGE_USERS_INITIAL_PARAMS,
      challengeId: challengeDataId,
      userId: userId ?? "",
      statusId: requestParams.statusId,
      isAdvisor: requestParams?.isAdvisor,
    });
  };

  const isClearFilterActive: boolean =
    JSON.stringify(requestParams) !==
    JSON.stringify({
      ...CHALLENGE_USERS_INITIAL_PARAMS,
      challengeId: challengeDataId,
      userId: userId ?? "",
      statusId: requestParams.statusId,
      isAdvisor: requestParams?.isAdvisor,
    });

  useEffect(() => {
    setSelectedSideMenu({
      type: "admin.challenges",
    });
  }, []);

  const handleToggleChange = () => {
    queryClient.resetQueries(["challenge-users"]);
    setAdvisorFilterInfo({ isAdvisor: !requestParams?.isAdvisor });
    setRequestParams((prevstate) => ({
      ...prevstate,
      isAdvisor: !requestParams?.isAdvisor,
      researchInstitute: "",
    }));
  };

  return (
    <>
      <div
        className={`w-full h-full flex flex-col  ${!isOpenSideMenu ? "miniSidebar" : ""} ${
          scrollValue > 0 ? "window-scroll" : ""
        }`}
      >
        <Header
          linkList={linkList}
          title={challengeData?.title ?? ""}
          handleCollapseClick={onToggleClick}
          className={
            scroll
              ? " bg-white animate__animated animate__fadeInDown !py-[26px] !h-[90px] fixed stickyHeader z-[41]"
              : "fixed"
          }
          displayGlobalTag={challengeData?.isGlobal && !userInfo?.isGlobal}
        />
        <div className="adminDetails w-full pt-[118px] pb-[45px] items-baseline px-10 ltr:pr-[30px] rtl:pl-[30px] min-h-screen flex flex-col overflow-hidden">
          <div className="w-full flex flex-col h-full space-y-3">
            {/* card of challenge details */}
            <div
              className={
                scroll
                  ? " bg-white rounded-md p-4 flex challengeInfo sticky animate__animated animate__fadeInUp shadow-sticky  z-40"
                  : "bg-white rounded-md p-4 flex challengeInfo relative z-40"
              }
            >
              {isLoadingChallengeData ? (
                <div className=" flex items-center justify-center h-auto w-full">
                  <InlineLoader />
                </div>
              ) : (
                <>
                  <div className="  min-w-[120px] max-w-[120px] h-[130px] rounded-md overflow-hidden imageHolder">
                    {isImageLoading ? <ImageLoader /> : null}
                    <img
                      alt="profile picture"
                      className={`w-full h-[130px] object-cover ${
                        isImageLoading ? "h-[0px] overflow-hidden" : "h-[130px] overflow-hidden"
                      }`}
                      src={
                        challengeData && challengeData?.coverImage?.length > 0
                          ? challengeData?.coverImage
                          : challengeDefault
                      }
                      onLoad={onLoaded}
                    />
                  </div>
                  <div className=" px-5 dataHolder overflow-hidden">
                    <div className="flex gap-4 items-center truncate">
                      <div className=" flex badgeBLock space-x-2 rtl:space-x-reverse">
                        <Label
                          className=" !w-auto text-gray-107 text-xs bg-green-500 rounded-xl px-3 py-1.5 "
                          text={t(getChallengeStatusTitle(challengeData?.challengeStatusId))}
                        />
                        <Label
                          className=" !w-auto text-gray-107 text-xs bg-green-500 rounded-xl px-3 py-1.5 "
                          text={t(
                            challengeData?.isPrivate
                              ? "organization.private"
                              : "organization.public"
                          )}
                        />
                      </div>
                      <div className=" h-[14px] bg-gray-200 w-[1px]"></div>

                      <div className="truncate">
                        <Label
                          className=" !w-auto text-gradient-blueDark text-xs !block bg-primary-500 rounded-xl px-3 py-1.5  h-[30px] truncate max-w-[200px]"
                          text={t(
                            challengeData?.targetIndustry
                              ? getMasterDataValue(challengeData?.targetIndustry, i18n.language)
                              : ""
                          )}
                        />
                      </div>
                    </div>
                    <div className=" flex flex-col space-y-3 pt-3 dataWrapper truncate">
                      <div className="flex space-x-10 rtl:space-x-reverse truncate sessionOne">
                        <Descriptor
                          title={challengeData?.location ?? ""}
                          startIcon="mapPin"
                          className="info  !mb-0 min-w-[176px] flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse truncate"
                        />
                      </div>
                      <div className="flex space-x-10 rtl:space-x-reverse truncate sessionTwo">
                        <Descriptor
                          title={`${t("global.start_date")}: ${displayDateFormat(
                            challengeData?.startDate ?? ""
                          )}`}
                          startIcon="date"
                          className="info !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW date"
                        />
                        <Descriptor
                          title={`${t("global.end_date")}: ${displayDateFormat(
                            challengeData?.endDate ?? ""
                          )}`}
                          startIcon="date"
                          className="info !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
                        />
                      </div>
                      <div className="flex space-x-10 rtl:space-x-reverse truncate sessionThree ">
                        {challengeData?.challengeTags?.length ? (
                          <Descriptor
                            title={getChallengeTags()}
                            startIcon="tag"
                            className="info !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW truncate"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="buttonHolder ltr:ml-auto rtl:mr-auto flex min-w-[215px] justify-end ">
                    {permissions?.Viewpost && challengeData?.isDiscussionForumEligible ? (
                      <PrimaryButton
                        title={"forum.discussion_forum"}
                        handleClick={() => {
                          navigate("discussion-forum");
                        }}
                        className=" !h-[38px] min-h-max"
                      />
                    ) : null}
                    <div
                      className="dropdown dropdown-bottom dropdown-end ltr:ml-[18px] rtl:mr-[18px]  iconDropdown"
                      onClick={() => setIsOpen(!isOpen)}
                      ref={dropDownRef}
                    >
                      <DropDown
                        title="..."
                        list={inviteDropDownItems}
                        handleSelection={handleItemSelection}
                        isOpen={isOpen}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* card of challenge details end */}
            {permissions?.Viewchallengerequests ||
            permissions?.Viewapprovedscholars ||
            permissions?.ViewfundingorganizationInterests ? (
              <>
                {/* statistics card start*/}
                <div className=" pt-4">
                  <Title
                    title={t("global.manage")}
                    className=" text-base text-black font-semibold mb-4"
                  />

                  <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-[10px] text-white selectedCard relative">
                    {challengeUsers && challengeUsers?.summary?.length > 0 ? (
                      challengeUsers?.summary?.map((item, index) => {
                        return (
                          <StatisticsDataCard
                            key={`${item.name}-${index}-${item.count}`}
                            data={{
                              title: item.name,
                              value: item.count,
                              type: item.name,
                            }}
                            handleClick={() => handleCardClick(item.id)}
                            isActive={item.id === requestParams.statusId}
                            statusCount={[
                              {
                                label: "global.advisors",
                                value: challengeUsers?.advisorCount
                                  ? challengeUsers?.advisorCount
                                  : 0,
                              },
                            ]}
                            showCount={item.id === CHALLENGE_USER_STATUS.Approved}
                          />
                        );
                      })
                    ) : (
                      <InlineLoader />
                    )}
                  </div>
                </div>

                {/**statistics card end */}
                <div className=" pt-[30px] pb-5 min-h-[500px]">
                  <div className=" flex items-center justify-between mb-4">
                    <div className="flex justify-start items-center">
                      <Title
                        title={t(getSubTitle())}
                        className=" text-base text-black font-semibold "
                      />
                      {requestParams?.statusId === CHALLENGE_USER_STATUS.Approved ? (
                        <div className="flex justify-end">
                          <ToggleButton
                            id={ADVISOR_TOGGLE_BUTTON.disabled}
                            label={"global.advisors"}
                            isChecked={requestParams?.isAdvisor ?? false}
                            handleChange={handleToggleChange}
                            showInfoTooltip={false}
                          />
                        </div>
                      ) : null}
                    </div>
                    {isClearFilterActive ? (
                      <SecondaryButton
                        startIcon="clearFilter"
                        className="!min-h-[38px] !h-[38px]  space-x-1.5 !bg-transparent text-primary-dark"
                        title={"global.clear_filters"}
                        handleClick={handleClearFilter}
                      />
                    ) : null}
                  </div>
                  {requestParams.statusId !== CHALLENGE_SUMMARY_TYPES.fundingOrganizations ? (
                    isLoadingChallengeUsers && !isClearFilterActive ? (
                      <div
                        className={` flex flex-col w-full h-auto xl:h-full items-center rounded-md justify-start bg-white mb-[45px] noData`}
                      >
                        <div className=" flex flex-col items-center justify-start my-[102px]">
                          <InlineLoader />
                        </div>
                      </div>
                    ) : challengeUsers ? (
                      <InfiniteScroll
                        dataLength={challengeUsers?.researchers?.length}
                        next={fetchNextPage}
                        hasMore={!!hasNextPage}
                        loader={hasNextPage || isLoadingChallengeUsers ? <TableLoader /> : null}
                        scrollThreshold="200px"
                      >
                        <div className=" flex flex-col p-4 pt-[18px] w-full h-full items-center rounded-md justify-start bg-white">
                          <Table
                            userData={challengeUsers?.researchers ?? []}
                            headers={CHALLENGE_USERS_TABLE_HEADERS}
                            handleItemClick={(challengeId) =>
                              navigate(`research-scholar/${challengeId}`)
                            }
                            updateRequestParams={updateRequestParams}
                            requestParams={requestParams}
                          />
                          <NoResults
                            isLoading={isLoadingChallengeUsers}
                            isFilterApplied={isClearFilterActive}
                            dataLength={challengeUsers?.researchers?.length ?? 0}
                          />
                        </div>
                      </InfiniteScroll>
                    ) : (
                      <NoData className=" !mb-0 h-full" message="admin.no_data_to_show" />
                    )
                  ) : challengeFundingInterests &&
                    challengeFundingInterests.totalCount &&
                    challengeFundingInterests.totalCount > 0 ? (
                    <InfiniteScroll
                      dataLength={challengeUsers?.researchers?.length}
                      next={fetchNextInterests}
                      hasMore={!!hasNextFundingInterest}
                      loader={
                        hasNextFundingInterest || isFundingInterestLoading ? <TableLoader /> : null
                      }
                      scrollThreshold="200px"
                    >
                      <div className=" flex flex-col p-4 pt-[18px] w-full h-full items-center rounded-md justify-start bg-white">
                        <FundingInterestTable
                          fundingInterestData={challengeFundingInterests?.interests ?? []}
                          headers={CHALLENGE_FUNDING_TABLE_HEADERS}
                          handleNavigation={handleFundingInterestNavigation}
                        />
                      </div>
                    </InfiniteScroll>
                  ) : (
                    <NoData message="admin.no_data_to_show" />
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChallengeDetails;
