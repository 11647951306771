import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputField, SingleSelect, Divider, DividerWithText, PrimaryButton } from "components";
import {
  setContactsInfo,
  setContactsInfoItem,
  useOrganizationDataStore,
} from "store/useOrganizationDataStore";
import { useMasterDataStore } from "store/useMasterDataStore";
import { phoneMaxLimitChecker, setMasterDataValue } from "utils/helpers";
import { DETAILS_MAX_CHAR_LENGTH, EMAIL_MAX_CHAR_LENGTH } from "utils/constants";
import { showConfirmationModal } from "utils/confirmationModals";

type ContactsFormProps = {
  validationErrors: Array<Record<string, string>> | null;
  removeValidationErrors: () => void;
  isReadOnly: boolean;
};

export default function ContactsForm({
  validationErrors,
  removeValidationErrors,
  isReadOnly,
}: ContactsFormProps) {
  const { t, i18n } = useTranslation();
  const { countryCode } = useMasterDataStore();
  const { contactsInfo } = useOrganizationDataStore();

  const countryCodeOptions = useMemo(() => {
    return countryCode?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [countryCode, i18n.language]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof OrganizationContact,
    index: number
  ) => {
    const value = e.target.value;
    setContactsInfoItem(value, key, index);
  };

  const handleSelectChange = (
    value: string | number,
    key: keyof OrganizationContact,
    index: number
  ) => {
    setContactsInfoItem(value, key, index);
  };

  const handleDeleteContact = async (index: number) => {
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.contact_delete_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      modalIcon: "confirmDelete",
      isAlert: true,
    });
    if (!result) {
      return;
    }
    if (result) {
      const tempContactsInfo = structuredClone(contactsInfo);
      tempContactsInfo?.contacts.splice(index, 1);
      if (tempContactsInfo && tempContactsInfo?.contacts?.length > 0)
        setContactsInfo({
          ...tempContactsInfo,
        });
    }
    removeValidationErrors();
  };

  return (
    <div className=" space-y-[22px]  px-5 pb-8 ">
      {contactsInfo && contactsInfo?.contacts?.length > 0
        ? contactsInfo.contacts?.map((contactItem, index) => (
            <>
              {index === 0 ? <DividerWithText text={t("organization.primary")} /> : <Divider />}
              <div className="flex space-x-3 rtl:space-x-reverse">
                <InputField
                  value={contactItem?.fullName}
                  hintText={t("organization.enter_full_name")}
                  labelText={t("admin.full_name")}
                  handleInputChange={(event) => handleInputChange(event, "fullName", index)}
                  fieldName="fullName"
                  isRequired
                  errorMessage={validationErrors?.[index]?.fullName}
                  maxCharLength={EMAIL_MAX_CHAR_LENGTH}
                  isDisabled={isReadOnly}
                />
                <InputField
                  value={contactItem?.designation}
                  hintText={t("organization.enter_designation")}
                  labelText={t("global.designation")}
                  handleInputChange={(event) => handleInputChange(event, "designation", index)}
                  fieldName="designation"
                  isRequired
                  errorMessage={validationErrors?.[index]?.designation}
                  maxCharLength={DETAILS_MAX_CHAR_LENGTH}
                  isDisabled={isReadOnly}
                />
              </div>
              <InputField
                value={contactItem?.email}
                hintText={t("global.enter_email")}
                labelText={t("organization.contact_email")}
                handleInputChange={(event) => handleInputChange(event, "email", index)}
                fieldName="email"
                isRequired
                errorMessage={validationErrors?.[index]?.email}
                maxCharLength={EMAIL_MAX_CHAR_LENGTH}
                isDisabled={isReadOnly}
              />
              <div className="flex flex-col sm:flex-row space-x-3 sm rtl:space-x-reverse space-y-3 sm:space-y-0 ">
                <SingleSelect
                  selectOption={contactItem?.countryCodeId}
                  options={countryCodeOptions || []}
                  labelText={t("global.country_code")}
                  hintText={t("global.select")}
                  handleChange={(value) => handleSelectChange(value, "countryCodeId", index)}
                  isRequired
                  className=" w-full"
                  errorMessage={validationErrors?.[index]?.countryCodeId}
                  isDisabled={isReadOnly}
                />
                <InputField
                  value={contactItem?.contactNumber}
                  hintText={t("global.enter_contact_number")}
                  labelText={t("global.contact_number")}
                  handleInputChange={(event) => handleInputChange(event, "contactNumber", index)}
                  fieldName="contactNumber"
                  isRequired
                  errorMessage={validationErrors?.[index]?.contactNumber}
                  maxCharLength={phoneMaxLimitChecker(
                    contactsInfo?.contacts?.[index]?.contactNumber ?? ""
                  )}
                  isDisabled={isReadOnly}
                />
              </div>
              {index !== 0 && contactsInfo && contactsInfo?.contacts?.length > 1 ? (
                <div className=" flex justify-center pt-[10px]">
                  <PrimaryButton
                    title={"global.delete_contact"}
                    handleClick={() => handleDeleteContact(index)}
                    startIcon="trashV1"
                    className=" !h-10 !min-h-0 !w-[150px] !min-w-[150px] !rounded-sm !mb-8 !text-xs !px-0 font-medium bg-white !text-red-dark !border-red-dark mx-auto hover:!bg-red-dark/10 hover:!border-red-dark/30"
                    disabled={isReadOnly}
                  />
                </div>
              ) : null}
            </>
          ))
        : null}
    </div>
  );
}
