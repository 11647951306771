import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function masterSystemConfiguration(formData: SystemConfigurationFields) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(urls.masterSystemConfiguration(), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}
export function useMasterSystemConfiguration() {
  const qc = useQueryClient();
  return useMutation(async (formData: SystemConfigurationFields) => {
    const result = await masterSystemConfiguration(formData);
    qc.invalidateQueries({ queryKey: ["master-data"] });
    return result;
  });
}
