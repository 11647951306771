import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DefaultCoverImage from "assets/images/challengeDefaultCover.png";
import SVGIcon from "assets/Icons";
import {
  Descriptor,
  Divider,
  SecondaryButton,
  PrimaryButton,
  Label,
  ImageLoader,
  UploadedFileItem,
  SubTitle,
  ContentViewWithToggle,
  LoaderModal,
  TextButton,
  EditorContentPreview,
} from "components";
import {
  displayDateFormat,
  getEventTagClassName,
  getMasterDataValue,
  getPlainText,
  handleLinkClick,
} from "utils/helpers";
import { useGetEventPreview } from "features/Events/api/useEventPreview";
import { useEventPublish } from "features/Events/api/useEventPublish";
import { DETAIL_DESCRIPTION_MAX_CHAR_LENGTH } from "utils/constants";
import { showConfirmationModal } from "utils/confirmationModals";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";

type EventPreviewProps = {
  onClose: () => void;
  eventId: number;
  setNavigation?: () => void;
  showPrimaryButton: boolean;
};
const PreviewEvent = ({
  onClose,
  eventId,
  showPrimaryButton,
  setNavigation,
}: EventPreviewProps) => {
  const { t, i18n } = useTranslation();
  const [isViewMoreSummary, setIsViewMoreSummary] = useState<boolean>(false);

  const { data: eventDetails, isLoading: isEventDetailsLoading } = useGetEventPreview(eventId);
  const { mutateAsync: publishEvent, isLoading: isPublishingEvent } = useEventPublish();

  const [isImageLoading, setImageLoading] = useState(false);

  const handleExitPreview = () => {
    onClose();
  };

  const onLoaded = () => {
    setImageLoading(false);
  };

  const handlePublish = async () => {
    const result = await showConfirmationModal({
      title: "organization.publish_now?",
      message: "admin.publish_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "organization.publish_now",
      isAlert: true,
    });
    if (!result) {
      return;
    }
    if (result) {
      try {
        const result = await publishEvent(eventId);
        onClose();
        setNavigation?.();
        showSuccessToastMessage({
          message: result.message,
        });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };

  const handleSummaryViewMoreClick = () => {
    setIsViewMoreSummary((prev) => !prev);
  };

  useEffect(() => {
    if (eventDetails && eventDetails?.eventAbout?.coverImage.length > 0) {
      setImageLoading(true);
    }
  }, [eventDetails]);

  return (
    <div className="w-[1190px] bg-white max-h-[calc(100vh-50px)] flex flex-col justify-start rounded-md pb-16">
      {/* HEADER */}
      <div className="flex justify-between items-center px-10 py-5 border-b border-gray-light mb-10">
        <p className="font-semibold text-[22px] text-black">
          {t(showPrimaryButton ? "organization.preview" : "global.event_details")}
        </p>
        <div className="flex gap-2 justify-center items-center">
          <SecondaryButton title={"global.close"} handleClick={handleExitPreview} />
          {showPrimaryButton ? (
            <PrimaryButton
              title={"organization.publish_now"}
              handleClick={handlePublish}
              disabled={isPublishingEvent}
            />
          ) : null}
        </div>
      </div>
      {/* HEADER end*/}
      <div className="overflow-auto mt-1">
        <div className="w-full flex gap-10 px-10">
          <div className=" w-full">
            <div className="relative mb-10">
              {isImageLoading ? (
                <div className="w-full h-[250px]  rounded-md ">
                  <ImageLoader />
                </div>
              ) : null}
              <img
                alt="previewImage"
                className={`w-full max-h-[250px]  rounded-md ${
                  isImageLoading ? "h-[0px] overflow-hidden" : "h-[250px] "
                }`}
                src={
                  eventDetails?.eventAbout?.coverImage.length > 0
                    ? eventDetails?.eventAbout?.coverImage
                    : DefaultCoverImage
                }
                onLoad={onLoaded}
              />
            </div>
            <div className="flex gap-2 items-center truncate pb-2">
              <div className=" flex badgeBLock items-center">
                <Label
                  className={`!w-auto text-gray-107 text-xs bg-green-500 rounded-xl px-3 py-1.5 h-[30px] ${getEventTagClassName(
                    eventDetails?.eventAbout?.eventStatusId
                  )}`}
                  text={eventDetails?.eventAbout?.status ?? ""}
                />
              </div>
              <div className=" h-[14px] bg-gray-200  w-[1px] min-w-[1px]"></div>
              <div className=" flex badgeBLock gap-6 items-center">
                <Label
                  className=" !w-auto text-gradient-blueDark text-xs bg-primary-500 rounded-xl px-3 py-1.5  h-[30px]"
                  text={
                    eventDetails?.eventAbout?.targetIndustry
                      ? getMasterDataValue(eventDetails?.eventAbout?.targetIndustry, i18n.language)
                      : ""
                  }
                />
              </div>
            </div>
            <div className=" flex items-center mt-2">
              <h2 className="font-bold text-3xl  text-gray-112 Word-break">
                {eventDetails?.eventAbout?.title}
              </h2>
            </div>
            <div className="flex gap-4 items-center truncate mt-[10px]">
              <Descriptor
                title={`${t("global.start_date")} : ${displayDateFormat(
                  eventDetails?.eventAbout?.startDate ?? ""
                )}`}
                startIcon="previewDate"
                className="flex gap-2 items-center text-black text-sm"
              />
              <Descriptor
                title={`${t("global.end_date")} : ${displayDateFormat(
                  eventDetails?.eventAbout?.endDate ?? ""
                )}`}
                startIcon="previewDate"
                className="flex gap-2 items-center text-black text-sm"
              />
            </div>
            <div className="max-w-[90%]  WB_BW mt-2">
              <Descriptor
                title={eventDetails?.eventAbout?.location ?? ""}
                startIcon="previewLocation"
                className="flex gap-2 items-center text-black text-sm Word-break"
              />
            </div>
            {eventDetails?.eventAbout?.websiteUrl ? (
              <div className="flex items-center gap-2 mt-2">
                <div className=" w-4 h-4">
                  <SVGIcon name="worldIcon" />
                </div>
                <TextButton
                  title={"organization.website"}
                  handleClick={() => handleLinkClick(eventDetails?.eventAbout?.websiteUrl)}
                  className=" text-primary-medium font-normal underline text-sm"
                />
              </div>
            ) : null}

            {eventDetails?.eventAbout?.eventDescription?.length > 0 ? (
              <>
                <Divider className="my-[30px] !border-gray-light" />
                <div>
                  <SubTitle
                    title={t("global.summary")}
                    className="!justify-start font-semibold text-lg text-gray-109 leading-[26px] !mb-2.5 max-[1025px]:text-[18px] max-[1025px]:!mb-4"
                  />
                  <ContentViewWithToggle
                    data={eventDetails?.eventAbout?.eventDescription ?? ""}
                    handleViewMoreClick={handleSummaryViewMoreClick}
                    isViewMore={isViewMoreSummary}
                    maxLength={DETAIL_DESCRIPTION_MAX_CHAR_LENGTH}
                    className=" text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] me-1"
                    contentType="textAreaContent"
                  />
                </div>
              </>
            ) : null}

            {eventDetails?.eventLearn?.learnMore &&
            getPlainText(eventDetails?.eventLearn?.learnMore) > 0 ? (
              <>
                <Divider className="my-[30px] !border-gray-light" />
                <div>
                  <SubTitle
                    title={t("organization.challenge_learn_more")}
                    className="!justify-start font-semibold text-lg text-gray-109 leading-[26px] !mb-2.5 max-[1025px]:text-[18px] max-[1025px]:!mb-4"
                  />
                  <EditorContentPreview
                    data={eventDetails?.eventLearn?.learnMore}
                    className="text-black text-sm font-normal max-w-[100%] mt-2 mb-[26px] WB_BW dangerously-set-html"
                  />
                </div>
              </>
            ) : null}
            {eventDetails?.eventLearn?.documents?.length > 0 ? (
              <>
                <Divider className="my-[30px] !border-gray-light" />
                <div>
                  <h3 className="mt-[30px] font-semibold text-lg text-gray-112 !mb-2.5">
                    {t("organization.documents")}
                  </h3>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 justify-between gap-[10px] mt-1">
                  {eventDetails?.eventLearn?.documents?.map((item: FileDetails, index: number) => (
                    <div className="flex-1 truncate" key={index}>
                      <UploadedFileItem key={item.fileUrl + new Date()} isPreview file={item} />
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
          {isEventDetailsLoading || isPublishingEvent ? <LoaderModal /> : null}
        </div>
      </div>
    </div>
  );
};

export default PreviewEvent;
