import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export const getResearchInstitute = async ({
  pageNumber,
  pageLimit,
  searchKeyword,
  isGlobal,
}: SearchParams): Promise<ResearchInstituteResponse> => {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(
    urls.getResearchInstitute(
      pageNumber,
      pageLimit,
      searchKeyword ? encodeURIComponent(searchKeyword) : "",
      isGlobal
    ),
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res.data.data as ResearchInstituteResponse | ApiError;
};
