import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import SVGIcon from "assets/Icons";
import challengeDefault from "assets/images/challengeDefaultProfile.png";
import {
  Title,
  Descriptor,
  StatisticsDataCard,
  PrimaryButton,
  ImageLoader,
  Label,
  Header,
  InlineLoader,
  NoData,
  TableLoader,
} from "components";
import {
  COLLABORATION_USERS_INITIAL_PARAMS,
  COLLABORATION_USER_STATUS,
  FORUM_TYPES,
  NOMINATED_USERS_TABLE_HEADER,
  RESEARCH_SCHOLARS_TABLE_HEADERS,
} from "utils/constants";
import { displayDateFormat, getChallengeStatusTitle, getMasterDataValue } from "utils/helpers";
import { useStickyScroll } from "hooks/useStickyScroll";
import { useUserDataStore } from "store/useUserDataStore";
import { useMasterDataStore } from "store/useMasterDataStore";
import Table from "features/ResearchInstitute/Requests/ViewRequests/Table";
import NominatedScholarsTable from "features/ResearchInstitute/Requests/ViewRequests/NominatedScholarsTable";
import { setSelectedSideMenu, setSelectedUserStatusType } from "store/useSidebarStore";
import {
  showCollaborationPreviewModal,
  showConfirmationModal,
  showShareModal,
} from "utils/confirmationModals";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { useGetUsersOnCollaboration } from "features/Collaboration/api/useGetUsersOnCollaboration";
import { useGetCollaborationBasicDetails } from "features/Collaboration/api/useGetCollaborationBasicDetails";
import { useManageResearchScholarRequest } from "features/Collaboration/api/useManageResearchScholarRequest";

const ViewCollaborationRequests = () => {
  const navigate = useNavigate();
  const { collaborationId } = useParams();
  const { permissions } = useMasterDataStore();
  const { scroll, scrollValue } = useStickyScroll(12);

  const collaborationDataId = Number(collaborationId);
  const { t, i18n } = useTranslation();
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const [isImageLoading, setImageLoading] = useState(false);
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId ?? "";
  const [requestParams, setRequestParams] = useState<CollaborationUserParams>({
    ...COLLABORATION_USERS_INITIAL_PARAMS,
    collaborationId: collaborationDataId,
    userId: userId ?? "",
    researchInstitute: "",
    statusId: COLLABORATION_USER_STATUS.InstitutePending,
    isRequest: true,
  });

  const { data: collaborationData, isLoading: isLoadingChallengeData } =
    useGetCollaborationBasicDetails(collaborationDataId);
  const {
    data,
    isLoading: isLoadingCollaborationUsers,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useGetUsersOnCollaboration(requestParams);

  const { mutateAsync: manageRequest, isLoading: isRequestLoading } =
    useManageResearchScholarRequest();

  useEffect(() => {
    refetch();
  }, [requestParams.statusId, i18n.language, refetch]);

  const users = useMemo(() => {
    const tempData: ChallengeUsersResponse = {
      researchers: [],
      totalCount: 0,
      totalPages: 0,
      summary: [],
    };
    if (data?.pages && data?.pages[0]) {
      tempData.summary = data.pages[0]?.summary;
      tempData.totalCount = data.pages[0]?.totalCount;
      tempData.totalPages = data.pages[0]?.totalPages;
      const researchers = data.pages
        .map((item) => {
          return item.researchers;
        })
        .flat();
      tempData.researchers = researchers;
    }
    return tempData;
  }, [data]);

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const getBreadCrumbPath = (): Array<LinkItem> => {
    return [
      {
        title: "research_institute.requests",
      },
      {
        title: "research_institute.view_collaboration",
      },
    ];
  };

  const linkList: Array<LinkItem> = getBreadCrumbPath();

  const handleCardClick = (id: number) => {
    setSelectedUserStatusType(id);
    if (id !== requestParams.statusId) {
      setRequestParams((prevState) => ({ ...prevState, statusId: id }));
    }
  };

  const getChallengeTags = () => {
    const tags = collaborationData?.collaborationTags?.map((collaboration) => {
      return i18n.language === "ar" ? collaboration?.name.ar : collaboration?.name.en;
    });
    return tags?.length ? tags?.toString().replace(/,/g, ", ") : "";
  };

  const getSubTitle = () => {
    if (requestParams?.statusId === COLLABORATION_USER_STATUS.InstitutePending) {
      return "global.pending_approvals";
    } else {
      return "research_institute.nominated_scholars";
    }
  };

  useEffect(() => {
    if (collaborationData && collaborationData && collaborationData?.coverImage?.length > 0) {
      setImageLoading(true);
    }
  }, [collaborationData]);

  const onLoaded = () => {
    setImageLoading(false);
  };

  const handleItemClick = (challengeId: number) => {
    navigate(`research-scholar/${challengeId}`);
    setSelectedUserStatusType(requestParams.statusId);
  };

  const handleWithDraw = async (event: React.MouseEvent<HTMLDivElement>, scholarId: string) => {
    event.stopPropagation();
    event.preventDefault();
    const result = await showConfirmationModal({
      title: "research_institute.confirm_withdraw",
      message: "research_institute.withdraw_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "research_institute.withdraw",
      modalIcon: "withdrawLargeIcon",
      isAlert: true,
    });
    if (!result) {
      return;
    }
    if (result) {
      try {
        const result = await manageRequest({
          collaborationId: collaborationDataId,
          scholarId: scholarId ?? "",
          status: COLLABORATION_USER_STATUS.Withdraw,
        });

        showSuccessToastMessage({
          message: result.message,
        });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };

  const handleShare = () => {
    const shareLink = ` ${window.location.origin}/research-institute/collaborations/${collaborationId}`;
    showShareModal(shareLink, "global.share_collaboration");
  };

  const handlePreview = () => {
    showCollaborationPreviewModal({
      collaborationId: Number(collaborationId),
      showPrimaryButton: false,
    });
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "research_institute.requests",
    });
  }, []);

  return (
    <>
      <div
        className={`w-full h-full flex flex-col  ${!isOpenSideMenu ? "miniSidebar" : ""} ${
          scrollValue > 0 ? "window-scroll" : ""
        }`}
      >
        <Header
          linkList={linkList}
          title={collaborationData?.title ?? ""}
          handleCollapseClick={onToggleClick}
          className={
            scroll
              ? " bg-white animate__animated animate__fadeInDown !py-[26px] !h-[90px] fixed stickyHeader z-[41]"
              : "fixed"
          }
          displayGlobalTag={collaborationData?.isGlobal && !userInfo?.isGlobal}
        />
        <div className="adminDetails w-full pt-[118px] pb-[45px] items-baseline px-10 ltr:pr-[30px] rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto ">
          <div className="w-full flex flex-col h-full space-y-3">
            {/* card of challenge details */}
            <div
              className={
                scroll
                  ? " bg-white rounded-md p-4 flex challengeInfo sticky animate__animated animate__fadeInUp shadow-sticky  z-40"
                  : "bg-white rounded-md p-4 flex challengeInfo relative z-40"
              }
            >
              {isLoadingChallengeData ? (
                <div className=" flex items-center justify-center h-auto w-full">
                  <InlineLoader />
                </div>
              ) : (
                <>
                  <div className="  min-w-[120px] max-w-[120px] h-[130px] rounded-md overflow-hidden imageHolder">
                    {isImageLoading ? <ImageLoader /> : null}
                    <img
                      alt="profile picture"
                      className={`w-full h-[130px] object-cover ${
                        isImageLoading ? "h-[0px] overflow-hidden" : "h-[130px] overflow-hidden"
                      }`}
                      src={
                        collaborationData && collaborationData?.coverImage?.length > 0
                          ? collaborationData?.coverImage
                          : challengeDefault
                      }
                      onLoad={onLoaded}
                    />
                  </div>
                  <div className=" px-5 dataHolder overflow-hidden">
                    <div className="flex gap-4 items-center truncate">
                      <div className=" flex badgeBLock space-x-2 rtl:space-x-reverse">
                        <Label
                          className=" !w-auto text-gray-107 text-xs bg-green-500 rounded-xl px-3 py-1.5 "
                          text={t(
                            getChallengeStatusTitle(collaborationData?.collaborationStatusId)
                          )}
                        />
                        <Label
                          className=" !w-auto text-gray-107 text-xs bg-green-500 rounded-xl px-3 py-1.5 "
                          text={t(
                            collaborationData?.isPrivate
                              ? "organization.private"
                              : "organization.public"
                          )}
                        />
                      </div>
                      <div className=" h-[14px] bg-gray-200 w-[1px]"></div>

                      <div className="truncate">
                        <Label
                          className=" !w-auto text-gradient-blueDark text-xs !block bg-primary-500 rounded-xl px-3 py-1.5  h-[30px] truncate max-w-[200px]"
                          text={t(
                            collaborationData?.targetIndustry
                              ? getMasterDataValue(collaborationData?.targetIndustry, i18n.language)
                              : ""
                          )}
                        />
                      </div>
                    </div>
                    <div className=" flex flex-col space-y-3 pt-3 dataWrapper truncate">
                      <div className="flex space-x-10 rtl:space-x-reverse truncate sessionOne">
                        <Descriptor
                          title={collaborationData?.location ?? ""}
                          startIcon="mapPin"
                          className="info  !mb-0 min-w-[176px] flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse truncate"
                        />
                      </div>
                      <div className="flex space-x-10 rtl:space-x-reverse truncate sessionTwo">
                        <Descriptor
                          title={`${t("global.start_date")}: ${displayDateFormat(
                            collaborationData?.startDate ?? ""
                          )}`}
                          startIcon="date"
                          className="info !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW date"
                        />
                        <Descriptor
                          title={`${t("global.end_date")}: ${displayDateFormat(
                            collaborationData?.endDate ?? ""
                          )}`}
                          startIcon="date"
                          className="info !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
                        />
                      </div>
                      <div className="flex space-x-10 rtl:space-x-reverse truncate sessionThree ">
                        {collaborationData?.collaborationTags?.length ? (
                          <Descriptor
                            title={getChallengeTags()}
                            startIcon="tag"
                            className="info !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW truncate"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="buttonHolder ltr:ml-auto rtl:mr-auto flex min-w-[215px] justify-end ">
                    {permissions?.Viewpost && collaborationData?.isDiscussionForumEligible ? (
                      <PrimaryButton
                        title={"forum.discussion_forum"}
                        handleClick={() => {
                          navigate("discussion-forum");
                        }}
                        className=" !h-[38px] min-h-max"
                      />
                    ) : null}
                    <div className=" space-x-2 rtl:space-x-reverse flex ltr:ml-2 rtl:mr-2">
                      <button className="eye-button" onClick={handleShare}>
                        <SVGIcon name="searchV5" />
                      </button>
                      <button className="eye-button" onClick={handlePreview}>
                        <SVGIcon name="eye" />
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* card of challenge details end */}
            {/* {permissions?.Viewchallengerequests ||
            permissions?.Viewapprovedscholars ||
            permissions?.ViewfundingorganizationInterests ? ( */}
            <>
              {/* statistics card start*/}
              <div className=" pt-10">
                <Title
                  title={t("global.manage")}
                  className=" text-base text-black font-semibold mb-4"
                />

                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-[10px] text-white selectedCard relative">
                  {users && users?.summary?.length > 0 ? (
                    users?.summary?.map((item, index) => {
                      return (
                        <StatisticsDataCard
                          key={`${item.name}-${index}-${item.count}`}
                          data={{
                            title: item.name,
                            value: item.count,
                            type: item.name,
                          }}
                          handleClick={() => handleCardClick(item.id)}
                          isActive={item.id === requestParams.statusId}
                        />
                      );
                    })
                  ) : (
                    <InlineLoader />
                  )}
                </div>
              </div>

              {/**statistics card end */}
              <div className=" pt-[30px] pb-5">
                <Title
                  title={t(getSubTitle())}
                  className=" text-base text-black font-semibold mb-4"
                />
                {users && users.totalCount && users.totalCount > 0 ? (
                  <InfiniteScroll
                    dataLength={users?.researchers?.length}
                    next={fetchNextPage}
                    hasMore={!!hasNextPage}
                    loader={hasNextPage || isLoadingCollaborationUsers ? <TableLoader /> : null}
                    scrollThreshold="200px"
                  >
                    <div className=" flex flex-col p-4 pt-[18px] w-full h-full items-center rounded-md justify-start bg-white">
                      {requestParams.statusId === COLLABORATION_USER_STATUS.InstitutePending ? (
                        <Table
                          userData={users?.researchers}
                          headers={RESEARCH_SCHOLARS_TABLE_HEADERS}
                          handleItemClick={(challengeId) => {
                            handleItemClick(challengeId);
                          }}
                          type={FORUM_TYPES.collaborations}
                        />
                      ) : (
                        <NominatedScholarsTable
                          userData={users?.researchers}
                          headers={NOMINATED_USERS_TABLE_HEADER}
                          handleItemClick={(challengeId) => {
                            handleItemClick(challengeId);
                          }}
                          handleWithDraw={handleWithDraw}
                          type={FORUM_TYPES.collaborations}
                        />
                      )}
                    </div>
                  </InfiniteScroll>
                ) : isLoadingCollaborationUsers ? (
                  <div
                    className={` flex flex-col w-full h-auto xl:h-full items-center rounded-md justify-start bg-white mb-[45px] noData`}
                  >
                    <div className=" flex flex-col items-center justify-start my-[102px]">
                      <InlineLoader />
                    </div>
                  </div>
                ) : (
                  <NoData className=" !mb-0 h-full" message="admin.no_data_to_show" />
                )}
              </div>
            </>
            {/* ) : null} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCollaborationRequests;
