import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { RESEARCH_SCHOLAR_CHALLENGE_REQUEST_STATUS } from "utils/constants";
import { urls } from "utils/urls";
async function manageScholarRequest(challengeId: number, scholarId: string, status: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(
    urls.manageScholarRequest(challengeId, scholarId, status),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus | ApiError;
}

export function useManageScholarRequest() {
  const qc = useQueryClient();
  return useMutation(
    async ({
      challengeId,
      scholarId,
      status,
    }: {
      challengeId: number;
      scholarId: string;
      status: number;
    }) => {
      const result = await manageScholarRequest(challengeId, scholarId, status);

      qc.resetQueries(["challenge-users"]);
      qc.invalidateQueries(["challenge-basic-details", challengeId]);
      if (status !== RESEARCH_SCHOLAR_CHALLENGE_REQUEST_STATUS.Remove) {
        qc.invalidateQueries(["user-challenge-status", challengeId, scholarId]);
        qc.resetQueries(["research-scholar-details"]);
      }
      return result;
    }
  );
}
