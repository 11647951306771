import { useQuery } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getOrganizationTypes() {
  const res = await apiClient.get(urls.organizationTypes());
  const response = res.data?.data;

  return response as Array<MasterDataItem>;
}

export function useGetOrganizationTypes() {
  return useQuery(
    ["organization-types"],
    async () => {
      return await getOrganizationTypes();
    },
    {
      staleTime: Infinity,
    }
  );
}
