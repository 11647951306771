import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function deletePost(collaborationId: number, postId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.delete(urls.collaborationDeletePost(collaborationId, postId), {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response?.data as ApiStatus | ApiError;
}

export function useDeletePost() {
  const qc = useQueryClient();
  return useMutation(async ({ collaborationId, postId }: { collaborationId: number; postId: number }) => {
    const result = await deletePost(collaborationId, postId);
    qc.resetQueries(["forums"]);
    qc.resetQueries(["forum-replies"]);
    qc.invalidateQueries({
      queryKey: ["forum-posts", collaborationId],
    });
    qc.resetQueries(["collaboration-basic-details"]);
    qc.resetQueries(["message-threads"]);
    return result;
  });
}
