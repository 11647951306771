import { useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { SubTitle, Title, TextArea, SecondaryButton, PrimaryButton, LoaderModal } from "components";
import { CHALLENGE_STATUS, DETAIL_DESCRIPTION_MAX_CHAR_LENGTH } from "utils/constants";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { useCollaborationChangeStatus } from "features/Collaboration/api/useCollaborationChangeStatus";


type CollaborationStatusUpdateModalProps = {
  onProceed: () => void;
  onClose: () => void;
  title: string;
  message: string;
  collaborationId: number;
  status: number;
};

export default function CollaborationStatusUpdateModal({
  onProceed,
  onClose,
  title,
  message,
  collaborationId,
  status,
}: CollaborationStatusUpdateModalProps) {
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState(false);
  const { mutateAsync: declineWithReasonCollaboration, isLoading } = useCollaborationChangeStatus();
  const [reason, setReason] = useState("");

  const handleProceed = async () => {
    if (status === CHALLENGE_STATUS.Decline) {
      if (!reason.trim().length) {
        setValidationErrors(true);
        return;
      }
    }

    try {
      const result = await declineWithReasonCollaboration({
        status: CHALLENGE_STATUS.Decline,
        collaborationId: collaborationId,
        specificReason: reason.trim()
      });
      showSuccessToastMessage({
        message: result.message,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    } finally {
      onProceed();
    }
  };

  return (
    <div className="fixed h-screen w-screen bg-black/[0.3] z-[99] flex justify-center items-center top-0 ltr:left-0 rtl:right-0 ">
      <div className="animate__animated animate__zoomIn flex flex-col rounded-md items-center bg-white max-w-[470px] mx-2 sm:w-[470px] py-[50px] px-8 max-h-[calc(100vh-3.125rem)] overflow-auto">
        <div>
          <SVGIcon name="documentDecline" />
        </div>
        <Title
          title={title}
          className=" text-xl font-semibold text-black mt-4 lg:mt-10 pb-1 leading-normal"
        />
        <SubTitle
          title={message}
          className=" text-base text-primary-text px-5 mt-1 mb-[30px] text-center"
        />
        <TextArea
          textContent={reason}
          handleChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            setReason(event?.target?.value)
          }
          labelText=""
          hintText={t("admin.enter_decline_reason")}
          fieldName="reason"
          maxCharLength={DETAIL_DESCRIPTION_MAX_CHAR_LENGTH}
          errorMessage={validationErrors ? "required" : ""}
        />
        <div className=" flex w-full justify-center space-x-[10px] rtl:space-x-reverse mt-[38px]">
          <SecondaryButton
            title={"global.close"}
            handleClick={onClose}
            className=" flex-1 sm:flex-[0] sm:min-w-[180px]"
          />
          <PrimaryButton
            title={"global.decline"}
            handleClick={handleProceed}
            className=" flex-1 sm:flex-[0] sm:min-w-[180px]"
          />
        </div>
      </div>
      {isLoading ? <LoaderModal /> : null}
    </div>
  );
}
