import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { Title } from "components";
import Profile from "assets/images/avatar.svg";
import { useUserDataStore } from "store/useUserDataStore";

type SidebarBottomNavigationProps = {
  handleLogoutClick?: () => void;
};

export function SidebarBottomNavigation({ handleLogoutClick }: SidebarBottomNavigationProps) {
  const { userInfo } = useUserDataStore();
  const { t } = useTranslation();

  return (
    <div className=" mt-auto mb-auto md:mb-0 flex flex-col justify-center items-center lg:ltr:pr-4 lg:rtl:pl-4  collapsed-side-bar">
      <div className=" w-[50px] h-[50px] min-h-[50px] min-w-[50px]  rounded-full  overflow-hidden">
        <img
          alt="profile picture"
          className="text- object-cover w-[50px] h-[50px]"
          src={userInfo?.avatarUrl ? userInfo?.avatarUrl : Profile}
          key={userInfo?.avatarUrl ?? `${new Date()}`}
        />
      </div>
      <Title
        className="font-semibold text-white text text-sm mt-3 truncate max-w-[200px] side-bar-mobile"
        title={userInfo?.firstName ? userInfo?.firstName : ""}
      />
      <div
        className=" font-light text-sm text-white bg-primary-700 hover:bg-secondary-medium rounded-full justify-center mx-auto  flex  items-center cursor-pointer  py-2.5 mt-5 lg:mt-[18px] px-3  rtl:pl-[18px]  border border-primary-800 logoutBtn mb-10 max-[1550px]:mb-5"
        onClick={handleLogoutClick}
      >
        <SVGIcon name="logOut" />
        <span className="text  ltr:pl-1.5 rtl:pr-1.5">{t("global.logout")}</span>
      </div>
    </div>
  );
}
