import React from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { useSidebarDataStore } from "store/useSidebarStore";

type MainMenuProps = {
  type: string;
  title: string;
  icon: string;
  handleMenuClick: (type: string) => void;
  children?: React.ReactNode;
  isActive?: boolean;
  hasSubMenu?: boolean;
};

export function MainMenu({
  type,
  title,
  icon,
  children,
  handleMenuClick,
  isActive,
  hasSubMenu,
}: MainMenuProps) {
  const { t } = useTranslation();

  const { selectedSideMenu } = useSidebarDataStore();

  return (
    <li className=" flex items-start flex-col justify-start w-full py-1 lg:py-0">
      <div
        className={`navItem !bg-transparent cursor-pointer flex items-center text-white text-base  w-full space-x-[14px] rtl:space-x-reverse p-0 rounded ${isActive ? " opacity-100 font-medium " : " opacity-50 font-normal"
          } ${selectedSideMenu?.isOpen && type === selectedSideMenu?.type ? "openMenu" : ""}`}
        onClick={() => {
          handleMenuClick(type);
        }}
      >
        <SVGIcon name={icon} />
        <div className="text !ml-0 pt-[2px]">{t(title)}</div>
        {hasSubMenu ? (
          <div className=" w-[10px] ltr:!ml-auto rtl:!mr-auto rotate-0 arrow rtl:!ml-0 ">
            <SVGIcon name="downArrow" />
          </div>
        ) : null}
      </div>
      {children ? (
        <div className="subMenu flex !bg-transparent gap-0 flex-wrap justify-start space-y-4 w-full mt-6 !p-0 text-white font-light text-sm  ">
          {children}
        </div>
      ) : null}
    </li>
  );
}
