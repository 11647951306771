import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";
async function updateCollaborationStatus(status: number, collaborationId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(
    urls.updateCollaborationStatus(status, collaborationId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus | ApiError;
}

export function useUpdateCollaborationStatus() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ status, collaborationId }: { status: number; collaborationId: number }) => {
      const result = await updateCollaborationStatus(status, collaborationId);
      queryClient.invalidateQueries(["collaboration-about", collaborationId]);
      queryClient.invalidateQueries(["collaboration-basic-details", collaborationId]);
      queryClient.invalidateQueries(["collaboration-preview-details", collaborationId]);
      return result;
    }
  );
}
