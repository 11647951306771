import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Header, LoaderModal } from "components";
import Tags from "features/Collaboration/CreateCollaboration/Tags";
import About from "features/Collaboration/CreateCollaboration/About";
import Funding from "features/Collaboration/CreateCollaboration/Funding";
import Contacts from "features/Collaboration/CreateCollaboration/Contacts";
import Artifacts from "features/Collaboration/CreateCollaboration/Artifacts";
import LearnMore from "features/Collaboration/CreateCollaboration/LearnMore";
import Milestones from "features/Collaboration/CreateCollaboration/Milestones";
import Navigator from "features/Collaboration/CreateCollaboration/Navigator";
import { useStickyScroll } from "hooks/useStickyScroll";
import { showCollaborationPreviewModal, showConfirmationModal } from "utils/confirmationModals";
import {
  CHALLENGE_STATUS,
  CREATE_CHALLENGE_COMPLETION_STATUS,
  CREATE_CHALLENGE_PATH_PATTERN,
  TOASTER_DURATION,
} from "utils/constants";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import { challengeContactValidation } from "utils/validation/challengeContactValidation";
import { collaborationAboutValidation } from "utils/validation/collaborationAboutValidation";
import { challengeLearnMoreValidation } from "utils/validation/challengeLearnMoreValidation";
import { challengeMilestonesValidation } from "utils/validation/challengeMilestoneValidation";
import { useGetCollaborationTags } from "features/Collaboration/api/useGetCollaborationTags";
import { useSaveCollaborationTags } from "features/Collaboration/api/useSaveCollaborationTags";
import { useGetCollaborationAbout } from "features/Collaboration/api/useGetCollaborationAbout";
import { useSaveCollaborationAbout } from "features/Collaboration/api/useSaveCollaborationAbout";
import { useGetCollaborationFunding } from "features/Collaboration/api/useGetCollaborationFunding";
import { useGetCollaborationContacts } from "features/Collaboration/api/useGetCollaborationContacts";
import { useSaveCollaborationFunding } from "features/Collaboration/api/useSaveCollaborationFunding";
import { useGetCollaborationLearnMore } from "features/Collaboration/api/useGetCollaborationLearnMore";
import { useGetCollaborationArtifacts } from "features/Collaboration/api/useGetCollaborationArtifacts";
import { useSaveCollaborationContacts } from "features/Collaboration/api/useSaveCollaborationContacts";
import { useGetCollaborationMilestones } from "features/Collaboration/api/useGetCollaborationMilestones";
import { useSaveCollaborationLearnMore } from "features/Collaboration/api/useSaveCollaborationLearnMore";
import { useSaveCollaborationMilestone } from "features/Collaboration/api/useSaveCollaborationMilestones";
import { useSaveCollaborationAdditionalInfo } from "features/Collaboration/api/useSaveCollaborationArtifacts";
import {
  setCollaborationInfoItem,
  useCollaborationDataStore,
} from "store/useCollaborationDataStore";
import {
  checkUnsavedAdditionalInfo,
  checkUnsavedDetails,
  checkUnsavedFundingData,
  prepareAboutFormData,
  prepareAdditionalInfoFormData,
  prepareContactFormData,
  prepareContactTagsFormData,
  prepareFundingFormData,
  prepareLearnMoreFormData,
  prepareMileStoneFormData,
} from "features/Collaboration/CreateCollaboration/helpers";
import {
  checkUnsavedContacts,
  checkUnsavedTags,
  isUnSavedContactsExist,
  isUnSavedMileStoneExist,
  isUnsavedAdditionalInfoExist,
  isUnsavedTextContent,
} from "features/Organization/Challenge/CreateChallenge/helper";
import { useNavigate, useParams } from "react-router-dom";
import { setSelectedFormId, setSelectedSideMenu, useSidebarDataStore } from "store/useSidebarStore";
import { collaborationLearnMoreValidation } from "utils/validation/collaborationLearnMoreValidation";
import { challengeEditorValidation } from "utils/validation/challengeEditorValidation";

export default function CreateCollaboration() {
  const navigate = useNavigate();
  const { collaborationId } = useParams();
  const { selectedFormId } = useSidebarDataStore();
  const pathname = window.location.pathname;
  const { t } = useTranslation();
  const [stepNumber, setStepNumber] = useState(1);
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const { scroll } = useStickyScroll();
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string> | null | Array<Record<string, string> | null>
  >(null);
  let previewDelayDuration = 500;

  const collaborationInfo = useCollaborationDataStore().collaborationInfo;
  const milestoneInfo = useCollaborationDataStore.getState().milestones;
  const fundingInfo = useCollaborationDataStore.getState()?.funding;
  const learnMoreInfo = useCollaborationDataStore.getState().learnMore;
  const additionalInfo = useCollaborationDataStore.getState().additionalInfo;
  const tagsInfo = useCollaborationDataStore.getState().tags;
  const contactsInfo = useCollaborationDataStore.getState().contacts;

  const { data: aboutData, isLoading: isCollaborationInfoLoading } = useGetCollaborationAbout();
  const { data: milestoneData, isLoading: isCollaborationMilestoneLoading } =
    useGetCollaborationMilestones();
  const { data: learnMoreData, isLoading: isCollaborationLearnMoreLoading } =
    useGetCollaborationLearnMore();
  const { data: fundingData, isLoading: isCollaborationFundingLoading } =
    useGetCollaborationFunding();
  const { data: artifactsData, isLoading: isArtifactsLoading } = useGetCollaborationArtifacts();
  const { data: contactsDetails, isLoading: isContactsLoading } = useGetCollaborationContacts();
  const { data: tagsData, isLoading: isTagsLoading } = useGetCollaborationTags();

  const { mutateAsync: saveCollaborationInfo, isLoading: isCollaborationSaving } =
    useSaveCollaborationAbout();
  const { mutateAsync: saveCollaborationMilestone, isLoading: isMilestoneSaving } =
    useSaveCollaborationMilestone();
  const { mutateAsync: saveCollaborationLearnMore, isLoading: isLearnMoreSaving } =
    useSaveCollaborationLearnMore();
  const { mutateAsync: saveCollaborationFunding, isLoading: isFundingSaving } =
    useSaveCollaborationFunding();
  const { mutateAsync: saveCollaborationArtifacts, isLoading: isArtifactsSaving } =
    useSaveCollaborationAdditionalInfo();
  const { mutateAsync: saveCollaborationContacts, isLoading: isContactsSaving } =
    useSaveCollaborationContacts();
  const { mutateAsync: saveCollaborationTags, isLoading: isTagsSaving } =
    useSaveCollaborationTags();

  const isEdit =
    aboutData?.collaborationStatusId === CHALLENGE_STATUS.Open ||
    aboutData?.collaborationStatusId === CHALLENGE_STATUS.Live ||
    aboutData?.collaborationStatusId === CHALLENGE_STATUS.Pending;

  const navigationItems: Array<RecordItem> = [
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.About,
      label: "organization.about",
    },
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.Milestones,
      label: "organization.milestones",
    },
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.LearnMore,
      label: "organization.learn_more",
    },
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.Funding,
      label: "organization.funding",
    },
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.Artifacts,
      label: "organization.additional_info_and_artifacts",
    },
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.Contacts,
      label: "organization.contact",
    },
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.Tags,
      label: "organization.tags",
    },
  ];
  const getCollaborationBreadCrumb = () => {
    if (collaborationId) {
      return { title: "research_institute.edit_collaboration" };
    } else {
      return {
        title: "research_institute.new_collaboration",
      };
    }
  };
  const getBreadCrumbPath = () => {
    if (pathname.includes("explore")) {
      const path = [
        {
          title: "research_institute.explore_collaborations",
        },
      ];
      if (!pathname.includes("explore/create")) {
        path.push({
          title: "research_institute.view_collaboration",
        });
      }
      path.push(getCollaborationBreadCrumb());
      return path;
    } else if (
      collaborationId &&
      collaborationInfo?.collaborationStatusId !== CHALLENGE_STATUS.Draft &&
      !CREATE_CHALLENGE_PATH_PATTERN.test(window.location.pathname)
    ) {
      return [
        {
          title: "global.collaborations",
        },
        {
          title: "research_institute.view_collaboration",
        },
        getCollaborationBreadCrumb(),
      ];
    } else {
      return [
        {
          title: "global.collaborations",
        },
        getCollaborationBreadCrumb(),
      ];
    }
  };

  const LINK_LIST: Array<LinkItem> = getBreadCrumbPath();

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const handleSaveDraft = async () => {
    let result = false;
    if (stepNumber === 1) {
      result = await handleSaveAbout();
    } else if (stepNumber === 2) {
      result = await handleSaveMilestones();
    } else if (stepNumber === 3) {
      result = await handleSaveLearnMore();
    } else if (stepNumber === 4) {
      result = await handleSaveFunding();
    } else if (stepNumber === 5) {
      result = await handleSaveAdditionalInfo();
    } else if (stepNumber === 6) {
      result = await handleSaveContacts();
    } else if (stepNumber === 7) {
      result = await handleSaveTags();
    }
    return result;
  };

  const handleSaveNext = async () => {
    if (stepNumber === 1) {
      const success = await handleSaveAbout(true);
      if (success) moveToNext();
    } else if (stepNumber === 2) {
      const success = await handleSaveMilestones(true);
      if (success) moveToNext();
    } else if (stepNumber === 3) {
      const success = await handleSaveLearnMore(true);
      if (success) moveToNext();
    } else if (stepNumber === 4) {
      const success = await handleSaveFunding(true);
      if (success) moveToNext();
    } else if (stepNumber === 5) {
      const success = await handleSaveAdditionalInfo(true);
      if (success) moveToNext();
    } else if (stepNumber === 6) {
      const success = await handleSaveContacts(true);
      if (success) moveToNext();
    } else if (stepNumber === 7) {
      try {
        const success = await handleSaveTags(true);
        if (success) {
          const showPrimaryButton =
            collaborationInfo.collaborationStatusId !== CHALLENGE_STATUS.Open &&
            collaborationInfo.collaborationStatusId !== CHALLENGE_STATUS.Live;

          setTimeout(() => {
            showCollaborationPreviewModal({
              collaborationId: collaborationInfo?.id,
              setNavigation: () => navigate("/research-institute/collaborations", { replace: true }),
              showPrimaryButton: showPrimaryButton,
            });
          }, previewDelayDuration);
        }
      } catch (error) {
        showErrorToastMessage({
          message: t("global.something_went_wrong"),
        });
      }
    }
  };

  const moveToNext = () => {
    if (stepNumber >= 0 && stepNumber <= 7) {
      const currentStep = stepNumber;
      const nextStep = currentStep + 1;
      setStepNumber(nextStep);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const removeValidationErrors = () => {
    setValidationErrors(null);
  };

  const showConfirmNavigationModal = async () => {
    const result = await showConfirmationModal({
      title: "organization.confirm_proceed",
      message: "organization.challenge_unsaved_section_confirm",
      cancelButtonText: "global.close",
      proceedButtonText: "global.proceed",
      modalIcon: "confirmSelection",
      isAlert: true,
    });
    return result;
  };

  const handleNavigationClick = async (value: number) => {
    if (stepNumber === value) return;
    let isSaveRequired = false;

    if (stepNumber === 1) {
      const collaborationInfo = useCollaborationDataStore.getState().collaborationInfo;
      isSaveRequired = checkUnsavedDetails(collaborationInfo, aboutData);
    } else if (stepNumber === 2) {
      isSaveRequired = isUnSavedMileStoneExist(milestoneInfo.milestones, milestoneData?.milestones);
    } else if (stepNumber === 3) {
      isSaveRequired = checkUnsavedDetails(learnMoreInfo, learnMoreData);
    } else if (stepNumber === 4) {
      isSaveRequired = isUnsavedTextContent(
        fundingInfo?.funding,
        fundingData?.funding,
        CREATE_CHALLENGE_COMPLETION_STATUS.Funding
      );
    } else if (stepNumber === 5) {
      isSaveRequired = isUnsavedAdditionalInfoExist(additionalInfo, artifactsData);
    } else if (stepNumber === 6) {
      isSaveRequired = isUnSavedContactsExist(contactsInfo.contacts, contactsDetails?.contacts);
    } else if (stepNumber === 7) {
      isSaveRequired = checkUnsavedTags(tagsInfo.tags, tagsData?.tags) ? true : false;
    }

    if (isSaveRequired) {
      const confirm = await showConfirmNavigationModal();
      if (confirm) {
        setPage(value);
      }
    } else {
      setPage(value);
    }
  };

  const setPage = (value: number) => {
    removeValidationErrors();
    setStepNumber(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePreview = async () => {
    await showCollaborationPreviewModal({
      collaborationId: collaborationInfo?.id,
      setNavigation: () => setStepNumber(CREATE_CHALLENGE_COMPLETION_STATUS.About),
      showPrimaryButton: false,
    });
  };

  const handleSaveAbout = async (isNext = false) => {
    if (collaborationInfo) {
      const error = collaborationAboutValidation(collaborationInfo);
      if (error) {
        setValidationErrors(error);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return false;
      }
      setValidationErrors(null);
      const isSaveRequired = checkUnsavedDetails(collaborationInfo, aboutData);
      if (isSaveRequired) {
        try {
          const formData = prepareAboutFormData(collaborationInfo);
          const response = await saveCollaborationInfo(formData);

          if (!formData.id) {
            const collaborationId = response?.data?.collaborationId;
            setCollaborationInfoItem(Number(collaborationId), "id");
            setSelectedFormId(collaborationId);
          }
          showInfoToastMessage({
            iconType: ToasterIconType.UPDATE,
            message: response.message,
          });

          return true;
        } catch (error: ApiError) {
          showErrorToastMessage({
            message:
              error?.response?.data?.status < 500
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
          return false;
        }
      } else if (isNext) {
        return true;
      }
    }
    return false;
  };

  const handleSaveMilestones = async (isNext = false) => {
    if (milestoneInfo) {
      const errors: Array<Record<string, string> | null> = [];
      milestoneInfo?.milestones?.map((item: MileStone) => {
        const error = challengeMilestonesValidation(item);
        errors.push(error);
      });
      if (errors?.filter((item) => item !== null).length > 0) {
        setValidationErrors(errors);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return false;
      }
      setValidationErrors(null);

      const isSaveRequired =
        checkUnsavedDetails(milestoneInfo, milestoneData) ||
        (aboutData?.collaborationCompletionStatusId &&
          aboutData?.collaborationCompletionStatusId <
            CREATE_CHALLENGE_COMPLETION_STATUS.Milestones);

      if (isSaveRequired) {
        try {
          const milestonesFormData = prepareMileStoneFormData(milestoneInfo.milestones);
          const result = await saveCollaborationMilestone({
            mileStoneFormData: milestonesFormData,
            collaborationId: collaborationInfo.id,
          });

          showInfoToastMessage({
            iconType: ToasterIconType.UPDATE,
            message: result.message,
          });
          return true;
        } catch (error: ApiError) {
          showErrorToastMessage({
            message:
              error?.response?.data?.status < 500
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
          return false;
        }
      } else if (isNext) {
        return true;
      }
    }
    return false;
  };

  const handleSaveLearnMore = async (isNext = false) => {
    if (learnMoreInfo) {
      const error = collaborationLearnMoreValidation(learnMoreInfo);
      if (error) {
        setValidationErrors(error);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return false;
      }
      setValidationErrors(null);
      const isSaveRequired = checkUnsavedDetails(learnMoreInfo, learnMoreData);

      if (isSaveRequired) {
        try {
          const formData = prepareLearnMoreFormData(learnMoreInfo);
          const result = await saveCollaborationLearnMore({
            formData: formData,
            collaborationId: collaborationInfo.id,
          });

          showInfoToastMessage({
            iconType: ToasterIconType.UPDATE,
            message: result.message,
          });
          return true;
        } catch (error: ApiError) {
          showErrorToastMessage({
            message:
              error?.response?.data?.status < 500
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
          return false;
        }
      } else if (isNext) {
        return true;
      }
    }
    return false;
  };

  const handleSaveFunding = async (isNext = false) => {
    if (fundingInfo) {
      const validation = challengeEditorValidation(fundingInfo?.funding);
      if (!validation) {
        setValidationErrors({ value: "required" });
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return false;
      }
      setValidationErrors(null);

      const isSaveRequired = checkUnsavedFundingData(fundingInfo.funding, fundingData?.funding);
      if (isSaveRequired) {
        try {
          const formData = prepareFundingFormData(fundingInfo);
          const result = await saveCollaborationFunding({
            formData: formData,
            collaborationId: collaborationInfo.id,
          });

          showInfoToastMessage({
            iconType: ToasterIconType.UPDATE,
            message: result.message,
          });
          return true;
        } catch (error: ApiError) {
          showErrorToastMessage({
            message:
              error?.response?.data?.status < 500
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
          return false;
        }
      } else if (isNext) {
        return true;
      }
    }
    return false;
  };

  const handleSaveAdditionalInfo = async (isNext = false) => {
    if (additionalInfo) {
      const validation = challengeEditorValidation(additionalInfo.information);
      if (!validation) {
        setValidationErrors({ value: "required" });
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return false;
      }
      setValidationErrors(null);
      const isSaveRequired = checkUnsavedAdditionalInfo(additionalInfo, artifactsData);

      if (isSaveRequired) {
        try {
          const formData = prepareAdditionalInfoFormData(additionalInfo);
          const result = await saveCollaborationArtifacts({
            formData: formData,
            collaborationId: collaborationInfo.id,
          });
          showInfoToastMessage({
            iconType: ToasterIconType.UPDATE,
            message: result.message,
          });
          return true;
        } catch (error: ApiError) {
          showErrorToastMessage({
            message:
              error?.response?.data?.status < 500
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
          return false;
        }
      } else if (isNext) {
        return true;
      }
    }
    return false;
  };

  const handleSaveContacts = async (isNext = false) => {
    if (contactsInfo) {
      const errors: Array<Record<string, string> | null> = [];
      contactsInfo.contacts?.map((item: Contact) => {
        const result = challengeContactValidation(item);
        errors.push(result);
      });
      if (errors?.filter((item) => item !== null).length > 0) {
        setValidationErrors(errors);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return false;
      }
      setValidationErrors(null);

      const isSaveRequired = checkUnsavedContacts(contactsInfo.contacts, contactsDetails?.contacts);

      if (isSaveRequired) {
        try {
          const contactFormData = prepareContactFormData(contactsInfo);
          const result = await saveCollaborationContacts({
            formData: contactFormData,
            collaborationId: collaborationInfo.id,
          });
          showInfoToastMessage({
            iconType: ToasterIconType.UPDATE,
            message: result.message,
          });
          return true;
        } catch (error: ApiError) {
          showErrorToastMessage({
            message:
              error?.response?.data?.status < 500
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
          return false;
        }
      } else if (isNext) {
        return true;
      }
    }
    return false;
  };

  const handleSaveTags = async (isSubmit = false) => {
    if (tagsInfo) {
      const isSaveRequired =
        checkUnsavedTags(tagsInfo.tags, tagsData?.tags) ||
        (aboutData?.collaborationCompletionStatusId &&
          aboutData?.collaborationCompletionStatusId < CREATE_CHALLENGE_COMPLETION_STATUS.Tags);
      if (isSaveRequired) {
        try {
          const tagsFormData = prepareContactTagsFormData(tagsInfo);
          const result = await saveCollaborationTags({
            formData: tagsFormData,
            collaborationId: collaborationInfo.id,
          });

          if (isSaveRequired) {
            showInfoToastMessage({
              iconType: ToasterIconType.UPDATE,
              message: result.message,
            });
          }
          previewDelayDuration = TOASTER_DURATION;
          return true;
        } catch (error: ApiError) {
          showErrorToastMessage({
            message:
              error?.response?.data?.status < 500
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
          return false;
        }
      } else if (isSubmit) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (collaborationId) {
      setCollaborationInfoItem(Number(collaborationId), "id");
    } else if (selectedFormId) {
      setCollaborationInfoItem(selectedFormId, "id");
    }
  }, [collaborationId, selectedFormId]);

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.collaborations",
    });
  }, []);

  return (
    <div className="w-full h-full flex">
      <Header
        linkList={LINK_LIST}
        title={aboutData?.title ? aboutData?.title : t("research_institute.new_collaboration")}
        handleCollapseClick={onToggleClick}
        className={
          scroll
            ? " bg-white animate__animated animate__fadeInDown shadow-sticky !py-[26px] !h-[90px] fixed stickyHeader"
            : "fixed"
        }
      />
      <div className=" adminDetails w-full mt-[170px] mb-20 lg:mb-0 lg:mt-0 lg:pt-[118px] pb-[45px] items-baseline px-4 lg:px-10 lg:min-h-screen flex flex-col  overflow-auto ltr:pr-[30px] rtl:pl-[30px]">
        <div className="w-full flex flex-col h-full ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 ltr:lg:pr-[292px] rtl:lg:pl-[292px] space-y-3">
          <div className=" flex-col bg-white w-full h-full rounded-none lg:rounded-md flex mb-auto">
            {stepNumber === 1 ? (
              <About validationErrors={validationErrors as Record<string, string> | null} />
            ) : null}
            {stepNumber === 2 ? (
              <Milestones
                validationErrors={validationErrors as Array<Record<string, string>> | null}
              />
            ) : null}
            {stepNumber === 3 ? (
              <LearnMore validationErrors={validationErrors as Record<string, string> | null} />
            ) : null}
            {stepNumber === 4 ? (
              <Funding validationErrors={validationErrors as Record<string, string> | null} />
            ) : null}
            {stepNumber === 5 ? (
              <Artifacts validationErrors={validationErrors as Record<string, string> | null} />
            ) : null}
            {stepNumber === 6 ? (
              <Contacts
                validationErrors={validationErrors as Array<Record<string, string>> | null}
              />
            ) : null}
            {stepNumber === 7 ? <Tags /> : null}
          </div>
        </div>
        <div
          className={
            scroll
              ? "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto fixed top-[114px] lg:top-[100px]  ltr:lg:right-[30px] rtl:lg:left-[30px]  z-[5]  lg:shadow-sticky  animate__animated animate__fadeInDown animateNone lg:transition-all"
              : "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto fixed top-[114px] lg:top-auto ltr:lg:right-[30px] rtl:left-0 rtl:lg:left-[30px]  z-[5]"
          }
        >
          <Navigator
            handleDraft={handleSaveDraft}
            handleNext={handleSaveNext}
            handleNavigationClick={handleNavigationClick}
            handlePreview={handlePreview}
            navigationItems={navigationItems}
            completionStatusId={aboutData?.collaborationCompletionStatusId ?? 0}
            stepNumber={stepNumber}
            isEdit={isEdit}
          />
        </div>
        {isArtifactsLoading ||
        isArtifactsSaving ||
        isCollaborationFundingLoading ||
        isCollaborationInfoLoading ||
        isCollaborationLearnMoreLoading ||
        isCollaborationMilestoneLoading ||
        isCollaborationSaving ||
        isContactsLoading ||
        isContactsSaving ||
        isFundingSaving ||
        isLearnMoreSaving ||
        isTagsSaving ||
        isTagsLoading ||
        isMilestoneSaving ? (
          <LoaderModal />
        ) : null}
      </div>
    </div>
  );
}
