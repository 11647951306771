import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Title, SecondaryButton, NoData, Header, InlineLoader } from "components";
import Table from "features/Organization/Users/Table";
import { useSendResetPasswordLink } from "features/Admin/api/useSendResetPasswordLink";
import { useDeleteOrganizationUser } from "features/Organization/api/useDeleteOrganizationUser";
import { useGetOrganizationUserList } from "features/Organization/api/useGetOrganizationUserList";
import { ORGANIZATION_USERS_TABLE_HEADERS } from "utils/constants";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { showOrganizationAddUserModal, showConfirmationModal } from "utils/confirmationModals";
import { useStickyScroll } from "hooks/useStickyScroll";
import { setSelectedSideMenu } from "store/useSidebarStore";

export default function Users() {
  const { t } = useTranslation();
  const { scroll } = useStickyScroll();
  const { data: userData, isLoading: isUsersLoading } = useGetOrganizationUserList();
  const { mutateAsync: deleteOrganizationUser, isLoading: isDeletionLoading } =
    useDeleteOrganizationUser();
  const { mutateAsync: resetLinkPassword, isLoading: isResetLinkLoading } =
    useSendResetPasswordLink();

  const handleUserDelete = async (userId: string) => {
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "organization.delete_user_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      try {
        const result = await deleteOrganizationUser(userId);
        return showSuccessToastMessage({
          message: result.message,
        });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };

  const handleResetPassword = async (userId: string) => {
    try {
      const result = await await resetLinkPassword(userId);
      showSuccessToastMessage({
        message: result.message,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const handleAddUserClick = async () => {
    showOrganizationAddUserModal();
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.settings",
      isOpen: true,
      subMenuType: "admin.users",
    });
  }, []);
  return (
    <>
      <Header
        title={"admin.users"}
        className={
          scroll
            ? " bg-white animate__animated animate__fadeInDown shadow-sticky !py-[26px] !h-[90px] fixed stickyHeader"
            : "fixed"
        }
      />
      <div className="adminDetails w-full pt-[118px] pb-[45px] items-baseline px-10 ltr:pr-[30px] rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto ">
        <div className="w-full flex flex-col h-full ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 space-y-[22px]">
          <div className=" flex items-center justify-between">
            <Title
              title={t("organization.manage_users")}
              className=" text-base text-black font-semibold"
            />
            {!isUsersLoading && (
              <SecondaryButton
                className=" flex items-center !h-10 space-x-2 rtl:space-x-reverse !bg-transparent !min-h-fit"
                title={"organization.add_new_user"}
                startIcon="plus"
                handleClick={() => {
                  handleAddUserClick();
                }}
              />
            )}
          </div>
          <div className=" flex flex-col p-4 pt-[18px] w-full h-full items-center rounded-md justify-start bg-white">
            {userData?.length > 0 ? (
              <Table
                userData={userData ?? []}
                headers={ORGANIZATION_USERS_TABLE_HEADERS}
                handleDelete={handleUserDelete}
                handleResetPassword={handleResetPassword}
              />
            ) : isUsersLoading || isDeletionLoading || isResetLinkLoading ? (
              <div className=" flex flex-col w-full h-full items-center justify-center">
                <InlineLoader />
              </div>
            ) : (
              <NoData message="admin.no_data_to_show" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
