import Joi from "joi";
import { groupBy } from "utils/helpers";
import { EMAIL_VALIDATION_PATTERN } from "utils/constants";

const addUserSchema = Joi.object({
  fullName: Joi.string().trim().required().messages({
    "string.empty": "required",
  }),
  email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .pattern(EMAIL_VALIDATION_PATTERN)
    .required()
    .messages({
      "string.empty": "required",
      "string.email": "global.invalid_email_error_message",
      "string.pattern.base": "global.invalid_email_error_message",
    }),
}).options({ abortEarly: false });

export const addUserSchemaValidation = (basicFormData: UserData) => {
  const result = addUserSchema.validate(basicFormData);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
