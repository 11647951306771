const API_END_POINT = process.env.REACT_APP_API_END_POINT;

function prepareURL(preparedPath: string) {
  return `/api/${preparedPath}`;
}

export const urls = {
  baseUrl: API_END_POINT,
  login: () => prepareURL(`auth/login`),
  emailVerification: () => prepareURL(`user/confirmuser`),
  resetConfirmationMail: () => prepareURL(`user/resetpassword/email`),
  resetPassword: () => prepareURL(`user/resetpassword`),
  verifyResetPasswordLink: () => prepareURL(`user/resetpassword/verifylink`),
  changePassword: () => prepareURL(`user/password`),
  refreshToken: () => prepareURL(`auth/login`),
  signUp: () => prepareURL(`user/signup`),
  organizationSignUp: () => prepareURL(`user/organization/signup`),
  logout: () => prepareURL(`auth/logout`),
  profile: () => prepareURL(`profile`),
  personalDetails: (userId: string) => prepareURL(`profile/${userId}`),
  countryCodes: () => prepareURL(`master/countrycodes`),
  publicMasterData: () => prepareURL(`master/data`),
  savePersonalDetails: () => prepareURL(`profile`),
  educationDetails: (userId: string) => prepareURL(`profile/${userId}/education`),
  saveEducationDetails: (userId: string) => prepareURL(`profile/${userId}/education`),
  saveWorkDetails: (userId: string) => prepareURL(`profile/${userId}/work`),
  workDetails: (userId: string) => prepareURL(`profile/${userId}/work`),
  socialDetails: (userId: string) => prepareURL(`profile/${userId}/social`),
  masterData: () => prepareURL(`master`),
  uploadProfileFile: (userId: string) => prepareURL(`profile/${userId}/uploads`),
  saveSocialDetails: (userId: string) => prepareURL(`profile/${userId}/social`),
  submitProfileDetails: (userId: string, status: number) =>
    prepareURL(`profile/${userId}/profileStatus/${status}`),
  uploadResearchDocuments: () => prepareURL(`document/researchproject/document`),
  addResearchProject: (userId: string) => prepareURL(`researchproject/${userId}/add`),
  getResearchProjects: (userId: string) =>
    prepareURL(`researchproject/${userId}/getresearchproject`),
  editResearchProject: () => prepareURL(`researchproject/update`),
  deleteResearchProject: (userId: string, researchProjectId: number) =>
    prepareURL(`researchproject/${researchProjectId}/user/${userId}`),
  getResearchArea: (pageNo: number, pageLimit: number, searchKeyword?: string) =>
    prepareURL(
      `master/researcharea?searchKeyword=${searchKeyword}&pageNumber=${pageNo}&pageLimit=${pageLimit}`
    ),
  manageRequest: (userId: string, profileStatusId: number) =>
    prepareURL(`profile/${userId}/profileStatus/${profileStatusId}`),
  getTags: (pageNo: number, pageLimit: number, searchKeyword?: string) =>
    prepareURL(
      `master/tags?searchKeyword=${searchKeyword}&pageNumber=${pageNo}&pageLimit=${pageLimit}`
    ),
  getResearchScholarDetails: (scholarId: string, challengeId?: number) =>
    prepareURL(
      `researchproject/getresearchscholarprofile/${scholarId}?challengeId=${challengeId ?? ""}`
    ),
  getPendingRequests: ({
    pageNumber,
    pageLimit,
    profileStatusId,
    userType,
    country,
    fromDate,
    toDate,
    type,
    isGlobal,
    isAdvisor,
  }: UserDetailsParams) =>
    prepareURL(
      `admin/users?userType=${userType}&pageNumber=${pageNumber}&pageLimit=${pageLimit}&profileStatus=${profileStatusId}&country=${
        country ? country : ""
      }&type=${type ? type : ""}&fromdate=${fromDate ? fromDate : ""}&todate=${
        toDate ? toDate : ""
      }&isGlobal=${isGlobal ?? ""}&isAdvisor=${isAdvisor ? isAdvisor : ""}`
    ),
  deleteProfileFileUpload: () => prepareURL(`Profile/Uploads`),
  deleteResearchFileUpload: () => prepareURL(`Document/researchproject`),
  downloadCSVTemplate: () => prepareURL(`document/researchscholar/getfile`),
  verifyBulkInviteFile: () => prepareURL(`document/researchscholar/validatefile`),
  uploadBulkInvite: (isGlobalInvite: boolean) =>
    prepareURL(`document/researchscholar/emailinvitation?isGlobal=${isGlobalInvite}`),
  inviteResearchScholar: (userId: string) => prepareURL(`user/${userId}/researchscholar/invite `),
  organizationEmailInvite: (isGlobalInvite: boolean) =>
    prepareURL(`organization/add/emailinvite?isGlobal=${isGlobalInvite}`),
  organizationInvite: () => prepareURL(`user/organization/invite `),
  organizationTypes: () => prepareURL(`master/organization`),
  adminSendResetPasswordLink: (userId: string) => prepareURL(`admin/resendpassword/${userId}`),
  changeUserStatus: (userId: string, status: number) =>
    prepareURL(`admin/user/${userId}/status/${status}`),
  organization: (userId?: string) => prepareURL(`organization${userId ? `/${userId}` : ""}`),
  organizationContacts: (userId: string) => prepareURL(`organization/${userId}/contacts`),
  organizationOffices: (userId: string) => prepareURL(`organization/${userId}/offices`),
  organizationAddUsers: () => prepareURL(`organization/addCredentials`),
  organizationUploads: (userId: string) => prepareURL(`organization/${userId}/uploads`),
  submitOrganizationProfile: (userId: string, organizationStatusId: number) =>
    prepareURL(`organization/${userId}/profilestatus/${organizationStatusId}`),
  manageOrganizationRequest: (userId: string, profileStatusId: number) =>
    prepareURL(`organization/${userId}/profileStatus/${profileStatusId}`),
  organizationUserDetails: (userId: string) => prepareURL(`organization/${userId}`),
  challengeFileUpload: (challengeId: number) => prepareURL(`challenge/${challengeId}/uploads`),
  challenge: () => prepareURL(`challenge`),
  organizationUserList: (userId: string) => prepareURL(`organization/${userId}/users`),
  deleteOrganizationUser: (userId: string) => prepareURL(`organization/users/${userId}`),
  challengeMilestone: (challengeId: number) => prepareURL(`challenge/${challengeId}/milestones`),
  challengeLearnMore: (challengeId: number) => prepareURL(`challenge/${challengeId}/learnmore`),
  challengeFunding: (challengeId: number) => prepareURL(`challenge/${challengeId}/funding`),
  challengeAdditonalInfoAndArtifacts: (challengeId: number) =>
    prepareURL(`challenge/${challengeId}/information`),
  challengeContacts: (challengeId: number) => prepareURL(`challenge/${challengeId}/contacts`),
  challengeTags: (challengeId: number) => prepareURL(`challenge/${challengeId}/tags`),
  challengeList: ({
    pageNumber,
    pageLimit,
    status,
    type,
    researchArea,
    isOwned,
    isExplore,
    isGlobal,
  }: ChallengeListParams) =>
    prepareURL(
      `challenge?statusId=${status}&pageNo=${pageNumber}&pageLimit=${pageLimit}&signatureId=${type}&researchArea=${researchArea}&isOwned=${isOwned}&isexplore=${isExplore}&isGlobal=${isGlobal}
      `
    ),
  masterSystemConfiguration: () => prepareURL(`admin/config`),
  challengePreview: (challengeId: number, isExplore: boolean) =>
    prepareURL(`challenge/${challengeId}/preview?isExplore=${isExplore}`),
  challengeDetails: (challengeId: number) => prepareURL(`challenge/${challengeId}`),
  challengeMilestones: (challengeId: number) => prepareURL(`challenge/${challengeId}/milestones`),
  statistics: (isGlobal: boolean) => prepareURL(`public/statistics?isGlobal=${isGlobal}`),
  latestCaseStudies: (isGlobal: boolean) => prepareURL(`public/casestudy?isGlobal=${isGlobal}`),
  contactUs: () => prepareURL(`public/contactus`),
  challengeUsers: ({ challengeId, statusId, pageNumber, pageLimit }: ChallengeUserParams) =>
    prepareURL(
      `challenge/${challengeId}/researchers/status?statusId=${statusId}&pageNo=${pageNumber}&pageLimit=${pageLimit}`
    ),
  changeChallengeStatus: (status: number, challengeId: number) =>
    prepareURL(`challenge/${challengeId}/status/${status}`),
  sendParticipationRequest: (userId: string, challengeId: number) =>
    prepareURL(`challenge/${challengeId}/request/${userId}`),
  manageScholarRequest: (challengeId: number, scholarId: string, statusId: number) =>
    prepareURL(`challenge/${challengeId}/researchers/${scholarId}/status/${statusId} 
    `),
  uploadCaseStudy: (challengeId: number) => prepareURL(`challenge/${challengeId}/casestudy`),
  challengeDocumentUpload: () => prepareURL(`challenge/uploads`),
  challengeBasicDetails: (challengeId: number, isExplore: boolean) =>
    prepareURL(`challenge/${challengeId}/basicdetails?isExplore=${isExplore}`),
  userChallengeStatus: (challengeId: number, userId: string) =>
    prepareURL(`challenge/${challengeId}/user/${userId}`),
  fundingOrganizationEmailInvite: () => prepareURL(`fundingorganization/add/emailinvite `),
  fundingOrganization: () => prepareURL(`fundingorganization`),
  fundingOrganizationSignUp: () => prepareURL(`user/fundingorganization/signup`),
  inviteFundingOrganization: () => prepareURL(`user/fundingorganization/invite `),
  createPost: (challengeId: number) => prepareURL(`challenge/${challengeId}/forum/post`),
  forumFileUpload: (challengeId: number) => prepareURL(`challenge/${challengeId}/forum/uploads`),
  forumPosts: (challengeId: number, pageNumber: number, pageLimit: number) =>
    prepareURL(
      `challenge/${challengeId}/forum/post?pageNumber=${pageNumber}&pageLimit=${pageLimit}`
    ),
  reply: (challengeId: number, postId: number) =>
    prepareURL(`challenge/${challengeId}/forum/post/${postId}/reply`),
  deleteReply: (challengeId: number, postId: number, replyId: number) =>
    prepareURL(`challenge/${challengeId}/forum/post/${postId}/reply/${replyId}`),
  deletePost: (challengeId: number, postId: number) =>
    prepareURL(`challenge/${challengeId}/forum/post/${postId}`),
  forumReplies: (challengeId: number, postId: number, pageNumber: number, pageLimit: number) =>
    prepareURL(
      `challenge/${challengeId}/forum/post/${postId}/reply?pageNumber=${pageNumber}&pageLimit=${pageLimit}`
    ),
  fundingOrganizationContacts: (userId: string) =>
    prepareURL(`fundingorganization/${userId}/contacts`),
  fundingOrganizationOffices: (userId: string) =>
    prepareURL(`fundingorganization/${userId}/offices`),
  fundingOrganizationDocuments: (userId: string) =>
    prepareURL(`fundingorganization/${userId}/uploads`),
  submitFundingOrganizationProfile: (userId: string, statusId: number) =>
    prepareURL(`fundingorganization/${userId}/profilestatus/${statusId}`),
  challengeEmailInvite: (challengeId: number, isFundingOrganization: boolean) =>
    prepareURL(
      `challenge/${challengeId}/invite?isFundingOrganization=${
        isFundingOrganization ? "true" : "false"
      }`
    ),
  researchScholarInvites: ({ pageNumber, pageLimit }: ResearchInviteParams) =>
    prepareURL(`challenge/invite?pageNumber=${pageNumber}&pageLimit=${pageLimit}`),
  researchScholarInviteUpdate: (challengeId: number, statusId: number) =>
    prepareURL(`challenge/${challengeId}/invite/${statusId}`),
  organizationDashboard: () => prepareURL(`organization/dashboard`),
  manageFundingOrganizationRequest: (userId: string, profileStatusId: number) =>
    prepareURL(`fundingorganization/${userId}/profileStatus/${profileStatusId}`),
  fundingOrganizationDetails: (userId: string) => prepareURL(`fundingorganization/${userId}`),
  latestChallenges: (isGlobal: boolean) => prepareURL(`public/challenge?isGlobal=${isGlobal} `),
  fundingInterests: (challengeId: number, pageNumber: number, pageLimit: number) =>
    prepareURL(`challenge/${challengeId}/interest?pageNumber=${pageNumber}&pageLimit=${pageLimit}`),
  fundingInterestMessage: (challengeId: number) => prepareURL(`challenge/${challengeId}/interest `),
  viewFundingInterest: (challengeId: number, interestId: number) =>
    prepareURL(`challenge/${challengeId}/interest/${interestId}`),
  messageThreads: () => prepareURL(`user/chat/threads`),
  organizationProfileDetails: (userId: string) => prepareURL(`organization/${userId}/profile`),
  fundingOrganizationProfileDetails: (userId: string) =>
    prepareURL(`fundingorganization/${userId}/profile`),
  messages: () => prepareURL(`user/chat/messagelist`),
  chat: () => prepareURL(`user/chat/message `),
  researchInstituteEmailInvite: () => prepareURL(`researchinstitute/add/emailinvite`),
  researchInstituteSignUp: () => prepareURL(`user/researchinstitute/signup`),
  researchInstituteDetails: () => prepareURL(`researchinstitute`),
  researchInstitute: (userId: string) => prepareURL(`researchinstitute/${userId}`),
  researchScholarBasicProfile: (userId: string) => prepareURL(`profile/${userId}/basicdetails`),
  forums: (userId: string, pageNumber: number, pageLimit: number, isChallenge: boolean) =>
    prepareURL(
      `forum/${userId}?pageNumber=${pageNumber}&pageLimit=${pageLimit}&isChallenge=${isChallenge}`
    ),
  researchInstituteDocuments: (userId: string) => prepareURL(`researchinstitute/${userId}/uploads`),
  researchInstituteProfileStatusUpdate: (userId: string, statusId: number) =>
    prepareURL(`researchinstitute/${userId}/profilestatus/${statusId}`),
  researchInstituteOffices: (userId: string) => prepareURL(`researchinstitute/${userId}/offices`),
  inviteResearchInstitute: () => prepareURL(`user/researchinstitute/invite`),
  researchInstituteContacts: (userId: string) => prepareURL(`researchinstitute/${userId}/contacts`),
  interests: (userId: string, pageNumber: number, pageLimit: number) =>
    prepareURL(`challenge/interest/${userId}?pageNumber=${pageNumber}&pageLimit=${pageLimit}`),
  researchInstituteProfileDetails: (userId: string) =>
    prepareURL(`researchinstitute/${userId}/profile`),
  rolesAndPermissions: (userType: number) => prepareURL(`roles/${userType}`),
  inviteHistory: (challengeId: number, pageNumber: number, pageLimit: number) =>
    prepareURL(`challenge/${challengeId}/invites?pageNo=${pageNumber}&pageLimit=${pageLimit}`),
  notifications: (pageNumber: number, pageLimit: number) =>
    prepareURL(`notification?pageNumber=${pageNumber}&pageLimit=${pageLimit}`),
  notificationCount: () => prepareURL(`notification/count`),
  chatFileUpload: (userId: string) => prepareURL(`user/chat/${userId}/uploads`),
  chatFile: () => prepareURL(`user/chat/uploads`),
  getSearchResults: ({
    pageNumber,
    pageLimit,
    searchValue,
    countryId,
    researchAreaId,
    userType,
    isGlobalSearch,
  }: GlobalSearchParams) =>
    prepareURL(
      `search/users?countryId=${countryId}&researchAreaId=${researchAreaId}&userType=${userType}&pageNumber=${pageNumber}&pageLimit=${pageLimit}${
        !isGlobalSearch ? `&searchValue=${searchValue}` : ""
      }`
    ),
  getUserStatus: (userId: string) => prepareURL(`admin/${userId}`),
  allRead: () => prepareURL(`notification/allread`),
  getChallengeResults: ({
    pageNumber,
    pageLimit,
    searchValue,
    targetIndustryId,
    isGlobalSearch,
  }: GlobalSearchParams) =>
    prepareURL(
      `search/challenge?targetIndustryId=${targetIndustryId}&pageNumber=${pageNumber}&pageLimit=${pageLimit}${
        !isGlobalSearch ? `&searchValue=${searchValue}` : ""
      }`
    ),
  permissionList: (userType: number) => prepareURL(`roles/${userType}/master`),
  deleteResearchInstitute: (userId: string) => prepareURL(`researchinstitute/${userId}`),
  researchInstituteAutoApproval: (userId: string) =>
    prepareURL(`researchinstitute/${userId}/settings`),
  getResearchInstitute: (
    pageNo: number,
    pageLimit: number,
    searchKeyword?: string,
    isGlobal?: boolean | string
  ) =>
    prepareURL(
      `master/researchinstitute?searchKeyword=${searchKeyword}&pageNumber=${pageNo}&pageLimit=${pageLimit}&isGlobal=${
        isGlobal ?? ""
      } `
    ),
  participationRequests: ({
    userId,
    challengeId,
    statusId,
    pageLimit,
    pageNumber,
    researchInstitute,
    fromDate,
    toDate,
    isAdvisor,
  }: ChallengeParticipationParams) =>
    prepareURL(
      `user/${userId}/challenge/${challengeId}/researchers/status?statusId=${statusId}&pageLimit=${pageLimit}&pageNumber=${pageNumber}&researchinstitutes=${researchInstitute}&fromDate=${fromDate}&toDate=${toDate}&isAdvisor=${
        isAdvisor ? isAdvisor : ""
      }`
    ),
  eventAbout: (eventId: number) => prepareURL(`event/${eventId}/about`),
  evenLearnMore: (eventId: number) => prepareURL(`event/${eventId}/learnmore`),
  eventFileUpload: (eventId: number) => prepareURL(`event/${eventId}/uploads`),
  eventDeleteFileUpload: () => prepareURL(`event/uploads`),
  eventPreview: (eventId: number) => prepareURL(`event/${eventId}`),
  eventPublish: (eventId: number) => prepareURL(`event/${eventId}/publish`),
  saveEventAbout: () => prepareURL(`event/about`),
  saveEventLearnMore: () => prepareURL(`event/learnmore`),
  eventList: ({ pageNumber, pageLimit, status, targetIndustry }: EventListParams) =>
    prepareURL(
      `event?status=${status}&pageNo=${pageNumber}&pageLimit=${pageLimit}&targetIndustry=${targetIndustry}`
    ),
  collaborationList: ({
    pageNumber,
    pageLimit,
    status,
    type,
    researchArea,
    isOwned,
    isExplore,
  }: CollaborationListParams) =>
    prepareURL(
      `collaboration?statusId=${status}&pageNo=${pageNumber}&pageLimit=${pageLimit}&signatureId=${type}&researchArea=${researchArea}&isOwned=${isOwned}&isexplore=${isExplore}`
    ),
  collaboration: () => prepareURL(`collaboration`),
  collaborationDetails: (collaborationId: number) => prepareURL(`collaboration/${collaborationId}`),
  collaborationMilestones: (collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/milestones`),
  collaborationLearnMore: (collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/learnmore`),
  collaborationFunding: (collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/funding`),
  collaborationAdditionalInfoAndArtifacts: (collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/information`),
  collaborationContacts: (collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/contacts`),
  collaborationTags: (collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/tags`),
  collaborationFileUpload: (collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/uploads`),
  collaborationDeleteUpload: () => prepareURL(`collaboration/uploads`),
  collaborationPreview: (collaborationId: number, isExplore: boolean) =>
    prepareURL(`collaboration/${collaborationId}/preview?isExplore=${isExplore}`),
  updateCollaborationStatus: (status: number, collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/status/${status}`),
  collaborationBasicDetails: (collaborationId: number, isExplore: boolean) =>
    prepareURL(`collaboration/${collaborationId}/basicdetails?isExplore=${isExplore}`),
  collaborationParticipationRequests: ({
    userId,
    collaborationId,
    statusId,
    pageLimit,
    pageNumber,
    fromDate,
    toDate,
    isRequest,
    researchInstitute,
  }: CollaborationParticipationParams) =>
    prepareURL(
      `user/${userId}/collaboration/${collaborationId}/researchers/status?statusId=${statusId}&pageLimit=${pageLimit}&pageNumber=${pageNumber}&fromDate=${fromDate}&toDate=${toDate}&isRequest=${isRequest}&researchinstitutes=${researchInstitute}`
    ),
  collaborationCaseSummary: (collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/casestudy`),
  collaborationManageScholarRequest: (
    collaborationId: number,
    scholarId: string,
    statusId: number
  ) =>
    prepareURL(`collaboration/${collaborationId}/researchers/${scholarId}/status/${statusId} 
    `),
  sendCollaborationParticipationRequest: (userId: string, collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/request/${userId}`),
  collaborationCreatePost: (collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/forum/post`),
  collaborationForumFileUpload: (collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/forum/uploads`),
  collaborationForumPosts: (collaborationId: number, pageNumber: number, pageLimit: number) =>
    prepareURL(
      `collaboration/${collaborationId}/forum/post?pageNumber=${pageNumber}&pageLimit=${pageLimit}`
    ),
  collaborationReply: (collaborationId: number, postId: number) =>
    prepareURL(`collaboration/${collaborationId}/forum/post/${postId}/reply`),
  collaborationDeleteReply: (collaborationId: number, postId: number, replyId: number) =>
    prepareURL(`collaboration/${collaborationId}/forum/post/${postId}/reply/${replyId}`),
  collaborationDeletePost: (collaborationId: number, postId: number) =>
    prepareURL(`collaboration/${collaborationId}/forum/post/${postId}`),
  collaborationForumReplies: (
    collaborationId: number,
    postId: number,
    pageNumber: number,
    pageLimit: number
  ) =>
    prepareURL(
      `collaboration/${collaborationId}/forum/post/${postId}/reply?pageNumber=${pageNumber}&pageLimit=${pageLimit}`
    ),
  collaborationInviteUser: (collaborationId: number) =>
    prepareURL(`collaboration/${collaborationId}/invite`),
  manageCollaborationScholarRequest: (
    collaborationId: number,
    scholarId: string,
    statusId: number
  ) =>
    prepareURL(`collaboration/${collaborationId}/researchers/${scholarId}/status/${statusId} 
    `),
  userCollaborationStatus: (collaborationId: number, userId: string) =>
    prepareURL(`collaboration/${collaborationId}/user/${userId}`),
  collaborationUserInviteUpdate: (collaborationId: number, statusId: number) =>
    prepareURL(`collaboration/${collaborationId}/invite/${statusId}`),
  collaborationInvites: ({ pageNumber, pageLimit }: ResearchInviteParams) =>
    prepareURL(`collaboration/invite?pageNumber=${pageNumber}&pageLimit=${pageLimit}`),
  collaborationInviteHistory: (collaborationId: number, pageNumber: number, pageLimit: number) =>
    prepareURL(
      `collaboration/${collaborationId}/invites?pageNo=${pageNumber}&pageLimit=${pageLimit}`
    ),
  validateEmail: () => prepareURL(`user/checkuser`),
  triggerEmailNotification: () => prepareURL("admin/triggeremailnotification"),
  adminChangeChallengeStatus: (status: number, challengeId: number, specificReason?: string) =>
    prepareURL(`challenge/${challengeId}/challengeStatus/${status}?specificReason=${specificReason}`),
  collaborationChangeStatus: (status: number, collaborationId: number, specificReason?: string) =>
    prepareURL(`collaboration/${collaborationId}/collaborationstatus/${status}?specificReason=${specificReason}`),
};
