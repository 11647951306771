import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SVGIcon from "assets/Icons";
import {
  InputField,
  CheckBox,
  SubTitle,
  Chip,
  UploadButton,
  DatePickerField,
  Divider,
  TextArea,
  SecondaryButton,
  DividerWithText,
  PrimaryButton,
  MultiSelect,
  FileUploadList,
  LoaderModal,
  Label,
} from "components";
import {
  setResearchProjectsInfo,
  setResearchProjectsInfoItem,
  useScholarProfileDataStore,
} from "store/useScholarProfileDataStore";
import { getTags } from "features/ResearchScholar/api/useGetTag";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { useUploadResearchDocs } from "features/ResearchScholar/api/useUploadResearchDocs";
import { useGetResearchProjects } from "features/ResearchScholar/api/useGetResearchProjects";
import { useDeleteResearchProject } from "features/ResearchScholar/api/useDeleteResearchProject";
import { useDeleteResearchFileUpload } from "features/ResearchScholar/api/useDeleteResearchProjectUpload";
import { showConfirmationModal } from "utils/confirmationModals";
import {
  ToasterIconType,
  showErrorToastMessage,
  showSuccessToastMessage,
} from "utils/toasterMessage";
import {
  DETAILS_MAX_CHAR_LENGTH,
  DETAIL_DESCRIPTION_MAX_CHAR_LENGTH,
  MAX_FILE_SIZE,
  PAGE_LIMIT,
  RESEARCH_PROJECT_INITIAL_STATE,
  URL_MAX_LENGTH,
  INITIAL_SEARCH_PARAMS,
} from "utils/constants";
import useInvalidateQuery from "hooks/useInvalidateQuery";

type ResearchProjectProps = {
  validationErrors: Array<Record<string, string>> | null;
  isReadOnly: boolean;
  removeValidationErrors: () => void;
  setIsUploading: (status: boolean) => void;
};

export const ResearchProject = ({
  validationErrors,
  isReadOnly,
  removeValidationErrors,
  setIsUploading,
}: ResearchProjectProps) => {
  const { t, i18n } = useTranslation();
  const { researchScholarId } = useParams();
  const { researchProjectsInfo } = useScholarProfileDataStore();
  useInvalidateQuery("personal-details", researchScholarId ?? "");

  const { data: researchProjectsData, isLoading: isResearchProjectsLoading } =
    useGetResearchProjects(researchScholarId ?? "");
  const { mutateAsync: deleteResearchProject, isLoading: isDeleteProjectLoading } =
    useDeleteResearchProject(researchScholarId ?? "");
  const { mutateAsync: fileUpload, isLoading: isFileUploadLoading } = useUploadResearchDocs();
  const { mutateAsync: deleteResearchFileUpload, isLoading: isDeleteUploadLoading } =
    useDeleteResearchFileUpload();

  useEffect(() => {
    if (researchProjectsData) {
      setResearchProjectsInfo(researchProjectsData);
    }
  }, [researchProjectsData, i18n.language]);

  useEffect(() => {
    searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
    tagSearchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
  }, [researchProjectsInfo]);

  const handleAddProjectClick = () => {
    const researchProjectsInfo = useScholarProfileDataStore.getState().researchProjectsInfo;
    if (researchProjectsInfo && researchProjectsInfo?.length > 0) {
      setResearchProjectsInfo([...researchProjectsInfo, { ...RESEARCH_PROJECT_INITIAL_STATE }]);
    } else {
      setResearchProjectsInfo([{ ...RESEARCH_PROJECT_INITIAL_STATE }]);
    }
  };

  const handleDeleteProject = async (index: number, researchProjectId: number | null) => {
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "research_scholar.project_delete_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      modalIcon: "confirmDelete",

      isAlert: true,
    });
    if (!result) {
      return;
    }
    if (result) {
      if (researchProjectId) {
        const project = researchProjectsInfo?.find(
          (item) => item.researchProjectId === researchProjectId
        );
        if (project) {
          await deleteResearchProject(researchProjectId);
        }
      } else {
        const projects = researchProjectsInfo?.filter((item, i) => i !== index);
        if (projects && projects?.length > 0) setResearchProjectsInfo([...projects]);
        else setResearchProjectsInfo([]);
      }
      removeValidationErrors();
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const selectedFiles = event.target?.files;
    if (selectedFiles && selectedFiles?.length > 0) {
      if (selectedFiles?.[0].size > MAX_FILE_SIZE) {
        event.target.value = "";
        return showErrorToastMessage({
          message: t("research_scholar.uploaded_files_size_error_message"),
        });
      }
      const fileName = selectedFiles?.[0].name;
      const isFileExist = researchProjectsInfo?.[index]?.documents?.find(
        (item) => item.name === fileName
      );
      if (isFileExist) {
        showErrorToastMessage({
          message: t("research_scholar.multiple_same_name_file_error_message"),
        });
        event.target.value = "";
        return;
      }
      try {
        setIsUploading(true);
        const docs = researchProjectsInfo?.[index].documents || [];
        setResearchProjectsInfoItem(
          docs ? [...docs, selectedFiles[0]] : [selectedFiles[0]],
          "documents",
          index
        );
        const result = await fileUpload(selectedFiles);
        if (result) {
          setResearchProjectsInfoItem([...docs, ...result], "documents", index);
          setTimeout(() => {
            // window.scrollTo(0, document.body.scrollHeight);
          }, 1000);
        }
      } catch (error) {
        const docs = researchProjectsInfo?.[index].documents || [];
        setResearchProjectsInfoItem(docs ? [...docs] : [], "documents", index);
        showErrorToastMessage({
          iconType: ToasterIconType.ALERT,
          message: t("global.something_went_wrong"),
        });
      } finally {
        setIsUploading(false);
        event.target.value = "";
      }
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: keyof ResearchProject,
    index: number
  ) => {
    const value = e.target.value;
    const newProjects = researchProjectsInfo?.map((item: any, i: number) => {
      const newItem = structuredClone(item);
      if (index === i) {
        if (key) {
          newItem[key] = value;
        }
      }
      return newItem;
    });
    if (newProjects && newProjects?.length > 0) setResearchProjectsInfo(newProjects);
  };

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
    index: number
  ) => {
    const value = event.target.checked;
    if (key === "isInProgress" && value === true) {
      setResearchProjectsInfoItem(null, "endDate", index);
    }
    setResearchProjectsInfoItem(value, key, index);
  };

  const handleFileRemove = async (
    event: React.MouseEvent<HTMLButtonElement>,
    item: FileDetails,
    index: number
  ) => {
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.deletion_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      modalIcon: "confirmDelete",

      isAlert: true,
    });
    if (!result) {
      return;
    }
    if (result) {
      if (item.id) {
        const formData = {
          id: item.id,
          name: item.name,
          fileUrl: item.fileUrl,
        };
        try {
          const result = await deleteResearchFileUpload(formData);
          const newProjects = structuredClone(researchProjectsInfo);
          if (newProjects && newProjects[index] && newProjects[index].documents)
            newProjects[index].documents =
              newProjects?.[index]?.documents?.filter(
                (doc: FileDetails) => doc.fileUrl !== item.fileUrl
              ) || [];
          if (newProjects) setResearchProjectsInfo(newProjects);
          showSuccessToastMessage({
            message: result.message,
          });
        } catch (error: ApiError) {
          showErrorToastMessage({
            iconType: ToasterIconType.ALERT,
            message:
              error.response.data.status === 400
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
        }
      } else {
        const newProjects = structuredClone(researchProjectsInfo);
        if (newProjects && newProjects[index] && newProjects[index].documents)
          newProjects[index].documents =
            newProjects?.[index]?.documents?.filter(
              (doc: FileDetails) => doc.fileUrl !== item.fileUrl
            ) || [];
        if (newProjects) setResearchProjectsInfo(newProjects);
        showSuccessToastMessage({
          message: t("global.document_deleted_successfully"),
        });
      }
    }
  };

  const handleRemoveResearchArea = (
    event: React.MouseEvent<HTMLElement>,
    researchProjectItem: ResearchProject,
    researchProjectIndex: number,
    itemIndex: number
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (researchProjectItem?.areaOfExpertise) {
      const tempOptions = [...researchProjectItem.areaOfExpertise];
      if (itemIndex < tempOptions.length) tempOptions.splice(itemIndex, 1);
      setResearchProjectsInfoItem(tempOptions, "areaOfExpertise", researchProjectIndex);
    }
  };

  const handleRemoveTag = (
    event: React.MouseEvent<HTMLElement>,
    researchProjectItem: ResearchProject,
    researchProjectIndex: number,
    itemIndex: number
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (researchProjectItem?.tags) {
      const tempOptions = [...researchProjectItem.tags];
      tempOptions.splice(itemIndex, 1);
      setResearchProjectsInfoItem(tempOptions, "tags", researchProjectIndex);
      tagSearchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
    }
  };

  const handleDateInputChange = (date: string, key: string, index: number) => {
    setResearchProjectsInfoItem(date, key, index);
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    key: string,
    index: number
  ) => {
    const value = event.target.value;
    setResearchProjectsInfoItem(value, key, index);
  };

  const disableUpload = (index: number) => {
    const docs = researchProjectsInfo?.[index]?.documents;
    if (isReadOnly) {
      return true;
    } else if (docs?.length !== undefined && docs?.length >= 5) {
      return true;
    } else if (isFileUploadLoading) {
      return true;
    } else {
      return false;
    }
  };

  const disabledEndDate = (index: number) => {
    if (isReadOnly) {
      return true;
    } else if (researchProjectsInfo?.[index]?.isInProgress) {
      return true;
    } else {
      return false;
    }
  };
  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);

  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  const tagSearchParamsRef = useRef(INITIAL_SEARCH_PARAMS);

  async function loadTagOptions(searchKeyword: string) {
    let pageNumber = tagSearchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== tagSearchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getTags({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    tagSearchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  return (
    <>
      <div className="flex items-center justify-between mb-6 flex-wrap !pb-8 lg:!pb-3 p-5 !py-3 border-b border-gray-light">
        <SVGIcon name="researchProjects" />
        <div className="ltr:mr-auto rtl:ml-auto flex items-center justify-between">
          <SubTitle
            className="font-semibold text-lg text-black subtitle  ltr:ml-3 rtl:mr-3"
            title={t("research_scholar.research_projects")}
          />
        </div>
        {researchProjectsInfo && researchProjectsInfo?.length > 0 ? (
          <SecondaryButton
            title={"research_scholar.add_project"}
            handleClick={handleAddProjectClick}
            className=" lg:!mt-0 !h-10 !min-h-[40px] !mt-4  w-full lg:w-auto space-x-3 rtl:space-x-reverse !px-6"
            startIcon="plus"
            disabled={isReadOnly}
          />
        ) : null}
      </div>
      <>
        {researchProjectsInfo && researchProjectsInfo?.length === 0 ? (
          <div className="pt-0 px-5">
            <SecondaryButton
              title={"research_scholar.add_project"}
              handleClick={handleAddProjectClick}
              className=" w-full !bg-gray-light/60 mb-6 space-x-4 rtl:space-x-reverse border-none h-16 flex items-center !text-primary-medium text-sm  hover:!bg-primary-medium hover:!text-white"
              endIcon="plusCircle"
              disabled={isReadOnly}
            />
          </div>
        ) : null}
        {researchProjectsInfo && researchProjectsInfo?.length > 0
          ? researchProjectsInfo?.map((researchProjectItem, index) => (
              <div key={researchProjectItem?.researchProjectId ?? index}>
                <div className=" space-y-[22px] px-4 lg:px-5 pb-8 ">
                  <DividerWithText text={`${t("global.project")} ${index + 1}`} />
                  <InputField
                    hintText={t("research_scholar.enter_research_title_name")}
                    labelText={t("research_scholar.research_title")}
                    handleInputChange={(event) => handleInputChange(event, "researchTitle", index)}
                    fieldName="researchTitle"
                    isRequired
                    maxCharLength={DETAILS_MAX_CHAR_LENGTH}
                    value={researchProjectItem?.researchTitle ?? ""}
                    errorMessage={validationErrors?.[index]?.researchTitle ?? ""}
                    isDisabled={isReadOnly}
                  />
                  <TextArea
                    textContent={researchProjectItem?.researchBrief ?? ""}
                    handleChange={(event) => handleTextAreaChange(event, "researchBrief", index)}
                    labelText={t("research_scholar.research_brief")}
                    hintText={t("research_scholar.research_brief_placeholder")}
                    fieldName="researchBrief"
                    maxCharLength={DETAIL_DESCRIPTION_MAX_CHAR_LENGTH}
                    isRequired
                    errorMessage={validationErrors?.[index]?.researchBrief ?? ""}
                    isDisabled={isReadOnly}
                  />
                  <CheckBox
                    option={{
                      label: t("research_scholar.are_you_still_pursuing_this_research_project"),
                      value: "pursuing_research_project",
                    }}
                    className="customCheckbox !mt-[22px]"
                    checked={researchProjectItem?.isInProgress}
                    handleChange={(event) => handleSelectChange(event, "isInProgress", index)}
                    disabled={isReadOnly}
                  />
                  <MultiSelect
                    labelText={t("global.research_area")}
                    hintText={t("global.search")}
                    setSelectedOptions={(options: Array<OptionItem> | null) => {
                      setResearchProjectsInfoItem(options, "areaOfExpertise", index);
                      searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
                    }}
                    selectedOptions={researchProjectItem?.areaOfExpertise || []}
                    isDisabled={isReadOnly}
                    addNew
                    loadOptions={loadResearchAreaOptions}
                    errorToastMessage={"global.create_research_area_min_error_message"}
                    createLabel={"research_scholar.new_research_area"}
                    resetKey={Math.random().toString()}
                    onMenuOpen={() => {
                      searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
                    }}
                  />
                  <div className=" flex  flex-wrap !mt-2 !-mb-2">
                    {researchProjectItem?.areaOfExpertise?.map((item, i) => (
                      <Chip
                        key={item.id ? `${item.id}` : `${i}`}
                        text={i18n.language === "ar" ? item.name.ar : item.name.en}
                        handleClose={(event: React.MouseEvent<HTMLElement>) =>
                          handleRemoveResearchArea(event, researchProjectItem, index, i)
                        }
                        disabled={isReadOnly}
                      />
                    ))}
                  </div>

                  <div className=" grid grid-cols-1 sm:grid-cols-2 gap-x-[14px] gap-y-[22px]">
                    <div className="datePicker relative">
                      <DatePickerField
                        labelText={t("global.start_date")}
                        selectedDate={researchProjectItem?.startDate || null}
                        setSelectedDate={(date) => handleDateInputChange(date, "startDate", index)}
                        errorMessage={validationErrors?.[index]?.startDate ?? ""}
                        showFutureDate={false}
                        isDisabled={isReadOnly}
                        isRequired
                        maxDate={researchProjectItem?.endDate}
                      />
                    </div>
                    <div className="datePicker  relative">
                      <DatePickerField
                        labelText={t("global.end_date")}
                        selectedDate={researchProjectItem?.endDate || null}
                        setSelectedDate={(date) => handleDateInputChange(date, "endDate", index)}
                        errorMessage={validationErrors?.[index]?.endDate ?? ""}
                        showFutureDate={false}
                        minDate={researchProjectItem?.startDate}
                        isDisabled={disabledEndDate(index)}
                      />
                    </div>
                    <InputField
                      hintText={t("global.please_enter_a_valid_url")}
                      labelText={t("research_scholar.project_url")}
                      handleInputChange={(event) => handleInputChange(event, "projectUrl", index)}
                      fieldName="projectURL"
                      value={researchProjectItem?.projectUrl ?? ""}
                      errorMessage={validationErrors?.[index]?.projectUrl}
                      isDisabled={isReadOnly}
                      maxCharLength={URL_MAX_LENGTH}
                      tooltipContent={[t("global.website_url_tooltip_message")]}
                    />
                    <InputField
                      hintText={t("global.please_enter_a_valid_url")}
                      labelText={t("research_scholar.public_url")}
                      handleInputChange={(event) => handleInputChange(event, "publicUrl", index)}
                      fieldName="publicURL"
                      value={researchProjectItem?.publicUrl ?? ""}
                      errorMessage={validationErrors?.[index]?.publicUrl}
                      isDisabled={isReadOnly}
                      maxCharLength={URL_MAX_LENGTH}
                      tooltipContent={[t("global.website_url_tooltip_message")]}
                    />
                  </div>
                  <MultiSelect
                    labelText={t("research_scholar.add_tags")}
                    hintText={t("global.search")}
                    setSelectedOptions={(options: Array<OptionItem> | null) => {
                      setResearchProjectsInfoItem(options, "tags", index);
                      tagSearchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
                    }}
                    selectedOptions={researchProjectItem?.tags || []}
                    isDisabled={isReadOnly}
                    addNew
                    loadOptions={loadTagOptions}
                    errorToastMessage="global.create_tag_min_error_message"
                    createLabel={"research_scholar.new_tag"}
                    resetKey={Math.random().toString()}
                    onMenuOpen={() => {
                      tagSearchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
                    }}
                  />
                  <div>
                    <div className=" flex  flex-wrap">
                      {researchProjectItem?.tags?.map((item, i) => (
                        <Chip
                          key={item.id}
                          text={i18n.language === "ar" ? item.name.ar : item.name.en}
                          handleClose={(event: React.MouseEvent<HTMLElement>) =>
                            handleRemoveTag(event, researchProjectItem, index, i)
                          }
                          disabled={isReadOnly}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="fileUpload w-full flex justify-between">
                    <Label
                      className="label p-0 text-primary-light text-sm"
                      text={t("global.upload_files")}
                      tooltipContent={"research_scholar.you_can_upload_a_maximum_of_5_documents"}
                    />
                    <UploadButton
                      label="global.upload"
                      icon="uploadV1"
                      handleFileUpload={(event) => handleFileUpload(event, index)}
                      disabled={disableUpload(index)}
                    />
                  </div>
                  {researchProjectItem &&
                  researchProjectItem.documents &&
                  researchProjectItem.documents.length > 0 ? (
                    <div className="">
                      <FileUploadList
                        fileList={researchProjectItem?.documents ?? []}
                        handleRemove={(event, item) => handleFileRemove(event, item, index)}
                        disabled={isReadOnly}
                        isFileUploading={isFileUploadLoading}
                      />
                    </div>
                  ) : null}
                  <div className=" flex justify-center">
                    <PrimaryButton
                      title={"research_scholar.delete_project"}
                      handleClick={() =>
                        handleDeleteProject(index, researchProjectItem?.researchProjectId)
                      }
                      startIcon="trashV1"
                      className=" !h-10 !min-h-0 !w-[150px] !min-w-[150px] !rounded-sm !mb-8 !text-xs !px-0 font-medium bg-white !text-red-dark !border-red-dark mx-auto hover:!bg-red-dark/10 hover:!border-red-dark/30"
                      disabled={isReadOnly}
                    />
                  </div>
                </div>
                {index != researchProjectsInfo?.length - 1 ? (
                  <Divider className="mb-8 -mx-[4px] lg:-mx-10 2xl:-mx-[22px] hidden" />
                ) : null}
              </div>
            ))
          : null}
      </>
      {isDeleteProjectLoading || isResearchProjectsLoading || isDeleteUploadLoading ? (
        <LoaderModal />
      ) : null}
    </>
  );
};
