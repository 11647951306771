import { TextButton } from "components/TextButton";

type ContentType = "textAreaContent" | "editorContent";

type ContentViewWithToggleProps = {
  data: string;
  isViewMore: boolean;
  handleViewMoreClick: () => void;
  maxLength: number;
  className: string;
  contentType?: ContentType;
};

export const ContentViewWithToggle = ({
  data,
  isViewMore,
  handleViewMoreClick,
  maxLength,
  className,
  contentType = "editorContent",
}: ContentViewWithToggleProps) => {
  let content = "";
  if (data) {
    if (data.length > maxLength && !isViewMore) {
      content = data.slice(0, maxLength) + "...";
    } else {
      content = data;
    }

    if (contentType === "textAreaContent") {
      content = content.replace(/\n/g, "<br/>").replace(/ /g, () => "&nbsp;");
    }
  }

  return (
    <>
      <div className=" max-w-[90%]  WB_BW">
        <span
          className={className}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
        {data && data.length > maxLength ? (
          <TextButton
            title={isViewMore ? "global.view_less" : "global.view_more"}
            handleClick={handleViewMoreClick}
            className=" text-primary-medium font-normal underline mt-1.5 text-sm"
          />
        ) : null}
      </div>
    </>
  );
};
