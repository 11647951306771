import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CheckBox,
  SubTitle,
  InputField,
  SecondaryButton,
  Divider,
  PrimaryButton,
} from "components";
import {
  setEducationInfoItem,
  useScholarProfileDataStore,
  setEducationInfo,
  HIGHEST_EDUCATION_INITIAL_STATE,
} from "store/useScholarProfileDataStore";
import { DETAILS_MAX_CHAR_LENGTH, HIGHER_EDUCATION_MAX_LIMIT, USER_STATUS } from "utils/constants";
import { useGetEducationDetails } from "features/ResearchScholar/api/useGetEducationDetails";
import SVGIcon from "assets/Icons";
import { showErrorToastMessage } from "utils/toasterMessage";
import { showConfirmationModal } from "utils/confirmationModals";
import useInvalidateQuery from "hooks/useInvalidateQuery";

type EducationDetailsFormProps = {
  validationErrors: Array<Record<string, string>> | null;
  removeValidationErrors: () => void;
};

export default function HighestEducation({
  validationErrors,
  removeValidationErrors,
}: EducationDetailsFormProps) {
  const { t, i18n } = useTranslation();
  const { researchScholarId } = useParams();
  const { educationInfo } = useScholarProfileDataStore();
  const { data: educationalData } = useGetEducationDetails(researchScholarId ?? "");
  const isReadOnly = educationInfo?.profileStatusId === USER_STATUS.PendingApproval;
  useInvalidateQuery("personal-details", researchScholarId ?? "");

  const handleInputChange = (
    key: keyof HighestEducation,
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = event?.target?.value;
    setEducationInfoItem(value, key, index);
  };

  const handleStillStudyingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setEducationInfoItem(value, "stillStudying");
  };

  useEffect(() => {
    if (educationalData && educationalData?.details?.length > 0) {
      setEducationInfo(educationalData);
    } else {
      setEducationInfo({
        ...educationalData,
        details: [{ ...HIGHEST_EDUCATION_INITIAL_STATE }],
      });
    }
  }, [educationalData, i18n.language]);

  const handleAddEducation = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const educationInfo = useScholarProfileDataStore.getState().educationInfo;
    if (educationInfo && educationInfo?.details?.length <= HIGHER_EDUCATION_MAX_LIMIT - 1) {
      const tempEducationInfo = structuredClone(educationInfo);
      tempEducationInfo?.details.push({ ...HIGHEST_EDUCATION_INITIAL_STATE });
      setEducationInfo({
        ...tempEducationInfo,
        details: tempEducationInfo?.details,
      });
    } else if (educationInfo && educationInfo?.details?.length >= HIGHER_EDUCATION_MAX_LIMIT) {
      showErrorToastMessage({
        message: `${t(
          "research_scholar.maximum_entry_limit_message"
        )} ${HIGHER_EDUCATION_MAX_LIMIT} ${t("research_scholar.entries")}`,
      });
    }
  };

  const handleDeleteEducation = async (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "research_scholar.delete_an_item_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      const tempEducationInfo = educationInfo?.details?.filter((item, i) => i !== index);
      if (educationInfo) {
        if (tempEducationInfo && tempEducationInfo?.length > 0)
          setEducationInfo({ ...educationInfo, details: [...tempEducationInfo] });
        else setEducationInfo({ ...educationInfo });

        removeValidationErrors();
      }
    }
  };

  return (
    <>
      <form>
        <div className="flex max-[600px]:flex-col items-center justify-between max-[600px]:justify-start mb-6 flex-wrap p-5 !py-3 border-b border-gray-light">
          {" "}
          <div className="flex ltr:mr-auto rtl:ml-auto gap-3 justify-center items-center">
            <SVGIcon name="highestEducation" />
            <SubTitle
              className=" text-lg font-semibold text-black subtitle"
              title={t("research_scholar.highest_education")}
            />
          </div>
          <div className=" flex items-center  max-[600px]:!mt-3 max-[600px]:mr-auto">
            <CheckBox
              option={{
                label: t("research_scholar.still_studying"),
                value: "still_studying",
              }}
              className="customCheckbox  higher-education-checkbox"
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleStillStudyingChange(event)
              }
              disabled={isReadOnly}
              checked={educationInfo?.stillStudying}
            />
            <SecondaryButton
              title={"research_scholar.add_education"}
              handleClick={handleAddEducation}
              className=" lg:!mt-0 !h-10 !min-h-[40px] w-auto space-x-3 rtl:space-x-reverse !px-6 ltr:ml-2 rtl:mr-2"
              startIcon="plus"
              disabled={isReadOnly}
            />
          </div>
        </div>
        <div className="w-full space-y-[22px] px-4 lg:px-5 pb-8">
          {educationInfo?.details?.map((item: HighestEducation, index: number) => {
            return (
              <div key={`${index}-${item.id}`} className=" space-y-[22px]">
                <div className=" col-span-2 lg:mt-0">
                  <InputField
                    hintText={t("research_scholar.enter_institute_name")}
                    labelText={t("research_scholar.institute_name")}
                    fieldName="instituteName"
                    handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange("instituteName", event, index)
                    }
                    isRequired
                    maxCharLength={DETAILS_MAX_CHAR_LENGTH}
                    errorMessage={validationErrors?.[index]?.instituteName ?? ""}
                    value={item.instituteName ?? ""}
                    isDisabled={isReadOnly}
                  />
                </div>
                <div className=" col-span-2 ">
                  <InputField
                    hintText={t("research_scholar.enter_course_name")}
                    labelText={t("research_scholar.course_name")}
                    handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange("courseName", event, index)
                    }
                    fieldName="courseName"
                    isRequired
                    maxCharLength={DETAILS_MAX_CHAR_LENGTH}
                    errorMessage={validationErrors?.[index]?.courseName ?? ""}
                    value={item.courseName ?? ""}
                    isDisabled={isReadOnly}
                  />
                </div>
                {index !== 0 ? (
                  <div className=" flex justify-center mt-2">
                    <PrimaryButton
                      title={"research_scholar.delete_education"}
                      handleClick={(event: React.MouseEvent<HTMLElement>) =>
                        handleDeleteEducation(event, index)
                      }
                      startIcon="trashV1"
                      className=" !h-10 !min-h-0 !w-[150px] !min-w-[150px] !rounded-sm !mt-5 !text-xs !px-0 font-medium bg-white !text-red-dark !border-red-dark mx-auto hover:!bg-red-dark/10 hover:!border-red-dark/30"
                      disabled={isReadOnly}
                    />
                  </div>
                ) : null}
                {index !== educationInfo?.details?.length - 1 ? <Divider className="my-8" /> : null}
              </div>
            );
          })}
        </div>
      </form>
    </>
  );
}
