import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getCaseSummary(collaborationId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  const response = await apiClient.get(urls.collaborationCaseSummary(collaborationId), {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response?.data?.data as ApiStatus | ApiError;
}

export function useGetCollaborationCaseSummary(collaborationId: number, isEdit: boolean) {
  return useQuery(
    ["collaboration-case-summary", collaborationId],

    async () => {
      if (collaborationId && isEdit) {
        const result = await getCaseSummary(collaborationId);
        return result;
      } else return null;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
