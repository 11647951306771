import { IconTypeButton, Title } from "components";
import SVGIcon from "assets/Icons";
import { useTranslation } from "react-i18next";

type InvitesContent = {
  id: number;
  svgName: string;
  title: string;
  content: string;
  handleClick: (id: number) => void;
};
export function DashBoardCards({ id, svgName, title, content, handleClick }: InvitesContent) {
  const { t } = useTranslation();
  return (
    <div
      className="border border-gray-900 shadow-100 min-h-[130px] rounded-md bg-gray-1300 cursor-pointer"
      onClick={() => handleClick(id)}
    >
      <div className="px-4 pt-[11px] pb-[13px] bg-white flex items-center rounded-t-md gap-3 border-b border-gray-900">
        <div className="w-[32px] h-[32px] rounded-full overflow-hidden dashboard-cards">
          <SVGIcon name={svgName} />
        </div>
        <Title
          title={t(title)}
          className="text-primary-light text-base leading-normal font-medium"
        />
      </div>
      <div className="py-4 ltr:pl-[18px] rtl:pr-[18px] ltr:pr-[26px] rtl:pl-[26px] bg-gray-1300 flex items-center justify-between rounded-b-md">
        <p className="text-sm leading-normal font-medium text-primary-light rtl:!ml-[10px]">
          {t(content)}
        </p>
        <IconTypeButton
          handleClick={() => {
            //noop
          }}
          iconName="rightRoundArrow"
          className="p-0  btn min-h-fit  w-5 h-5 !bg-transparent !border-none flex justify-center items-center circleColor rtl:rotate-180 rtl:!ml-[0px] rtl:!mr-[0px]"
        />
      </div>
    </div>
  );
}
