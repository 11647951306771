import React from "react";

type DividerProps = {
  className?: string;
};

export function Divider({ className }: DividerProps) {
  return (
    <div
      className={`relative flex items-center flex-grow border-t border-gray-light ${className}`}
    />
  );
}
