import { useTranslation } from "react-i18next";
import { SubTitle, Title, ImageLoader } from "components";
import DefaultCoverImage from "assets/images/challengeDefault.png";
import {
  displayDateFormat,
  getChallengeStatus,
  getChallengeStatusBackground,
  getChallengeType,
} from "utils/helpers";
import { Link } from "react-router-dom";
import useLoadImage from "hooks/useImageLoaded";

type CollaborationCardProps = {
  data: CollaborationData;
  showCollaborationSignature?: boolean;
  path?: string;
  handleCollaborationClick: () => void;
};

export function CollaborationCard({
  data,
  showCollaborationSignature,
  path,
  handleCollaborationClick,
}: CollaborationCardProps) {
  const { t } = useTranslation();
  const [ref, isLoaded, onLoad] = useLoadImage();

  return (
    <Link to={path ? path : `${data?.id}`} onClick={handleCollaborationClick}>
      <div className=" bg-white rounded-md w-full flex h-full flex-col cursor-pointer">
        <div className=" rounded-md min-h-[140px] h-[140px] overflow-hidden">
          {!isLoaded && <ImageLoader />}
          <img
            ref={ref}
            alt="profile picture"
            className=" w-full h-[140px] object-cover "
            src={data?.coverImage ? data?.coverImage : DefaultCoverImage}
            onLoad={onLoad}
          />
        </div>
        <div className=" p-4 lg:p-5 flex flex-col items-start h-full">
          <Title
            className="font-bold text-base leading-[26px] text-gray-109 pb-1 line-clamp-1 WB_BW dirRTL-LTR"
            title={data?.name}
          />
          <SubTitle
            className="font-normal text-sm leading-normal text-black pb-1 !justify-start mb-2.5 "
            title={`${displayDateFormat(data?.startDate)} - ${displayDateFormat(data?.endDate)}`}
          />
          <div className="flex items-center space-x-2 rtl:space-x-reverse mt-auto">
            {showCollaborationSignature && data?.collaborationSignature && (
              <div className="py-1.5 px-3 rounded-xl text-xs text-primary-medium font-normal bg-[#d9dde9]  mt-auto">
                {t(`${getChallengeType(data?.collaborationSignature)}`)}
              </div>
            )}
            <div
              className={`py-1.5 px-3 rounded-xl text-xs text-gray-107 font-normal  mt-auto ${getChallengeStatusBackground(
                data?.statusId
              )}`}
            >
              {t(`${getChallengeStatus(data?.statusId)}`)}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
