import Joi from "joi";
import { PASSWORD_VALIDATION_PATTERN } from "utils/constants";
import { groupBy } from "utils/helpers";

const changePasswordSchema = Joi.object({
  currentPassword: Joi.string()
    .trim()
    .min(8)
    .max(24)
    .regex(PASSWORD_VALIDATION_PATTERN, "password")
    .required()
    .messages({
      "string.empty": "required",
      "string.pattern.base": "global.password_criteria_warning_message",
      "string.pattern.name": "global.password_criteria_warning_message",
      "string.min": "global.password_criteria_warning_message",
    }),
  newPassword: Joi.string()
    .trim()
    .min(8)
    .max(24)
    .regex(PASSWORD_VALIDATION_PATTERN, "password")
    .required()
    .messages({
      "string.empty": "required",
      "string.pattern.base": "global.password_criteria_warning_message",
      "string.pattern.name": "global.password_criteria_warning_message",
      "string.min": "global.password_criteria_warning_message",
    }),
  confirmPassword: Joi.string()
    .trim()
    .min(8)
    .regex(PASSWORD_VALIDATION_PATTERN, "password")
    .valid(Joi.ref("newPassword"))
    .required()
    .messages({
      "string.empty": "required",
      "string.pattern.base": "required",
      "string.pattern.name": "required",
      "any.only": "global.passwords_do_not_match",
      "string.min": "global.password_criteria_warning_message",
    }),
}).options({ abortEarly: false });

export const changePasswordSchemaValidation = (basicFormData: ChangePasswordFormFields) => {
  const result = changePasswordSchema.validate(basicFormData);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
