import React from "react";

type TextAreaProps = {
  textContent: string | undefined;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>, fieldName: string) => void;
  labelText: string;
  hintText: string;
  fieldName: string;
  className?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  maxCharLength?: number;
};
export function TextArea({
  textContent,
  handleChange,
  labelText,
  hintText,
  fieldName,
  className,
  isRequired,
  isDisabled,
  maxCharLength,
  errorMessage,
}: TextAreaProps) {
  return (
    <div className="form-control w-full relative">
      <label className="label p-0 pb-[10px]">
        <span className="label-text text-primary-light text-sm  font-normal flex justify-start items-center ">
          {labelText}
          {isRequired ? <span className=" ml-[3px] text-red-100">*</span> : null}
        </span>
      </label>

      <textarea
        className={`w-full placeholder:text-gray-1100 placeholder:!text-base h-[220px] xl:h-[160px] 2xl:h-12 font-medium placeholder:font-normal text-primary-text text-base outline-none focus:border-primary-medium p-3 ltr:pr-10 rt:pl-10 resize-none min-h-[8.125rem] rounded  border bg-white ${className} ${
          errorMessage ? "!border-red-100" : "border-primary-light "
        }`}
        value={textContent}
        placeholder={hintText}
        maxLength={maxCharLength}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(event, fieldName)}
        disabled={isDisabled}
      />
    </div>
  );
}
