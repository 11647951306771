import { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import {
  DropDown,
  InlineLoader,
  StatisticsDataCard,
  TableLoader,
  Title,
  Header,
  SecondaryButton,
  NoResults,
  NoData,
} from "components";
import { useGetUsersDetails } from "features/Admin/api/useGetUsersDetails";
import Table from "features/Admin/Users/FundingOrganizations/Table";
import { useOutsideClick } from "hooks/useOutsideClick";
import {
  showFundingEmailInviteModal,
  showFundingOrganizationIndividualInviteModal,
} from "utils/confirmationModals";
import { handleUserTableRowClick } from "utils/helpers";
import {
  setSelectedSideMenu,
  setSelectedUserStatusType,
  useSidebarDataStore,
} from "store/useSidebarStore";
import { useStickyScroll } from "hooks/useStickyScroll";
import {
  ADMIN_REQUEST_INIT_PARAMS,
  ORGANIZATIONS_TABLE_HEADERS,
  PAGE_LIMIT,
  USERS,
  USER_STATUS,
} from "utils/constants";
import { clearFilterDataStore, setScrollInfo, useFilterDataStore } from "store/useFilterDataStore";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";
import { useSetScroll } from "hooks/useSetScroll";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import useResetQueryOnLanguageSwitch from "hooks/useResetQueryOnLanguageSwitch";

export default function FundingOrganizationDetails() {
  const navigate = useNavigate();
  const { selectedUserStatusType } = useSidebarDataStore();
  const pathName = window.location.pathname;
  const { scroll } = useStickyScroll();
  const { t } = useTranslation();
  const { tableFilterInfo, scrollInfo } = useFilterDataStore();
  const queryClient = useQueryClient();
  const { scrollPosition } = useGetScrollPosition();

  const enableFiltering = pathName === tableFilterInfo?.pathName;

  const [requestParams, setRequestParams] = useState<UserDetailsParams>(
    selectedUserStatusType
      ? {
          ...ADMIN_REQUEST_INIT_PARAMS,
          profileStatusId: selectedUserStatusType,
          userType: USERS.Funding,
          country: enableFiltering ? tableFilterInfo?.country : "",
          type: enableFiltering ? tableFilterInfo?.researchArea : "",
          fromDate: enableFiltering ? tableFilterInfo?.fromDate : null,
          toDate: enableFiltering ? tableFilterInfo?.toDate : null,
        }
      : {
          ...ADMIN_REQUEST_INIT_PARAMS,
          userType: USERS.Funding,
          country: enableFiltering ? tableFilterInfo?.country : "",
          type: enableFiltering ? tableFilterInfo?.researchArea : "",
          fromDate: enableFiltering ? tableFilterInfo?.fromDate : null,
          toDate: enableFiltering ? tableFilterInfo?.toDate : null,
        }
  );

  const { data, isLoading, fetchNextPage, hasNextPage, refetch } =
    useGetUsersDetails(requestParams);

  const { isOpen, setIsOpen, dropDownRef } = useOutsideClick();

  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "users-details");
  useResetQueryOnLanguageSwitch("users-details");

  const usersDetails = useMemo(() => {
    const tempData: UsersDetails = { users: [], totalCount: 0, totalPages: 0, summary: [] };
    if (data?.pages && data?.pages[0]) {
      const index =
        scrollInfo?.index && data?.pages[scrollInfo?.index]?.users?.length > 0
          ? scrollInfo?.index
          : 0;

      tempData.summary = data.pages[index]?.summary;
      tempData.totalCount = data.pages[index]?.totalCount;
      tempData.totalPages = data.pages[index]?.totalPages;
      const users = data.pages
        .map((item) => {
          return item.users;
        })
        .flat();
      tempData.users = users;
    }
    return tempData;
  }, [data, scrollInfo?.index]);

  const linkList: Array<LinkItem> = [
    {
      title: "admin.users",
      path: "/admin/users",
    },
    {
      title: "admin.funding_organizations",
      path: "",
    },
  ];

  const inviteDropDownItems: Array<DropdownListItem> = [
    { type: "individual", icon: "plus", label: "admin.individual_invite" },
    { type: "bulk", icon: "bulkEmail", label: "admin.invite_via_email" },
  ];

  const handleCardClick = (id: number) => {
    clearFilterDataStore();
    queryClient.resetQueries(["users-details"]);
    if (id !== requestParams.profileStatusId) {
      setRequestParams(() => ({
        ...ADMIN_REQUEST_INIT_PARAMS,
        profileStatusId: id,
        userType: USERS.Funding,
      }));
      setSelectedUserStatusType(id);
    }
  };

  const handleItemSelection = (type: string) => {
    if (type === "individual") {
      showFundingOrganizationIndividualInviteModal();
    } else if (type === "bulk") {
      showFundingEmailInviteModal();
    }
  };

  const getTableHeaders = () => {
    if (requestParams?.profileStatusId === USER_STATUS.PendingApproval) {
      return "admin.pending_request_for_funding_organizations";
    } else if (requestParams?.profileStatusId === USER_STATUS.Disabled) {
      return "admin.disabled_funding_organizations";
    } else {
      return "admin.active_funding_organizations";
    }
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "admin.users",
    });
  }, []);

  useEffect(() => {
    if (scrollInfo?.pathName && pathName !== scrollInfo?.pathName) {
      clearFilterDataStore();
    }
  }, [pathName, scrollInfo?.pathName]);

  const handleRowClick = async (userId: string, index: number) => {
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: location.pathname,
      index: pageParam,
    });

    await handleUserTableRowClick(
      userId,
      requestParams?.profileStatusId,
      () => navigate(userId),
      refetch,
      t("global.something_went_wrong")
    );
  };

  const updateRequestParams = (data: FilterParams) => {
    setRequestParams({
      ...requestParams,
      ...data,
    });
    refetch();
  };

  const handleClearFilter = () => {
    clearFilterDataStore();
    setRequestParams(
      selectedUserStatusType
        ? {
            ...ADMIN_REQUEST_INIT_PARAMS,
            profileStatusId: selectedUserStatusType,
            userType: USERS.Funding,
          }
        : { ...ADMIN_REQUEST_INIT_PARAMS, userType: USERS.Funding }
    );
    queryClient.resetQueries(["users-details"]);
  };

  const isClearFilterActive: boolean =
    JSON.stringify(requestParams) !==
    JSON.stringify({
      ...ADMIN_REQUEST_INIT_PARAMS,
      profileStatusId: selectedUserStatusType ?? USER_STATUS.PendingApproval,
      userType: USERS.Funding,
    });

  return (
    <div className=" w-full h-full flex flex-col">
      <div
        className={
          scroll
            ? "navbar px-10   p-0 justify-between  top-0 z-[99] nav-header bg-white animate__animated animate__fadeInDown shadow-sticky w-[calc(100vw-250px)] !py-[26px] !h-[90px] fixed stickyHeader"
            : "navbar bg-gray-light px-10   p-0 justify-between h-[118px] py-[30px] top-0 z-30 nav-header w-[calc(100vw-250px)] fixed"
        }
      >
        <Header
          className="!px-0 !py-0 !w-full !h-auto !bg-transparent"
          title={"admin.funding_organizations"}
          linkList={linkList}
        />
        <div
          className="dropdown dropdown-bottom dropdown-end ltr:ml-[18px] rtl:mr-[18px]"
          onClick={() => setIsOpen(!isOpen)}
          ref={dropDownRef}
        >
          <DropDown
            title={"organization.invite_users"}
            list={inviteDropDownItems}
            handleSelection={handleItemSelection}
            className="min-w-[150px] invite-drop-down"
            isOpen={isOpen}
          />
        </div>
      </div>
      <div className="px-10 h-full flex flex-col pt-[118px] pb-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-[10px] text-white selectedCard relative">
          {usersDetails && usersDetails.summary?.length > 0 ? (
            usersDetails.summary?.map((item) => {
              if (item.userTypeId === USERS.Funding) {
                return item.userSummary?.map((i, index) => (
                  <StatisticsDataCard
                    key={`${i.name}-${index}-${i.count}`}
                    data={{
                      title: i.name,
                      value: i.count,
                      type: i.name,
                    }}
                    handleClick={() => handleCardClick(i.id)}
                    isActive={i.id === requestParams.profileStatusId}
                  />
                ));
              }
            })
          ) : (
            <InlineLoader />
          )}
        </div>
        <div className=" flex items-center justify-between">
          <Title
            className="font-semibold text-black mb-5 mt-[30px] text-base"
            title={t(getTableHeaders())}
          />
          {isClearFilterActive ? (
            <SecondaryButton
              startIcon="clearFilter"
              className="!min-h-[38px] !h-[38px]  space-x-1.5 !bg-transparent text-primary-dark"
              title={"global.clear_filters"}
              handleClick={handleClearFilter}
            />
          ) : null}
        </div>
        {usersDetails && !isLoading ? (
          <Title
            className=" text-primary-light text-sm leading-4 font-normal mb-3"
            title={`${usersDetails?.totalCount ? usersDetails?.totalCount : t("global.no")} ${t(
              "organization.results_found"
            )}`}
          />
        ) : null}
        {isLoading && !isClearFilterActive ? (
          <div
            className={` flex flex-col w-full h-auto xl:h-full items-center rounded-md justify-start bg-white mb-[45px] noData`}
          >
            <div className=" flex flex-col items-center justify-start my-[102px]">
              <InlineLoader />
            </div>
          </div>
        ) : usersDetails ? (
          <InfiniteScroll
            dataLength={usersDetails?.users?.length}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={hasNextPage || isLoading ? <TableLoader /> : null}
            scrollThreshold="200px"
            className="!min-h-[75vh] "
          >
            <div className=" flex flex-col p-4 pt-[18px] w-full h-full items-center rounded-md justify-start bg-white">
              <Table
                userData={usersDetails?.users ?? []}
                headers={ORGANIZATIONS_TABLE_HEADERS}
                handleRowClick={(userId, index) => handleRowClick(userId, index)}
                updateRequestParams={updateRequestParams}
                requestParams={requestParams}
              />
              <NoResults
                isLoading={isLoading}
                isFilterApplied={isClearFilterActive}
                dataLength={usersDetails?.users?.length ?? 0}
              />
            </div>
          </InfiniteScroll>
        ) : (
          <NoData message="admin.no_data_to_show" />
        )}
      </div>
    </div>
  );
}
