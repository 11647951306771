import { useEffect, useMemo } from "react";
import { InlineLoader, Label, Title } from "components";
import coverImage from "assets/images/challengeAvatar.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { useGetInterests } from "../api/useGetInterests";
import NoData from "features/Messages/NoData";
import { getTimeDifference } from "utils/helpers";

type InterestsBarItemProps = {
  handleItemClick: (challengeId: number, interestId: number) => void;
  selectedInterestId: number;
  interestId: number;
};

export default function InterestsBar({
  handleItemClick,
  selectedInterestId,
}: InterestsBarItemProps) {
  const { data, fetchNextPage, hasNextPage, isLoading } = useGetInterests();
  const forumDetails = useMemo(() => {
    const tempData: Interests = { forums: [], totalCount: 0, totalPages: 0 };
    if (data?.pages && data?.pages[0]) {
      tempData.totalCount = data.pages[0]?.totalCount;
      tempData.totalPages = data.pages[0]?.totalPages;
      const forums = data.pages
        .map((item) => {
          return item.forums;
        })
        .flat();
      tempData.forums = forums;
    }
    return tempData;
  }, [data]);

  useEffect(() => {
    const infiniteScroll = document.getElementById("scrollableDiv");
    if (infiniteScroll) {
      const wrapperHeight = infiniteScroll.clientHeight;
      setInfiniteScrollHeight(wrapperHeight);
    }
  }, [forumDetails]);

  const setInfiniteScrollHeight = (height: number) => {
    const infiniteScroll = document.getElementById("scrollableDiv");
    if (infiniteScroll) {
      infiniteScroll.style.height = `${height + 160}px`;
    }
  };
  return (
    <div className="w-full">
      <div
        className=" overflow-auto !mt-0 md:block flex items-center flex-col px-5 pt-5 msgListing"
        id="scrollableDiv"
      >
        {forumDetails && forumDetails.totalCount && forumDetails.totalCount > 0 ? (
          <InfiniteScroll
            dataLength={forumDetails?.forums?.length}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={hasNextPage || isLoading ? <InlineLoader /> : null}
            scrollThreshold="160"
            scrollableTarget="scrollableDiv"
          >
            {forumDetails?.forums?.map((item: InterestDetails) => (
              <InterestsBarItem
                key={item.interestId}
                data={item}
                handleItemClick={() => handleItemClick(item.challengeId, item.interestId)}
                selectedInterestId={selectedInterestId}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <NoData message="forum.no_interests" />
        )}
      </div>
    </div>
  );
}

const InterestsBarItem = ({
  data,
  handleItemClick,
  selectedInterestId,
}: {
  data: InterestDetails;
  handleItemClick: (challengeId: number) => void;
  selectedInterestId: number;
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`p-2.5 cursor-pointer w-full ${
        selectedInterestId === data?.interestId ? "activeForum" : ""
      }`}
      onClick={() => handleItemClick(data?.challengeId)}
    >
      <div className="flex gap-2.5 items-start">
        <img
          alt="profile picture"
          className="h-10 w-10 min-w-[40px] rounded-full object-cover "
          src={data.coverImage ? data?.coverImage : coverImage}
        />
        <div className=" truncate block ">
          <div className="flex flex-col items-start">
            <Title
              title={data?.challengeName}
              className="text-sm text-black truncate font-semibold w-full dirRTL_LTR"
            />
            <span className=" text-sm font-normal text-gray-102 leading-5 my-[2px] truncate !overflow-hidden !inline w-full dirRTL_LTR">
              {data?.message?.length > 20 ? `${data?.message.slice(0, 21)} ...` : data?.message}
            </span>

            <Label
              text={getTimeDifference(data?.createdDate, i18n.language, t)}
              className="font-normal text-xs text-gray-107 "
            />
          </div>
        </div>
      </div>
    </div>
  );
};
