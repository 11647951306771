import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveChallengeLearnMore(
  LearnMoreFormData: ChallengeLearnMoreSave,
  challengeId: number
) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  const response = await apiClient.post(urls.challengeLearnMore(challengeId), LearnMoreFormData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useSaveChallengeLearnMore() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      formData,
      challengeId,
    }: {
      formData: ChallengeLearnMoreSave;
      challengeId: number;
    }) => {
      const data = await saveChallengeLearnMore(formData, challengeId);
      queryClient.invalidateQueries(["challenge-learnMore", challengeId]);
      queryClient.invalidateQueries(["challenge-preview-details", challengeId]);
      return data;
    }
  );
}
