import { useMemo } from "react";
import { Descriptor } from "components";
import { useMasterDataStore } from "store/useMasterDataStore";

type ContactPreviewCardProps = {
  fullName: string;
  designation: string;
  contactNumber: string;
  countryCodeId: number;
  email: string;
};

export function ContactPreviewCard({
  fullName,
  designation,
  contactNumber,
  countryCodeId,
  email,
}: ContactPreviewCardProps) {
  const { countryCode } = useMasterDataStore();

  const selectedCountryCode = useMemo(() => {
    return countryCode?.filter((item) => item.id == countryCodeId)?.[0];
  }, [countryCode, countryCodeId]);

  return (
    <div>
      <h4 className="text-lg text-black Word-break">{fullName}</h4>
      <p className="text-gray-102 mb-[10px] text-sm Word-break max-[1025px]:mb-[16px] pt-1">
        {designation}
      </p>
      <div className="flex gap-5 text-black max-[1025px]:flex-col max-[1025px]:items-start max-[1025px]:gap-3 mb-6">
        <Descriptor
          title={`${selectedCountryCode?.name?.code ?? ""} ${contactNumber ?? ""}`}
          startIcon="previewContact"
          className="flex gap-2 justify-center items-center text-sm"
        />
        <Descriptor
          title={email}
          startIcon="previewMail"
          className="flex gap-2 justify-center items-center text-sm Word-break"
        />
      </div>
    </div>
  );
}
