import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Label, Title, UploadedFileItem } from "components";
import { getTimeDifference } from "utils/helpers";
import dayjs from "dayjs";

type OutgoingChatProps = {
  message: Message;
  isLast: boolean;
};

export default function OutgoingChat({ message, isLast }: OutgoingChatProps) {
  const { t, i18n } = useTranslation();
  const [currentCount, setCount] = useState(0);
  const timer = () => {
    const date = dayjs.utc(message?.createdOn);
    const now = dayjs().utc();
    const timeDiff = now.diff(date);
    const oneMinute = 6;
    if (timeDiff < oneMinute) {
      setCount(currentCount + 1);
    }
  };

  useEffect(() => {
    if (currentCount < 0) {
      return;
    }
    let id: NodeJS.Timer;
    if (currentCount <= 100) {
      id = setInterval(timer, 100);
    }
    return () => clearInterval(id);
  }, [currentCount]);

  return (
    <div className="flex flex-col items-end">
      <div className="text+time flex items-end mb-1.5 flex-wrap space-x-2 rtl:space-x-reverse">
        <div>
          <Title
            title={t("forum.you")}
            className="font-semibold text-sm flex-shrink-0 text-black"
          />
        </div>
        <div>
          <Label
            text={getTimeDifference(message.createdOn, i18n.language, t)}
            className="text-gray-103 font-normal text-sm "
          />
        </div>
      </div>
      <div
        className={`bg-white rounded-2xl rounded-tr-none p-4 max-w-full md:max-w-[447px] flex flex-col gap-4 mb-4 border shadow-500 ${
          currentCount > 0 && currentCount <= 100 && isLast ? " border border-secondary-medium" : ""
        }`}
      >
        {message?.title ? (
          <div>
            <Label
              text={t(
                message?.isChallenge ? "forum.post_from_challenge" : "forum.post_from_collaboration"
              )}
              className="text-gray-200 font-normal text-sm mb-1"
            />
            <Title title={message?.title} className="font-semibold text-base text-gray-109 " />
          </div>
        ) : null}
        <p
          className="text-sm font-normal text-gray-109 WB_BW"
          dangerouslySetInnerHTML={{
            __html: message?.content?.replace(/\n/g, "<br/>"),
          }}
        />
        {message?.attachments?.length > 0 ? (
          <div>
            <Label
              text={t("global.attachments")}
              className="text-gray-200 font-normal text-sm mb-2"
            />
            <div className="attachments flex gap-2 flex-wrap w-full">
              {message?.attachments?.map((attaches) => (
                <UploadedFileItem key={attaches.id} isPreview className=" w-full" file={attaches} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
