import { useEffect, useMemo } from "react";
import coverImage from "assets/images/challengeAvatar.png";
import { Chip, InlineLoader, Label, Title } from "components";
import { useGetForums } from "features/Messages/api/useGetForums";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import NoData from "features/Messages/NoData";
import { getChallengeStatusBackground } from "utils/helpers";

type CollaborationForumBarProps = {
  handleItemClick: (name: string, id: number) => void;
  collaborationId: number;
};

export default function CollaborationForumBar({
  handleItemClick,
  collaborationId,
}: CollaborationForumBarProps) {
  const { i18n } = useTranslation();

  const { data, fetchNextPage, hasNextPage, isLoading, refetch } = useGetForums(false);

  const forumDetails = useMemo(() => {
    const tempData: CollaborationForums = { forums: [], totalCount: 0, totalPages: 0 };
    if (data?.pages && data?.pages[0]) {
      tempData.totalCount = data.pages[0]?.totalCount;
      tempData.totalPages = data.pages[0]?.totalPages;
      const forums = data.pages
        .map((item) => {
          return item.forums;
        })
        .flat();
      tempData.forums = forums;
    }
    return tempData;
  }, [data]);

  useEffect(() => {
    const infiniteScroll = document.getElementById("scrollableDiv");
    if (infiniteScroll) {
      const wrapperHeight = infiniteScroll.clientHeight;
      setInfiniteScrollHeight(wrapperHeight);
    }
  }, [forumDetails]);

  const setInfiniteScrollHeight = (height: number) => {
    const infiniteScroll = document.getElementById("scrollableDiv");
    if (infiniteScroll) {
      infiniteScroll.style.height = `${height + 160}px`;
    }
  };

  useEffect(() => {
    refetch();
  }, [i18n.language, refetch]);

  return (
    <div>
      {forumDetails && forumDetails.totalCount && forumDetails.totalCount > 0 ? (
        <InfiniteScroll
          dataLength={forumDetails?.forums?.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={hasNextPage || isLoading ? <InlineLoader /> : null}
          scrollThreshold="160"
          scrollableTarget="scrollableDiv"
        >
          <div
            id="scrollableDiv"
            className=" !mt-0 md:block flex  items-center flex-col px-5 pt-5 msgListing overflow-auto !h-auto"
          >
            {forumDetails?.forums?.map((item: CollaborationForumItem) => (
              <CollaborationForumBarItem
                key={item.collaborationId}
                data={item}
                handleItemClick={() =>
                  handleItemClick(item.collaborationTitle, item.collaborationId)
                }
                collaborationId={collaborationId}
              />
            ))}
          </div>
        </InfiniteScroll>
      ) : (
        <div className=" p-5">
          <NoData message="forum.no_forum_posts" />
        </div>
      )}
    </div>
  );
}

const CollaborationForumBarItem = ({
  data,
  handleItemClick,
  collaborationId,
}: {
  data: CollaborationForumItem;
  handleItemClick: (collaborationId: number) => void;
  collaborationId: number;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`p-2.5 cursor-pointer w-full ${
        collaborationId === data?.collaborationId ? "activeForum" : ""
      }`}
      onClick={() => handleItemClick(data?.collaborationId)}
    >
      <div className="flex gap-2.5 items-start">
        <img
          alt="profile picture"
          className="h-10 w-10 min-w-[40px] rounded-full object-cover "
          src={data.coverImage ? data?.coverImage : coverImage}
        />
        <div className="block truncate">
          <div className="flex flex-col overflow-hidden items-start">
            <Title
              title={data?.collaborationTitle}
              className="text-sm text-black font-semibold truncate w-full dirLTR"
            />
            <Label
              text={
                data?.memberCount > 1
                  ? `${data?.memberCount - 1} + ${t("forum.members")}`
                  : `${data?.memberCount} ${t("forum.member")}`
              }
              className=" text-sm font-normal text-gray-102 leading-5 my-[2px] truncate !overflow-hidden !inline dirRTL_LTR"
            />
            <Chip
              className={getChallengeStatusBackground(data?.collaborationStatusId)}
              text={data?.collaborationStatus}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
