import { useState, useEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Sidebar } from "components";
import {
  setSelectedSideMenu,
  setSelectedUserStatusType,
  useSidebarDataStore,
} from "store/useSidebarStore";
import { useUserDataStore } from "store/useUserDataStore";
import { USER_STATUS } from "utils/constants";
import { clearStores } from "store";
import { hideSidebarMenuItem } from "utils/helpers";
import { clearFilterDataStore, clearUserTypeFilter } from "store/useFilterDataStore";

export default function ResearchInstitute() {
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const { userInfo } = useUserDataStore();
  const { selectedSideMenu } = useSidebarDataStore();
  const queryClient = useQueryClient();

  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const SidebarMenuItems: Array<SidebarMenuItem> = useMemo(() => {
    const tempItems = [];
    if (userInfo && hideSidebarMenuItem(userInfo?.profileStatusId)) {
      tempItems.push({
        name: "global.settings",
        icon: "settings",
        subMenu: [
          {
            name: "global.profile",
          },
          {
            name: "global.password",
          },
        ],
      });
    } else {
      tempItems.push({
        name: "research_institute.requests",
        icon: "requests",
      });
      tempItems.push({
        name: "home.researchers",
        icon: "researchers",
      });
      tempItems.push({
        name: "admin.challenges",
        icon: "challenge",
      });

      if (userInfo?.isGlobal) {
        tempItems.push({
          name: "global.collaborations",
          icon: "collaborationIcon",
        });
      }
      tempItems.push({
        name: "forum.forums",
        icon: "forums",
      });
      tempItems.push({
        name: "global.events",
        icon: "eventIcon",
      });

      tempItems.push({
        name: "global.settings",
        icon: "settings",
        subMenu: [
          {
            name: "global.profile",
          },
          {
            name: "global.password",
          },
          {
            name: "research_institute.approvals",
          },
        ],
      });
    }
    return tempItems;
  }, [userInfo]);

  const handleIsOpen = (type: string) => {
    if (selectedSideMenu?.type === type) {
      return !selectedSideMenu.isOpen;
    } else {
      return true;
    }
  };

  const handleMenuClick = (type: string) => {
    clearFilterDataStore();
    clearUserTypeFilter();
    setSelectedUserStatusType();
    if (type === "forum.forums") {
      navigate("forums");
    } else if (type === "admin.challenges") {
      queryClient.resetQueries(["challenge-list"]);
      navigate("challenges");
    } else if (type === "research_institute.requests") {
      queryClient.resetQueries(["challenge-list"]);
      navigate("requests");
    } else if (type === "home.researchers") {
      queryClient.resetQueries(["users-details"]);
      navigate("researchers");
    } else if (type === "global.events") {
      queryClient.resetQueries(["event-list"]);
      navigate("events");
    } else if (type === "global.collaborations") {
      queryClient.resetQueries(["collaboration-list"]);
      navigate("collaborations");
    }
    setSelectedSideMenu({
      type,
      isOpen: handleIsOpen(type),
      subMenuType: type === "global.settings" ? selectedSideMenu?.subMenuType : null,
    });
  };

  const handleSubMenuClick = (type: string) => {
    clearFilterDataStore();
    clearUserTypeFilter();
    if (selectedSideMenu?.type) {
      setSelectedSideMenu({
        type: selectedSideMenu?.type,
        isOpen: true,
        subMenuType: type,
      });
    }
    if (type === "global.password") {
      navigate("settings/change-password");
    } else if (type === "global.profile") {
      navigate(`settings/profile/${userInfo?.userId}`);
    } else if (type === "research_institute.approvals") {
      navigate(`settings/approvals`);
    }
  };

  useEffect(() => {
    if (userInfo?.profileStatusId === USER_STATUS.Disabled) {
      clearStores();
      navigate("/login");
    }
    if (pathName === "/research-institute/forums") {
      setSelectedSideMenu({
        type: "forum.forums",
        isOpen: false,
      });
    } else if (pathName === "/research-institute/events") {
      setSelectedSideMenu({
        type: "global.events",
        isOpen: false,
      });
    }
  }, [navigate, userInfo, pathName]);
  return (
    <div className={`flex  min-h-screen w-full   ${!isOpenSideMenu ? "miniSidebar" : ""}`}>
      <Sidebar
        menuItems={SidebarMenuItems}
        handleCollapseClick={onToggleClick}
        handleMenuClick={handleMenuClick}
        handleSubMenuClick={handleSubMenuClick}
      />
      <div className="w-full items-baseline mainContainer min-h-[calc(100vh-76px)] flex overflow-auto lg:rtl:mr-[250px] lg:ltr:ml-[250px] flex-col">
        <Outlet />
      </div>
    </div>
  );
}
