import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveWorkDetails(userId: string, formData: WorkExperienceDetails) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(urls.saveWorkDetails(userId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response;
}

export function useSaveWorkDetails() {
  const qc = useQueryClient();

  return useMutation(
    async ({
      formData,
      researchScholarId,
    }: {
      formData: WorkExperienceDetails;
      researchScholarId: string;
    }) => {
      const userInfo = useUserDataStore.getState().userInfo;
      const userId = researchScholarId ? researchScholarId : userInfo?.userId;
      if (userId) {
        const result = await saveWorkDetails(userId, formData);
        qc.invalidateQueries({ queryKey: ["work-experience-details", userId] });
        qc.invalidateQueries({ queryKey: ["personal-details", userId] });
        return result;
      }
    }
  );
}
