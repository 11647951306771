import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function manageRequest(scholarId: string, statusId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(
    urls.manageRequest(scholarId, statusId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus;
}

export function useManageRequest() {
  const qc = useQueryClient();
  return useMutation(async ({ scholarId, statusId }: { scholarId: string; statusId: number }) => {
    const result = await manageRequest(scholarId, statusId);
    qc.invalidateQueries(["research-scholar-details", scholarId]);
    return result;
  });
}
