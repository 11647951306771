import { produce } from "immer";
import { DEFAULT_EDITOR_EMPTY_STATE } from "utils/constants";
import { create } from "zustand";
import { persist } from "zustand/middleware";

const defaultChallengeInfo: ChallengeInfo = {
  id: 0,
  title: "",
  startDate: "",
  endDate: "",
  isPrivate: false,
  targetIndustry: null,
  location: "",
  summary: "",
  coverImage: "",
  challengeCompletionStatusId: 0,
  challengeStatusId: 0,
  isGlobal: false,
};
const defaultChallengeMileStone: ChallengeMileStone = {
  id: 0,
  startDate: "",
  endDate: "",
  milestone: "",
  description: "",
};
export const defaultChallengeAdditionalInfo: ChallengeAdditionalInfo = {
  information: DEFAULT_EDITOR_EMPTY_STATE,
  documents: [],
  challengeStatusId: 0,
  challengeCompletionStatusId: 0,
};

export const defaultPrimaryContact: ChallengeContact = {
  isPrimary: true,
  fullName: "",
  designation: "",
  email: "",
  countryCodeId: "",
  contactNumber: "",
};

const defaultLearnMore: ChallengeLearnMore = {
  overviewOfCurrentChallenges: "",
  detailedDescriptionOftheChallenges: "",
  desiredEnhancements: "",
  resourcesAndConstraints: "",
  successMetrics: "",
};

export type ChallengeStore = {
  challengeInfo: ChallengeInfo;
  milestones: ChallengeMileStone[];
  learnMore: ChallengeLearnMore;
  funding: string | undefined;
  additionalInfo: ChallengeAdditionalInfo;
  contacts: ChallengeContact[];
  challengeTags: OptionItem[] | null;
};

export const useChallengeStore = create<ChallengeStore>()(
  persist(
    (set) => ({
      coverImage: "",
      challengeInfo: defaultChallengeInfo,
      milestones: [],
      learnMore: defaultLearnMore,
      funding: undefined,
      additionalInfo: defaultChallengeAdditionalInfo,
      contacts: [defaultPrimaryContact],
      challengeTags: [],
    }),
    {
      name: "challenge-data",
      getStorage: () => sessionStorage,
    }
  )
);

export function setChallengeInfo(value: any, key: keyof ChallengeInfo) {
  useChallengeStore.setState(
    produce((state: any) => {
      if (state.challengeInfo) {
        state.challengeInfo[key] = value;
      }
    })
  );
}
export function addChallengeMilestone() {
  useChallengeStore.setState(
    produce((state: any) => {
      if (state.milestones) {
        state.milestones.push(defaultChallengeMileStone);
      }
    })
  );
}
export function setChallengeMilestone(value: any, key: string, index: number) {
  useChallengeStore.setState(
    produce((state: any) => {
      if (state.milestones && state.milestones.length > 0) {
        state.milestones[index][key] = value;
      }
    })
  );
}
export function removeMilestone(index: number) {
  const milestones = useChallengeStore.getState().milestones;
  useChallengeStore.setState(
    produce((state: any) => {
      if (state.milestones && state.milestones.length > 0) {
        const updatedItems = [...milestones];
        updatedItems.splice(index, 1);
        state.milestones = updatedItems;
      }
    })
  );
}
export function setChallengeLearnMore(value: any) {
  useChallengeStore.setState(
    produce((state: any) => {
      state.learnMore = value;
    })
  );
}
export function setChallengeLearnMoreInfoItem(
  value: string | number | null,
  key: keyof ChallengeLearnMore
) {
  useChallengeStore.setState(
    produce((state) => {
      if (state.learnMore) {
        state.learnMore[key] = value;
      }
    })
  );
}

export function setChallengeFunding(value: any) {
  useChallengeStore.setState(
    produce((state: any) => {
      state.funding = value;
    })
  );
}
export function setChallengeAdditionalInfo(value: any, key: keyof ChallengeAdditionalInfo) {
  useChallengeStore.setState(
    produce((state: any) => {
      state.additionalInfo[key] = value;
    })
  );
}

export function setChallengeContacts(challengeContacts: ChallengeContact[]) {
  useChallengeStore.setState(
    produce((state: any) => {
      if (state.contacts && state.contacts.length > 0) {
        state.contacts = challengeContacts;
      }
    })
  );
}

export function setChallengeContact(value: any, key: string, index: number) {
  useChallengeStore.setState(
    produce((state: any) => {
      if (state.contacts && state.contacts.length > 0) {
        state.contacts[index][key] = value;
      }
    })
  );
}

export function setChallengeTags(tags: OptionItem[] | null) {
  useChallengeStore.setState(
    produce((state: any) => {
      if (state.challengeTags) {
        state.challengeTags = tags;
      }
    })
  );
}
export function setChallengeDetails(details: ChallengeInfo) {
  useChallengeStore.setState(
    produce((state: any) => {
      if (state.challengeInfo) {
        state.challengeInfo = details;
      }
    })
  );
}
export function setMilestones(milestones: ChallengeMileStone[]) {
  useChallengeStore.setState(
    produce((state: any) => {
      if (state.milestones) {
        state.milestones = milestones;
      }
    })
  );
}

export function setChallengeArtifacts(additionalInfo: ChallengeAdditionalInfo) {
  useChallengeStore.setState(
    produce((state: any) => {
      state.additionalInfo = additionalInfo;
    })
  );
}
export function clearChallengeStore() {
  useChallengeStore.setState(
    produce((state: ChallengeStore) => {
      state.challengeInfo = defaultChallengeInfo;
      state.milestones = [];
      state.learnMore = defaultLearnMore;
      state.funding = undefined;
      state.additionalInfo = defaultChallengeAdditionalInfo;
      state.contacts = [defaultPrimaryContact];
      state.challengeTags = [];
    })
  );
}
