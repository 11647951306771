import React from "react";
import { Button } from "react-daisyui";
import SVGIcon from "assets/Icons";

type ChipProps = {
  text: string;
  handleClose?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  className?: string;
};

export function Chip({ text, handleClose, className, disabled }: ChipProps) {
  return (
    <span
      className={`bg-primary-500 pl-3 pr-3 rounded-full flex items-center mb-2 ltr:mr-2 rtl:ml-2 truncate ${className}`}
    >
      <span className=" text-primary-medium font-medium text-xs  leading-5 py-[6px] whitespace-nowrap truncate">
        {text}
      </span>
      {handleClose && (
        <Button
          className="btn btn-link text-primary-medium h-auto min-h-0 p-0 ltr:ml-[10px] rtl:mr-[10px]"
          onClick={(event: React.MouseEvent<HTMLElement>) => handleClose(event)}
          disabled={disabled}
        >
          <SVGIcon name="closeV1" />
        </Button>
      )}
    </span>
  );
}
