import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useUserDataStore } from "store/useUserDataStore";
import { displayDateFormat, getColumns } from "utils/helpers";
import { DropDown } from "components";

type TableProps = {
  userData: Array<OrganizationUserData>;
  headers: Array<string>;
  handleDelete: (userId: string) => void;
  handleResetPassword: (userId: string) => void;
};

const Table = ({ userData, headers, handleDelete, handleResetPassword }: TableProps) => {
  const { t } = useTranslation();
  const userInfo = useUserDataStore.getState().userInfo;
  const loggedUserEmail = userInfo?.email;
  const filteredData = userData?.map((item) => {
    return {
      createdOn: item.createdOn,
      fullName: item.fullName,
      email: item.email,
    };
  });

  const dataTable = useReactTable({
    data: userData,
    columns: filteredData && filteredData.length ? getColumns(filteredData) : [],

    getCoreRowModel: getCoreRowModel(),
  });

  const [isMenuOpen, setIsMenuOpen] = useState<DropDownState>({ id: "", isOpen: false });

  const moreOptionDropDownItems: Array<DropdownListItem> = [
    { type: "resetPassword", icon: "dropResetPass", label: "organization.reset_password" },
    { type: "deleteUser", icon: "dropDeleteUser", label: "admin.delete_user" },
  ];

  const resetPasswordDropDownItem: Array<DropdownListItem> = [
    { type: "resetPassword", icon: "dropResetPass", label: "organization.reset_password" },
  ];

  const handleDropDownSelection = (type: string, id: string) => {
    if (type === "deleteUser") {
      handleDelete(id);
    } else if (type === "resetPassword") {
      handleResetPassword(id);
    }
  };

  const handleDropDownMenuList = (email: string) => {
    if (email === loggedUserEmail) {
      return resetPasswordDropDownItem;
    } else {
      return moreOptionDropDownItems;
    }
  };

  const handleDropDownClick = (id: string) => {
    if (id === isMenuOpen.id) {
      setIsMenuOpen({ id: id, isOpen: !isMenuOpen.isOpen });
    } else {
      setIsMenuOpen({ id: id, isOpen: true });
    }
  };

  return (
    <div className="customTable overflowUnset mb-10">
      <table>
        <thead>
          {headers?.map((item) => (
            <th key={item}>{t(item)}</th>
          ))}
        </thead>
        <tbody>
          {dataTable.getRowModel().rows.map((row) => (
            <tr key={row.id} className="cursor-default">
              {row.getVisibleCells().map((cell, index) => {
                if (cell.column.columnDef.header?.toString().trim() == "createdOn") {
                  return <td key={index}>{displayDateFormat(cell.getValue() as string)}</td>;
                } else if (cell.column.columnDef.header?.toString().trim() === "fullName") {
                  return (
                    <td key={index}>
                      <p className="truncate">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </p>
                    </td>
                  );
                } else {
                  return (
                    <td key={index}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  );
                }
              })}
              <td>
                <div
                  className="dropdown dropdown-bottom dropdown-end mx-auto  icon__Dropdown"
                  onClick={() => handleDropDownClick(row?.id)}
                >
                  <DropDown
                    title="..."
                    list={handleDropDownMenuList(row.original?.email)}
                    handleSelection={(type: string) => {
                      handleDropDownSelection(type, row.original?.userId);
                    }}
                    isOpen={isMenuOpen.isOpen}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
