import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function sendFundingInterestMessage(challengeId: number, formData: FundingInterestMessage) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(urls.fundingInterestMessage(challengeId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useSendFundingInterestMessage() {
  return useMutation(
    async ({
      challengeId,
      formData,
    }: {
      challengeId: number;
      formData: FundingInterestMessage;
    }) => {
      return await sendFundingInterestMessage(challengeId, formData);
    }
  );
}
