import React, { useState } from "react";
import { Button } from "react-daisyui";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { SubTitle, Title, SecondaryButton, PrimaryButton, LoaderModal, CheckBox } from "components";
import { ACCEPTED_INVITE_FILE_TYPES, MAX_FILE_SIZE, USERS } from "utils/constants";
import { showConfirmationModal } from "utils/confirmationModals";
import {
  ToasterIconType,
  showErrorToastMessage,
  showSuccessToastMessage,
} from "utils/toasterMessage";
import {
  useUploadBulkInvite,
  useVerifyBulkInviteFile,
} from "features/Admin/api/useBulkInviteFileUpload";
import { useDownloadCSVTemplate } from "features/Admin/api/useDownloadCSVTemplete";
import { useUserDataStore } from "store/useUserDataStore";

type BulkInviteProps = {
  onCancel: () => void;
};

export function BulkInvite({ onCancel }: BulkInviteProps) {
  const { t } = useTranslation();
  const { userInfo } = useUserDataStore();

  const { mutateAsync: verifyBulkInviteFile, isLoading: isUploadLoading } =
    useVerifyBulkInviteFile();
  const { mutateAsync: uploadBulkInvite, isLoading: isSendInviteLoading } = useUploadBulkInvite();
  const { mutateAsync: downloadTemplate } = useDownloadCSVTemplate();

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isGlobalInvite, setIsGlobalInvite] = useState(false);
  const [fileUploadErrors, setFileUploadErrors] = useState<FileUploadErrors | null>(null);
  const handleDownloadTemplate = () => {
    downloadTemplate();
  };

  const handleFileDelete = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.deletion_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
    });
    if (!result) {
      return;
    }
    if (result) {
      setUploadedFile(null);
      setFileUploadErrors(null);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event?.target?.files?.[0];
    if (selectedFile) {
      if (!ACCEPTED_INVITE_FILE_TYPES.includes(selectedFile?.type)) {
        return showErrorToastMessage({
          iconType: ToasterIconType.ALERT,
          message: t("admin.csv_file_type_error"),
        });
      } else if (selectedFile?.size > MAX_FILE_SIZE) {
        return showErrorToastMessage({
          iconType: ToasterIconType.ALERT,
          message: t("admin.csv_file_size_error"),
        });
      } else {
        setUploadedFile(selectedFile);
        try {
          await verifyBulkInviteFile(selectedFile);
        } catch (error: ApiError) {
          if (error.response.data.status === 400 || error.response.data.status === 422) {
            setFileUploadErrors(error.response.data);
          } else {
            return showErrorToastMessage({
              iconType: ToasterIconType.ALERT,
              message: t("global.something_went_wrong"),
            });
          }
        }
      }
    }
  };

  const handleSendInvite = async () => {
    if (!uploadedFile) {
      showErrorToastMessage({
        iconType: ToasterIconType.ALERT,
        message: t("admin.empty_file_error_message"),
      });
    }
    if (uploadedFile && fileUploadErrors?.message == null) {
      try {
        const result = await uploadBulkInvite({
          file: uploadedFile,
          isGlobalInvite:
            userInfo?.userType === USERS.ResearchInstitute ? userInfo?.isGlobal : isGlobalInvite,
        });
        showSuccessToastMessage({
          message: result.message,
        });

        onCancel();
      } catch (error: ApiError) {
        return showErrorToastMessage({
          iconType: ToasterIconType.ALERT,
          message:
            error.response.data.status === 400
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };

  const FileUploader = () => {
    return (
      <div className=" bg-gray-light/60 w-full flex items-center justify-between p-[14px] rounded">
        <SubTitle
          title={t("global.upload_file")}
          className=" text-primary-medium text-sm font-normal"
        />
        <div
          className={`bg-white border w-9 h-9 rounded-full border-primary-medium text-primary-medium border-dashed  flex items-center justify-center hover:!text-white hover:!bg-primary-medium uploadContainer relative overflow-hidden}`}
        >
          <label className="  font-medium text-[13px] flex items-center">
            <SVGIcon name="upload" />
          </label>
          <input
            type="file"
            className=" opacity-0 absolute cursor-pointer w-full h-full -indent-[999px] typeFile"
            accept={ACCEPTED_INVITE_FILE_TYPES?.join()}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="fixed h-screen w-screen bg-black/[0.3] z-[99] flex justify-center items-center top-0 ltr:left-0 rtl:right-0 ">
      <div className="animate__animated animate__zoomIn flex flex-col rounded-md items-center bg-white max-w-[470px] mx-2 sm:w-[470px] py-[50px] px-8 max-h-[calc(100vh-3.125rem)] overflow-auto">
        <div>
          <SVGIcon name="bulkInvite" />
        </div>
        <Title title={t("admin.bulk_invite")} className="mt-10 text-black text-xl font-semibold" />

        <span className="text-center mt-[6px] text-base font-normal mb-6 text-black">
          {t("admin.this_will_allow_you_to_upload")}{" "}
          <span className=" text-primary-medium"> {`"${t("admin.csv_template")}"`}</span>
          {t("admin.bulk_invite_message_last_part")}
        </span>
        {uploadedFile === null ? (
          <>
            <FileUploader />
            <div className="flex pt-7">
              <PrimaryButton
                title={"admin.download_csv_template"}
                startIcon="download"
                handleClick={handleDownloadTemplate}
                className=" h-auto w-auto flex-nowrap !bg-white !text-sm !text-primary-medium border-none !min-h-0  underline"
              />
            </div>
          </>
        ) : null}
        {uploadedFile ? (
          <div className="bulk-invite-uploading  bg-gray-light/60 w-full flex flex-col p-6 pt-4 rounded">
            <span className="text-sm font-normal text-gray-200 mb-3">{t("global.upload")}</span>
            <div className="flex justify-between items-center truncate">
              <div className=" flex items-center truncate">
                <div className=" min-w-[24px]">
                  <SVGIcon name="doc" />
                </div>
                <span className="text-sm font-normal ltr:pl-4 rtl:pr-4 truncate text-black">
                  {uploadedFile.name}
                </span>
              </div>

              {fileUploadErrors && fileUploadErrors.message ? (
                <span className=" text-xs font-normal text-red-dark ml-auto  flex items-center">
                  {t("global.error")}
                  <div className="ltr:ml-1 rtl:mr-1">
                    <SVGIcon name="info" />
                  </div>
                </span>
              ) : null}

              {isUploadLoading ? (
                <span className=" text-xs font-normal text-gray-200 ltr:ml-auto rtl:mr-auto  flex items-center">
                  {t("global.uploading")}
                  <button
                    type="button"
                    className="text-green-200 ltr:ml-1 rtl:mr-1 p-0 w-auto min-w-0"
                  >
                    <SVGIcon name="uploading" />
                  </button>
                </span>
              ) : (
                <Button
                  className="btn p-0 min-h-0 w-auto h-auto border-none !bg-transparent text-xs font-normal text-red-dark ltr:ml-3 rtl:mr-3  flex items-center"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleFileDelete(event)}
                >
                  <SVGIcon name="trash" />
                </Button>
              )}
            </div>
          </div>
        ) : null}
        {fileUploadErrors && fileUploadErrors.message ? (
          <SubTitle
            className="text-center mt-3 text-sm font-normal text-red-dark"
            title={fileUploadErrors.message}
          />
        ) : null}
        {fileUploadErrors && fileUploadErrors.data ? (
          <>
            {fileUploadErrors?.data?.invalidEmails ? (
              <SubTitle
                className="text-center mt-3 text-sm font-normal text-primary-medium"
                title={fileUploadErrors?.data?.invalidEmails}
              />
            ) : null}
            {fileUploadErrors?.data?.missingEmails ? (
              <SubTitle
                className="text-center mt-3 text-sm font-normal text-primary-medium"
                title={fileUploadErrors?.data?.missingEmails}
              />
            ) : null}
            {fileUploadErrors?.data?.missingNames ? (
              <SubTitle
                className="text-center mt-3 text-sm font-normal text-primary-medium"
                title={fileUploadErrors?.data?.missingNames}
              />
            ) : null}
          </>
        ) : null}
        {userInfo?.userType === USERS.Admin ? (
          <div className=" w-full text-left mt-2">
            <CheckBox
              option={{
                label: t("admin.global_invite"),
                value: "global_invite",
              }}
              className="p-2 text-black customCheckboxV2"
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setIsGlobalInvite(event.target.checked);
              }}
              checked={isGlobalInvite}
            />
          </div>
        ) : null}
        <div className=" flex w-full justify-center space-x-[10px] rtl:space-x-reverse mt-[38px]">
          <SecondaryButton
            title={"global.close"}
            handleClick={onCancel}
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
          />
          <PrimaryButton
            title={"global.send_invite"}
            handleClick={handleSendInvite}
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
          />
        </div>
      </div>
      {isSendInviteLoading ? <LoaderModal /> : null}
    </div>
  );
}
