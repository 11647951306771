import type { FormatOptionLabelMeta, GroupBase, OptionsOrGroups } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { useTranslation } from "react-i18next";

type Option = {
  id: number | string;
  name: string;
};

type SingleSelectProps = {
  options?: Array<Option> | [];
  labelText: string;
  hintText: string;
  selectedOptions: Option | null;
  setSelectedOptions: (options: Option | null) => void;
  isDisabled?: boolean;
  addNew?: boolean;
  loadOptions: (
    search: string,
    prevOptions: OptionsOrGroups<Option, GroupBase<Option>>,
    additional?: { page: number }
  ) => Promise<{
    options: Option[];
    hasMore: boolean;
  }>;
  errorToastMessage?: string;
  createLabel?: string;
  resetKey?: string;
  className?: string;
  errorMessage?: string | null;
  onMenuOpen: () => void;
};

export function SearchableSingleSelect({
  options,
  labelText,
  hintText,
  selectedOptions,
  setSelectedOptions,
  isDisabled,
  loadOptions,
  createLabel,
  resetKey,
  className,
  errorMessage,
  onMenuOpen,
}: SingleSelectProps) {
  const { t } = useTranslation();

  const handleChange = (newValue: any) => {
    setSelectedOptions(newValue);
  };

  return (
    <div className={className}>
      <label className=" d-flex label p-0 pb-[10px] text-primary-light text-sm">
        {labelText}
        {""}
        <span className=" ltr:mr-auto rtl:ml-auto  ml-1 text-red-200">*</span>
      </label>
      <SelectComponent
        options={options}
        loadOptions={loadOptions}
        value={selectedOptions}
        isClearable={false}
        isMulti={false}
        placeholder={hintText}
        noOptionsMessage={() => t("global.no_results")}
        controlShouldRenderValue={true}
        hideSelectedOptions={false}
        onChange={(newValue: any, actionMeta: any) => handleChange(newValue)}
        classNamePrefix={`customSelect truncate ${
          errorMessage ? "customSelectError" : "customSelect"
        }`}
        isDisabled={isDisabled}
        formatOptionLabel={(data: Option) => (data?.name ? data?.name : "")}
        getOptionValue={(option: Option | any) => {
          return (option?.id as string) || `${option.value}`;
        }}
        createLabel={createLabel}
        resetKey={resetKey}
        onMenuOpen={onMenuOpen}
      />
    </div>
  );
}

type SelectProps = {
  isClearable: boolean;
  isMulti: boolean;
  options?: Array<Option> | [];
  placeholder: string;
  noOptionsMessage: () => string;
  controlShouldRenderValue: boolean;
  hideSelectedOptions: boolean;
  onChange: (newValue: any, actionMeta: any) => void;
  classNamePrefix?: string;
  value: Option | null;
  isDisabled?: boolean;
  addNew?: boolean;
  loadOptions: (
    search: string,
    prevOptions: OptionsOrGroups<Option, GroupBase<Option>>,
    additional?: { page: number }
  ) => Promise<{
    options: Option[];
    hasMore: boolean;
  }>;
  formatOptionLabel: (data: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) => string;
  getOptionValue: (option: Option) => string;
  createLabel?: string;
  resetKey?: string;
  onMenuOpen: () => void;
};

function SelectComponent({
  loadOptions,
  formatOptionLabel,
  getOptionValue,
  resetKey,
  onMenuOpen,
  ...rest
}: SelectProps) {
  const { t } = useTranslation();

  const NoOptionsMessage = () => {
    return <div>{t("global.no_results")}</div>;
  };

  return (
    <AsyncPaginate
      {...rest}
      loadOptionsOnMenuOpen
      getOptionValue={getOptionValue}
      loadOptions={loadOptions}
      key={resetKey}
      noOptionsMessage={NoOptionsMessage}
      formatOptionLabel={formatOptionLabel}
      onMenuOpen={onMenuOpen}
    />
  );
}
