import Joi from "joi";
import { groupBy } from "utils/helpers";

const challengeMileStoneSchema = Joi.object({
  id: Joi.number().optional(),
  startDate: Joi.string().isoDate().messages({
    "string.empty": "required",
    "string.isoDate": "required",
    "string.date.base": "required",
  }),
  endDate: Joi.string().isoDate().messages({
    "string.empty": "required",
    "string.isoDate": "required",
    "string.date.base": "required",
  }),
  milestone: Joi.string().messages({ "string.empty": "required" }),
  description: Joi.string().messages({ "string.empty": "required" }),
}).options({ abortEarly: false });

export const challengeMilestonesValidation = (challengeMileStone: ChallengeMileStone) => {
  const result = challengeMileStoneSchema.validate(challengeMileStone, { abortEarly: false });
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
