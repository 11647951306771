import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function inviteResearchInstitute(formData: ResearchInstituteCreation) {
    const tokenInfo = useUserDataStore.getState().tokenInfo;
    const token = tokenInfo?.token;
    const response = await apiClient.post(urls.inviteResearchInstitute(), formData, {
        headers: {Authorization: `Bearer ${token}`}
    });
    return response?.data as ApiStatus | ApiError
}

export function useInviteResearchInstitute() {
    return useMutation(async (formData: ResearchInstituteCreation) => {
        return await inviteResearchInstitute(formData);
    });
}