import { useEffect } from "react";
import Router from "router";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "tippy.js/dist/tippy.css";
import "assets/css/main.scss";
import "animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ReactQueryClientWrapper } from "utils/reactQueryHelper";
import "App.css";
import { useUserDataStore } from "store/useUserDataStore";

function App() {
  const { i18n } = useTranslation();
  const { userInfo } = useUserDataStore();
  document
    .getElementsByTagName("html")?.[0]
    ?.setAttribute("dir", i18n.language === "en" ? "ltr" : "rtl");
  document.body.classList.replace(
    i18n.language === "en" ? "rtl" : "ltr",
    i18n.language === "en" ? "ltr" : "rtl"
  );

  useEffect(() => {
    const handlePopstate = (e: PopStateEvent) => {
      const prevPath = location.pathname;
      if (prevPath == "/login" && userInfo) {
        window.history.go(1);
      }
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [userInfo]);

  return (
    <ReactQueryClientWrapper>
      <BrowserRouter>
        <Router />
        <ToastContainer
          transition={Zoom}
          className={`custom-toaster top-[1rem]`}
          position={i18n.language === "en" ? "top-right" : "top-left"}
        />
      </BrowserRouter>
    </ReactQueryClientWrapper>
  );
}

export default App;
