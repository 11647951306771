import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveEventLearnMore(formData: EventLearnMore) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  const response = await apiClient.put(urls.saveEventLearnMore(), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response as EventLearnMore | ApiError;
}

export function useSaveEventLearnMore() {
  const qc = useQueryClient();
  return useMutation(async (formData: EventLearnMore) => {
    const result = await saveEventLearnMore(formData);
    qc.invalidateQueries({ queryKey: ["event-about", formData.id] });
    qc.invalidateQueries({ queryKey: ["event-learn-more", formData.id] });
    return result;
  });
}
