import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

type RichTextEditorProps = {
  text?: string;
  handleTextChange: (text: string) => void;
  key?: string;
  placeholder?: string | null;
  isDisabled?: boolean;
};
export const RichTextEditor = ({
  key,
  text,
  placeholder,
  handleTextChange,
  isDisabled
}: RichTextEditorProps) => {
  const editorStyle = {
    backgroundColor: isDisabled? "#F2F2F2" : "#fff",
    color: isDisabled? "#7892A4" : "#000",
    fontFamily: "Poppins",
  };
  const { i18n } = useTranslation();
  const [textAlignment, setTextAlignment] = useState<"left" | "right">(() =>
    i18n.language === "ar" ? "right" : "left"
  );
  const [editorState, setEditorState] = useState(() => {
    if (text && text.length > 0) {
      const contentState = convertFromRaw(JSON.parse(text));
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  });

  const handleStateChange = (e: any) => {
    setEditorState(e);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    handleTextChange(JSON.stringify(rawContentState));
  };

  let editorWrapperClassName = "wrapper-class";
  const editorHasText = editorState.getCurrentContent().hasText();
  if (!editorHasText) {
    if (editorState.getCurrentContent().getBlockMap().first().getType() !== "unstyled") {
      editorWrapperClassName = "wrapper-class-hide-placeholder";
    }
  }

  useEffect(() => {
    if (i18n.language === "ar") {
      setTextAlignment("right");
    } else {
      setTextAlignment("left");
    }
  }, [i18n.language]);

  return (
    <div>
      <Editor
        key={key || textAlignment}
        editorState={editorState}
        onEditorStateChange={handleStateChange}
        wrapperClassName={editorWrapperClassName}
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: ["inline", "list", "textAlign", "history", "fontSize", "fontFamily"],
          inline: {
            options: ["bold", "italic", "underline", "strikethrough"],
          },
          fontFamily: {
            options: [
              "Arial",
              "Georgia",
              "Impact",
              "Tahoma",
              "Times New Roman",
              "Verdana",
              "Poppins",
              "sans-serif",
            ],
            className: "editor-font-family",
            dropdownClassName: "editor-font-family-dropdown",
          },
          fontSize: {
            options: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 24, 30, 36, 48, 60, 72, 96],
            className: "editor-font-size",
            dropdownClassName: "editor-font-size-dropdown",
          },
        }}
        textAlignment={textAlignment}
        editorStyle={editorStyle}
        placeholder={placeholder}
        textDirectionality={i18n.language === "ar" ? "RTL" : "LTR"}
        readOnly={isDisabled}
        toolbarHidden={isDisabled}
      />
    </div>
  );
};
