import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { USERS } from "utils/constants";
import { urls } from "utils/urls";

export async function getRolesAndPermissions(userType: number) {
  const userInfo = useUserDataStore.getState().userInfo;
  const loggedInUserType = userInfo?.userType;
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  if (loggedInUserType === USERS.Admin) {
    const res = await apiClient.get(urls.rolesAndPermissions(userType), {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data?.data as PermissionData | ApiError;
  } else {
    const res = await apiClient.get(urls.permissionList(userType), {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data?.data as PermissionData | ApiError;
  }
}

export function useGetRolesAndPermissions(userType: number) {
  return useQuery(["permission-list", userType], async () => {
    const result = await getRolesAndPermissions(userType);
    return result;
  });
}
