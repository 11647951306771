import { Area } from "react-easy-crop";
import { createColumnHelper } from "@tanstack/react-table";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/en";
import { NavigateFunction } from "react-router-dom";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import draftToHtml from "draftjs-to-html";
import {
  CHALLENGE_STATUS,
  CHALLENGE_TYPES,
  EVENT_STATUS,
  GLOBAL_PORTAL_FILTERS,
  PHONE_NUMBER_MAX_CHAR_LENGTH,
  PHONE_NUMBER_ONLY_MAX_CHAR_LENGTH,
  PREVIEW_ALLOWED_EXTENSIONS,
  USERS,
  USER_STATUS,
} from "utils/constants";
import { showErrorToastMessage } from "utils/toasterMessage";
import { getUserStatus } from "features/Admin/api/useGetUserStatus";

export function emailValidator(email: string) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

export function passwordValidator(password: string) {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/.test(password);
}

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);

    return groups;
  }, {} as Record<K, T[]>);

export const isRuleVerified = (ruleNumber: number, password: string) => {
  const specialChars = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const value = password?.trim();
  switch (ruleNumber) {
    case 1:
      return value.length >= 8;
    case 2:
      return /[A-Z]/.test(value);
    case 3:
      return /\d+/.test(value);
    case 4:
      return specialChars.test(value);
    default:
      return false;
  }
};

export const phoneMaxLimitChecker = (value: string) => {
  if (value.includes("(") || value.includes(")") || value.includes("+") || value.includes("-")) {
    return PHONE_NUMBER_MAX_CHAR_LENGTH;
  } else {
    return PHONE_NUMBER_ONLY_MAX_CHAR_LENGTH;
  }
};

export const columnHelper = createColumnHelper<any>();

export const getColumns = (table: Array<any>) => {
  const columns = Object.keys(table[0]);

  const result = columns.map((item) => {
    return columnHelper.accessor(
      (row) => {
        return row[item];
      },

      {
        header: ` ${item} `,
      }
    );
  });

  return result;
};

export const displayDateFormat = (date: string) => {
  return dayjs(date).format("DD/MM/YYYY");
};

export const downloadFile = (url: string, fileName: string) => {
  saveAs(url, fileName);
};

export const handleLinkClick = async (url: string | null) => {
  if (url) {
    window.open(url, "_blank");
  }
};

export const isPreviewAllowed = (fileName: string) => {
  const lastPeriodIndex = fileName?.lastIndexOf(".");
  return PREVIEW_ALLOWED_EXTENSIONS.includes(
    fileName?.substring(lastPeriodIndex + 1)?.toLowerCase()
  );
};

export const calculateAge = (dateOfBirth: string) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  const [datePart] = dateOfBirth.split("T");
  const [year, month, day] = datePart.split("-");
  const birthYear = parseInt(year, 10);
  const birthMonth = parseInt(month, 10) - 1;
  const birthDay = parseInt(day, 10);
  let age = currentYear - birthYear;
  if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
    age--;
  }
  return age;
};

export const handleKeyDown = (e: React.KeyboardEvent) => {
  ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
};

export const scrollToReference = (ref: React.RefObject<HTMLDivElement>, value = 50) => {
  if (ref && ref.current) {
    window.scrollTo({
      top: ref.current.offsetTop - value,
      left: 0,
      behavior: "smooth",
    });
  }
};

export const getChallengeStatus = (status: number) => {
  if (status === CHALLENGE_STATUS.Draft) {
    return "organization.draft";
  } else if (status === CHALLENGE_STATUS.Open) {
    return "organization.open";
  } else if (status === CHALLENGE_STATUS.Live) {
    return "organization.live";
  } else if (status === CHALLENGE_STATUS.Close) {
    return "organization.closed";
  } else if (status === CHALLENGE_STATUS.Disable) {
    return "global.disabled";
  } else if (status === CHALLENGE_STATUS.Pending) {
    return "global.pending";
  }
};

export const getChallengeType = (type: number) => {
  if (type === CHALLENGE_TYPES.Private) {
    return "organization.private";
  } else if (type === CHALLENGE_TYPES.Public) {
    return "organization.public";
  } else if (type === CHALLENGE_TYPES.All) {
    return "global.all";
  }
};

export const formatDataChanger = (data: InfiniteListData | undefined) => {
  const tempData: ChallengeListingResponse = { challenges: [], totalCount: 0, totalPages: 0 };
  if (data?.pages && data?.pages[0]) {
    tempData.totalCount = data.pages[0]?.totalCount;
    tempData.totalPages = data.pages[0]?.totalPages;
    const challenges = data.pages
      .map((item) => {
        return item.challenges;
      })
      .flat();
    tempData.challenges = challenges;
  }
  return tempData;
};

export const getPlainText = (htmlString: string) => {
  const parsedData = JSON.parse(htmlString);
  if (parsedData.blocks && parsedData.blocks.length > 0) {
    let totalLength = 0;
    for (const block of parsedData.blocks) {
      if (block.text) {
        totalLength += block.text.length;
      }
    }
    return totalLength;
  } else {
    return 0;
  }
};

export const getMasterDataValue = (masterData: OptionItem | MasterDataItem, language: string) => {
  if (language === "ar") {
    return masterData?.name.ar;
  } else {
    return masterData?.name.en;
  }
};

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image: HTMLImageElement = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export async function getCroppedImg(imageSrc: string, pixelCrop: Area, fileName: string) {
  const image: any = await createImage(imageSrc);
  // As a blob to multipart/formData
  const canvas = document.createElement("canvas");
  const scaleX = image?.naturalWidth / image?.width;
  const scaleY = image?.naturalHeight / image?.height;
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(
    image,
    pixelCrop.x * scaleX,
    pixelCrop.y * scaleY,
    pixelCrop.width * scaleX,
    pixelCrop.height * scaleY,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );
  const dataURL = canvas.toDataURL();
  return dataUrlToFile(dataURL, fileName);
}

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: "image/png" });
}

export const setMasterDataValue = (
  masterDataItem: CountryCodeItem | MasterDataItem,
  language: string
) => {
  return {
    value: masterDataItem.id ?? "",
    label: language === "ar" ? masterDataItem?.name?.ar : masterDataItem?.name?.en,
  };
};

export const getChallengeStatusTitle = (challengeStatusId = 0) => {
  if (challengeStatusId === CHALLENGE_STATUS.Open) {
    return "organization.open";
  } else if (challengeStatusId === CHALLENGE_STATUS.Close) {
    return "organization.closed";
  } else if (challengeStatusId === CHALLENGE_STATUS.Live) {
    return "organization.live";
  } else if (challengeStatusId === CHALLENGE_STATUS.Disable) {
    return "global.disabled";
  } else {
    return "";
  }
};

export const hideSidebarMenuItem = (profileStatus: number) => {
  if (
    profileStatus === USER_STATUS.New ||
    profileStatus === USER_STATUS.Draft ||
    profileStatus === USER_STATUS.PendingApproval
  ) {
    return true;
  } else {
    return false;
  }
};

export const getTimeDifference = (
  utcTimestamp: string,
  locale: string,
  t: (key: string) => string
) => {
  dayjs.extend(utc);
  dayjs.locale(locale);
  const now = dayjs().utc();
  const date = dayjs.utc(utcTimestamp);
  const timeDiff = now.diff(date);
  const oneMinute = 60 * 1000;
  const oneHour = 60 * oneMinute;

  if (timeDiff < oneMinute) {
    return t("global.just_now");
  } else if (timeDiff < oneHour) {
    const minutesAgo = Math.floor(timeDiff / oneMinute);
    if (locale === "ar") {
      return t("global.ago") + " " + minutesAgo + " " + t("global.minute");
    } else {
      const minutes = minutesAgo > 1 ? t("global.minutes") : t("global.minute");
      return minutesAgo + " " + minutes + " " + t("global.ago");
    }
  } else if (timeDiff < 24 * oneHour) {
    const hoursAgo = Math.floor(timeDiff / oneHour);
    if (locale === "ar") {
      return t("global.ago") + " " + hoursAgo + " " + t("global.hours");
    } else {
      const hours = hoursAgo > 1 ? t("global.hours") : t("global.hour");
      return hoursAgo + " " + hours + " " + t("global.ago");
    }
  } else {
    const formattedDate = date.format("DD/MM/YYYY");
    return formattedDate;
  }
};

export const getBreadCrumbTitle = (profileStatusId: number) => {
  if (profileStatusId === USER_STATUS?.PendingApproval) {
    return "global.pending";
  } else if (profileStatusId === USER_STATUS?.Approved) {
    return "global.active";
  } else if (profileStatusId === USER_STATUS?.Disabled) {
    return "global.disabled";
  } else {
    return "";
  }
};

export const extractFileNameFromUrl = (url: string) => {
  const urlParts = url.split("/");
  const lastPart = urlParts[urlParts.length - 1].split("?")[0];
  const decodedFileName = decodeURIComponent(lastPart.replace(/%20/g, " "));
  return decodedFileName;
};

export const isEditorEmpty = (data: string): boolean => {
  try {
    const jsonData = JSON.stringify(JSON.parse(data));
    const jsonObject = JSON.parse(jsonData);
    if (jsonObject.blocks && jsonObject.blocks.length > 0) {
      const result = jsonObject.blocks.some((item: any) => item?.text?.trim().length > 0);
      return result;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const getChallengeStatusBackground = (status: number) => {
  if (status === CHALLENGE_STATUS.Draft) {
    return "!bg-badge-draft !text-badge-draftText";
  } else if (status === CHALLENGE_STATUS.Open) {
    return "!bg-badge-open !text-badge-openText";
  } else if (status === CHALLENGE_STATUS.Live) {
    return "!bg-badge-live !text-badge-liveText";
  } else if (status === CHALLENGE_STATUS.Close) {
    return "!bg-badge-closed !text-badge-closedText";
  }else if (status === CHALLENGE_STATUS.Pending) {
    return "!bg-badge-pending !text-badge-pendingText";
  }
};
export const setContactMasterDataValue = (masterDataItem: CountryCodeItem) => {
  return {
    value: masterDataItem.id ?? "",
    label: masterDataItem?.name?.code ?? "",
  };
};

export const handleUserTableRowClick = async (
  userId: string,
  profileStatusId: number,
  handleNavigate: () => void,
  refetch: any,
  message: string
) => {
  if (profileStatusId !== USER_STATUS.PendingApproval) {
    handleNavigate();
    return;
  }
  try {
    const statusInfo = await getUserStatus(userId);
    if (statusInfo?.data !== profileStatusId) {
      showErrorToastMessage({
        message: statusInfo?.message,
      });
      refetch();
      return;
    }
    handleNavigate();
  } catch (error) {
    showErrorToastMessage({
      message,
    });
  }
};

export const formatToISODate = (date: string) => {
  return dayjs(date).format("YYYY-MM-DD");
};

// const isEditorContainsList = (editorText: string): boolean => {
//   try {
//     const learnMore = JSON.stringify(editorText);
//     const jsonObject = JSON.parse(learnMore);
//     if (jsonObject.blocks && jsonObject.blocks.length > 0) {
//       const result = jsonObject.blocks.some(
//         (item: any) => item?.type === "unordered-list-item" || item?.type === "ordered-list-item"
//       );
//       return result;
//     } else {
//       return false;
//     }
//   } catch (e) {
//     return false;
//   }
// };

const convertJSONListToHtml = (editorText: any) => {
  const options = {
    blockStyleFn: (block: any) => {
      const data = block.getData();
      if (data.size == 0) return;

      let style = {};
      if (data.get("text-align")) style = { ...style, textAlign: data.get("text-align") };
      return {
        attributes: {
          dir: "auto",
        },
        style,
      };
    },
    inlineStyleFn: (styles: any) => {
      let style = {};
      const fontSizeKey = "fontsize-";
      const fontFamilyKey = "fontfamily-";
      const fontSize = styles.filter((value: any) => value.startsWith(fontSizeKey)).first();
      const fontFamily = styles.filter((value: any) => value.startsWith(fontFamilyKey)).first();
      if (fontSize) {
        style = {
          ...style,
          fontSize: fontSize.replace(fontSizeKey, ""),
        };
      }
      if (fontFamily) {
        style = {
          ...style,
          fontFamily: fontFamily.replace(fontFamilyKey, ""),
        };
      }

      return {
        element: "span",
        style,
      };
    },
  };

  try {
    const parsedJson = JSON.parse(editorText);
    const contentState = convertFromRaw(parsedJson);
    const html = stateToHTML(contentState, options);
    return html;
  } catch (error) {
    return "";
  }
};

// const convertJSONStringToTHtml = (editorText: string): string => {
//   try {
//     const parsedJson = JSON.parse(editorText);
//     const contentState = convertFromRaw(parsedJson);
//     const html = draftToHtml(convertToRaw(contentState));
//     const convertedHtml = html;
//     return convertedHtml;
//   } catch (error) {
//     return "";
//   }
// };

export const convertJSONStringAsHtml = (editorText: string): string => {
  try {
    // This condition needs to be done to ensure listing is displayed properly.
    // const parsedJson = JSON.parse(editorText);
    // const result = isEditorContainsList(parsedJson);
    // if (result) {
    //   return convertJSONListToHtml(editorText);
    // } else {
    //   return convertJSONStringToTHtml(editorText);
    // }
    // Used convertJSONListToHtml function as it satisfied both scenarios.

    return convertJSONListToHtml(editorText);
  } catch (error) {
    return "";
  }
};

export const getCountryNameById = (
  countryId: number,
  countryList: Array<MasterDataItem>,
  language: string
) => {
  const country = countryList?.find((item) => item.id === countryId);
  if (!country) return "";
  return getMasterDataValue(country, language);
};

export const isGlobalWebsite = () => {
  if (window.location.origin === process.env.REACT_APP_NATIONAL_WEBSITE_URL) {
    return false;
  }
  return true;
};

export const getGlobalFilterParam = (filterId: number) => {
  if (filterId === GLOBAL_PORTAL_FILTERS.National) {
    return false;
  } else if (filterId === GLOBAL_PORTAL_FILTERS.Global) {
    return true;
  }
  return "";
};

export const getRedirectionPath = (userType: number) => {
  if (userType === USERS.ResearchScholar) {
    return `${window.location.origin}/research-scholar/home`;
  } else if (userType === USERS.ResearchInstitute) {
    return `${window.location.origin}/research-institute/requests`;
  } else if (userType === USERS.Organization) {
    return `${window.location.origin}/organization/dashboard`;
  }
  return `${window.location.origin}`;
};

export const getEventTagClassName = (statusId: number) => {
  if (statusId === EVENT_STATUS.Upcoming) {
    return "!bg-badge-draft !text-badge-draftText";
  } else if (statusId === EVENT_STATUS.Draft) {
    return "!bg-badge-open !text-badge-openText";
  }
  if (statusId === EVENT_STATUS.Live) {
    return "!bg-badge-live !text-badge-liveText";
  }
  if (statusId === EVENT_STATUS.Closed) {
    return "!bg-badge-closed !text-badge-closedText";
  }
};

export const handleDisableOrEnableNavigation = (
  isSearchResult: boolean,
  navigate: NavigateFunction,
  navigateTo: string
) => {
  if (isSearchResult) {
    navigate(-1);
  } else {
    navigate(navigateTo);
  }
};

export const isMobileDevice = () => {
  const details = navigator.userAgent;
  const regexp = /android|iphone|kindle|ipad/i;
  const isMobileDevice = regexp.test(details);
  if (isMobileDevice) {
    return true;
  } else {
    return false;
  }
};
