import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { LoaderModal, Header } from "components";
import Tags from "features/Organization/Challenge/CreateChallenge/Tags";
import About from "features/Organization/Challenge/CreateChallenge/About";
import Funding from "features/Organization/Challenge/CreateChallenge/Funding";
import Contacts from "features/Organization/Challenge/CreateChallenge/Contacts";
import Artifacts from "features/Organization/Challenge/CreateChallenge/Artifacts";
import Navigator from "features/Organization/Challenge/CreateChallenge/Navigator";
import LearnMore from "features/Organization/Challenge/CreateChallenge/LearnMore";
import Milestones from "features/Organization/Challenge/CreateChallenge/Milestones";
import { useSaveChallenge } from "features/Organization/api/useSaveChallengeInfo";
import { useUpdateChallenge } from "features/Organization/api/useUpdateChallengeInfo";
import { useGetChallengeTags } from "features/Organization/api/useGetChallengeTags";
import { useSaveChallengeTags } from "features/Organization/api/useSaveChallengeTags";
import { useGetChallengeFunding } from "features/Organization/api/useGetChallengeFunding";
import { useGetChallengeDetails } from "features/Organization/api/useGetChallengeDetails";
import { useSaveChallengeFunding } from "features/Organization/api/useSaveChallengeFunding";
import { useGetChallengeContacts } from "features/Organization/api/useGetChallengeContacts";
import { useSaveChallengeContacts } from "features/Organization/api/useSaveChallengeContact";
import { useGetChallengeLearnMore } from "features/Organization/api/useGetChallengeLearnMore";
import { useGetChallengeArtifacts } from "features/Organization/api/useGetChallengeArtifacts";
import { useSaveChallengeLearnMore } from "features/Organization/api/useSaveChallengeLearnMore";
import { useSaveChallengeMilestone } from "features/Organization/api/useSaveChallengeMilestone";
import { useGetChallengeMilestones } from "features/Organization/api/useGetChallengeMilestones";
import { useSaveChallengeAdditionalInfo } from "features/Organization/api/useSaveAdditionalInfo";
import {
  checkUnsavedChallengeDetails,
  checkUnsavedTextContent,
  checkUnsavedMilestones,
  prepareAdditionalInfoFormData,
  prepareContactFormData,
  prepareContactTagsFormData,
  prepareFormData,
  prepareFundingFormData,
  prepareLearnMoreFormData,
  prepareMileStoneFormData,
  checkUnsavedAdditionalInfo,
  checkUnsavedContacts,
  checkUnsavedTags,
  isUnSavedContactsExist,
  isUnsavedTextContent,
  isUnSavedMileStoneExist,
  isUnsavedAdditionalInfoExist,
  isValidDataEntered,
  checkUnsavedLearnMoreDetails,
} from "features/Organization/Challenge/CreateChallenge/helper";
import { showChallengePreviewModal, showConfirmationModal } from "utils/confirmationModals";
import {
  CREATE_CHALLENGE_COMPLETION_STATUS,
  CHALLENGE_STATUS,
  CREATE_CHALLENGE_PATH_PATTERN,
  TOASTER_DURATION,
} from "utils/constants";
import { challengeContactValidation } from "utils/validation/challengeContactValidation";
import { challengeDetailsValidation } from "utils/validation/challengeDetailsValidation";
import { challengeLearnMoreValidation } from "utils/validation/challengeLearnMoreValidation";
import { challengeEditorValidation } from "utils/validation/challengeEditorValidation";
import { challengeMilestonesValidation } from "utils/validation/challengeMilestoneValidation";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import { useChallengeStore, setChallengeInfo } from "store/useChallengeStore";
import { useStickyScroll } from "hooks/useStickyScroll";
import { setSelectedSideMenu } from "store/useSidebarStore";

export default function ChallengeCreate() {
  const navigate = useNavigate();
  const { challengeId } = useParams();
  const pathname = window.location.pathname;
  const { t } = useTranslation();
  const { scroll } = useStickyScroll();
  const { mutateAsync: saveChallengeInfo, isLoading: isChallengeSaving } = useSaveChallenge();
  const { mutateAsync: saveChallengeMilestone, isLoading: isMilestoneSaving } =
    useSaveChallengeMilestone();
  const { mutateAsync: saveChallengeLearnMore, isLoading: isLearnMoreSaving } =
    useSaveChallengeLearnMore();
  const { mutateAsync: saveChallengeFunding, isLoading: isFundingSaving } =
    useSaveChallengeFunding();
  const { mutateAsync: saveChallengeArtifacts, isLoading: isArtifactsSaving } =
    useSaveChallengeAdditionalInfo();
  const { mutateAsync: saveChallengeContacts, isLoading: isContactsSaving } =
    useSaveChallengeContacts();
  const { mutateAsync: saveChallengeTags, isLoading: isTagsSaving } = useSaveChallengeTags();
  const { mutateAsync: updateChallenge, isLoading: isUpdatingChallenge } = useUpdateChallenge();
  const { data: challengeInfo, isLoading: isChallengeInfoLoading } = useGetChallengeDetails();
  const { data: milestones, isLoading: isChallengeMilestoneLoading } = useGetChallengeMilestones();
  const { data: learnMoreData, isLoading: isChallengeLearnMoreLoading } =
    useGetChallengeLearnMore();
  const { data: fundingData, isLoading: isChallengeFundingLoading } = useGetChallengeFunding();
  const { data: artifactsData, isLoading: isArtifactsLoading } = useGetChallengeArtifacts();
  const { data: contactsDetails, isLoading: isContactsLoading } = useGetChallengeContacts();
  const { data: tagsData, isLoading: isTagsLoading } = useGetChallengeTags();

  const [stepNumber, setStepNumber] = useState<number>(CREATE_CHALLENGE_COMPLETION_STATUS.About);
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const [completionStatusId, setCompletionStatusId] = useState<number>(0);
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    if (challengeId) {
      setChallengeInfo(Number(challengeId), "id");
    }
  }, [challengeId]);

  useEffect(() => {
    if (challengeInfo) {
      const challengeData: ChallengeInfo = challengeInfo;
      const isEdit =
        challengeData.challengeStatusId === CHALLENGE_STATUS.Open ||
        challengeData.challengeStatusId === CHALLENGE_STATUS.Live;
      setEdit(isEdit);
      setCompletionStatusId(challengeInfo.challengeCompletionStatusId);
    }
  }, [challengeInfo]);

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string> | null | Array<Record<string, string> | null>
  >(null);
  const isDraftChallenge = challengeId === undefined;

  const navigationItems: Array<RecordItem> = [
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.About,
      label: "organization.about",
    },
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.Milestones,
      label: "organization.milestones",
    },
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.LearnMore,
      label: "organization.learn_more",
    },
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.Funding,
      label: "organization.funding",
    },
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.Artifacts,
      label: "organization.additional_info_and_artifacts",
    },
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.Contacts,
      label: "organization.contact",
    },
    {
      value: CREATE_CHALLENGE_COMPLETION_STATUS.Tags,
      label: "organization.tags",
    },
  ];

  const challengeTitle = challengeInfo ? challengeInfo?.title : t("organization.new_challenge");

  const getChallengeBreadCrumb = () => {
    if (challengeId) {
      return { title: "global.edit_challenge", path: "" };
    } else {
      return {
        title: "organization.new_challenge",
        path: "",
      };
    }
  };
  const getBreadCrumbPath = () => {
    if (pathname.includes("explore")) {
      return [
        {
          title: "home.challenges",
          path: "/organization/challenges",
        },
        {
          title: "organization.explore_challenge",
          path: "/organization/challenges",
        },
        getChallengeBreadCrumb(),
      ];
    } else if (
      challengeId &&
      challengeInfo?.challengeStatusId !== CHALLENGE_STATUS.Draft &&
      !CREATE_CHALLENGE_PATH_PATTERN.test(window.location.pathname)
    ) {
      return [
        {
          title: "admin.challenges",
          path: "/organization/challenges",
        },
        {
          title: "organization.view_challenge",
        },
        getChallengeBreadCrumb(),
      ];
    } else {
      return [
        {
          title: "admin.challenges",
          path: "/organization/challenges",
        },
        getChallengeBreadCrumb(),
      ];
    }
  };

  const linkList: Array<LinkItem> = getBreadCrumbPath();

  const handleSaveDraft = async () => {
    let result = false;
    if (stepNumber === 1) {
      result = await handleSaveChallenge();
    } else if (stepNumber === 2) {
      result = await handleSaveChallengeMileStone();
    } else if (stepNumber === 3) {
      result = await handleSaveLearnMore();
    } else if (stepNumber === 4) {
      result = await handleSaveFunding();
    } else if (stepNumber === 5) {
      result = await handleAdditionalInfo();
    } else if (stepNumber === 6) {
      result = await handleSaveContacts();
    } else if (stepNumber === 7) {
      result = await handleSaveTags();
    }
    return result;
  };
  const moveToNext = () => {
    if (stepNumber >= 0 && stepNumber <= 7) {
      const currentStep = stepNumber;
      const nextStep = currentStep + 1;
      setStepNumber(nextStep);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const handleSaveNext = async () => {
    removeValidationErrors();
    if (stepNumber === 1) {
      handleSaveChallenge(true);
    } else if (stepNumber === 2) {
      handleSaveChallengeMileStone(true);
    } else if (stepNumber === 3) {
      handleSaveLearnMore(true);
    } else if (stepNumber === 4) {
      handleSaveFunding(true);
    } else if (stepNumber === 5) {
      handleAdditionalInfo(true);
    } else if (stepNumber === 6) {
      handleSaveContacts(true);
    } else if (stepNumber === 7) {
      handleSaveTags(true);
    }
  };

  const handleSaveChallenge = async (isNext = false) => {
    const challengeDetails = useChallengeStore.getState().challengeInfo;
    if (challengeDetails) {
      const error = challengeDetailsValidation(challengeDetails);
      if (error) {
        setValidationErrors(error);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return false;
      }
      setValidationErrors(null);
    }
    try {
      const isSaveRequired = checkUnsavedChallengeDetails(challengeInfo, challengeDetails);
      if (isSaveRequired) {
        if (challengeInfo && challengeInfo.id) {
          const challengeData = { ...challengeDetails, id: challengeInfo.id };
          const challengeFormData: ChallengeSave = prepareFormData(challengeData);
          const response = await updateChallenge(challengeFormData);
          if (response) {
            showInfoToastMessage({
              iconType: ToasterIconType.UPDATE,
              message: response.message,
            });
          }
        } else {
          const challengeFormData: ChallengeSave = prepareFormData(challengeDetails);
          const response = await saveChallengeInfo(challengeFormData);
          if (response) {
            const challengeInfo = response.data.challengeId;
            if (isDraftChallenge) {
              setChallengeInfo(challengeInfo, "id");
              updateCompletionStatus(CREATE_CHALLENGE_COMPLETION_STATUS.About);
            }
            showInfoToastMessage({
              iconType: ToasterIconType.UPDATE,
              message: response.message,
            });
          }
        }
      }
      if (isNext) {
        moveToNext();
      }
      return true;
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
      return false;
    }
  };

  const handleSaveChallengeMileStone = async (isNext = false) => {
    const challengeMilestones = useChallengeStore.getState().milestones;
    const challengeInfo = useChallengeStore.getState().challengeInfo;
    const errors: Array<Record<string, string> | null> = [];
    challengeMilestones?.map((item: ChallengeMileStone) => {
      const error = challengeMilestonesValidation(item);
      errors.push(error);
    });
    if (errors?.filter((item) => item !== null).length > 0) {
      setValidationErrors(errors);
      showErrorToastMessage({
        message: t("global.error_message"),
      });
      return false;
    }
    setValidationErrors(null);
    try {
      const isSaveRequired = checkUnsavedMilestones(challengeMilestones, milestones);
      const milestonesFormData = prepareMileStoneFormData(challengeMilestones);
      const result = await saveChallengeMilestone({
        mileStoneFormData: milestonesFormData,
        challengeId: challengeInfo.id,
      });
      if (isDraftChallenge) {
        updateCompletionStatus(CREATE_CHALLENGE_COMPLETION_STATUS.Milestones);
      }
      if (isSaveRequired) {
        showInfoToastMessage({
          iconType: ToasterIconType.UPDATE,
          message: result.message,
        });
      }
      if (isNext) {
        moveToNext();
      }
      return true;
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
      return false;
    }
  };
  const handleSaveLearnMore = async (isNext = false) => {
    const learnMore = useChallengeStore.getState().learnMore;
    const challenge = useChallengeStore.getState().challengeInfo;
    const error = challengeLearnMoreValidation(learnMore);
    if (error) {
      setValidationErrors(error);
      showErrorToastMessage({
        message: t("global.error_message"),
      });
      return false;
    }
    setValidationErrors(null);
    try {
      const isSaveRequired = checkUnsavedLearnMoreDetails(learnMore, learnMoreData);

      if (isSaveRequired) {
        const formData = prepareLearnMoreFormData(learnMore);
        const result = await saveChallengeLearnMore({
          formData: formData,
          challengeId: challenge.id,
        });
        if (isDraftChallenge) {
          updateCompletionStatus(CREATE_CHALLENGE_COMPLETION_STATUS.LearnMore);
        }
        showInfoToastMessage({
          iconType: ToasterIconType.UPDATE,
          message: result.message,
        });
      }
      if (isNext) {
        moveToNext();
      }
      return true;
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
      return false;
    }
  };
  const handleSaveFunding = async (isNext = false) => {
    const funding = useChallengeStore.getState().funding;
    const challenge = useChallengeStore.getState().challengeInfo;
    if (!isValidDataEntered(fundingData) && funding === undefined) {
      const formData = prepareFundingFormData(funding);
      await saveChallengeFunding({
        formData: formData,
        challengeId: challenge.id,
      });
      if (isNext) {
        updateCompletionStatus(CREATE_CHALLENGE_COMPLETION_STATUS.Funding);
        moveToNext();
      }
      return true;
    }
    const validation = challengeEditorValidation(funding);
    if (!validation) {
      setValidationErrors({ value: "required" });
      showErrorToastMessage({
        message: t("global.error_message"),
      });
      return false;
    }
    setValidationErrors(null);
    try {
      const isSaveRequired = checkUnsavedTextContent(funding, fundingData);
      if (isSaveRequired) {
        const formData = prepareFundingFormData(funding);
        const result = await saveChallengeFunding({
          formData: formData,
          challengeId: challenge.id,
        });
        if (isDraftChallenge) {
          updateCompletionStatus(CREATE_CHALLENGE_COMPLETION_STATUS.Funding);
        }
        showInfoToastMessage({
          iconType: ToasterIconType.UPDATE,
          message: result.message,
        });
      }
      if (isNext) {
        moveToNext();
      }
      return true;
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
      return false;
    }
  };
  const handleAdditionalInfo = async (isNext = false) => {
    const additionalInfo = useChallengeStore.getState().additionalInfo;
    const challenge = useChallengeStore.getState().challengeInfo;
    const validation = challengeEditorValidation(additionalInfo.information);
    if (!validation) {
      setValidationErrors({ value: "required" });
      showErrorToastMessage({
        message: t("global.error_message"),
      });
      return false;
    }
    setValidationErrors(null);
    try {
      const isSaveRequired = checkUnsavedAdditionalInfo(additionalInfo, artifactsData);
      if (isSaveRequired) {
        const formData = prepareAdditionalInfoFormData(additionalInfo);
        const result = await saveChallengeArtifacts({
          formData: formData,
          challengeId: challenge.id,
        });
        if (isDraftChallenge) {
          updateCompletionStatus(CREATE_CHALLENGE_COMPLETION_STATUS.Artifacts);
        }
        showInfoToastMessage({
          iconType: ToasterIconType.UPDATE,
          message: result.message,
        });
      }
      if (isNext) {
        moveToNext();
      }
      return true;
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
      return false;
    }
  };

  const handleSaveContacts = async (isNext = false) => {
    const TOASTER_DURATION = 1700;
    const contactsData = useChallengeStore.getState().contacts;
    const challenge = useChallengeStore.getState().challengeInfo;
    const errors: Array<Record<string, string> | null> = [];
    contactsData?.map((item: ChallengeContact) => {
      const result = challengeContactValidation(item);
      errors.push(result);
    });
    if (errors?.filter((item) => item !== null).length > 0) {
      setValidationErrors(errors);
      showErrorToastMessage({
        message: t("global.error_message"),
      });
      return false;
    }
    setValidationErrors(null);

    try {
      const isSaveRequired = checkUnsavedContacts(contactsData, contactsDetails);
      if (isSaveRequired) {
        const contactFormData = prepareContactFormData(contactsData);
        const result = await saveChallengeContacts({
          formData: contactFormData,
          challengeId: challenge.id,
        });
        if (isDraftChallenge) {
          updateCompletionStatus(CREATE_CHALLENGE_COMPLETION_STATUS.Contacts);
        }
        showInfoToastMessage({
          iconType: ToasterIconType.UPDATE,
          message: result.message,
          duration: TOASTER_DURATION,
        });
      }
      if (isNext) {
        moveToNext();
      }
      return true;
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
      return false;
    }
  };

  const handleSaveTags = async (isNext = false) => {
    const challenge = useChallengeStore.getState().challengeInfo;

    const tags = useChallengeStore.getState().challengeTags;
    try {
      const isSaveRequired = checkUnsavedTags(tags, tagsData);
      const tagsFormData = prepareContactTagsFormData(tags);
      const PREVIEW_DELAY_DURATION = isSaveRequired ? TOASTER_DURATION : 500;
      const result = await saveChallengeTags({
        formData: tagsFormData,
        challengeId: challenge.id,
      });
      if (isDraftChallenge) {
        updateCompletionStatus(CREATE_CHALLENGE_COMPLETION_STATUS.Tags);
      }
      if (result && isSaveRequired) {
        showInfoToastMessage({
          iconType: ToasterIconType.UPDATE,
          message: result.message,
          duration: TOASTER_DURATION,
        });
      }

      setTimeout(() => {
        displayChallengePreviewModal(isNext);
      }, PREVIEW_DELAY_DURATION);

      return true;
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
      return false;
    }
  };
  const removeValidationErrors = () => {
    setValidationErrors(null);
  };

  const handleNavigationClick = async (value: number) => {
    if (stepNumber === value) return;
    let isSaveRequired = false;
    switch (stepNumber) {
      case 1: {
        const challengeDetails = useChallengeStore.getState().challengeInfo;
        isSaveRequired = checkUnsavedChallengeDetails(challengeInfo, challengeDetails);
        break;
      }
      case 2: {
        const challengeMilestones = useChallengeStore.getState().milestones;
        isSaveRequired = isUnSavedMileStoneExist(challengeMilestones, milestones);
        break;
      }
      case 3: {
        const learnMore = useChallengeStore.getState().learnMore;
        isSaveRequired = checkUnsavedLearnMoreDetails(learnMore, learnMoreData);
        break;
      }
      case 4: {
        const funding = useChallengeStore.getState().funding;
        isSaveRequired = isUnsavedTextContent(
          funding,
          fundingData,
          CREATE_CHALLENGE_COMPLETION_STATUS.Funding
        );
        break;
      }
      case 5: {
        const additionalInfo = useChallengeStore.getState().additionalInfo;
        isSaveRequired = isUnsavedAdditionalInfoExist(additionalInfo, artifactsData);
        break;
      }
      case 6: {
        const contactsData = useChallengeStore.getState().contacts;
        isSaveRequired = isUnSavedContactsExist(contactsData, contactsDetails);
        break;
      }
      case 7: {
        const tags = useChallengeStore.getState().challengeTags;
        isSaveRequired = checkUnsavedTags(tags, tagsData) ?? false;
        break;
      }
    }
    if (isSaveRequired) {
      const confirm = await showConfirmNavigationModal();
      if (confirm) {
        removeValidationErrors();
        setStepNumber(value);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } else {
      removeValidationErrors();
      setStepNumber(value);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handlePreview = async () => {
    const challenge = useChallengeStore.getState().challengeInfo;
    await showChallengePreviewModal({
      challengeId: challenge?.id,
      setNavigation: () => setStepNumber(CREATE_CHALLENGE_COMPLETION_STATUS.About),
      showPrimaryButton: false,
    });
  };

  const updateCompletionStatus = (completionStatus: number) => {
    if (completionStatusId < completionStatus) {
      setCompletionStatusId(completionStatus);
    }
  };

  const showConfirmNavigationModal = async () => {
    const result = await showConfirmationModal({
      title: "organization.confirm_proceed",
      message: "organization.challenge_unsaved_section_confirm",
      cancelButtonText: "global.close",
      proceedButtonText: "global.proceed",
      modalIcon: "confirmSelection",
      isAlert: true,
    });
    return result;
  };

  const displayChallengePreviewModal = (isNext: boolean) => {
    const challenge = useChallengeStore.getState().challengeInfo;
    if (isNext && challenge?.id) {
      const showPrimaryButton =
        challenge.challengeStatusId !== CHALLENGE_STATUS.Open &&
        challenge.challengeStatusId !== CHALLENGE_STATUS.Live;
      showChallengePreviewModal({
        challengeId: challenge?.id,
        setNavigation: () => navigate("/organization/challenges", { replace: true }),
        showPrimaryButton: showPrimaryButton,
      });
    }
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "admin.challenges",
    });
  }, []);

  return (
    <div className="w-full h-full flex">
      <Header
        linkList={linkList}
        title={challengeTitle}
        handleCollapseClick={onToggleClick}
        className={
          scroll
            ? " bg-white animate__animated animate__fadeInDown shadow-sticky !py-[26px] !h-[90px] fixed stickyHeader"
            : "fixed"
        }
      />
      <div className=" adminDetails w-full mt-[170px] mb-20 lg:mb-0 lg:mt-0 lg:pt-[118px] pb-[45px] items-baseline px-4 lg:px-10 lg:min-h-screen flex flex-col  overflow-auto ltr:pr-[30px] rtl:pl-[30px]">
        <div className="w-full flex flex-col h-full ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 ltr:lg:pr-[292px] rtl:lg:pl-[292px] space-y-3">
          <div className=" flex-col bg-white w-full h-full rounded-none lg:rounded-md flex mb-auto">
            {stepNumber === 1 ? (
              <About validationErrors={validationErrors as Record<string, string> | null} />
            ) : null}
            {stepNumber === 2 ? (
              <Milestones
                validationErrors={validationErrors as Array<Record<string, string>> | null}
                isDisabled={challengeInfo?.challengeStatusId === CHALLENGE_STATUS.Pending}
              />
            ) : null}
            {stepNumber === 3 ? (
              <LearnMore validationErrors={validationErrors as Record<string, string> | null} />
            ) : null}
            {stepNumber === 4 ? (
              <Funding
                validationErrors={validationErrors as Record<string, string> | null}
                isDisabled={challengeInfo?.challengeStatusId === CHALLENGE_STATUS.Pending}
              />
            ) : null}
            {stepNumber === 5 ? (
              <Artifacts validationErrors={validationErrors as Record<string, string> | null} />
            ) : null}
            {stepNumber === 6 ? (
              <Contacts
                validationErrors={validationErrors as Array<Record<string, string>> | null}
              />
            ) : null}
            {stepNumber === 7 ? <Tags /> : null}
          </div>
        </div>
        <div
          className={
            scroll
              ? "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto fixed top-[114px] lg:top-[100px]  ltr:lg:right-[30px] rtl:lg:left-[30px]  z-[5]  lg:shadow-sticky  animate__animated animate__fadeInDown animateNone lg:transition-all"
              : "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto fixed top-[114px] lg:top-auto ltr:lg:right-[30px] rtl:left-0 rtl:lg:left-[30px]  z-[5]"
          }
        >
          <Navigator
            handleDraft={handleSaveDraft}
            handleNext={handleSaveNext}
            handleNavigationClick={handleNavigationClick}
            handlePreview={handlePreview}
            navigationItems={navigationItems}
            completionStatusId={completionStatusId}
            stepNumber={stepNumber}
            isEdit={isEdit}
          />
        </div>
        {isChallengeInfoLoading ||
        isChallengeMilestoneLoading ||
        isChallengeFundingLoading ||
        isChallengeLearnMoreLoading ||
        isArtifactsLoading ||
        isContactsLoading ||
        isChallengeSaving ||
        isLearnMoreSaving ||
        isFundingSaving ||
        isArtifactsSaving ||
        isContactsSaving ||
        isTagsSaving ||
        isMilestoneSaving ||
        isTagsLoading ||
        isUpdatingChallenge ? (
          <LoaderModal />
        ) : null}
      </div>
    </div>
  );
}
