import { useTranslation } from "react-i18next";
import { Label, Title, InlineLoader } from "components";
import profilePicture from "assets/images/avatar.svg";
import { getTimeDifference } from "utils/helpers";
import { useGetMessageThreads } from "features/Messages/api/useGetMessageThreads";
import NoData from "features/Messages/NoData";

type MessageBarProps = {
  handleItemClick: (threadId: string, userAccessToken: string) => void;
  selectedChatTreadId: string;
};

export default function MessageBar({ handleItemClick, selectedChatTreadId }: MessageBarProps) {
  const { data, isLoading } = useGetMessageThreads();
  return (
    <div className="w-full flex flex-col bg-white">
      <div className=" adminDetails w-full items-baseline  flex flex-col  overflow-auto ">
        <div className="w-full flex flex-col h-full space-y-3 bg-white rounded-[4px]">
          <div className=" overflow-auto !mt-0 md:block flex justify-center items-center flex-col px-5 pt-5 msgListing">
            {data && data?.chatThreads?.length > 0 ? (
              data?.chatThreads?.map((item: MessageThread) => (
                <MessageBarItem
                  key={item.threadId}
                  data={item}
                  handleItemClick={(threadId: string, userAccessToken: string) =>
                    handleItemClick(threadId, userAccessToken)
                  }
                  selectedChatTreadId={selectedChatTreadId}
                  userAccessToken={data?.userAccessToken}
                />
              ))
            ) : (
              <NoData message="forum.no_messages" />
            )}
          </div>
        </div>
      </div>
      {isLoading ? <InlineLoader /> : null}
    </div>
  );
}

type MessageBarItemProps = {
  data: MessageThread;
  handleItemClick: (threadId: string, userAccessToken: string) => void;
  selectedChatTreadId: string;
  userAccessToken: string;
};

function MessageBarItem({
  data,
  handleItemClick,
  selectedChatTreadId,
  userAccessToken,
}: MessageBarItemProps) {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`p-2.5 cursor-pointer w-full ${selectedChatTreadId === data?.threadId ? " activeMsg" : ""
        }`}
      onClick={() => handleItemClick(data.threadId, userAccessToken)}
    >
      <div className="flex gap-2.5 items-start">
        <img
          alt="profile picture"
          className="h-10 w-10 min-w-[40px] rounded-full object-cover "
          src={data?.chatUserAvatar ? data?.chatUserAvatar : profilePicture}
        />
        <div className=" truncate block ">
          <div className="flex flex-col truncate overflow-hidden">
            <Title title={data?.chatUserName} className="text-sm text-black truncate font-normal dirRTL_LTR" />
            <Label
              text={data?.lastMessage}
              className="font-semibold text-black leading-5 my-[2px] truncate !overflow-hidden !inline dirRTL_LTR"
            />
            <Label
              text={getTimeDifference(data?.lastMessageReceivedOn, i18n.language, t)}
              className="font-normal text-sm text-gray-102 hidden md:block"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
