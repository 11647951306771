import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function useResetQueryOnLanguageSwitch(queryKey: string) {
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();

  useEffect(() => {
    function resetPage() {
      queryClient.resetQueries([queryKey]);
    }

    const languageChangeListener = () => {
      resetPage();
    };

    i18n.on("languageChanged", languageChangeListener);

    return () => {
      i18n.off("languageChanged", languageChangeListener);
    };
  }, [i18n, queryClient, queryKey]);

  return {};
}

export default useResetQueryOnLanguageSwitch;
