import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { DatePickerCalender, PrimaryButton, SecondaryButton } from "components";
import { formatToISODate } from "utils/helpers";

type FilterCalenderProps = {
  filterParams: AdminFilterParams;
  item: string;
  handleSubmit: (item: string) => void;
  setFilterParamsValue: (data: FilterParams) => void;
  isFilterActive: boolean;
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
};

export function FilterCalender({
  filterParams,
  item,
  handleSubmit,
  setFilterParamsValue,
  isFilterActive,
  isOpen,
  setIsOpen,
}: FilterCalenderProps) {
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState<Date | null>(
    filterParams?.fromDate ? new Date(filterParams.fromDate) : null
  );
  const [toDate, setToDate] = useState<Date | null>(
    filterParams?.toDate ? new Date(filterParams.toDate) : null
  );

  useEffect(() => {
    setFromDate(filterParams?.fromDate ? new Date(filterParams.fromDate) : null);
    setToDate(filterParams?.toDate ? new Date(filterParams.toDate) : null);
  }, [filterParams]);

  const setFromDateValue = (value: Date | null) => {
    setFromDate(value);
  };
  const setToDateValue = (value: Date | null) => {
    setToDate(value);
  };

  const handleFilterClick = () => {
    setIsOpen(!isOpen);
  };

  const setDateParams = (startDate: Date | null, endDate: Date | null) => {
    setFilterParamsValue({
      fromDate: startDate ? formatToISODate(startDate?.toUTCString()) : null,
      toDate: endDate ? formatToISODate(endDate?.toUTCString()) : null,
    });
  };

  const handleResetCalender = () => {
    setFromDate(null);
    setToDate(null);
    setFilterParamsValue({
      toDate: null,
      fromDate: null,
    });
  };

  const handleDateChange = (item: string) => {
    handleSubmit(item);
    setIsOpen(false);
  };

  return (
    <div className="dropdown">
      <div
        onClick={handleFilterClick}
        className={`flex items-center cursor-pointer pt-1.5 -mb-1.5 ${
          isOpen ? "filterActive" : ""
        }`}
      >
        <SVGIcon name={isFilterActive ? "filterActive" : "filterIconV1"} />
        <span className=" pl-1">{t(item)}</span>
      </div>
      <div className="absolute z-[50] bg-white rounded-md shadow-700 top-10 -left-[22px] p-[18px] tableFilterDropdown">
        <DatePickerCalender
          endDate={toDate}
          setEndDate={setToDateValue}
          startDate={fromDate}
          setStartDate={setFromDateValue}
          handleDateChange={setDateParams}
        />
        <div className=" flex justify-center space-x-2.5 rtl:space-reverse  pt-[18px]">
          <SecondaryButton
            title={"global.close"}
            handleClick={handleFilterClick}
            className=" flex-1  !min-h-[38px] !h-[38px]"
          />
          <SecondaryButton
            title={"global.clear"}
            handleClick={handleResetCalender}
            className=" flex-1  !min-h-[38px] !h-[38px]"
          />
          <PrimaryButton
            title={"global.apply"}
            handleClick={() => {
              handleDateChange(item);
            }}
            className=" flex-1  !min-h-[38px] !h-[38px]"
          />
        </div>
      </div>
    </div>
  );
}
