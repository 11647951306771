import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  InputField,
  PrimaryButton,
  SingleSelect,
  SubTitle,
  Title,
  SecondaryButton,
  LoaderModal,
} from "components";
import { useMasterDataStore } from "store/useMasterDataStore";
import { useGetOrganizationTypes } from "features/Admin/api/useGetOrganizationTypes";
import { fundingOrganizationInviteValidation } from "utils/validation/fundingOrganizationInviteValidation";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import { useInviteFundingOrganization } from "features/Admin/api/useInviteFundingOrganization";
import SVGIcon from "assets/Icons";
import { EMAIL_MAX_CHAR_LENGTH, ORGANIZATION_TYPES } from "utils/constants";
import { setMasterDataValue } from "utils/helpers";

type FundingOrganizationIndividualInviteProps = {
  onClose: () => void;
};

export default function FundingOrganizationIndividualInvite({
  onClose,
}: FundingOrganizationIndividualInviteProps) {
  const { t, i18n } = useTranslation();
  const { countryCode } = useMasterDataStore();
  const { mutateAsync: inviteFundingOrganization, isLoading: isSaving } =
    useInviteFundingOrganization();
  const initialState: FundingOrganizationBasicFields = {
    organizationName: "",
    organizationType: 0,
    email: "",
    contactNumber: "",
    countryCodeId: 0,
  };
  const [formState, setFormState] = useState<FundingOrganizationBasicFields>(initialState);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const { data: organizationTypes, isLoading: isOrganizationTypesLoading } =
    useGetOrganizationTypes();

  const countryCodeOptions = useMemo(() => {
    return countryCode?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [countryCode, i18n.language]);

  const organizationTypeOptions = useMemo(() => {
    return organizationTypes
      ?.filter((item) => item?.id !== ORGANIZATION_TYPES.StartUp)
      .map((item) => {
        return setMasterDataValue(item, i18n.language);
      });
  }, [organizationTypes, i18n.language]);

  const handleSelectChange = (
    value: string | number,
    key: keyof FundingOrganizationBasicFields
  ) => {
    setFormState((previousState) => ({ ...previousState, [key]: value as string | number }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof FundingOrganizationBasicFields
  ) => {
    const value = event?.target?.value;
    setFormState((previousState) => ({ ...previousState, [key]: value }));
  };
  const handleInvite = async () => {
    const error = fundingOrganizationInviteValidation(formState);
    if (error) {
      setValidationErrors(error);
      return;
    }
    setValidationErrors(null);
    try {
      const formData = prepareFormData();
      const result = await inviteFundingOrganization(formData);
      showInfoToastMessage({
        iconType: ToasterIconType.MAIL,
        message: result?.message,
      });
      onClose();
    } catch (error: ApiError) {
      showErrorToastMessage({
        iconType: ToasterIconType.ALERT,
        message:
          error.response.data.status >= 400
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };
  const prepareFormData = () => {
    const data = {
      organizationName: formState.organizationName,
      email: formState.email.trim(),
      contactNumber: formState.contactNumber.trim(),
      countryCodeId: formState.countryCodeId,
      organizationType: formState.organizationType,
    };
    return data;
  };
  return (
    <div className="fixed h-screen w-screen bg-black/[0.3] z-[99] flex justify-center items-center top-0 left-0 ">
      {" "}
      <div className="animate__animated animate__zoomIn flex flex-col rounded-md items-center bg-white max-w-[470px] mx-2 sm:w-[470px] py-[50px] px-8 max-h-[calc(100vh-3.125rem)] overflow-auto">
        <div>
          <SVGIcon name="individualInvite" />
        </div>
        <Title
          className="mt-10 text-black text-xl font-semibold"
          title={t("admin.individual_invite")}
        />
        <SubTitle
          className="mt-[6px] text-primary-text font-normal text-base text-center"
          title={t("admin.funding_organization_invite_message")}
        />

        <div className="grid grid-cols-1 gap-x-4 gap-y-[22px] mt-[30px] mb-12 w-full">
          <InputField
            hintText={t("organization.enter_organization_name")}
            labelText={t("organization.organization_name")}
            handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(event, "organizationName")
            }
            isRequired
            fieldName="organizationName"
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
            errorMessage={validationErrors?.organizationName}
          />
          <SingleSelect
            options={organizationTypeOptions || []}
            labelText={t("organization.organization_type")}
            hintText={t("global.select")}
            handleChange={(value: string | number) => handleSelectChange(value, "organizationType")}
            errorMessage={validationErrors?.organizationType}
            isRequired
          />

          <InputField
            hintText={t("global.enter_email_address")}
            labelText={t("global.email_address")}
            handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(event, "email")
            }
            fieldName="email"
            isRequired
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
            errorMessage={validationErrors?.email}
          />

          <SingleSelect
            className="text-sm font-normal"
            options={countryCodeOptions || []}
            labelText={t("global.country_code")}
            hintText={t("global.select")}
            handleChange={(value: string | number) => handleSelectChange(value, "countryCodeId")}
            isRequired
            errorMessage={validationErrors?.countryCodeId}
          />

          <InputField
            hintText={t("global.enter_contact_number")}
            labelText={t("global.contact_number")}
            handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(event, "contactNumber")
            }
            fieldName="contactNumber"
            errorMessage={validationErrors?.contactNumber}
            isRequired
          />
        </div>

        <div className=" flex w-full justify-center space-x-[10px] rtl:space-x-reverse ">
          <SecondaryButton
            title={"global.close"}
            handleClick={onClose}
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
          />

          <PrimaryButton
            title={"global.send_invite"}
            handleClick={handleInvite}
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
          />
        </div>
      </div>
      {isOrganizationTypesLoading || isSaving ? <LoaderModal /> : null}
    </div>
  );
}
