import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import {
  InputField,
  SingleSelect,
  Chip,
  Title,
  TextButton,
  TextArea,
  ProfilePicture,
  MultiSelect,
  LoaderModal,
} from "components";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { useGetOrganizationTypes } from "features/Admin/api/useGetOrganizationTypes";
import { useGetFundingOrganizationDetails } from "features/FundingOrganization/api/useGetFundingOrganizationDetails";
import {
  EMAIL_MAX_CHAR_LENGTH,
  ORGANIZATION_TYPES,
  PROFILE_DESCRIPTION_MAX_CHAR_LENGTH,
  URL_MAX_LENGTH,
  USER_STATUS,
  INITIAL_SEARCH_PARAMS,
  PAGE_LIMIT,
} from "utils/constants";
import { showProfilePicUploadModal } from "utils/confirmationModals";
import { phoneMaxLimitChecker, setMasterDataValue } from "utils/helpers";
import {
  setFundingOrganizationInfo,
  setFundingOrganizationInfoItem,
  useFundingOrganizationDataStore,
} from "store/useFundingOrganizationDataStore";
import { useMasterDataStore } from "store/useMasterDataStore";

type FundingOrganizationDetailsProps = {
  validationErrors: Record<string, string> | null;
};

export default function Details({ validationErrors }: FundingOrganizationDetailsProps) {
  const { t, i18n } = useTranslation();
  const { fundingOrganizationId } = useParams();
  const { fundingOrganizationInfo } = useFundingOrganizationDataStore();
  const { countryCode, masterData } = useMasterDataStore();

  const { data: organizationTypes, isLoading: isOrganizationTypesLoading } =
    useGetOrganizationTypes();
  const { data: fundingOrganizationData, isLoading: isFundingOrganizationDataLoading } =
    useGetFundingOrganizationDetails(fundingOrganizationId ?? "");

  const isReadOnly = fundingOrganizationData?.profileStatusId === USER_STATUS.PendingApproval;

  const handleRemove = async () => {
    if (fundingOrganizationInfo && fundingOrganizationInfo?.profileActualImage) {
      setFundingOrganizationInfoItem(null, "profileActualImage");
    }
  };

  const handleProfileClick = async () => {
    await showProfilePicUploadModal({
      setProfileUrl: (url: string) => setFundingOrganizationInfoItem(url, "profileActualImage"),
      url: fundingOrganizationInfo?.profileActualImage ?? "",
      handleRemove,
    });
  };

  const handleInputChange =
    (key: keyof FundingOrganizationDetails) =>
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event?.target?.value;
      setFundingOrganizationInfoItem(value, key);
    };

  const handleSelect = (key: keyof FundingOrganizationDetails) => (value: string | number) => {
    setFundingOrganizationInfoItem(value, key);
  };

  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);

  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  const fundingOrganizationTypeOptions = useMemo(() => {
    return organizationTypes
      ?.filter((item) => item?.id !== ORGANIZATION_TYPES.StartUp)
      .map((item) => {
        return setMasterDataValue(item, i18n.language);
      });
  }, [organizationTypes, i18n.language]);

  const countryOptions = useMemo(() => {
    return masterData?.country?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [masterData, i18n.language]);

  const countryCodeOptions = useMemo(() => {
    return countryCode?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [countryCode, i18n.language]);

  const handleRemoveResearchArea = (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    if (fundingOrganizationInfo?.researchArea) {
      const tempOptions = fundingOrganizationInfo?.researchArea
        ? structuredClone(fundingOrganizationInfo?.researchArea)
        : [];
      tempOptions.splice(index, 1);
      setFundingOrganizationInfoItem(tempOptions, "researchArea");
      searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
    }
  };

  useEffect(() => {
    searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
  }, []);

  useEffect(() => {
    if (fundingOrganizationData) {
      setFundingOrganizationInfo(fundingOrganizationData);
    }
  }, [fundingOrganizationData]);
  
  return (
    <div>
      <div className=" p-4 lg:p-5 flex items-center border-b border-gray-light">
        <SVGIcon name="personalDetails" />
        <Title
          className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
          title={t("funding_organization.funding_organization_details")}
        />
      </div>
      <div className="space-y-6 px-5 pt-6 pb-8 ">
        <div className=" flex flex-col bg-gray-light/60 py-[30px] justify-center rounded items-center mb-[34px]">
          <ProfilePicture
            url={fundingOrganizationInfo?.profileActualImage ?? ""}
            handleClick={handleProfileClick}
            disabled={isReadOnly}
          />
          <TextButton
            title={
              fundingOrganizationInfo?.profileActualImage
                ? "global.change_image"
                : "global.upload_image"
            }
            className="text-primary-medium mt-6 text-sm"
            handleClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleProfileClick();
            }}
            isDisabled={isReadOnly}
          />
        </div>
        <InputField
          value={fundingOrganizationInfo?.organizationName}
          hintText={t("organization.enter_organization_name")}
          labelText={t("organization.organization_name")}
          handleInputChange={handleInputChange("organizationName")}
          fieldName="organizationName"
          isRequired
          errorMessage={validationErrors?.organizationName}
          isDisabled={isReadOnly}
          maxCharLength={EMAIL_MAX_CHAR_LENGTH}
        />
        <TextArea
          textContent={fundingOrganizationInfo?.about}
          handleChange={handleInputChange("about")}
          labelText={t("global.about")}
          hintText={t("global.tell_us_more_about_you")}
          fieldName="about"
          errorMessage={validationErrors?.about}
          isDisabled={isReadOnly}
          maxCharLength={PROFILE_DESCRIPTION_MAX_CHAR_LENGTH}
        />
        <MultiSelect
          labelText={t("global.research_area")}
          hintText={t("global.search")}
          setSelectedOptions={(options: Array<OptionItem> | null) => {
            setFundingOrganizationInfoItem(options, "researchArea");
            searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
          }}
          selectedOptions={fundingOrganizationInfo?.researchArea || []}
          addNew
          loadOptions={loadResearchAreaOptions}
          errorToastMessage={"global.create_research_area_min_error_message"}
          createLabel={"research_scholar.new_research_area"}
          resetKey={Math.random().toString()}
          isDisabled={isReadOnly}
          onMenuOpen={() => {
            searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
          }}
        />
        <div>
          {fundingOrganizationInfo &&
          fundingOrganizationInfo?.researchArea &&
          fundingOrganizationInfo?.researchArea?.length > 0 ? (
            <div className=" flex  flex-wrap">
              {fundingOrganizationInfo?.researchArea.map((item: OptionItem, i: number) => (
                <Chip
                  key={item.id ? item.id + "" : i + ""}
                  text={i18n.language === "ar" ? item.name.ar : item.name.en}
                  handleClose={(event: React.MouseEvent<HTMLElement>) =>
                    handleRemoveResearchArea(event, i)
                  }
                  disabled={isReadOnly}
                />
              ))}
            </div>
          ) : null}
        </div>
        <SingleSelect
          selectOption={fundingOrganizationInfo?.organizationTypeId}
          options={fundingOrganizationTypeOptions || []}
          labelText={t("organization.organization_type")}
          hintText={t("global.search")}
          handleChange={handleSelect("organizationTypeId")}
          isRequired
          isDisabled={isReadOnly}
          errorMessage={validationErrors?.organizationTypeId}
        />
        <div className=" flex space-x-3 rtl:space-x-reverse">
          <SingleSelect
            selectOption={fundingOrganizationInfo?.countryId}
            options={countryOptions || []}
            labelText={t("global.country")}
            hintText={t("global.select")}
            handleChange={handleSelect("countryId")}
            isRequired
            className=" w-full"
            isDisabled={isReadOnly}
            errorMessage={validationErrors?.countryId}
          />
          <InputField
            value={fundingOrganizationInfo?.email}
            hintText={t("global.enter_email_address")}
            labelText={t("organization.contact_email")}
            handleInputChange={handleInputChange("email")}
            fieldName="email"
            isRequired
            isDisabled
            errorMessage={validationErrors?.email}
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
          />
        </div>
        <div className=" flex space-x-3 rtl:space-x-reverse">
          <SingleSelect
            selectOption={fundingOrganizationInfo?.countryCodeId}
            options={countryCodeOptions || []}
            labelText={t("global.country_code")}
            hintText={t("global.select")}
            handleChange={handleSelect("countryCodeId")}
            isRequired
            className=" w-full"
            isDisabled={isReadOnly}
            errorMessage={validationErrors?.countryCodeId}
          />
          <InputField
            value={fundingOrganizationInfo?.contactNumber}
            hintText={t("global.enter_contact_number")}
            labelText={t("global.contact_number")}
            handleInputChange={handleInputChange("contactNumber")}
            fieldName="contactNumber"
            isRequired
            isDisabled={isReadOnly}
            errorMessage={validationErrors?.contactNumber}
            maxCharLength={phoneMaxLimitChecker(fundingOrganizationInfo?.contactNumber ?? "")}
          />
        </div>
        <div className=" flex space-x-3 rtl:space-x-reverse">
          <InputField
            value={fundingOrganizationInfo?.linkedInUrl}
            hintText={t("research_scholar.enter_linkedIn_URL")}
            labelText={t("research_scholar.linked_in_url")}
            handleInputChange={handleInputChange("linkedInUrl")}
            fieldName="linkedInUrl"
            isDisabled={isReadOnly}
            errorMessage={validationErrors?.linkedInUrl}
            maxCharLength={URL_MAX_LENGTH}
            tooltipContent={[t("global.website_url_tooltip_message")]}
          />
          <InputField
            value={fundingOrganizationInfo?.websiteUrl}
            hintText={t("global.enter_website_url")}
            labelText={t("global.website_url")}
            handleInputChange={handleInputChange("websiteUrl")}
            fieldName="websiteUrl"
            isDisabled={isReadOnly}
            errorMessage={validationErrors?.websiteUrl}
            maxCharLength={URL_MAX_LENGTH}
            tooltipContent={[t("global.website_url_tooltip_message")]}
          />
        </div>
      </div>
      {isOrganizationTypesLoading || isFundingOrganizationDataLoading ? <LoaderModal /> : null}
    </div>
  );
}
