import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import {
  ButtonWithSubtitle,
  Title,
  SecondaryButton,
  RichTextEditor,
  LoaderModal,
} from "components";
import { DEFAULT_EDITOR_EMPTY_STATE } from "utils/constants";
import { setChallengeFunding, useChallengeStore } from "store/useChallengeStore";
import { useGetChallengeFunding } from "features/Organization/api/useGetChallengeFunding";

type FundingProps = {
  validationErrors: Record<string, string> | null;
  isDisabled?: boolean;
};

const Funding = ({ validationErrors, isDisabled }: FundingProps) => {
  const { data: fundingData, isLoading } = useGetChallengeFunding();
  const { funding } = useChallengeStore();
  const { t } = useTranslation();

  const handleResetFunding = () => {
    setChallengeFunding(undefined);
  };

  const handleAddFunding = () => {
    setChallengeFunding(DEFAULT_EDITOR_EMPTY_STATE);
  };

  useEffect(() => {
    if (fundingData) {
      setChallengeFunding(fundingData);
    } else {
      setChallengeFunding(undefined);
    }
  }, [fundingData]);

  return (
    <div>
      <div className=" flex items-center justify-between mb-6 flex-wrap pb-8 p-5 !py-3 border-b border-gray-light">
        <div className="flex items-center ltr:mr-auto rtl:ml-auto">
          <SVGIcon name="fundingV1" />
          <Title
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            title={t("organization.funding")}
          />
        </div>
        {funding !== undefined && (
          <SecondaryButton
            title={"organization.remove_funding"}
            handleClick={handleResetFunding}
            className=" !h-10 !min-h-0 !w-[99px] !min-w-[150px] !text-sm !px-0 font-medium bg-white hover:!text-white flex gap-3"
            disabled={isDisabled}
          />
        )}
      </div>

      {funding === undefined ? (
        <div className="px-5 mt-6 w-full">
          <ButtonWithSubtitle
            title={"organization.click_to_enable_funding"}
            subtitle={"organization.skip_funding"}
            handleClick={handleAddFunding}
            isDisabled={isDisabled}
          />
        </div>
      ) : (
        <div>
          <div
            className={`border border-[#7890A1] rounded-md my-6 mx-5 ${
              validationErrors ? "!border-red-200 " : "border-primary-light"
            }`}
          >
            <RichTextEditor
              key={funding.length !== fundingData?.length ? "reset" : ""}
              text={funding}
              handleTextChange={(text) => {
                setChallengeFunding(text);
              }}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      )}
      {isLoading ? <LoaderModal /> : null}
    </div>
  );
};

export default Funding;
