import { useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SVGIcon from "assets/Icons";
import {
  InputField,
  SingleSelect,
  Title,
  TextButton,
  TextArea,
  RadioButton,
  ProfilePicture,
  MultiSelect,
  LoaderModal,
  Chip,
} from "components";
import {
  EMAIL_MAX_CHAR_LENGTH,
  INITIAL_SEARCH_PARAMS,
  ORGANIZATION_TYPES,
  PAGE_LIMIT,
  PROFILE_DESCRIPTION_MAX_CHAR_LENGTH,
  RESEARCH_INSTITUTE_STATUS,
  URL_MAX_LENGTH,
  USER_STATUS,
} from "utils/constants";
import { showProfilePicUploadModal } from "utils/confirmationModals";
import { phoneMaxLimitChecker, setMasterDataValue } from "utils/helpers";
import {
  setResearchInstituteInfo,
  setResearchInstituteInfoItem,
  useResearchInstituteDataStore,
} from "store/useResearchInstituteDataStore";
import { useMasterDataStore } from "store/useMasterDataStore";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { useGetOrganizationTypes } from "features/Admin/api/useGetOrganizationTypes";
import { useGetResearchInstituteDetails } from "features/ResearchInstitute/api/useGetResearchInstituteDetails";

type ResearchInstituteDetailsProps = {
  validationErrors: Record<string, string> | null;
};

export default function ResearchInstituteDetails({
  validationErrors,
}: ResearchInstituteDetailsProps) {
  const { t, i18n } = useTranslation();
  const { researchInstituteInfo } = useResearchInstituteDataStore();
  const { countryCode, masterData } = useMasterDataStore();
  const { researchInstituteId } = useParams();
  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);

  const { data: researchInstituteTypes, isLoading: isOrganizationTypesLoading } =
    useGetOrganizationTypes();
  const { data: researchInstituteData, isLoading: isResearchInstituteDataLoading } =
    useGetResearchInstituteDetails(researchInstituteId ?? "");

  const isReadOnly = researchInstituteData?.profileStatusId === USER_STATUS.PendingApproval;

  const handleRemove = async () => {
    if (researchInstituteInfo && researchInstituteInfo?.profileActualImage) {
      setResearchInstituteInfoItem(null, "profileActualImage");
    }
  };

  const handleProfileClick = async () => {
    await showProfilePicUploadModal({
      setProfileUrl: (url: string) => setResearchInstituteInfoItem(url, "profileActualImage"),
      url: researchInstituteInfo?.profileActualImage ?? "",
      handleRemove,
    });
  };

  const handleInputChange =
    (key: keyof ResearchInstituteDetails) =>
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event?.target?.value;
      setResearchInstituteInfoItem(value, key);
    };

  const handleSelect = (key: keyof ResearchInstituteDetails) => (value: string | number) => {
    setResearchInstituteInfoItem(value, key);
  };

  const handleStatusTypeChange = (value: number) => {
    setResearchInstituteInfoItem(value, "statusId");
  };

  const countryCodeOptions = useMemo(() => {
    return countryCode?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [countryCode, i18n.language]);

  const researchInstituteTypeOptions = useMemo(() => {
    return researchInstituteTypes
      ?.filter((item) => item?.id !== ORGANIZATION_TYPES.StartUp)
      .map((item) => {
        return setMasterDataValue(item, i18n.language);
      });
  }, [researchInstituteTypes, i18n.language]);

  const countryOptions = useMemo(() => {
    return masterData?.country?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [masterData, i18n.language]);

  const handleRemoveResearchArea = (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    if (researchInstituteInfo?.researchArea) {
      const tempOptions = researchInstituteInfo?.researchArea
        ? structuredClone(researchInstituteInfo?.researchArea)
        : [];
      tempOptions.splice(index, 1);
      setResearchInstituteInfoItem(tempOptions, "researchArea");
      searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
    }
  };

  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  useEffect(() => {
    if (researchInstituteData) {
      setResearchInstituteInfo(researchInstituteData);
    }
  }, [researchInstituteData, i18n.language]);

  useEffect(() => {
    searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
  }, []);

  return (
    <div>
      <div className=" p-4 lg:p-5 flex items-center border-b border-gray-light">
        <SVGIcon name="personalDetails" />
        <Title
          className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
          title={t("research_institute.research_institute_details")}
        />
      </div>
      <div className="space-y-6 px-5 pt-6 pb-8 ">
        <div className=" flex flex-col bg-gray-light/60 py-[30px] justify-center rounded items-center mb-[34px]">
          <ProfilePicture
            url={
              researchInstituteInfo?.profileActualImage
                ? researchInstituteInfo.profileActualImage
                : ""
            }
            handleClick={handleProfileClick}
            disabled={isReadOnly}
          />
          <TextButton
            title={
              researchInstituteInfo?.profileActualImage
                ? "global.change_image"
                : "global.upload_image"
            }
            className="text-primary-medium mt-6 text-sm"
            handleClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleProfileClick();
            }}
            isDisabled={isReadOnly}
          />
        </div>
        <InputField
          value={researchInstituteInfo?.instituteName}
          hintText={t("research_institute.enter_institute_name")}
          labelText={t("research_scholar.institute_name")}
          handleInputChange={handleInputChange("instituteName")}
          fieldName="instituteName"
          isRequired
          maxCharLength={EMAIL_MAX_CHAR_LENGTH}
          isDisabled={isReadOnly}
          errorMessage={validationErrors?.instituteName}
        />
        <TextArea
          textContent={researchInstituteInfo?.about}
          handleChange={handleInputChange("about")}
          labelText={t("global.about")}
          hintText={t("global.tell_us_more_about_you")}
          fieldName="about"
          maxCharLength={PROFILE_DESCRIPTION_MAX_CHAR_LENGTH}
          isDisabled={isReadOnly}
          errorMessage={validationErrors?.about}
        />
        <MultiSelect
          labelText={t("global.research_area")}
          hintText={t("global.search")}
          setSelectedOptions={(options: Array<OptionItem> | null) => {
            setResearchInstituteInfoItem(options, "researchArea");
            searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
          }}
          selectedOptions={researchInstituteInfo?.researchArea || []}
          addNew
          loadOptions={loadResearchAreaOptions}
          errorToastMessage={"global.create_research_area_min_error_message"}
          createLabel={"research_scholar.new_research_area"}
          resetKey={Math.random().toString()}
          isDisabled={isReadOnly}
          onMenuOpen={() => {
            searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
          }}
        />
        <div>
          {researchInstituteInfo &&
          researchInstituteInfo?.researchArea &&
          researchInstituteInfo?.researchArea?.length > 0 ? (
            <div className=" flex  flex-wrap">
              {researchInstituteInfo?.researchArea.map((item: OptionItem, i: number) => (
                <Chip
                  key={item.id ? item.id + "" : i + ""}
                  text={i18n.language === "ar" ? item.name.ar : item.name.en}
                  handleClose={(event: React.MouseEvent<HTMLElement>) =>
                    handleRemoveResearchArea(event, i)
                  }
                  disabled={isReadOnly}
                />
              ))}
            </div>
          ) : null}
        </div>
        <div>
          <SingleSelect
            selectOption={researchInstituteInfo?.instituteTypeId}
            options={researchInstituteTypeOptions || []}
            labelText={t("research_institute.institute_type")}
            hintText={t("global.search")}
            handleChange={handleSelect("instituteTypeId")}
            isRequired
            isDisabled={isReadOnly}
            className="w-full"
            errorMessage={validationErrors?.instituteTypeId}
          />
        </div>
        <div className=" flex space-x-3 rtl:space-x-reverse">
          <SingleSelect
            selectOption={researchInstituteInfo?.countryId}
            options={countryOptions || []}
            labelText={t("global.country")}
            hintText={t("global.select")}
            handleChange={handleSelect("countryId")}
            isRequired
            className=" w-full"
            isDisabled={isReadOnly}
            errorMessage={validationErrors?.countryId}
          />
          <InputField
            value={researchInstituteInfo?.email}
            hintText={t("global.enter_email_address")}
            labelText={t("organization.contact_email")}
            handleInputChange={handleInputChange("email")}
            fieldName="email"
            isRequired
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
            isDisabled
            errorMessage={validationErrors?.email}
          />
        </div>
        <div className=" flex space-x-3 rtl:space-x-reverse">
          <SingleSelect
            selectOption={researchInstituteInfo?.countryCodeId}
            options={countryCodeOptions || []}
            labelText={t("global.country_code")}
            hintText={t("global.select")}
            handleChange={handleSelect("countryCodeId")}
            isRequired
            className=" w-full"
            isDisabled={isReadOnly}
            errorMessage={validationErrors?.countryCodeId}
          />
          <InputField
            value={researchInstituteInfo?.contactNumber}
            hintText={t("global.enter_contact_number")}
            labelText={t("global.contact_number")}
            handleInputChange={handleInputChange("contactNumber")}
            fieldName="contactNumber"
            isRequired
            maxCharLength={phoneMaxLimitChecker(researchInstituteInfo?.contactNumber ?? "")}
            isDisabled={isReadOnly}
            errorMessage={validationErrors?.contactNumber}
          />
        </div>
        <div className="flex items-start rtl:space-x-reverse flex-col justify-items-start space-y-2">
          <span className="label-text text-primary-light text-sm font-normal leading-4 whitespace-nowrap">
            {t("research_institute.status")}
            <span className=" rtl:mr-1 ltr:ml-1 text-red-200">*</span>
          </span>
          <div className=" flex items-center space-x-6">
            <RadioButton
              className="mr-10 p-2 text-gray-1100 font-normal"
              label={t("research_institute.start_up")}
              value={RESEARCH_INSTITUTE_STATUS.startUp}
              checked={researchInstituteInfo?.statusId === RESEARCH_INSTITUTE_STATUS.startUp}
              onChange={() => handleStatusTypeChange(RESEARCH_INSTITUTE_STATUS.startUp)}
              disabled={isReadOnly}
            />
            <RadioButton
              className="mr-10 p-2 text-gray-1100 font-normal"
              label={t("research_institute.others")}
              value={RESEARCH_INSTITUTE_STATUS.others}
              checked={researchInstituteInfo?.statusId === RESEARCH_INSTITUTE_STATUS.others}
              onChange={() => handleStatusTypeChange(RESEARCH_INSTITUTE_STATUS.others)}
              disabled={isReadOnly}
            />
          </div>
        </div>
        <div className=" flex space-x-3 rtl:space-x-reverse">
          <InputField
            value={researchInstituteInfo?.linkedInUrl}
            hintText={t("research_scholar.enter_linkedIn_URL")}
            labelText={t("research_scholar.linked_in_url")}
            handleInputChange={handleInputChange("linkedInUrl")}
            fieldName="linkedInUrl"
            maxCharLength={URL_MAX_LENGTH}
            isDisabled={isReadOnly}
            errorMessage={validationErrors?.linkedInUrl}
            tooltipContent={[t("global.website_url_tooltip_message")]}
          />
          <InputField
            value={researchInstituteInfo?.websiteUrl}
            hintText={t("global.enter_website_url")}
            labelText={t("global.website_url")}
            handleInputChange={handleInputChange("websiteUrl")}
            fieldName="websiteUrl"
            maxCharLength={URL_MAX_LENGTH}
            isDisabled={isReadOnly}
            errorMessage={validationErrors?.websiteUrl}
            tooltipContent={[t("global.website_url_tooltip_message")]}
          />
        </div>
      </div>
      {isOrganizationTypesLoading || isResearchInstituteDataLoading ? <LoaderModal /> : null}
    </div>
  );
}
