import { useQuery } from "@tanstack/react-query";
import { setUserInfo, useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { USERS } from "utils/constants";
import { urls } from "utils/urls";

async function getFundingOrganizationDetails(userId: string, userInfo: UserInfo) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.fundingOrganizationDetails(userId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  const response = res.data?.data;
  if (userInfo && userInfo.userType === USERS.Funding) {
    setUserInfo({
      ...userInfo,
      firstName: response?.organizationName ?? "",
      profileStatusId: response.profileStatusId,
      avatarUrl: response?.profileImage,
    });
  }
  return res.data?.data as FundingOrganizationDetails | ApiError;
}

export function useGetFundingOrganizationDetails(fundingOrganizationId: string) {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = fundingOrganizationId ? fundingOrganizationId : userInfo?.userId;
  return useQuery(
    ["funding-organization-details", userId],
    async () => {
      if (userId && userInfo) {
        return await getFundingOrganizationDetails(userId, userInfo);
      } else return;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
