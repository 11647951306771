import { ToastPosition, toast } from "react-toastify";
import SVGIcon from "assets/Icons";
import i18n from "i18n";

export const ToasterIconType = {
  MAIL: "toasterMsg",
  REVIEW: "toasterReview",
  DISABLED: "toasterAccountDisabled",
  ALERT: "toasterAlert",
  WELCOME: "toasterWelcome",
  CONFIRM_SUBMISSION: "confirmSubmission",
  DECLINED: "toasterAccountDeclined",
  ENABLED: "toasterAccountEnabled",
  APPROVED: "toasterAccountApproved",
  DELETED: "toasterAccountDeleted",
  UPDATE: "toasterUpdate",
};

type ToasterProps = {
  message: string;
  iconType?: string;
  position?: ToastPosition;
  duration?: number;
};

const toastConfig = {
  pauseOnHover: false,
  pauseOnFocusLoss: false,
  position: i18n.language === "ar" ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT,
};

export const showSuccessToastMessage = ({ message, iconType, position }: ToasterProps) => {
  toast.success(message, {
    icon: <SVGIcon name={iconType ? iconType : "toasterSuccess"} />,
    closeButton: <div className="Toastify__close-button"></div>,
    ...toastConfig,
    position,
    autoClose: 2000,
  });
};

export const showErrorToastMessage = ({ message, iconType, position }: ToasterProps) => {
  toast.error(message, {
    icon: <SVGIcon name={iconType ? iconType : "toasterError"} />,
    closeButton: <div className="Toastify__close-button"></div>,
    ...toastConfig,
    position,
    autoClose: 2500,
  });
};

export const showInfoToastMessage = ({ message, iconType, position, duration }: ToasterProps) => {
  toast.info(message, {
    icon: <SVGIcon name={iconType ? iconType : "toasterInfo"} />,
    closeButton: <div className="Toastify__close-button"></div>,
    ...toastConfig,
    position,
    autoClose: duration ?? 2000,
  });
};
