import React, { useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { useMasterDataStore } from "store/useMasterDataStore";
import {
  InputField,
  SingleSelect,
  SubTitle,
  Title,
  SecondaryButton,
  PrimaryButton,
  LoaderModal,
  SearchableSingleSelect,
} from "components";
import { basicSchemaValidation } from "utils/validation/signUpValidation";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import {
  DEFAULT_MAX_CHAR_LENGTH,
  EMAIL_MAX_CHAR_LENGTH,
  INITIAL_SEARCH_PARAMS,
  PAGE_LIMIT,
  PHONE_NUMBER_MAX_CHAR_LENGTH,
  USERS,
} from "utils/constants";
import { useInviteResearchScholar } from "features/Admin/api/useInviteResearchScholar";
import { setMasterDataValue } from "utils/helpers";
import { getResearchInstitute } from "features/ResearchScholar/api/useGetResearchInstitute";
import { useUserDataStore } from "store/useUserDataStore";

type IndividualInviteProps = {
  onClose: () => void;
};

export function IndividualInvite({ onClose }: IndividualInviteProps) {
  const { t, i18n } = useTranslation();
  const { userInfo } = useUserDataStore();

  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);
  const initialState = {
    titleId: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    researchInstituteId: userInfo?.userType === USERS.ResearchInstitute ? userInfo?.userId : "",
    researchInstitute: null,
    countryCodeId: 0,
  };

  const { countryCode, title } = useMasterDataStore();
  const { mutateAsync: inviteResearchScholar, isLoading } = useInviteResearchScholar();

  const [formState, setFormState] = useState<BasicFormFields>(initialState);
  const [selectedInstitute, setSelectedInstitute] = useState<ResearchInstituteItem | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const handleSelectChange = (value: string | number, key: keyof BasicFormFields) => {
    setFormState((prevstate) => ({ ...prevstate, [key]: value as string | number }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof BasicFormFields
  ) => {
    const value = event?.target?.value;
    setFormState((prevstate) => ({ ...prevstate, [key]: value }));
  };

  const countryCodeOptions = useMemo(() => {
    return countryCode?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [countryCode, i18n.language]);

  const handleInvite = async () => {
    const error = basicSchemaValidation(formState);
    if (error) {
      setValidationErrors(error);
      return;
    }
    setValidationErrors(null);
    try {
      const formData = prepareFormData();
      const result = await inviteResearchScholar(formData);
      onClose();
      return showInfoToastMessage({
        iconType: ToasterIconType.MAIL,
        message: result?.message,
      });
    } catch (error: ApiError) {
      return showErrorToastMessage({
        iconType: ToasterIconType.ALERT,
        message:
          error.response.data.status >= 400
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const prepareFormData = () => {
    const data: BasicFormFields = {
      titleId: formState.titleId,
      firstName: formState.firstName.trim(),
      middleName: formState.middleName.trim(),
      lastName: formState.lastName.trim(),
      contactNumber: formState.contactNumber.trim(),
      countryCodeId: formState.countryCodeId,
      researchInstituteId: formState.researchInstituteId,
      email: formState.email.trim(),
    };
    return data;
  };

  const titleOptions = useMemo(() => {
    return title?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [title, i18n.language]);

  async function loadResearchInstituteOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchInstitute({
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  return (
    <div className="fixed h-screen w-screen bg-black/[0.3] z-[99] flex justify-center items-center top-0 left-0 ">
      <div className="animate__animated animate__zoomIn flex flex-col rounded-md items-center bg-white max-w-[470px] mx-2 sm:w-[470px] py-[50px] px-8 max-h-[calc(100vh-3.125rem)] overflow-auto">
        <div>
          <SVGIcon name="individualInvite" />
        </div>
        <Title
          className="mt-10 text-black text-xl font-semibold"
          title={t("admin.individual_invite")}
        />
        <SubTitle
          className="mt-[6px] text-primary-text font-normal text-base text-center"
          title={t("admin.this_will_allow_you_to_create_new_research_scholar")}
        />
        <div className="grid grid-cols-4 gap-x-4 gap-y-[22px] mt-[30px] mb-12">
          <div className=" flex col-span-4 w-full space-x-4 rtl:space-x-reverse">
            <div className=" min-w-[100px] max-w-[100px]">
              <SingleSelect
                className="text-sm font-normal"
                options={titleOptions ?? []}
                labelText={t("global.title")}
                hintText={t("global.select")}
                handleChange={(value: string | number) => handleSelectChange(value, "titleId")}
                selectOption={formState?.titleId}
                isRequired
                errorMessage={validationErrors?.titleId}
              />
            </div>
            <div className="w-full text-sm font-normal">
              <InputField
                hintText={t("global.enter_first_name")}
                labelText={t("global.first_name")}
                handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(event, "firstName")
                }
                fieldName="firstName"
                isRequired
                errorMessage={validationErrors?.firstName}
                maxCharLength={DEFAULT_MAX_CHAR_LENGTH}
              />
            </div>
          </div>
          <div className="col-span-2 text-sm font-normal">
            <InputField
              hintText={t("global.enter_middle_name")}
              labelText={t("global.middle_name")}
              handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(event, "middleName")
              }
              fieldName="middleName"
              className=" pr-2 col-span-2"
              maxCharLength={DEFAULT_MAX_CHAR_LENGTH}
              errorMessage={validationErrors?.middleName}
            />
          </div>
          <div className="col-span-2 text-sm font-normal">
            <InputField
              hintText={t("global.enter_last_name")}
              labelText={t("global.last_name")}
              handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(event, "lastName")
              }
              fieldName="lastName"
              isRequired
              className=" pr-2 col-span-2"
              maxCharLength={DEFAULT_MAX_CHAR_LENGTH}
              errorMessage={validationErrors?.lastName}
            />
          </div>
          <div className="col-span-4 text-sm font-normal">
            <InputField
              hintText={t("global.enter_email_address")}
              labelText={t("global.email_address")}
              handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(event, "email")
              }
              fieldName="email"
              isRequired
              className="col-span-full"
              maxCharLength={EMAIL_MAX_CHAR_LENGTH}
              errorMessage={validationErrors?.email}
            />
          </div>
          {userInfo?.userType !== USERS.ResearchInstitute ? (
            <div className="col-span-4 text-sm font-normal">
              <SearchableSingleSelect
                className="text-sm font-normal flex-grow w-full"
                labelText={t("research_scholar.research_institute_name")}
                hintText={t("global.select")}
                selectedOptions={selectedInstitute}
                setSelectedOptions={function (option: ResearchInstituteItem | null): void {
                  if (option) {
                    setSelectedInstitute(option);
                    handleSelectChange(option?.id, "researchInstituteId");
                  }
                  searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
                }}
                errorToastMessage="global.create_target_industry_min_character"
                loadOptions={loadResearchInstituteOptions}
                errorMessage={validationErrors?.researchInstituteId}
                resetKey={Math.random().toString()}
                onMenuOpen={() => {
                  //Commented this code to prevent duplicate entries in the list- HP-4880
                  //searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
                }}
              />
            </div>
          ) : null}
          <div className=" flex flex-col items-center col-span-4 space-y-[22px]">
            <div className="col-span-4 text-sm font-normal w-full">
              <SingleSelect
                className="text-sm font-normal"
                options={countryCodeOptions || []}
                labelText={t("global.country_code")}
                hintText={t("global.select")}
                handleChange={(value: string | number) =>
                  handleSelectChange(value, "countryCodeId")
                }
                isRequired
                errorMessage={validationErrors?.countryCodeId}
              />
            </div>

            <div className="col-span-4 text-sm font-normal w-full">
              <InputField
                hintText={t("global.enter_contact_number")}
                labelText={t("global.contact_number")}
                handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(event, "contactNumber")
                }
                fieldName="contactNumber"
                isRequired
                className="col-span-full"
                maxCharLength={PHONE_NUMBER_MAX_CHAR_LENGTH}
                errorMessage={validationErrors?.contactNumber}
              />
            </div>
          </div>
        </div>

        <div className=" flex w-full justify-center space-x-[10px] rtl:space-x-reverse ">
          <SecondaryButton
            title={"global.close"}
            handleClick={onClose}
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
          />
          <PrimaryButton
            title={"global.send_invite"}
            handleClick={handleInvite}
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
          />
        </div>
      </div>
      {isLoading ? <LoaderModal /> : null}
    </div>
  );
}
