import SVGIcon from "assets/Icons";
import useLoadImage from "hooks/useImageLoaded";
import { ImageLoader } from "components";
import { useState } from "react";

type CoverImageProps = {
  src: string;
  handleDeleteClicked: () => void;
  isDisabled?: boolean;
};
export function CoverImage({ src, handleDeleteClicked, isDisabled }: CoverImageProps) {
  const [imageSrc, setImageSrc] = useState(src);
  const [ref, isLoaded, onLoad] = useLoadImage();

  return (
    <div className="w-auto h-[200px] relative overflow-hidden">
      <div className="w-full h-[200px]">
        {!isLoaded ? <ImageLoader /> : null}
        <img src={imageSrc} ref={ref} alt="Cover" className="w-full h-[200px]" onLoad={onLoad} />
      </div>
      {isDisabled ? null : (
        <div
          className="absolute bottom-2.5 right-2.5 w-[50px] h-[50px]"
          onClick={handleDeleteClicked}
        >
          <SVGIcon name="delete" />
        </div>
      )}
    </div>
  );
}
