import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import SVGIcon from "assets/Icons";
import { TableSearchFilter } from "components";
import { INITIAL_SEARCH_PARAMS } from "utils/constants";
import { displayDateFormat, getColumns } from "utils/helpers";
import { useGetResearchInstitutes } from "features/Organization/api/useGetResearchInstitutes";
import { setOrganizationFilterInfo } from "store/useFilterDataStore";

type TableProps = {
  userData: Array<ChallengeUser>;
  headers: Array<string>;
  handleItemClick: (challengeId: number) => void;
  updateRequestParams: (data: FilterParams) => void;
  requestParams: CollaborationParticipationParams;
};

const Table = ({
  userData,
  headers,
  handleItemClick,
  requestParams,
  updateRequestParams,
}: TableProps) => {
  const { t } = useTranslation();
  const pathName = window.location.pathname;
  const filteredData = userData?.map((item) => {
    return {
      createdOn: item.createdOn,
      fullName: item.fullName,
      researchInstituteName: item.researchInstituteName,
      researchAreas: item.researchAreas,
      country: item.country,
    };
  });

  const dataTable = useReactTable({
    data: userData,
    columns: filteredData && filteredData.length ? getColumns(filteredData) : [],
    getCoreRowModel: getCoreRowModel(),
  });

  const [researchInstitutesRequestParams, setResearchInstitutesRequestParams] = useState({
    ...INITIAL_SEARCH_PARAMS,
    isGlobal: true,
  });
  const [activeFilter, setActiveFilter] = useState({
    researchInstitute: false,
  });

  const initialState = useMemo(() => {
    return {
      researchInstitutes: requestParams.researchInstitute
        ? requestParams.researchInstitute.split(",")
        : [],
    };
  }, [requestParams]);
  const [filterParams, setFilterParams] = useState<OrganizationFilterParams>(initialState);

  const {
    data,
    isLoading: isResearchInstitutesLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetResearchInstitutes(researchInstitutesRequestParams);

  useEffect(() => {
    setFilterParams(initialState);
  }, [requestParams.statusId, initialState]);

  const researchInstituteDetails = useMemo(() => {
    const tempData: ResearchInstituteData = {
      researchInstitutes: [],
      totalCount: 0,
      totalPages: 0,
    };
    if (data?.pages && data?.pages[0]) {
      tempData.totalCount = data.pages[0]?.totalCount;
      tempData.totalPages = data.pages[0]?.totalPages;
      const researchInstitutes = data.pages
        .map((item) => {
          return item.responseData;
        })
        .flat();
      tempData.researchInstitutes = researchInstitutes;
    }
    return tempData;
  }, [data]);

  const handleResearchInstituteSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResearchInstitutesRequestParams((prevState) => ({
      ...prevState,
      searchKeyword: event.target.value,
    }));
  };

  const handleResearchInstituteSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number | string
  ) => {
    if (filterParams.researchInstitutes.length === 5 && event.target.checked === true) {
      event.target.checked = false;
      return;
    }
    if (event.target.checked) {
      setFilterParams((prevState) => ({
        researchInstitutes: [...prevState.researchInstitutes, id],
      }));
    } else {
      setFilterParams((prevState) => ({
        researchInstitutes: prevState.researchInstitutes.filter((item) => item !== id),
      }));
    }
  };

  const handleSubmit = (item: string) => {
    if (item === "research_institute.research_institute") {
      updateRequestParams({
        type: filterParams.researchInstitutes.toString(),
      });
    }
    setOrganizationFilterInfo({
      researchInstitutes: filterParams.researchInstitutes.toString(),
      pathName: pathName,
    });
  };

  const isResearchInstituteSelected = (id: number | string) => {
    return filterParams.researchInstitutes.some(
      (researchInstituteId) => researchInstituteId === id
    );
  };

  const handleFilterSelection = () => {
    setFilterParams(initialState);
    setActiveFilter({
      researchInstitute: !activeFilter.researchInstitute,
    });
  };

  return (
    <div className="customTable ">
      <table>
        <thead>
          <tr>
            {headers?.map((item, index) => {
              if (item !== "research_institute.research_institute") {
                return <th key={item}>{t(item)}</th>;
              } else {
                return (
                  <th key={index}>
                    <div className="dropdown">
                      <InfiniteScroll
                        dataLength={researchInstituteDetails?.researchInstitutes?.length}
                        next={fetchNextPage}
                        hasMore={!!hasNextPage}
                        loader={false}
                        scrollThreshold="100px"
                        scrollableTarget="research-areas-dropdown"
                      >
                        <TableSearchFilter
                          item={item}
                          options={researchInstituteDetails?.researchInstitutes || []}
                          handleCheckBoxClick={handleResearchInstituteSelection}
                          handleSubmit={() => {
                            handleSubmit(item);
                          }}
                          isLoading={isResearchInstitutesLoading}
                          handleSearchInput={handleResearchInstituteSearchInput}
                          isSelected={isResearchInstituteSelected}
                          selectedValues={filterParams.researchInstitutes}
                          isFilterActive={
                            requestParams?.researchInstitute &&
                            requestParams?.researchInstitute?.length > 0
                              ? true
                              : false
                          }
                          isOpen={activeFilter.researchInstitute}
                          setIsOpen={() => handleFilterSelection()}
                        />
                      </InfiniteScroll>
                    </div>
                  </th>
                );
              }
            })}
            <th>{t("admin.view")}</th>
          </tr>
        </thead>
        <tbody>
          {dataTable.getRowModel().rows.map((row) => (
            <tr
              className="cursor-pointer"
              key={row.id}
              onClick={() => handleItemClick(row.original?.userId)}
            >
              {row.getVisibleCells().map((cell, index) => {
                if (cell.column.columnDef.header?.toString().trim() == "createdOn") {
                  return <td key={index}>{displayDateFormat(cell.getValue() as string)}</td>;
                } else if (cell.column.columnDef.header?.toString().trim() === "fullName") {
                  return (
                    <td key={index}>
                      <p className="text-primary-medium underline truncate">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </p>
                    </td>
                  );
                } else if (cell.column.columnDef.header?.toString().trim() == "researchAreas") {
                  if (cell.getValue()) {
                    return (
                      <td key={index}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  }
                  return <td key={index}>-</td>;
                } else {
                  return (
                    <td key={index}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  );
                }
              })}
              <td>
                <div className="btn btn-link h-auto min-h-[auto] p-0 text-primary-medium">
                  <SVGIcon name={"eyeCloseV1"} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
