import React from "react";
import SVGIcon from "assets/Icons";

type DescriptorProps = {
  title: string;
  startIcon?: string;
  endIcon?: string;
  className?: string;
};

export function Descriptor({ title, startIcon, endIcon, className }: DescriptorProps) {
  return (
    <div className={className}>
      <div className=" min-w-[16px] h-3.5">{startIcon ? <SVGIcon name={startIcon} /> : null}</div>
      <span className=" mt-[2px] block">{title}</span>
      {endIcon ? <SVGIcon name={endIcon} /> : null}
    </div>
  );
}
