import Joi from "joi";
import { groupBy } from "utils/helpers";

const researchProjectInfoSchema = Joi.object({
  researchProjectId: Joi.number().optional().allow(null),
  researchTitle: Joi.string().required().messages({
    "string.empty": "required",
  }),
  researchBrief: Joi.string().required().messages({
    "string.empty": "required",
  }),
  isInProgress: Joi.boolean().optional(),
  startDate: Joi.string().isoDate().required().messages({
    "string.empty": "required",
    "string.isoDate": "required",
    "string.date.base": "required",
  }),
  endDate: Joi.string().isoDate().optional().allow(null, "").messages({
    "string.isoDate": "required",
    "string.date.base": "required",
  }),
  documents: Joi.array().allow(null).optional().messages({ "string.pattern.base": "required" }),
  projectUrl: Joi.string()
    .uri()
    .optional()
    .allow("", null)
    .messages({ "string.uri": "global.please_enter_a_valid_url" }),
  publicUrl: Joi.string()
    .uri()
    .optional()
    .allow("", null)
    .messages({ "string.uri": "global.please_enter_a_valid_url" }),
  areaOfExpertise: Joi.array()
    .optional()
    .allow(null)
    .messages({ "string.pattern.base": "required" }),
  tags: Joi.array().optional().allow(null).messages({ "string.pattern.base": "required" }),
  profileStatusId: Joi.number().optional().default(1).allow(null),
}).options({ abortEarly: false });

export const researchProjectValidation = (researchProjects: ResearchProject) => {
  const result = researchProjectInfoSchema.validate(researchProjects);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
