import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getEventDetails(eventId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.get(urls.eventPreview(eventId), {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response?.data?.data as EventPreview | ApiError;
}

export function useGetEventPreview(eventId: number) {
  return useQuery(["event-preview", eventId], async () => {
    if (eventId) {
      const result = await getEventDetails(eventId);
      return result;
    } else return null;
  });
}
