import React, { useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CheckBox,
  SingleSelect,
  SubTitle,
  TextArea,
  InputField,
  SecondaryButton,
  PrimaryButton,
  Divider,
} from "components";
import {
  ADDRESS_MAX_CHAR_LENGTH,
  DETAILS_MAX_CHAR_LENGTH,
  USER_STATUS,
  WORK_EXPERIENCE_MAX_LIMIT,
} from "utils/constants";
import {
  setWorkInfo,
  setWorkInfoItem,
  useScholarProfileDataStore,
  WORK_EXPERIENCE_INITIAL_STATE,
} from "store/useScholarProfileDataStore";
import { useGetWorkExperienceDetails } from "features/ResearchScholar/api/useGetWorkExperienceDetails";
import { useMasterDataStore } from "store/useMasterDataStore";
import SVGIcon from "assets/Icons";
import { setMasterDataValue } from "utils/helpers";
import { showErrorToastMessage } from "utils/toasterMessage";
import { showConfirmationModal } from "utils/confirmationModals";
import useInvalidateQuery from "hooks/useInvalidateQuery";

type WorkExperienceProps = {
  validationErrors: Array<Record<string, string>> | null;
  removeValidationErrors: () => void;
};

export default function WorkExperience({
  validationErrors,
  removeValidationErrors,
}: WorkExperienceProps) {
  const { t, i18n } = useTranslation();
  const { researchScholarId } = useParams();
  const { workInfo } = useScholarProfileDataStore();
  const { data: workExperienceData } = useGetWorkExperienceDetails(researchScholarId ?? "");
  useInvalidateQuery("personal-details", researchScholarId ?? "");

  const { masterData } = useMasterDataStore();

  const isReadOnly = workInfo?.profileStatusId === USER_STATUS.PendingApproval;

  const countryOptions = useMemo(() => {
    return masterData?.country?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [masterData, i18n.language]);

  const handleInputChange = (
    key: keyof WorkExperience,
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const value = e.target.value;
    setWorkInfoItem(value, key, index);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setWorkInfoItem(value, "notWorking");
    removeValidationErrors();
  };

  const handleCountrySelect = (id: number | string, index: number) => {
    setWorkInfoItem(id, "companyCountryId", index);
  };

  useEffect(() => {
    if (workExperienceData) {
      setWorkInfo(workExperienceData);
    }
  }, [workExperienceData, i18n.language]);

  const handleAddWork = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const workInfo = useScholarProfileDataStore.getState().workInfo;
    if (workInfo && workInfo?.details?.length <= WORK_EXPERIENCE_MAX_LIMIT - 1) {
      const tempWorkInfo = structuredClone(workInfo);
      tempWorkInfo?.details.push({ ...WORK_EXPERIENCE_INITIAL_STATE });
      setWorkInfo({
        ...tempWorkInfo,
        details: tempWorkInfo?.details,
      });
    } else if (workInfo && workInfo?.details?.length >= WORK_EXPERIENCE_MAX_LIMIT) {
      showErrorToastMessage({
        message: `${t(
          "research_scholar.maximum_entry_limit_message"
        )} ${WORK_EXPERIENCE_MAX_LIMIT} ${t("research_scholar.entries")}`,
      });
    }
  };

  const handleDeleteWork = async (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "research_scholar.delete_an_item_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      const tempWorkInfo = workInfo?.details?.filter((item, i) => i !== index);
      if (workInfo) {
        if (tempWorkInfo && tempWorkInfo?.length > 0)
          setWorkInfo({ ...workInfo, details: [...tempWorkInfo] });
        else setWorkInfo({ ...workInfo, details: [] });

        removeValidationErrors();
      }
    }
  };

  return (
    <>
      <form className="space-y-6">
        <div className="flex max-[600px]:flex-col items-center justify-between max-[600px]:justify-start mb-6 flex-wrap pb-8 p-5 !py-3 border-b border-gray-light">
          <div className="flex  ltr:mr-auto rtl:ml-auto gap-3 justify-center items-center">
            <SVGIcon name="workExperience" />
            <SubTitle
              className=" font-semibold text-lg text-black subtitle"
              title={t("research_scholar.work_experience")}
            />
          </div>
          <div className=" flex items-center  max-[600px]:!mt-3 max-[600px]:mr-auto">
            <CheckBox
              option={{
                label: t("research_scholar.not_working"),
                value: "not_working",
              }}
              className="customCheckbox  higher-education-checkbox "
              handleChange={handleSelectChange}
              disabled={isReadOnly}
              checked={workInfo?.notWorking}
            />
            {workInfo && workInfo?.details?.length > 0 ? (
              <SecondaryButton
                title={"research_scholar.add_experience"}
                handleClick={handleAddWork}
                className=" lg:!mt-0 !h-10 !min-h-[40px] w-auto space-x-3 rtl:space-x-reverse !px-6 ltr:ml-2 rtl:mr-2"
                startIcon="plus"
                disabled={isReadOnly}
              />
            ) : null}
          </div>
        </div>
        {!workInfo?.details || workInfo?.details?.length === 0 ? (
          <div className="p-5 pt-0 ">
            <SecondaryButton
              title={"research_scholar.add_experience"}
              handleClick={handleAddWork}
              className=" w-full !bg-gray-light/60 py-3.5 !font-normal mb-6 space-x-4 rtl:space-x-reverse border-none h-16 flex items-center  ltr:ml-2 rtl:mr-2 !text-primary-medium text-sm  hover:!bg-primary-medium hover:!text-white"
              endIcon="plusCircle"
              disabled={isReadOnly}
            />
          </div>
        ) : null}
        <div className=" w-full px-4 lg:px-5 pb-8 ">
          {workInfo?.details?.map((item: WorkExperience, index: number) => {
            return (
              <div key={`${index}-${item.id}`} className=" space-y-[22px]">
                <div className="lg:mt-0">
                  <InputField
                    hintText={t("research_scholar.enter_company_name")}
                    labelText={t("research_scholar.company_name")}
                    handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange("companyName", event, index)
                    }
                    fieldName="companyName"
                    isRequired
                    isDisabled={isReadOnly}
                    errorMessage={validationErrors?.[index]?.companyName}
                    maxCharLength={DETAILS_MAX_CHAR_LENGTH}
                    value={item.companyName ?? ""}
                  />
                </div>
                <div className=" w-full">
                  <SingleSelect
                    options={countryOptions || []}
                    labelText={t("global.country")}
                    hintText={t("global.select")}
                    isRequired
                    handleChange={(value: string | number) => handleCountrySelect(value, index)}
                    isDisabled={isReadOnly}
                    errorMessage={validationErrors?.[index]?.companyCountryId}
                    selectOption={item?.companyCountryId}
                  />
                </div>
                <div className=" w-full lg:col-span-2">
                  <TextArea
                    textContent={item?.companyAddress ?? ""}
                    handleChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                      handleInputChange("companyAddress", event, index)
                    }
                    hintText={t("research_scholar.enter_company_address")}
                    labelText={t("research_scholar.company_address")}
                    fieldName="courseName"
                    isDisabled={isReadOnly}
                    maxCharLength={ADDRESS_MAX_CHAR_LENGTH}
                  />
                </div>
                <div className=" flex justify-center">
                  <PrimaryButton
                    title={"research_scholar.delete_experience"}
                    handleClick={(event: React.MouseEvent<HTMLElement>) =>
                      handleDeleteWork(event, index)
                    }
                    startIcon="trashV1"
                    className=" !h-10 !min-h-0  !min-w-[150px] !rounded-sm !my-5 !text-xs !px-[6px] font-medium bg-white !text-red-dark !border-red-dark mx-auto hover:!bg-red-dark/10 hover:!border-red-dark/30"
                    disabled={isReadOnly}
                  />
                </div>
                {index != workInfo?.details?.length - 1 ? <Divider className="!mb-[22px]" /> : null}
              </div>
            );
          })}
        </div>
      </form>
    </>
  );
}
