import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import SVGIcon from "assets/Icons";
import { FilterCalender, TableSearchFilter } from "components";
import { displayDateFormat, getColumns } from "utils/helpers";
import { useMasterDataStore } from "store/useMasterDataStore";
import { useGetOrganizationTypes } from "features/Admin/api/useGetOrganizationTypes";
import { FILTER_INIT_STATE, ORGANIZATION_TYPES } from "utils/constants";
import { setTableFilterInfo } from "store/useFilterDataStore";

type TableProps = {
  userData: Array<OrganizationUsersData>;
  headers: Array<string>;
  handleRowClick: (userId: string, index: number) => void;
  updateRequestParams: (data: FilterParams) => void;
  requestParams: UserDetailsParams;
};

const Table = ({
  userData,
  headers,
  handleRowClick,
  updateRequestParams,
  requestParams,
}: TableProps) => {
  const { t, i18n } = useTranslation();
  const { masterData } = useMasterDataStore();
  const pathName = window.location.pathname;
  const initialState = useMemo(() => {
    return {
      country: requestParams.country ? requestParams.country.split(",").map(Number) : [],
      type: requestParams.type ? requestParams.type.split(",").map(Number) : [],
      fromDate: requestParams.fromDate ? requestParams.fromDate : null,
      toDate: requestParams.toDate ? requestParams.toDate : null,
    };
  }, [requestParams]);
  const [filterParams, setFilterParams] = useState<AdminFilterParams>(initialState);

  const { data: organizationTypes } = useGetOrganizationTypes();

  useEffect(() => {
    setFilterParams(initialState);
  }, [requestParams, initialState]);

  const setFilterParamsDateValue = (data: FilterParams) => {
    setFilterParams((prevState) => ({
      ...prevState,
      fromDate: data.fromDate ?? null,
      toDate: data.toDate ?? null,
    }));
  };
  const [activeFilter, setActiveFilter] = useState(FILTER_INIT_STATE);

  const countryOptions = useMemo(() => {
    return masterData?.country;
  }, [masterData]);

  const organizationTypeOptions = useMemo(() => {
    return organizationTypes
      ?.filter((item) => item?.id !== ORGANIZATION_TYPES.StartUp)
      .map((item) => {
        return item;
      });
  }, [organizationTypes]);

  const handleSubmit = (item: string) => {
    if (item === "admin.created_on") {
      updateRequestParams({
        fromDate: filterParams.fromDate,
        toDate: filterParams.toDate,
      });
    } else if (item === "global.country") {
      updateRequestParams({
        country: filterParams.country.toString(),
      });
    } else if (item === "admin.research_areas" || item === "admin.type") {
      updateRequestParams({
        type: filterParams.type.toString(),
      });
    }
    setTableFilterInfo({
      researchArea: filterParams.type.toString(),
      pathName: pathName,
      country: filterParams.country.toString(),
      fromDate: filterParams.fromDate,
      toDate: filterParams.toDate,
    });
  };

  const handleOrganizationClick = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (filterParams.type.length === 5 && event.target.checked === true) {
      event.target.checked = false;
      return;
    }
    if (event.target.checked) {
      setFilterParams((prevState) => ({
        ...prevState,
        type: [...prevState.type, id],
      }));
    } else {
      setFilterParams((prevState) => ({
        ...prevState,
        type: prevState.type.filter((item) => item !== id),
      }));
    }
  };

  const handleCountryClick = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (filterParams.country.length === 5 && event.target.checked === true) {
      event.target.checked = false;
      return;
    }
    if (event.target.checked) {
      setFilterParams((prevState) => ({
        ...prevState,
        country: [...prevState.country, id],
      }));
    } else {
      setFilterParams((prevState) => ({
        ...prevState,
        country: prevState.country.filter((item) => item !== id),
      }));
    }
  };

  const [countrySearchResult, setCountrySearchResult] = useState<
    Array<MasterDataItem> | undefined
  >();
  const handleCountrySearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchKey = event.target.value;

    if (searchKey) {
      const filteredCountries = countryOptions?.filter((country) => {
        if (i18n.language === "en") {
          return country?.name?.en.toLowerCase().trim().includes(searchKey.toLowerCase().trim());
        } else {
          return country?.name?.ar.trim().includes(searchKey.trim());
        }
      });
      setCountrySearchResult(filteredCountries);
    } else {
      setCountrySearchResult(undefined);
    }
  };

  const [organizationTypesSearchResult, setOrganizationTypesSearchResult] = useState<
    Array<MasterDataItem> | undefined
  >();
  const handleOrganizationSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchKey = event.target.value;

    if (searchKey) {
      const filteredOrganizationTypes = organizationTypeOptions?.filter((organizationType) => {
        if (i18n.language === "en") {
          return organizationType?.name?.en
            .toLowerCase()
            .trim()
            .includes(searchKey.toLowerCase().trim());
        } else {
          return organizationType?.name?.ar.trim().includes(searchKey.trim());
        }
      });
      setOrganizationTypesSearchResult(filteredOrganizationTypes);
    } else {
      setOrganizationTypesSearchResult(undefined);
    }
  };

  const isCountrySelected = (id: number) => {
    return filterParams.country.some((countryId) => countryId === id);
  };

  const isOrganizationSelected = (id: number) => {
    return filterParams.type.some((typeId) => typeId === id);
  };
  const filteredData = userData?.map((item) => {
    return {
      createdOn: item.createdOn,
      organizationName: item.organizationName,
      organizationType: item.organizationType,
      email: item.email,
      country: item.country,
    };
  });

  const dataTable = useReactTable({
    data: userData,
    columns: filteredData && filteredData.length ? getColumns(filteredData) : [],
    getCoreRowModel: getCoreRowModel(),
  });

  const handleFilterSelection = (filterOption: { [key: string]: boolean }) => {
    setFilterParams(initialState);
    setActiveFilter(() => ({
      ...FILTER_INIT_STATE,
      ...filterOption,
    }));
  };

  return (
    <div className="customTable ">
      <table>
        <thead>
          <tr>
            {headers?.map((item, index) => {
              if (item === "admin.created_on") {
                return (
                  <th key={index}>
                    <FilterCalender
                      item={item}
                      handleSubmit={handleSubmit}
                      filterParams={filterParams}
                      setFilterParamsValue={setFilterParamsDateValue}
                      isFilterActive={requestParams?.fromDate ? true : false}
                      isOpen={activeFilter.createdOn}
                      setIsOpen={(status: boolean) => handleFilterSelection({ createdOn: status })}
                    />
                  </th>
                );
              } else if (item === "admin.type") {
                return (
                  <th key={index}>
                    <div className="dropdown">
                      <TableSearchFilter
                        item={item}
                        options={
                          organizationTypesSearchResult
                            ? organizationTypesSearchResult
                            : organizationTypeOptions || []
                        }
                        handleCheckBoxClick={handleOrganizationClick}
                        handleSubmit={handleSubmit}
                        handleSearchInput={handleOrganizationSearch}
                        isSelected={isOrganizationSelected}
                        selectedValues={filterParams.type}
                        isFilterActive={
                          requestParams?.type && requestParams?.type?.length > 0 ? true : false
                        }
                        isOpen={activeFilter.type}
                        setIsOpen={(status: boolean) => handleFilterSelection({ type: status })}
                      />
                    </div>
                  </th>
                );
              } else if (item === "global.country") {
                return (
                  <th key={index}>
                    <div className="dropdown">
                      <TableSearchFilter
                        item={item}
                        options={countrySearchResult ? countrySearchResult : countryOptions || []}
                        handleCheckBoxClick={handleCountryClick}
                        handleSubmit={handleSubmit}
                        handleSearchInput={handleCountrySearch}
                        isSelected={isCountrySelected}
                        selectedValues={filterParams.country}
                        isFilterActive={
                          requestParams?.country && requestParams?.country?.length > 0
                            ? true
                            : false
                        }
                        isOpen={activeFilter.country}
                        setIsOpen={(status: boolean) => handleFilterSelection({ country: status })}
                      />
                    </div>
                  </th>
                );
              } else {
                return <th key={index}>{t(item)}</th>;
              }
            })}
            <th>{t("admin.view")}</th>
          </tr>
        </thead>
        <tbody>
          {dataTable.getRowModel().rows.map((row, index) => (
            <tr
              className="cursor-pointer"
              key={row.id}
              onClick={() => handleRowClick(row.original?.userId, index)}
            >
              {row.getVisibleCells().map((cell, index) => {
                if (cell.column.columnDef.header?.toString().trim() == "createdOn") {
                  return <td key={index}>{displayDateFormat(cell.getValue() as string)}</td>;
                } else if (cell.column.columnDef.header?.toString().trim() === "organizationName") {
                  return (
                    <td key={index}>
                      <p className="text-primary-medium underline truncate">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </p>
                    </td>
                  );
                } else {
                  return (
                    <td key={index}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  );
                }
              })}
              <td>
                <div className="btn btn-link h-auto min-h-[auto] p-0 text-primary-medium">
                  <SVGIcon name={"eyeCloseV1"} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
