import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getProfile(userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.researchScholarBasicProfile(userId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res?.data?.data as ResearchScholarProfile | ApiError;
}

export function useGetProfile(userId: string) {
  return useQuery(
    ["profile", userId],
    async () => {
      if (userId) return await getProfile(userId);
      else return null;
    },
    {
      staleTime: Infinity,
    }
  );
}
