import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQueryClient } from "@tanstack/react-query";
import {
  ChallengeCard,
  Chip,
  FilterTab,
  Header,
  InlineLoader,
  LoaderModal,
  MultiSelect,
  NoData,
  SecondaryButton,
  Title,
} from "components";
import {
  resetScroll,
  setScrollInfo,
  useFilterDataStore,
  setChallengeFilterInfoItem as setCollaborationFilterInfoItem,
} from "store/useFilterDataStore";
import { useUserDataStore } from "store/useUserDataStore";
import { clearCollaborationDataStore } from "store/useCollaborationDataStore";
import { setSelectedFormId, setSelectedSideMenu } from "store/useSidebarStore";
import { useSetScroll } from "hooks/useSetScroll";
import { useStickyScroll } from "hooks/useStickyScroll";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import useResetQueryOnLanguageSwitch from "hooks/useResetQueryOnLanguageSwitch";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";
import {
  CHALLENGE_STATUS,
  INITIAL_SEARCH_PARAMS,
  EXPLORE_COLLABORATIONS_INIT_PARAMS,
  PAGE_LIMIT,
} from "utils/constants";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { useGetCollaborationList } from "features/Collaboration/api/useGetCollaborationList";
import { CollaborationCard } from "components/CollaborationCard";

const ExploreCollaborations = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { scroll } = useStickyScroll();
  const { scrollPosition } = useGetScrollPosition();
  const { userInfo } = useUserDataStore();
  const { scrollInfo } = useFilterDataStore();
  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);
  const { challengeFilterInfo: collaborationFilterInfo } = useFilterDataStore();

  const COLLABORATION_FILTER_ITEMS = [
    {
      value: CHALLENGE_STATUS.All,
      label: "research_institute.all_collaborations",
    },
    {
      value: CHALLENGE_STATUS.Open,
      label: "organization.open",
    },
    {
      value: CHALLENGE_STATUS.Close,
      label: "organization.closed",
    },
  ];
  const linkList: Array<LinkItem> = [
    {
      title: "global.collaborations",
    },
    {
      title: "research_institute.explore_collaborations",
    },
  ];
  const [activeTab, setActiveTab] = useState<number>(
    collaborationFilterInfo?.exploreActiveTab ?? CHALLENGE_STATUS.All
  );
  const [selectedOptions, setSelectedOptions] = useState<OptionItem[]>(
    collaborationFilterInfo?.selectedResearchAreas ?? []
  );
  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "collaboration-list");
  useResetQueryOnLanguageSwitch("collaboration-list");

  const [requestParams, setRequestParams] = useState<CollaborationListParams>({
    ...EXPLORE_COLLABORATIONS_INIT_PARAMS,
    status: collaborationFilterInfo?.exploreActiveTab ?? CHALLENGE_STATUS.All,
    researchArea: collaborationFilterInfo?.researchAreaId ?? [],
  });

  const {
    data: collaborationList,
    isLoading: collaborationListLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetCollaborationList(requestParams);

  const collaborationDetails = useMemo(() => {
    const tempData: CollaborationListingResponse = {
      collaborations: [],
      totalCount: 0,
      totalPages: 0,
    };
    if (collaborationList?.pages && collaborationList?.pages[0]) {
      tempData.totalCount = collaborationList.pages[0]?.totalCount;
      tempData.totalPages = collaborationList.pages[0]?.totalPages;
      const collaborations = collaborationList.pages
        .map((item) => {
          return item.collaborations;
        })
        .flat();
      tempData.collaborations = collaborations;
    }
    return tempData;
  }, [collaborationList]);

  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  const handleTabChange = (type: number) => {
    setActiveTab(type);
    queryClient.resetQueries(["collaboration-list"]);
    if (type === CHALLENGE_STATUS.All && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.All }));
      setCollaborationFilterInfoItem("exploreActiveTab", CHALLENGE_STATUS.All);
    } else if (type === CHALLENGE_STATUS.Open && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Open }));
      setCollaborationFilterInfoItem("exploreActiveTab", CHALLENGE_STATUS.Open);
    } else if (type === CHALLENGE_STATUS.Close && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Close }));
      setCollaborationFilterInfoItem("exploreActiveTab", CHALLENGE_STATUS.Close);
    }
  };

  const handleSelectedOptions = (options: OptionItem[] | null) => {
    if (options === null) {
      setSelectedOptions([]);
      setCollaborationFilterInfoItem("researchAreaId", []);
      setCollaborationFilterInfoItem("selectedResearchAreas", []);
    } else if (options.length <= 5) {
      const newOptions = options.filter(
        (option) => !selectedOptions.some((selectedOption) => selectedOption.id === option.id)
      );
      if (newOptions.length > 0) {
        setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, ...newOptions]);
        setCollaborationFilterInfoItem("selectedResearchAreas", [
          ...selectedOptions,
          ...newOptions,
        ]);
        const researchAreaIds = newOptions.map((option) => Number(option.id));
        setRequestParams((prevState) => ({
          ...prevState,
          researchArea: [...prevState.researchArea, ...researchAreaIds],
        }));
        setCollaborationFilterInfoItem("researchAreaId", [
          ...requestParams.researchArea,
          ...researchAreaIds,
        ]);
      }
    }
  };

  const handleRemoveSelectedOption = (optionToRemove: OptionItem) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    setCollaborationFilterInfoItem(
      "selectedResearchAreas",
      selectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    const remainingResearchAreas = selectedOptions.filter(
      (option) => option.id !== optionToRemove.id
    );
    const researchAreaIds = remainingResearchAreas.map((option) => Number(option.id));
    setRequestParams((prevState) => ({
      ...prevState,
      researchArea: researchAreaIds,
    }));
    setCollaborationFilterInfoItem("researchAreaId", [...researchAreaIds]);
  };

  const handleCreateCollaboration = () => {
    clearCollaborationDataStore();
    setSelectedFormId(null);
    resetScroll();
    setScrollInfo({
      pathName: window.location.pathname,
      scrollPosition: 0,
      index: 0,
    });
    navigate("/research-institute/collaborations/explore/create");
  };

  function handleCollaborationClick(index: number) {
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: location.pathname,
      index: pageParam,
    });
  }

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.collaborations",
    });
  }, []);

  useEffect(() => {
    if (scrollInfo?.pathName && location.pathname !== scrollInfo?.pathName) {
      resetScroll();
    }
  }, [scrollInfo?.pathName]);

  return (
    <div className="w-full h-full flex">
      <Header
        title={"research_institute.explore_collaborations"}
        linkList={linkList}
        className={
          scroll
            ? " bg-gradient-blueDark md:bg-white animate__animated animate__fadeInDown shadow-sticky !py-[26px] !h[57px] md:!h-[90px] fixed stickyHeader"
            : "fixed bg-gradient-blueDark lg:bg-[#E8F1F6]"
        }
      />
      <div className=" adminDetails w-full pt-[118px] pb-10 items-baseline px-4 lg:px-10 lg:ltr:pr-[30px] lg:rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto ">
        <div className="tabContainer w-full   flex items-baseline ">
          <FilterTab
            filterOptions={COLLABORATION_FILTER_ITEMS}
            activeTab={activeTab}
            handleClick={(value) => handleTabChange(value)}
          />
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-[46px] space-x-0 rtl:space-x-reverse w-full pt-[22px]">
          <div className="min-w-[288px] lg:min-w-[482px]">
            <div className=" relative">
              <MultiSelect
                labelText={t("research_scholar.challenge_search_areas")}
                hintText={t("research_scholar.add_research_areas")}
                setSelectedOptions={handleSelectedOptions}
                selectedOptions={selectedOptions}
                loadOptions={loadResearchAreaOptions ?? []}
                isDisabled={selectedOptions.length > 4}
              />
            </div>
          </div>
          <div className="w-full mt-6 md:mt-0">
            {selectedOptions.length > 0 && (
              <Title
                className=" text-primary-light text-sm leading-4 font-normal mb-2 md:mb-6"
                title={t("global.selected_areas")}
              />
            )}
            <div className="flex flex-wrap mb-4 lg:mb-0">
              {selectedOptions.map((option) => (
                <Chip
                  key={option.id}
                  className="chipPrimary"
                  text={option.name.en}
                  handleClose={() => handleRemoveSelectedOption(option)}
                />
              ))}
            </div>
          </div>
          <div className="tab-content pt-0 pb-6 lg:py-6 w-full">
            <div className=" flex justify-end  items-center">
              {/* {permissions?.Createchallenge ? ( */}
              <SecondaryButton
                title={"organization.create_new_collaboration"}
                handleClick={handleCreateCollaboration}
                startIcon="addMilestone"
                className="!bg-transparent space-x-1 rtl:space-x-reverse"
              />
              {/* ) : null} */}
            </div>
          </div>
        </div>
        <div className="w-full pt-5">
          <Title
            className=" text-primary-light text-sm leading-4 font-normal  pb-5 "
            title={`${
              collaborationList?.pages[0]?.totalCount
                ? collaborationList?.pages[0]?.totalCount
                : t("global.no")
            } ${t("organization.results_found")}`}
          />
          {collaborationDetails?.collaborations?.length > 0 ? (
            <InfiniteScroll
              dataLength={collaborationDetails?.collaborations?.length}
              next={fetchNextPage}
              hasMore={!!hasNextPage}
              loader={hasNextPage || collaborationListLoading ? <InlineLoader /> : null}
              scrollThreshold="200px"
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                {collaborationDetails.collaborations.map(
                  (item: CollaborationData, index: number) => (
                    <div key={index} className="">
                      <CollaborationCard
                        data={item}
                        showCollaborationSignature={false}
                        path={
                          item?.researchInstituteId === userInfo?.userId
                            ? `/research-institute/collaborations/${item?.id}/explore`
                            : `/research-institute/collaborations/explore/${item?.id}`
                        }
                        handleCollaborationClick={() => handleCollaborationClick(index)}
                      />
                    </div>
                  )
                )}
              </div>
            </InfiniteScroll>
          ) : null}
          {!collaborationListLoading && collaborationDetails?.collaborations?.length === 0 ? (
            <div>
              <NoData message={"global.no_collaborations_to_show"} />
            </div>
          ) : null}
        </div>
        {collaborationListLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
};
export default ExploreCollaborations;
