import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function manageFundingOrganizationRequest(userId: string, statusId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(
    urls.manageFundingOrganizationRequest(userId, statusId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus;
}

export function useManageFundingOrganizationRequest() {
  const qc = useQueryClient();
  return useMutation(async ({ userId, statusId }: { userId: string; statusId: number }) => {
    const result = await manageFundingOrganizationRequest(userId, statusId);
    qc.invalidateQueries(["funding-organization-details", userId]);
    return result;
  });
}
