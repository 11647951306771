import Joi from "joi";
import { groupBy } from "utils/helpers";

const educationInfoSchema = Joi.object({
  id: Joi.number().default(0),
  instituteName: Joi.string().trim().required().messages({
    "string.empty": "required",
    "string.pattern.base": "required",
    "string.base": "required",
  }),
  courseName: Joi.string().trim().required().messages({
    "string.empty": "required",
    "string.pattern.base": "required",
    "string.base": "required",
  }),
}).options({ abortEarly: false });

export const educationInfoValidation = (educationInfo: HighestEducation) => {
  const result = educationInfoSchema.validate(educationInfo);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
