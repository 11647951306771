import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function deleteOrganizationUser(userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.delete(urls.deleteOrganizationUser(userId), {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response?.data as ApiStatus | ApiError;
}

export function useDeleteOrganizationUser() {
  const userInfo = useUserDataStore.getState().userInfo;
  const loggeduserId = userInfo?.userId;
  const qc = useQueryClient();
  return useMutation(async (userId: string) => {
    if (userId) {
      const result = await deleteOrganizationUser(userId);
      qc.invalidateQueries(["organization-user-list", loggeduserId]);
      return result;
    }
  });
}
