import { useState, useMemo, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQueryClient } from "@tanstack/react-query";
import {
  FilterPopup,
  FilterTab,
  ChallengeCard,
  Chip,
  Title,
  SecondaryButton,
  NoData,
  MultiSelect,
  LoaderModal,
  InlineLoader,
  Header,
  PrimaryButton,
  NoPermissionCard,
  PortalFilter,
} from "components";
import {
  CHALLENGE_STATUS,
  CHALLENGE_TYPES,
  PAGE_LIMIT,
  USERS,
  INITIAL_SEARCH_PARAMS,
  ORGANIZATION_CHALLENGE_INIT_PARAMS,
  RESEARCH_SCHOLAR_CHALLENGE_INIT_PARAMS,
  GLOBAL_PORTAL_FILTERS,
} from "utils/constants";
import { clearChallengeStore } from "store/useChallengeStore";
import { useUserDataStore } from "store/useUserDataStore";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { useGetChallengeList } from "features/Challenge/api/useGetChallengeList";
import { useStickyScroll } from "hooks/useStickyScroll";
import { setSelectedSideMenu, setSelectedUserType } from "store/useSidebarStore";
import { useMasterDataStore } from "store/useMasterDataStore";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import {
  resetScroll,
  setScrollInfo,
  useFilterDataStore,
  setChallengeFilterInfoItem,
  clearFilterDataStore,
  clearOrganizationFilterInfo,
  setUserType,
  clearUserTypeFilter,
} from "store/useFilterDataStore";
import { useSetScroll } from "hooks/useSetScroll";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";
import useResetQueryOnLanguageSwitch from "hooks/useResetQueryOnLanguageSwitch";
import { getGlobalFilterParam } from "utils/helpers";

export default function Dashboard() {
  const { t, i18n } = useTranslation();
  const { scroll } = useStickyScroll();
  const { userInfo } = useUserDataStore();
  const navigate = useNavigate();
  const { permissions } = useMasterDataStore();
  const pathname = window.location.pathname;
  const { challengeFilterInfo, scrollInfo, userType } = useFilterDataStore();
  const queryClient = useQueryClient();
  const isViewLiveChallenge = permissions
    ? permissions.Viewlivechallenges && pathname !== "/research-institute/challenges"
    : false;
  const { organizationFilterInfo } = useFilterDataStore();
  const showChallengeSignature =
    userInfo?.userType === USERS.Organization ||
    (userInfo?.userType === USERS.ResearchInstitute && pathname === "/research-institute/requests");

  const isGlobal = userInfo?.isGlobal
    ? true
    : getGlobalFilterParam(userType ?? GLOBAL_PORTAL_FILTERS.Both);
  const [requestParams, setRequestParams] = useState<ChallengeListParams>(
    userInfo?.userType === USERS.Organization
      ? {
          ...ORGANIZATION_CHALLENGE_INIT_PARAMS,
          type: challengeFilterInfo?.activeTab ?? CHALLENGE_TYPES.All,
          status: challengeFilterInfo?.activeFilter ?? CHALLENGE_STATUS.All,
          isGlobal,
        }
      : {
          ...RESEARCH_SCHOLAR_CHALLENGE_INIT_PARAMS,
          status: challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.All,
          researchArea: challengeFilterInfo?.researchAreaId ?? [],
          isGlobal,
        }
  );
  const [selectedOptions, setSelectedOptions] = useState<OptionItem[]>(
    challengeFilterInfo?.selectedResearchAreas ?? []
  );
  const {
    data: challengeList,
    isLoading: challengeListLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetChallengeList(requestParams);
  const [isOpenSideMenu, setIsOpenSideMenu] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(
    userInfo?.userType === USERS.Organization
      ? challengeFilterInfo?.activeTab ?? CHALLENGE_TYPES.All
      : challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.All
  );
  const [activeFilter, setActiveFilter] = useState<number>(
    challengeFilterInfo?.activeFilter ?? CHALLENGE_STATUS.All
  );
  const [userTypeActiveFilter, setUserTypeActiveFilter] = useState(
    userType ?? GLOBAL_PORTAL_FILTERS.Both
  );
  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };
  const enableAllContent =
    (permissions &&
      permissions.Viewopenchallenges &&
      permissions.Viewlivechallenges &&
      permissions.Viewclosedchallenges &&
      userInfo?.userType !== USERS.ResearchInstitute) ||
    (permissions &&
      permissions.Viewopenchallenges &&
      permissions.Viewclosedchallenges &&
      userInfo?.userType === USERS.ResearchInstitute);

  const displayContent =
    permissions?.Viewopenchallenges ||
    permissions?.Viewlivechallenges ||
    permissions?.Viewclosedchallenges ||
    userInfo?.userType === USERS.Admin ||
    (userInfo?.userType === USERS.ResearchInstitute &&
      pathname === "/research-institute/requests" &&
      permissions?.Viewchallengerequests);

  const { scrollPosition } = useGetScrollPosition();

  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "challenge-list");
  useResetQueryOnLanguageSwitch("challenge-list");

  const handleTabChange = (type: number) => {
    setActiveTab(type);
    queryClient.resetQueries(["challenge-list"]);
    if (userInfo?.userType === USERS.Organization) {
      if (type === CHALLENGE_TYPES.All && type !== activeTab) {
        setRequestParams((prevState) => ({ ...prevState, type: CHALLENGE_TYPES.All }));
        setChallengeFilterInfoItem("activeTab", CHALLENGE_TYPES.All);
      } else if (type === CHALLENGE_TYPES.Public && type !== activeTab) {
        setRequestParams((prevState) => ({ ...prevState, type: CHALLENGE_TYPES.Public }));
        setChallengeFilterInfoItem("activeTab", CHALLENGE_TYPES.Public);
      } else if (type === CHALLENGE_TYPES.Private && type !== activeTab) {
        setRequestParams((prevState) => ({ ...prevState, type: CHALLENGE_TYPES.Private }));
        setChallengeFilterInfoItem("activeTab", CHALLENGE_TYPES.Private);
      }
    } else {
      if (type === CHALLENGE_STATUS.All && type !== activeTab) {
        setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.All }));
        setChallengeFilterInfoItem("activeTab", CHALLENGE_STATUS.All);
      } else if (type === CHALLENGE_STATUS.Open && type !== activeTab) {
        setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Open }));
        setChallengeFilterInfoItem("activeTab", CHALLENGE_STATUS.Open);
      } else if (type === CHALLENGE_STATUS.Live && type !== activeTab) {
        setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Live }));
        setChallengeFilterInfoItem("activeTab", CHALLENGE_STATUS.Live);
      } else if (type === CHALLENGE_STATUS.Close && type !== activeTab) {
        setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Close }));
        setChallengeFilterInfoItem("activeTab", CHALLENGE_STATUS.Close);
      } else if (type === CHALLENGE_STATUS.Pending && type !== activeTab) {
        setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Pending }));
        setChallengeFilterInfoItem("activeTab", CHALLENGE_STATUS.Pending);
      }
    }
  };

  const handleCreateChallenge = async () => {
    clearChallengeStore();
    setScrollInfo({
      pathName: pathname,
      scrollPosition: 0,
      index: 0,
    });
    navigate("create");
  };

  const handleFilterChange = (selectedFilterId: number) => {
    setActiveFilter(selectedFilterId);
    queryClient.resetQueries(["challenge-list"]);
    if (selectedFilterId === CHALLENGE_STATUS.Draft && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Draft }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.Draft);
    } else if (selectedFilterId === CHALLENGE_STATUS.Open && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Open }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.Open);
    } else if (selectedFilterId === CHALLENGE_STATUS.Live && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Live }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.Live);
    } else if (selectedFilterId === CHALLENGE_STATUS.Close && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Close }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.Close);
    } else if (selectedFilterId === CHALLENGE_STATUS.All && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.All }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.All);
    } else if (selectedFilterId === CHALLENGE_STATUS.Disable && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Disable }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.Disable);
    } else if (selectedFilterId === CHALLENGE_STATUS.Pending && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Pending }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.Pending);
    }
  };

  const challengeDetails = useMemo(() => {
    const tempData: ChallengeListingResponse = { challenges: [], totalCount: 0, totalPages: 0 };
    if (challengeList?.pages && challengeList?.pages[0]) {
      tempData.totalCount = challengeList.pages[0]?.totalCount;
      tempData.totalPages = challengeList.pages[0]?.totalPages;
      const challenges = challengeList.pages
        .map((item) => {
          return item.challenges;
        })
        .flat();
      tempData.challenges = challenges;
    }
    return tempData;
  }, [challengeList]);

  const challengeListItems: Array<FilterOption> = useMemo(() => {
    const tempItems = [];
    if (permissions) {
      if (
        permissions.Viewdraftchallenges &&
        permissions?.Viewopenchallenges &&
        permissions?.Viewlivechallenges &&
        permissions?.Viewclosedchallenges
      ) {
        tempItems.push({
          option: {
            value: CHALLENGE_STATUS.All,
            label: t("global.all"),
          },
        });
      }

      if (permissions.Viewdraftchallenges) {
        tempItems.push({
          option: {
            value: CHALLENGE_STATUS.Draft,
            label: t("organization.drafts"),
          },
        });
      }
      if (permissions?.Viewopenchallenges) {
        tempItems.push({
          option: {
            value: CHALLENGE_STATUS.Open,
            label: t("organization.open"),
          },
        });
      }
      if (permissions?.Viewlivechallenges) {
        tempItems.push({
          option: {
            value: CHALLENGE_STATUS.Live,
            label: t("organization.live"),
          },
        });
      }
      if (permissions.Viewclosedchallenges) {
        tempItems.push({
          option: {
            value: CHALLENGE_STATUS.Close,
            label: t("organization.closed"),
          },
        });
      }
      tempItems.push(
        {
          option: {
            value: CHALLENGE_STATUS.Pending,
            label: t("global.pending"),
          },
        },
        {
          option: {
            value: CHALLENGE_STATUS.Disable,
            label: t("global.disabled"),
          },
        }
      );
    }
    return tempItems;
  }, [permissions, userInfo, i18n.language]);

  const challengeFilterItems: Array<RecordItem> = useMemo(() => {
    const tempItems = [];
    if (permissions) {
      if (userInfo?.userType && userInfo?.userType > 0 && permissions) {
        if (userInfo?.userType === USERS.Organization) {
          tempItems.push({
            value: CHALLENGE_TYPES.All,
            label: "global.all",
          });
          tempItems.push({
            value: CHALLENGE_TYPES.Public,
            label: "organization.public",
          });
          tempItems.push({
            value: CHALLENGE_TYPES.Private,
            label: "organization.private",
          });
        } else {
          if (enableAllContent) {
            tempItems.push({
              value: CHALLENGE_STATUS.All,
              label: "organization.all_challenges",
            });
          }
          if (permissions.Viewopenchallenges) {
            tempItems.push({
              value: CHALLENGE_STATUS.Open,
              label: "organization.open",
            });
          }
          if (isViewLiveChallenge) {
            tempItems.push({
              value: CHALLENGE_STATUS.Live,
              label: "organization.live",
            });
          }
          if (permissions.Viewclosedchallenges) {
            tempItems.push({
              value: CHALLENGE_STATUS.Close,
              label: "organization.closed",
            });
          }
        }
        if (userInfo?.userType === USERS.Admin) {
          tempItems.push({
            value: CHALLENGE_STATUS.Pending,
            label: "global.pending",
          });
        }
      }
    }
    return tempItems;
  }, [permissions, userInfo, enableAllContent]);

  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);
  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  const handleSelectedOptions = (options: OptionItem[] | null) => {
    if (options === null) {
      setSelectedOptions([]);
      setRequestParams((prevState) => ({ ...prevState, researchArea: [] }));
      setChallengeFilterInfoItem("researchAreaId", []);
      setChallengeFilterInfoItem("selectedResearchAreas", []);
    } else if (options.length <= 5) {
      const newOptions = options.filter(
        (option) => !selectedOptions.some((selectedOption) => selectedOption.id === option.id)
      );
      if (newOptions.length > 0) {
        setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, ...newOptions]);
        setChallengeFilterInfoItem("selectedResearchAreas", [...selectedOptions, ...newOptions]);
        const researchAreaIds = newOptions.map((option) => Number(option.id));
        setRequestParams((prevState) => ({
          ...prevState,
          researchArea: [...prevState.researchArea, ...researchAreaIds],
        }));
        setChallengeFilterInfoItem("researchAreaId", [
          ...requestParams.researchArea,
          ...researchAreaIds,
        ]);
      }
    }
  };

  const handleRemoveSelectedOption = (optionToRemove: OptionItem) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    setChallengeFilterInfoItem(
      "selectedResearchAreas",
      selectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    const remainingResearchAreas = selectedOptions.filter(
      (option) => option.id !== optionToRemove.id
    );
    const researchAreaIds = remainingResearchAreas.map((option) => Number(option.id));
    setRequestParams((prevState) => ({
      ...prevState,
      researchArea: researchAreaIds,
    }));
    setChallengeFilterInfoItem("researchAreaId", [...researchAreaIds]);
  };

  useEffect(() => {
    if (
      pathname === "/research-scholar/home" ||
      pathname === "/funding-organization/home" ||
      pathname === "/research-institute/requests"
    ) {
      setRequestParams((prevState) => ({ ...prevState, isOwned: true }));
      setRequestParams((prevState) => ({ ...prevState, researchArea: [] }));
      setSelectedOptions([]);
    } else {
      setRequestParams((prevState) => ({ ...prevState, isOwned: false }));
    }

    setSelectedSideMenu({
      type:
        pathname === "/research-scholar/home" || pathname === "/funding-organization/home"
          ? "research_scholar.home"
          : pathname === "/research-institute/requests"
          ? "research_institute.requests"
          : "admin.challenges",
    });
  }, [pathname]);

  const handleChallengeStatus = () => {
    if (permissions) {
      if (userInfo?.userType === USERS.Organization) {
        setChallengeFilterInfoItem("researchAreaId", []);
        setChallengeFilterInfoItem("selectedResearchAreas", []);
        setActiveTab(challengeFilterInfo?.activeTab ?? CHALLENGE_TYPES.All);
      } else if (enableAllContent) {
        setActiveTab(challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.All);
        setRequestParams((prevState) => ({
          ...prevState,
          status: challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.All,
        }));
      } else if (permissions.Viewopenchallenges) {
        setActiveTab(challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.Open);
        setRequestParams((prevState) => ({
          ...prevState,
          status: challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.Open,
        }));
      } else if (permissions.Viewlivechallenges) {
        setActiveTab(challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.Live);
        setRequestParams((prevState) => ({
          ...prevState,
          status: challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.Live,
        }));
      } else if (permissions.Viewclosedchallenges) {
        setActiveTab(challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.Close);
        setRequestParams((prevState) => ({
          ...prevState,
          status: challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.Close,
        }));
      }
    }
  };

  useEffect(() => {
    handleChallengeStatus();
  }, [permissions, userInfo]);

  function handleChallengeClick(index: number) {
    setSelectedUserType();
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: location.pathname,
      index: pageParam,
    });
  }
  useEffect(() => {
    if (
      organizationFilterInfo?.pathName &&
      location.pathname !== organizationFilterInfo?.pathName
    ) {
      clearOrganizationFilterInfo();
    }
  }, [organizationFilterInfo?.pathName]);

  const handleRadioTypeChange = (value: boolean | string, item: number) => {
    queryClient.resetQueries(["challenge-list"]);
    setRequestParams((prevstate) => ({
      ...prevstate,
      isGlobal: value,
    }));
    setUserTypeActiveFilter(item);
    setUserType(item);
  };

  const handleExploreClick = () => {
    clearUserTypeFilter();
    clearFilterDataStore();
    navigate("explore");
  };

  const handlePath = (item : ChallengeData) => {
    if(userInfo?.userType === USERS.Organization){
      if(item?.statusId === CHALLENGE_STATUS.Draft){
        return `/organization/challenges/create/${item?.id}`;
      }else if(item?.statusId === CHALLENGE_STATUS.Pending){
        return `/organization/challenges/${item?.id}/preview`
      }
    }
    return "";
  };

  useEffect(() => {
    if (scrollInfo?.pathName && location.pathname !== scrollInfo?.pathName) {
      resetScroll();
      handleRadioTypeChange("", GLOBAL_PORTAL_FILTERS.Both);
    }
  }, [scrollInfo?.pathName]);

  return displayContent ? (
    <div className="w-full h-full flex">
      <Header
        title={
          pathname === "/research-scholar/home" ||
          pathname === "/organization/challenges" ||
          pathname === "/funding-organization/home" ||
          pathname === "/research-institute/requests"
            ? `${"research_scholar.my_challenges"}`
            : `${"admin.challenges"}`
        }
        handleCollapseClick={onToggleClick}
        className={
          scroll
            ? " bg-primary-medium lg:bg-white  animate__animated animate__fadeInDown lg:shadow-sticky   lg:!h-[90px] fixed stickyHeader smaller-devices-section-challenge"
            : "fixed bg-primary-medium lg:bg-gray-light smaller-devices-section-challenge"
        }
      />
      <div className=" adminDetails w-full pt-[118px] pb-10 items-baseline px-4 lg:px-10 lg:ltr:pr-[30px] lg:rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto max-[1025px]:overflow-x-hidden ">
        {challengeDetails?.challenges?.length === 0 &&
        requestParams.status === CHALLENGE_STATUS.All &&
        requestParams.type === CHALLENGE_TYPES.All &&
        userInfo?.userType === USERS.Organization &&
        permissions?.Createchallenge &&
        !challengeListLoading &&
        userTypeActiveFilter === GLOBAL_PORTAL_FILTERS.Both ? (
          <div className="flex space-x-4">
            <SecondaryButton
              title={"organization.create_your_first_challenge"}
              handleClick={handleCreateChallenge}
              endIcon="addChallenge"
              className="bg-white w-[262px] h-[88px] !text-base font-medium flex gap-4 p-5 rounded-md flex-nowrap !justify-start text-left"
            />
            <SecondaryButton
              className="bg-white w-[262px] h-[88px] !text-base font-medium flex gap-4 p-5 rounded-md flex-nowrap !justify-start text-left"
              startIcon="exploreChallenge"
              title={"organization.explore_challenge"}
              handleClick={handleExploreClick}
            />
          </div>
        ) : permissions &&
          (permissions?.Viewopenchallenges ||
            permissions?.Viewclosedchallenges ||
            permissions?.Viewlivechallenges) ? (
          <>
            <div className="tabContainer w-full   flex items-baseline tabMobileView tab-mobile-view">
              <FilterTab
                filterOptions={challengeFilterItems}
                activeTab={activeTab}
                handleClick={(value) => handleTabChange(value)}
              />
              {userInfo?.userType === USERS.Organization ? (
                <FilterPopup
                  filterOptions={challengeListItems}
                  activeFilter={activeFilter}
                  handleClick={(selectedFilterId) => handleFilterChange(selectedFilterId)}
                />
              ) : null}
            </div>
            {!userInfo?.isGlobal || userInfo?.userType === USERS.Admin ? (
              <div className="lg:ms-auto -mt-[66px] lg:-mt-[28px] mb-[66px] lg:mb-0 -ml-3 lg:ml-0 relative -left-2.5 lg:left-0">
                <PortalFilter
                  activeFilter={userTypeActiveFilter}
                  handleRadioTypeChange={handleRadioTypeChange}
                />
              </div>
            ) : null}
            {userInfo?.userType !== USERS.Organization &&
            pathname !== "/research-scholar/home" &&
            pathname !== "/funding-organization/home" &&
            pathname !== "/research-institute/requests" ? (
              <div className="flex flex-col lg:flex-row lg:space-x-[46px] space-x-0 rtl:space-x-reverse w-full pt-[22px]">
                <div className="min-w-[288px] lg:min-w-[482px]">
                  <div className=" relative">
                    <MultiSelect
                      labelText={t("research_scholar.challenge_search_areas")}
                      hintText={t("research_scholar.add_research_areas")}
                      setSelectedOptions={handleSelectedOptions}
                      selectedOptions={selectedOptions}
                      loadOptions={loadResearchAreaOptions ?? []}
                      isDisabled={selectedOptions.length > 4}
                    />
                  </div>
                </div>
                {selectedOptions?.length > 0 ? (
                  <div className="w-full mt-6 md:mt-0">
                    <Title
                      className=" text-primary-light text-sm leading-4 font-normal mb-2 md:mb-6"
                      title={t("global.selected_areas")}
                    />
                    <div className="flex flex-wrap mb-4 lg:mb-0">
                      {selectedOptions.map((option) => (
                        <Chip
                          key={option.id}
                          className="chipPrimary"
                          text={i18n.language === "en" ? option.name.en : option.name.ar}
                          handleClose={() => handleRemoveSelectedOption(option)}
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="tab-content pt-6 w-full">
              <div className=" flex justify-between pb-5 items-center">
                <Title
                  className=" text-primary-light text-sm leading-4 font-normal"
                  title={`${
                    challengeList?.pages[0]?.totalCount
                      ? challengeList?.pages[0]?.totalCount
                      : t("global.no")
                  } ${t("organization.results_found")}`}
                />
                <div className="flex gap-4">
                  {userInfo?.userType === USERS.Organization && (
                    <>
                      {permissions?.Explorechallenges ? (
                        <PrimaryButton
                          className="bg-white border-none !text-black hover:!bg-gradient-blueDark hover:!text-white"
                          startIcon="exploreChallenge"
                          title={"organization.explore_challenge"}
                          handleClick={handleExploreClick}
                        />
                      ) : null}
                      {permissions?.Createchallenge ? (
                        <SecondaryButton
                          title={"organization.create_new_challenge"}
                          handleClick={handleCreateChallenge}
                          startIcon="addMilestone"
                          className="!bg-transparent space-x-1 rtl:space-x-reverse"
                        />
                      ) : null}
                    </>
                  )}
                </div>
              </div>

              {challengeDetails?.challenges?.length > 0 ? (
                <InfiniteScroll
                  dataLength={challengeDetails?.challenges?.length}
                  next={fetchNextPage}
                  hasMore={!!hasNextPage}
                  loader={hasNextPage || challengeListLoading ? <InlineLoader /> : null}
                  scrollThreshold="200px"
                >
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                    {challengeDetails.challenges.map((item: ChallengeData, index: number) => (
                      <div key={index} className="">
                        <ChallengeCard
                          data={item}
                          showChallengeSignature={showChallengeSignature}
                          path={handlePath(item)}
                          handleChallengeClick={() => handleChallengeClick(index)}
                        />
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              ) : null}
              {!challengeListLoading && challengeDetails?.challenges?.length === 0 ? (
                <div>
                  <NoData message={"admin.no_challenges_to_show"} />
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        {challengeListLoading ? <LoaderModal /> : null}
      </div>
    </div>
  ) : (
    <NoPermissionCard />
  );
}
