import { useTranslation } from "react-i18next";
import { Divider } from "components";
import { useOutsideClick } from "hooks/useOutsideClick";
import SVGIcon from "assets/Icons";

type DeclineButtonProps = {
  handleDeclineAndRemove: () => void;
  handleDeclineWithReason: () => void;
};

export function DeclineButton({
  handleDeclineAndRemove,
  handleDeclineWithReason,
}: DeclineButtonProps) {
  const { t } = useTranslation();
  const { isOpen, setIsOpen, dropDownRef } = useOutsideClick();

  return (
    <>
      <div
        className="flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        ref={dropDownRef}
      >
        <button className="btn rounded-md hover:bg-primary-medium hover:text-white w-full bg-transparent border border-primary-medium text-primary-medium text-sm font-medium">
          {t("global.decline")}
          <div className="ltr:ml-5 rtl:mr-5">
            <SVGIcon name={`${isOpen ? "upArrow" : "downArrow"}`} />
          </div>
        </button>
      </div>
      <div
        className={`${isOpen ? "" : "hidden"
          } z-50 absolute w-[170px] h-[90px] bg-white top-[76px] bottom-auto mb-[2px] ltr:left-3 rtl:right-3 text-black rounded-md shadow-400`}
      >
        <div
          className="flex gap-[10px] cursor-pointer px-[18px] py-3 hover:bg-gradient-blueDark hover:text-white rounded-md"
          onClick={handleDeclineAndRemove}
        >
          <span className="font-normal text-[13px] ">{t("admin.decline_and_remove")}</span>
        </div>
        <Divider className=" text-[#E8F1F6] px-[18px]" />
        <div
          className="flex gap-[10px] cursor-pointer px-[18px] py-3 hover:bg-gradient-blueDark hover:text-white rounded-md"
          onClick={handleDeclineWithReason}
        >
          <span className="font-normal text-[13px] ">{t("admin.decline_with_reason")}</span>
        </div>
      </div>
    </>
  );
}
