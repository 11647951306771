import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DashBoardCards, InlineLoader, LoaderModal, StatisticsDataCard, Header } from "components";
import { useGetDashboard } from "features/Organization/api/useGetDashboard";
import { useStickyScroll } from "hooks/useStickyScroll";
import { setSelectedSideMenu } from "store/useSidebarStore";
import { useEffect, useMemo } from "react";
import { clearChallengeStore } from "store/useChallengeStore";

import { useMasterDataStore } from "store/useMasterDataStore";

export default function OrganizationDashboard() {
  const { t } = useTranslation();
  const { permissions } = useMasterDataStore();
  const navigate = useNavigate();
  const { scroll } = useStickyScroll();
  const { data: organizationDashboard, isLoading } = useGetDashboard();
  const staticCardContent: Array<{
    id: number;
    name: ORGANIZATION_DASHBOARD_TYPE;
    count: number;
  }> = permissions?.Viewprivatereplies
      ? [
        {
          id: 1,
          name: "Users",
          count: organizationDashboard?.organizationUsersCount,
        },
        {
          id: 2,
          name: "Challenges",
          count: organizationDashboard?.challengesCount,
        },
        {
          id: 3,
          name: "Discussion forums",
          count: organizationDashboard?.organizationMessages,
        },
      ]
      : [
        {
          id: 1,
          name: "Users",
          count: organizationDashboard?.organizationUsersCount,
        },
        {
          id: 2,
          name: "Challenges",
          count: organizationDashboard?.challengesCount,
        },
      ];

  const dashBoardCardContents = useMemo(() => {
    const tempItems = [
      {
        id: 1,
        svgName: "usersIcon",
        title: "admin.users",
        content: "organization.invite_members_from_organization",
      },
    ];

    if (permissions?.Createchallenge) {
      tempItems.push({
        id: 2,
        svgName: "challengeIcon",
        title: "admin.challenges",
        content: "organization.create_challenge",
      });
    }
    if (permissions?.Viewprivatereplies) {
      tempItems.push({
        id: 3,
        svgName: "discussionIcon",
        title: "organization.messages",
        content: "organization.manage_all_your_forums",
      });
    }
    return tempItems;
  }, [permissions]);

  const handleCardClick = (id: number) => {
    if (id === 1) {
      navigate("/organization/settings/users");
    } else if (id === 2) {
      navigate("/organization/challenges");
    } else if (id === 3) {
      navigate("/organization/messages");
    }
  };
  const handleDashboardCardClick = (id: number) => {
    if (id === 1) {
      navigate("/organization/settings/users");
    } else if (id === 2) {
      clearChallengeStore();
      navigate("/organization/challenges/create");
    } else if (id === 3) {
      navigate("/organization/messages");
    }
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.dashboard",
    });
  }, []);
  return (
    <>
      <div className=" w-full h-full flex flex-col">
        <Header
          title={"global.dashboard"}
          className={
            scroll
              ? " bg-white animate__animated animate__fadeInDown shadow-sticky !py-[26px] !h-[90px] fixed stickyHeader"
              : "fixed"
          }
        />
        <div className="px-10 h-full flex flex-col pt-[118px] pb-10">
          <div className=" pt-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-[10px] text-white selectedCard relative">
              {staticCardContent?.length > 0 ? (
                staticCardContent?.map((item, index) => {
                  return (
                    <StatisticsDataCard
                      key={`${item.name}-${index}-${item.count}`}
                      data={{
                        title: item.name,
                        value: item.count,
                        type: item.name,
                      }}
                      handleClick={() => handleCardClick(item.id)}
                      isActiveUsers={true}
                      subtitle={item.name}
                      disabled={false}
                    />
                  );
                })
              ) : (
                <InlineLoader />
              )}
            </div>
          </div>
          <h2 className="text-black text-base leading-normal font-semibold mb-[30px] mt-[30px]">
            {t("admin.get_started")}
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-[10px] text-white selectedCard !min-h-fit relative">
            {dashBoardCardContents.map((item) => (
              <DashBoardCards
                key={item.id}
                id={item.id}
                svgName={item.svgName}
                title={item.title}
                content={item.content}
                handleClick={(id) => handleDashboardCardClick(id)}
              />
            ))}
          </div>
        </div>
      </div>
      {isLoading ? <LoaderModal /> : null}
    </>
  );
}
