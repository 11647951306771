import Joi from "joi";
import JoiPhoneNumber from "joi-phone-number";
import { groupBy } from "utils/helpers";
import { EMAIL_VALIDATION_PATTERN, INVALID_CHARACTER_VALIDATION_PATTERN } from "utils/constants";

const JoiWithPhoneNumber = Joi.extend(JoiPhoneNumber);
const fundingOrganization = Joi.object({
  organizationName: Joi.string().required().messages({
    "string.empty": "required",
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .pattern(EMAIL_VALIDATION_PATTERN)
    .trim()
    .required()
    .messages({
      "string.empty": "required",
      "string.email": "global.invalid_email_error_message",
      "string.pattern.base": "global.invalid_email_error_message",
    }),
  organizationType: Joi.number().required().disallow(0).messages({
    "string.empty": "required",
  }),
  countryCodeId: Joi.number().required().disallow(0).messages({
    "string.empty": "required",
  }),
  contactNumber: JoiWithPhoneNumber.string()
    .trim()
    .min(5)
    .when("countryCode", {
      is: 236, // UAE country code
      then: JoiWithPhoneNumber.string().phoneNumber({
        defaultCountry: "AE",
        format: "national",
        //strict: true,
      }),
      otherwise: JoiWithPhoneNumber.string().phoneNumber({
        defaultCountry: "US",
        format: "international",
      }), // Default phone number validation
    })
    .custom((value: string, helpers: any) => {
      if (!INVALID_CHARACTER_VALIDATION_PATTERN.test(value)) {
        return helpers.error("string.invalidCharacters", { v: value });
      }
      return value;
    }, "Custom validation for characters")
    .custom((value: string, helpers: any) => {
      const numbersOnly = value.replace(/\D/g, "");
      if (numbersOnly?.length < 5) {
        return helpers.error("string.min", { v: value });
      }
      return value;
    }, "Custom Validation")
    .custom((value: string, helpers: any) => {
      const stack = [];
      for (let i = 0; i < value.length; i++) {
        const char = value[i];
        if (char === "(") {
          stack.push(char);
        } else if (char === ")") {
          if (stack.length === 0) {
            return helpers.error("string.unbalancedParentheses", { v: value });
          }
          stack.pop();
        }
      }
      if (stack.length > 0) {
        return helpers.error("string.unbalancedParentheses", { v: value });
      }
      return value;
    }, "Custom validation for balanced parentheses")
    .required()
    .messages({
      "string.empty": "required",
      "string.pattern.base": "global.invalid_contact_number_error_message",
      "string.invalidCharacters": "global.invalid_special_character_error_message",
      "string.unbalancedParentheses": "global.phone_parenthesis_error_message",
      "string.min": "global.phone_min_character_error_message",
      "phoneNumber.invalid": "global.invalid_contact_number_error_message",
    }),
}).options({ abortEarly: false });

export const fundingOrganizationInviteValidation = (contact: FundingOrganizationBasicFields) => {
  const result = fundingOrganization.validate(contact);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
