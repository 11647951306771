import { useInfiniteQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { PAGE_LIMIT } from "utils/constants";
import { urls } from "utils/urls";

export async function getPosts({ pageNumber, pageLimit, challengeId }: PostsParams) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.forumPosts(challengeId, pageNumber, pageLimit), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res?.data?.data as PostList | ApiError;
}

export function useGetPosts(challengeId: number) {
  return useInfiniteQuery(
    ["forum-posts", challengeId],
    async ({ pageParam = 1 }) => {
      const result = await getPosts({
        pageNumber: pageParam,
        pageLimit: PAGE_LIMIT,
        challengeId,
      });
      return result;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.totalPages > allPages.length ? allPages?.length + 1 : undefined;
      },
      staleTime: 10 * 1000,
    }
  );
}
