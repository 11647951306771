import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function sendResetPasswordLink(userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(
    urls.adminSendResetPasswordLink(userId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus | ApiError;
}

export function useSendResetPasswordLink() {
  return useMutation(async (userId: string) => {
    return await sendResetPasswordLink(userId);
  });
}
