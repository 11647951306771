import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useUserDataStore } from "store/useUserDataStore";

export function PrivateRoute({ children }: { children: ReactElement }) {
  const { userInfo } = useUserDataStore();
  if (!userInfo) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" state={{ from: window.location }} />;
  }
  // authorized so return child components
  return children;
}
