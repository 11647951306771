import { useQuery } from "@tanstack/react-query";
import { useCollaborationDataStore } from "store/useCollaborationDataStore";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getCollaborationMilestones(collaborationId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.collaborationMilestones(collaborationId), {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res.data?.data as CollaborationMileStone;
}

export function useGetCollaborationMilestones() {
  const collaborationId = useCollaborationDataStore.getState().collaborationInfo?.id;

  return useQuery(
    ["collaboration-milestones", collaborationId],
    async () => {
      if (collaborationId) {
        const result = await getCollaborationMilestones(collaborationId);
        return result;
      } else return null;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
