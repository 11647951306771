import { DropdownItem } from "components";

type PopupProps = {
  items: Array<DropdownListItem>;
  handleClick: (type: string) => void;
};
export function Popup({ items, handleClick }: PopupProps) {
  return (
    <>
      {items?.map((item) => (
        <li key={item.label} className="mb-3 pt-3">
          <DropdownItem
            icon={item.icon}
            label={item.label}
            handleSelection={handleClick}
            type={item.type}
          />
        </li>
      ))}
    </>
  );
}
