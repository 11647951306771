import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function sendMessage(formData: ChatDetails) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(urls.chat(), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useSendMessage() {
  const qc = useQueryClient();
  return useMutation(async (formData: ChatDetails) => {
    const result = await sendMessage(formData);
    qc.invalidateQueries(["messages"]);
    return result;
  });
}
