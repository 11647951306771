import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { useMasterDataStore } from "store/useMasterDataStore";
import {
  SingleSelect,
  SubTitle,
  InputField,
  Title,
  SecondaryButton,
  PrimaryButton,
  LoaderModal,
  CheckBox,
} from "components";
import {
  DEFAULT_MAX_CHAR_LENGTH,
  EMAIL_MAX_CHAR_LENGTH,
  PHONE_NUMBER_MAX_CHAR_LENGTH,
  CREATE_ORGANIZATION_INIT_STATE,
} from "utils/constants";
import { organizationCreationValidation } from "utils/validation/organizationCreationValidation";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { useGetOrganizationTypes } from "features/Admin/api/useGetOrganizationTypes";
import { useOrganizationIndividualInvite } from "features/Admin/api/useOrganizationIndividualInvite";
import { setMasterDataValue } from "utils/helpers";

type IndividualInviteProps = {
  onCancel: () => void;
};

export default function IndividualInvite({ onCancel }: IndividualInviteProps) {
  const { t, i18n } = useTranslation();

  const [formState, setFormState] = useState<OrganizationCreation>({
    ...CREATE_ORGANIZATION_INIT_STATE,
    isGlobal: false,
  });
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const { mutateAsync: individualInvite, isLoading: isLoadingIndividualInvite } =
    useOrganizationIndividualInvite();

  const { countryCode } = useMasterDataStore();
  const { data: organizationTypes, isLoading: isOrganizationTypesLoading } =
    useGetOrganizationTypes();

  const countryCodeOptions = useMemo(() => {
    return countryCode?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [countryCode, i18n.language]);

  const organizationTypeOptions = useMemo(() => {
    return organizationTypes?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [organizationTypes, i18n.language]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof OrganizationCreation
  ) => {
    const value = event?.target?.value;
    setFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSelectChange = (value: string | number, key: keyof OrganizationCreation) => {
    setFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleProceed = async () => {
    const error = organizationCreationValidation(formState);
    if (error) {
      setValidationErrors(error);
      return;
    }
    setValidationErrors(null);
    try {
      const formData = prepareFormData();
      const result = await individualInvite(formData);
      onCancel();
      showSuccessToastMessage({
        message: result.message,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error.response.data.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const prepareFormData = () => {
    const data: OrganizationCreation = {
      organizationName: formState?.organizationName?.trim(),
      organizationType: formState?.organizationType,
      email: formState?.email?.trim(),
      contactNumber: formState?.contactNumber?.trim(),
      countryCodeId: formState?.countryCodeId,
      tradingLicenseNumber: formState?.tradingLicenseNumber?.trim(),
      isGlobal: formState.isGlobal,
    };
    return data;
  };

  const handleCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isGlobal = event.target.checked;
    setFormState((previousState) => ({ ...previousState, isGlobal }));
  };

  return (
    <div className="fixed h-screen w-screen bg-black/[0.3] z-[99] flex justify-center items-center top-0 left-0 ">
      <div className="animate__animated animate__zoomIn flex flex-col rounded-md items-center bg-white max-w-[470px] mx-2 sm:w-[470px] py-[50px] px-8 max-h-[calc(100vh-3.125rem)] overflow-auto">
        <div>
          <SVGIcon name="individualInvite" />
        </div>
        <Title
          className="mt-10 text-black text-xl font-semibold text-center"
          title={t("admin.individual_invite")}
        />
        <SubTitle
          className="mt-[6px] text-primary-text font-normal text-base text-center"
          title={t("admin.individual_invite_organization_message")}
        />
        <div className="grid grid-cols-1 gap-x-4 gap-y-[22px] mt-[30px] w-full">
          <InputField
            hintText={t("organization.enter_organization_name")}
            labelText={t("organization.organization_name")}
            handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(event, "organizationName")
            }
            isRequired
            fieldName="organizationName"
            errorMessage={validationErrors?.organizationName}
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
          />
          <SingleSelect
            className="text-sm font-normal"
            options={organizationTypeOptions || []}
            labelText={t("organization.organization_type")}
            hintText={t("global.select")}
            handleChange={(value: string | number) => handleSelectChange(value, "organizationType")}
            isRequired
            errorMessage={validationErrors?.organizationType}
          />
          <InputField
            hintText={t("organization.enter_trade_license_number")}
            labelText={t("organization.trade_license_number")}
            handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(event, "tradingLicenseNumber")
            }
            isRequired
            fieldName="tradingLicenseNumber"
            errorMessage={validationErrors?.tradingLicenseNumber}
            maxCharLength={DEFAULT_MAX_CHAR_LENGTH}
          />
          <InputField
            hintText={t("global.enter_email_address")}
            labelText={t("global.email_address")}
            handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(event, "email")
            }
            fieldName="email"
            isRequired
            className="col-span-full"
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
            errorMessage={validationErrors?.email}
          />
          <SingleSelect
            className="text-sm font-normal"
            options={countryCodeOptions || []}
            labelText={t("global.country_code")}
            hintText={t("global.select")}
            handleChange={(value: string | number) => handleSelectChange(value, "countryCodeId")}
            isRequired
            errorMessage={validationErrors?.countryCodeId}
          />
          <InputField
            hintText={t("global.enter_contact_number")}
            labelText={t("global.contact_number")}
            handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(event, "contactNumber")
            }
            fieldName="contactNumber"
            isRequired
            className="col-span-full"
            maxCharLength={PHONE_NUMBER_MAX_CHAR_LENGTH}
            errorMessage={validationErrors?.contactNumber}
          />
          <CheckBox
            option={{
              label: t("admin.global_invite"),
              value: "global_invite",
            }}
            className="p-2 px-0 text-black customCheckboxV2"
            handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleCheckBoxClick(event);
            }}
            checked={formState.isGlobal}
          />
        </div>
        <div className=" flex w-full justify-center space-x-[10px] rtl:space-x-reverse  mt-12 ">
          <SecondaryButton
            title="global.close"
            handleClick={handleCancel}
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
          />
          <PrimaryButton
            title="global.send_invite"
            handleClick={handleProceed}
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
          />
        </div>
      </div>
      {isLoadingIndividualInvite || isOrganizationTypesLoading ? <LoaderModal /> : null}
    </div>
  );
}
