import { useEffect, useState } from "react";
import { InlineLoader, NoData } from "components";
import { CreatePost } from "features/Collaboration/DiscussionForum/Posts/CreatePost";
import ForumHeader from "features/Collaboration/DiscussionForum/ForumHeader";
import Posts from "features/Collaboration/DiscussionForum/Posts";
import { useGetCollaborationBasicDetails } from "features/Collaboration/api/useGetCollaborationBasicDetails";

type CollaborationForumProps = {
  collaborationId: number;
  resetTabs: () => void;
};

export default function CollaborationForum({ collaborationId, resetTabs }: CollaborationForumProps) {
  const pathName = window.location.pathname;
  const isExplore = pathName.includes("explore");
  const { data: collaborationData, isLoading } = useGetCollaborationBasicDetails(
    collaborationId,
    isExplore
  );

  const [showCreatePost, setShowCreatePost] = useState(false);

  
  useEffect(() => {
    if (collaborationData?.isDiscussionForumEligible === false || collaborationData?.hasPostedOrReplied === false) {
      resetTabs();
    }
  }, [collaborationData?.isDiscussionForumEligible, collaborationData?.hasPostedOrReplied]);

  return (
    <div className=" w-full h-full" key={collaborationId}>
      {collaborationData ? (
        <ForumHeader
          collaborationData={collaborationData}
          handleCreatePost={() => setShowCreatePost((prevState) => !prevState)}
          showCreatePost={!showCreatePost}
        />
      ) : null}
      <div className="chatReplayHolder">
        {showCreatePost && collaborationData?.isDiscussionForumEligible ? (
          <CreatePost
            key={collaborationId}
            collaborationId={collaborationId}
            handleDiscardPost={() => setShowCreatePost((prevState) => !prevState)}
            handlePost={() => setShowCreatePost((prevState) => !prevState)}
          />
        ) : null}
        {collaborationId && collaborationData?.isDiscussionForumEligible ? (
          <Posts
            collaborationId={collaborationId}
            collaborationData={collaborationData}
            isShowCreatePost={showCreatePost}
          />
        ) : null}
        {!collaborationId && !isLoading ? (
          <div className="p-5 bg-white w-full h-full rounded-md nodata">
            <NoData
              className="!mb-0 bg-gray-111 justify-center py-0"
              iconName="noMsg"
              message="forum.select_a_post_to_show_here"
            />
          </div>
        ) : null}
      </div>
      {isLoading ? <InlineLoader /> : null}
    </div>
  );
}
