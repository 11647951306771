import Joi from "joi";
import JoiPhoneNumber from "joi-phone-number";
import { groupBy } from "utils/helpers";
import { EMAIL_VALIDATION_PATTERN } from "utils/constants";

const JoiWithPhoneNumber = Joi.extend(JoiPhoneNumber);

const contactUsSchema = Joi.object({
  name: Joi.string()
    .trim()
    .required()
    .messages({ "string.empty": "required", "string.pattern.base": "required" }),
  organization: Joi.string()
    .trim()
    .optional()
    .allow("")
    .messages({ "string.pattern.base": "required" }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .pattern(EMAIL_VALIDATION_PATTERN)
    .trim()
    .required()
    .messages({
      "string.empty": "required",
      "string.email": "global.invalid_email_error_message",
      "string.pattern.base": "global.invalid_email_error_message",
    }),
  phoneNumber: JoiWithPhoneNumber.string().trim().max(30).messages({
    "string.empty": "required",
    "string.pattern.base": "required",
    "string.max": "required",
  }),
  message: Joi.string().trim().max(1000).default(null).messages({
    "string.max": "required",
  }),
}).options({ abortEarly: false });

export const contactUsValidation = (basicFormData: ContactUs) => {
  const result = contactUsSchema.validate(basicFormData);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
