type FilterButtonProps = {
  title: string;
  count: number;
  active?: boolean;
  handleClick: (event: any) => void;
  disabled?: boolean;
};

export function FilterButton({ title, count, active, handleClick, disabled }: FilterButtonProps) {
  return (
    <button
      className={`btn mr-2 my-[2px] group relative flex items-center flex-grow font-normal border text-[13px] normal-case   h-auto min-h-fit  px-2 py-[6px]  border-gray-900 bg-white rounded-full text-primary-light hover:!border-primary-medium hover:!bg-primary-medium hover:!text-white ${
        active ? " !border-primary-medium !bg-primary-medium !text-white" : ""
      }`}
      disabled={disabled}
      onClick={handleClick}
    >
      {title}
      <span
        className={`bg-primary-900 group-hover:!bg-white/20 group-hover:!text-white rounded-full text-xs font-medium h-[18px] px-[6px] min-w-[22px] justify-center flex items-center ltr:ml-[14px] rtl:mr-[14px]  ${
          active ? " !bg-white/20 !text-white" : ""
        }`}
      >
        {count}
      </span>
    </button>
  );
}
