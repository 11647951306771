import { Routes, Route } from "react-router-dom";
import { PageNotFound } from "components";
import AppOutlet from "components/AppOutlet";
import { PrivateRoute } from "router/PrivateRoute";
import ChangePassword from "features/ChangePassword";
import SearchList from "features/GlobalSearch/SearchList";

import ResearchScholarProfile from "features/ResearchScholar/Profile";
import ResearchScholarInfo from "features/Admin/Users/ResearchScholars/ResearchScholarInfo";
import OrganizationInfo from "features/Admin/Users/Organizations/OrganizationInfo";
import FundingOrganizationInfo from "features/Admin/Users/FundingOrganizations/FundingOrganizationInfo";

/* Research Institute */
import ResearchInstitute from "features/ResearchInstitute";
import ResearchInstituteProfile from "features/ResearchInstitute/Profile";
import ResearchInstituteInfo from "features/Admin/Users/ResearchInstitute/ResearchInstituteInfo";
import Approval from "features/ResearchInstitute/Approval";
import Researchers from "features/ResearchInstitute/Researchers";
import Requests from "features/ResearchInstitute/Requests";
import ViewRequests from "features/ResearchInstitute/Requests/ViewRequests";
import ResearchScholarDetails from "features/ResearchInstitute/Requests/ResearchScholarDetails";
import CreateCollaboration from "features/Collaboration/CreateCollaboration";
import ScholarPreview from "features/Collaboration/CollaborationDetails/ResearchScholarPreview";
import CollaborationInviteHistory from "features/Collaboration/CollaborationDetails/InviteHistory";
import ViewCollaborationRequests from "features/ResearchInstitute/Requests/ViewCollaborationRequests";
import CollaborationScholarDetails from "features/ResearchInstitute/Requests/CollaborationScholarDetails";

/* Challenge */
import ChallengeDashboard from "features/Challenge/Dashboard";
import ChallengeDetailsPreview from "features/Challenge/Preview";

/* Events */
import EventDashboard from "features/Events/Dashboard";
import EventPreview from "features/Events/EventPreview";

/*Collaborations*/
import CollaborationDashboard from "features/Collaboration/Dashboard";
import CollaborationDetailsPreview from "features/Collaboration/CollaborationDetailsPreview";
import CollaborationDetails from "features/Collaboration/CollaborationDetails";
import ExploreCollaborations from "features/ResearchInstitute/Collaboration/ExploreCollaborations";

import DiscussionForum from "features/DiscussionForum";
import Messages from "features/Messages";
import CollaborationDiscussionForum from "features/Collaboration/DiscussionForum";

export default function ResearchInstituteRoutes() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <ResearchInstitute />
          </PrivateRoute>
        }
      >
        <Route path="forums" element={<Messages />} />
        <Route
          path="settings"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route path="profile/:researchInstituteId" element={<ResearchInstituteProfile />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="approvals" element={<Approval />} />
        </Route>
        <Route
          path="challenges"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<ChallengeDashboard />} index />
          <Route
            path=":challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ChallengeDetailsPreview />} index />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>
        </Route>
        <Route
          path="search-results"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<SearchList />} index />
          <Route
            path="challenges/:challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ChallengeDetailsPreview />} index />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>
          <Route
            path="research-scholar/:researchScholarId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ResearchScholarInfo />} index />
            <Route path="profile" element={<ResearchScholarProfile />} />
          </Route>
          <Route path="organization/:organizationId" element={<OrganizationInfo />} />
          <Route
            path="funding-organization/:fundingOrganizationId"
            element={<FundingOrganizationInfo />}
          />
          <Route
            path="research-institute/:researchInstituteId"
            element={<ResearchInstituteInfo />}
          />
        </Route>
        <Route
          path="requests"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route index element={<Requests />} />
          <Route
            path=":challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ViewRequests />} index />
            <Route
              path="research-scholar/:researchScholarId"
              element={<ResearchScholarDetails />}
            />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>
          <Route
            path="collaborations/:collaborationId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ViewCollaborationRequests />} index />
            <Route
              path="research-scholar/:researchScholarId"
              element={<CollaborationScholarDetails />}
            />
            <Route path="discussion-forum" element={<CollaborationDiscussionForum />} />
          </Route>
        </Route>
        <Route
          path="researchers"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route index element={<Researchers />} />
          <Route
            path=":researchScholarId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ResearchScholarInfo />} index />
            <Route path="profile" element={<ResearchScholarProfile />} />
          </Route>
        </Route>
        <Route
          path="collaborations"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route index element={<CollaborationDashboard />} />
          <Route
            path="create"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route index element={<CreateCollaboration />} />
            <Route path=":collaborationId" element={<CreateCollaboration />} />
          </Route>
          <Route
            path=":collaborationId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route index element={<CollaborationDetails />} />
            <Route path="preview" element={<CollaborationDetailsPreview/>}/>
            <Route
              path="explore"
              element={
                <PrivateRoute>
                  <AppOutlet />
                </PrivateRoute>
              }
            >
              <Route element={<CollaborationDetails />} index />
              <Route path="research-scholar/:researchScholarId" element={<ScholarPreview />} />
              <Route path="update" element={<CreateCollaboration />} />
              <Route
                path="invite-history"
                element={
                  <PrivateRoute>
                    <AppOutlet />
                  </PrivateRoute>
                }
              >
                <Route element={<CollaborationInviteHistory />} index />
                <Route path="discussion-forum" element={<CollaborationDiscussionForum />} />
              </Route>
              <Route path="discussion-forum" element={<CollaborationDiscussionForum />} />
            </Route>
            <Route path="research-scholar/:researchScholarId" element={<ScholarPreview />} />

            <Route
              path="invite-history"
              element={
                <PrivateRoute>
                  <AppOutlet />
                </PrivateRoute>
              }
            >
              <Route element={<CollaborationInviteHistory />} index />
              <Route path="discussion-forum" element={<CollaborationDiscussionForum />} />
            </Route>

            <Route path="update" element={<CreateCollaboration />} />
            <Route path="discussion-forum" element={<CollaborationDiscussionForum />} />
          </Route>
          <Route
            path="explore"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route index element={<ExploreCollaborations />} />
            <Route
              path=":collaborationId"
              element={
                <PrivateRoute>
                  <AppOutlet />
                </PrivateRoute>
              }
            >
              <Route element={<CollaborationDetailsPreview />} index />
              <Route path="discussion-forum" element={<CollaborationDiscussionForum />} />
            </Route>
            <Route path="create" element={<CreateCollaboration />} />
          </Route>
        </Route>
        <Route
          path="events"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route index element={<EventDashboard />} />
          <Route path=":eventId" element={<EventPreview />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
