import { useMemo } from "react";
import { useGetPosts } from "features/DiscussionForum/api/useGetPosts";
import InfiniteScroll from "react-infinite-scroll-component";
import { InlineLoader, NoData } from "components";
import Post from "features/DiscussionForum/Posts/Post";

type PostsProps = {
  challengeId: number;
  challengeData: ChallengeBasicDetails | undefined;
  isShowCreatePost: boolean;
};

const Posts = ({ challengeId, challengeData, isShowCreatePost }: PostsProps) => {
  const { data, fetchNextPage, hasNextPage, isLoading } = useGetPosts(challengeId);

  const postDetails = useMemo(() => {
    const tempData: PostList = { forums: [], totalCount: 0, totalPages: 0 };
    if (data?.pages && data?.pages[0]) {
      tempData.totalCount = data.pages[0]?.totalCount;
      tempData.totalPages = data.pages[0]?.totalPages;
      const forums = data.pages
        .map((item) => {
          return item.forums;
        })
        .flat();
      tempData.forums = forums;
    }
    return tempData;
  }, [data]);
  return (
    <div className="w-full chatPostHolder">
      {postDetails && postDetails.totalCount && postDetails.totalCount > 0 ? (
        <InfiniteScroll
          dataLength={postDetails?.forums?.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={hasNextPage || isLoading ? <InlineLoader /> : null}
          scrollThreshold="200px"
        >
          {postDetails?.forums?.map((item: PostDetails, index: number) => (
            <Post
              key={item.id}
              data={item}
              challengeId={challengeId}
              isFirst={index === 0}
              challengeData={challengeData}
            />
          ))}
        </InfiniteScroll>
      ) : null}
      {postDetails && postDetails.totalCount === 0 && !isLoading && !isShowCreatePost ? (
        <div className="w-full h-full flex items-center min-h-[390px] noPost bg-white p-5 rounded-md">
          <NoData
            message="forum.no_posts_yet"
            className=" w-full !mb-0 bg-gray-111 justify-center py-0"
            iconName="emptyPosts"
          />
        </div>
      ) : null}
    </div>
  );
};
export default Posts;
