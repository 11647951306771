import { useMemo } from "react";
import { SubTitle, Title, Descriptor } from "components";
import { useMasterDataStore } from "store/useMasterDataStore";

type ContactDetailsProps = {
  fullName: string;
  contactNumber: string;
  countryCodeId: number;
  designation: string;
  email: string;
};

export function ContactDetailsCard({
  fullName,
  contactNumber,
  countryCodeId,
  designation,
  email,
}: ContactDetailsProps) {
  const { countryCode } = useMasterDataStore();
  const selectedCountryCode = useMemo(() => {
    return countryCode?.filter((item) => item.id == countryCodeId)?.[0];
  }, [countryCode, countryCodeId]);
  return (
    <div>
      <Title className=" text-black text-sm font-medium WB_BW" title={fullName} />
      <SubTitle
        className=" text-gray-102 font-normal mt-1.5 mb-2 text-sm !justify-start WB_BW"
        title={designation}
      />
      <div className="flex space-y-3 md:space-y-0 md:space-x-10 md:rtl:space-x-reverse truncate flex-col md:flex-row">
        <Descriptor
          startIcon="msg"
          title={email}
          className=" !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse truncate"
        />
        <Descriptor
          startIcon="phone"
          title={`${selectedCountryCode?.name?.code ?? ""} ${contactNumber ?? ""}`}
          className=" !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse truncate min-w-[160px]"
        />
      </div>
    </div>
  );
}
