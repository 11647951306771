import { Button } from "react-daisyui";
import SVGIcon from "assets/Icons";

type IconButtonProps = {
  handleClick: () => void;
  iconName: string;
  className?: string;
  disabled?: boolean;
};

export function IconTypeButton({ disabled, handleClick, className, iconName }: IconButtonProps) {
  return (
    <Button
      className={`btn btn-link text-primary-medium h-auto min-h-0 p-0 ltr:ml-[10px] rtl:mr-[10px] ${
        className ? className : ""
      }`}
      onClick={handleClick}
      disabled={disabled}
    >
      <SVGIcon name={iconName} />
    </Button>
  );
}
