import React, { useState, SyntheticEvent } from "react";
import Tippy from "@tippyjs/react";
import SVGIcon from "assets/Icons";
import { ErrorText } from "components";

type InputFieldProps = {
  hintText: string;
  labelText: string;
  fieldName: string;
  value?: string | number;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  inputType?: "password" | "text" | "number";
  children?: React.ReactNode;
  className?: string;
  errorMessage?: string;
  maxCharLength?: number;
  id?: string;
  isHIndexField?: boolean;
  tooltipContent?: Array<string>;
  handleInputPaste?: (event: SyntheticEvent) => void;
  handleKeyDown?: (event: React.KeyboardEvent) => void;
};

export function InputField({
  hintText,
  labelText,
  handleInputChange,
  isRequired,
  isDisabled,
  fieldName,
  value,
  inputType,
  children,
  className,
  errorMessage,
  maxCharLength,
  tooltipContent,
  handleInputPaste,
  handleKeyDown,
}: InputFieldProps) {
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(inputType !== "password");

  const toggleIcon = () => {
    setPasswordVisible((isVisible) => !isVisible);
  };
  const TooltipMessage = () => {
    return (
      <div className="max-w-[325px]">
        <div className=" flex items-center">
          <div className="flex flex-col pl-2">
            <ul className=" list-disc pl-4 tooltipList">
              {tooltipContent?.map((data: string, index: number) => (
                <li key={index} className={`mb-1 font-medium text-xs max-w-[250px] `}>
                  {data}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="form-control w-full relative">
      <label className="label p-0 pb-[8px]">
        <span className="text-primary-light text-sm flex justify-start items-center leading-4 font-normal whitespace-nowrap">
          <div>
            <span className=" ltr:float-left rtl:float-right"> {labelText}</span>{" "}
            {isRequired ? (
              <span className=" ltr:float-left rtl:float-right mr-1 ml-1 text-red-200">*</span>
            ) : null}
            {children ?? null}
            {tooltipContent ? (
              <Tippy
                placement="bottom"
                arrow={true}
                theme="light"
                appendTo="parent"
                allowHTML={true}
                content={<TooltipMessage />}
              >
                <div className="info !inline-block -mb-1.5 ltr:pl-1.5 rtl:pr-1.5">
                  <SVGIcon name="info" />
                </div>
              </Tippy>
            ) : null}
          </div>
        </span>
      </label>

      <input
        value={value}
        type={inputType === "password" && isPasswordVisible ? "text" : inputType}
        placeholder={hintText}
        autoComplete="new-password"
        className={`w-full placeholder:text-gray-1100 border-primary-light h-12 font-medium placeholder:!text-base  text-primary-text text-base outline-none focus:border-primary-medium placeholder:font-normal !px-[16px]  truncate  border bg-white rounded defaultInput ${className} ${errorMessage ? " !border-red-200 !border-[1.5px] errorField" : "border-primary-light "
          }`}
        disabled={isDisabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange(event, fieldName)
        }
        maxLength={maxCharLength}
        onPaste={(e) => {
          if (e && handleInputPaste) {
            handleInputPaste(e);
          }
        }}
        onKeyDown={(e) => {
          if (e && handleKeyDown) {
            handleKeyDown(e);
          }
        }}
      />
      {inputType === "password" ? (
        <div className=" absolute ltr:right-4 rtl:left-4 top-[40px]">
          <button
            type="button"
            className=" text-black"
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation();
              e.preventDefault();
              toggleIcon();
            }}
          >
            {isPasswordVisible ? <SVGIcon name="eyeOpen" /> : <SVGIcon name="eyeClose" />}
          </button>
        </div>
      ) : null}
      {errorMessage && errorMessage !== "required" ? (
        <ErrorText errorMessage={errorMessage} />
      ) : null}
    </div>
  );
}
