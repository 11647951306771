import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getDashboard() {
    const tokenInfo = useUserDataStore.getState().tokenInfo;
    const token = tokenInfo?.token;
    const res = await apiClient.get(urls.organizationDashboard(), {
        headers: {Authorization: `Bearer ${token}`},
    })
    return res?.data?.data as OrganizationDashboard | ApiError 
}

export function useGetDashboard() {
    const userInfo = useUserDataStore.getState().userInfo;
    const userId = userInfo?.userId;
    
    return useQuery(
      ["organization-dashboard", userId],
      async () => {
        return await getDashboard();
      }
    );
  }
  