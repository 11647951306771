import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { UploadButton, RichTextEditor, FileUploadList, LoaderModal, Label } from "components";
import { useUploadChallengeFile } from "features/Organization/api/useUploadChallengeDocument";
import { useGetChallengeArtifacts } from "features/Organization/api/useGetChallengeArtifacts";
import { useDeleteChallengeFileUpload } from "features/Organization/api/useDeleteChallengeFileUpload";
import { CHALLENGE_STATUS, MAX_FILE_SIZE } from "utils/constants";
import { showConfirmationModal } from "utils/confirmationModals";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import {
  defaultChallengeAdditionalInfo,
  setChallengeAdditionalInfo,
  setChallengeArtifacts,
  useChallengeStore,
} from "store/useChallengeStore";

type ArtifactsProps = {
  validationErrors: Record<string, string> | null;
};

const Artifacts = ({ validationErrors }: ArtifactsProps) => {
  const { t } = useTranslation();
  const { data: artifactsData, isLoading: isArtifactsLoading } = useGetChallengeArtifacts();
  const { mutateAsync: fileUpload, isLoading } = useUploadChallengeFile();
  const { mutateAsync: deleteFile } = useDeleteChallengeFileUpload();
  const { challengeInfo, additionalInfo } = useChallengeStore();
  const [isReset, resetEditor] = useState(false);

  useEffect(() => {
    if (artifactsData && artifactsData.information) {
      setChallengeArtifacts(artifactsData);
    } else {
      setChallengeArtifacts(defaultChallengeAdditionalInfo);
    }
    checkChangeInData();
  }, [artifactsData]);

  const checkChangeInData = () => {
    if (!artifactsData?.information) {
      resetEditor(true);
    } else if (additionalInfo.information.length !== artifactsData?.information?.length) {
      resetEditor(true);
    } else {
      resetEditor(false);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target?.files;
    if (selectedFiles && selectedFiles?.length > 0) {
      if (selectedFiles?.[0].size > MAX_FILE_SIZE) {
        event.target.value = "";
        return showErrorToastMessage({
          message: t("research_scholar.uploaded_files_size_error_message"),
        });
      }
      const fileName = selectedFiles?.[0].name;
      const isFileExist = additionalInfo.documents.find((item) => item.name === fileName);
      if (isFileExist) {
        showErrorToastMessage({
          message: t("research_scholar.multiple_same_name_file_error_message"),
        });
        event.target.value = "";
        return;
      }
      try {
        const fileDetails = additionalInfo.documents;
        setChallengeAdditionalInfo(
          fileDetails ? [...fileDetails, selectedFiles[0]] : [selectedFiles[0]],
          "documents"
        );
        const result = await fileUpload({
          file: selectedFiles[0],
          challengeId: challengeInfo.id,
          isCoverImage: false,
        });
        if (result) {
          setChallengeAdditionalInfo(
            fileDetails ? [...fileDetails, result] : [result],
            "documents"
          );
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 1000);
        }
      } catch (error) {
        showErrorToastMessage({
          message: t("global.something_went_wrong"),
        });
      } finally {
        event.target.value = "";
      }
    }
  };

  const handleRemove = async (event: React.MouseEvent<HTMLButtonElement>, item: FileDetails) => {
    event.stopPropagation();
    event.preventDefault();
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.deletion_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      if (item.id) {
        const formData = {
          id: item.id,
          name: item.name,
          fileUrl: item.fileUrl,
        };
        try {
          const response = await deleteFile({ formData });
          const files = additionalInfo.documents;
          const newFiles = files?.filter((file) => file.fileUrl !== item?.fileUrl);
          if (newFiles) {
            setChallengeAdditionalInfo(newFiles, "documents");
          }
          showSuccessToastMessage({
            message: response.message,
          });
        } catch (error: ApiError) {
          if (error.response.data.status === 400) {
            showErrorToastMessage({
              message: error.response.data.message,
            });
          } else {
            showErrorToastMessage({
              message: t("global.something_went_wrong"),
            });
          }
        }
      } else {
        const files = additionalInfo.documents;
        const newFiles = files?.filter((file) => file.fileUrl !== item?.fileUrl);
        if (newFiles) {
          setChallengeAdditionalInfo(newFiles, "documents");
        }
        showSuccessToastMessage({
          message: t("global.document_deleted_successfully"),
        });
      }
    }
  };
  const isUploadDisabled = additionalInfo.documents.length >= 5;

  return (
    <div>
      <div className=" flex items-center justify-between mb-6 flex-wrap pb-8 p-5 !py-3 border-b border-gray-light">
        <div className="flex items-center ltr:mr-auto rtl:ml-auto">
          <SVGIcon name="learnMore" />
          <Label
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            text={t("organization.artifacts")}
            tooltipContent={"research_scholar.you_can_upload_a_maximum_of_5_documents"}
          />
        </div>
        <UploadButton
          label="global.upload"
          icon="uploadV2"
          handleFileUpload={handleFileUpload}
          disabled={
            isUploadDisabled || challengeInfo?.challengeStatusId === CHALLENGE_STATUS.Pending
          }
        />
      </div>
      <div
        className={`border border-[#7890A1] rounded-md my-6 mx-5 ${
          validationErrors ? "!border-red-200 " : "border-primary-light"
        }`}
      >
        <RichTextEditor
          key={isReset ? "reset" : ""}
          text={additionalInfo.information}
          handleTextChange={(text) => {
            setChallengeAdditionalInfo(text, "information");
          }}
          placeholder={t("organization.additional_info_placeholder")}
          isDisabled={challengeInfo?.challengeStatusId === CHALLENGE_STATUS.Pending}
        />
      </div>
      <div className=" p-5 pt-0 mt-8">
        {additionalInfo.documents?.length ? (
          <FileUploadList
            fileList={additionalInfo.documents}
            handleRemove={handleRemove}
            disabled={challengeInfo?.challengeStatusId === CHALLENGE_STATUS.Pending}
            isFileUploading={isLoading}
          />
        ) : null}
      </div>
      {isLoading || isArtifactsLoading ? <LoaderModal /> : null}
    </div>
  );
};

export default Artifacts;
