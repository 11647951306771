import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getEventAbout(eventId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.get(urls.eventAbout(eventId), {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response?.data?.data as EventDetails | ApiError;
}

export function useGetAbout(eventId: number) {
  return useQuery(
    ["event-about", eventId],
    async () => {
      if (eventId) {
        const result = await getEventAbout(eventId);
        return result;
      } else return null;
    },
    {
      staleTime: Infinity,
    }
  );
}
