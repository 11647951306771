import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Header, LoaderModal } from "components";
import { Navigator } from "features/Events/CreateEvent/Navigator";
import LearnMore from "features/Events/CreateEvent/LearnMore";
import About from "features/Events/CreateEvent/About";
import { eventDetailsValidation } from "utils/validation/eventDetailsValidation";
import { showConfirmationModal, showEventPreviewModal } from "utils/confirmationModals";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import { EVENT_CREATION_COMPLETION_STATUS, EVENT_STATUS } from "utils/constants";
import { setEventDetailsInfoItem, useEventDataStore } from "store/useEventDataStore";
import { setSelectedFormId, setSelectedSideMenu, useSidebarDataStore } from "store/useSidebarStore";
import { useStickyScroll } from "hooks/useStickyScroll";
import { useSaveAbout } from "features/Events/api/useSaveAbout";
import { useGetAbout } from "features/Events/api/useGetAbout";
import { useGetLearnMore } from "features/Events/api/useGetLearnMore";
import { useSaveEventLearnMore } from "features/Events/api/useSaveLearnMore";

export default function CreateEvent() {
  const { eventId } = useParams();
  const { t } = useTranslation();
  const [stepNumber, setStepNumber] = useState<number>(EVENT_CREATION_COMPLETION_STATUS.About);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string> | null | Array<Record<string, string> | null | undefined>
  >(null);
  const { scroll } = useStickyScroll();
  const { detailsInfo, descriptionInfo } = useEventDataStore();
  const { selectedFormId } = useSidebarDataStore();

  useEffect(() => {
    setEventDetailsInfoItem(selectedFormId ?? 0, "id");
  }, [selectedFormId]);

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  function setIsUploading(status: boolean) {
    setIsFileUploading(status);
  }

  const { data: eventAboutInfo, isLoading: isEventAboutLoading } = useGetAbout(detailsInfo.id);
  const { mutateAsync: saveEventAbout, isLoading: isSavingEventAbout } = useSaveAbout();
  const { data: eventLearnMoreInfo, isLoading: isEventLearnMoreLoading } = useGetLearnMore(
    detailsInfo.id
  );
  const { mutateAsync: saveEventLearnMore, isLoading: isSavingEventLearnMore } =
    useSaveEventLearnMore();

  const isEdit =
    detailsInfo.eventStatusId && detailsInfo?.eventStatusId !== EVENT_STATUS.Draft ? true : false;

  const headerTitle = detailsInfo.id ? eventAboutInfo?.title : "admin.new_event";

  const linkList: Array<LinkItem> = [
    {
      title: "global.events",
    },
    {
      title: getBreadCrumbTitle(),
    },
  ];

  function getBreadCrumbTitle() {
    if (eventId) {
      return "admin.edit_event";
    } else {
      return "admin.new_event";
    }
  }

  const PROFILE_NAVIGATION_ITEM = [
    {
      value: EVENT_CREATION_COMPLETION_STATUS.About,
      label: "organization.about",
    },
    {
      value: EVENT_CREATION_COMPLETION_STATUS.LearnMore,
      label: "organization.learn_more",
    },
  ];

  const handleNext = async () => {
    if (stepNumber === 1) {
      const success = await handleSaveAboutEvent(true);
      if (success) moveToNext();
    } else if (stepNumber === 2) {
      try {
        const success = await handleSaveLearnMore(true);
        if (success) {
          showEventPreviewModal({
            eventId: detailsInfo.id,
            setNavigation: () => navigateNext(EVENT_CREATION_COMPLETION_STATUS.About),
            showPrimaryButton: true,
          });
        }
      } catch (error) {
        showErrorToastMessage({
          message: t("global.something_went_wrong"),
        });
      }
    }
  };

  const moveToNext = () => {
    if (stepNumber >= 1 && stepNumber < 2) {
      const currentStep = stepNumber;
      const nextStep = currentStep + 1;
      setStepNumber(nextStep);
    }
  };

  const handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (stepNumber === 1) {
      handleSaveAboutEvent();
    } else if (stepNumber === 2) {
      handleSaveLearnMore();
    }
  };

  const handleNavigationClick = async (step: number) => {
    if (stepNumber === step) return;
    let isSaveRequired = false;
    if (stepNumber === 1 && detailsInfo) {
      isSaveRequired = await checkUnsavedDetails(detailsInfo, eventAboutInfo);
    } else if (stepNumber === 2 && descriptionInfo) {
      isSaveRequired = checkUnsavedLearnMore(descriptionInfo, eventLearnMoreInfo);
    }

    showNavigationConfirmation(isSaveRequired, step);
  };

  const showNavigationConfirmation = async (show: boolean, step: number) => {
    if (show) {
      const confirm = await showConfirmationModal({
        title: "organization.confirm_proceed",
        message: "organization.challenge_unsaved_section_confirm",
        cancelButtonText: "global.close",
        proceedButtonText: "global.proceed",
        modalIcon: "confirmSelection",
        isAlert: true,
      });
      if (confirm) {
        navigateNext(step);
      }
    } else {
      navigateNext(step);
    }
  };

  const navigateNext = (step: number) => {
    setStepNumber(step);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const checkUnsavedDetails = async (currentData: EventDetails, savedData: EventDetails) => {
    if (JSON.stringify(currentData) === JSON.stringify(savedData)) {
      return false;
    } else {
      return true;
    }
  };

  const prepareAboutEventFormData = () => {
    return {
      id: detailsInfo.id,
      coverImage: detailsInfo.coverImage,
      title: detailsInfo.title.trim(),
      targetIndustry: detailsInfo.targetIndustry,
      location: detailsInfo.location.trim(),
      startDate: detailsInfo.startDate,
      endDate: detailsInfo.endDate,
      eventDescription: detailsInfo?.eventDescription?.trim(),
      websiteUrl: detailsInfo?.websiteUrl?.trim(),
    };
  };

  const handleSaveAboutEvent = async (isNext = false) => {
    const eventAboutDetails = useEventDataStore.getState().detailsInfo;
    if (eventAboutDetails) {
      const error = eventDetailsValidation(eventAboutDetails);
      if (error) {
        setValidationErrors(error);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return;
      }
      setValidationErrors(null);
      const isSaveRequired = await checkUnsavedDetails(eventAboutDetails, eventAboutInfo);

      if (isSaveRequired) {
        try {
          const formData = prepareAboutEventFormData();
          const result = await saveEventAbout({ formData });
          showInfoToastMessage({
            message: result?.message,
            iconType: ToasterIconType.UPDATE,
          });
          if (!formData.id) {
            const eventId = result.data.eventId;
            setSelectedFormId(eventId);
          }
          return true;
        } catch (error) {
          showErrorToastMessage({
            message: t("global.something_went_wrong"),
          });
        }
      } else if (isNext) {
        return true;
      }
    }
  };

  const checkUnsavedLearnMore = (
    currentTextContent: EventLearnMore,
    savedTextContent: EventLearnMore | undefined
  ): boolean => {
    if (!savedTextContent) return true;

    const currentLearnMore = currentTextContent.learnMore;
    const savedLearnMore = savedTextContent.learnMore;

    const currentDocuments = currentTextContent.documents;
    const savedDocuments = savedTextContent.documents;

    if (currentLearnMore !== savedLearnMore) {
      return true;
    }

    if (currentDocuments.length !== savedDocuments.length) {
      return true;
    }

    const currentDocumentNames = currentDocuments.map((item) => item.name);
    const savedDocumentNames = savedDocuments.map((item) => item.name);

    const areDocumentNamesEqual = currentDocumentNames.every((name) =>
      savedDocumentNames.includes(name)
    );

    return !areDocumentNamesEqual;
  };

  const prepareEventLearnMoreFormData = () => {
    return {
      id: descriptionInfo.id,
      learnMore: descriptionInfo.learnMore,
      documents: descriptionInfo.documents,
    };
  };

  const handleSaveLearnMore = async (isSubmit = false) => {
    const eventLearnMore = descriptionInfo;
    if (eventLearnMore || isSubmit) {
      const isSaveRequired = checkUnsavedLearnMore(eventLearnMore, eventLearnMoreInfo);

      if (isSaveRequired) {
        try {
          const formData = prepareEventLearnMoreFormData();
          const result = await saveEventLearnMore(formData);
          if (!isSubmit) {
            showInfoToastMessage({
              message: result?.data?.message,
              iconType: ToasterIconType.UPDATE,
            });
          }
          return true;
        } catch (error) {
          showErrorToastMessage({
            message: t("global.something_went_wrong"),
          });
        }
      } else {
        return true;
      }
    }
  };

  const handlePreview = async () => {
    await showEventPreviewModal({
      eventId: detailsInfo?.id,
      setNavigation: () => setStepNumber(EVENT_CREATION_COMPLETION_STATUS.About),
      showPrimaryButton: false,
    });
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.events",
    });
  }, []);

  return (
    <div className="w-full h-full flex">
      <Header
        linkList={linkList}
        title={headerTitle}
        className={
          scroll
            ? " bg-white animate__animated animate__fadeInDown shadow-sticky !py-[26px] !h-[90px] fixed stickyHeader"
            : "fixed"
        }
      />
      <div className=" adminDetails w-full mt-[170px] mb-20 lg:mb-0 lg:mt-0 lg:pt-[118px] pb-[45px] items-baseline px-4 lg:px-10 lg:min-h-screen flex flex-col  overflow-auto ltr:pr-[30px] rtl:pl-[30px]">
        <div className="w-full flex flex-col h-full ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 ltr:lg:pr-[292px] rtl:lg:pl-[292px] space-y-3">
          <div className=" flex-col bg-white w-full h-full rounded-none lg:rounded-md flex mb-auto">
            {stepNumber === 1 ? (
              <About validationErrors={validationErrors as Record<string, string> | null} />
            ) : null}
            {stepNumber === 2 ? (
              <LearnMore
                validationErrors={validationErrors as Record<string, string> | null}
                setIsUploading={setIsUploading}
              />
            ) : null}
          </div>
        </div>
        <div
          className={
            scroll
              ? "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto fixed top-[114px] lg:top-[100px]  ltr:lg:right-[30px] rtl:lg:left-[30px]  z-[5]  lg:shadow-sticky  animate__animated animate__fadeInDown animateNone lg:transition-all"
              : "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto fixed top-[114px] lg:top-auto ltr:lg:right-[30px] rtl:left-0 rtl:lg:left-[30px]  z-[5]"
          }
        >
          <Navigator
            handleNext={handleNext}
            handleDraft={handleSave}
            navigationItems={PROFILE_NAVIGATION_ITEM}
            isEdit={isEdit}
            stepNumber={stepNumber}
            completionStatusId={eventAboutInfo?.eventCompletionStatusId}
            handleNavigationClick={handleNavigationClick}
            handlePreview={handlePreview}
            isDisabled={isFileUploading}
            isPreview={false}
          />
        </div>
        {isEventAboutLoading ||
        isSavingEventAbout ||
        isEventLearnMoreLoading ||
        isSavingEventLearnMore ? (
          <LoaderModal />
        ) : null}
      </div>
    </div>
  );
}
