import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import JoinImageWeb from "assets/images/home-images/joinImageWeb.png";
import joinImageTab from "assets/images/home-images/joinImageTab.png";
import joinImageMobile from "assets/images/home-images/joinImageMobile.png";
import { SecondaryButton } from "components";
import { HOME_MENU_IDS } from "utils/constants";
import { isGlobalWebsite } from "utils/helpers";

type JoinUsProps = {
  handleClick: (id: number) => void;
};

const JoinUS = forwardRef(function JoinUS(
  { handleClick }: JoinUsProps,
  ref: React.Ref<HTMLDivElement>
) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isGlobal = isGlobalWebsite();

  const handleSignUpNavigation = () => {
    navigate("/research-scholar/sign-up");
  };
  return (
    <div className="joinWrapper px-5 md:px-10" ref={ref}>
      <div className="mx-auto w-full max-w-[1280px] ">
        <div className="overflow-hidden rounded-xl bg-white joinWrapperHolder flex items-end relative">
          <div className="hidden xl:block  xl:min-w-[477px]  rtl:scale-x-[-1]">
            <img alt="Join image web" src={JoinImageWeb} className="w-full" />
          </div>
          <div className="hidden md:block xl:hidden  relative order-1 ltr:-ml-[322px] rtl:-mr-[322px] ltr:right-0 rtl:left-0 rtl:scale-x-[-1] min-w-[322px] ">
            <img alt="Join image tab" src={joinImageTab} className="w-full" />
          </div>
          <div className="block md:hidden relative order-1 ltr:-ml-[227px]  rtl:-mr-[227px]  ltr:right-0 rtl:left-0  min-w-[227px] rtl:scale-x-[-1]">
            <img alt="Join image mobile" src={joinImageMobile} className="w-full" />
          </div>
          <div className="pb-[150px] p-[30px] xl:pr-[140px] md:p-10 xl:p-[60px] space-y-[20px] md:space-y-[30px] mb-auto">
            <h2 className="font-semibold text-skin-primaryDark text-[18px] leading-[26px] tracking-[-0.18px]  md:text-[23px] md:leading-8 md:tracking-[-0.23px] xl:text-2xl xl:leading-[34px] xl:tracking-[-0.24px]">
              {t("home.get_involved_in")}{" "}
              <span className=" text-skin-textSecondary">
                {t("home.ground_breaking_research_initiatives")}
              </span>{" "}
              {t("home.that_can_make_difference_help_improve_society")}
            </h2>
            <p className=" text-skin-textBase text-[15px] leading-[22px] md:text-base ltr:md:pr-[60px] rtl:md:pl-[60px] ltr:xl:pr:[140px] ltr:xl:pr-0 rtl:xl:pl:[140px] rtl:xl:pl-0 md:leading-[25px] xl:leading-[26px] ">
              {isGlobal
                ? t("home.the_national_advanced_global_content")
                : t("home.the_national_advanced")}
            </p>
            <p className=" text-skin-textBase text-[15px] leading-[22px] md:text-base ltr:md:pr-48  ltr:xl:pr-0  rtl:md:pl-48  rtl:xl:pl-0  md:leading-[25px] xl:leading-[26px] ">
              {isGlobal
                ? t("home.join_us_on_this_journey_exploration_and_be_part_global_content")
                : t("home.join_us_on_this_journey_exploration_and_be_part")}
            </p>
            <div className=" flex space-x-0 space-y-[20px] xl:space-y-0 rtl:space-x-reverse flex-col items-start xl:items-center xl:flex-row md:space-x-0 xl:space-x-[30px] md:space-y-[30px]">
              <SecondaryButton
                title={"home.join_us"}
                handleClick={handleSignUpNavigation}
                className="joinUs min-w-[170px] !text-[15px] !font-semibold !leading-5 !bg-skin-secondaryDark !border-none !text-white h-14"
              />
              <span className="flex-col items-start md:items-center md:flex-row flex text-skin-textBase text-[15px] leading-[22px] md:text-base md:leading-[25px] xl:leading-[26px]">
                {t("home.have_questions")}{" "}
                <button
                  className=" md:ltr:ml-1 md:rtl:mr-1  btn btn-link min-h-max h-auto p-0 normal-case text-[15px] leading-[22px] md:text-base md:leading-[25px] xl:leading-[26px] text-skin-textPrimary hover:text-skin-textSecondary !font-normal"
                  onClick={() => handleClick(HOME_MENU_IDS.ContactUs)}
                >
                  {t("home.contact_us")}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default JoinUS;
