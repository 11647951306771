import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "components";
import Tippy from "@tippyjs/react";
import SVGIcon from "assets/Icons";
import { downloadFile, isPreviewAllowed } from "utils/helpers";
import { showDocPreviewModal } from "utils/confirmationModals";

type FileUploadListProps = {
  classNames?: string;
  fileList?: Array<FileDetails> | null;
  handleRemove: (event: React.MouseEvent<HTMLButtonElement>, item: FileDetails) => void;
  disabled?: boolean;
  isFileUploading?: boolean;
};

export function FileUploadList({
  fileList,
  handleRemove,
  disabled,
  isFileUploading,
}: FileUploadListProps) {
  const { t } = useTranslation();

  return (
    <div className="">
      <Label
        className="label p-0 pb-[10px] text-primary-light text-sm"
        text={t("global.uploaded_files")}
      >
        <>
          {fileList ? (
            <span className=" ltr:ml-1 rtl:mr-1 font-semibold text-primary-medium">
              {fileList?.length} <span className=" text-black/40">/</span> 5
            </span>
          ) : null}
        </>
      </Label>

      <div className=" grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-6">
        {fileList?.map((item) => (
          <UploadedFileItem
            key={item.name + new Date()}
            handleRemove={(event) => handleRemove(event, item)}
            disabled={disabled}
            isFileUploading={isFileUploading}
            file={item}
          />
        ))}
      </div>
    </div>
  );
}

type UploadedFileItemProps = {
  handleRemove?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
  isPreview?: boolean;
  isFileUploading?: boolean;
  file: FileDetails;
};

export function UploadedFileItem({
  handleRemove,
  className,
  disabled,
  isPreview,
  isFileUploading,
  file,
}: UploadedFileItemProps) {
  const { t } = useTranslation();

  const handleDownload = () => {
    downloadFile(file.fileUrl, file.name);
  };

  const handlePreview = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    showDocPreviewModal(file.fileUrl, file.name);
  };

  return (
    <div
      className={`flex items-center px-5 py-[18px]  border border-gray-light rounded-md ${className}`}
    >
      <div className=" min-w-[24px]">
        <SVGIcon name="pdfIcon" />
      </div>
      <div className=" w-full truncate px-[18px] text-black font-medium text-sm uploadFileItem">
        {file.name}
      </div>
      <div className=" flex ml-auto">
        {/* File upload loader */}
        {isFileUploading && file.size ? (
          <span className=" text-xs font-normal text-gray-200 ltr:ml-auto rtl:mr-auto  flex items-center">
            {t("global.uploading")}
            <button type="button" className="text-green-200 ltr:ml-1 rtl:mr-1 p-0 w-auto min-w-0">
              <SVGIcon name="uploading" />
            </button>
          </span>
        ) : (
          <>
            <button className=" text-primary-medium" onClick={handleDownload} disabled={disabled}>
              <SVGIcon name="previewDownload" />
            </button>
            {isPreviewAllowed(file.name) ? (
              <button
                className="text-primary-medium ltr:ml-4 rtl:mr-4"
                onClick={(event) => handlePreview && handlePreview(event)}
                disabled={disabled}
              >
                <SVGIcon name="eye" />
              </button>
            ) : (
              <Tippy
                placement="bottom"
                arrow={true}
                theme="light"
                appendTo="parent"
                allowHTML={true}
                content={
                  <span className=" font-medium text-xs max-w-[250px] block">
                    {t("global.cannot_preview_this_file")}
                  </span>
                }
              >
                <button
                  className="text-primary-medium ltr:ml-4 rtl:mr-4 opacity-50 cursor-not-allowed"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <SVGIcon name="eye" />
                </button>
              </Tippy>
            )}
            {!isPreview ? (
              <button
                onClick={(event) => handleRemove && handleRemove(event)}
                disabled={disabled}
                className=" ltr:ml-4 rtl:mr-4"
              >
                <SVGIcon name="trash" />
              </button>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
