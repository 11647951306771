import Joi from "joi";
import { groupBy } from "utils/helpers";

const CaseStudyDescriptionValidation = (description: string): boolean => {
  try {
    const learnMore = JSON.stringify(JSON.parse(description));
    const jsonObject = JSON.parse(learnMore);
    if (jsonObject.blocks && jsonObject.blocks.length > 0) {
      const block = jsonObject.blocks[0];
      const text = block.text;
      return text.trim().length > 0;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

const descriptionValidator = (value: string, helpers: any): string | Error => {
  if (!CaseStudyDescriptionValidation(value)) {
    return helpers.message("required");
  }
  return value;
};

const systemConfiguration = Joi.object({
  title: Joi.string().messages({ "string.empty": "required" }),
  description: Joi.string()
    .custom(descriptionValidator, "custom validation")
    .messages({ "string.empty": "required" }),
  uploadFileUrl: Joi.string().optional().default("").allow(""),
});

export const challengeUploadCaseStudyValidation = (basicFormData: UploadCaseStudyFormData) => {
  const result = systemConfiguration.validate(basicFormData, { abortEarly: false });
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
