import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { SubTitle, Title, InlineLoader } from "components";
import { useGetStatistics } from "features/Home/api/useGetStatistics";
import DataInsightsItem from "features/Home/DataInsights/DataInsightsItem";
import { isGlobalWebsite } from "utils/helpers";

type DataInsightsProps = {
  inView: boolean;
};

const DataInsights = forwardRef(function DataInsights(
  { inView }: DataInsightsProps,
  ref: React.Ref<HTMLDivElement>
) {
  const { t } = useTranslation();
  const isGlobal = isGlobalWebsite();
  const { data: statisticsData, isLoading: isDataLoading } = useGetStatistics(isGlobal);
  return (
    <>
      {statisticsData ? (
        <div className=" w-full px-5 md:px-10  py-10 xl:py-[60px] bg-skin-primaryDark " ref={ref}>
          <div className="mx-auto w-full max-w-[1280px]">
            <SubTitle
              title={t("home.analytics")}
              className=" relative font-semibold text-skin-textSecondary01 uppercase !justify-start text-xs leading-4  md:text-xl md:leading-[26px] "
            />
            <Title
              title={t("home.data_insights")}
              className=" relative font-semibold text-white text-[27px] leading-[34px] md:text-[43px] md:leading-[54px]"
            />
            <ul className=" grid grid-cols-2 gap-2.5 md:grid-cols-3 md:gap-5 xl:grid-cols-4 mt-10 xl:mt-[60px]">
              <>
                {statisticsData?.researches > 0 ? (
                  <DataInsightsItem
                    iconName="DIActiveResearch"
                    value={statisticsData?.researches}
                    label={t("home.active_researchers")}
                    inView={inView}
                  />
                ) : null}
                {statisticsData?.activeChallenges > 0 ? (
                  <DataInsightsItem
                    iconName="DIActiveChallenges"
                    value={statisticsData?.activeChallenges}
                    label={t("home.active_challenges")}
                    inView={inView}
                  />
                ) : null}
                {statisticsData?.completedChallenges > 0 ? (
                  <DataInsightsItem
                    iconName="DIChallengesCompleted"
                    value={statisticsData?.completedChallenges}
                    label={t("home.challenges_completed")}
                    inView={inView}
                  />
                ) : null}
                {statisticsData?.collaborations > 0 ? (
                  <DataInsightsItem
                    iconName="DICollaborations"
                    value={statisticsData?.collaborations}
                    label={t("home.collaborations")}
                    inView={inView}
                  />
                ) : null}
                {statisticsData?.fundingInterests > 0 ? (
                  <DataInsightsItem
                    iconName="DIFundingInterests"
                    value={statisticsData?.fundingInterests}
                    label={t("home.funding_interests")}
                    inView={inView}
                  />
                ) : null}
                {statisticsData?.industryPartners > 0 ? (
                  <DataInsightsItem
                    iconName="DIIndustryPartners"
                    value={statisticsData?.industryPartners}
                    label={t("home.industry_partners")}
                    inView={inView}
                  />
                ) : null}
                {statisticsData?.startUps > 0 ? (
                  <DataInsightsItem
                    iconName="DIStartUPS"
                    value={statisticsData?.startUps}
                    label={t("home.startups")}
                    inView={inView}
                  />
                ) : null}
                {statisticsData?.fieldsOfResearch > 0 ? (
                  <DataInsightsItem
                    iconName="DIFieldResearch"
                    value={statisticsData?.fieldsOfResearch}
                    label={t("home.fields_of_research")}
                    inView={inView}
                  />
                ) : null}
              </>
            </ul>
          </div>
        </div>
      ) : null}
      {isDataLoading ? <InlineLoader /> : null}
    </>
  );
});

export default DataInsights;
