import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterShareButton,
  LinkedinIcon,
} from "react-share";
import SVGIcon from "assets/Icons";
import { Title, Divider, SubTitle } from "components";

type ShareProps = {
  onClose: () => void;
  shareLink: string;
  title: string
};

export const ShareModal = ({ onClose, shareLink, title }: ShareProps) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);

  const handleCopyClick = () => {
    setActive(true);
    navigator.clipboard.writeText(shareLink);
  };
  return (
    <div className="max-w-[100vw] w-[100vw] min-w-[100vw]  md:w-auto md:max-w-[450px] mx-0 md:mx-2  md:min-w-[450px]  bg-white rounded-md rounded-b-none md:rounded-b-md  animate__animated animate__zoomIn">
      <div className="flex justify-between items-center p-6 !text-black">
        <div className="flex gap-3 items-center">
          <SVGIcon name="shareV3" />
          <SubTitle
            title={t(title)}
            className="font-semibold text-base text-black"
          />
        </div>
        <button onClick={onClose}>
          <SVGIcon name="close" />
        </button>
      </div>
      <Divider className="" />
      <div className="p-10">
        <div className="share-input-section relative h-full w-full">
          <p className="text-primary-light text-sm leading-[16px] font-normal mb-[6px]">
            {t("global.share_page_link")}
          </p>
          <div className="rounded bg-gray-light border border-gray-110 h-[48px] w-full py-[14px] ltr:pl-[14px] ltr:pr-[50px]  rtl:pr-[14px] rtl:pl-[50px] relative">
            <p
              className={`truncate text-gray-200 text-sm leading-normal font-medium dirLTR ${active ? "highlight-input" : ""
                }`}
            >
              {shareLink}
            </p>
            <div
              className={`absolute ltr:right-[14px] rtl:left-[14px] bottom-[10px] cursor-pointer text-gray-200 hover:text-primary-100 ${active ? "text-primary-100" : ""
                }`}
              onClick={handleCopyClick}
            >
              <SVGIcon name="copy" />
            </div>
          </div>
          {active ? (
            <div className="absolute top-[-10px] right-0 rounded-md w-[100px] h-[30px] bg-primary-900 flex items-center justify-center text-[14px] text-primary-medium font-medium ease-linear">
              {t("global.copied")}
            </div>
          ) : null}
        </div>
        <Title
          title={t("global.share_directly")}
          className="text-primary-light text-sm leading-4 font-normal mt-8"
        />
        <div className="grid grid-cols-4 gap-[34px] justify-center items-center pt-4">
          <div className="flex flex-col justify-center items-center gap-[10px]">
            <div className="h-16 w-16 flex items-center justify-center bg-[#E8F1FE] rounded-full">
              <FacebookShareButton url={shareLink} className="flex justify-center items-center">
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </div>
            <span className="text-base font-normal text-black">{t("global.facebook")}</span>
          </div>
          <div className="flex flex-col justify-center items-center gap-[10px]">
            <div className="h-16 w-16 flex items-center justify-center bg-[#E8F6FE] rounded-full">
              <TwitterShareButton url={shareLink} className="flex justify-center items-center">
                <SVGIcon name="hTwitterNew" />
              </TwitterShareButton>
            </div>
            <span className="text-base font-normal text-black">{t("global.twitter")}</span>
          </div>
          <div className="flex flex-col justify-center items-center gap-[10px]">
            <div className="h-16 w-16 flex items-center justify-center bg-gray-101/30 rounded-full">
              <EmailShareButton url={shareLink} className="flex justify-center items-center">
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>

            <span className="text-base font-normal text-black">{t("global.mail")}</span>
          </div>

          <div className="flex flex-col justify-center items-center gap-[10px]">
            <div className="h-16 w-16 flex items-center justify-center bg-[#E8F6FE] rounded-full">
              <LinkedinShareButton url={shareLink} className="flex justify-center items-center">
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>{" "}
            </div>

            <span className="text-base font-normal text-black">{t("global.linkedin")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
