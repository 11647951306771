import { useNavigate } from "react-router-dom";
import masterLogo from "assets/images/logo.svg";
import globalConsortiumLogo from "assets/images/home-images/GCIEI-white.svg";
import { isGlobalWebsite } from "utils/helpers";
import { Title } from "components/Title";

export function AppLogo() {
  const navigate = useNavigate();
  const isGlobal = isGlobalWebsite();

  const navigateToHome = () => {
    navigate("/");
  };
  return isGlobal ? (
    // <div className="cursor-pointer " onClick={navigateToHome}>
    //   <Title
    //     className="text-white text-4xl font-semibold  max-[1024px]:text-base "
    //     title={"Global Consortium"}
    //   />
    // </div>
    <div className="w-[440px] h-[71px] overflow-hidden mb-6 max-[1024px]:w-[90%] max-[1024px]:h-[60px] max-[1024px]:mb-5">
      <img
        src={globalConsortiumLogo}
        alt="Higher Colleges of technology Logo"
        className=" h-[68px] max-[1024px]:h-[60px]  cursor-pointer"
        onClick={navigateToHome}
      />
    </div>
  ) : (
    <div className="w-[440px] h-[71px] mb-6 max-[1024px]:w-[90%] max-[1024px]:h-[60px] max-[1024px]:mb-5">
      <img
        src={masterLogo}
        className=" max-w-[80%] min-[1300px]:max-w-[100%] max-[1024px]:h-[60px] cursor-pointer"
        alt="master logo"
        onClick={navigateToHome}
      />
    </div>
  );
}
