import React, { useState, useRef, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import SVGIcon from "assets/Icons";
import DefaultCoverImage from "assets/images/challengeDefaultCover.png";
import {
  SubTitle,
  Chip,
  ContactPreviewCard,
  Descriptor,
  Divider,
  SecondaryButton,
  UploadedFileItem,
  MilestonePreviewCard,
  LoaderModal,
  Label,
  Header,
  ImageLoader,
  ContentViewWithToggle,
  EditorContentPreview,
} from "components";
import { useSendParticipationRequest } from "features/ResearchScholar/api/useSendParticipationRequest";
import { useGetChallengePreviewDetails } from "features/Organization/api/useGetChallengePreviewDetails";
import { ChallengeNavigator } from "features/Challenge/Preview/ChallengeNavigator";
import {
  showChallengeStatusUpdateModal,
  showConfirmationModal,
  showShareFundingInterestModal,
  showShareModal,
} from "utils/confirmationModals";
import {
  showErrorToastMessage,
  showInfoToastMessage,
  showSuccessToastMessage,
} from "utils/toasterMessage";
import {
  convertJSONStringAsHtml,
  displayDateFormat,
  downloadFile,
  getChallengeStatus,
  scrollToReference,
  getMasterDataValue,
  extractFileNameFromUrl,
  getChallengeStatusBackground,
} from "utils/helpers";
import {
  CHALLENGE_STATUS,
  CHALLENGE_USER_STATUS,
  CHALLENGE_VIEW_MORE_INIT_STATE,
  CREATE_CHALLENGE_COMPLETION_STATUS,
  DETAILS_DISPLAY_MAX_CHAR_LENGTH,
  USERS,
} from "utils/constants";
import { setSelectedSideMenu } from "store/useSidebarStore";
import { useUserDataStore } from "store/useUserDataStore";
import { useStickyScroll } from "hooks/useStickyScroll";
import useLoadImage from "hooks/useImageLoaded";
import { useTriggerEmailNotification } from "features/Admin/api/useTriggerEmailNotification";
import { useAdminChangeChallengeStatus } from "features/Challenge/api/useAdminChangeChallengeStatus";

const ChallengeDetailsPreview = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { scroll } = useStickyScroll();
  const { scrollValue } = useStickyScroll(20);
  const pathname = window.location.pathname;
  const isSearchResult = pathname.includes("search-results");
  const isExplore = pathname.includes("explore");
  const { mutateAsync: sendParticipationRequest, isLoading: isRequestLoading } =
    useSendParticipationRequest();
  const { challengeId } = useParams();
  const [viewMore, setViewMore] = useState(CHALLENGE_VIEW_MORE_INIT_STATE);
  const [isViewMoreSummary, setIsViewMoreSummary] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState<number>(0);
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const [isImageLoading, setImageLoading] = useState(false);
  const { data: challengeDetails, isLoading: isUsersLoading } = useGetChallengePreviewDetails(
    Number(challengeId),
    isExplore
  );
  const { mutateAsync: triggerEmailNotification, isLoading } = useTriggerEmailNotification();
  const { mutateAsync: approveChallenge, isLoading: isChallengeApproveLoading } = useAdminChangeChallengeStatus();

  const aboutRef = useRef(null);
  const milestoneRef = useRef(null);
  const learnMoreRef = useRef(null);
  const fundingRef = useRef(null);
  const additionalInfoRef = useRef(null);
  const contactRef = useRef(null);
  const tagsRef = useRef(null);
  const { userInfo } = useUserDataStore();

  const [ref] = useLoadImage();
  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const getBreadCrumbPath = () => {
    const breadCrumbPath = [];
    if (pathname.includes("explore")) {
      breadCrumbPath.push(
        {
          title: "home.challenges",
        },
        {
          title: "organization.explore_challenge",
        }
      );
    } else if (isSearchResult) {
      breadCrumbPath.push({
        title: "global.search_results",
      });
    } else if (pathname.includes("dashboard")) {
      breadCrumbPath.push({
        title: "global.dashboard",
      });
    } else {
      breadCrumbPath.push({
        title: "admin.challenges",
      });
    }

    breadCrumbPath.push({
      title: "organization.view_challenge",
    });
    return breadCrumbPath;
  };

  useEffect(() => {
    if (challengeDetails && challengeDetails?.challengeDetails?.coverImage.length > 0) {
      setImageLoading(true);
    }

    if(challengeDetails && challengeDetails?.challengeDetails?.challengeStatusId){
      if (pathname.includes("preview") && userInfo?.userType === USERS.Organization) {
        if (challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Draft) {
          showInfoToastMessage({ message: t("admin.challenge_declined_by_admin") });
          navigate(`/organization/challenges/create/${challengeId}`, { replace: true });
        } else if (challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Open) {
          showInfoToastMessage({ message: t("admin.challenge_approved_by_admin") });
          navigate(`/organization/challenges/${challengeId}`, { replace: true });
        }
      }
    }
  }, [challengeDetails]);

  useEffect(() => {
    const type = pathname.includes("home")
      ? "research_scholar.home"
      : pathname.includes("requests")
      ? "research_institute.requests"
      : pathname.includes("dashboard")
      ? "global.dashboard"
      : "admin.challenges";

    setSelectedSideMenu({
      type,
    });
  }, [pathname]);

  const linkList: Array<LinkItem> = getBreadCrumbPath();

  const handleParticipate = async () => {
    if (
      challengeDetails?.userStatus !== CHALLENGE_USER_STATUS.PendingResearchInstituteApproval &&
      challengeDetails?.userStatus !== CHALLENGE_USER_STATUS.PendingApproval
    ) {
      try {
        const result = await sendParticipationRequest(Number(challengeId));
        showSuccessToastMessage({
          message: result.message,
        });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };

  const handleShare = () => {
    showShareModal(window.location.href, "global.share_challenge");
  };

  const handleScroll = (ref: React.RefObject<HTMLDivElement>) => {
    scrollToReference(ref, 120);
  };

  const handleNavigationClick = (value: number, index: number) => {
    setActiveMenu(index);
    if (value === CREATE_CHALLENGE_COMPLETION_STATUS.About) {
      handleScroll(aboutRef);
    } else if (value === CREATE_CHALLENGE_COMPLETION_STATUS.Milestones) {
      handleScroll(milestoneRef);
    } else if (value === CREATE_CHALLENGE_COMPLETION_STATUS.LearnMore) {
      handleScroll(learnMoreRef);
    } else if (value === CREATE_CHALLENGE_COMPLETION_STATUS.Funding) {
      handleScroll(fundingRef);
    } else if (value === CREATE_CHALLENGE_COMPLETION_STATUS.Artifacts) {
      handleScroll(additionalInfoRef);
    } else if (value === CREATE_CHALLENGE_COMPLETION_STATUS.Contacts) {
      handleScroll(contactRef);
    } else if (value === CREATE_CHALLENGE_COMPLETION_STATUS.Tags) {
      handleScroll(tagsRef);
    }
  };

  const navigationItems: Array<RecordItem> = useMemo(() => {
    const tempItems = [
      {
        value: CREATE_CHALLENGE_COMPLETION_STATUS.About,
        label: "organization.about",
      },
    ];
    if (challengeDetails?.mileStone?.milestones?.length) {
      {
        tempItems.push({
          value: CREATE_CHALLENGE_COMPLETION_STATUS.Milestones,
          label: "organization.milestones",
        });
      }
    }
    if (challengeDetails?.moreDetails) {
      {
        tempItems.push({
          value: CREATE_CHALLENGE_COMPLETION_STATUS.LearnMore,
          label: "organization.learn_more",
        });
      }
    }
    if (challengeDetails?.fundingDetails?.funding) {
      {
        tempItems.push({
          value: CREATE_CHALLENGE_COMPLETION_STATUS.Funding,
          label: "organization.funding",
        });
      }
    }
    if (
      challengeDetails?.additionalInformation?.information ||
      challengeDetails?.additionalInformation?.documents?.length
    ) {
      {
        tempItems.push({
          value: CREATE_CHALLENGE_COMPLETION_STATUS.Artifacts,
          label: "organization.additional_info_and_artifacts",
        });
      }
    }

    if (challengeDetails?.contacts?.contacts) {
      {
        tempItems.push({
          value: CREATE_CHALLENGE_COMPLETION_STATUS.Contacts,
          label: "organization.contact",
        });
      }
    }
    if (challengeDetails?.tags?.tags?.length) {
      {
        tempItems.push({
          value: CREATE_CHALLENGE_COMPLETION_STATUS.Tags,
          label: "organization.tags",
        });
      }
    }
    return tempItems;
  }, [challengeDetails, i18n.language]);

  const handleDownload = (fileDetails: FileDetails) => {
    downloadFile(fileDetails.fileUrl, fileDetails.name);
  };

  const handleSummaryViewMoreClick = () => {
    setIsViewMoreSummary((prev) => !prev);
  };
  const handleShareFundingInterest = async () => {
    if (challengeDetails && challengeDetails?.challengeDetails) {
      await showShareFundingInterestModal(challengeDetails?.challengeDetails);
    }
  };

  const downloadCaseStudy = () => {
    if (challengeDetails) {
      const file = {
        id: 0,
        name: extractFileNameFromUrl(challengeDetails?.caseStudy.uploadFileUrl),
        fileUrl: challengeDetails?.caseStudy.uploadFileUrl,
      };
      handleDownload(file);
    }
  };

  const onLoaded = () => {
    setImageLoading(false);
  };

  const handleViewMoreClick = (type: keyof ChallengeViewMore) => {
    setViewMore((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const handleNotifyItemSelection = async (type: string) => {
    const result = await showConfirmationModal({
      title: "global.confirm_selection",
      message:
        type === "allResearchers"
          ? "admin.notify_all_confirmation_message"
          : "admin.notify_by_tags_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.proceed",
      isAlert: true,
      modalIcon: "confirmSelection",
    });
    if (!result) {
      return;
    }
    try {
      const result = await triggerEmailNotification({
        id: challengeDetails.challengeDetails.id,
        isAllResearchers: type === "allResearchers" ? true : false,
        isChallenge: true,
      });
      showSuccessToastMessage({
        message: result?.message,
      });
    } catch (error: ApiError) {
      error?.response?.data?.status === 429
        ? showInfoToastMessage({ message: error.response.data.message })
        : showErrorToastMessage({
            message:
              error?.response?.data?.status < 500
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
    }
  };

  const handleApprove = async () => {
    const result = await showConfirmationModal({
      title: "global.confirm_approve",
      message: "admin.challenge_approval",
      cancelButtonText: "global.close",
      proceedButtonText: "global.approve",
      isAlert: true,
      modalIcon: "confirmSelection",
    });
    if (!result) {
      return;
    }
    if (result) {
      try {
        const result = await approveChallenge({
          status: CHALLENGE_STATUS.Open,
          challengeId: Number(challengeId),
        });
        showSuccessToastMessage({
          message: result.message,
        });
        navigate(`/admin/challenges`, { replace: true });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };

  const handleDeclineWithReason = async () => {
    const isConfirm = await showChallengeStatusUpdateModal({
      title: t("global.confirm_decline"),
      message: t("admin.challenge_decline_with_reason_message"),
      challengeId: Number(challengeId),
      status: CHALLENGE_STATUS.Decline,
    });
    if (!isConfirm) {
      return;
    } else {
      navigate(`/admin/challenges`, { replace: true });
    }
  };

  return (
    <div className={`flex  min-h-screen w-full  `}>
      <Header
        linkList={linkList}
        title={challengeDetails?.challengeDetails?.title}
        handleCollapseClick={onToggleClick}
        className={
          scroll
            ? " bg-primary-medium lg:bg-white animate__animated animate__fadeInDown lg:shadow-sticky lg:!h-[90px] fixed stickyHeader smaller-devices-section z-[91]"
            : "fixed bg-primary-medium lg:bg-gray-light smaller-devices-section"
        }
      />
      <div className=" adminDetails w-full mt-[170px] mb-20 lg:mb-0 lg:mt-0 lg:pt-[118px] pb-[45px] ltr:pr-[30px] rtl:pl-[30px] items-baseline px-4 lg:px-10 lg:min-h-screen flex flex-col  overflow-auto max-[1025px]:bg-white max-[1025px]:!px-4 max-[1025px]:mt-[106px] max-[1025px]:pb-[80px]">
        <div className="w-full flex flex-col h-full ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 ltr:lg:pr-[292px] rtl:lg:pl-[292px] space-y-3">
          <div className=" flex-col bg-white w-full h-full rounded-none lg:rounded-md flex mb-auto">
            <div className="p-5 relative max-[1025px]:p-0" ref={aboutRef}>
              {isImageLoading ? (
                <div className="h-[160px] lg:h-[250px]">
                  <ImageLoader />
                </div>
              ) : null}

              <img
                alt="previewImage"
                className={`w-full max-h-[250px] object-cover rounded-md max-[1025px]:min-h-[160px] ${
                  isImageLoading ? "h-[0px] overflow-hidden" : "h-[250px] overflow-hidden"
                }`}
                src={
                  challengeDetails?.challengeDetails?.coverImage.length > 0
                    ? challengeDetails?.challengeDetails?.coverImage
                    : DefaultCoverImage
                }
                ref={ref}
                onLoad={onLoaded}
              />
              {!challengeDetails?.challengeDetails?.isPrivate &&
              challengeDetails?.challengeDetails?.challengeStatusId !== CHALLENGE_STATUS.Disable &&
              challengeDetails?.challengeDetails?.challengeStatusId !== CHALLENGE_STATUS.Pending ? (
                <button
                  onClick={handleShare}
                  className="absolute ltr:bottom-0 ltr:right-[5%] rtl:bottom-0 rtl:left-[5%] bg-[#37D3AE] border-2 border-white rounded-md max-[1025px]:hidden"
                >
                  <SVGIcon name="shareV2" className="" />
                </button>
              ) : null}
            </div>
            <div className="p-5 pb-10 max-[1025px]:p-0 max-[1025px]:pt-4">
              <div className="flex gap-4 items-center truncate">
                <div className=" flex badgeBLock gap-2 items-center">
                  <Label
                    className={`!w-auto text-gray-107 text-xs bg-green-500 rounded-xl px-3 py-1.5 h-[30px] ${getChallengeStatusBackground(
                      challengeDetails?.challengeDetails?.challengeStatusId
                    )}`}
                    text={t(
                      `${getChallengeStatus(challengeDetails?.challengeDetails?.challengeStatusId)}`
                    )}
                  />
                  <Label
                    className=" !w-auto text-gray-107 text-xs bg-green-500 rounded-xl px-3 py-1.5  h-[30px]"
                    text={
                      challengeDetails?.challengeDetails?.isPrivate
                        ? t("organization.private")
                        : t("organization.public")
                    }
                  />
                </div>
                <div className=" h-[14px] bg-gray-200  w-[1px] min-w-[1px]"></div>
                <div className=" flex badgeBLock gap-6 items-center max-[768px]:truncate">
                  <Label
                    className=" !w-auto text-gradient-blueDark text-xs bg-primary-500 rounded-xl px-3 py-1.5  h-[30px]  max-[768px]:truncate !block"
                    text={
                      challengeDetails?.challengeDetails?.targetIndustry
                        ? getMasterDataValue(
                            challengeDetails?.challengeDetails?.targetIndustry,
                            i18n.language
                          )
                        : ""
                    }
                  />
                </div>
              </div>
              <div>
                <div className=" flex items-center space-x-2 rtl:space-x-reverse  mt-2">
                  <h2 className="font-bold text-3xl text-gray-109 Word-break max-[1025px]:text-black  max-[1025px]:text-[22px]  max-[1025px]:leading-[30px]  ">
                    {challengeDetails?.challengeDetails?.title}
                  </h2>
                  {challengeDetails?.challengeDetails?.isGlobal && !userInfo?.isGlobal ? (
                    <div className=" h-[20px] flex items-center px-3 ltr:!ml-1 rtl:!mr-1 rounded-xl text-xs text-white font-normal bg-primary-medium ">
                      {t("global.global")}
                    </div>
                  ) : null}
                </div>
                <div className="flex gap-4 items-center mt-[10px] max-[1025px]:mt-[16px] max-[1025px]:flex-wrap">
                  <Descriptor
                    title={`${t("global.start_date")} : ${displayDateFormat(
                      challengeDetails?.challengeDetails?.startDate ?? ""
                    )}`}
                    startIcon="previewDate"
                    className="flex gap-2 items-center text-black text-sm preview-descriptor whitespace-nowrap"
                  />
                  <Descriptor
                    title={`${t("global.end_date")} : ${displayDateFormat(
                      challengeDetails?.challengeDetails?.endDate ?? ""
                    )}`}
                    startIcon="previewDate"
                    className="flex gap-2 items-center text-black text-sm preview-descriptor  whitespace-nowrap"
                  />
                  <Descriptor
                    title={challengeDetails?.challengeDetails?.location ?? ""}
                    startIcon="previewLocation"
                    className="flex gap-2 items-center text-black text-sm Word-break preview-descriptor"
                  />
                </div>
              </div>
              <Divider className=" my-[30px] max-[1025px]:hidden" />
              <div
                className={`min-[1025px]:hidden mt-6 mb-6 bg-white navigator-mobile-view   ${
                  scrollValue > 250 ? "window-scroll-challenge" : ""
                }`}
              >
                <ChallengeNavigator
                  navigatorList={navigationItems}
                  handleParticipate={handleParticipate}
                  handleClick={(value: number, index: number) =>
                    handleNavigationClick(value, index)
                  }
                  disabled={false}
                  userType={userInfo?.userType}
                  handleShareInterest={handleShareFundingInterest}
                  challengeDetails={challengeDetails}
                  activeMenu={activeMenu}
                  handleShareChallenge={handleShare}
                  handleApprove={handleApprove}
                  handleDeclineWithReason={handleDeclineWithReason}
                />
              </div>
              <SubTitle
                title={t("global.summary")}
                className="!justify-start font-semibold text-lg text-gray-109 leading-[26px] !mb-2.5 max-[1025px]:text-[18px] max-[1025px]:!mb-4"
              />
              <ContentViewWithToggle
                data={challengeDetails?.challengeDetails?.summary ?? ""}
                handleViewMoreClick={handleSummaryViewMoreClick}
                isViewMore={isViewMoreSummary}
                maxLength={DETAILS_DISPLAY_MAX_CHAR_LENGTH}
                className={
                  " text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] "
                }
                contentType="textAreaContent"
              />

              <Divider className=" my-[30px] max-[1025px]:my-[24px]" />
              {challengeDetails?.caseStudy ? (
                <div>
                  <div>
                    <h3>{t("organization.case_study")}</h3>
                    <h3 className="mt-1 font-semibold text-2xl text-gray-109">
                      {challengeDetails?.caseStudy?.title}
                    </h3>
                    <EditorContentPreview
                      className={
                        "mt-[10px] text-gray-109 text-sm  max-w-[90%] Word-break dangerously-set-html max-[1025px]:mt-4 max-[1025px]:max-w-full"
                      }
                      data={challengeDetails?.caseStudy.description}
                    />
                    <div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-2.5 mt-2.5">
                        <div className="flex items-center px-5 py-[18px]  border border-gray-light rounded-md">
                          <div className=" min-w-[24px]">
                            <SVGIcon name="pdfIcon" />
                          </div>
                          <div className=" w-full truncate px-[18px] text-black font-medium text-sm uploadFileItem">
                            {extractFileNameFromUrl(challengeDetails.caseStudy.uploadFileUrl)}
                          </div>
                          <SecondaryButton
                            startIcon="previewDownload"
                            title={""}
                            handleClick={downloadCaseStudy}
                            className=" text-primary-medium !border-none !h-auto !min-h-max !p-0 !bg-transparent hover:!bg-transparent hover:!text-primary-medium"
                          />
                        </div>
                      </div>
                      <p className=" text-gray-109 text-sm flex items-center space-x-2 my-4">
                        <SVGIcon name="previewDate" />
                        <span></span>
                        {t("global.uploaded_on")}{" "}
                        {displayDateFormat(challengeDetails?.caseStudy.createdOn)}
                      </p>
                    </div>
                  </div>

                  <Divider className="my-[30px] !border-gray-light max-[1025px]:my-[24px]" />
                </div>
              ) : null}

              {challengeDetails?.mileStone?.milestones?.length ? (
                <div ref={milestoneRef}>
                  <h3 className=" font-semibold text-lg mb-3 text-gray-109 max-[1025px]:mb-4">
                    {t("organization.milestones")}
                  </h3>
                  <ul className="flex flex-col gap-5 preview-milestones-line preview-milestone-card">
                    {challengeDetails?.mileStone?.milestones?.map(
                      (item: ChallengeMilestoneSave, index: number) => (
                        <MilestonePreviewCard
                          key={index}
                          title={item?.milestone}
                          startDate={item?.startDate}
                          endDate={item?.endDate}
                          description={item?.description}
                        />
                      )
                    )}
                  </ul>
                  <Divider className="mt-[30px] !border-gray-light max-[1025px]:mt-[24px]" />
                </div>
              ) : null}
              {challengeDetails?.moreDetails ? (
                <div ref={learnMoreRef}>
                  <h3 className="mt-[30px] font-semibold text-lg text-gray-112 !mb-2.5">
                    {t("organization.challenge_learn_more")}
                  </h3>
                  <h3 className="font-semibold text-md text-gray-112 !mb-2.5">
                    {t("organization.overview_of_current_challenges")}
                  </h3>
                  <ContentViewWithToggle
                    data={challengeDetails?.moreDetails?.overviewOfCurrentChallenges}
                    handleViewMoreClick={() => handleViewMoreClick("overviewOfCurrentChallenges")}
                    isViewMore={viewMore.overviewOfCurrentChallenges}
                    maxLength={DETAILS_DISPLAY_MAX_CHAR_LENGTH}
                    className={
                      " text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] me-1"
                    }
                    contentType="textAreaContent"
                  />

                  <h3 className="!mt-2.5 font-semibold text-md text-gray-112 !mb-2.5">
                    {t("organization.detailed_description_of_the_challenge")}
                  </h3>
                  <ContentViewWithToggle
                    data={challengeDetails?.moreDetails?.detailedDescriptionOftheChallenges}
                    handleViewMoreClick={() =>
                      handleViewMoreClick("detailedDescriptionOftheChallenges")
                    }
                    isViewMore={viewMore.detailedDescriptionOftheChallenges}
                    maxLength={DETAILS_DISPLAY_MAX_CHAR_LENGTH}
                    className={
                      " text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] me-1"
                    }
                    contentType="textAreaContent"
                  />

                  <h3 className="!mt-2.5 font-semibold text-md text-gray-112 !mb-2.5">
                    {t("organization.desired_enhancement")}
                  </h3>
                  <ContentViewWithToggle
                    data={challengeDetails?.moreDetails?.desiredEnhancements}
                    handleViewMoreClick={() => handleViewMoreClick("desiredEnhancements")}
                    isViewMore={viewMore.desiredEnhancements}
                    maxLength={DETAILS_DISPLAY_MAX_CHAR_LENGTH}
                    className={
                      " text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] me-1"
                    }
                    contentType="textAreaContent"
                  />

                  <h3 className="!mt-2.5 font-semibold text-md text-gray-112 !mb-2.5">
                    {t("organization.resources_and_constraints")}
                  </h3>
                  <ContentViewWithToggle
                    data={challengeDetails?.moreDetails?.resourcesAndConstraints}
                    handleViewMoreClick={() => handleViewMoreClick("resourcesAndConstraints")}
                    isViewMore={viewMore.resourcesAndConstraints}
                    maxLength={DETAILS_DISPLAY_MAX_CHAR_LENGTH}
                    className={
                      " text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] me-1"
                    }
                    contentType="textAreaContent"
                  />
                  <h3 className="!mt-2.5 font-semibold text-md text-gray-112 !mb-2.5">
                    {t("organization.success_metrics")}
                  </h3>
                  <ContentViewWithToggle
                    data={challengeDetails?.moreDetails?.successMetrics}
                    handleViewMoreClick={() => handleViewMoreClick("successMetrics")}
                    isViewMore={viewMore.successMetrics}
                    maxLength={DETAILS_DISPLAY_MAX_CHAR_LENGTH}
                    className={
                      " text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] me-1"
                    }
                    contentType="textAreaContent"
                  />
                </div>
              ) : null}
              <Divider className="my-[30px] !border-gray-light max-[1025px]:my-[24px]" />
              {challengeDetails?.fundingDetails?.funding ? (
                <div ref={fundingRef}>
                  <h3 className="mt-[30px] font-semibold text-lg text-gray-109 max-[1025px]:mt-0">
                    {t("organization.funding")}
                  </h3>
                  <div className="flex gap-4 bg-gray-light justify-center items-center mt-[10px] p-6 rounded-md max-w-[90%] w-full max-[1025px]:max-w-full">
                    <div className="max-[1025px]:order-1 min-w-[40px]">
                      <SVGIcon
                        name="fundingPreviewIcon"
                        className="!max-w-10 !w-full min-w-[40px]"
                      />
                    </div>
                    <p
                      className=" max-w-[100%] mr-auto w-full text-gray-109 text-sm Word-break dangerously-set-html max-[1025px]:max-w-full"
                      dangerouslySetInnerHTML={{
                        __html: convertJSONStringAsHtml(challengeDetails?.fundingDetails?.funding),
                      }}
                    />
                  </div>
                  <Divider className="mt-[30px] !border-gray-light max-[1025px]:mt-[24px]" />
                </div>
              ) : null}
              {challengeDetails?.additionalInformation?.information ? (
                <div ref={additionalInfoRef}>
                  <h3 className="mt-[30px] font-semibold text-lg text-gray-109 max-[1025px]:mt-[24px] mb-[10px]">
                    {t("organization.additional_information")}
                  </h3>
                  <EditorContentPreview
                    className={
                      " mt-[10px] text-gray-109  max-w-[90%] Word-break dangerously-set-html max-[1025px]:max-w-full"
                    }
                    data={challengeDetails?.additionalInformation?.information}
                  />
                  <Divider className="mt-[30px] !border-gray-light max-[1025px]:mt-[24px]" />
                </div>
              ) : null}
              {challengeDetails?.additionalInformation?.documents?.length ? (
                <>
                  <h3 className="mt-[30px] font-semibold text-lg text-gray-109 max-[1025px]:mt-[24px] mb-[10px]">
                    {t("organization.artifact")}
                  </h3>
                  <div className="grid grid-cols-2 justify-between gap-[10px] mt-1 max-[1025px]:grid-cols-1">
                    {challengeDetails?.additionalInformation?.documents?.map(
                      (item: FileDetails, index: number) => (
                        <div className="flex-1 truncate" key={index}>
                          <UploadedFileItem key={item.fileUrl + new Date()} isPreview file={item} />
                        </div>
                      )
                    )}
                  </div>
                  <Divider className="mt-[30px] !border-gray-light max-[1025px]:mt-[24px]" />
                </>
              ) : null}
              <div ref={contactRef}>
                <h3 className="mt-[30px] font-semibold text-lg mb-[10px] text-gray-109 max-[1025px]:mt-[24px] max-[1025px]:mb-[16px]">
                  {t("organization.point_of_contact")}
                </h3>
                {challengeDetails?.contacts?.contacts?.map(
                  (item: OrganizationContact, index: number) => (
                    <ContactPreviewCard
                      key={index}
                      fullName={item?.fullName}
                      designation={item?.designation}
                      contactNumber={item?.contactNumber}
                      countryCodeId={item?.countryCodeId}
                      email={item?.email}
                    />
                  )
                )}
              </div>
              <Divider className="mt-[30px] !border-gray-light max-[1025px]:mt-[24px]" />
              {challengeDetails?.tags?.tags?.length ? (
                <div ref={tagsRef}>
                  <h3 className="mt-[30px] font-semibold text-lg text-gray-109 max-[1025px]:mt-[24px]">
                    {t("organization.tags")}
                  </h3>
                  <div className="flex gap-3 mt-[11px] text-xs flex-wrap items-center">
                    {challengeDetails?.tags?.tags?.map((value: OptionItem, index: number) => (
                      <Chip
                        key={value.id ? value.id + "" : index + ""}
                        text={i18n.language === "ar" ? value.name.ar : value.name.en}
                        className="!m-0"
                      />
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={`w-full min-w-full lg:w-[280px] lg:min-w-[280px]  mb-auto fixed max-[1025px]:hidden
            ${
              scroll
                ? " top-[100px]  ltr:lg:right-[30px] rtl:lg:left-[30px]  z-[5]  shadow-sticky  lg:animate__animated lg:animate__fadeInDown lg:transition-all"
                : " top-[76px] lg:top-auto ltr:lg:right-[30px] rtl:left-[30px]  z-[5]"
            }`}
        >
          <ChallengeNavigator
            navigatorList={navigationItems}
            handleParticipate={handleParticipate}
            handleClick={handleNavigationClick}
            disabled={false}
            userType={userInfo?.userType}
            handleShareInterest={handleShareFundingInterest}
            challengeDetails={challengeDetails}
            activeMenu={activeMenu}
            handleShareChallenge={handleShare}
            handleNotifyItemSelection={handleNotifyItemSelection}
            handleApprove={handleApprove}
            handleDeclineWithReason={handleDeclineWithReason}
          />
        </div>
        {isLoading || isRequestLoading || isUsersLoading || isChallengeApproveLoading ? (
          <LoaderModal />
        ) : null}
      </div>
    </div>
  );
};
export default ChallengeDetailsPreview;
