import { useState, useMemo } from "react";
import { FilterPopup, FilterTab, Header, NoPermissionCard } from "components";
import Dashboard from "features/Challenge/Dashboard";
import { useQueryClient } from "@tanstack/react-query";
import { useMasterDataStore } from "store/useMasterDataStore";
import { useUserDataStore } from "store/useUserDataStore";
import ChallengeRequests from "features/Challenge/ChallengeRequests";
import CollaborationRequests from "features/Collaboration/CollaborationRequests";
import { useStickyScroll } from "hooks/useStickyScroll";
import { CHALLENGE_STATUS, FORUM_TYPES } from "utils/constants";
import { useTranslation } from "react-i18next";
import { setCollaborationRequestFilterInfo, useFilterDataStore } from "store/useFilterDataStore";

export default function Requests() {
  const { t, i18n } = useTranslation();
  const { permissions } = useMasterDataStore();
  const { userInfo } = useUserDataStore();
  const { scroll } = useStickyScroll();
  const { collaborationRequestFilterInfo } = useFilterDataStore();
  const displayContent = permissions?.Viewchallengerequests;
  const queryClient = useQueryClient();

  const [isOpenSideMenu, setIsOpenSideMenu] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(
    collaborationRequestFilterInfo?.activeTab
      ? collaborationRequestFilterInfo?.activeTab
      : displayContent
      ? FORUM_TYPES.challenges
      : FORUM_TYPES.collaborations
  );
  const [activeFilter, setActiveFilter] = useState<number>(
    collaborationRequestFilterInfo?.activeFilter ?? CHALLENGE_STATUS.All
  );
  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const RequestTabItems: Array<RecordItem> = useMemo(() => {
    const tempItems = [];
    if (displayContent) {
      tempItems.push({
        value: FORUM_TYPES.challenges,
        label: "admin.challenges",
      });
    }
    tempItems.push({
      value: FORUM_TYPES.collaborations,
      label: "global.collaborations",
    });

    return tempItems;
  }, [i18n.language]);

  const RequestFilterItems: Array<FilterOption> = useMemo(() => {
    const tempItems = [];
    if (activeTab === FORUM_TYPES.challenges) {
      tempItems.push({
        option: {
          value: CHALLENGE_STATUS.All,
          label: t("research_institute.all_challenges"),
        },
      });

      tempItems.push({
        option: {
          value: CHALLENGE_STATUS.Open,
          label: t("organization.open"),
        },
      });

      tempItems.push({
        option: {
          value: CHALLENGE_STATUS.Live,
          label: t("organization.live"),
        },
      });

      tempItems.push({
        option: {
          value: CHALLENGE_STATUS.Close,
          label: t("organization.closed"),
        },
      });
    } else if (activeTab === FORUM_TYPES.collaborations) {
      tempItems.push({
        option: {
          value: CHALLENGE_STATUS.All,
          label: t("research_institute.all_collaborations"),
        },
      });

      tempItems.push({
        option: {
          value: CHALLENGE_STATUS.Open,
          label: t("organization.open"),
        },
      });

      tempItems.push({
        option: {
          value: CHALLENGE_STATUS.Close,
          label: t("organization.closed"),
        },
      });
    }
    return tempItems;
  }, [activeTab, t, i18n.language]);

  const handleTabChange = (type: number) => {
    setActiveTab(type);
    if (type === FORUM_TYPES.challenges) {
      queryClient.resetQueries(["challenge-list"]);
    } else {
      queryClient.resetQueries(["collaboration-list"]);
    }
    setActiveFilter(CHALLENGE_STATUS.All);
    setCollaborationRequestFilterInfo({
      activeTab: type,
      activeFilter: activeFilter,
    });
  };

  const handleFilterChange = (selectedFilterId: number) => {
    setActiveFilter(selectedFilterId);
    setCollaborationRequestFilterInfo({
      activeTab: activeTab,
      activeFilter: selectedFilterId,
    });
  };

  if (userInfo?.isGlobal) {
    return (
      <div className="w-full h-full flex">
        <Header
          title={"research_institute.my_requests"}
          handleCollapseClick={onToggleClick}
          className={
            scroll
              ? " bg-primary-medium lg:bg-white  animate__animated animate__fadeInDown lg:shadow-sticky   lg:!h-[90px] fixed stickyHeader smaller-devices-section-challenge"
              : "fixed bg-primary-medium lg:bg-gray-light smaller-devices-section-challenge"
          }
        />

        <div className=" adminDetails w-full pt-[118px] pb-10 items-baseline px-4 lg:px-10 lg:ltr:pr-[30px] lg:rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto max-[1025px]:overflow-x-hidden ">
          {permissions &&
          (permissions?.Viewopenchallenges ||
            permissions?.Viewclosedchallenges ||
            permissions?.Viewlivechallenges) ? (
            <>
              <div className="tabContainer w-full   flex items-baseline tabMobileView collaborationFilter">
                <FilterTab
                  filterOptions={RequestTabItems}
                  activeTab={activeTab}
                  handleClick={(value) => handleTabChange(value)}
                />
                <FilterPopup
                  filterOptions={RequestFilterItems}
                  activeFilter={activeFilter}
                  handleClick={(selectedFilterId) => {
                    handleFilterChange(selectedFilterId);
                  }}
                />
              </div>
              <div className="tab-content pt-6 w-full">
                {activeTab === FORUM_TYPES.challenges ? (
                  <ChallengeRequests activeFilter={activeFilter} isScholarHomePage={false} />
                ) : activeTab === FORUM_TYPES.collaborations ? (
                  <CollaborationRequests activeFilter={activeFilter} isScholarHomePage={false} />
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  } else {
    return displayContent ? <Dashboard /> : <NoPermissionCard />;
  }
}
