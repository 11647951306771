import React from "react";
import { SecondaryButton, ProfileNavigationTimeline, PrimaryButton } from "components";
import { USERS, USER_STATUS } from "utils/constants";
import { useUserDataStore } from "store/useUserDataStore";

type NavigatorProps = {
  navigatorList: Array<RecordItem>;
  handleSave: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleNext: () => void;
  handleClick: (i: number) => void;
  stepNumber?: number;
  disabled?: boolean;
  profileCompletionStatusId: number;
  profileStatusId: number;
};

export const Navigator = ({
  navigatorList,
  handleSave,
  handleNext,
  stepNumber,
  handleClick,
  disabled,
  profileCompletionStatusId,
  profileStatusId,
}: NavigatorProps) => {
  const { userInfo } = useUserDataStore();
  const showUpdateText =
    profileStatusId === USER_STATUS.Approved || profileStatusId === USER_STATUS.Disabled;

  const showDraftText =
    profileStatusId === USER_STATUS.New ||
    profileStatusId === USER_STATUS.PendingApproval ||
    profileStatusId === USER_STATUS.Draft;

  return (
    <div className=" bg-white rounded-md pl-3 pt-4 lg:pt-0 lg:!p-[15px] lg:!pb-0">
      <div
        className={`!pb-[30px] grid gap-[10px] ${
          showUpdateText && stepNumber === navigatorList.length ? "grid-cols-1" : "grid-cols-2"
        } fixed lg:relative bottom-0 w-full left-0 z-10 bg-white py-4 px-10 lg:py-0 lg:px-0 shadow-footer lg:shadow-none`}
      >
        <SecondaryButton
          title={showUpdateText ? "global.update" : showDraftText ? "global.save_draft" : ""}
          handleClick={handleSave}
          className="!mt-0 !h-12"
          disabled={disabled}
        />
        {stepNumber === navigatorList.length ? (
          profileStatusId !== USER_STATUS.Approved &&
          userInfo?.userType === USERS.ResearchInstitute && (
            <PrimaryButton
              title={"global.submit"}
              handleClick={handleNext}
              className="!h-12"
              disabled={disabled}
            />
          )
        ) : (
          <PrimaryButton
            title={"global.next"}
            handleClick={handleNext}
            className="!h-12"
            disabled={disabled}
          />
        )}
      </div>
      <ProfileNavigationTimeline
        navigatorList={navigatorList}
        stepNumber={stepNumber}
        handleClick={handleClick}
        profileCompletionStatusId={profileCompletionStatusId}
        isViewOnly={false}
      />
    </div>
  );
};
