import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { USER_STATUS } from "utils/constants";
import { urls } from "utils/urls";

async function submitProfileDetails(userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.put(
    urls.researchInstituteProfileStatusUpdate(userId, USER_STATUS.PendingApproval),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return res?.data as ApiStatus | ApiError;
}

export function useSubmitProfileDetails() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;
  const qc = useQueryClient();

  return useMutation(async () => {
    if (userId) {
      const result = await submitProfileDetails(userId);
      qc.invalidateQueries({
        queryKey: ["research-institute-details", userId],
      });
      qc.invalidateQueries({
        queryKey: ["research-institute-contacts", userId],
      });
      qc.invalidateQueries({
        queryKey: ["research-institute-offices", userId],
      });
      qc.invalidateQueries({
        queryKey: ["research-institute-uploads", userId],
      });
      return result;
    }
  });
}
