import { ChangeEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputField, SingleSelect, ClickableText, Divider, PrimaryButton } from "components";
import { useMasterDataStore } from "store/useMasterDataStore";
import { DETAILS_MAX_CHAR_LENGTH, EMAIL_MAX_CHAR_LENGTH } from "utils/constants";
import { phoneMaxLimitChecker, setMasterDataValue } from "utils/helpers";

const ContactForm = ({
  index,
  item,
  validationErrors,
  onDeleteContact,
  handleInputChange,
  handleCountryCodeSelection,
  isDisabled
}: {
  index: number;
  item: ChallengeContact;
  validationErrors: Array<Record<string, string>> | null;
  onDeleteContact: (index: number) => void;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    key: keyof ChallengeContact,
    index: number
  ) => void;
  handleCountryCodeSelection: (value: number | string, index: number) => void;
  isDisabled?: boolean
}) => {
  const { t, i18n } = useTranslation();
  const { countryCode } = useMasterDataStore();

  const countryCodeOptions = useMemo(() => {
    return countryCode?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [countryCode, i18n.language]);

  return (
    <div>
      <div>
        <div className="px-5 mt-5">
          <div className=" flex items-center -mx-4 lg:-mx-5 2xl:-mx-5 -mt-[36px] -mb-[7px]">
            <div className=" text-gradient-blueDark text-sm bg-white pl-4 lg:pl-5 pr-3 whitespace-nowrap">
              {item.isPrimary ? (
                <ClickableText
                  text={t("organization.primary")}
                  onClick={function (): void {
                    //noop
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className="mt-6 flex justify-center items-center gap-3">
            <InputField
              hintText={t("organization.enter_full_name")}
              labelText={t("admin.full_name")}
              fieldName={"fullName"}
              value={item.fullName}
              handleInputChange={function (event: React.ChangeEvent<HTMLInputElement>): void {
                handleInputChange(event, "fullName", index);
              }}
              errorMessage={validationErrors?.[index]?.fullName}
              maxCharLength={EMAIL_MAX_CHAR_LENGTH}
              isRequired
              isDisabled={isDisabled}
            />
            <InputField
              hintText={t("organization.enter_designation")}
              labelText={t("global.designation")}
              fieldName={"designation"}
              value={item.designation}
              handleInputChange={function (event: React.ChangeEvent<HTMLInputElement>): void {
                handleInputChange(event, "designation", index);
              }}
              maxCharLength={DETAILS_MAX_CHAR_LENGTH}
              errorMessage={validationErrors?.[index]?.designation}
              isRequired
              isDisabled={isDisabled}
            />
          </div>
          <div className="flex gap-3 justify-center items-center mt-[22px]">
            <div className="w-full flex-grow">
              <InputField
                value={item.email}
                hintText={t("global.enter_email_address")}
                labelText={t("organization.contact_email")}
                fieldName={""}
                handleInputChange={function (event: React.ChangeEvent<HTMLInputElement>): void {
                  handleInputChange(event, "email", index);
                }}
                errorMessage={validationErrors?.[index]?.email}
                maxCharLength={DETAILS_MAX_CHAR_LENGTH}
                isRequired
                isDisabled={isDisabled}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-3 mt-[22px] sm rtl:space-x-reverse space-y-3 sm:space-y-0 ">
            <SingleSelect
              selectOption={item.countryCodeId}
              className="text-sm font-normal w-full"
              options={countryCodeOptions || []}
              labelText={t("global.country_code")}
              hintText={t("global.select")}
              isRequired
              errorMessage={validationErrors?.[index]?.countryCodeId}
              handleChange={function (value: string | number): void {
                handleCountryCodeSelection(value, index);
              }}
              isDisabled={isDisabled}
            />
            <InputField
              hintText={t("global.enter_contact_number")}
              labelText={t("global.contact_number")}
              fieldName="contactNumber"
              isRequired
              value={item.contactNumber}
              handleInputChange={function (event: React.ChangeEvent<HTMLInputElement>): void {
                handleInputChange(event, "contactNumber", index);
              }}
              errorMessage={validationErrors?.[index]?.contactNumber}
              maxCharLength={phoneMaxLimitChecker(item?.contactNumber ?? "")}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      </div>
      {!item.isPrimary && (
        <div className="flex justify-center items-center">
          <PrimaryButton
            title={"global.delete_contact"}
            handleClick={() => {
              onDeleteContact(index);
            }}
            startIcon="trashV1"
            className=" !h-10 !min-h-0 px-4 !min-w-[150px] !rounded-sm !mt-4 !text-xs font-medium bg-white !text-red-dark !border-red-dark hover:!bg-red-dark/10 hover:!border-red-dark/30 hover:!text-red-dark flex gap-3"
          />
        </div>
      )}
      <Divider className="mt-10 pb-10" />
    </div>
  );
};

export default ContactForm;
