import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function uploadFile(collaborationId: number, file: File) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const formData = new FormData();
  formData.append("file", file);
  const response = await apiClient.post(
    urls.collaborationForumFileUpload(collaborationId),
    formData,
    {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
    }
  );
  return response?.data?.data as FileDetails;
}

export function useForumFileUpload() {
  return useMutation(async ({ collaborationId, file }: { collaborationId: number; file: File }) => {
    return await uploadFile(collaborationId, file);
  });
}
