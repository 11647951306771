import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveCollaborationFunding(formData: CollaborationFunding, collaborationId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(urls.collaborationFunding(collaborationId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useSaveCollaborationFunding() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      formData,
      collaborationId,
    }: {
      formData: CollaborationFunding;
      collaborationId: number;
    }) => {
      const data = await saveCollaborationFunding(formData, collaborationId);
      queryClient.invalidateQueries(["collaboration-funding", collaborationId]);
      queryClient.invalidateQueries(["collaboration-about", collaborationId]);
      queryClient.invalidateQueries(["collaboration-preview-details", collaborationId]);
      return data;
    }
  );
}
