import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { LoaderModal, Title } from "components";
import { CHALLENGE_STATUS, INITIAL_SEARCH_PARAMS } from "utils/constants";
import {
  setCollaborationInfo,
  setCollaborationInfoItem,
  useCollaborationDataStore,
} from "store/useCollaborationDataStore";
import AboutForm from "features/Collaboration/CreateCollaboration/About/AboutForm";
import { showCollaborationFileUploadModal, showConfirmationModal } from "utils/confirmationModals";
import { useGetCollaborationAbout } from "features/Collaboration/api/useGetCollaborationAbout";

type AboutProps = {
  validationErrors: Record<string, string> | null;
};

export default function About({ validationErrors }: AboutProps) {
  const { t } = useTranslation();
  const { collaborationInfo } = useCollaborationDataStore();
  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);

  const { data: collaborationAbout, isLoading: isLoadingCollaborationAbout } =
    useGetCollaborationAbout();

  const isEditDisabled =
    collaborationInfo.collaborationStatusId === CHALLENGE_STATUS.Open ||
    collaborationInfo.collaborationStatusId === CHALLENGE_STATUS.Live ||
    collaborationInfo.collaborationStatusId === CHALLENGE_STATUS.Pending;

  const handleProfileClick = async () => {
    await showCollaborationFileUploadModal(
      function (url: string) {
        setCollaborationInfoItem(url, "coverImage");
      },
      true,
      collaborationInfo.id
    );
  };

  const handleDeleteClick = async () => {
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.deletion_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    setCollaborationInfoItem("", "coverImage");
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    key: keyof ChallengeBasic
  ) => {
    const value = event?.target?.value;
    setCollaborationInfoItem(value, key);
  };

  const handleRadioButtonSelection = (value: boolean) => {
    setCollaborationInfoItem(value, "isPrivate");
  };

  const handleDateInputChange = (key: keyof ChallengeBasic) => (date: string) => {
    setCollaborationInfoItem(date, key);
  };

  const handleTargetIndustrySelection = (value: OptionItem, key: keyof ChallengeBasic) => {
    setCollaborationInfoItem(value, key);
  };

  const eventHandlers = {
    handleProfileClick,
    handleInputChange,
    handleDeleteClick,
    handleRadioButtonSelection,
    handleDateInputChange,
    handleTargetIndustrySelection,
  };

  useEffect(() => {
    if (collaborationAbout) {
      setCollaborationInfo(collaborationAbout);
    }
  }, [collaborationAbout]);

  return (
    <div>
      <div className="bg-white rounded-md">
        <div className=" p-4 lg:p-5 flex items-center border-b border-gray-light">
          <SVGIcon name="personalDetails" />
          <Title
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            title={t("organization.about")}
          />
        </div>
        <AboutForm
          data={collaborationInfo}
          eventHandlers={eventHandlers}
          isEditDisabled={isEditDisabled}
          searchParamsRef={searchParamsRef}
          validationErrors={validationErrors}
        />
      </div>
      {isLoadingCollaborationAbout ? <LoaderModal /> : null}
    </div>
  );
}
