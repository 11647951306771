import SVGIcon from "assets/Icons";
import { useTranslation } from "react-i18next";
import { TradeMark } from "features/Home/TradeMark";
import { Title } from "components/Title";
import { isGlobalWebsite } from "utils/helpers";

type DiscoverMoreModalProps = {
  onclose: () => void;
};

function DiscoverMoreModal({ onclose }: DiscoverMoreModalProps) {
  const { t } = useTranslation();
  const isGlobal = isGlobalWebsite();
  return (
    <div className="fixed left-0 top-0 md:relative md:left-auto md:top-auto rtl:right-0">
      <div className="fixed max-h-[100vh] md:max-h-[90vh] md:relative h-screen md:h-auto  max-w-[100vw] w-[100vw] min-w-[100vw]  md:w-auto md:max-w-[754px] md:min-w-[754px] xl:max-w-[900px] xl:min-w-[900px]  bg-white py-[64px] pb-20 px-5 md:p-[60px] xl:p-20    md:!rounded-md overflow-auto !rounded-none xl:pr-[170px] ">
        <TradeMark
          title={
            isGlobal ? "home.global_consortium_heading" : "home.national_advanced_solution_portal"
          }
          className="text-skin-textPrimary relative font-semibold mb-5 text-[26px] leading-[34px] tracking-[-0.26px] xl:text-[29px] xl:leading-[44px] xl:tracking-[-0.29px;] "
        />
        <p className=" text-[15px] text-skin-textBase leading-[22px] md:text-base md:leading-[26px]">
          {!isGlobal
            ? t("home.the_national_advanced_solution_portal")
            : t("home.established_by_the_higher_college_technology_global_content")}

          <Title
            title={t("home.mission_statement")}
            className=" text-skin-textSecondary font-semibold text-base pb-2 pt-6"
          />
          {isGlobal
            ? t("home.we_aim_address_industry_challenges_global_content")
            : t("home.we_aim_address_industry_challenges")}

          {!isGlobal ? (
            <>
              <Title
                title={t("home.impact")}
                className=" text-skin-textSecondary font-semibold text-base pb-2 pt-6"
              />

              {t("home.as_our_platform_continues_to_grow")}
            </>
          ) : null}
        </p>
        <div className="modal-action">
          <button
            className="btn text-skin-textPrimary  top-5 right-5  !h-auto p-0 absolute !border-none !min-h-max !bg-transparent"
            onClick={onclose}
          >
            <SVGIcon name="h-modalClose" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default DiscoverMoreModal;
