import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function eventPublish(eventId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  const response = await apiClient.put(
    urls.eventPublish(eventId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return response?.data as ApiStatus | ApiError;
}

export function useEventPublish() {
  const qc = useQueryClient();
  return useMutation(async (eventId: number) => {
    const result = await eventPublish(eventId);
    qc.invalidateQueries({ queryKey: ["event-about", eventId] });
    qc.invalidateQueries({ queryKey: ["event-learn-more", eventId] });
    return result;
  });
}
