import { useInfiniteQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getResearchInstitutes({
  pageNumber,
  pageLimit,
  searchKeyword,
  isGlobal,
}: {
  pageNumber: number;
  pageLimit: number;
  searchKeyword?: string;
  isGlobal?: boolean;
}) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(
    urls.getResearchInstitute(pageNumber, pageLimit, searchKeyword ? searchKeyword : "", isGlobal),
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res?.data?.data;
}

export function useGetResearchInstitutes({
  searchKeyword,
  isGlobal,
}: {
  searchKeyword?: string;
  isGlobal?: boolean;
}) {
  return useInfiniteQuery(
    ["research-institutes", searchKeyword],
    async ({ pageParam = 1 }) => {
      const result = await getResearchInstitutes({
        pageNumber: pageParam,
        pageLimit: 5,
        searchKeyword,
        isGlobal,
      });
      return result;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.totalPages > allPages.length ? allPages?.length + 1 : undefined;
      },
      staleTime: 10 * 1000,
    }
  );
}
