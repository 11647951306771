import React from "react";
import { Title } from "components";

type DocViewProps = {
  url: string;
  fileName: string;
};

export function DocView({ url, fileName }: DocViewProps) {
  return (
    <div>
      <Title
        className="text-base font-medium text-black p-5 px-4 ltr:pr-16 rtl:pl-16 leading-normal ltr:text-left rtl:text-right border-b border-gray-light truncate titleTextTruncate max-w-[calc(100vw-24px)]"
        title={fileName}
      />
      <div className="max-h-[calc(100vh-220px)] justify-center flex items-center  flex-col">
        <iframe
          title={fileName}
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
          width="100%"
          height="600px"
        ></iframe>
      </div>
    </div>
  );
}
