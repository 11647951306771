import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function challengeEmailInvite(
  formData: Array<string>,
  challengeId: number,
  isFundingOrganization: boolean
) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(
    urls.challengeEmailInvite(challengeId, isFundingOrganization),
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus;
}

export function useChallengeEmailInvite(challengeId: number) {
  return useMutation(
    async ({
      formData,
      isFundingOrganization,
    }: {
      formData: Array<string>;
      isFundingOrganization: boolean;
    }) => {
      return await challengeEmailInvite(formData, challengeId, isFundingOrganization);
    }
  );
}
