import { useTranslation } from "react-i18next";
import { Title } from "components";
import Profile from "assets/images/avatar.svg";
import { getTimeDifference } from "utils/helpers";

type NotificationItemProps = {
  title: string;
  message: string;
  profileImage: string;
  createdOn: string;
  isRead: boolean;
};

export function NotificationItem({
  title,
  message,
  profileImage,
  createdOn,
  isRead,
}: NotificationItemProps) {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div
        className={` flex lg:w-full rtl:pl-2 relative after:absolute  after:w-2 after:h-2  after:-left-2 after:-top-2 ltr:ml-6 ltr:lg:ml-8 rtl:mr-6 rtl:lg:mr-8 mt-3 ${!isRead ? " after:rounded-full   after:bg-primary-medium " : ""
          }`}
      >
        <div className=" w-8 h-8 min-w-[32px]  rounded-full overflow-hidden">
          <img alt="Profile Picture" src={profileImage ? profileImage : Profile} />
        </div>
        <div className="flex flex-col flex-1 lg:flex-auto lg:w-full lg:max-w-[380px] ltr:text-left rtl:text-right ltr:pl-4 rtl:pr-4 justify-start pb-4 mb-2 border-b border-gray-104 ltr:pr-2 rtl:pl-2">
          <Title className=" text-black font-semibold text-sm" title={title} />
          <p
            className=" text-black font-normal mt-1 text-sm !justify-start WB_BW"
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
          <p className=" text-gray-103 mt-1 text-sm font-medium">
            {getTimeDifference(createdOn, i18n.language, t)}
          </p>
        </div>
      </div>
    </div>
  );
}
