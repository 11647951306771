import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveChallengeContacts(contactFormData: ChallengeContact[], challengeId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  const response = await apiClient.post(urls.challengeContacts(challengeId), contactFormData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useSaveChallengeContacts() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ formData, challengeId }: { formData: ChallengeContact[]; challengeId: number }) => {
      const result = await saveChallengeContacts(formData, challengeId);
      queryClient.invalidateQueries(["challenge-details", challengeId]);
      queryClient.invalidateQueries(["challenge-contacts", challengeId]);
      queryClient.invalidateQueries(["challenge-preview-details", challengeId]);
      return result;
    }
  );
}
