import Joi from "joi";
import { groupBy } from "utils/helpers";
import { challengeDetailsSchema } from "utils/validation/challengeDetailsValidation";

const challengeDetails = challengeDetailsSchema.keys({
  collaborationCompletionStatusId: Joi.number().optional(),
  collaborationStatusId: Joi.number().optional(),
});

export const collaborationAboutValidation = (collaborationAbout: CollaborationAbout) => {
  const result = challengeDetails.validate(collaborationAbout);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
