import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import {
  InputField,
  PrimaryButton,
  SubTitle,
  Chip,
  Title,
  SecondaryButton,
  ErrorText,
  LoaderModal,
} from "components";
import { useFundingOrganizationEmailInvite } from "features/Admin/api/useFundingOrganizationEmailInvite";
import { EMAIL_MAX_CHAR_LENGTH } from "utils/constants";
import { emailValidation } from "utils/validation/authValidation";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";

type EmailInviteProps = {
  onCancel: () => void;
};

export default function EmailInvite({ onCancel }: EmailInviteProps) {
  const { t } = useTranslation();

  const [enteredValue, setEnteredValue] = useState<EmailData>({ email: "" });
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();
  const [emails, setEmails] = useState<Array<string>>([]);
  const [error, setError] = useState({ message: "", data: [] });

  const {
    mutateAsync: fundingOrganizationEmailInvite,
    isLoading: isFundingOrganizationEmailInviteLoading,
  } = useFundingOrganizationEmailInvite();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value?.trim();
    setEnteredValue({ email: value });
    setValidationErrors(null);
    setError({ message: "", data: [] });
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      const error = emailValidation(enteredValue);
      if (error) {
        setValidationErrors(error);
        return;
      }
      setValidationErrors(null);
      setError({ message: "", data: [] });

      if (emails?.length === 0) {
        setEmails([enteredValue.email?.trim()]);
      } else {
        const isExist = [...emails]?.find((item) => item === enteredValue?.email?.trim());
        if (!isExist) {
          const tempEmails = [...emails, enteredValue?.email?.trim()];
          setEmails([...tempEmails]);
        }
      }
      setEnteredValue({ email: "" });
    }
  };
  const handleCancel = () => {
    onCancel();
  };
  const handleProceed = async () => {
    setError({ message: "", data: [] });
    if (emails?.length <= 0) {
      setError({ message: "admin.at_least_one_email_required_error_message", data: [] });
      return;
    }
    try {
      const result = await fundingOrganizationEmailInvite(emails);
      showInfoToastMessage({
        iconType: ToasterIconType.MAIL,
        message: result?.message,
      });
      onCancel();
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const handleRemove = (event: React.MouseEvent<HTMLElement>, index: number) => {
    event.preventDefault();
    event.stopPropagation();
    const tempEmails = [...emails];
    tempEmails?.splice(index, 1);
    setEmails(tempEmails);
  };

  return (
    <div className="max-w-[100vw] w-[100vw] min-w-[100vw]  lg:w-auto lg:max-w-[470px] mx-0 lg:mx-2  lg:min-w-[470px]  bg-white rounded-md rounded-b-none lg:rounded-b-md px-4 pb-4 pt-12 lg:pt-[50px]  lg:p-[50px] lg:pb-[50px] lg:!px-8 sm:px-[50px] sm:p-[50px] flex flex-col relative text-center animate__animated animate__zoomIn max-h-[calc(100vh-50px)] overflow-auto">
      <div className=" flex justify-center flex-col items-center">
        <SVGIcon name="inviteViaMail" />
        <Title
          className="mt-10 text-black font-semibold text-xl mb-1.5"
          title={t("admin.invite_users")}
        />
        <SubTitle
          className="font-normal text-base text-primary-text text-center mx-8"
          title={t("admin.funding_organization_invite_email_message")}
        />
      </div>
      <div className="mt-8 w-full">
        <InputField
          hintText={
            emails?.length >= 10
              ? t("admin.you_can_send_up_to_ten_invites_at_once")
              : t("global.enter_email_address_and_press_enter")
          }
          labelText={t("global.email_address")}
          handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(event)
          }
          fieldName="email"
          isRequired
          handleKeyDown={handleEnterKeyPress}
          maxCharLength={EMAIL_MAX_CHAR_LENGTH}
          isDisabled={emails?.length >= 10}
          value={enteredValue?.email}
          errorMessage={validationErrors?.email}
        />
        {emails?.length > 0 ? (
          <div className="flex flex-wrap pt-6">
            {emails?.map((item, index) => (
              <Chip
                key={item + index}
                text={item}
                handleClose={(event: React.MouseEvent<HTMLElement>) => handleRemove(event, index)}
              />
            ))}
          </div>
        ) : null}
      </div>
      {error?.message ? <ErrorText errorMessage={error?.message} /> : null}
      {error?.data?.length > 0
        ? error.data.map((item) => <ErrorText key={item} errorMessage={item} />)
        : null}
      <div className="flex justify-center items-center mt-[47px] gap-2">
        <div className="w-[120px]">
          <SecondaryButton title={"global.close"} handleClick={handleCancel} className="w-full" />
        </div>
        <div className="w-[120px]">
          <PrimaryButton
            title={"global.send_invite"}
            handleClick={handleProceed}
            className="w-full"
          />
        </div>
      </div>
      {isFundingOrganizationEmailInviteLoading ? <LoaderModal /> : null}
    </div>
  );
}
