import React, { useState, useMemo, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoaderModal, Header, ScholarProfileDetails } from "components";
import { showConfirmationModal } from "utils/confirmationModals";
import {
  ToasterIconType,
  showErrorToastMessage,
  showSuccessToastMessage,
} from "utils/toasterMessage";
import {
  COLLABORATION_USER_STATUS,
  RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS,
} from "utils/constants";
import { scrollToReference } from "utils/helpers";
import Navigator from "features/Admin/Users/Navigator";
import { useGetResearchScholarDetails } from "features/Admin/api/useGetResearchScholarDetails";
import { useStickyScroll } from "hooks/useStickyScroll";
import { useManageResearchScholarRequest } from "features/Collaboration/api/useManageResearchScholarRequest";
import { useGetUserCollaborationStatus } from "features/Collaboration/api/useGetUserCollaborationStatus";
import { setSelectedSideMenu } from "store/useSidebarStore";

const ScholarPreview = () => {
  const { collaborationId, researchScholarId } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { scroll } = useStickyScroll();
  const personalDetailsRef = useRef(null);
  const educationRef = useRef(null);
  const workRef = useRef(null);
  const socialRef = useRef(null);
  const researchProjectRef = useRef(null);

  const collaborationDataId = Number(collaborationId);
  const userStatusRequestParams = {
    collaborationId: collaborationDataId ?? "",
    userId: researchScholarId ?? "",
  };

  const { data: userData, isLoading: userDataLoading } = useGetResearchScholarDetails(
    researchScholarId ?? ""
  );

  const [activeMenu, setActiveMenu] = useState<number>(0);

  const { data: userStatus, isLoading: userStatusLoading } =
    useGetUserCollaborationStatus(userStatusRequestParams);
  const { mutateAsync: manageRequest, isLoading: isRequestLoading } =
    useManageResearchScholarRequest();
  const [isViewMore, setIsViewMore] = useState<boolean>(false);

  const getBreadCrumbTitle = () => {
    if (
      userStatus?.statusId === COLLABORATION_USER_STATUS.Pending ||
      userStatus?.statusId === COLLABORATION_USER_STATUS.Nominated
    ) {
      return "global.pending_approval";
    } else if (userStatus?.statusId === COLLABORATION_USER_STATUS.Approved) {
      return "global.accepted_scholars";
    } else {
      return "";
    }
  };

  const linkList: Array<LinkItem> = [
    {
      title: location.pathname.includes("explore")
        ? "research_institute.explore_collaborations"
        : "global.collaborations",
    },
    {
      title: "research_institute.view_collaboration",
    },

    {
      title: getBreadCrumbTitle(),
    },
  ];

  const handleDecline = async () => {
    try {
      const result = await manageRequest({
        collaborationId: collaborationDataId,
        scholarId: researchScholarId ?? "",
        status: COLLABORATION_USER_STATUS.Declined,
      });
      if (result) {
        navigate(-1);
      }
      showErrorToastMessage({
        message: result.message,
        iconType: ToasterIconType.DECLINED,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };
  const handleApprove = async () => {
    try {
      const result = await manageRequest({
        collaborationId: collaborationDataId,
        scholarId: researchScholarId ?? "",
        status: COLLABORATION_USER_STATUS.Approved,
      });
      if (result) {
        navigate(-1);
      }
      showSuccessToastMessage({
        message: result.message,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const handleDelete = async () => {
    const result = await showConfirmationModal({
      title: "global.remove_user",
      message: "organization.remove_user_collaboration_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.remove",
      modalIcon: "disableUser",
    });
    if (!result) {
      return;
    } else {
      try {
        const result = await manageRequest({
          collaborationId: collaborationDataId,
          scholarId: researchScholarId ?? "",
          status: COLLABORATION_USER_STATUS.Removed,
        });
        if (result) {
          navigate(-1);
        }
        showSuccessToastMessage({
          message: result.message,
        });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };

  const handleSettingsMenuClick = async (type: string) => {
    if (type === "delete") {
      handleDelete();
    }
  };

  const handleViewClick = () => {
    setIsViewMore(!isViewMore);
    scrollToReference(personalDetailsRef);
  };

  const handleNavigationClick = (value: number, index: number) => {
    setActiveMenu(index);
    if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Personal) {
      handleScroll(personalDetailsRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Education) {
      handleScroll(educationRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Work) {
      handleScroll(workRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Social) {
      handleScroll(socialRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Research) {
      handleScroll(researchProjectRef);
    }
  };

  const handleScroll = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 100,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const navigationItems: Array<RecordItem> = useMemo(() => {
    const tempItems = [
      {
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Personal,
        label: "global.personal_details",
      },
      {
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Education,
        label: "global.highest_education",
      },
      {
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Work,
        label: "global.work_experience",
      },
    ];
    if (
      userData?.usersocialprofile?.googleScholarURL ||
      userData?.usersocialprofile?.linkedInURL ||
      userData?.usersocialprofile?.researchGateURL
    ) {
      tempItems.push({
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Social,
        label: "global.social_profiles",
      });
    }
    if (userData?.researchprojects?.length) {
      tempItems.push({
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Research,
        label: "global.research_projects",
      });
    }
    return tempItems;
  }, [userData]);

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.collaborations",
    });
  }, []);

  return (
    <>
      <Header
        title={t("global.profile")}
        linkList={linkList}
        className={
          scroll
            ? "  bg-primary-medium lg:bg-white animate__animated animate__fadeInDown lg:shadow-sticky lg:!py-[26px] lg:!h-[90px] fixed stickyHeader"
            : "fixed bg-primary-medium lg:bg-gray-light"
        }
      />
      <div
        className="adminDetails w-full mt-[170px] mb-20 lg:mb-0 lg:mt-0 lg:pt-[118px] pb-[45px] items-baseline px-4 lg:px-10 lg:min-h-screen flex flex-col  overflow-auto lg:ltr:pr-[30px] lg:rtl:pl-[30px]"
        ref={personalDetailsRef}
      >
        <ScholarProfileDetails
          userData={userData}
          researchProjectRef={researchProjectRef}
          handleSettingsMenuClick={handleSettingsMenuClick}
          isViewMore={isViewMore}
          handleViewClick={handleViewClick}
          educationRef={educationRef}
          workRef={workRef}
          socialRef={socialRef}
          showSettingsMenu={userStatus?.statusId === COLLABORATION_USER_STATUS.Approved}
          isCollaborationView={true}
        />
        <Navigator
          isButtonVisible={
            userStatus?.statusId === COLLABORATION_USER_STATUS.Pending ||
            userStatus?.statusId === COLLABORATION_USER_STATUS.Nominated
          }
          handleDecline={handleDecline}
          handleApprove={handleApprove}
          handleNavigationClick={(value: number, index: number) =>
            handleNavigationClick(value, index)
          }
          navigationItems={navigationItems}
          scroll={scroll}
          activeMenu={activeMenu}
        />
        {userDataLoading || isRequestLoading || userStatusLoading ? <LoaderModal /> : null}
      </div>
    </>
  );
};

export default ScholarPreview;
