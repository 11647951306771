import Image from "./Image";
import { PDFView } from "./PDFView";
import { DocView } from "./DocView";
import Audio from "./Audio";
import Video from "./Video";
import { IconTypeButton } from "components";

type DocPreviewProps = {
  url: string;
  fileName: string;
  onClose: () => void;
};

export function DocPreview({ url, fileName, onClose }: DocPreviewProps) {
  const extension = fileName.split(".").pop()?.toLowerCase();
  const preview = () => {
    switch (extension) {
      case "image/jpeg":
      case "image/jpg":
      case "image/png":
      case "image/webp":
      case "image/gif":
      case "jpg":
      case "jpeg":
      case "png":
      case "webp":
      case "gif":
        return <Image url={url} fileName={fileName} />;
      case "mp4":
      case "video/mp4":
      case "webm":
      case "video/webm":
      case "ogv":
      case "video/ogg":
      case "3gp":
      case "video/3gp":
        return <Video url={url} fileName={fileName} />;
      case "audio/mpeg":
      case "audio/mp3":
      case "audio/x-m4a":
      case "mp3":
        return <Audio url={url} fileName={fileName} />;
      case "ppt":
      case "pptx":
      case "doc":
      case "docx":
      case "xlsx":
        return <DocView url={url} fileName={fileName} />;
      case "pdf":
      case "application/pdf":
        return <PDFView url={url} fileName={fileName} />;
      default:
        return "No preview available";
    }
  };
  return (
    <div className=" w-full lg:W-[800px] lg:max-w-[800px]  lg:min-w-[800px]  bg-white rounded-md  flex flex-col items-center justify-center relative text-center animate__animated animate__zoomIn">
      <IconTypeButton
        className="absolute btn btn-link text-black p-0 w-auto h-auto min-h-fit top-[16px] ltr:right-6 rtl:left-6 z-[11]"
        handleClick={onClose}
        iconName="close"
      />
      <div className="w-full docPreview">{preview()}</div>
    </div>
  );
}
