import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Area } from "react-easy-crop/types";
import { LoaderModal, CoverImageSelector } from "components";
import { ACCEPTED_IMAGE_TYPES, IMAGE_MAX_FILE_SIZE } from "utils/constants";
import { ToasterIconType, showErrorToastMessage } from "utils/toasterMessage";
import { useUploadChallengeFile } from "features/Organization/api/useUploadChallengeDocument";
import { getCroppedImg } from "utils/helpers";

type UploadChallengeFileProps = {
  onUpload: () => void;
  onRemove: () => void;
  onClose: () => void;
  setFileUrl: (url: string) => void;
  isCoverImage: boolean;
  challengeId: number;
};

export default function UploadChallengeFile({
  onUpload,
  onRemove,
  onClose,
  setFileUrl,
  isCoverImage,
  challengeId,
}: UploadChallengeFileProps) {
  const { t } = useTranslation();
  const [imageURL, setImageURL] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<{ url: string; fileName: string }>();
  const [error, setError] = useState("");

  const { mutateAsync: uploadChallengeFile, isLoading } = useUploadChallengeFile();

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileSelected = event.target?.files?.[0];
    if (fileSelected && fileSelected.size > IMAGE_MAX_FILE_SIZE) {
      event.target.value = "";
      return showErrorToastMessage({
        message: t("global.image_file_size_exceed"),
      });
    }
    if (fileSelected?.type && ACCEPTED_IMAGE_TYPES.includes(fileSelected?.type)) {
      setSelectedFile({ url: URL.createObjectURL(fileSelected), fileName: fileSelected?.name });
      setError("");
    } else {
      showErrorToastMessage({
        iconType: ToasterIconType.ALERT,
        message: t("global.profile_image_type_error"),
      });
    }
    event.target.value = "";
  };

  const saveSelectedFile = async () => {
    if (!selectedFile?.url) {
      setError("global.please_upload_a_photo_to_continue");
      return;
    }
    try {
      const croppedImage = await getCroppedImg(
        selectedFile?.url,
        croppedAreaPixels,
        selectedFile?.fileName
      );
      const result = await uploadChallengeFile({ file: croppedImage, isCoverImage, challengeId });
      setFileUrl(result?.fileUrl ?? "");
    } catch (error) {
      showErrorToastMessage({
        iconType: ToasterIconType.ALERT,
        message: t("global.something_went_wrong"),
      });
    } finally {
      onUpload();
    }
  };

  const onFileRemove = () => {
    if (!imageURL && !selectedFile?.url) {
      setError("global.no_photo_uploaded");
      return;
    }
    onRemove();
    setImageURL("");
    setSelectedFile({ url: "", fileName: "" });
  };

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const updateCroppedAreaPixels = (croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <>
      <CoverImageSelector
        updateCroppedAreaPixels={updateCroppedAreaPixels}
        onClose={onClose}
        imageURL={imageURL}
        selectedFile={selectedFile}
        handleFileUpload={handleFileUpload}
        error={error}
        onFileRemove={onFileRemove}
        saveSelectedFile={saveSelectedFile}
      />
      {isLoading ? <LoaderModal /> : null}
    </>
  );
}
