import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import { saveAs } from "file-saver";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function downloadCSVTemplate() {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  const response = await apiClient.get(urls.downloadCSVTemplate(), {
    headers: { Authorization: `Bearer ${token}` },
  });
  const url = response.data?.data?.fileUrl;
  saveAs(url, "BulkInviteTemplate.csv");
}

export function useDownloadCSVTemplate() {
  return useMutation(async () => {
    return downloadCSVTemplate();
  });
}
