import React, { useMemo, useState } from "react";
import { SearchBar } from "components";
import SearchResultModal from "features/GlobalSearch/SearchResultModal";
import { useOutsideClick } from "hooks/useOutsideClick";
import { useGetSearchResults } from "features/GlobalSearch/api/useGetSearchResults";
import { useUserDataStore } from "store/useUserDataStore";
import { USERS } from "utils/constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { clearFilterDataStore } from "store/useFilterDataStore";
import { useQueryClient } from "@tanstack/react-query";

type GlobalSearchProps = {
  className?: string;
};
export default function GlobalSearch({ className }: GlobalSearchProps) {
  const { isOpen, setIsOpen, dropDownRef } = useOutsideClick();
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const { userInfo } = useUserDataStore();
  const [searchParams] = useSearchParams();
  const searchKeyword = searchParams.get("key");
  const queryClient = useQueryClient();

  const [requestParams, setRequestParams] = useState<GlobalSearchParams>({
    pageNumber: 1,
    pageLimit: 4,
    searchValue: "",
    countryId: [],
    researchAreaId: [],
    targetIndustryId: [],
    userType: 0,
    searchType: "users",
    isGlobalSearch: false,
  });

  const { data, isLoading } = useGetSearchResults(requestParams);

  const [keyword, setKeyword] = useState<string>("");

  const searchData = useMemo(() => {
    const tempData: GlobalSearchResult = { searchDetails: [], totalCount: 0, totalPages: 0 };
    if (data?.pages && data?.pages[0]) {
      tempData.totalCount = data.pages[0]?.totalCount;
      tempData.totalPages = data.pages[0]?.totalPages;
      const searchDetails = data.pages
        .map((item) => {
          return item.searchDetails;
        })
        .flat();
      tempData.searchDetails = searchDetails;
    }
    setIsOpen(true);
    return tempData;
  }, [data]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^\w\s]/gi, "");
    setKeyword(value);
    if (value?.trim()?.length >= 3) {
      setRequestParams((prevState) => ({ ...prevState, searchValue: value }));
    }
  };

  const getUserType = () => {
    if (userInfo?.userType === USERS.ResearchScholar) {
      return "research-scholar";
    } else if (userInfo?.userType === USERS.Organization) {
      return "organization";
    } else if (userInfo?.userType === USERS.Admin) {
      return "admin";
    } else if (userInfo?.userType === USERS.Funding) {
      return "funding-organization";
    } else if (userInfo?.userType === USERS.ResearchInstitute) {
      return "research-institute";
    } else {
      return "";
    }
  };

  const handleShowMoreClick = () => {
    clearFilterDataStore();
    queryClient.resetQueries(["search-results"]);
    setIsOpen(false);
    if (pathName === `/${getUserType()}/search-results` && keyword === searchKeyword) {
      navigate(0);
    }
  };

  const handleEnterKeyPress = () => {
    queryClient.resetQueries(["search-results"]);
    setIsOpen(false);
  };

  return (
    <div className={`relative ${className}`} ref={dropDownRef}>
      <div onClick={() => setIsOpen((prev) => !prev)} className={`${className}`}>
        <SearchBar
          handleInputChange={handleInputChange}
          hintText="global.search"
          value={keyword}
          maxCharLength={200}
          user={getUserType()}
          handleEnterKeyPress={handleEnterKeyPress}
        />
      </div>
      {searchData?.searchDetails && isOpen && keyword?.trim()?.length >= 3 ? (
        <SearchResultModal
          data={searchData?.searchDetails}
          keyword={keyword}
          user={getUserType()}
          isLoading={isLoading}
          handleShowMoreClick={() => handleShowMoreClick()}
        />
      ) : null}
    </div>
  );
}
