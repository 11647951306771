import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoaderModal } from "components";
import { setDocumentsInfo } from "store/useOrganizationDataStore";
import { USER_STATUS } from "utils/constants";
import { useGetUploads } from "features/Organization/api/useGetUploads";
import DocumentList from "features/Organization/Profile/Documents/DocumentsList";
import useInvalidateQuery from "hooks/useInvalidateQuery";

type DocumentsProps = {
  setIsUploading: (status: boolean) => void;
};

export default function Documents({ setIsUploading }: DocumentsProps) {
  const { i18n } = useTranslation();
  const { organizationId } = useParams();
  const { data: files, isLoading: isLoadingFiles } = useGetUploads(organizationId ?? "");
  useInvalidateQuery("organization-details", organizationId ?? "");

  const isReadOnly = files?.profileStatusId === USER_STATUS.PendingApproval;

  useEffect(() => {
    if (files && files?.documents?.length > 0) {
      setDocumentsInfo(files?.documents);
    } else {
      setDocumentsInfo([]);
    }
  }, [files, i18n.language]);

  return (
    <>
      <DocumentList isReadOnly={isReadOnly} setIsUploading={setIsUploading} />
      {isLoadingFiles ? <LoaderModal /> : null}
    </>
  );
}
