import { Modal } from "react-daisyui";
import Loader from "components/Loader";

export function LoaderModal() {
  return (
    <Modal open className="loaderModal animate__animated animate__zoomIn">
      <Loader />
    </Modal>
  );
}
