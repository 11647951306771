import { useNavigate } from "react-router-dom";
import { Label, Title } from "components";
import { displayDateFormat } from "utils/helpers";
import { showChallengeDetailsModal } from "utils/confirmationModals";
import DefaultCoverImage from "assets/images/home-images/challengeDefault.png";

type ChallengeItemProps = {
  data: Challenge;
};

export default function ChallengeItem({ data }: ChallengeItemProps) {
  const navigate = useNavigate();

  const handleChallengeClick = async () => {
    await showChallengeDetailsModal(data, handleNavigateToLogin);
  };

  const handleNavigateToLogin = (challengeId: number) => {
    navigate(`/login/challenges/${challengeId}`);
  };

  return (
    <div
      className="w-full flex flex-col items-start bg-white rounded-md truncate"
      onClick={handleChallengeClick}
    >
      <img
        src={data?.coverImage ? data?.coverImage : DefaultCoverImage}
        alt="cover-image"
        className="!h-40 w-full rounded-md object-cover"
      />
      <div className=" p-5 truncate w-full challengeCard">
        <div className="flex gap-4 items-center truncate mb-2">
          <div className="flex gap-2 w-full ">
            {data?.targetIndustry ? (
              <span className="text-sm font-medium leading-5 tracking-[-0.1px] rounded-[5px] px-3 py-1 w-fit rtl:ml-auto bg-skin-primaryLight text-skin-primaryMedium truncate dirRTL_LTR">
                {data?.targetIndustry}
              </span>
            ) : null}
          </div>
        </div>
        <div className="truncate flex flex-col items-start">
          <Title
            title={data?.title}
            className="text-gray-109 font-bold text-base  truncate w-full  dirRTL_LTR uploadFileItem"
          />
        </div>
        <Label
          text={`${displayDateFormat(data?.startDate)} - ${displayDateFormat(data?.endDate)}`}
          className="text-black text-sm font-normal !justify-start mt-1"
        />
      </div>
    </div>
  );
}
