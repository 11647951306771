import React from "react";
import { useTranslation } from "react-i18next";

type HeaderItemProps = {
  text: string;
  handleClick: () => void;
  isActive: boolean;
};

export function HeaderItem({ text, handleClick, isActive }: HeaderItemProps) {
  const { t } = useTranslation();

  return (
    <li className={`${isActive ? "active" : ""}`} onClick={handleClick}>
      <span>{t(text)}</span>
    </li>
  );
}
