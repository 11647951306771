import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import {
  ButtonWithSubtitle,
  Title,
  SecondaryButton,
  RichTextEditor,
  LoaderModal,
} from "components";
import { CHALLENGE_STATUS, DEFAULT_EDITOR_EMPTY_STATE } from "utils/constants";
import {
  setFundingInfo,
  setFundingInfoItem,
  useCollaborationDataStore,
} from "store/useCollaborationDataStore";
import { useGetCollaborationFunding } from "features/Collaboration/api/useGetCollaborationFunding";
import { useEffect } from "react";

type FundingProps = {
  validationErrors: Record<string, string> | null;
};

const Funding = ({ validationErrors }: FundingProps) => {
  const { t } = useTranslation();
  const { funding } = useCollaborationDataStore().funding;
  const { data: fundingData, isLoading: isCollaborationFundingLoading } =
    useGetCollaborationFunding();
  const { collaborationInfo } = useCollaborationDataStore();
  const isPending = collaborationInfo.collaborationStatusId === CHALLENGE_STATUS.Pending;

  const handleResetFunding = () => {
    setFundingInfoItem(null, "funding");
  };

  const handleAddFunding = () => {
    setFundingInfoItem(DEFAULT_EDITOR_EMPTY_STATE, "funding");
  };

  useEffect(() => {
    if (fundingData) {
      setFundingInfo(fundingData);
    }
  }, [fundingData]);

  return (
    <div>
      <div className=" flex items-center justify-between mb-6 flex-wrap pb-8 p-5 !py-3 border-b border-gray-light">
        <div className="flex items-center ltr:mr-auto rtl:ml-auto">
          <SVGIcon name="fundingV1" />
          <Title
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            title={t("organization.funding")}
          />
        </div>
        {funding && (
          <SecondaryButton
            title={"organization.remove_funding"}
            handleClick={handleResetFunding}
            className=" !h-10 !min-h-0 !w-[99px] !min-w-[150px] !text-sm !px-0 font-medium bg-white hover:!text-white flex gap-3"
            disabled={isPending}
          />
        )}
      </div>

      {!funding ? (
        <div className="px-5 mt-6 w-full">
          <ButtonWithSubtitle
            title={"organization.click_to_enable_funding"}
            subtitle={"organization.skip_funding"}
            handleClick={handleAddFunding}
            isDisabled={isPending}
          />
        </div>
      ) : (
        <div>
          <div
            className={`border border-[#7890A1] rounded-md my-6 mx-5 ${
              validationErrors ? "!border-red-200 " : "border-primary-light"
            }`}
          >
            <RichTextEditor
              key={funding && funding.length !== fundingData?.funding?.length ? "reset" : ""}
              text={funding}
              handleTextChange={(text) => {
                setFundingInfoItem(text, "funding");
              }}
              isDisabled={isPending}
            />
          </div>
        </div>
      )}
      {isCollaborationFundingLoading ? <LoaderModal /> : null}
    </div>
  );
};

export default Funding;
