import { useInfiniteQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { PAGE_LIMIT } from "utils/constants";
import { urls } from "utils/urls";

export async function getForums({
  pageNumber,
  pageLimit,
  userId,
  isChallenge,
}: {
  pageNumber: number;
  pageLimit: number;
  userId: string;
  isChallenge: boolean;
}) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.forums(userId, pageNumber, pageLimit, isChallenge), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res?.data?.data as Forums | ApiError;
}

export function useGetForums(isChallenge: boolean) {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId ? userInfo?.userId : "";
  return useInfiniteQuery(
    ["forums", userId, isChallenge],
    async ({ pageParam = 1 }) => {
      const result = await getForums({
        pageNumber: pageParam,
        pageLimit: PAGE_LIMIT,
        userId,
        isChallenge,
      });
      return result;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.totalPages > allPages.length ? allPages?.length + 1 : undefined;
      },
      refetchOnWindowFocus: true,
    }
  );
}
