import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PrimaryButton,
  UploadButton,
  Title,
  SecondaryButton,
  RichTextEditor,
  LoaderModal,
  Label,
  UploadedFileItem,
} from "components";
import { MAX_FILE_SIZE } from "utils/constants";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { showConfirmationModal } from "utils/confirmationModals";
import { getPlainText, isEditorEmpty } from "utils/helpers";
import { useCreatePost } from "features/Collaboration/api/useCreatePost";
import { useForumFileUpload } from "features/Collaboration/api/useForumFileUpload";
import { useDeleteForumFile } from "features/Collaboration/api/useDeleteForumFile";


type CreatePostProps = {
  collaborationId: number;
  handleDiscardPost: () => void;
  handlePost: () => void;
};

export const CreatePost = ({ collaborationId, handleDiscardPost, handlePost }: CreatePostProps) => {
  const { t } = useTranslation();
  const pathname = window.location.pathname;
  const isExplore = pathname.includes("explore");

  const [data, setData] = useState<Post>({
    post: "",
    attachments: [],
    isExplore: isExplore,
  });

  const { mutateAsync: createPost, isLoading: isPostLoading } = useCreatePost();
  const { mutateAsync: fileUpload, isLoading: isFileUploading } = useForumFileUpload();
  const { mutateAsync: deleteFile, isLoading: isFileDeleting } = useDeleteForumFile();

  const handlePostDetails = async () => {
    if (!isEditorEmpty(data?.post)) {
      return showErrorToastMessage({
        message: t("forum.post_cannot_be_empty"),
      });
    }
    if (collaborationId && data?.post?.length > 0 && getPlainText(data?.post) > 0) {
      try {
        const result = await createPost({ collaborationId, formData: data });
        handlePost();
        showSuccessToastMessage({ message: result?.message });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    } else {
      showErrorToastMessage({
        message: t("forum.post_cannot_be_empty"),
      });
    }
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event?.target?.files?.[0];
    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        event.target.value = "";
        return showErrorToastMessage({
          message: t("research_scholar.uploaded_files_size_error_message"),
        });
      }
      const fileName = selectedFile.name;
      const isFileExist = data?.attachments?.find((item) => item.name === fileName);
      if (isFileExist) {
        showErrorToastMessage({
          message: t("research_scholar.multiple_same_name_file_error_message"),
        });
        event.target.value = "";
        return;
      }
      try {
        const file = { id: -1, name: selectedFile?.name, fileUrl: "", size: selectedFile?.size };
        const files = data?.attachments ? [...data.attachments, file] : [file];
        setData((prevState) => ({ ...prevState, attachments: files }));
        const result = await fileUpload({
          collaborationId,
          file: selectedFile,
        });
        if (result) {
          const files = data?.attachments ? [...data.attachments, result] : [result];
          setData((prevState) => ({ ...prevState, attachments: files }));
        }
      } catch (error) {
        showErrorToastMessage({
          message: t("global.something_went_wrong"),
        });
      } finally {
        event.target.value = "";
      }
    }
  };

  const handleRemove = async (event: React.MouseEvent<HTMLButtonElement>, item: FileDetails) => {
    event.stopPropagation();
    event.preventDefault();
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.deletion_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      if (item.id) {
        const fileDetails = {
          id: item.id,
          name: item.name,
          fileUrl: item.fileUrl,
        };
        try {
          const response = await deleteFile({ collaborationId, fileDetails });
          const files = data?.attachments;
          const newFiles = files?.filter((file) => file.fileUrl !== item?.fileUrl);
          if (newFiles) {
            setData((prevState) => ({ ...prevState, attachments: newFiles }));
          }
          showSuccessToastMessage({
            message: response.message,
          });
        } catch (error: ApiError) {
          showErrorToastMessage({
            message:
              error.response.data.status === 400
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
        }
      } else {
        const files = data?.attachments;
        const newFiles = files?.filter((file) => file.fileUrl !== item?.fileUrl);
        if (newFiles) {
          setData((prevState) => ({ ...prevState, attachments: newFiles }));
        }
        showSuccessToastMessage({
          message: t("global.document_deleted_successfully"),
        });
      }
    }
  };

  return (
    <>
      <div className=" w-full my-4 mt-0 char">
        <div className="w-full bg-white rounded-md p-6">
          <div className="flex flex-col gap-1 mb-4">
            <Title
              title={t("forum.create_your_post")}
              className="font-semibold text-sm text-black truncate dirRTL_LTR"
            />
            <Label
              text={t("forum.once_you_post_it_cannot_be_edited_later")}
              className="font-normal text-xs text-gray-107"
            />
          </div>
          <div className="border rounded-md border-[#7890A1]">
            <RichTextEditor
              key={"create-post"}
              text={data?.post}
              placeholder={t("global.enter_your_text_here")}
              handleTextChange={(text) => setData((prevState) => ({ ...prevState, post: text }))}
            />
          </div>
          {/* Attachments start */}
          {data?.attachments ? (
            <div>
              {data?.attachments?.length > 0 ? (
                <Title
                  title={t("global.attachments")}
                  className="text-sm text-primary-light font-normal mb-1 mt-4"
                />
              ) : null}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2.5 mt-2.5">
                {data?.attachments?.map((attaches) => (
                  <UploadedFileItem
                    key={attaches.name + new Date()}
                    isFileUploading={isFileUploading}
                    handleRemove={(event: React.MouseEvent<HTMLButtonElement>) =>
                      handleRemove(event, attaches)
                    }
                    file={attaches}
                  />
                ))}
              </div>
            </div>
          ) : null}
          {/* Attachments end */}
          <div className="flex justify-between items-center gap-3 mt-5">
            <UploadButton
              label={"forum.attach_files"}
              icon="upload"
              handleFileUpload={handleUpload}
              disabled={
                !!((data && data.attachments && data.attachments.length >= 5) || isFileUploading)
              }
            />
            <div className="flex gap-2 justify-center items-center w-full lg:w-auto ">
              <SecondaryButton
                title={"forum.discard_post"}
                handleClick={handleDiscardPost}
                className="!h-[40px] min-h-max flex-1 lg:flex-auto px-1.5  md:px-4 text-xs md:text-sm"
                disabled={isFileUploading}
              />
              <PrimaryButton
                title={"forum.post"}
                handleClick={handlePostDetails}
                className="!h-[40px] min-h-max flex-1 lg:flex-auto px-1.5  md:px-4 text-xs md:text-sm"
                disabled={isFileUploading}
              />
            </div>
          </div>
        </div>
      </div>
      {isPostLoading || isFileDeleting ? <LoaderModal /> : null}
    </>
  );
};
