import { useMutation } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function signUp(formData: FundingOrganizationBasicFields) {
    const res = await apiClient.post(urls.fundingOrganizationSignUp(), formData);
    return res.data as SignUpResponse | ApiError    
}

export function useSignUp(){
    return useMutation(async (formData: FundingOrganizationBasicFields)=>{
        return await signUp(formData)
    })
}