import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FilterTab, Header, NoPermissionCard, RadioButton } from "components";
import CollaborationForumBar from "features/Messages/CollaborationForumBar";
import CollaborationForum from "features/Messages/CollaborationForum";
import InterestsBar from "features/Messages/InterestsBar";
import MessageBar from "features/Messages/MessageBar";
import Interests from "features/Messages/Interests";
import ForumBar from "features/Messages/ForumBar";
import Forum from "features/Messages/Forum";
import Chats from "features/Messages/Chats";
import { useMasterDataStore } from "store/useMasterDataStore";
import { setSelectedSideMenu } from "store/useSidebarStore";
import { useUserDataStore } from "store/useUserDataStore";
import { useStickyScroll } from "hooks/useStickyScroll";
import { FORUM_TYPES, USERS } from "utils/constants";
import { useGetForums } from "features/Messages/api/useGetForums";

export default function Messages() {
  const { t } = useTranslation();
  const { scroll } = useStickyScroll();
  const { userInfo } = useUserDataStore();
  const { permissions } = useMasterDataStore();
  const [isChallenge, setIsChallenge] = useState(true);
  const { data } = useGetForums(isChallenge);
  const [activeTab, setActiveTab] = useState<number>(
    userInfo?.userType === USERS.Organization ? 2 : 1
  );
  const [selectedChatTreadId, setSelectedChatTreadId] = useState<string>("");
  const [selectedChallengeDetails, setSelectedChallengeDetails] = useState<{
    name: string;
    id: number;
  }>({ name: "", id: 0 });
  const [selectedInterestDetails, setSelectedInterestDetails] = useState({
    challengeId: 0,
    interestId: 0,
  });
  const [selectedUserAccessToken, setSelectedUserAccessToken] = useState("");

  const tabs = useMemo(() => {
    if (userInfo?.userType === USERS.Organization) {
      return [];
    } else if (
      userInfo?.userType === USERS.Funding &&
      permissions?.Viewprivatereplies &&
      permissions?.Viewpost
    ) {
      return [
        {
          value: 1,
          label: "forum.forums",
        },
        {
          value: 2,
          label: "organization.messages",
        },
        {
          value: 3,
          label: "forum.interests",
        },
      ];
    } else if (
      userInfo?.userType === USERS.Funding &&
      !permissions?.Viewprivatereplies &&
      permissions?.Viewpost
    ) {
      return [
        {
          value: 1,
          label: "forum.forums",
        },
        {
          value: 3,
          label: "forum.interests",
        },
      ];
    } else if (
      userInfo?.userType === USERS.Funding &&
      permissions?.Viewprivatereplies &&
      !permissions?.Viewpost
    ) {
      setActiveTab(2);
      return [
        {
          value: 2,
          label: "organization.messages",
        },
        {
          value: 3,
          label: "forum.interests",
        },
      ];
    } else if (
      userInfo?.userType === USERS.Funding &&
      !permissions?.Viewprivatereplies &&
      !permissions?.Viewpost
    ) {
      setActiveTab(3);
      return [
        {
          value: 3,
          label: "forum.interests",
        },
      ];
    } else if (
      (permissions && permissions.Viewprivatereplies && permissions?.Viewpost) ||
      userInfo?.userType === USERS.Admin
    ) {
      return [
        {
          value: 1,
          label: "forum.forums",
        },
        {
          value: 2,
          label: "organization.messages",
        },
      ];
    } else if (permissions && permissions.Viewprivatereplies && !permissions?.Viewpost) {
      setActiveTab(2);
      return [
        {
          value: 2,
          label: "organization.messages",
        },
      ];
    } else if (permissions && !permissions.Viewprivatereplies && permissions?.Viewpost) {
      return [
        {
          value: 1,
          label: "forum.forums",
        },
      ];
    } else {
      setActiveTab(0);
      return [];
    }
  }, [userInfo, permissions]);

  const resetTabs = () => {
    setSelectedChallengeDetails({ name: "", id: 0 });
    setSelectedInterestDetails({ challengeId: 0, interestId: 0 });
    setSelectedChatTreadId("");
  };

  const handleTabChange = (type: number) => {
    setActiveTab(type);
    resetTabs();
  };

  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  useEffect(() => {
    if (userInfo?.userType === USERS.Organization) {
      setSelectedSideMenu({
        type: "organization.messages",
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (data?.pages[0]?.totalCount === 0) {
      setSelectedChallengeDetails({ name: "", id: 0 });
    }
  }, [data?.pages]);

  const handleRadioButtonChange = (value: boolean) => {
    setIsChallenge(value);
    resetTabs();
  };

  return (
    <div
      className={`flex w-full  min-h-screen msgScreen ${
        !selectedChallengeDetails?.id &&
        selectedChatTreadId?.length === 0 &&
        !selectedInterestDetails?.interestId
          ? "msgDetailHide"
          : ""
      }`}
    >
      <Header
        title={""}
        className={
          scroll
            ? "bg-primary-medium lg:bg-white  animate__animated animate__fadeInDown lg:shadow-sticky  !py-[26px]  lg:!h-[90px] fixed stickyHeader  flex lg:hidden msgHeader "
            : "fixed bg-primary-medium lg:bg-gray-light  flex lg:hidden msgHeader"
        }
        handleCollapseClick={onToggleClick}
        handleBackButtonClick={resetTabs}
      />
      <div className=" w-full min-w-full pt-[58px] lg:pt-0 lg:min-w-[350px] lg:w-[350px]  h-screen bg-white">
        <div className="flex flex-wrap researchScholarMsg">
          {tabs?.length > 0 ? (
            <div className="msgTab flex  pt-5 border-b border-primary-102/[19%] w-full max-[1024px]:mx-4">
              <FilterTab
                filterOptions={tabs}
                activeTab={activeTab}
                handleClick={(value) => handleTabChange(value)}
                className=" ml-5 msgFilterTab max-[1024px]:ml-0"
              />
            </div>
          ) : null}
          <div className=" w-full ">
            {activeTab === 1 ? (
              <>
                {userInfo?.isGlobal || userInfo?.userType === USERS.Admin ? (
                  <div className=" mt-[11px] flex items-center space-x-6 rtl:space-x-reverse w-full ltr:ml-5 rtl:mr-5">
                    <RadioButton
                      className="p-1 text-black text-sm"
                      label={t("home.challenges")}
                      value={FORUM_TYPES.challenges}
                      checked={isChallenge}
                      onChange={() => handleRadioButtonChange(true)}
                    />
                    <RadioButton
                      className="p-1 text-black text-sm"
                      label={t("global.collaborations")}
                      value={FORUM_TYPES.collaborations}
                      checked={!isChallenge}
                      onChange={() => handleRadioButtonChange(false)}
                    />
                  </div>
                ) : null}
                {isChallenge ? (
                  <ForumBar
                    handleItemClick={(name: string, id: number) =>
                      setSelectedChallengeDetails({ name, id })
                    }
                    challengeId={selectedChallengeDetails?.id}
                  />
                ) : (
                  <CollaborationForumBar
                    handleItemClick={(name: string, id: number) =>
                      setSelectedChallengeDetails({ name, id })
                    }
                    collaborationId={selectedChallengeDetails?.id}
                  />
                )}
              </>
            ) : null}
            {activeTab === 2 ? (
              <MessageBar
                handleItemClick={(threadId: string, userAccessToken: string) => {
                  setSelectedChatTreadId(threadId);
                  setSelectedUserAccessToken(userAccessToken);
                }}
                selectedChatTreadId={selectedChatTreadId}
              />
            ) : null}
            {activeTab === 3 ? (
              <InterestsBar
                handleItemClick={(challengeId: number, interestId: number) =>
                  setSelectedInterestDetails({ challengeId, interestId })
                }
                selectedInterestId={selectedInterestDetails?.interestId}
                interestId={selectedInterestDetails?.interestId}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div
        className={`w-full h-full hidden lg:flex  ${
          selectedChallengeDetails?.id ||
          selectedChatTreadId?.length > 0 ||
          selectedInterestDetails?.interestId
            ? "msgDetails"
            : ""
        }`}
      >
        {activeTab === 1 ? (
          <div className="w-full h-full flex flex-col">
            <Header
              title={
                selectedChallengeDetails?.name ? selectedChallengeDetails?.name : "forum.forums"
              }
              className="fixed headerMsg"
              handleBackButtonClick={resetTabs}
            />
            <div className="formDetails adminDetails flex w-full h-full pt-[156px] lg:pt-[118px] pb-[45px] items-baseline px-10 rounded-md">
              {isChallenge ? (
                <Forum challengeId={selectedChallengeDetails?.id} resetTabs={resetTabs}/>
              ) : (
                <CollaborationForum collaborationId={selectedChallengeDetails?.id} resetTabs={resetTabs} />
              )}
            </div>
          </div>
        ) : null}
        {activeTab === 2 ? (
          <div className="w-full h-full flex flex-col ">
            <Header
              title={"organization.messages"}
              className="fixed headerMsg"
              handleBackButtonClick={resetTabs}
            />
            <div className="formDetails adminDetails flex w-full h-full pt-[156px] lg:pt-[118px] pb-[45px] items-baseline px-10 rounded-md">
              <Chats
                selectedChatTreadId={selectedChatTreadId}
                userAccessToken={selectedUserAccessToken}
              />
            </div>
          </div>
        ) : null}
        {activeTab === 3 ? (
          <div className="w-full h-full flex flex-col ">
            <Header
              title={"forum.interests"}
              className="fixed headerMsg"
              handleBackButtonClick={resetTabs}
            />
            <div className="formDetails adminDetails flex w-full h-full pt-[156px] lg:pt-[118px] pb-[45px] items-baseline px-10 rounded-md">
              <Interests
                challengeId={selectedInterestDetails?.challengeId}
                interestId={selectedInterestDetails?.interestId}
              />
            </div>
          </div>
        ) : null}

        {activeTab === 0 ? <NoPermissionCard /> : null}
      </div>
    </div>
  );
}
