import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import {
  Title,
  DatePickerField,
  TextArea,
  RadioButton,
  LoaderModal,
  InputField,
  CreatableSingleSelect,
  CheckBox,
} from "components";
import CoverImageArea from "features/Organization/Challenge/CreateChallenge/CoverImageArea";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { useGetChallengeDetails } from "features/Organization/api/useGetChallengeDetails";
import { setChallengeDetails, setChallengeInfo, useChallengeStore } from "store/useChallengeStore";
import { useUserDataStore } from "store/useUserDataStore";
import {
  CHALLENGE_STATUS,
  CHALLENGE_TYPES,
  EMAIL_MAX_CHAR_LENGTH,
  GENERAL_DATA_MAX_LENGTH,
  PAGE_LIMIT,
  SUMMARY_MAX_CHAR_LENGTH,
  INITIAL_SEARCH_PARAMS,
} from "utils/constants";
import { showChallengeFileUploadModal, showConfirmationModal } from "utils/confirmationModals";

type ChallengeDetailsProps = {
  validationErrors: Record<string, string> | null;
};

const About = ({ validationErrors }: ChallengeDetailsProps) => {
  const { data: challengeDetails, isLoading: isChallengeInfoLoading } = useGetChallengeDetails();
  const { challengeInfo } = useChallengeStore();
  const { userInfo } = useUserDataStore();
  const { t } = useTranslation();
  const isPending = challengeInfo?.challengeStatusId === CHALLENGE_STATUS.Pending;
  const handleProfileClick = async () => {
    await showChallengeFileUploadModal(
      function (url: string) {
        setChallengeInfo(url, "coverImage");
      },
      true,
      challengeInfo.id
    );
  };

  const handleDeleteClick = async () => {
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.deletion_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    setChallengeInfo("", "coverImage");
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    key: keyof ChallengeInfo
  ) => {
    const value = event?.target?.value;
    setChallengeInfo(value, key);
  };

  const handleDateInputChange = (key: keyof ChallengeInfo) => (date: string) => {
    setChallengeInfo(date, key);
  };
  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);

  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }
  const handleChallengeTypeChange = (value: boolean) => {
    setChallengeInfo(value, "isPrivate");
  };

  const isEditDisabled =
    challengeInfo.challengeStatusId === CHALLENGE_STATUS.Open ||
    challengeInfo.challengeStatusId === CHALLENGE_STATUS.Live;

  useEffect(() => {
    if (challengeDetails) {
      const challengeData: ChallengeInfo = challengeDetails;
      setChallengeDetails(challengeData);
      searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
    }
  }, [challengeDetails]);

  const handleGlobalChallengeCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChallengeGlobal = event.target.checked;
    setChallengeInfo(isChallengeGlobal, "isGlobal");
  };

  useEffect(() => {
    if (userInfo?.isGlobal) {
      setChallengeInfo(true, "isGlobal");
    }
  }, [userInfo?.isGlobal]);

  return (
    <div>
      <div className="bg-white rounded-md">
        <div className=" p-4 lg:p-5 flex items-center border-b border-gray-light">
          <SVGIcon name="personalDetails" />
          <Title
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            title={t("organization.about")}
          />
        </div>
        <div className="px-5 bg-white">
          <div className=" flex flex-col pt-6 justify-center rounded items-center mb-[34px] ">
            <Title
              className="  text-primary-light text-sm font-normal text-left w-full pb-2"
              title={t("organization.cover_image")}
            />
            <CoverImageArea
              errorMessage={""}
              url={challengeInfo.coverImage}
              handleClick={handleProfileClick}
              handleDeleteClick={handleDeleteClick}
              isDisabled={isPending}
            />
            <div className="mt-[30px] w-full h-full">
              {!userInfo?.isGlobal ? (
                <CheckBox
                  option={{
                    label: t("global.global_challenge"),
                    value: "global_challenge",
                  }}
                  className="customCheckboxV2  higher-education-checkbox mb-6"
                  handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleGlobalChallengeCheckBoxClick(event);
                  }}
                  disabled={isEditDisabled || isPending}
                  checked={challengeInfo?.isGlobal}
                />
              ) : null}
              <InputField
                hintText={t("organization.enter_challenge_title")}
                labelText={t("global.title")}
                value={challengeInfo.title}
                handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(event, "title");
                }}
                fieldName="title"
                isRequired
                errorMessage={validationErrors?.title}
                maxCharLength={GENERAL_DATA_MAX_LENGTH}
                isDisabled={isPending}
              />
            </div>
            <div className="flex items-start flex-col mt-[22px] w-full justify-center">
              <span className="label-text text-primary-light text-sm font-normal leading-4 whitespace-nowrap">
                {t("organization.challenge_type")}
              </span>
              <div className=" mt-[11px] flex items-center space-x-6 rtl:space-x-reverse ">
                <RadioButton
                  className="mr-10 p-2 text-black"
                  label={t("organization.public")}
                  value={CHALLENGE_TYPES.Public}
                  checked={!challengeInfo.isPrivate}
                  onChange={() => handleChallengeTypeChange(false)}
                  disabled={isEditDisabled || isPending}
                />
                <RadioButton
                  className="mr-10 p-2 text-black"
                  label={t("organization.private")}
                  value={CHALLENGE_TYPES.Private}
                  checked={challengeInfo.isPrivate}
                  onChange={() => handleChallengeTypeChange(true)}
                  disabled={isEditDisabled || isPending}
                />
              </div>
            </div>

            <div className="flex w-full gap-3 mt-[22px] targetIndustry">
              <CreatableSingleSelect
                className="text-sm font-normal flex-grow w-full"
                labelText={t("organization.target_industry")}
                hintText={t("organization.target_industry_placeholder")}
                selectedOptions={Array(challengeInfo.targetIndustry) as OptionItem[]}
                setSelectedOptions={function (option: OptionItem | null): void {
                  if (option) {
                    setChallengeInfo(option, "targetIndustry");
                  }
                  searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
                }}
                addNew
                errorToastMessage="global.create_target_industry_min_character"
                createLabel={"global.new_target_industry"}
                loadOptions={loadResearchAreaOptions}
                errorMessage={validationErrors?.targetIndustry}
                resetKey={Math.random().toString()}
                onMenuOpen={() => {
                  searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
                }}
                isDisabled={isPending}
              />

              <InputField
                hintText={t("organization.enter_challenge_location")}
                labelText={t("organization.location")}
                handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(event, "location");
                }}
                fieldName="location"
                isRequired
                errorMessage={validationErrors?.location}
                maxCharLength={EMAIL_MAX_CHAR_LENGTH}
                className=" w-full !h-12 flex-grow-0"
                value={challengeInfo.location}
                isDisabled={isPending}
              />
            </div>
            <div className="flex w-full gap-3 mt-[22px]">
              <div className="w-full flex-grow">
                <DatePickerField
                  labelText={t("global.start_date")}
                  isRequired
                  selectedDate={challengeInfo.startDate}
                  setSelectedDate={handleDateInputChange("startDate")}
                  errorMessage={validationErrors?.startDate}
                  showFutureDate={true}
                  isDisabled={false || isPending}
                  maxDate={challengeInfo.endDate}
                />
              </div>
              <div className="w-full flex-grow">
                <DatePickerField
                  labelText={t("global.end_date")}
                  isRequired
                  selectedDate={challengeInfo.endDate}
                  setSelectedDate={handleDateInputChange("endDate")}
                  errorMessage={validationErrors?.endDate}
                  showFutureDate={true}
                  isDisabled={false || isPending}
                  minDate={challengeInfo?.startDate}
                />
              </div>
            </div>
            <div className="mt-[22px] w-full">
              <TextArea
                textContent={challengeInfo.summary}
                handleChange={(event) => handleInputChange(event, "summary")}
                labelText={t("organization.challenge_summary")}
                hintText={t("organization.enter_challenge_summary")}
                fieldName="summary"
                isRequired
                maxCharLength={SUMMARY_MAX_CHAR_LENGTH}
                errorMessage={validationErrors?.summary}
                isDisabled={false || isPending}
              />
            </div>
          </div>
        </div>
      </div>
      {isChallengeInfoLoading ? <LoaderModal /> : null}
    </div>
  );
};

export default About;
