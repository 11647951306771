import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function collaborationEmailInvite(
  formData: Array<string>,
  collaborationId: number,
) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(
    urls.collaborationInviteUser(collaborationId),
    formData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus;
}

export function useCollaborationEmailInvite(collaborationId: number) {
  return useMutation(
    async ({
      formData,
    }: {
      formData: Array<string>;
    }) => {
      return await collaborationEmailInvite(formData, collaborationId);
    }
  );
}
