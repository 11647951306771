import { useTranslation } from "react-i18next";
import { Title, Chip, Descriptor } from "components";
import SVGIcon from "assets/Icons";
import { displayDateFormat } from "utils/helpers";
import DefaultCoverImage from "assets/images/home-images/challengeDefaultCover.png";

type ChallengeDetailsModalProps = {
  onclose: () => void;
  data: Challenge;
  handleNavigateToLogin: (challengeId: number) => void;
};

function ChallengeDetailsModal({
  onclose,
  data,
  handleNavigateToLogin,
}: ChallengeDetailsModalProps) {
  const { t } = useTranslation();
  return (
    <div className=" max-w-[100vw] w-[100vw] min-w-[100vw]  md:w-auto md:max-w-[820px] mx-0 md:mx-2  md:min-w-[820px]  bg-white rounded-md rounded-b-none md:rounded-b-md flex flex-col relative text-center animate__animated animate__zoomIn max-h-[calc(100vh-50px)] overflow-auto">
      <div className="flex items-center justify-between p-6 w-full border-b border-gray-light">
        <Title title={t("home.challenge")} className="text-black font-semibold text-[22px]" />
        <div className="modal-action m-0">
          <button
            className="btn text-skin-textPrimary  !border-none !min-h-max !bg-transparent !h-auto !p-0"
            onClick={onclose}
          >
            <SVGIcon name="closeV2" />
          </button>
        </div>
      </div>
      <div className=" p-6">
        <img
          src={data?.coverImage ? data?.coverImage : DefaultCoverImage}
          alt="banner image"
          className="w-full h-[193px] rounded-md mb-10 object-cover"
        />
        <div className="text-left flex justify-start items-start flex-col">
          <div className="flex gap-4 items-center truncate">
            <div className="flex  items-center">
              <Chip text={data?.targetIndustry} className=" !mb-0 !bg-green-500" />
            </div>
          </div>
          <Title title={data?.title} className="text-gray-109 font-bold text-[40px] line-clamp-5 WB_BW" />
          <div className="flex gap-4 items-center truncate mt-[10px] flex-wrap">
            <Descriptor
              startIcon="date"
              title={`${displayDateFormat(data?.startDate)}`}
              className="flex gap-2 items-center text-black text-sm preview-descriptor"
            />
            <Descriptor
              startIcon="date"
              title={`${displayDateFormat(data?.endDate)}`}
              className="flex gap-2 items-center text-black text-sm preview-descriptor"
            />
            <Descriptor
              startIcon="mapPin"
              title={`${data?.location}`}
              className="flex gap-2 items-center text-black text-sm Word-break preview-descriptor"
            />
          </div>
          <div className=" pt-[30px] text-gray-109 text-base leading-[26px]">{data?.summary}</div>
          <div className="border-t border-gray-light mt-[22px] w-full flex justify-center">
            <button
              className=" btn btn-link mt-6 h-auto m-0 p-0 min-h-max text-green-400 font-medium text-base underline normal-case"
              onClick={() => {
                onclose();
                handleNavigateToLogin(data.id);
              }}
            >
              {t("home.login_to_view_more_details")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChallengeDetailsModal;
