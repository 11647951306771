import { SyntheticEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InputField, SubTitle } from "components";
import {
  setSocialInfoItem,
  setSocialInfo,
  useScholarProfileDataStore,
} from "store/useScholarProfileDataStore";
import { useGetSocialProfilesDetails } from "features/ResearchScholar/api/useGetSocialProfilesDetails";
import { URL_MAX_LENGTH, USER_STATUS } from "utils/constants";
import SVGIcon from "assets/Icons";
import { handleKeyDown } from "utils/helpers";
import useInvalidateQuery from "hooks/useInvalidateQuery";

type SocialProfilesProps = {
  validationErrors: Record<string, string> | null;
};

export default function SocialProfiles({ validationErrors }: SocialProfilesProps) {
  const { t, i18n } = useTranslation();
  const { researchScholarId } = useParams();
  const { socialInfo } = useScholarProfileDataStore();
  const { data: socialData } = useGetSocialProfilesDetails(researchScholarId ?? "");
  const isReadOnly = socialInfo?.profileStatusId === USER_STATUS.PendingApproval;
  useInvalidateQuery("personal-details", researchScholarId ?? "");

  const handleInputChange = (key: keyof SocialProfile) => (e: any) => {
    const value = e.target.value;
    setSocialInfoItem(value, key);
  };

  const handleInputPaste = (e: SyntheticEvent) => {
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    if (socialData) {
      setSocialInfo(socialData);
    }
  }, [socialData, i18n.language]);

  return (
    <>
      <form className="space-y-6">
        <div className="flex items-center justify-between mb-6 flex-wrap p-4 lg:p-5 border-b border-gray-light">
          <SVGIcon name="socialProfiles" />
          <div className="ltr:mr-auto rtl:ml-auto">
            <SubTitle
              className=" font-semibold text-lg text-black subtitle  ltr:ml-3 rtl:mr-3"
              title={t("research_scholar.social_profiles")}
              tooltipContent="global.website_url_tooltip_message"
            />
          </div>
        </div>
        <div className="grid sm:grid-cols-2 xl:grid-cols-2  gap-x-[14px] gap-y-[22px] w-full px-4 lg:px-5 pb-8 ">
          <InputField
            labelText={t("research_scholar.google_scholar_url")}
            hintText={t("research_scholar.enter_google_scholar_URL")}
            handleInputChange={handleInputChange("googleScholarURL")}
            fieldName="googleScholarURL"
            errorMessage={validationErrors?.googleScholarURL}
            value={socialInfo?.googleScholarURL ?? ""}
            isDisabled={isReadOnly}
            maxCharLength={URL_MAX_LENGTH}
          />
          <InputField
            labelText={t("research_scholar.linked_in_url")}
            hintText={t("research_scholar.enter_linkedIn_URL")}
            handleInputChange={handleInputChange("linkedInURL")}
            fieldName="linkedInURL"
            errorMessage={validationErrors?.linkedInURL}
            value={socialInfo?.linkedInURL ?? ""}
            isDisabled={isReadOnly}
            maxCharLength={URL_MAX_LENGTH}
          />
          <InputField
            labelText={t("research_scholar.research_gate_url")}
            hintText={t("research_scholar.enter_research_gate_URL")}
            handleInputChange={handleInputChange("researchGateURL")}
            fieldName="researchGateURL"
            errorMessage={validationErrors?.researchGateURL}
            value={socialInfo?.researchGateURL ?? ""}
            isDisabled={isReadOnly}
            maxCharLength={URL_MAX_LENGTH}
          />
          <InputField
            labelText={t("research_scholar.h-index")}
            hintText={t("research_scholar.enter_h-Index")}
            handleInputChange={handleInputChange("hIndex")}
            fieldName="hIndex"
            inputType="number"
            errorMessage={validationErrors?.hIndex}
            value={socialInfo?.hIndex ?? ""}
            isDisabled={isReadOnly}
            isHIndexField={true}
            handleInputPaste={handleInputPaste}
            handleKeyDown={handleKeyDown}
          />
        </div>
      </form>
    </>
  );
}
