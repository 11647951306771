import { useTranslation } from "react-i18next";
import Profile from "assets/images/avatar.svg";
import { PrimaryButton, SubTitle, SecondaryButton, TextButton } from "components";
import { useMasterDataStore } from "store/useMasterDataStore";
import { useUserDataStore } from "store/useUserDataStore";
import { USERS } from "utils/constants";

type InvitesContent = {
  imageUrl: string;
  title: string;
  date: string;
  challengeId: number;
  handleClick: (challengeId: number) => void;
  handleAcceptInvite: (challengeId: number) => void;
  handleDeclineInvite: (challengeId: number) => void;
};

export function InvitesItem({
  challengeId,
  imageUrl,
  title,
  date,
  handleClick,
  handleAcceptInvite,
  handleDeclineInvite,
}: InvitesContent) {
  const { t } = useTranslation();
  const { userInfo } = useUserDataStore();
  const { permissions } = useMasterDataStore();
  return (
    <div>
      <div className=" flex w-full relative after:absolute after:w-2 after:h-2 after:rounded-full after:bg-primary-medium ltr:after:-left-2 rtl:after:-right-2 after:-top-2 ltr:ml-8 rtl:mr-8 mt-3">
        {/* Add dot icon here */}
        <div className=" w-8 h-8 min-w-[32px] rounded-full overflow-hidden">
          <img
            alt="Profile Picture"
            className=" object-cover w-8 h-8"
            src={imageUrl === "" ? Profile : imageUrl}
          />
        </div>
        {/* Add notification image here */}
        <div className="flex flex-col ltr:text-left rtl:text-right w-[calc(100%-80px)] ltr:ml-4 rtl:mr-4 ltr:justify-start rtl:justify-end  pb-4 mb-2 border-b border-gray-104 rtl:pl-2">
          <TextButton
            className="ltr:text-left rtl:text-right text-black font-semibold text-sm break-words hover:cursor-pointer "
            title={title}
            handleClick={() => handleClick(challengeId)}
          />
          <SubTitle className="text-black font-normal mt-1 text-sm !justify-start" title="" />
          <p className=" text-gray-103 mt-1 text-sm font-medium">{date}</p>
          <div className="flex items-center mt-[10px] gap-[9px] ltr:pr-4 rtl:pl-4 lg:ltr:pr-0 lg:rtl:pl-0">
            {permissions?.Manageinvites ? (
              <>
                <PrimaryButton
                  className="flex-1 min-w-min sm:min-w-[120px] px-2 md:px-4"
                  title={"global.accept"}
                  handleClick={() => {
                    handleAcceptInvite(challengeId);
                  }}
                />
                <SecondaryButton
                  className="flex-1 min-w-min sm:min-w-[120px] px-2 md:px-4"
                  title={"global.decline"}
                  handleClick={() => {
                    handleDeclineInvite(challengeId);
                  }}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
