import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function uploadCollaborationDocument(
  file: File,
  isCoverImage: boolean,
  collaborationId: number
) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("isCoverImage", isCoverImage ? "true" : "false");
  const response = await apiClient.post(urls.collaborationFileUpload(collaborationId), formData, {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
  });
  return response?.data?.data as FileDetailsItem;
}

export function useFileUpload() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;

  return useMutation(
    async ({
      file,
      isCoverImage,
      collaborationId,
    }: {
      file: File;
      isCoverImage: boolean;
      collaborationId: number;
    }) => {
      if (userId) {
        const result = await uploadCollaborationDocument(file, isCoverImage, collaborationId);
        return result;
      }
    }
  );
}
