import React, { useState, useRef, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SVGIcon from "assets/Icons";
import DefaultCoverImage from "assets/images/challengeDefaultCover.png";
import {
  SubTitle,
  Descriptor,
  Divider,
  UploadedFileItem,
  LoaderModal,
  Label,
  Header,
  TextButton,
  ImageLoader,
  ContentViewWithToggle,
  EditorContentPreview,
} from "components";
import {
  getPlainText,
  displayDateFormat,
  getMasterDataValue,
  getChallengeStatusBackground,
  handleLinkClick,
  scrollToReference,
} from "utils/helpers";
import {
  DETAIL_DESCRIPTION_MAX_CHAR_LENGTH,
  EVENT_CREATION_COMPLETION_STATUS,
} from "utils/constants";
import { useStickyScroll } from "hooks/useStickyScroll";
import useLoadImage from "hooks/useImageLoaded";
import { useGetEventPreview } from "features/Events/api/useEventPreview";
import { Navigator } from "features/Events/CreateEvent/Navigator";

const EventPreview = () => {
  const { t, i18n } = useTranslation();
  const { scroll } = useStickyScroll();
  const { scrollValue } = useStickyScroll(20);
  const { eventId } = useParams();
  const eventDataId = Number(eventId);
  const { data: eventDetails, isLoading: isEventDetailsLoading } = useGetEventPreview(eventDataId);
  const [isViewMoreSummary, setIsViewMoreSummary] = useState<boolean>(false);
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const [isImageLoading, setImageLoading] = useState(false);
  const [activeMenu, setActiveMenu] = useState<number>(0);

  const aboutRef = useRef(null);
  const learnMoreRef = useRef(null);

  const [ref] = useLoadImage();
  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const getBreadCrumbPath = () => {
    const breadCrumbPath = [];

    breadCrumbPath.push({
      title: "global.events",
    });
    breadCrumbPath.push({
      title: "global.view_event",
    });
    return breadCrumbPath;
  };

  useEffect(() => {
    if (eventDetails && eventDetails?.eventAbout?.coverImage.length > 0) {
      setImageLoading(true);
    }
  }, [eventDetails]);

  const linkList: Array<LinkItem> = getBreadCrumbPath();

  const handleSummaryViewMoreClick = () => {
    setIsViewMoreSummary((prev) => !prev);
  };

  const onLoaded = () => {
    setImageLoading(false);
  };

  const handleScroll = (ref: React.RefObject<HTMLDivElement>) => {
    scrollToReference(ref, 120);
  };

  const PROFILE_NAVIGATION_ITEM: Array<RecordItem> = useMemo(() => {
    const tempItems = [];
    if (eventDetails?.eventAbout?.eventDescription?.length > 0) {
      {
        tempItems.push({
          value: EVENT_CREATION_COMPLETION_STATUS.About,
          label: "organization.about",
        });
      }
    }
    if (
      eventDetails?.eventLearn?.learnMore &&
      getPlainText(eventDetails?.eventLearn?.learnMore) > 0
    ) {
      {
        tempItems.push({
          value: EVENT_CREATION_COMPLETION_STATUS.LearnMore,
          label: "organization.learn_more",
        });
      }
    }
    return tempItems;
  }, [eventDetails, i18n.language]);

  const handleNavigationClick = (value: number, index: number) => {
    setActiveMenu(index);
    if (value === EVENT_CREATION_COMPLETION_STATUS.About) {
      handleScroll(aboutRef);
    } else if (value === EVENT_CREATION_COMPLETION_STATUS.LearnMore) {
      handleScroll(learnMoreRef);
    }
  };

  return (
    <div className={`flex  min-h-screen w-full  `}>
      <Header
        linkList={linkList}
        title={eventDetails?.eventAbout?.title}
        handleCollapseClick={onToggleClick}
        className={
          scroll
            ? " bg-primary-medium lg:bg-white animate__animated animate__fadeInDown lg:shadow-sticky lg:!h-[90px] fixed stickyHeader smaller-devices-section z-[91]"
            : "fixed bg-primary-medium lg:bg-gray-light smaller-devices-section"
        }
      />
      <div className=" adminDetails w-full mt-[170px] mb-20 lg:mb-0 lg:mt-0 lg:pt-[118px] pb-[45px] ltr:pr-[30px] rtl:pl-[30px] items-baseline px-4 lg:px-10 lg:min-h-screen flex flex-col  overflow-auto max-[1025px]:bg-white max-[1025px]:!px-4 max-[1025px]:mt-[106px] max-[1025px]:pb-[80px]">
        <div className="w-full flex flex-col h-full ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 ltr:lg:pr-[292px] rtl:lg:pl-[292px] space-y-3">
          <div className=" flex-col bg-white w-full h-full rounded-none lg:rounded-md flex mb-auto">
            <div className="p-5 relative max-[1025px]:p-0" ref={aboutRef}>
              {isImageLoading ? (
                <div className="h-[160px] lg:h-[250px]">
                  <ImageLoader />
                </div>
              ) : null}

              <img
                alt="previewImage"
                className={`w-full max-h-[250px] object-cover rounded-md max-[1025px]:min-h-[160px] ${
                  isImageLoading ? "h-[0px] overflow-hidden" : "h-[250px] overflow-hidden"
                }`}
                src={
                  eventDetails?.eventAbout?.coverImage.length > 0
                    ? eventDetails?.eventAbout?.coverImage
                    : DefaultCoverImage
                }
                ref={ref}
                onLoad={onLoaded}
              />
            </div>
            <div className="p-5 pb-10 max-[1025px]:p-0 max-[1025px]:pt-4">
              <div className="flex gap-4 items-center truncate">
                <div className=" flex badgeBLock gap-2 items-center">
                  <Label
                    className={`!w-auto text-gray-107 text-xs bg-green-500 rounded-xl px-3 py-1.5 h-[30px] ${getChallengeStatusBackground(
                      eventDetails?.eventAbout?.eventStatusId
                    )}`}
                    text={eventDetails?.eventAbout?.status ?? ""}
                  />
                </div>
                <div className=" h-[14px] bg-gray-200  w-[1px] min-w-[1px]"></div>
                <div className=" flex badgeBLock gap-6 items-center max-[768px]:truncate">
                  <Label
                    className=" !w-auto text-gradient-blueDark text-xs bg-primary-500 rounded-xl px-3 py-1.5  h-[30px]  max-[768px]:truncate !block"
                    text={
                      eventDetails?.eventAbout?.targetIndustry
                        ? getMasterDataValue(
                            eventDetails?.eventAbout?.targetIndustry,
                            i18n.language
                          )
                        : ""
                    }
                  />
                </div>
              </div>
              <div>
                <div className=" flex items-center space-x-2 rtl:space-x-reverse  mt-2">
                  <h2 className="font-bold text-3xl text-gray-109 Word-break max-[1025px]:text-black  max-[1025px]:text-[22px]  max-[1025px]:leading-[30px]  ">
                    {eventDetails?.eventAbout?.title}
                  </h2>
                </div>
                <div className="flex gap-4 items-center mt-[10px] max-[1025px]:mt-[16px] max-[1025px]:flex-wrap">
                  <Descriptor
                    title={`${t("global.start_date")} : ${displayDateFormat(
                      eventDetails?.eventAbout?.startDate ?? ""
                    )}`}
                    startIcon="previewDate"
                    className="flex gap-2 items-center text-black text-sm preview-descriptor whitespace-nowrap"
                  />
                  <Descriptor
                    title={`${t("global.end_date")} : ${displayDateFormat(
                      eventDetails?.eventAbout?.endDate ?? ""
                    )}`}
                    startIcon="previewDate"
                    className="flex gap-2 items-center text-black text-sm preview-descriptor whitespace-nowrap"
                  />
                  <Descriptor
                    title={eventDetails?.eventAbout?.location ?? ""}
                    startIcon="previewLocation"
                    className="flex gap-2 items-center text-black text-sm Word-break preview-descriptor"
                  />
                </div>
                {eventDetails?.eventAbout?.websiteUrl ? (
                  <div className="flex items-center gap-2 mt-4">
                    <div className=" w-4 h-4">
                      <SVGIcon name="worldIcon" />
                    </div>
                    <TextButton
                      title={"organization.website"}
                      handleClick={() => handleLinkClick(eventDetails?.eventAbout?.websiteUrl)}
                      className=" text-primary-medium font-normal underline text-sm"
                    />
                  </div>
                ) : null}
              </div>
              <Divider className=" my-[30px] max-[1025px]:hidden" />
              <div
                className={`min-[1025px]:hidden mt-6 mb-6 bg-white navigator-mobile-view   ${
                  scrollValue > 250 ? "window-scroll-challenge" : ""
                }`}
              >
                {eventDetails?.eventAbout?.eventDescription?.length > 0 ||
                (eventDetails?.eventLearn?.learnMore &&
                  getPlainText(eventDetails?.eventLearn?.learnMore) > 0) ? (
                  <Navigator
                    handleNext={() => {
                      //No-op
                    }}
                    handleDraft={() => {
                      //No-op
                    }}
                    navigationItems={PROFILE_NAVIGATION_ITEM}
                    isEdit={false}
                    handleNavigationClick={handleNavigationClick}
                    handlePreview={() => {
                      //No-op
                    }}
                    isDisabled={false}
                    isPreview={true}
                    activeMenu={activeMenu}
                  />
                ) : null}
              </div>
              {eventDetails?.eventAbout?.eventDescription?.length > 0 ? (
                <>
                  <div>
                    <SubTitle
                      title={t("global.summary")}
                      className="!justify-start font-semibold text-lg text-gray-109 leading-[26px] !mb-2.5 max-[1025px]:text-[18px] max-[1025px]:!mb-4"
                    />
                    <ContentViewWithToggle
                      data={eventDetails?.eventAbout?.eventDescription ?? ""}
                      handleViewMoreClick={handleSummaryViewMoreClick}
                      isViewMore={isViewMoreSummary}
                      maxLength={DETAIL_DESCRIPTION_MAX_CHAR_LENGTH}
                      className=" text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] "
                      contentType="textAreaContent"
                    />
                  </div>
                </>
              ) : null}

              {eventDetails?.eventLearn?.learnMore &&
              getPlainText(eventDetails?.eventLearn?.learnMore) > 0 ? (
                <>
                  <Divider className="my-[30px] !border-gray-light" />
                  <div ref={learnMoreRef}>
                    <SubTitle
                      title={t("organization.challenge_learn_more")}
                      className="!justify-start font-semibold text-lg text-gray-109 leading-[26px] !mb-2.5 max-[1025px]:text-[18px] max-[1025px]:!mb-4"
                    />
                    <EditorContentPreview
                      data={eventDetails?.eventLearn?.learnMore}
                      className="text-black text-sm font-normal max-w-[100%] mt-2 mb-[26px] WB_BW dangerously-set-html"
                    />
                  </div>
                </>
              ) : null}

              {eventDetails?.eventLearn?.documents?.length > 0 ? (
                <>
                  <Divider className="my-[30px] !border-gray-light" />
                  <div>
                    <h3 className="mt-[30px] font-semibold text-lg text-gray-112 !mb-2.5">
                      {t("organization.documents")}
                    </h3>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 justify-between gap-[10px] mt-1">
                    {eventDetails?.eventLearn?.documents?.map(
                      (item: FileDetails, index: number) => (
                        <div className="flex-1 truncate" key={index}>
                          <UploadedFileItem key={item.fileUrl + new Date()} isPreview file={item} />
                        </div>
                      )
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={`w-full min-w-full lg:w-[280px] lg:min-w-[280px]  mb-auto fixed max-[1025px]:hidden
          ${
            scroll
              ? " top-[100px]  ltr:lg:right-[30px] rtl:lg:left-[30px]  z-[5]  shadow-sticky  lg:animate__animated lg:animate__fadeInDown lg:transition-all"
              : " top-[76px] lg:top-auto ltr:lg:right-[30px] rtl:left-[30px]  z-[5]"
          }`}
        >
          {eventDetails?.eventAbout?.eventDescription?.length > 0 ||
          (eventDetails?.eventLearn?.learnMore &&
            getPlainText(eventDetails?.eventLearn?.learnMore) > 0) ? (
            <Navigator
              handleNext={() => {
                //No-op
              }}
              handleDraft={() => {
                //No-op
              }}
              navigationItems={PROFILE_NAVIGATION_ITEM}
              isEdit={false}
              handleNavigationClick={handleNavigationClick}
              handlePreview={() => {
                //No-op
              }}
              isDisabled={false}
              isPreview={true}
              activeMenu={activeMenu}
            />
          ) : null}
        </div>
        {isEventDetailsLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
};
export default EventPreview;
