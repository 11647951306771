import { useMutation } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveContactUs(formData: ContactUs) {
  const response = await apiClient.post(urls.contactUs(), formData);
  return response?.data as ApiStatus | ApiError;
}

export function useSaveContactUs() {
  return useMutation(async (formData: ContactUs) => {
    return await saveContactUs(formData);
  });
}
