import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQueryClient } from "@tanstack/react-query";
import {
  Header,
  InlineLoader,
  NoData,
  NoResults,
  PortalFilter,
  PrimaryButton,
  SecondaryButton,
  StatisticsDataCard,
  TableLoader,
  Title,
} from "components";
import Table from "features/Admin/Users/ResearchInstitute/Table";
import {
  showConfirmationModal,
  showResearchInstituteIndividualInviteModal,
  showResearchScholarDetailsModal,
} from "utils/confirmationModals";
import {
  ADMIN_REQUEST_INIT_PARAMS,
  GLOBAL_PORTAL_FILTERS,
  INVITED_RESEARCH_INSTITUTE_TABLE_HEADERS,
  PAGE_LIMIT,
  RESEARCH_INSTITUTE_TABLE_HEADERS,
  USERS,
  USER_STATUS,
} from "utils/constants";
import { getGlobalFilterParam, handleUserTableRowClick } from "utils/helpers";
import { useOutsideClick } from "hooks/useOutsideClick";
import { useStickyScroll } from "hooks/useStickyScroll";
import {
  setSelectedSideMenu,
  setSelectedUserStatusType,
  useSidebarDataStore,
} from "store/useSidebarStore";
import { useGetUsersDetails } from "features/Admin/api/useGetUsersDetails";
import {
  clearFilterDataStore,
  clearUserTypeFilter,
  setScrollInfo,
  setUserType,
  useFilterDataStore,
} from "store/useFilterDataStore";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import { useSetScroll } from "hooks/useSetScroll";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";
import useResetQueryOnLanguageSwitch from "hooks/useResetQueryOnLanguageSwitch";
import { useDeleteResearchInstitute } from "features/Admin/api/useDeleteResearchInstitute";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import InvitedUsersTable from "features/Admin/Users/ResearchInstitute/InvitedUsersTable";

export default function ResearchInstituteDetails() {
  const { isOpen, setIsOpen, dropDownRef } = useOutsideClick();
  const { selectedUserStatusType } = useSidebarDataStore();
  const pathName = window.location.pathname;
  const { scroll } = useStickyScroll();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tableFilterInfo, scrollInfo, userType } = useFilterDataStore();
  const { scrollPosition } = useGetScrollPosition();
  const queryClient = useQueryClient();
  const enableFiltering = pathName === tableFilterInfo?.pathName;
  const [activeFilter, setActiveFilter] = useState(userType ?? GLOBAL_PORTAL_FILTERS.Both);
  const [requestParams, setRequestParams] = useState<UserDetailsParams>(
    selectedUserStatusType
      ? {
          ...ADMIN_REQUEST_INIT_PARAMS,
          profileStatusId: selectedUserStatusType,
          userType: USERS.ResearchInstitute,
          country: enableFiltering ? tableFilterInfo?.country : "",
          type: enableFiltering ? tableFilterInfo?.researchArea : "",
          fromDate: enableFiltering ? tableFilterInfo?.fromDate : null,
          toDate: enableFiltering ? tableFilterInfo?.toDate : null,
          isGlobal: getGlobalFilterParam(userType ?? GLOBAL_PORTAL_FILTERS.Both),
        }
      : {
          ...ADMIN_REQUEST_INIT_PARAMS,
          profileStatusId: USER_STATUS.Invited,
          userType: USERS.ResearchInstitute,
          country: enableFiltering ? tableFilterInfo?.country : "",
          type: enableFiltering ? tableFilterInfo?.researchArea : "",
          fromDate: enableFiltering ? tableFilterInfo?.fromDate : null,
          toDate: enableFiltering ? tableFilterInfo?.toDate : null,
          isGlobal: getGlobalFilterParam(userType ?? GLOBAL_PORTAL_FILTERS.Both),
        }
  );

  const { data, isLoading, fetchNextPage, hasNextPage, refetch } =
    useGetUsersDetails(requestParams);
  const { mutateAsync: deleteResearchInstitute } = useDeleteResearchInstitute();

  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "users-details");
  useResetQueryOnLanguageSwitch("users-details");

  const linkList: Array<LinkItem> = [
    {
      title: "admin.users",
      path: "/admin/users",
    },
    {
      title: "home.research_institutes",
      path: "",
    },
  ];

  const handleCardClick = (id: number) => {
    queryClient.resetQueries(["users-details"]);
    if (id !== requestParams.profileStatusId) {
      clearFilterDataStore();
      setRequestParams(() => ({
        ...ADMIN_REQUEST_INIT_PARAMS,
        profileStatusId: id,
        userType: USERS.ResearchInstitute,
        isGlobal: getGlobalFilterParam(userType ?? GLOBAL_PORTAL_FILTERS.Both),
      }));
      setSelectedUserStatusType(id);
    }
  };

  const getTableHeaders = () => {
    if (requestParams?.profileStatusId === USER_STATUS.PendingApproval) {
      return "admin.pending_request_for_research_institute";
    } else if (requestParams?.profileStatusId === USER_STATUS.Disabled) {
      return "admin.disabled_research_institute";
    } else if (requestParams?.profileStatusId === USER_STATUS.Invited) {
      return "admin.invited_research_institute";
    } else {
      return "admin.active_research_institute";
    }
  };

  const usersDetails = useMemo(() => {
    const tempData: UsersDetails = {
      users: [],
      totalCount: 0,
      totalPages: 0,
      summary: [],
      startUpCount: 0,
      otherCount: 0,
    };
    if (data?.pages && data?.pages[0]) {
      const index =
        scrollInfo?.index && data?.pages[scrollInfo?.index]?.users?.length > 0
          ? scrollInfo?.index
          : 0;

      tempData.summary = data.pages[index]?.summary;
      tempData.totalCount = data.pages[index]?.totalCount;
      tempData.totalPages = data.pages[index]?.totalPages;
      tempData.startUpCount = data.pages[index]?.startUpCount;
      tempData.otherCount = data.pages[index]?.otherCount;
      const users = data.pages
        .map((item) => {
          return item.users;
        })
        .flat();
      tempData.users = users;
    }
    return tempData;
  }, [data, scrollInfo?.index]);

  const handleRowClick = async (userId: string, index: number) => {
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: location.pathname,
      index: pageParam,
    });

    await handleUserTableRowClick(
      userId,
      requestParams?.profileStatusId,
      () => navigate(userId),
      refetch,
      t("global.something_went_wrong")
    );
  };

  const handleDelete = async (
    event: React.MouseEvent<HTMLSpanElement>,
    userId: string,
    index: number
  ) => {
    event.stopPropagation();
    const result = await showConfirmationModal({
      title: "admin.delete_research_institute",
      message: "admin.delete_research_institute_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      modalIcon: "confirmDelete",
      isAlert: true,
    });
    if (!result) {
      return;
    }

    try {
      const currentPage = Math.floor(index / PAGE_LIMIT);
      const data = await deleteResearchInstitute(userId);
      queryClient.invalidateQueries(["users-details"], {
        refetchPage: (_, index) => index === currentPage,
      });
      showSuccessToastMessage({
        message: data.message,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const updateRequestParams = (data: FilterParams) => {
    setRequestParams({
      ...requestParams,
      ...data,
    });
    refetch();
  };

  const handleClearFilter = () => {
    clearFilterDataStore();
    setRequestParams(
      selectedUserStatusType
        ? {
            ...ADMIN_REQUEST_INIT_PARAMS,
            profileStatusId: selectedUserStatusType,
            userType: USERS.ResearchInstitute,
            isGlobal: getGlobalFilterParam(userType ?? GLOBAL_PORTAL_FILTERS.Both),
          }
        : { ...ADMIN_REQUEST_INIT_PARAMS, userType: USERS.ResearchInstitute }
    );
    queryClient.resetQueries(["users-details"]);
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "admin.users",
    });
  }, []);

  useEffect(() => {
    if (scrollInfo?.pathName && pathName !== scrollInfo?.pathName) {
      clearFilterDataStore();
    }
  }, [pathName, scrollInfo?.pathName]);

  const isClearFilterActive: boolean =
    JSON.stringify(requestParams) !==
    JSON.stringify({
      ...ADMIN_REQUEST_INIT_PARAMS,
      profileStatusId: selectedUserStatusType ?? USER_STATUS.Invited,
      userType: USERS.ResearchInstitute,
      isGlobal: getGlobalFilterParam(userType ?? GLOBAL_PORTAL_FILTERS.Both),
    });

  const handleInvitedRowClick = (data: ResearchInstituteUsersData) => {
    showResearchScholarDetailsModal(data);
  };

  const handleRadioTypeChange = (value: boolean | string, item: number) => {
    queryClient.resetQueries(["users-details"]);
    setRequestParams((prevstate) => ({
      ...prevstate,
      isGlobal: value,
    }));
    setActiveFilter(item);
    setUserType(item);
  };

  return (
    <div className=" w-full h-full flex flex-col">
      <div
        className={
          scroll
            ? "navbar px-10   p-0 justify-between  top-0 z-[99] nav-header bg-white animate__animated animate__fadeInDown shadow-sticky w-[calc(100vw-250px)] !py-[26px] !h-[90px] fixed stickyHeader"
            : "navbar bg-gray-light px-10   p-0 justify-between h-[118px] py-[30px] top-0 z-30 nav-header w-[calc(100vw-250px)] fixed"
        }
      >
        <Header
          className="!px-0 !py-0 !w-full !h-auto !bg-transparent"
          title={"home.research_institutes"}
          linkList={linkList}
        />
        <div
          className="dropdown dropdown-bottom dropdown-end ltr:ml-[18px] rtl:mr-[18px]"
          onClick={() => setIsOpen(!isOpen)}
          ref={dropDownRef}
        >
          <PrimaryButton
            className=" min-w-[120px] h-[38px] min-h-[38px]"
            title={"organization.invite_users"}
            handleClick={() => showResearchInstituteIndividualInviteModal()}
          />
        </div>
      </div>
      <div className="px-10 h-full flex flex-col pt-[118px] pb-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-[10px] text-white selectedCard relative">
          {usersDetails && usersDetails.summary?.length > 0 ? (
            usersDetails.summary?.map((item) => {
              if (item.userTypeId === USERS.ResearchInstitute) {
                return item.userSummary?.map((i, index) => (
                  <StatisticsDataCard
                    key={`${i.name}-${index}-${i.count}`}
                    data={{
                      title: i.name,
                      value: i.count,
                      type: i.name,
                    }}
                    handleClick={() => handleCardClick(i.id)}
                    isActive={i.id === requestParams.profileStatusId}
                    statusCount={[
                      {
                        label: "research_institute.start_up",
                        value: usersDetails?.startUpCount ? usersDetails?.startUpCount : 0,
                      },
                      {
                        label: "research_institute.others",
                        value: usersDetails?.otherCount ? usersDetails?.otherCount : 0,
                      },
                    ]}
                  />
                ));
              }
            })
          ) : (
            <InlineLoader />
          )}
        </div>
        <div className=" flex items-center justify-between">
          <Title
            className="font-semibold text-black mb-5 mt-[30px] text-base"
            title={t(getTableHeaders())}
          />

          {isClearFilterActive ? (
            <SecondaryButton
              startIcon="clearFilter"
              className="!min-h-[38px] !h-[38px]  space-x-1.5 !bg-transparent text-primary-dark"
              title={"global.clear_filters"}
              handleClick={handleClearFilter}
            />
          ) : null}
        </div>
        <PortalFilter activeFilter={activeFilter} handleRadioTypeChange={handleRadioTypeChange} />
        {usersDetails && !isLoading ? (
          <Title
            className=" text-primary-light text-sm leading-4 font-normal mb-3"
            title={`${usersDetails?.totalCount ? usersDetails?.totalCount : t("global.no")} ${t(
              "organization.results_found"
            )}`}
          />
        ) : null}

        {isLoading && !isClearFilterActive ? (
          <div
            className={` flex flex-col w-full h-auto xl:h-full items-center rounded-md justify-start bg-white mb-[45px] noData`}
          >
            <div className=" flex flex-col items-center justify-start my-[102px]">
              <InlineLoader />
            </div>
          </div>
        ) : usersDetails ? (
          <InfiniteScroll
            dataLength={usersDetails?.users?.length}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={hasNextPage || isLoading ? <TableLoader /> : null}
            scrollThreshold="200px"
            className="!min-h-[75vh] "
          >
            <div className=" flex flex-col p-4 pt-[18px] w-full h-full items-center rounded-md justify-start bg-white">
              {requestParams?.profileStatusId === USER_STATUS.Invited ? (
                <InvitedUsersTable
                  userData={usersDetails?.users ?? []}
                  headers={INVITED_RESEARCH_INSTITUTE_TABLE_HEADERS}
                  handleRowClick={handleInvitedRowClick}
                  updateRequestParams={updateRequestParams}
                  requestParams={requestParams}
                  profileStatusId={USER_STATUS.Invited}
                  handleDelete={handleDelete}
                />
              ) : (
                <Table
                  userData={usersDetails?.users ?? []}
                  headers={RESEARCH_INSTITUTE_TABLE_HEADERS}
                  handleRowClick={(userId, index) => handleRowClick(userId, index)}
                  updateRequestParams={updateRequestParams}
                  requestParams={requestParams}
                  profileStatusId={selectedUserStatusType}
                  handleDelete={handleDelete}
                />
              )}
              <NoResults
                isLoading={isLoading}
                isFilterApplied={isClearFilterActive}
                dataLength={usersDetails?.users?.length ?? 0}
              />
            </div>
          </InfiniteScroll>
        ) : (
          <NoData message="admin.no_data_to_show" />
        )}
      </div>
    </div>
  );
}
