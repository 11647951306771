import { Link } from "react-router-dom";
import { SubTitle, Title, ImageLoader } from "components";
import DefaultCoverImage from "assets/images/challengeDefault.png";
import { displayDateFormat, getEventTagClassName } from "utils/helpers";
import useLoadImage from "hooks/useImageLoaded";

type EventInfoCardProps = {
  data: EventData;
  path?: string;
  handleEventClick: () => void;
};

export function EventInfoCard({ data, path, handleEventClick }: EventInfoCardProps) {
  const [ref, isLoaded, onLoad] = useLoadImage();

  return (
    <Link to={path ? path : `${data?.eventId}`} onClick={handleEventClick}>
      <div className=" bg-white rounded-md w-full flex h-full flex-col cursor-pointer">
        <div className=" rounded-md min-h-[140px] h-[140px] overflow-hidden">
          {!isLoaded && <ImageLoader />}
          <img
            ref={ref}
            alt="event cover image"
            className=" w-full h-[140px] object-cover "
            src={data?.coverImage ? data?.coverImage : DefaultCoverImage}
            onLoad={onLoad}
          />
        </div>
        <div className=" p-4 lg:p-5 flex flex-col items-start h-full">
          <Title
            className="font-bold text-base leading-[26px] text-gray-109 pb-1 line-clamp-1 WB_BW dirRTL-LTR"
            title={data?.title}
          />
          <SubTitle
            className="font-normal text-sm leading-normal text-black pb-1 !justify-start mb-2.5 "
            title={`${displayDateFormat(data?.startDate)} - ${displayDateFormat(data?.endDate)}`}
          />
          <div className="flex items-center space-x-2 rtl:space-x-reverse mt-auto">
            <div
              className={`py-1.5 px-3 rounded-xl text-xs text-white font-normal bg-primary-medium   mt-auto ${getEventTagClassName(
                data?.statusId
              )}`}
            >
              {data?.status}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
