import { create } from "zustand";
import { produce } from "immer";

export type ResearchInstituteDataStore = {
  researchInstituteInfo?: ResearchInstituteDetails | undefined;
};

export const useResearchInstituteDataStore = create<ResearchInstituteDataStore>()(() => ({
  researchInstituteInfo: undefined,
}));

export function setResearchInstituteInfo(researchInstituteInfo: ResearchInstituteDetails) {
  useResearchInstituteDataStore.setState(
    produce((state: ResearchInstituteDataStore) => {
      state.researchInstituteInfo = researchInstituteInfo;
    })
  );
}

export function setResearchInstituteInfoItem(
  value: string | number | OptionItem[] | null,
  key: keyof ResearchInstituteDetails
) {
  useResearchInstituteDataStore.setState(
    produce((state: any) => {
      if (state.researchInstituteInfo) {
        state.researchInstituteInfo[key] = value;
      }
    })
  );
}

export function clearResearchInstituteDataStore() {
  useResearchInstituteDataStore.setState(
    produce((state: ResearchInstituteDataStore) => {
      state.researchInstituteInfo = undefined;
    })
  );
}
