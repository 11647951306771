import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function deleteFileUpload(formData: FileDetailsItem) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(urls.eventDeleteFileUpload(), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response?.data as ApiStatus | ApiError;
}

export function useDeleteFileUpload() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;

  return useMutation(async (formData: FileDetailsItem) => {
    if (userId) {
      const result = await deleteFileUpload(formData);
      return result;
    }
  });
}
