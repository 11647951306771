import { create } from "zustand";
import { produce } from "immer";

const EVENT_ABOUT_INIT_STATE: EventDetails = {
  id: 0,
  title: "",
  endDate: "",
  startDate: "",
  location: "",
  targetIndustry: null,
  coverImage: "",
  eventDescription: "",
  websiteUrl: "",
  eventCompletionStatusId: 0,
  eventStatusId: 0
};

const EVENT_LEARN_MORE_INIT_STATE: EventLearnMore = {
  id: 0,
  learnMore: "",
  documents: [],
};

export type EventDataStore = {
  detailsInfo: EventDetails;
  descriptionInfo: EventLearnMore;
};

export const useEventDataStore = create<EventDataStore>()(() => ({
  detailsInfo: EVENT_ABOUT_INIT_STATE,
  descriptionInfo: EVENT_LEARN_MORE_INIT_STATE,
}));

export function setEventDetailsInfo(detailsInfo: EventDetails) {
  useEventDataStore.setState(
    produce((state: EventDataStore) => {
      state.detailsInfo = detailsInfo;
    })
  );
}

export function setEventDetailsInfoItem(
  value: string | number | OptionItem | null,
  key: keyof EventDetails
) {
  useEventDataStore.setState(
    produce((state: any) => {
      if (state.detailsInfo) {
        state.detailsInfo[key] = value;
      }
    })
  );
}

export function setEventDescriptionInfo(description: EventLearnMore) {
  useEventDataStore.setState(
    produce((state: EventDataStore) => {
      state.descriptionInfo = description;
    })
  );
}

export function setEventDescriptionInfoItem(
  value: string | Array<File | FileDetails>,
  key: keyof EventLearnMore
) {
  useEventDataStore.setState(
    produce((state: any) => {
      if (state.descriptionInfo) {
        state.descriptionInfo[key] = value;
      }
    })
  );
}

export function clearEventDataStore() {
  useEventDataStore.setState(
    produce((state: EventDataStore) => {
      state.detailsInfo = EVENT_ABOUT_INIT_STATE;
      state.descriptionInfo = EVENT_LEARN_MORE_INIT_STATE;
    })
  );
}
