import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function reply(challengeId: number, postId: number, formData: Reply) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(urls.reply(challengeId, postId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useReply() {
  const qc = useQueryClient();
  return useMutation(
    async ({
      challengeId,
      postId,
      formData,
    }: {
      challengeId: number;
      postId: number;
      formData: Reply;
    }) => {
      const result = await reply(challengeId, postId, formData);
      qc.resetQueries(["forum-replies"]);
      qc.resetQueries(["forum-posts"]);
      qc.resetQueries(["challenge-basic-details"]);
      qc.resetQueries(["message-threads"]);
      qc.resetQueries(["forums"]);
      return result;
    }
  );
}
