import React from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";

type PrimaryButtonProps = {
  title: string;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  startIcon?: string;
  endIcon?: string;
  className?: string;
  disabled?: boolean;
};

export function PrimaryButton({
  title,
  handleClick,
  startIcon,
  endIcon,
  className,
  disabled,
}: PrimaryButtonProps) {
  const { t } = useTranslation();
  return (
    <button
      className={`btn btnPrimary normal-case text-sm font-medium h-12 rounded-md bg-secondary-medium border border-secondary-medium text-white hover:text-white hover:border-green-300 hover:bg-green-300 ${className}`}
      onClick={handleClick}
      disabled={disabled}
    >
      {startIcon ? <SVGIcon name={startIcon} /> : null}
      <span>{t(title)}</span>
      {endIcon ? <SVGIcon name={endIcon} /> : null}
    </button>
  );
}
