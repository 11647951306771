import { useState } from "react";
import { IconTypeButton, Title } from "components";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PDFViewerProps = {
  url: string;
  fileName: string;
};

export function PDFView({ url, fileName }: PDFViewerProps) {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number | null>();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess(doc: any) {
    setNumPages(doc?.numPages);
  }

  return (
    <div className=" w-full">
      <div className="border-b border-gray-light">
        <Title
          className="text-base font-medium text-black p-5 px-4 ltr:pr-16 rtl:pl-16 leading-normal ltr:text-left rtl:text-right border-b border-gray-light truncate titleTextTruncate max-w-[calc(100vw-24px)]"
          title={fileName}
        />
      </div>
      <div className=" text-black overflow-auto w-full min-h-[400px] max-h-[calc(100vh-220px)] flex flex-col items-center">
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      {numPages && numPages > 0 ? (
        <div className=" space-x-3 rtl:space-x-reverse py-5 flex items-center justify-center pdf-pagination-buttons">
          <IconTypeButton
            handleClick={() => {
              setPageNumber(pageNumber - 1);
            }}
            disabled={pageNumber === 1}
            iconName="leftArrow"
            className=" w-6 h-6 bg-primary-medium text-white rounded hover:bg-secondary-medium rotate_180"
          />
          <span className=" flex items-center justify-center min-w-[50px]">{`${pageNumber} ${t(
            "global.of"
          )} ${numPages}`}</span>
          <IconTypeButton
            handleClick={() => {
              setPageNumber(pageNumber + 1);
            }}
            disabled={pageNumber === numPages}
            iconName="rightArrow"
            className=" w-6 h-6 bg-primary-medium text-white rounded hover:bg-secondary-medium rotate__180"
          />
        </div>
      ) : null}
    </div>
  );
}
