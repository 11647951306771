import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Title,
  StatisticsDataCard,
  NoData,
  LoaderModal,
  InlineLoader,
  ChallengeCard,
  Header,
} from "components";
import { useGetUsersDetails } from "features/Admin/api/useGetUsersDetails";
import {
  CHALLENGE_STATUS,
  PAGE_LIMIT,
  USERS,
  USER_STATUS,
  ADMIN_CHALLENGE_INIT_PARAM,
} from "utils/constants";
import { setSelectedSideMenu, setSelectedUserStatusType } from "store/useSidebarStore";
import { useStickyScroll } from "hooks/useStickyScroll";
import { useGetChallengeList } from "features/Challenge/api/useGetChallengeList";
import { useUserDataStore } from "store/useUserDataStore";
import { setScrollInfo, useFilterDataStore } from "store/useFilterDataStore";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import { useSetScroll } from "hooks/useSetScroll";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";

export default function DashBoard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { scroll } = useStickyScroll();
  const [requestParams] = useState<UserDetailsParams>({
    pageNumber: 1,
    pageLimit: PAGE_LIMIT,
    profileStatusId: USER_STATUS.PendingApproval,
    userType: USERS.ResearchScholar,
    country: "",
    type: "",
    fromDate: "",
    toDate: ""
  });
  const { scrollPosition } = useGetScrollPosition();

  const challengeRequestParams: ChallengeListParams = ADMIN_CHALLENGE_INIT_PARAM;

  const { data, isLoading } = useGetUsersDetails(requestParams);
  const { userInfo } = useUserDataStore();
  const { data: challengeList, isLoading: challengeListLoading } =
    useGetChallengeList(challengeRequestParams);

  const usersDetails = useMemo(() => {
    const tempData: UsersDetails = { users: [], totalCount: 0, totalPages: 0, summary: [] };
    if (data?.pages && data?.pages[0]) {
      tempData.summary = data.pages[0]?.summary;
      tempData.totalCount = data.pages[0]?.totalCount;
      tempData.totalPages = data.pages[0]?.totalPages;
      const users = data.pages
        .map((item) => {
          return item.users;
        })
        .flat();
      tempData.users = users;
    }
    return tempData;
  }, [data]);

  const challengeDetails = useMemo(() => {
    const tempData: ChallengeListingResponse = { challenges: [], totalCount: 0, totalPages: 0 };
    if (challengeList?.pages && challengeList?.pages[0]) {
      tempData.totalCount = challengeList.pages[0]?.totalCount;
      tempData.totalPages = challengeList.pages[0]?.totalPages;
      const challenges = challengeList.pages
        .map((item) => {
          return item.challenges;
        })
        .flat();
      tempData.challenges = challenges;
    }
    return tempData;
  }, [challengeList]);

  const handleCardClick = (type: number) => {
    setSelectedUserStatusType();
    if (type === USERS.ResearchScholar) {
      navigate("/admin/users/research-scholar", { replace: true });
    } else if (type === USERS.Organization) {
      navigate("/admin/users/organization", { replace: true });
    } else if (type === USERS.Funding) {
      navigate("/admin/users/funding-organization", { replace: true });
    } else if (type === USERS.ResearchInstitute) {
      navigate("/admin/users/research-institute", { replace: true });
    }
  };

  const { scrollInfo } = useFilterDataStore();
  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "challenge-list");

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.dashboard",
    });
  }, []);

  function handleChallengeClick(index: number) {
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: location.pathname,
      index: pageParam,
    });
  }

  return (
    <>
      <div className=" w-full h-full flex flex-col">
        <Header
          title={"global.dashboard"}
          className={
            scroll
              ? " bg-white animate__animated animate__fadeInDown shadow-sticky !py-[26px] !h-[90px] fixed stickyHeader"
              : "fixed"
          }
        />
        <div className="px-10 h-full flex flex-col pt-[118px] pb-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-[10px] text-white selectedCard !min-h-fit relative">
            {usersDetails && usersDetails.summary?.length > 0 ? (
              usersDetails.summary.map((item) => (
                <StatisticsDataCard
                  key={`${item.userTypeId}`}
                  data={{
                    title: item.userTypeName,
                    value: item.userSummary.find((i) => {
                      if (i.id === USER_STATUS.Approved) {
                        return i.count;
                      }
                    })
                      ? item.userSummary.find((i) => {
                        if (i.id === USER_STATUS.Approved) {
                          return i.count;
                        }
                      })?.count || 0
                      : 0,
                    type: item.userTypeName,
                  }}
                  handleClick={() => handleCardClick(item.userTypeId)}
                  isActiveUsers
                />
              ))
            ) : (
              <InlineLoader />
            )}
          </div>
          <Title
            className="font-semibold text-black mb-5 mt-[30px] text-base"
            title={t("admin.latest_challenges")}
          />
          {!challengeListLoading && challengeDetails?.challenges?.length === 0 ? (
            <div>
              <NoData message={"admin.no_challenges_to_show"} />
            </div>
          ) : null}
          <div className="grid grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 gap-5">
            {challengeDetails.challenges.map((item: ChallengeData, index: number) => (
              <div key={index} className="">
                <ChallengeCard
                  data={item}
                  showChallengeSignature={userInfo?.userType === USERS.Organization}
                  path={
                    item?.statusId === CHALLENGE_STATUS.Draft
                      ? "/organization/challenges/create"
                      : ""
                  }
                  handleChallengeClick={() => handleChallengeClick(index)}
                />
              </div>
            ))}
          </div>
        </div>
        {isLoading || challengeListLoading ? <LoaderModal /> : null}
      </div>
    </>
  );
}
