import Joi from "joi";
import { groupBy } from "utils/helpers";
import { organizationSignUpSchema } from "utils/validation/organizationSignUpValidation";

const organizationCreationSchema = organizationSignUpSchema.keys({
  isGlobal: Joi.boolean(),
});

export const organizationCreationValidation = (basicFormData: OrganizationCreation) => {
  const result = organizationCreationSchema.validate(basicFormData);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
