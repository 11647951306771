import { useInfiniteQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getChallengeList({
  pageNumber,
  pageLimit,
  userId,
  status,
  type,
  researchArea,
  isOwned,
  isExplore,
  isGlobal
}: ChallengeListParams) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(
    urls.challengeList({
      pageNumber,
      pageLimit,
      userId,
      status,
      type,
      researchArea,
      isOwned,
      isExplore,
      isGlobal
    }),
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return res?.data?.data as ChallengeListingResponse | ApiError;
}

export function useGetChallengeList({
  status,
  type,
  pageLimit,
  researchArea,
  isOwned,
  isExplore,
  isGlobal
}: ChallengeListParams) {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;
  if (
    window.location.pathname === "/research-scholar/home" ||
    window.location.pathname === "/funding-organization/home"
  ) {
    isOwned = true;
    researchArea = [];
  }
  return useInfiniteQuery(
    ["challenge-list", userId, status, type, researchArea, isOwned, isExplore, isGlobal],
    async ({ pageParam = 1 }) => {
      const result = await getChallengeList({
        pageNumber: pageParam,
        pageLimit: pageLimit,
        userId: userId ?? "",
        status: status,
        type: type,
        researchArea: researchArea,
        isOwned: isOwned,
        isExplore: isExplore,
        isGlobal
      });
      return result;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.totalPages > allPages.length ? allPages?.length + 1 : undefined;
      },
      staleTime: Infinity,
    }
  );
}
