import { PrimaryButton, TextButton, ProfileNavigationTimeline } from "components";
import { CHALLENGE_STATUS, CREATE_CHALLENGE_COMPLETION_STATUS } from "utils/constants";
import { useChallengeStore } from "store/useChallengeStore";

type CreateChallengeNavigatorProps = {
  handleDraft: () => void;
  handleNext: () => void;
  handleNavigationClick: (value: number) => void;
  handlePreview: () => void;
  navigationItems: Array<RecordItem>;
  stepNumber: number;
  completionStatusId: number;
  isEdit: boolean;
};

function Navigator({
  handleDraft,
  handleNext,
  handleNavigationClick,
  handlePreview,
  navigationItems,
  completionStatusId,
  stepNumber,
  isEdit,
}: CreateChallengeNavigatorProps) {
  const { challengeInfo } = useChallengeStore();
  const isPreview = stepNumber === CREATE_CHALLENGE_COMPLETION_STATUS.Tags;
  return (
    <div className=" bg-white rounded-md pl-3 pt-4 pb-[6px] lg:pt-0 lg:!p-[15px] lg:!pb-0">
      {isEdit ? (
        <div className="flex space-x-2.5 rtl:space-x-reverse pb-[30px]">
          <PrimaryButton
            title=""
            handleClick={handlePreview}
            startIcon="eye"
            className="btn iconButton rounded-md hover:bg-primary-medium hover:!text-white bg-transparent border !border-primary-medium !text-primary-medium text-sm font-medium !min-w-[48px] !px-0 w-auto flex-0"
          />
          <TextButton
            title={"global.update"}
            handleClick={handleDraft}
            className="btn normal-case rounded-md w-full bg-secondary-medium border-none text-white text-sm font-medium  hover:bg-primary-medium hover:text-white flex-1"
          />
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-[10px] pb-[30px]">
          <TextButton
            title={"global.save_draft"}
            handleClick={handleDraft}
            className="btn normal-case rounded-md hover:bg-primary-medium hover:text-white w-full bg-transparent border border-primary-medium text-primary-medium text-sm font-medium"
            isDisabled={challengeInfo?.challengeStatusId===CHALLENGE_STATUS.Pending}
          />
          <TextButton
            title={isPreview ? "organization.preview" : "global.next"}
            handleClick={handleNext}
            className="btn normal-case rounded-md w-full bg-secondary-medium border-none text-white text-sm font-medium  hover:bg-primary-medium hover:text-white"
            isDisabled={challengeInfo?.challengeStatusId===CHALLENGE_STATUS.Pending}
          />
        </div>
      )}

      <div>
        <ProfileNavigationTimeline
          stepNumber={stepNumber}
          handleClick={handleNavigationClick}
          navigatorList={navigationItems}
          isViewOnly={false}
          profileCompletionStatusId={completionStatusId}
        />
      </div>
    </div>
  );
}

export default Navigator;
