import { useInfiniteQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { PAGE_LIMIT } from "utils/constants";
import { urls } from "utils/urls";

export async function getUsersDetails({
  pageNumber,
  pageLimit,
  profileStatusId,
  userType,
  country,
  fromDate,
  toDate,
  type,
  isGlobal,
  isAdvisor,
}: UserDetailsParams) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(
    urls.getPendingRequests({
      pageNumber,
      pageLimit,
      profileStatusId,
      userType,
      country,
      fromDate,
      toDate,
      type,
      isGlobal,
      isAdvisor,
    }),
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res?.data?.data as UsersDetails | ApiError;
}

export function useGetUsersDetails({
  profileStatusId,
  userType,
  country,
  fromDate,
  toDate,
  type,
  isGlobal,
  isAdvisor,
}: UserDetailsParams) {
  return useInfiniteQuery(
    [
      "users-details",
      userType,
      profileStatusId,
      country,
      fromDate,
      toDate,
      type,
      isGlobal,
      isAdvisor,
    ],
    async ({ pageParam = 1 }) => {
      const result = await getUsersDetails({
        pageNumber: pageParam,
        pageLimit: PAGE_LIMIT,
        profileStatusId,
        userType,
        country,
        fromDate,
        toDate,
        type,
        isGlobal,
        isAdvisor,
      });
      return result;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.totalPages > allPages.length ? allPages?.length + 1 : undefined;
      },
      staleTime: Infinity,
    }
  );
}
