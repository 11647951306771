import { Outlet } from "react-router-dom";
import { BackToTop, ScrollToTop } from "components";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import { PATHS } from "utils/constants";

export default function AppOutlet() {
  const { scrollPosition } = useGetScrollPosition();

  return (
    <div className="flex w-full  min-h-screen">
      {!PATHS.includes(location.pathname) && <ScrollToTop />}
      <Outlet />
      {PATHS.includes(location.pathname) && scrollPosition > 400 ? (
        <BackToTop
          className={
            window.location.pathname.includes("search-result") ? "max-[1024px]:hidden" : ""
          }
        />
      ) : null}
    </div>
  );
}
