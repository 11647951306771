type ClickableTextProps = {
  text: string;
  subtitle?: string;
  iconType?: string;
  onClick: () => void;
};
export function ClickableText({ text, subtitle, iconType, onClick }: ClickableTextProps) {
  return (
    <div onClick={onClick}>
      <span>{text}</span>
      {subtitle && <span>{subtitle}</span>}
      {iconType && <img src={iconType} alt="Icon" />}
    </div>
  );
}
