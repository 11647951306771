import Joi from "joi";
import { groupBy } from "utils/helpers";

export const challengeLearnMoreSchema = Joi.object({
  overviewOfCurrentChallenges: Joi.string().trim().messages({ "string.empty": "required" }),
  detailedDescriptionOftheChallenges: Joi.string().trim().messages({ "string.empty": "required" }),
  desiredEnhancements: Joi.string().trim().messages({ "string.empty": "required" }),
  resourcesAndConstraints: Joi.string().trim().messages({ "string.empty": "required" }),
  successMetrics: Joi.string().trim().messages({ "string.empty": "required" }),
  challengeStatusId: Joi.number().optional(),
  challengeCompletionStatusId: Joi.number().optional(),
}).options({ abortEarly: false });

export const challengeLearnMoreValidation = (challengeLearnMore: ChallengeLearnMore) => {
  const result = challengeLearnMoreSchema.validate(challengeLearnMore);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
