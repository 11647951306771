import React, { useEffect, useState, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DefaultResetBgImage from "assets/images/defaultResetPasswordBg.png";
import {
  InputField,
  CheckBox,
  SubTitle,
  AppLogo,
  Title,
  PrimaryButton,
  LoaderModal,
  Footer,
  LanguageSwitchButton,
} from "components";
import { isRuleVerified } from "utils/helpers";
import { PASSWORD_MAX_CHAR_LENGTH, PASSWORD_RULES } from "utils/constants";
import { passwordSchemaValidation } from "utils/validation/signUpValidation";
import {
  ToasterIconType,
  showErrorToastMessage,
  showSuccessToastMessage,
} from "utils/toasterMessage";
import { useResetPassword, useVerifyResetPasswordLink } from "features/Login/api/useAuth";

const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: resetPassword, isLoading } = useResetPassword();
  const { mutateAsync: verifyResetPasswordLink, isLoading: isLinkLoading } =
    useVerifyResetPasswordLink();

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");
  const token = urlParams.get("token");

  const passwordInitialState: PasswordFormFields = {
    password: "",
    confirmPassword: "",
  };

  const [passwordFormData, setPasswordFormData] =
    useState<PasswordFormFields>(passwordInitialState);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const handlePasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const value = event.target.value;
    setPasswordFormData((prevstate) => ({ ...prevstate, [fieldName]: value }));
  };

  const prepareFormData = () => {
    const data: ResetPasswordData = {
      email: email ? email.trim() : "",
      password: passwordFormData.password.trim(),
      token: token ? token.trim() : "",
    };
    return data;
  };

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const error = passwordSchemaValidation(passwordFormData);
    if (error) {
      setValidationErrors(error);
      return;
    }
    setValidationErrors(null);
    try {
      const formData = prepareFormData();
      const resetPasswordResponse = await resetPassword(formData);
      showSuccessToastMessage({
        message: resetPasswordResponse.message,
        position: i18n.language === "ar" ? "top-right" : "top-left",
      });
      setTimeout(() => {
        navigate("/login");
      }, 500);
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error.response.data.status === 400
            ? error.response.data.message
            : t("global.something_went_wrong"),
        position: i18n.language === "ar" ? "top-right" : "top-left",
      });
    }
  };

  const verifyEmailLink = async () => {
    try {
      const formData = prepareFormData();
      await verifyResetPasswordLink(formData);
    } catch (error: ApiError) {
      if (error.response.data.status === 400) {
        showErrorToastMessage({
          message: error.response.data.message,
          position: i18n.language === "ar" ? "top-right" : "top-left",
        });
        navigate("/forgot-password");
      } else {
        showErrorToastMessage({
          iconType: ToasterIconType.ALERT,
          message: t("global.something_went_wrong"),
          position: i18n.language === "ar" ? "top-right" : "top-left",
        });
      }
    }
  };

  const handleInputPaste = (e: SyntheticEvent) => {
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    if (email && token) {
      verifyEmailLink();
    }
  }, [email, token]);

  return (
    <div className="h-screen min-w-screen flex overflow-hidden">
      <div className="h-full w-full">
        {!isLinkLoading ? (
          <div className="h-full w-full relative rtl:pl-[550px] ltr:pr-[550px] max-[1280px]:rtl:pl-[450px] max-[1280px]:ltr:pr-[450px] max-[1024px]:!p-0">
            <img
              src={DefaultResetBgImage}
              alt="Reset background"
              className="w-full h-full object-cover max-[1024px]:hidden"
            />
            {/*Reset password section */}
            <div className="h-full bg-gradient-blueDark w-[551px] max-[1280px]:w-[451px]  absolute top-0 ltr:right-0 rtl:left-0 max-[1024px]:relative max-[1024px]:w-full">
              <div className="h-full w-full default-inputs-section">
                <div className="overflow-auto h-full">
                  {/*Language selector */}
                  <div className="relative z-20 pt-3.5 ltr:pr-5 rtl:pl-5 mb-[65px] flex justify-end max-[1024px]:!p-5 max-[1024px]:mb-0">
                    <LanguageSwitchButton className="language-selector min-w-[86px] h-[38px]" />
                  </div>
                  {/*Reset password inputs section */}
                  <div className="ltr:pr-[105px] ltr:pl-[3px] rtl:pr-[3px] rtl:pl-[105px]  max-[1280px]:ltr:pr-[85px]  max-[1280px]:rtl:pl-[85px]  max-[1024px]:!px-5 relative z-10 h-[calc(100%-117px)]  max-[1024px]:h-[calc(100%-78px)] flex flex-col">
                    <div>
                      <AppLogo />
                      <div className="mt-[42px] max-[1024px]:mt-[20px]">
                        <Title
                          className="text-white text-xl font-semibold mb-8 max-[1024px]:text-base max-[1024px]:mb-5"
                          title={t("global.reset_password")}
                        />
                        <form className="flex flex-col">
                          <div className=" space-y-5">
                            <InputField
                              hintText={t("global.create_new_password")}
                              labelText={t("global.new_password")}
                              handleInputChange={handlePasswordInputChange}
                              value={passwordFormData.password}
                              fieldName="password"
                              inputType="password"
                              errorMessage={validationErrors?.password}
                              isRequired
                              maxCharLength={PASSWORD_MAX_CHAR_LENGTH}
                              className="px-[18px]"
                            />
                            <InputField
                              hintText={t("global.confirm_password")}
                              labelText={t("global.confirm_password")}
                              handleInputChange={handlePasswordInputChange}
                              handleInputPaste={handleInputPaste}
                              value={passwordFormData.confirmPassword}
                              fieldName="confirmPassword"
                              inputType="password"
                              errorMessage={validationErrors?.confirmPassword}
                              isRequired
                              maxCharLength={PASSWORD_MAX_CHAR_LENGTH}
                              className="px-[18px] ltr:pr-[35px] rtl:pl-[35px]"
                            />
                          </div>
                          <div className="mt-10">
                            <SubTitle
                              className="font-normal text-white !justify-start text-sm mb-2 yourPasswordMust"
                              title={t("global.your_password_must")}
                            />
                            <div className="passwordMust">
                              {PASSWORD_RULES.map((item, index) => (
                                <CheckBox
                                  option={{
                                    label: t(`global.passwordRule${index + 1}`),
                                    value: item,
                                  }}
                                  key={item}
                                  value={item}
                                  className={` text-sm text-gray-100 font-normal password-must-icons ${
                                    isRuleVerified(index + 1, passwordFormData.password)
                                      ? "password-success"
                                      : "password-fail"
                                  }`}
                                />
                              ))}
                            </div>
                          </div>
                          <div className="mt-[56px] max-[1024px]:mt-[30px]">
                            <PrimaryButton
                              title={"global.reset_password"}
                              handleClick={onSubmit}
                              className="default-primary-button w-full"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                    {/*Footer section */}
                    <div className="mt-auto relative  z-10 pb-[30px] pt-[90px]  max-[1024px]:w-full  bg-primary-medium max-[1024px]:pt-[50px]">
                      <div>
                        <Footer />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {isLoading || isLinkLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
};

export default ResetPassword;
