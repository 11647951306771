import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export const getTags = async ({
  pageNumber,
  pageLimit,
  searchKeyword,
}: SearchParams): Promise<MasterDataResponse> => {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(
    urls.getTags(pageNumber, pageLimit, searchKeyword ? encodeURIComponent(searchKeyword) : ""),
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res.data.data as MasterDataResponse | ApiError;
};
