import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { Title, LoaderModal, TextArea } from "components";
import { CHALLENGE_STATUS, SUMMARY_MAX_CHAR_LENGTH, URL_MAX_LENGTH } from "utils/constants";
import {
  setChallengeLearnMore,
  setChallengeLearnMoreInfoItem,
  useChallengeStore,
} from "store/useChallengeStore";
import { useGetChallengeLearnMore } from "features/Organization/api/useGetChallengeLearnMore";

type LearnMoreProps = {
  validationErrors: Record<string, string> | null;
};

const LearnMore = ({ validationErrors }: LearnMoreProps) => {
  const { data: learnMoreData, isLoading } = useGetChallengeLearnMore();
  const { learnMore } = useChallengeStore();
  const { t } = useTranslation();
  const isPending = learnMore?.challengeStatusId === CHALLENGE_STATUS.Pending;
  const handleInputChange =
    (key: keyof ChallengeLearnMore) => (e: ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      setChallengeLearnMoreInfoItem(value, key);
    };

  useEffect(() => {
    if (learnMoreData) {
      setChallengeLearnMore(learnMoreData);
    }
  }, [learnMoreData]);

  return (
    <div>
      <div className=" flex items-center justify-between mb-6 flex-wrap pb-8 p-5 !py-3 border-b border-gray-light">
        <div className="flex items-center ltr:mr-auto rtl:ml-auto">
          <SVGIcon name="learnMore" />
          <Title
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            title={t("organization.challenge_learn_more")}
          />
        </div>
      </div>

      <div className="my-6 mx-5">
        <div className="mt-[22px] w-full">
          <TextArea
            textContent={learnMore.overviewOfCurrentChallenges ?? ""}
            handleChange={handleInputChange("overviewOfCurrentChallenges")}
            labelText={t("organization.overview_of_current_challenges")}
            hintText={t("organization.overview_of_current_challenges_placeholder")}
            fieldName="overviewOfCurrentChallenges"
            isRequired
            maxCharLength={URL_MAX_LENGTH}
            errorMessage={validationErrors?.overviewOfCurrentChallenges}
            isDisabled={isPending}
          />
        </div>
      </div>
      <div className="my-6 mx-5">
        <div className="mt-[22px] w-full">
          <TextArea
            textContent={learnMore.detailedDescriptionOftheChallenges ?? ""}
            handleChange={handleInputChange("detailedDescriptionOftheChallenges")}
            labelText={t("organization.detailed_description_of_the_challenge")}
            hintText={t("organization.detailed_description_of_the_challenge_placeholder")}
            fieldName="detailedDescriptionOftheChallenges"
            isRequired
            maxCharLength={SUMMARY_MAX_CHAR_LENGTH}
            errorMessage={validationErrors?.detailedDescriptionOftheChallenges}
            isDisabled={isPending}
          />
        </div>
      </div>
      <div className="my-6 mx-5">
        <div className="mt-[22px] w-full">
          <TextArea
            textContent={learnMore.desiredEnhancements ?? ""}
            handleChange={handleInputChange("desiredEnhancements")}
            labelText={t("organization.desired_enhancement")}
            hintText={t("organization.desired_enhancement_placeholder")}
            fieldName="desiredEnhancements"
            isRequired
            maxCharLength={URL_MAX_LENGTH}
            errorMessage={validationErrors?.desiredEnhancements}
            isDisabled={isPending}
          />
        </div>
      </div>
      <div className="my-6 mx-5">
        <div className="mt-[22px] w-full">
          <TextArea
            textContent={learnMore.resourcesAndConstraints ?? ""}
            handleChange={handleInputChange("resourcesAndConstraints")}
            labelText={t("organization.resources_and_constraints")}
            hintText={t("organization.resources_and_constraints_placeholder")}
            fieldName="resourcesAndConstraints"
            isRequired
            maxCharLength={URL_MAX_LENGTH}
            errorMessage={validationErrors?.resourcesAndConstraints}
            isDisabled={isPending}
          />
        </div>
      </div>
      <div className="my-6 mx-5">
        <div className="mt-[22px] w-full">
          <TextArea
            textContent={learnMore.successMetrics ?? ""}
            handleChange={handleInputChange("successMetrics")}
            labelText={t("organization.success_metrics")}
            hintText={t("organization.success_metrics_placeholder")}
            fieldName="successMetrics"
            isRequired
            maxCharLength={URL_MAX_LENGTH}
            errorMessage={validationErrors?.successMetrics}
            isDisabled={isPending}
          />
        </div>
      </div>

      {isLoading ? <LoaderModal /> : null}
    </div>
  );
};

export default LearnMore;
