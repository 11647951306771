import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function deleteChallengeFileUpload(formData: FileDetails) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(urls.challengeDocumentUpload(), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response?.data as ApiStatus | ApiError;
}

export function useDeleteChallengeFileUpload() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;

  return useMutation(async ({ formData }: { formData: FileDetails }) => {
    if (userId) {
      const result = await deleteChallengeFileUpload(formData);
      return result;
    }
  });
}
