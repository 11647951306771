import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function createPost(collaborationId: number, formData: Post) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(urls.collaborationCreatePost(collaborationId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useCreatePost() {
  const qc = useQueryClient();
  return useMutation(async ({ collaborationId, formData }: { collaborationId: number; formData: Post }) => {
    const result = await createPost(collaborationId, formData);
    qc.resetQueries(["forum-posts"]);
    qc.invalidateQueries(["collaboration-basic-details"]);
    qc.invalidateQueries(["forums"]);
    return result;
  });
}
