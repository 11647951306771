import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function fundingOrganizationEmailInvite(formData: Array<string>) {
    const tokenInfo =useUserDataStore.getState().tokenInfo;
    const token = tokenInfo?.token;
    const response = await apiClient.post(urls.fundingOrganizationEmailInvite(), formData, {
        headers: {Authorization: `Bearer ${token}`},
    });
    return response?.data as ApiStatus | ApiError
}

export function useFundingOrganizationEmailInvite(){
    return useMutation(async (FormData: Array<string>) => {
        return await fundingOrganizationEmailInvite(FormData);
    });
}