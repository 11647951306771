import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQueryClient } from "@tanstack/react-query";
import {
  Title,
  TableLoader,
  StatisticsDataCard,
  FilterButton,
  InlineLoader,
  Header,
  SecondaryButton,
  NoResults,
  NoData,
} from "components";
import {
  setSelectedSideMenu,
  setSelectedUserStatusType,
  setSelectedUserType,
  useSidebarDataStore,
} from "store/useSidebarStore";
import {
  ADMIN_REQUEST_INIT_PARAMS,
  ALL_USERS_TABLE_HEADERS,
  ORGANIZATIONS_TABLE_HEADERS,
  PAGE_LIMIT,
  RESEARCH_INSTITUTE_TABLE_HEADERS,
  RESEARCH_SCHOLARS_TABLE_HEADERS,
  USERS,
  USER_STATUS,
} from "utils/constants";
import { handleUserTableRowClick } from "utils/helpers";
import AllUsersTable from "features/Admin/Users/UsersData/AllUsersTable";
import OrganizationTable from "features/Admin/Users/Organizations/Table";
import ResearchScholarTable from "features/Admin/Users/ResearchScholars/Table";
import FundingOrganizationTable from "features/Admin/Users/FundingOrganizations/Table";
import ResearchInstituteTable from "features/Admin/Users/ResearchInstitute/Table";
import { useGetUsersDetails } from "features/Admin/api/useGetUsersDetails";
import { useStickyScroll } from "hooks/useStickyScroll";
import {
  clearAdvisorFilterDataStore,
  clearFilterDataStore,
  clearUserTypeFilter,
  setScrollInfo,
  useFilterDataStore,
} from "store/useFilterDataStore";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";
import { useSetScroll } from "hooks/useSetScroll";
import useResetQueryOnLanguageSwitch from "hooks/useResetQueryOnLanguageSwitch";

export default function UserData() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { scroll } = useStickyScroll();
  const { scrollPosition } = useGetScrollPosition();
  const queryClient = useQueryClient();
  const pathName = window.location.pathname;
  const { selectedUserType } = useSidebarDataStore();
  const { scrollInfo, tableFilterInfo } = useFilterDataStore();
  const enableFiltering = pathName === tableFilterInfo?.pathName;

  const [requestParams, setRequestParams] = useState<UserDetailsParams>(
    selectedUserType
      ? {
          ...ADMIN_REQUEST_INIT_PARAMS,
          userType: selectedUserType,
          country: enableFiltering ? tableFilterInfo?.country : "",
          type: enableFiltering ? tableFilterInfo?.researchArea : "",
          fromDate: enableFiltering ? tableFilterInfo?.fromDate : null,
          toDate: enableFiltering ? tableFilterInfo?.toDate : null,
        }
      : {
          ...ADMIN_REQUEST_INIT_PARAMS,
          userType: USERS.All,
          country: enableFiltering ? tableFilterInfo?.country : "",
          fromDate: enableFiltering ? tableFilterInfo?.fromDate : null,
          toDate: enableFiltering ? tableFilterInfo?.toDate : null,
        }
  );

  const { data, isLoading, fetchNextPage, hasNextPage, refetch } =
    useGetUsersDetails(requestParams);

  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "users-details");
  useResetQueryOnLanguageSwitch("users-details");

  useEffect(() => {
    if (scrollInfo?.pathName && location.pathname !== scrollInfo?.pathName) {
      clearFilterDataStore();
    }
  }, [scrollInfo?.pathName]);

  const usersDetails = useMemo(() => {
    const tempData: UsersDetails = {
      users: [],
      totalCount: 0,
      totalPages: 0,
      summary: [],
      startUpCount: 0,
      otherCount: 0,
    };
    if (data?.pages && data?.pages[0]) {
      const index =
        scrollInfo?.index && data?.pages[scrollInfo?.index]?.users?.length > 0
          ? scrollInfo?.index
          : 0;

      tempData.summary = data.pages[index]?.summary;
      tempData.totalCount = data.pages[index]?.totalCount;
      tempData.totalPages = data.pages[index]?.totalPages;
      tempData.startUpCount = data.pages[index]?.startUpCount;
      tempData.otherCount = data.pages[index]?.otherCount;
      const users = data.pages
        .map((item) => {
          return item.users;
        })
        .flat();
      tempData.users = users;
    }
    return tempData;
  }, [data, scrollInfo?.index]);

  const handleCardClick = (type: number) => {
    setSelectedUserStatusType();
    setSelectedUserType();
    clearUserTypeFilter();
    clearFilterDataStore();
    clearAdvisorFilterDataStore();
    if (type === USERS.ResearchScholar) {
      navigate("research-scholar");
    } else if (type === USERS.Organization) {
      navigate("organization");
    } else if (type === USERS.Funding) {
      navigate("funding-organization");
    } else if (type === USERS.ResearchInstitute) {
      navigate("research-institute");
    }
  };

  const handleFilterClick = async (type: number) => {
    clearFilterDataStore();
    queryClient.resetQueries(["users-details"]);
    setSelectedUserType(type);
    if (type !== requestParams.userType) {
      setRequestParams({
        ...ADMIN_REQUEST_INIT_PARAMS,
        userType: type,
      });
    }
  };

  const getTitle = (userTypeId: number) => {
    switch (userTypeId) {
      case USERS.ResearchScholar:
        return "research_scholar.research_scholar_title";
      case USERS.Organization:
        return "global.organization";
      case USERS.Funding:
        return "funding_organization.funding_organization";
      case USERS.ResearchInstitute:
        return "global.institutes";
      default:
        return "";
    }
  };

  const handleNavigate = (userId: string, userType: string) => {
    if (userType === "Organization") {
      navigate(`organization/${userId}`);
    } else if (userType === "Research Scholar") {
      navigate(`research-scholar/${userId}`);
    } else if (userType === "Funding Organization") {
      navigate(`funding-organization/${userId}`);
    } else if (userType === "Research Institute") {
      navigate(`research-institute/${userId}`);
    }
  };

  const handleAllUsersNavigation = async (userId: string, userType: string, index: number) => {
    setScroll(index);
    await handleUserTableRowClick(
      userId,
      requestParams?.profileStatusId,
      () => handleNavigate(userId, userType),
      refetch,
      t("global.something_went_wrong")
    );
  };

  const handleNavigation = (userId: string) => {
    if (requestParams?.userType === USERS.Organization) {
      navigate(`organization/${userId}`);
    } else if (requestParams?.userType === USERS.ResearchScholar) {
      navigate(`research-scholar/${userId}`);
    } else if (requestParams?.userType === USERS.Funding) {
      navigate(`funding-organization/${userId}`);
    } else if (requestParams?.userType === USERS.ResearchInstitute) {
      navigate(`research-institute/${userId}`);
    }
  };

  const handleRowClick = async (userId: string, index: number) => {
    setScroll(index);
    await handleUserTableRowClick(
      userId,
      requestParams?.profileStatusId,
      () => handleNavigation(userId),
      refetch,
      t("global.something_went_wrong")
    );
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "admin.users",
    });
  }, []);

  const updateRequestParams = (data: FilterParams) => {
    setRequestParams({
      ...requestParams,
      ...data,
    });
    refetch();
  };

  const handleClearFilter = () => {
    setRequestParams(
      selectedUserType
        ? {
            ...ADMIN_REQUEST_INIT_PARAMS,
            userType: selectedUserType,
          }
        : { ...ADMIN_REQUEST_INIT_PARAMS, userType: USERS.All }
    );
  };

  const isClearFilterActive: boolean =
    JSON.stringify(requestParams) !==
    JSON.stringify({
      ...ADMIN_REQUEST_INIT_PARAMS,
      userType: selectedUserType ?? USERS.All,
    });

  const setScroll = (index: number) => {
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: location.pathname,
      index: pageParam,
    });
  };

  return (
    <>
      <div className=" w-full h-full flex flex-col">
        <Header
          title={"admin.users"}
          className={
            scroll
              ? " !bg-white animate__animated animate__fadeInDown shadow-sticky !py-[26px] !h-[90px] fixed stickyHeader"
              : "fixed"
          }
        />
        <div className="px-10 h-full flex flex-col pt-[118px] pb-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-[10px] text-white selectedCard relative">
            {usersDetails && usersDetails.summary?.length > 0 ? (
              usersDetails.summary.map((item) => (
                <StatisticsDataCard
                  key={`${item.userTypeId}`}
                  data={{
                    title: item.userTypeName,
                    value: item.userSummary.find((i) => {
                      if (i.id === USER_STATUS.Approved) {
                        return i.count;
                      }
                    })
                      ? item.userSummary.find((i) => {
                          if (i.id === USER_STATUS.Approved) {
                            return i.count;
                          }
                        })?.count || 0
                      : 0,
                    type: item.userTypeName,
                  }}
                  handleClick={() => handleCardClick(item.userTypeId)}
                  isActiveUsers
                />
              ))
            ) : (
              <InlineLoader />
            )}
          </div>
          <div className=" flex items-center  mb-5 mt-7">
            <Title
              className="font-semibold text-black mr-5 whitespace-nowrap"
              title={t("admin.pending_requests")}
            />
            <div className=" flex overflow-x-auto mr-5">
              <FilterButton
                key={`all-${Math.random()}`}
                title={t("global.all")}
                count={
                  usersDetails?.summary?.reduce(
                    (partialSum, item) =>
                      partialSum +
                      (item.userSummary?.find((i) => i.id === USER_STATUS.PendingApproval)?.count ??
                        0),
                    0
                  ) || 0
                }
                handleClick={() => handleFilterClick(USERS.All)}
                active={requestParams.userType === USERS.All}
              />
              {usersDetails?.summary?.map((item) => (
                <FilterButton
                  key={item.userTypeId}
                  title={t(getTitle(item.userTypeId))}
                  count={
                    item.userSummary?.find((i) => i.id === USER_STATUS.PendingApproval)?.count ?? 0
                  }
                  handleClick={() => handleFilterClick(item.userTypeId)}
                  active={requestParams.userType === item.userTypeId}
                />
              ))}
            </div>
            {isClearFilterActive ? (
              <SecondaryButton
                startIcon="clearFilter"
                className="!min-h-[38px] !h-[38px]  space-x-1.5 !bg-transparent text-primary-dark ms-auto"
                title={"global.clear_filters"}
                handleClick={handleClearFilter}
              />
            ) : null}
          </div>
          {usersDetails && !isLoading ? (
            <Title
              className=" text-primary-light text-sm leading-4 font-normal mb-3"
              title={`${usersDetails?.totalCount ? usersDetails?.totalCount : t("global.no")} ${t(
                "organization.results_found"
              )}`}
            />
          ) : null}

          {isLoading && !isClearFilterActive ? (
            <div
              className={` flex flex-col w-full h-auto xl:h-full items-center rounded-md justify-start bg-white mb-[45px] noData`}
            >
              <div className=" flex flex-col items-center justify-start my-[102px]">
                <InlineLoader />
              </div>
            </div>
          ) : usersDetails ? (
            <InfiniteScroll
              dataLength={usersDetails?.users?.length}
              next={fetchNextPage}
              hasMore={!!hasNextPage}
              loader={hasNextPage || isLoading ? <TableLoader /> : null}
              scrollThreshold="200px"
              className="!min-h-[75vh] "
            >
              <div className=" flex flex-col p-4 pt-[18px] w-full h-full items-center rounded-md justify-start bg-white">
                {requestParams?.userType === USERS.All ? (
                  <AllUsersTable
                    userData={usersDetails?.users ?? []}
                    headers={ALL_USERS_TABLE_HEADERS}
                    handleRowClick={(userId, userType, index) =>
                      handleAllUsersNavigation(userId, userType, index)
                    }
                    updateRequestParams={updateRequestParams}
                    requestParams={requestParams}
                  />
                ) : null}
                {requestParams?.userType === USERS.ResearchScholar ? (
                  <ResearchScholarTable
                    userData={usersDetails?.users ?? []}
                    headers={RESEARCH_SCHOLARS_TABLE_HEADERS}
                    handleRowClick={(userId, index) => handleRowClick(userId, index)}
                    updateRequestParams={updateRequestParams}
                    requestParams={requestParams}
                  />
                ) : null}
                {requestParams?.userType === USERS.Organization ? (
                  <OrganizationTable
                    userData={usersDetails?.users ?? []}
                    headers={ORGANIZATIONS_TABLE_HEADERS}
                    handleRowClick={(userId, index) => handleRowClick(userId, index)}
                    updateRequestParams={updateRequestParams}
                    requestParams={requestParams}
                  />
                ) : null}
                {requestParams?.userType === USERS.Funding ? (
                  <FundingOrganizationTable
                    userData={usersDetails?.users ?? []}
                    headers={ORGANIZATIONS_TABLE_HEADERS}
                    handleRowClick={(userId, index) => handleRowClick(userId, index)}
                    updateRequestParams={updateRequestParams}
                    requestParams={requestParams}
                  />
                ) : null}
                {requestParams?.userType === USERS.ResearchInstitute ? (
                  <ResearchInstituteTable
                    userData={usersDetails?.users ?? []}
                    headers={RESEARCH_INSTITUTE_TABLE_HEADERS}
                    handleRowClick={(userId, index) => handleRowClick(userId, index)}
                    updateRequestParams={updateRequestParams}
                    requestParams={requestParams}
                  />
                ) : null}
                <NoResults
                  isLoading={isLoading}
                  isFilterApplied={isClearFilterActive}
                  dataLength={usersDetails?.users?.length ?? 0}
                />
              </div>
            </InfiniteScroll>
          ) : (
            <NoData message="admin.no_data_to_show" />
          )}
        </div>
      </div>
    </>
  );
}
