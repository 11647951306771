import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

type DatePickerFieldProps = {
  labelText: string;
  isRequired?: boolean;
  selectedDate: string | null;
  setSelectedDate: (date: string) => void;
  errorMessage?: string | null;
  showFutureDate?: boolean;
  isDisabled?: boolean;
  minDate?: string | null;
  maxDate?: string | null;
};

export function DatePickerField({
  labelText,
  isRequired,
  selectedDate,
  setSelectedDate,
  errorMessage,
  showFutureDate,
  isDisabled,
  minDate,
  maxDate,
}: DatePickerFieldProps) {
  const maximumDate = maxDate ? new Date(maxDate) : showFutureDate ? null : new Date();
  return (
    <div className="datePicker">
      <label className="label p-0 pb-2">
        <span className="label-text text-primary-light text-sm font-normal leading-4 whitespace-nowrap">
          <span className=" ltr:float-left rtl:float-right">{labelText}</span>
          {isRequired ? (
            <span className=" ltr:float-left rtl:float-right mr-1 ml-1 text-red-200 float-left">
              *
            </span>
          ) : null}
        </span>
      </label>
      <DatePicker
        calendarClassName="datePicker"
        className={`customDatePicker w-full placeholder:text-gray-300 h-12 rounded font-medium placeholder:text-xs text-primary-text text-base outline-none focus:border-primary-medium px-3 ltr:pr-10 rtl:pl-10  border bg-white ${
          errorMessage ? "!border-red-100" : "border-primary-light"
        } `}
        selected={selectedDate ? dayjs(selectedDate).toDate() : null}
        onChange={(date: Date) => {
          const neWDate = date ? dayjs(date).format("YYYY-MM-DDT00:00:00.000") : "";
          setSelectedDate(neWDate);
        }}
        placeholderText="DD/MM/YYYY"
        dateFormat={"dd/MM/yyyy"}
        maxDate={maximumDate}
        minDate={minDate ? new Date(minDate) : new Date("01/01/1900")}
        disabled={isDisabled}
        portalId="datepicker-portal"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  );
}
