import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterTab, ToggleButton, PrimaryButton, Header, LoaderModal } from "components";
import { useStickyScroll } from "hooks/useStickyScroll";
import { USERS } from "utils/constants";
import { useGetRolesAndPermissions } from "features/Admin/api/useGetRolesAndPermissions";
import { useSaveRolesAndPermissions } from "features/Admin/api/useSaveRolesAndPermissions";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { setSelectedSideMenu } from "store/useSidebarStore";

export default function Permissions() {
  const { t, i18n } = useTranslation();
  const { scroll, scrollValue } = useStickyScroll(12);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [userType, setUserType] = useState<number>(2);
  const {
    data: roleData,
    isLoading: roleDataLoading,
    refetch,
  } = useGetRolesAndPermissions(userType);
  const { mutateAsync: saveRoles, isLoading: isSaveLoading } = useSaveRolesAndPermissions();

  const [roleList, setRoleList] = useState<Array<PermissionDetails>>(roleData?.features);

  const organizationDisabledOptions = {
    viewDraftChallenges: 14,
    viewOpenChallenges: 15,
    viewLiveChallenges: 16,
    viewClosedChallenges: 17,
  };

  const instituteDisabledOptions = {
    viewOpenChallenges: 45,
    viewLiveChallenges: 56,
    viewClosedChallenges: 46,
  };

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    if (index === 0) {
      setUserType(USERS.ResearchScholar);
    } else if (index === 1) {
      setUserType(USERS.Organization);
    } else if (index === 2) {
      setUserType(USERS.ResearchInstitute);
    } else if (index === 3) {
      setUserType(USERS.Funding);
    }
  };

  const userTypes = [
    {
      value: 0,
      label: "global.research_scholar",
    },
    {
      value: 1,
      label: "global.organization",
    },
    {
      value: 2,
      label: "global.institutes",
    },
    {
      value: 3,
      label: "funding_organization.funding_organization",
    },
  ];

  const handleToggleChange = (id: number) => {
    const updatedRoleList = roleList.map((item) => {
      if (item.featureId === id) {
        return { ...item, isEnabled: !item?.isEnabled };
      }
      return item;
    });
    setRoleList(updatedRoleList);
  };

  const prepareFormData = () => {
    const data = {
      roleId: roleData?.roleId,
      roleName: roleData?.roleName,
      features: roleList,
    };
    return data;
  };

  const handleUpdate = async () => {
    const formData = prepareFormData();

    try {
      const response = await saveRoles({ formData: formData, userType: userType });
      if (response) {
        showSuccessToastMessage({
          message: response?.message,
        });
      }
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const isOptionDisabled = (featureId: number) => {
    if (
      featureId === organizationDisabledOptions.viewClosedChallenges ||
      featureId === organizationDisabledOptions.viewDraftChallenges ||
      featureId === organizationDisabledOptions.viewOpenChallenges ||
      featureId === organizationDisabledOptions.viewLiveChallenges ||
      featureId === instituteDisabledOptions.viewClosedChallenges ||
      featureId === instituteDisabledOptions.viewOpenChallenges ||
      featureId === instituteDisabledOptions.viewLiveChallenges
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setRoleList(roleData?.features);
  }, [roleData]);

  useEffect(() => {
    refetch();
  }, [i18n.language, refetch]);

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.settings",
      isOpen: true,
      subMenuType: "admin.permissions",
    });
  }, []);

  return (
    <div className={`w-full h-full flex flex-col   ${scrollValue > 0 ? "window-scroll" : ""}`}>
      <Header
        title={"admin.roles_and_permissions"}
        className={
          scroll
            ? " bg-gradient-blueDark md:bg-white animate__animated animate__fadeInDown shadow-sticky !py-[26px] !h[57px] md:!h-[90px] fixed stickyHeader"
            : "fixed bg-gradient-blueDark lg:bg-[#E8F1F6]"
        }
      />
      <div className=" adminDetails w-full pt-[118px] pb-10 items-baseline px-4 lg:px-10 lg:ltr:pr-[30px] lg:rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto ">
        <div className="tabContainer w-full flex items-baseline bg-white rounded rounded-t-md ltr:pl-6 rtl:pr-6 pt-5">
          <FilterTab
            filterOptions={userTypes}
            activeTab={activeTab}
            handleClick={(value) => handleTabChange(value)}
          />
        </div>
        <div className="bg-white w-full">
          <div className="p-6 roles-list">
            {roleList?.map((item: PermissionDetails, index: number) => (
              <div key={index}>
                <ToggleButton
                  id={item?.featureId}
                  label={item?.featureName}
                  isChecked={item?.isEnabled}
                  handleChange={handleToggleChange}
                  disabled={isOptionDisabled(item?.featureId)}
                  activeTab={activeTab}
                />
              </div>
            ))}
          </div>

          <div className="pb-10 ltr:pl-6 rtl:pr-6">
            <PrimaryButton
              className="max-w-[176px] w-full"
              title={t("global.update")}
              handleClick={() => {
                handleUpdate();
              }}
            />
          </div>
          {roleDataLoading || isSaveLoading ? <LoaderModal /> : null}
        </div>
      </div>
    </div>
  );
}
