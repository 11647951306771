import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import SVGIcon from "assets/Icons";
import {
  CheckBox,
  InputField,
  LoaderModal,
  PrimaryButton,
  RadioButton,
  SecondaryButton,
  SingleSelect,
  SubTitle,
  Title,
} from "components";
import { useInviteResearchInstitute } from "features/Admin/api/useInviteResearchInstitute";
import { useGetOrganizationTypes } from "features/Admin/api/useGetOrganizationTypes";
import { researchInstituteSignUpValidation } from "utils/validation/researchInstituteSignUpValidation";
import { phoneMaxLimitChecker, setMasterDataValue } from "utils/helpers";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import {
  EMAIL_MAX_CHAR_LENGTH,
  ORGANIZATION_TYPES,
  RESEARCH_INSTITUTE_INIT_STATE,
  RESEARCH_INSTITUTE_STATUS,
} from "utils/constants";
import { useMasterDataStore } from "store/useMasterDataStore";

type IndividualInviteProps = {
  onClose: () => void;
};
export default function IndividualInvite({ onClose }: IndividualInviteProps) {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { countryCode } = useMasterDataStore();
  const { data: organizationTypes, isLoading: isOrganizationTypesLoading } =
    useGetOrganizationTypes();
  const { mutateAsync: inviteResearchInstitute, isLoading: isResearchInstituteLoading } =
    useInviteResearchInstitute();
  const [formState, setFormState] = useState<ResearchInstituteCreation>({
    ...RESEARCH_INSTITUTE_INIT_STATE,
    isGlobal: false,
  });
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const countryCodeOptions = useMemo(() => {
    return countryCode?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [countryCode, i18n.language]);

  const organizationTypeOptions = useMemo(() => {
    return organizationTypes
      ?.filter((item) => item?.id !== ORGANIZATION_TYPES.StartUp)
      .map((item) => {
        return setMasterDataValue(item, i18n.language);
      });
  }, [organizationTypes, i18n.language]);

  const handleSelectChange = (value: string | number, key: keyof ResearchInstituteCreation) => {
    setFormState((previousState) => ({ ...previousState, [key]: value as string | number }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof ResearchInstituteCreation
  ) => {
    const value = event?.target?.value;
    setFormState((previousState) => ({ ...previousState, [key]: value }));
  };

  const handleInvite = async () => {
    const error = researchInstituteSignUpValidation(formState);
    if (error) {
      setValidationErrors(error);
      return;
    }
    setValidationErrors(null);
    try {
      const formData = prepareFormData();
      const result = await inviteResearchInstitute(formData);
      queryClient.resetQueries(["users-details"]);
      showInfoToastMessage({
        iconType: ToasterIconType.MAIL,
        message: result?.message,
      });
      onClose();
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error.response.data.status >= 400
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };
  const prepareFormData = () => {
    const data = {
      instituteName: formState.instituteName,
      instituteTypeId: formState.instituteTypeId,
      email: formState.email,
      status: formState.status,
      countryCodeId: formState.countryCodeId,
      contactNumber: formState.contactNumber,
      isGlobal: formState.isGlobal,
    };
    return data;
  };

  const handleStatusTypeChange = (value: number, key: keyof ResearchInstituteCreation) => {
    setFormState((previousState) => ({ ...previousState, [key]: value }));
  };

  const handleCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isGlobal = event.target.checked;
    setFormState((previousState) => ({ ...previousState, isGlobal }));
  };

  return (
    <>
      <div className="fixed h-screen w-screen bg-black/[0.3] z-[99] flex justify-center items-center top-0 left-0 ">
        <div className="animate__animated animate__zoomIn flex flex-col rounded-md items-center bg-white max-w-[470px] mx-2 sm:w-[470px] py-[50px] px-8 max-h-[calc(100vh-3.125rem)] overflow-auto">
          <div>
            <SVGIcon name="individualInvite" />
          </div>
          <Title
            className="mt-10 text-black text-xl font-semibold text-center"
            title={t("admin.individual_invite")}
          />
          <SubTitle
            className="mt-[6px] text-primary-text font-normal text-base text-center"
            title={t("research_institute.research_institute_individual_invite_message")}
          />
          <div className="grid grid-cols-1 gap-x-4 gap-y-[22px] mt-[30px] w-full">
            <InputField
              hintText={t("research_institute.enter_institute_name")}
              labelText={t("research_scholar.institute_name")}
              handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(event, "instituteName")
              }
              fieldName="instituteName"
              isRequired
              maxCharLength={EMAIL_MAX_CHAR_LENGTH}
              errorMessage={validationErrors?.instituteName}
            />
            <SingleSelect
              options={organizationTypeOptions || []}
              labelText={t("research_institute.institute_type")}
              hintText={t("global.select")}
              handleChange={(value: string | number) =>
                handleSelectChange(value, "instituteTypeId")
              }
              isRequired
              errorMessage={validationErrors?.instituteTypeId}
            />
            <div>
              <span className="label-text text-primary-light text-sm font-normal leading-4 whitespace-nowrap">
                {t("research_institute.status")}
                <span className=" rtl:mr-1 ltr:ml-1 text-red-200">*</span>
              </span>
              <div className=" mt-1 flex items-center space-x-6 rtl:space-x-reverse ">
                <RadioButton
                  className="mr-10 p-2 text-black"
                  label={t("research_institute.start_up")}
                  value={RESEARCH_INSTITUTE_STATUS.startUp}
                  checked={formState.status === RESEARCH_INSTITUTE_STATUS.startUp}
                  onChange={() => handleStatusTypeChange(1, "status")}
                  errorMessage={validationErrors?.status}
                />
                <RadioButton
                  className="mr-10 p-2 text-black"
                  label={t("research_institute.others")}
                  value={RESEARCH_INSTITUTE_STATUS.others}
                  checked={formState.status === RESEARCH_INSTITUTE_STATUS.others}
                  onChange={() => handleStatusTypeChange(2, "status")}
                  errorMessage={validationErrors?.status}
                />
              </div>
            </div>
            <InputField
              hintText={t("admin.enter_email_addresses")}
              labelText={t("global.email_address")}
              handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(event, "email")
              }
              fieldName="email"
              isRequired
              maxCharLength={EMAIL_MAX_CHAR_LENGTH}
              errorMessage={validationErrors?.email}
            />
            <div>
              <SingleSelect
                className="text-sm font-normal"
                options={countryCodeOptions || []}
                labelText={t("global.country_code")}
                hintText={t("global.select")}
                handleChange={(value: string | number) =>
                  handleSelectChange(value, "countryCodeId")
                }
                isRequired
                errorMessage={validationErrors?.countryCodeId}
              />
            </div>
            <div>
              <InputField
                hintText={t("global.enter_contact_number")}
                labelText={t("global.contact_number")}
                handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(event, "contactNumber")
                }
                fieldName="contactNumber"
                isRequired
                errorMessage={validationErrors?.contactNumber}
                maxCharLength={phoneMaxLimitChecker(formState.contactNumber)}
              />
            </div>
            <div className=" -m-2 pt-1">
              <CheckBox
                option={{
                  label: t("admin.global_invite"),
                  value: "global_invite",
                }}
                className="p-2 !text-black customCheckboxV3"
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleCheckBoxClick(event);
                }}
                checked={formState.isGlobal}
              />
            </div>
          </div>
          <div className=" flex w-full justify-center space-x-[10px] rtl:space-x-reverse  mt-12 ">
            <SecondaryButton
              title={"global.close"}
              handleClick={onClose}
              className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
            />
            <PrimaryButton
              title={"global.send_invite"}
              handleClick={handleInvite}
              className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
            />
          </div>
        </div>
      </div>
      {isOrganizationTypesLoading || isResearchInstituteLoading ? <LoaderModal /> : null}
    </>
  );
}
