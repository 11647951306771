import { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import {
  FilterTab,
  Title,
  NoData,
  LoaderModal,
  InlineLoader,
  Header,
  EventInfoCard,
  SecondaryButton,
  MultiSelect,
  Chip,
} from "components";
import {
  ADMIN_EVENT_LIST_INIT_PARAMS,
  EVENT_STATUS,
  INITIAL_SEARCH_PARAMS,
  PAGE_LIMIT,
  USERS,
} from "utils/constants";
import { useStickyScroll } from "hooks/useStickyScroll";
import {
  useFilterDataStore,
  clearOrganizationFilterInfo,
  setScrollInfo,
  setEventFilterInfoItem,
} from "store/useFilterDataStore";
import { useSetScroll } from "hooks/useSetScroll";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";
import useResetQueryOnLanguageSwitch from "hooks/useResetQueryOnLanguageSwitch";
import { useGetEventList } from "features/Events/api/useGetEventList";
import { useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import { clearEventDataStore } from "store/useEventDataStore";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { setSelectedFormId } from "store/useSidebarStore";

export default function Events() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { scroll } = useStickyScroll();
  const { scrollInfo, eventFilterInfo, organizationFilterInfo } = useFilterDataStore();
  const { userInfo } = useUserDataStore();
  const pathName = window.location.pathname;

  const [requestParams, setRequestParams] = useState<EventListParams>({
    ...ADMIN_EVENT_LIST_INIT_PARAMS,
    status: eventFilterInfo?.activeTab
      ? eventFilterInfo?.activeTab
      : userInfo?.userType === USERS.Admin
      ? EVENT_STATUS?.All
      : EVENT_STATUS?.Live,
    targetIndustry: eventFilterInfo?.researchAreaId ?? [],
  });

  const [selectedOptions, setSelectedOptions] = useState<OptionItem[]>(
    eventFilterInfo?.selectedResearchAreas ?? []
  );

  const {
    data: eventList,
    isLoading: eventListLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetEventList(requestParams);
  const [isOpenSideMenu, setIsOpenSideMenu] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(
    eventFilterInfo?.activeTab
      ? eventFilterInfo?.activeTab
      : userInfo?.userType === USERS.Admin
      ? EVENT_STATUS?.All
      : EVENT_STATUS?.Live
  );

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const { scrollPosition } = useGetScrollPosition();
  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "event-list");
  useResetQueryOnLanguageSwitch("event-list");

  const handleTabChange = (type: number) => {
    setActiveTab(type);
    queryClient.resetQueries(["event-list"]);
    if (type === EVENT_STATUS.All && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: EVENT_STATUS.All }));
      setEventFilterInfoItem("activeTab", EVENT_STATUS.All);
    } else if (type === EVENT_STATUS.Draft && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: EVENT_STATUS.Draft }));
      setEventFilterInfoItem("activeTab", EVENT_STATUS.All);
    } else if (type === EVENT_STATUS.Upcoming && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: EVENT_STATUS.Upcoming }));
      setEventFilterInfoItem("activeTab", EVENT_STATUS.Upcoming);
    } else if (type === EVENT_STATUS.Live && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: EVENT_STATUS.Live }));
      setEventFilterInfoItem("activeTab", EVENT_STATUS.Live);
    } else if (type === EVENT_STATUS.Closed && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: EVENT_STATUS.Closed }));
      setEventFilterInfoItem("activeTab", EVENT_STATUS.Closed);
    }
  };

  const eventDetails = useMemo(() => {
    const tempData: EventListingResponse = { events: [], totalCount: 0, totalPages: 0 };
    if (eventList?.pages && eventList?.pages[0]) {
      tempData.totalCount = eventList.pages[0]?.totalCount;
      tempData.totalPages = eventList.pages[0]?.totalPages;
      const events = eventList.pages
        .map((item) => {
          return item.events;
        })
        .flat();
      tempData.events = events;
    }
    return tempData;
  }, [eventList]);

  const eventFilterItems: Array<RecordItem> = useMemo(() => {
    const tempItems = [];
    tempItems.push({
      value: EVENT_STATUS?.All,
      label: "global.all",
    });
    if (userInfo?.userType === USERS.Admin) {
      tempItems.push({
        value: EVENT_STATUS?.Draft,
        label: "organization.drafts",
      });
    }
    tempItems.push({
      value: EVENT_STATUS?.Live,
      label: "organization.live",
    });
    tempItems.push({
      value: EVENT_STATUS?.Upcoming,
      label: "global.upcoming",
    });
    tempItems.push({
      value: EVENT_STATUS?.Closed,
      label: "global.ended",
    });
    return tempItems;
  }, []);

  function handleEventClick(eventId: number, index: number) {
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setSelectedFormId(eventId);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: pathName,
      index: pageParam,
    });
  }

  function handleCreateEvent() {
    clearEventDataStore();
    setSelectedFormId(null);
    setScrollInfo({
      pathName: pathName,
      scrollPosition: 0,
      index: 0,
    });
    navigate("create");
  }

  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);
  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  const handleSelectedOptions = (options: OptionItem[] | null) => {
    if (options === null) {
      setSelectedOptions([]);
      setRequestParams((prevState) => ({ ...prevState, targetIndustry: [] }));
      setEventFilterInfoItem("researchAreaId", []);
      setEventFilterInfoItem("selectedResearchAreas", []);
    } else if (options.length <= 5) {
      const newOptions = options.filter(
        (option) => !selectedOptions.some((selectedOption) => selectedOption.id === option.id)
      );
      if (newOptions.length > 0) {
        setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, ...newOptions]);
        setEventFilterInfoItem("selectedResearchAreas", [...selectedOptions, ...newOptions]);
        const researchAreaIds = newOptions.map((option) => Number(option.id));
        setRequestParams((prevState) => ({
          ...prevState,
          targetIndustry: [...prevState.targetIndustry, ...researchAreaIds],
        }));
        setEventFilterInfoItem("researchAreaId", [
          ...requestParams.targetIndustry,
          ...researchAreaIds,
        ]);
      }
    }
  };

  const handleRemoveSelectedOption = (optionToRemove: OptionItem) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    setEventFilterInfoItem(
      "selectedResearchAreas",
      selectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    const remainingResearchAreas = selectedOptions.filter(
      (option) => option.id !== optionToRemove.id
    );
    const researchAreaIds = remainingResearchAreas.map((option) => Number(option.id));
    setRequestParams((prevState) => ({
      ...prevState,
      targetIndustry: researchAreaIds,
    }));
    setEventFilterInfoItem("researchAreaId", [...researchAreaIds]);
  };

  useEffect(() => {
    if (organizationFilterInfo?.pathName && pathName !== organizationFilterInfo?.pathName) {
      clearOrganizationFilterInfo();
    }
  }, [organizationFilterInfo?.pathName, pathName]);

  return (
    <div className="w-full h-full flex">
      <Header
        title={"global.events"}
        handleCollapseClick={onToggleClick}
        className={
          scroll
            ? " bg-primary-medium lg:bg-white  animate__animated animate__fadeInDown lg:shadow-sticky   lg:!h-[90px] fixed stickyHeader smaller-devices-section-challenge"
            : "fixed bg-primary-medium lg:bg-gray-light smaller-devices-section-challenge"
        }
      />
      <div className=" adminDetails w-full pt-[118px] pb-10 items-baseline px-4 lg:px-10 lg:ltr:pr-[30px] lg:rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto max-[1025px]:overflow-x-hidden ">
        <div className="tabContainer w-full   flex items-baseline tabMobileView tab-mobile-view">
          <FilterTab
            filterOptions={eventFilterItems}
            activeTab={activeTab}
            handleClick={(value) => handleTabChange(value)}
          />
        </div>

        <div className="tab-content w-full">
          <div className=" flex justify-between pb-5 items-center">
            <div className="flex w-full lg:w-auto flex-col lg:flex-row lg:space-x-[46px] space-x-0 rtl:space-x-reverse  pt-[22px]">
              <div className="min-w-full lg:min-w-[482px]">
                <div className=" relative">
                  <MultiSelect
                    labelText={t("research_scholar.challenge_search_areas")}
                    hintText={t("research_scholar.add_research_areas")}
                    setSelectedOptions={handleSelectedOptions}
                    selectedOptions={selectedOptions}
                    loadOptions={loadResearchAreaOptions ?? []}
                    isDisabled={selectedOptions.length > 4}
                  />
                </div>
              </div>
              {selectedOptions?.length > 0 ? (
                <div className="w-full mt-6 md:mt-0">
                  <Title
                    className=" text-primary-light text-sm leading-4 font-normal mb-2 md:mb-6"
                    title={t("global.selected_areas")}
                  />
                  <div className="flex flex-wrap mb-4 lg:mb-0">
                    {selectedOptions.map((option) => (
                      <Chip
                        key={option.id}
                        className="chipPrimary"
                        text={i18n.language === "en" ? option.name.en : option.name.ar}
                        handleClose={() => handleRemoveSelectedOption(option)}
                      />
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex gap-4">
              {userInfo?.userType === USERS.Admin ? (
                <SecondaryButton
                  title={"admin.create_new_event"}
                  handleClick={handleCreateEvent}
                  startIcon="addMilestone"
                  className="!bg-transparent space-x-1 rtl:space-x-reverse"
                />
              ) : null}
            </div>
          </div>
          <Title
            className=" text-primary-light text-sm leading-4 font-normal mb-5"
            title={`${
              eventList?.pages[0]?.totalCount ? eventList?.pages[0]?.totalCount : t("global.no")
            } ${t("organization.results_found")}`}
          />

          {eventDetails?.events?.length > 0 ? (
            <InfiniteScroll
              dataLength={eventDetails?.events?.length}
              next={fetchNextPage}
              hasMore={!!hasNextPage}
              loader={hasNextPage || eventListLoading ? <InlineLoader /> : null}
              scrollThreshold="200px"
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                {eventDetails.events.map((item: EventData, index: number) => (
                  <div key={index} className="">
                    <EventInfoCard
                      data={item}
                      handleEventClick={() => handleEventClick(item.eventId, index)}
                      path={
                        userInfo?.userType === USERS.Admin
                          ? `/admin/events/create/${item.eventId}`
                          : ""
                      }
                    />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          ) : null}
          {!eventListLoading && eventDetails?.events?.length === 0 ? (
            <div>
              <NoData message={"global.no_events_to_show"} />
            </div>
          ) : null}
        </div>

        {eventListLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
}
