import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import Brand from "assets/images/home-images/footerLogoWhite.svg";
import logoSidebar from "assets/images/logoSidebar.svg";
import BrandMini from "assets/images/logoMini.svg";
import { LoaderModal } from "components";
import { SubMenu } from "components/Sidebar/SubMenu";
import { MainMenu } from "components/Sidebar/MainMenu";
import { SidebarBottomNavigation } from "components/SidebarBottomNavigation";
import { useSidebarDataStore } from "store/useSidebarStore";
import { clearStores } from "store";
import { useLogout } from "features/Login/api/useAuth";
import { showConfirmationRequestModal } from "utils/confirmationModals";
import { ToasterIconType, showErrorToastMessage } from "utils/toasterMessage";
import { useNavigate } from "react-router-dom";
import { isMobileDevice } from "utils/helpers";
import { useUserDataStore } from "store/useUserDataStore";

type SideBarProps = {
  handleCollapseClick: () => void;
  menuItems: Array<SidebarMenuItem>;
  handleMenuClick: (type: string) => void;
  handleSubMenuClick: (type: string) => void;
};

export const Sidebar = ({
  menuItems,
  handleCollapseClick,
  handleMenuClick,
  handleSubMenuClick,
}: SideBarProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: logout, isLoading: isLogoutLoading } = useLogout();
  const { userInfo } = useUserDataStore();

  const { selectedSideMenu } = useSidebarDataStore();

  const handleLogout = async () => {
    const result = await showConfirmationRequestModal({
      title: "global.logout_confirmation_title",
      message: "global.logout_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.logout",
      buttonIcon: "logOut",
      modalIcon: "confirmLogout",
      isAlert: true,
    });
    if (!result) {
      return;
    }
    try {
      await logout();
      clearStores();
      navigate("/login");
    } catch (error: ApiError) {
      showErrorToastMessage({
        iconType: ToasterIconType.ALERT,
        message: t("global.something_went_wrong"),
      });
    }
  };

  const handleLanguageClick = async () => {
    const result = await showConfirmationRequestModal({
      title: "global.confirm_selection",
      message: "global.language_change_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.proceed",
      isAlert: true,
      modalIcon: "confirmSelection",
    });

    if (!result) {
      return;
    }

    const selectedLanguage = i18n.language === "en" ? "ar" : "en";
    i18n.changeLanguage(selectedLanguage);
  };

  const handleSidebarClose = () => {
    handleCollapseClick();
    document.getElementsByTagName("html")?.[0]?.classList.remove("mobile-sidebar-open");
  };

  return (
    <>
      <div className=" w-0 fixed h-screen top-0 ltr:left-0 rtl:right-0 py-[1.875rem] pb-[0] px-6 lg:ltr:pr-2 lg:rtl:pl-2 lg:w-[250px] min-w-[250px] lg:flex flex-col bg-primary-medium sidebar sidebar-mobile z-50">
        <button
          className=" text-white mt-1 w-10 h-10 flex ml-2 items-center justify-center mb-10 lg:hidden"
          onClick={handleSidebarClose}
        >
          <SVGIcon name="closeV2" />
        </button>
        <div className="logo w-full justify-center h-[60px]  mb-[68px] max-[1550px]:mb-10 hidden lg:flex lg:ltr:pr-4 lg:rtl:pl-4">
          <img
            alt="Company logo"
            src={userInfo?.isGlobal ? logoSidebar : Brand}
            className=" w-auto "
          />
          <img alt="Company logo" src={BrandMini} className=" w-auto max-w-max hidden" />
        </div>
        <button
          className=" w-8 h-8  absolute rounded-full font-medium text-base bg-white text-primary-medium  flex items-center cursor-pointer p-0 justify-center  min-h-[auto] !order-none !outline-none menuIcon ltr:-right-4 rtl:-left-4 top-[88px] shadow-menuIcon hover:bg-secondary-medium hover:text-white"
          onClick={handleCollapseClick}
        >
          <div className="collapse rtl:-rotate-180">
            <SVGIcon name="collapse" />
          </div>
          <div className="collapsed hidden ml-auto lg:mx-auto rtl:rotate-180">
            <SVGIcon name="collapsed" />
          </div>
        </button>
        <div className="max-[1550px]:space-y-7 space-y-8 menu overflow-auto  justify-start flex-nowrap pb-3 lg:ltr:pr-2 lg:rtl:pl-2 menuSidebar">
          {menuItems.map((item: SidebarMenuItem, index: number) => (
            <MainMenu
              key={item?.name + index}
              icon={item.icon}
              title={item.name}
              handleMenuClick={(type: string) => {
                if (isMobileDevice() && type !== "global.settings") {
                  handleSidebarClose();
                }
                handleMenuClick(type);
              }}
              isActive={selectedSideMenu?.type === item.name}
              type={item?.name}
              hasSubMenu={item?.subMenu ? true : false}
            >
              {selectedSideMenu.isOpen &&
                selectedSideMenu?.type === item.name &&
                item?.subMenu?.map((item, index) => (
                  <SubMenu
                    key={item?.name + index}
                    isActive={selectedSideMenu?.subMenuType === item?.name}
                    title={item?.name}
                    handleSubMenuClick={(type) => {
                      if (isMobileDevice()) {
                        handleSidebarClose();
                      }
                      handleSubMenuClick(type);
                    }}
                    type={item?.name}
                  />
                ))}
            </MainMenu>
          ))}

          <MainMenu
            icon={"language"}
            title={i18n.language === "en" ? "global.english" : "global.arabic"}
            handleMenuClick={(type: string) => handleMenuClick(type)}
            type="language"
            isActive={selectedSideMenu?.type === "language"}
            hasSubMenu={true}
          >
            {selectedSideMenu?.type === "language" && selectedSideMenu.isOpen ? (
              <SubMenu
                title={i18n.language === "en" ? "عربي" : "English"}
                handleSubMenuClick={handleLanguageClick}
                type="language"
              />
            ) : null}
          </MainMenu>
        </div>
        <SidebarBottomNavigation handleLogoutClick={handleLogout} />
      </div>
      {isLogoutLoading ? <LoaderModal /> : null}
    </>
  );
};
