import SVGIcon from "assets/Icons";
import { ProfilePicPreview } from "components";

type ProfilePictureProps = {
  url: string;
  handleClick?: () => void;
  disabled?: boolean;
  className?: string;
};
export function ProfilePicture({ url, handleClick, className, disabled }: ProfilePictureProps) {
  return (
    <div className={`relative w-[92px] h-[92px] ${className}`}>
      {url?.length === 0 ? (
        <div
          className={` bg-white border border-primary-medium border-dashed rounded-full flex items-center justify-center w-[92px] h-[92px]  text-primary-medium relative z-[1]  ${disabled ? " opacity-50 cursor-not-allowed" : "cursor-pointer"
            }`}
          onClick={
            disabled
              ? () => {
                //noop
              }
              : handleClick
          }
        >
          <SVGIcon name="addPhoto" />
        </div>
      ) : null}
      <div
        className={`absolute top-0 bg-white border border-primary-medium/10 rounded-full flex items-center justify-center w-full h-full  text-primary-medium ${disabled ? "cursor-not-allowed" : "cursor-pointer"
          }`}
        onClick={
          disabled
            ? () => {
              //noop
            }
            : handleClick
        }
      >
        {url ? <ProfilePicPreview className=" bg-black" url={url} /> : null}
      </div>
    </div>
  );
}
