import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, SubTitle, InputField, PrimaryButton, Header, LoaderModal } from "components";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { isRuleVerified } from "utils/helpers";
import { PASSWORD_MAX_CHAR_LENGTH, PASSWORD_RULES } from "utils/constants";
import { changePasswordSchemaValidation } from "utils/validation/changePasswordValidation";
import { useChangePassword } from "features/Login/api/useAuth";
import { setSelectedSideMenu } from "store/useSidebarStore";
import { useStickyScroll } from "hooks/useStickyScroll";

const ChangePassword = () => {
  const { t } = useTranslation();
  const { mutateAsync: changePassword, isLoading } = useChangePassword();
  const { scroll } = useStickyScroll();
  const changePasswordInitialState: ChangePasswordFormFields = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [changePasswordFormData, setChangePasswordFormData] = useState<ChangePasswordFormFields>(
    changePasswordInitialState
  );
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const handlePasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const value = event.target.value;
    setChangePasswordFormData((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const prepareFormData = () => {
    const data: ChangePasswordData = {
      currentPassword: changePasswordFormData.currentPassword.trim(),
      newPassword: changePasswordFormData.newPassword.trim(),
    };
    return data;
  };

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const error = changePasswordSchemaValidation(changePasswordFormData);
    if (error) {
      setValidationErrors(error);
      return;
    }
    setValidationErrors(null);
    try {
      const formData = prepareFormData();
      const changePasswordResponse = await changePassword(formData);
      showSuccessToastMessage({
        message: changePasswordResponse.message,
      });
      setChangePasswordFormData(changePasswordInitialState);
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.settings",
      isOpen: true,
      subMenuType: "global.password",
    });
  }, []);

  return (
    <>
      <div className="flex  min-h-screen w-full">
        <Header
          title={"global.password"}
          className={
            scroll
              ? " bg-primary-medium lg:bg-white  animate__animated animate__fadeInDown lg:shadow-sticky   lg:!h-[90px] fixed stickyHeader smaller-devices-section-challenge"
              : "fixed bg-primary-medium lg:bg-gray-light smaller-devices-section-challenge"
          }
        />
        <div className="adminDetails w-full pt-[118px] pb-[45px] items-baseline px-10 ltr:pr-[30px] rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto max-[1024px]:pt-[130px] max-[1024px]:!px-[15px] ">
          <div className=" bg-white w-full rounded-md flex h-full  max-[767px]:flex-col">
            <form className="space-y-6 p-[32px] w-[45%] max-[767px]:w-full">
              <InputField
                hintText={t("global.current_password")}
                labelText={t("global.current_password")}
                handleInputChange={handlePasswordInputChange}
                value={changePasswordFormData.currentPassword}
                fieldName="currentPassword"
                inputType="password"
                isRequired
                errorMessage={validationErrors?.currentPassword}
                maxCharLength={PASSWORD_MAX_CHAR_LENGTH}
              />
              <InputField
                hintText={t("global.new_password")}
                labelText={t("global.new_password")}
                handleInputChange={handlePasswordInputChange}
                value={changePasswordFormData.newPassword}
                fieldName="newPassword"
                inputType="password"
                isRequired
                errorMessage={validationErrors?.newPassword}
                maxCharLength={PASSWORD_MAX_CHAR_LENGTH}
              />
              <InputField
                hintText={t("global.confirm_password")}
                labelText={t("global.confirm_password")}
                handleInputChange={handlePasswordInputChange}
                value={changePasswordFormData.confirmPassword}
                fieldName="confirmPassword"
                inputType="password"
                isRequired
                errorMessage={validationErrors?.confirmPassword}
                maxCharLength={PASSWORD_MAX_CHAR_LENGTH}
              />

              <div className="!mt-6">
                <PrimaryButton
                  title={"global.change_password"}
                  handleClick={onSubmit}
                  className="default-primary-button w-full mt-0"
                />
              </div>
            </form>
            <form className="space-y-6 p-[32px] w-1/2 xl:w-1/2 max-[767px]:w-full max-[767px]:pt-0">
              <SubTitle
                title={t("global.your_password_must")}
                className="font-normal text-black !justify-start text-sm mb-2 yourPasswordMust"
              />
              <div className="passwordMust !mt-2">
                {PASSWORD_RULES.map((item, index) => (
                  <CheckBox
                    option={{
                      label: t(`global.passwordRule${index + 1}`),
                      value: item,
                    }}
                    key={item}
                    value={item}
                    className={` text-sm text-gray-100 font-normal password-must-icons ${
                      isRuleVerified(index + 1, changePasswordFormData.newPassword)
                        ? "password-success"
                        : "password-fail"
                    }`}
                  />
                ))}
              </div>
            </form>
          </div>
        </div>
      </div>
      {isLoading ? <LoaderModal /> : null}
    </>
  );
};
export default ChangePassword;
