import { useQuery } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getLatestCaseStudies(isGlobal: boolean) {
  const res = await apiClient.get(urls.latestCaseStudies(isGlobal));
  return res?.data?.data as Array<CaseStudy> | ApiError;
}

export function useGetLatestCaseStudies(isGlobal: boolean) {
  return useQuery(
    ["latest-case-studies"],
    async () => {
      return await getLatestCaseStudies(isGlobal);
    },
    {
      staleTime: Infinity,
    }
  );
}
