import React, { useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { SubTitle, Title, TextArea, PrimaryButton, LoaderModal, InputField } from "components";
import {
  EMAIL_MAX_CHAR_LENGTH,
  GENERAL_DATA_MAX_LENGTH,
  MESSAGE_MAX_LENGTH,
  PHONE_NUMBER_MAX_CHAR_LENGTH,
} from "utils/constants";
import { contactUsValidation } from "utils/validation/contactUsValidation";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { useSaveContactUs } from "features/Home/api/useSaveContactUs";

const ContactUs = forwardRef(function ContactUs(_, ref: React.Ref<HTMLDivElement>) {
  const { t } = useTranslation();
  const initialState = { name: "", organization: "", email: "", phoneNumber: "", message: "" };

  const { mutateAsync: saveContactUs, isLoading } = useSaveContactUs();

  const [state, setState] = useState<ContactUs>(initialState);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: keyof ContactUs) => {
    const value = event?.target?.value;
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event?.target?.value;
    setState((prevState) => ({ ...prevState, message: value }));
  };

  const prepareFormData = () => {
    const data = {
      name: state?.name?.trim(),
      organization: state?.organization?.trim(),
      email: state?.email?.trim(),
      phoneNumber: state?.phoneNumber?.trim(),
      message: state?.message?.trim(),
    };
    return data;
  };

  const handleSave = async () => {
    try {
      const error = contactUsValidation(state);
      if (error) {
        setValidationErrors(error);
        return;
      }
      setValidationErrors(null);
      const data = prepareFormData();
      const result = await saveContactUs(data);
      setState({ ...initialState });
      showSuccessToastMessage({
        message: result.message ?? t("global.something_went_wrong"),
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error.response.data.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  return (
    <>
      <div className=" w-full px-5 md:px-10 py-10 xl:py-[60px] bg-white " ref={ref}>
        {/* <div className=" w-full h-full bg-skin-bgLight px-5 md:px-10 py-10 xl:py-[60px] " ref={ref}> */}
        <div className="mx-auto w-full max-w-[1280px]">
          <SubTitle
            title={t("home.message")}
            className=" relative font-semibold text-skin-textSecondary01 uppercase !justify-start text-xs leading-4  md:text-xl md:leading-[26px] "
          />
          <Title
            title={t("home.contact_us")}
            className=" relative font-semibold text-skin-primaryDark text-[27px] leading-[34px] md:text-[43px] md:leading-[54px]"
          />
          <div className=" bg-transparent md:bg-white rounded-xl md:py-[20px] xl:py-[50px] md:px-[20px] xl:px-20 grid gap-[20px] xl:gap-[30px] grid-cols-1 md:grid-cols-2 contactUsHolder mt-10 md:mt-[30px]">
            <InputField
              value={state?.name}
              hintText={t("admin.name")}
              labelText={""}
              fieldName="name"
              handleInputChange={(event) => handleInputChange(event, "name")}
              className="placeholder:!text-[#81818D] border-skin-primary04"
              errorMessage={validationErrors?.name}
              maxCharLength={GENERAL_DATA_MAX_LENGTH}
            />
            <InputField
              value={state?.organization}
              hintText={t("home.organization_Optional")}
              labelText={""}
              fieldName="organization"
              handleInputChange={(event) => handleInputChange(event, "organization")}
              className="placeholder:!text-[#81818D] border-skin-primary04"
              errorMessage={validationErrors?.organization}
              maxCharLength={GENERAL_DATA_MAX_LENGTH}
            />
            <InputField
              value={state?.email}
              hintText={t("global.email_address")}
              labelText={""}
              fieldName="email"
              handleInputChange={(event) => handleInputChange(event, "email")}
              className="placeholder:!text-[#81818D] border-skin-primary04"
              errorMessage={validationErrors?.email}
              maxCharLength={EMAIL_MAX_CHAR_LENGTH}
            />
            <InputField
              value={state?.phoneNumber}
              hintText={t("home.phone_number")}
              labelText={""}
              fieldName="phoneNumber"
              handleInputChange={(event) => handleInputChange(event, "phoneNumber")}
              className="placeholder:!text-[#81818D] border-skin-primary04"
              errorMessage={validationErrors?.phoneNumber}
              maxCharLength={PHONE_NUMBER_MAX_CHAR_LENGTH}
            />
            <div className="col-span-1 md:col-span-2">
              <TextArea
                textContent={state?.message}
                handleChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  handleMessageChange(event)
                }
                labelText=""
                hintText={t("home.add_a_message")}
                fieldName="message"
                className="w-full placeholder:!text-[#81818D]  placeholder:!text-base h-12 font-medium placeholder:font-normal text-primary-text text-base outline-none focus:border-primary-medium p-3 ltr:pr-10 rt:pl-10 resize-none rounded  border border-skin-primary04 bg-white min-h-[224px]"
                maxCharLength={MESSAGE_MAX_LENGTH}
                errorMessage={validationErrors?.message}
              />
            </div>
            <PrimaryButton
              title={"home.send_message"}
              handleClick={handleSave}
              className="!h-12 w-[170px]"
            />
          </div>
        </div>
      </div>
      {isLoading ? <LoaderModal /> : null}
    </>
  );
});

export default ContactUs;
