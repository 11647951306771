import { TextButton } from "components";

type FilterTabProps = {
  filterOptions: Array<RecordItem>;
  handleClick: (filterValue: number) => void;
  activeTab: number;
  className?: string;

};

export function FilterTab({ filterOptions, activeTab, handleClick, className }: FilterTabProps) {
  return (
    <>
      {filterOptions?.map((item: RecordItem, index: number) => (
        <div key={index} className={`!border-none ${className}`}>
          <TextButton
            title={item?.label}
            handleClick={() => handleClick(item?.value)}
            className={`tab w-full ${activeTab === item?.value ? "active" : ""}`}
          />
        </div>
      ))}
    </>
  );
}
