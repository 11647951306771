import React from "react";
import { useTranslation } from "react-i18next";
import Tippy from "@tippyjs/react";
import SVGIcon from "assets/Icons";

type CheckBoxProps = {
  option: Option;
  value?: string;
  className?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  checked?: boolean;
  tooltipContent?: string;
};

export function CheckBox({
  option,
  value,
  className,
  handleChange,
  disabled,
  checked,
  tooltipContent,
}: CheckBoxProps) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <input
        id={value}
        type="checkbox"
        className="checkbox"
        onChange={handleChange}
        disabled={disabled}
        checked={checked}
      />
      <label>{option.label}</label>
      {tooltipContent ? (
        <Tippy
          placement="bottom"
          arrow={true}
          theme="light"
          appendTo="parent"
          allowHTML={true}
          content={
            <span className=" font-medium text-xs max-w-[250px] block">{t(tooltipContent)}</span>
          }
        >
          <div className="info !inline-block -mb-1.5 ltr:pl-1.5 rtl:pr-1.5">
            <SVGIcon name="info" />
          </div>
        </Tippy>
      ) : null}
    </div>
  );
}
