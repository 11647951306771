import { useInfiniteQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { PAGE_LIMIT } from "utils/constants";
import { urls } from "utils/urls";

export async function getUsersOnCollaboration({
  pageNumber,
  pageLimit,
  fromDate,
  toDate,
  collaborationId,
  researchInstitute,
  statusId,
  userId,
  isRequest,
}: CollaborationParticipationParams) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(
    urls.collaborationParticipationRequests({
      collaborationId,
      statusId,
      pageNumber,
      pageLimit,
      fromDate,
      researchInstitute,
      toDate,
      userId,
      isRequest,
    }),
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return res?.data?.data as ChallengeUsersResponse | ApiError;
}

export function useGetUsersOnCollaboration({
  fromDate,
  toDate,
  collaborationId,
  researchInstitute,
  statusId,
  userId,
  isRequest,
}: CollaborationParticipationParams) {
  return useInfiniteQuery(
    ["collaboration-users", collaborationId, statusId],
    async ({ pageParam = 1 }) => {
      const result = await getUsersOnCollaboration({
        pageNumber: pageParam,
        pageLimit: PAGE_LIMIT,
        fromDate: fromDate ?? "",
        toDate: toDate ?? "",
        collaborationId,
        researchInstitute,
        statusId,
        userId,
        isRequest,
      });
      return result;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.totalPages > allPages.length ? allPages?.length + 1 : undefined;
      },
      staleTime: 10 * 1000,
    }
  );
}
