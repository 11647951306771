import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import SVGIcon from "assets/Icons";
import { FILTER_INIT_STATE, INITIAL_SEARCH_PARAMS } from "utils/constants";
import { displayDateFormat, getColumns } from "utils/helpers";
import { FilterCalender, TableSearchFilter } from "components";
import { useMasterDataStore } from "store/useMasterDataStore";
import { useGetResearchAreas } from "features/Admin/api/useGetResearchAreas";
import { setTableFilterInfo } from "store/useFilterDataStore";

type TableProps = {
  userData: Array<ResearchScholarsUsersData>;
  headers: Array<string>;
  handleRowClick: (userId: string, index: number) => void;
  updateRequestParams: (data: FilterParams) => void;
  requestParams: UserDetailsParams;
};

const Table = ({
  userData,
  headers,
  handleRowClick,
  updateRequestParams,
  requestParams,
}: TableProps) => {
  const { t, i18n } = useTranslation();
  const { masterData } = useMasterDataStore();
  const pathName = window.location.pathname;

  const initialState = useMemo(() => {
    return {
      country: requestParams.country ? requestParams.country.split(",").map(Number) : [],
      type: requestParams.type ? requestParams.type.split(",").map(Number) : [],
      fromDate: requestParams.fromDate ? requestParams.fromDate : null,
      toDate: requestParams.toDate ? requestParams.toDate : null,
    };
  }, [requestParams]);
  const [filterParams, setFilterParams] = useState<AdminFilterParams>(initialState);

  useEffect(() => {
    setFilterParams(initialState);
  }, [requestParams, initialState]);

  const [researchAreasRequestParams, setResearchAreasRequestParams] =
    useState(INITIAL_SEARCH_PARAMS);
  const [activeFilter, setActiveFilter] = useState(FILTER_INIT_STATE);

  const {
    data,
    isLoading: isResearchAreasLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetResearchAreas(researchAreasRequestParams);

  const setFilterParamsDateValue = (data: FilterParams) => {
    setFilterParams((prevState) => ({
      ...prevState,
      fromDate: data.fromDate ?? null,
      toDate: data.toDate ?? null,
    }));
  };

  const countryOptions = useMemo(() => {
    return masterData?.country;
  }, [masterData]);

  const researchAreaDetails = useMemo(() => {
    const tempData: ResearchAreaDetails = { researchAreas: [], totalCount: 0, totalPages: 0 };
    if (data?.pages && data?.pages[0]) {
      tempData.totalCount = data.pages[0]?.totalCount;
      tempData.totalPages = data.pages[0]?.totalPages;
      const researchAreas = data.pages
        .map((item) => {
          return item.responseData;
        })
        .flat();
      tempData.researchAreas = researchAreas;
    }
    return tempData;
  }, [data]);

  const handleSubmit = (item: string) => {
    if (item === "admin.created_on") {
      updateRequestParams({
        fromDate: filterParams.fromDate,
        toDate: filterParams.toDate,
      });
    } else if (item === "global.country") {
      updateRequestParams({
        country: filterParams.country.toString(),
      });
    } else if (item === "admin.research_areas") {
      updateRequestParams({
        type: filterParams.type.toString(),
      });
    }
    setTableFilterInfo({
      researchArea: filterParams.type.toString(),
      pathName: pathName,
      country: filterParams.country.toString(),
      fromDate: filterParams.fromDate,
      toDate: filterParams.toDate,
    });
  };

  const handleResearchAreaClick = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (filterParams.type.length === 5 && event.target.checked === true) {
      event.target.checked = false;
      return;
    }
    if (event.target.checked) {
      setFilterParams((prevState) => ({
        ...prevState,
        type: [...prevState.type, id],
      }));
    } else {
      setFilterParams((prevState) => ({
        ...prevState,
        type: prevState.type.filter((item) => item !== id),
      }));
    }
  };

  const handleCountryClick = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    if (filterParams.country.length === 5 && event.target.checked === true) {
      event.target.checked = false;
      return;
    }
    if (event.target.checked) {
      setFilterParams((prevState) => ({
        ...prevState,
        country: [...prevState.country, id],
      }));
    } else {
      setFilterParams((prevState) => ({
        ...prevState,
        country: prevState.country.filter((item) => item !== id),
      }));
    }
  };

  const handleResearchAreaSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResearchAreasRequestParams((prevState) => ({
      ...prevState,
      searchKeyword: event.target.value,
    }));
  };

  const [countrySearchResult, setCountrySearchResult] = useState<
    Array<MasterDataItem> | undefined
  >();
  const handleCountrySearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchKey = event.target.value;

    if (searchKey) {
      const filteredCountries = countryOptions?.filter((country) => {
        if (i18n.language === "en") {
          return country?.name?.en.toLowerCase().trim().includes(searchKey.toLowerCase().trim());
        } else {
          return country?.name?.ar.trim().includes(searchKey.trim());
        }
      });
      setCountrySearchResult(filteredCountries);
    } else {
      setCountrySearchResult(undefined);
    }
  };

  const filteredData = userData?.map((item) => {
    return {
      createdOn: item.createdOn,
      fullName: item.fullName,
      researchAreas: item.researchAreas,
      country: item.country,
    };
  });

  const dataTable = useReactTable({
    data: userData,
    columns: filteredData && filteredData.length ? getColumns(filteredData) : [],
    getCoreRowModel: getCoreRowModel(),
  });

  const isCountrySelected = (id: number) => {
    return filterParams.country.some((countryId) => countryId === id);
  };

  const isResearchAreaSelected = (id: number) => {
    return filterParams.type.some((typeId) => typeId === id);
  };

  const handleFilterSelection = (filterOption: { [key: string]: boolean }) => {
    setFilterParams(initialState);
    setActiveFilter(() => ({
      ...FILTER_INIT_STATE,
      ...filterOption,
    }));
  };

  return (
    <div className="customTable ">
      <table>
        <thead>
          <tr>
            {headers?.map((item, index) => {
              if (item === "admin.created_on") {
                return (
                  <th key={index}>
                    <FilterCalender
                      item={item}
                      handleSubmit={handleSubmit}
                      filterParams={filterParams}
                      setFilterParamsValue={setFilterParamsDateValue}
                      isFilterActive={requestParams?.fromDate ? true : false}
                      isOpen={activeFilter.createdOn}
                      setIsOpen={(status: boolean) => handleFilterSelection({ createdOn: status })}
                    />
                  </th>
                );
              } else if (item === "admin.research_areas") {
                return (
                  <th key={index}>
                    <div className="dropdown">
                      <InfiniteScroll
                        dataLength={researchAreaDetails?.researchAreas?.length}
                        next={fetchNextPage}
                        hasMore={!!hasNextPage}
                        loader={false}
                        scrollThreshold="100px"
                        scrollableTarget="research-areas-dropdown"
                      >
                        <TableSearchFilter
                          item={item}
                          options={researchAreaDetails?.researchAreas || []}
                          handleCheckBoxClick={handleResearchAreaClick}
                          handleSubmit={() => {
                            handleSubmit(item);
                          }}
                          isLoading={isResearchAreasLoading}
                          handleSearchInput={handleResearchAreaSearchInput}
                          isSelected={isResearchAreaSelected}
                          selectedValues={filterParams.type}
                          isFilterActive={
                            requestParams?.type && requestParams?.type?.length > 0 ? true : false
                          }
                          isOpen={activeFilter.type}
                          setIsOpen={(status: boolean) => handleFilterSelection({ type: status })}
                        />
                      </InfiniteScroll>
                    </div>
                  </th>
                );
              } else if (item === "global.country") {
                return (
                  <th key={index}>
                    <div className="dropdown">
                      <TableSearchFilter
                        item={item}
                        options={countrySearchResult ? countrySearchResult : countryOptions || []}
                        handleCheckBoxClick={handleCountryClick}
                        handleSubmit={handleSubmit}
                        handleSearchInput={handleCountrySearch}
                        isSelected={isCountrySelected}
                        selectedValues={filterParams.country}
                        isFilterActive={
                          requestParams?.country && requestParams?.country?.length > 0
                            ? true
                            : false
                        }
                        isOpen={activeFilter.country}
                        setIsOpen={(status: boolean) => handleFilterSelection({ country: status })}
                      />
                    </div>
                  </th>
                );
              } else {
                return <th key={index}>{t(item)}</th>;
              }
            })}
            <th>{t("admin.view")}</th>
          </tr>
        </thead>
        <tbody>
          {dataTable.getRowModel().rows.map((row, index) => (
            <tr
              className="cursor-pointer"
              key={row.id}
              onClick={() => handleRowClick(row.original?.userId, index)}
            >
              {row.getVisibleCells().map((cell, index) => {
                if (cell.column.columnDef.header?.toString().trim() == "createdOn") {
                  return <td key={index}>{displayDateFormat(cell.getValue() as string)}</td>;
                } else if (cell.column.columnDef.header?.toString().trim() === "fullName") {
                  return (
                    <td key={index}>
                      <p className="text-primary-medium underline truncate">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </p>
                    </td>
                  );
                } else if (cell.column.columnDef.header?.toString().trim() == "researchAreas") {
                  if (cell.getValue()) {
                    return (
                      <td key={index}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  }
                  return <td key={index}>-</td>;
                } else {
                  return (
                    <td key={index}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  );
                }
              })}
              <td>
                <div className="btn btn-link h-auto min-h-[auto] p-0 text-primary-medium">
                  <SVGIcon name={"eyeCloseV1"} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
