import { Route, Routes } from "react-router-dom";
import { PageNotFound } from "components";
import AppOutlet from "components/AppOutlet";

import { PrivateRoute } from "router/PrivateRoute";
import ChangePassword from "features/ChangePassword";
import SearchList from "features/GlobalSearch/SearchList";

/* Research Scholar */
import ResearchScholarProfile from "features/ResearchScholar/Profile";

/* Admin */
import Admin from "features/Admin";
import AdminDashboard from "features/Admin/DashBoard";
import UserData from "features/Admin/Users/UsersData";
import ResearchScholarsDetails from "features/Admin/Users/ResearchScholars/ResearchScholarsDetails";
import ResearchScholarInfo from "features/Admin/Users/ResearchScholars/ResearchScholarInfo";
import OrganizationsDetails from "features/Admin/Users/Organizations/OrganizationsDetails";
import OrganizationInfo from "features/Admin/Users/Organizations/OrganizationInfo";
import FundingOrganizationDetails from "features/Admin/Users/FundingOrganizations/FundingOrganizationDetails";
import FundingOrganizationInfo from "features/Admin/Users/FundingOrganizations/FundingOrganizationInfo";
import AdminSettings from "features/Admin/Settings";
import AdminPermissions from "features/Admin/Permissions";

/*Organization */
import OrganizationProfile from "features/Organization/Profile";

/*FundingOrganization*/
import FundingOrganizationProfile from "features/FundingOrganization/Profile";

/* Research Institute */
import ResearchInstituteProfile from "features/ResearchInstitute/Profile";
import ResearchInstituteDetails from "features/Admin/Users/ResearchInstitute/ResearchInstituteDetails";
import ResearchInstituteInfo from "features/Admin/Users/ResearchInstitute/ResearchInstituteInfo";

/* Challenge */
import ChallengeDashboard from "features/Challenge/Dashboard";
import ChallengeDetailsPreview from "features/Challenge/Preview";

/* Events */
import EventDashboard from "features/Events/Dashboard";
import CreateEvent from "features/Events/CreateEvent";

/*Collaborations*/
import CollaborationListing from "features/Collaboration/CollaborationListing";
import CollaborationDetailsPreview from "features/Collaboration/CollaborationDetailsPreview";

import DiscussionForum from "features/DiscussionForum";
import Messages from "features/Messages";
import CollaborationDiscussionForum from "features/Collaboration/DiscussionForum";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <Admin />
          </PrivateRoute>
        }
      >
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<AdminDashboard />} index />
          <Route
            path=":challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ChallengeDetailsPreview />} index />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>
        </Route>
        <Route
          path="challenges"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<ChallengeDashboard />} index />
          <Route
            path=":challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ChallengeDetailsPreview />} index />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>
        </Route>
        <Route path="forums" element={<Messages />} />
        <Route
          path="users"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<UserData />} index />
          <Route
            path="research-scholar"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ResearchScholarsDetails />} index />
            <Route
              path=":researchScholarId"
              element={
                <PrivateRoute>
                  <AppOutlet />
                </PrivateRoute>
              }
            >
              <Route element={<ResearchScholarInfo />} index />
              <Route path="profile" element={<ResearchScholarProfile />} />
            </Route>
          </Route>
          <Route
            path="organization"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<OrganizationsDetails />} index />
            <Route
              path=":organizationId"
              element={
                <PrivateRoute>
                  <AppOutlet />
                </PrivateRoute>
              }
            >
              <Route element={<OrganizationInfo />} index />
              <Route path="profile" element={<OrganizationProfile />} />
            </Route>
          </Route>
          <Route
            path="funding-organization"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<FundingOrganizationDetails />} index />
            <Route
              path=":fundingOrganizationId"
              element={
                <PrivateRoute>
                  <AppOutlet />
                </PrivateRoute>
              }
            >
              <Route element={<FundingOrganizationInfo />} index />
              <Route path="profile" element={<FundingOrganizationProfile />} />
            </Route>
          </Route>
          <Route
            path="research-institute"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ResearchInstituteDetails />} index />
            <Route
              path=":researchInstituteId"
              element={
                <PrivateRoute>
                  <AppOutlet />
                </PrivateRoute>
              }
            >
              <Route element={<ResearchInstituteInfo />} index />
              <Route path="profile" element={<ResearchInstituteProfile />} />
            </Route>
          </Route>
        </Route>
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="permissions" element={<AdminPermissions />} />
        <Route path="settings" element={<AdminSettings />} />
        <Route
          path="search-results"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<SearchList />} index />
          <Route
            path="challenges/:challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ChallengeDetailsPreview />} index />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>

          <Route
            path="research-scholar/:researchScholarId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ResearchScholarInfo />} index />
            <Route path="profile" element={<ResearchScholarProfile />} />
          </Route>
          <Route
            path="organization/:organizationId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<OrganizationInfo />} index />
            <Route path="profile" element={<OrganizationProfile />} />
          </Route>
          <Route
            path="funding-organization/:fundingOrganizationId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<FundingOrganizationInfo />} index />
            <Route path="profile" element={<FundingOrganizationProfile />} />
          </Route>
          <Route
            path="research-institute/:researchInstituteId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ResearchInstituteInfo />} index />
            <Route path="profile" element={<ResearchInstituteProfile />} />
          </Route>
        </Route>
        <Route
          path="events"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route index element={<EventDashboard />} />

          <Route
            path="create"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route index element={<CreateEvent />} />
            <Route path=":eventId" element={<CreateEvent />} />
          </Route>
        </Route>
        <Route
          path="collaborations"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route index element={<CollaborationListing />} />

          <Route
            path=":collaborationId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route index element={<CollaborationDetailsPreview />} />
            <Route path="discussion-forum" element={<CollaborationDiscussionForum />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
