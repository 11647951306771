import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-daisyui";
import SVGIcon from "assets/Icons";
import {
  SubTitle,
  Title,
  SecondaryButton,
  ErrorText,
  ProfilePicPreview,
  PrimaryButton,
  FileUpload,
  LoaderModal,
} from "components";
import { ACCEPTED_IMAGE_TYPES } from "utils/constants";
import { useProfilePicUpload } from "features/ResearchScholar/api/useProfilePicUpload";
import { ToasterIconType, showErrorToastMessage } from "utils/toasterMessage";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import { getCroppedImg } from "utils/helpers";

type UploadProfilePicProps = {
  onUpload: () => void;
  onClose: () => void;
  setProfileUrl: (url: string) => void;
  url: string;
  handleRemove: () => void;
};

export function UploadProfilePic({
  onUpload,
  onClose,
  setProfileUrl,
  url,
  handleRemove,
}: UploadProfilePicProps) {
  const { t } = useTranslation();
  const { mutateAsync: profilePicUpload, isLoading } = useProfilePicUpload();
  const [imageURL, setImageURL] = useState(url);
  const [selectedFile, setSelectedFile] = useState<{ url: string; fileName: string }>();
  const [error, setError] = useState("");

  const onProfileRemove = () => {
    if (!imageURL && !selectedFile?.url) {
      setError("global.no_photo_uploaded");
      return;
    }
    handleRemove();
    setImageURL("");
    setSelectedFile({ url: "", fileName: "" });
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileSelected = event.target?.files?.[0];
    if (fileSelected?.type && ACCEPTED_IMAGE_TYPES.includes(fileSelected?.type)) {
      setSelectedFile({ url: URL.createObjectURL(fileSelected), fileName: fileSelected?.name });
      setError("");
    } else {
      showErrorToastMessage({
        iconType: ToasterIconType.ALERT,
        message: t("global.profile_image_type_error"),
      });
    }
    event.target.value = "";
  };

  const saveSelectedFile = async () => {
    if (!selectedFile?.url) {
      setError("global.please_upload_a_photo_to_continue");
      return;
    }
    try {
      const croppedImage = await getCroppedImg(
        selectedFile?.url,
        croppedAreaPixels,
        selectedFile?.fileName
      );
      const result = await profilePicUpload(croppedImage);
      if (result?.fileUrl) setProfileUrl(result?.fileUrl);
    } catch (error) {
      showErrorToastMessage({
        iconType: ToasterIconType.ALERT,
        message: t("global.something_went_wrong"),
      });
    } finally {
      onUpload();
    }
  };

  // Crop
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const onCropComplete = useCallback((_: any, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <div className="max-w-[600px] mx-0 lg:mx-2  sm:min-w-[470px]  bg-white rounded-none rounded-b-none lg:rounded-b-none  flex flex-col items-center justify-center relative text-center animate__animated animate__zoomIn">
      <div className="flex items-center justify-between p-6 w-full border-b border-gray-light">
        <div className="flex items-center">
          <SVGIcon name="profileUploadLogo" />
          <Title
            title={t("global.profile_photo")}
            className="text-black text-base font-semibold leading-normal ltr:ml-3 rtl:mr-3 pt-[3px]"
          />
        </div>
        <Button
          className="absolute btn btn-link text-black p-0 w-auto h-auto min-h-fit top-[26px] ltr:right-6 rtl:left-6"
          onClick={onClose}
        >
          <SVGIcon name="close" />
        </Button>
      </div>
      <div className="p-6 w-full">
        <SubTitle
          title={t("global.profile_pic_rule_1")}
          className="ltr:text-left rtl:text-right !justify-start text-sm removeIcon  text-black font-normal leading-normal"
        />
        <SubTitle
          title={t("global.profile_pic_rule_2")}
          className="ltr:text-left rtl:text-right !justify-start text-sm removeIcon  text-black font-normal leading-normal"
        />

        <div className=" relative w-full mt-6 rounded h-[300px] overflow-hidden">
          {imageURL && !selectedFile?.url ? (
            <div className="w-full h-full bg-gray-extraLight rounded relative flex flex-col items-center justify-center">
              <div className="addProfilePhoto  top-0  z-10 relative bg-white  rounded-full  w-[92px] h-[92px]   overflow-hidden">
                <FileUpload
                  multiple={false}
                  acceptedTypes={ACCEPTED_IMAGE_TYPES}
                  handleFileUpload={handleFileUpload}
                  className="opacity-0 no-image-upload-section top-[-2px] bottom-0 left-0 right-0 !w-[97px] !h-[97px] absolute"
                  icon={<SVGIcon name={"addPhoto"} />}
                />
                <ProfilePicPreview url={imageURL} className="w-full h-full" />
              </div>
              <div className="addProfilePhoto  top-0  z-10 relative mt-6">
                <FileUpload
                  multiple={false}
                  acceptedTypes={ACCEPTED_IMAGE_TYPES}
                  handleFileUpload={handleFileUpload}
                  className="opacity-0 no-image-upload-section w-full h-full absolute "
                  icon={<SVGIcon name={"addPhoto"} />}
                />
                <SubTitle
                  title={t("global.change_image")}
                  className=" text-sm leading-normal font-normal text-primary-medium cursor-pointer p-1"
                />
              </div>
            </div>
          ) : null}
          {!selectedFile?.url && !imageURL ? (
            <div className="w-full h-full bg-gray-extraLight rounded relative flex flex-col items-center justify-center">
              <div className="addProfilePhoto  top-0  z-10 relative bg-white border border-primary-medium border-dashed rounded-full flex items-center justify-center w-[92px] h-[92px]  text-primary-medium overflow-hidden">
                <FileUpload
                  multiple={false}
                  acceptedTypes={ACCEPTED_IMAGE_TYPES}
                  handleFileUpload={handleFileUpload}
                  className="opacity-0 absolute !w-[96px] !h-[96px] no-image-upload-section"
                  icon={<SVGIcon name={"addPhoto"} />}
                />
                <SVGIcon name="addPhoto" />
              </div>
              <div className="addProfilePhoto  top-0  z-10 relative mt-6">
                <FileUpload
                  multiple={false}
                  acceptedTypes={ACCEPTED_IMAGE_TYPES}
                  handleFileUpload={handleFileUpload}
                  className="opacity-0 absolute w-full h-full no-image-upload-section"
                  icon={<SVGIcon name={"addPhoto"} />}
                />
                <SubTitle
                  title={t("global.select_image")}
                  className=" text-sm leading-normal font-normal text-primary-medium cursor-pointer p-1"
                />
              </div>
            </div>
          ) : null}
          {selectedFile?.url ? (
            <div className="mx-0 max-w-full h-full w-full overflow-hidden relative">
              <Cropper
                image={selectedFile?.url}
                crop={crop}
                zoom={zoom}
                aspect={1}
                showGrid={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          ) : null}
        </div>
        <ErrorText errorMessage={error} />
        <div className="flex w-full justify-between space-x-[10px] rtl:space-x-reverse mt-6 buttons-container">
          <SecondaryButton
            className=" flex-1 sm:flex-[0] sm:min-w-[144px] min-w-[144px] flex items-center flex-nowrap !rounded-md border-red-dark !text-red-dark !text-sm leading-normal !font-medium whitespace-nowrap !px-3 gap-1 hover:!bg-transparent delete-image-button"
            title={"global.delete_image"}
            handleClick={onProfileRemove}
            startIcon="trashV1"
          />
          <PrimaryButton
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
            title={"global.save"}
            handleClick={saveSelectedFile}
          />
        </div>
      </div>
      {isLoading ? <LoaderModal /> : null}
    </div>
  );
}
