import { defaultPrimaryContact } from "store/useChallengeStore";
import {
  CREATE_CHALLENGE_COMPLETION_STATUS,
  DEFAULT_EDITOR_EMPTY_STATE,
  DEFAULT_CHALLENGE_LEARN_MORE_FORMAT,
} from "utils/constants";
import { extractFileNameFromUrl } from "utils/helpers";

export const prepareFormData = (challengeDetails: ChallengeInfo) => {
  const challengeFormData: ChallengeSave = {
    id: challengeDetails.id,
    coverImage: challengeDetails.coverImage,
    title: challengeDetails.title,
    location: challengeDetails.location,
    startDate: challengeDetails.startDate,
    endDate: challengeDetails.endDate,
    summary: challengeDetails.summary,
    targetIndustry: challengeDetails.targetIndustry,
    isPrivate: challengeDetails.isPrivate,
    challengeStatusId: 0,
    challengeCompletionStatusId: 0,
    isGlobal: challengeDetails.isGlobal,
  };
  return challengeFormData;
};
export const prepareMileStoneFormData = (
  challengeMilestones: ChallengeMileStone[]
): ChallengeMilestoneSave[] => {
  const challengeFormData = challengeMilestones.map((item) => ({
    id: item.id,
    startDate: item.startDate,
    endDate: item.endDate,
    milestone: item.milestone,
    description: item.description,
  }));
  return challengeFormData;
};
export const prepareLearnMoreFormData = (learnMore: ChallengeLearnMore): ChallengeLearnMoreSave => {
  const learnMoreData: ChallengeLearnMoreSave = {
    // learnMore: learnMore,
    // challengeStatusId: 0,
    // challengeCompletionStatusId: 0,
    desiredEnhancements: learnMore.desiredEnhancements,
    detailedDescriptionOftheChallenges: learnMore.detailedDescriptionOftheChallenges,
    overviewOfCurrentChallenges: learnMore.overviewOfCurrentChallenges,
    resourcesAndConstraints: learnMore.resourcesAndConstraints,
    successMetrics: learnMore.successMetrics,
  };
  return learnMoreData;
};

export const prepareFundingFormData = (fundingData: string | undefined): ChallengeFundingSave => {
  const fundingFormData = {
    funding: fundingData ?? "",
    challengeStatusId: 0,
    challengeCompletionStatusId: 0,
  };
  return fundingFormData;
};
export const prepareAdditionalInfoFormData = (
  additionalInfo: ChallengeAdditionalInfo
): ChallengeAdditionalInfoSave => {
  const fundingFormData = {
    information: additionalInfo.information,
    documents: additionalInfo.documents,
    challengeStatusId: 0,
    challengeCompletionStatusId: 0,
  };
  return fundingFormData;
};
export const prepareContactFormData = (items: ChallengeContact[]) => {
  const contactFormData = items.map((item: ChallengeContact) => ({
    id: 0,
    fullName: item.fullName,
    designation: item.designation,
    email: item.email,
    countryCodeId: item.countryCodeId,
    contactNumber: item.contactNumber,
    isPrimary: item.isPrimary,
  }));
  return contactFormData;
};
export const prepareContactTagsFormData = (items: OptionItem[] | null): OptionItem[] => {
  if (items) {
    const challengeTagsFormData = items.map(
      (item): OptionItem => ({
        id: item.id,
        name: item.name,
      })
    );
    return challengeTagsFormData;
  } else {
    return [];
  }
};

export const checkUnsavedChallengeDetails = (
  savedData: ChallengeDetails | undefined,
  currentData: ChallengeInfo
) => {
  if (!savedData) return true;
  const currentDataToCompare: ChallengeCompare = {
    title: currentData.title,
    summary: currentData.summary,
    startDate: currentData.startDate,
    endDate: currentData.endDate,
    coverImage: extractFileNameFromUrl(currentData.coverImage),
    isPrivate: currentData.isPrivate,
    location: currentData.location,
    targetIndustryId: currentData.targetIndustry?.id ?? 0,
    isGlobal: currentData.isGlobal,
  };

  const savedDataToCompare: ChallengeCompare = {
    title: savedData.title,
    summary: savedData.summary,
    startDate: savedData.startDate,
    endDate: savedData.endDate,
    coverImage: extractFileNameFromUrl(savedData.coverImage),
    isPrivate: savedData.isPrivate,
    location: savedData.location,
    targetIndustryId: savedData.targetIndustry?.id ?? 0,
    isGlobal: savedData.isGlobal,
  };
  return JSON.stringify(currentDataToCompare) !== JSON.stringify(savedDataToCompare);
};

export const checkUnsavedMilestones = (
  currentData: ChallengeMileStone[],
  savedData: ChallengeMileStone[] | undefined
) => {
  if (savedData && currentData.length !== savedData?.length) {
    return true;
  } else if (savedData) {
    const areArraysSame = currentData.every((currentMilestone, index) => {
      const savedMilestone = savedData[index];
      return (
        currentMilestone.startDate === savedMilestone.startDate &&
        currentMilestone.endDate === savedMilestone.endDate &&
        currentMilestone.milestone === savedMilestone.milestone &&
        currentMilestone.description === savedMilestone.description
      );
    });
    return !areArraysSame;
  }
  return true;
};

export const checkUnsavedTextContent = (
  currentTextContent: string | undefined,
  savedTextContent: string | undefined
) => {
  try {
    if (!savedTextContent || !currentTextContent) return true;
    const currentData = JSON.parse(currentTextContent);
    const savedData = JSON.parse(savedTextContent);
    return JSON.stringify(currentData) !== JSON.stringify(savedData);
  } catch (error) {
    return true;
  }
};

export const checkUnsavedAdditionalInfo = (
  currentTextContent: ChallengeAdditionalInfo,
  savedTextContent: ChallengeAdditionalInfo | undefined
) => {
  if (!savedTextContent) return true;
  const currentData = JSON.parse(currentTextContent.information);
  const savedData = JSON.parse(savedTextContent.information);
  if (
    JSON.stringify(currentData) === JSON.stringify(savedData) &&
    currentTextContent.documents.length === savedTextContent.documents.length
  ) {
    return false;
  } else if (JSON.stringify(currentData) !== JSON.stringify(savedData)) {
    return true;
  } else if (
    currentTextContent.documents &&
    currentTextContent.documents.length > 0 &&
    currentTextContent.documents.length === savedTextContent.documents.length
  ) {
    const currentDocumentNames = currentTextContent.documents.map((item) => item.name);
    const savedDocumentNames = savedTextContent.documents.map((item) => item.name);
    const result = currentDocumentNames.every((name) => savedDocumentNames.includes(name));
    return !result;
  }
  return true;
};

export const checkUnsavedContacts = (
  currentData: ChallengeContact[],
  savedData: ChallengeContact[] | undefined
) => {
  if (!savedData) return true;
  if (currentData.length !== savedData.length) {
    return true;
  }
  for (let i = 0; i < currentData?.length; i++) {
    if (JSON.stringify(currentData?.[i]) !== JSON.stringify(savedData?.[i])) {
      return true;
    }
  }
  return false;
};

export const checkUnsavedTags = (
  currentData: OptionItem[] | null,
  savedData: OptionItem[] | undefined
) => {
  if (!savedData) return true;
  return currentData && currentData.length !== savedData.length;
};

export const isUnSavedContactsExist = (
  currentData: ChallengeContact[],
  savedData: ChallengeContact[] | undefined
) => {
  if (!savedData) return true;
  const currentContacts = currentData.filter(
    (item) =>
      item.contactNumber.trim() !== "" ||
      item.countryCodeId.trim() !== "" ||
      item.designation.trim() !== "" ||
      item.email.trim() !== "" ||
      item.fullName !== ""
  );
  if (savedData.length === 0) {
    return JSON.stringify(defaultPrimaryContact) !== JSON.stringify(currentData[0]);
  } else if (currentContacts.length !== savedData.length) {
    return true;
  }
  for (let i = 0; i < currentContacts?.length; i++) {
    if (JSON.stringify(currentData?.[i]) !== JSON.stringify(savedData?.[i])) {
      return true;
    }
  }
  return false;
};

export const isUnsavedTextContent = (
  currentTextContent: string | undefined | null,
  savedTextContent: string | undefined | null,
  stepNumber: number
) => {
  try {
    if (!savedTextContent) {
      if (stepNumber === CREATE_CHALLENGE_COMPLETION_STATUS.LearnMore) {
        return (
          JSON.stringify(currentTextContent) !== JSON.stringify(DEFAULT_CHALLENGE_LEARN_MORE_FORMAT)
        );
      } else if (stepNumber === CREATE_CHALLENGE_COMPLETION_STATUS.Funding) {
        if (currentTextContent) {
          return JSON.stringify(currentTextContent) !== JSON.stringify(DEFAULT_EDITOR_EMPTY_STATE);
        }
        return false;
      }
      return true;
    } else if (savedTextContent && currentTextContent) {
      const currentData = JSON.parse(currentTextContent);
      const savedData = JSON.parse(savedTextContent);
      return JSON.stringify(currentData) !== JSON.stringify(savedData);
    }
    return true;
  } catch (error) {
    return true;
  }
};

export const isUnSavedMileStoneExist = (
  currentData: ChallengeMileStone[] | MileStone[],
  savedData: ChallengeMileStone[] | MileStone[] | undefined | null
) => {
  const currentMilestones = currentData.filter(
    (item) =>
      item.startDate.trim() !== "" ||
      item.endDate.trim() !== "" ||
      item.milestone.trim() !== "" ||
      item.description.trim() !== ""
  );
  if (!savedData || savedData.length !== currentMilestones.length) {
    return true;
  }
  const areArraysSame = currentMilestones.every((currentMilestone, index) => {
    const savedMilestone = savedData[index];
    return (
      currentMilestone.startDate === savedMilestone.startDate &&
      currentMilestone.endDate === savedMilestone.endDate &&
      currentMilestone.milestone === savedMilestone.milestone &&
      currentMilestone.description === savedMilestone.description
    );
  });
  return !areArraysSame;
};

export const isUnsavedAdditionalInfoExist = (
  currentTextContent: ChallengeAdditionalInfo | CollaborationAdditionalInfo,
  savedTextContent: ChallengeAdditionalInfo | CollaborationAdditionalInfo | undefined | null
) => {
  if (
    savedTextContent?.information === null &&
    JSON.stringify(currentTextContent?.information) === JSON.stringify(DEFAULT_EDITOR_EMPTY_STATE)
  ) {
    return false;
  }

  if (
    currentTextContent?.information !== savedTextContent?.information ||
    isUnsavedFileExist(currentTextContent?.documents)
  ) {
    return true;
  }

  return false;
};

const isUnsavedFileExist = (files: Array<ChallengeInfoDocument | FileDetailsItem>) => {
  if (!files) {
    return false;
  }

  for (let i = 0; i < files.length; i++) {
    if (!files[i].id) {
      return true;
    }
  }

  return false;
};

export const isValidDataEntered = (text: string | undefined): boolean => {
  try {
    if (!text) return false;
    const enteredText = JSON.stringify(JSON.parse(text));
    const jsonObject = JSON.parse(enteredText);
    if (jsonObject.blocks && jsonObject.blocks.length > 0) {
      const result = jsonObject.blocks.some((item: any) => item?.text?.trim().length > 0);
      return result;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export const checkUnsavedLearnMoreDetails = (
  currentData: ChallengeLearnMore,
  savedData: ChallengeLearnMore | null | undefined
) => {
  if (JSON.stringify(currentData) === JSON.stringify(savedData)) {
    return false;
  } else {
    return true;
  }
};
