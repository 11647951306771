import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputField, PrimaryButton } from "components";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { MAX_FILE_SIZE } from "utils/constants";
import SVGIcon from "assets/Icons";
import { useSendMessage } from "features/Messages/api/useSendMessage";
import { useUploadChatFile } from "features/Messages/api/useUploadChatFile";
import { useDeleteChatFileUpload } from "features/Messages/api/useDeleteChatFileUpload";

type ReplyProps = {
  threadId: string;
  scrollToBottom: () => void;
};

export default function Reply({ threadId, scrollToBottom }: ReplyProps) {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [attachments, setAttachments] = useState<Array<FileDetails> | null>();
  const [validationError, setValidationError] = useState<string>("");

  const { mutateAsync: fileUpload, isLoading: isFileUploading } = useUploadChatFile();
  const { mutateAsync: deleteFile, isLoading: isFileDeleting } = useDeleteChatFileUpload();
  const { mutateAsync: sendMessage, isLoading } = useSendMessage();

  const handleReply = async () => {
    if (message?.trim()?.length <= 0) {
      setValidationError("required");
      return;
    }
    setValidationError("");
    try {
      if (message?.trim()?.length > 0) {
        const formData = {
          threadId,
          content: message,
          hasAttachment: attachments && attachments?.length > 0 ? true : false,
          attachments: attachments && attachments?.length > 0 ? attachments : [],
        };
        sendMessage(formData);
      }
      setMessage("");
      setAttachments(null);
      scrollToBottom();
    } catch (error) {
      showErrorToastMessage({ message: t("global.something_went_wrong") });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    setMessage(value);
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event?.target?.files?.[0];
    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        event.target.value = "";
        return showErrorToastMessage({
          message: t("research_scholar.uploaded_files_size_error_message"),
        });
      }
      const fileName = selectedFile.name;
      const isFileExist = attachments?.find((item) => item?.name === fileName);
      if (isFileExist) {
        showErrorToastMessage({
          message: t("research_scholar.multiple_same_name_file_error_message"),
        });
        event.target.value = "";
        return;
      }
      try {
        const file = {
          id: -1,
          name: selectedFile?.name,
          fileUrl: "",
          size: selectedFile?.size,
        };
        const files = attachments && attachments.length > 0 ? [...attachments, file] : [file];
        setAttachments(files);
        const result = await fileUpload({
          file: selectedFile,
        });
        if (result) {
          const files = attachments ? [...attachments, result] : [result];
          setAttachments(files);
        }
      } catch (error) {
        showErrorToastMessage({
          message: t("global.something_went_wrong"),
        });
      } finally {
        event.target.value = "";
      }
    }
  };

  const handleRemove = async (event: React.MouseEvent<HTMLDivElement>, item: FileDetails) => {
    event.stopPropagation();
    event.preventDefault();
    const fileDetails = {
      id: item.id,
      name: item.name,
      fileUrl: item.fileUrl,
    };
    try {
      const response = await deleteFile(fileDetails);
      const newFiles = attachments?.filter((file) => file.fileUrl !== item?.fileUrl);
      if (newFiles) {
        setAttachments(newFiles);
      }
      showSuccessToastMessage({
        message: response.message,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error.response.data.status === 400
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const handleEnterKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleReply();
    }
  };

  return (
    <div className="mt-5 flex gap-2 mb-[18px] msgSend">
      <div
        className={`chatField relative ltr:!pr-12 rtl:!pl-12 focus-visible:outline-none py-2.5 placeholder:text-gray-1100 rtl:pr-3 ltr:pl-3 border border-primary-light w-full rounded-[4px] ${validationError ? validationError : ""
          } `}
      >
        <InputField
          value={message}
          hintText={t("forum.type_message")}
          labelText=""
          handleInputChange={handleInputChange}
          fieldName="message"
          className="!p-0 !h-auto !border-none !bg-transparent  dirRTL_LTR"
          handleKeyDown={handleEnterKeyPress}
        />
        <div className=" flex flex-wrap space-x-1 rtl:space-x-reverse">
          {attachments && attachments?.length > 0
            ? attachments?.map((item) => (
              <div
                className="flex items-center mt-1 px-2.5 py-2  border border-gray-light rounded-md"
                key={item.fileUrl}
              >
                <div className=" min-w-[20px]">
                  <SVGIcon name="docIcon" />
                </div>
                <div className=" max-w-[150px] truncate px-2.5 text-black font-medium text-sm uploadFileItem">
                  {item.name}
                </div>
                <div
                  className=" flex ml-auto"
                  onClick={(event: React.MouseEvent<HTMLDivElement>) => handleRemove(event, item)}
                >
                  <button className=" ltr:ml-2 rtl:mr-2" disabled={isFileUploading}>
                    <SVGIcon name="closeV3" />
                  </button>
                </div>
              </div>
            ))
            : null}
        </div>
        <div
          className=" absolute ltr:right-3 rtl:left-3 rtl:right-auto top-[11px] flex items-center justify-center upload-file-section attachmentBtn "
          aria-disabled={isFileUploading || !!(attachments && attachments?.length >= 5)}
        >
          <label className="text-primary-medium !block">
            <SVGIcon name="attachmentIcon" />
          </label>
          <input
            type="file"
            className=" opacity-0 absolute cursor-pointer w-full h-full -indent-[999px] typeFile"
            onChange={handleUpload}
          />
        </div>
      </div>
      <PrimaryButton
        title={"forum.send"}
        startIcon="sendMessage"
        handleClick={handleReply}
        className="max-w-[105px] flex items-center w-full !py-2 sendBtn"
        disabled={isFileUploading}
      />
    </div>
  );
}
