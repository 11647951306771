import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DefaultResetBgImage from "assets/images/defaultResetPasswordBg.png";
import {
  AppLogo,
  Title,
  PrimaryButton,
  Footer,
  InputField,
  LanguageSwitchButton,
  LoaderModal,
} from "components";
import { EMAIL_MAX_CHAR_LENGTH } from "utils/constants";
import { emailValidation } from "utils/validation/authValidation";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { useValidateResetEmail } from "features/Login/api/useAuth";

const ForgotPassword = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: validateResetEmail, isLoading } = useValidateResetEmail();

  const [state, setState] = useState<EmailData>({ email: "" });
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setState({ email: value });
  };

  const prepareFormData = () => {
    const data: EmailData = {
      email: state.email.trim(),
    };
    return data;
  };

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const error = emailValidation(state);
    if (error) {
      setValidationErrors(error);
      return;
    }
    setValidationErrors(null);
    try {
      const formData = prepareFormData();
      const validateEmailResponse = await validateResetEmail(formData);
      showSuccessToastMessage({
        message: validateEmailResponse.message,
        position: i18n.language === "ar" ? "top-right" : "top-left",
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error.response.data.status === 400
            ? error.response.data.message
            : t("global.something_went_wrong"),
        position: i18n.language === "ar" ? "top-right" : "top-left",
      });
    }
  };

  return (
    <div className="h-screen min-w-screen flex overflow-hidden">
      <div className="h-full w-full">
        <div className="h-full w-full relative rtl:pl-[550px] ltr:pr-[550px] max-[1280px]:rtl:pl-[450px] max-[1280px]:ltr:pr-[450px] max-[1024px]:!p-0">
          <img
            src={DefaultResetBgImage}
            alt="Reset background"
            className="w-full h-full object-cover max-[1024px]:hidden"
          />
          {/*Reset password section */}
          <div className="h-full bg-gradient-blueDark w-[551px] max-[1280px]:w-[451px]  absolute top-0 ltr:right-0 rtl:left-0 max-[1024px]:relative max-[1024px]:w-full">
            <div className="h-full w-full default-inputs-section">
              <div className=" overflow-auto h-full">
                {/*Language selector */}
                <div className="relative z-20 pt-3.5 ltr:pr-5 rtl:pl-5 mb-[65px] flex justify-end max-[1024px]:!p-5 max-[1024px]:mb-0">
                  <LanguageSwitchButton className="language-selector min-w-[86px] h-[38px]" />
                </div>
                {/*Reset password inputs section */}
                <div className="ltr:pr-[105px] ltr:pl-[3px] rtl:pr-[3px] rtl:pl-[105px] max-[1280px]:ltr:pr-[85px] max-[1280px]:rtl:pl-[85px]  max-[1024px]:!px-5 relative z-10 h-[calc(100%-117px)]  max-[1024px]:h-[calc(100%-78px)] flex flex-col">
                  <div>
                    <AppLogo />
                    <div className="mt-[42px] max-[1024px]:mt-[20px]">
                      <Title
                        className="text-white text-xl font-semibold mb-8 max-[1024px]:text-base max-[1024px]:mb-5"
                        title={t("global.reset_password")}
                      />
                      <form className="flex flex-col">
                        <div>
                          <InputField
                            hintText={t("global.enter_email_address")}
                            labelText={t("global.email_address")}
                            value={state.email}
                            handleInputChange={handleInputChange}
                            fieldName="Email"
                            errorMessage={validationErrors?.email}
                            isRequired
                            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
                            className="px-[18px]"
                          />
                        </div>
                        <div className="mt-[56px] max-[1024px]:mt-[30px]">
                          <PrimaryButton
                            title={"global.send_reset_password_link"}
                            handleClick={onSubmit}
                            className="default-primary-button w-full mb-[22px]"
                          />
                          <PrimaryButton
                            title={"global.login_instead"}
                            handleClick={() => {
                              navigate("/login");
                            }}
                            className="default-secondary-button !border-secondary-medium  !text-secondary-medium hover:!bg-primary-extraDark hover:!text-white !bg-transparent w-full hover:!border-primary-extraDark"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  {/*Footer section */}
                  <div className="mt-auto relative  z-10 pb-[30px] pt-[90px]  max-[1024px]:w-full  bg-primary-medium max-[1024px]:pt-[50px]">
                    <div>
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
};

export default ForgotPassword;
