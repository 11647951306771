import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStickyScroll } from "hooks/useStickyScroll";
import { LoaderModal, Header } from "components";
import { CreatePost } from "features/Collaboration/DiscussionForum/Posts/CreatePost";
import ForumHeader from "features/Collaboration/DiscussionForum/ForumHeader";
import Posts from "features/Collaboration/DiscussionForum/Posts";
import { useMasterDataStore } from "store/useMasterDataStore";
import { useUserDataStore } from "store/useUserDataStore";
import { useGetCollaborationBasicDetails } from "features/Collaboration/api/useGetCollaborationBasicDetails";
import { setSelectedSideMenu } from "store/useSidebarStore";
import { USERS } from "utils/constants";

export default function CollaborationDiscussionForum() {
  const { collaborationId } = useParams();
  const pathname = window.location.pathname;
  const isExplore = pathname.includes("explore");
  const isSearchResult = pathname.includes("search-results");
  const { scroll } = useStickyScroll();
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const { permissions } = useMasterDataStore();
  const { userInfo } = useUserDataStore();

  const collaborationDataId = Number(collaborationId);

  const { data: collaborationData, isLoading } = useGetCollaborationBasicDetails(
    collaborationDataId,
    isExplore
  );

  const getBreadCrumbPath = () => {
    const breadCrumb: Array<LinkItem> = [];

    if (isSearchResult) {
      return breadCrumb;
    }

    if (pathname.includes("invite-history")) {
      breadCrumb.push(
        {
          title: "research_institute.view_collaboration",
        },
        {
          title: "organization.invite_history",
        }
      );
    } else {
      let title = "global.collaborations";

      if (
        pathname.includes("explore") &&
        pathname.includes("discussion-forum") &&
        pathname.includes("collaborations")
      ) {
        title = "research_institute.explore_collaborations";
      } else if (pathname.includes("research-institute/requests")) {
        title = "research_institute.requests";
      }

      breadCrumb.push(
        {
          title,
        },
        {
          title: "research_institute.view_collaboration",
        }
      );
    }

    breadCrumb.push({
      title: "forum.discussion_forum",
    });
    return breadCrumb;
  };

  const linkList: Array<LinkItem> = getBreadCrumbPath();

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  useEffect(() => {
    let type = "global.collaborations";
    if (
      window.location.pathname.includes("requests") &&
      userInfo?.userType === USERS.ResearchInstitute
    ) {
      type = "research_institute.requests";
    } else if (window.location.pathname.includes("home")) {
      type = "research_scholar.home";
    }

    setSelectedSideMenu({
      type,
    });
  }, [pathname, userInfo?.userType]);

  return (
    <>
      <div
        className={`w-full h-full flex flex-col msgDetails   ${
          !isOpenSideMenu ? "miniSidebar" : ""
        }`}
      >
        <Header
          linkList={linkList}
          title={collaborationData?.title ?? ""}
          handleCollapseClick={onToggleClick}
          className={
            scroll
              ? "  bg-primary-medium lg:bg-white animate__animated animate__fadeInDown lg:shadow-sticky lg:!py-[26px] lg:!h-[90px] fixed stickyHeader"
              : "fixed bg-primary-medium lg:bg-gray-light"
          }
          displayGlobalTag={collaborationData?.isGlobal && !userInfo?.isGlobal}
        />
        <div className="formDetails adminDetails w-full pt-[138px] lg:pt-[118px] pb-[45px] items-baseline px-4 lg:px-10 lg:ltr:pr-[30px] lg:rtl:pl-[30px] min-h-screen flex flex-col ">
          <div className="  lg:w-[calc(100%-290px)] w-full">
            {collaborationData ? (
              <ForumHeader
                collaborationData={collaborationData}
                handleCreatePost={() => setShowCreatePost((prevState) => !prevState)}
                showCreatePost={!showCreatePost}
              />
            ) : null}
            <div className="chatReplayHolder">
              {showCreatePost && collaborationData?.isDiscussionForumEligible ? (
                <CreatePost
                  collaborationId={collaborationDataId}
                  handleDiscardPost={() => setShowCreatePost((prevState) => !prevState)}
                  handlePost={() => setShowCreatePost((prevState) => !prevState)}
                />
              ) : null}

              {permissions?.Viewpost ? (
                <Posts
                  collaborationId={collaborationDataId}
                  collaborationData={collaborationData}
                  isShowCreatePost={showCreatePost}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {isLoading ? <LoaderModal /> : null}
    </>
  );
}
