import { useQuery } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getPublicMasterData() {
  const res = await apiClient.get(urls.publicMasterData());
  const response = res.data?.data;
  return response as PublicMasterData;
}

export function useGetPublicMasterData() {
  return useQuery(
    ["public-master-data"],
    async () => {
      return await getPublicMasterData();
    },
    {
      staleTime: Infinity,
    }
  );
}
