import { convertJSONStringAsHtml } from "utils/helpers";

type EditorContentPreviewProps = {
  className?: string;
  data: string;
};

export function EditorContentPreview({ className, data }: EditorContentPreviewProps) {
  return (
    <div className=" max-w-[90%]  WB_BW">
      <span
        className={className ? className : ""}
        dangerouslySetInnerHTML={{
          __html: convertJSONStringAsHtml(data),
        }}
      />
    </div>
  );
}
