import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { SecondaryButton, PrimaryButton } from "components";

export function ConfirmationModal({
  onProceed,
  onClose,
  message,
  title,
  modalIcon,
  cancelButtonText,
  proceedButtonText,
  isAlert,
}: ModalProps) {
  const { t } = useTranslation();
  return (
    <div className=" max-w-[100vw] w-[100vw] min-w-[100vw]  md:w-auto md:max-w-[470px] mx-0 md:mx-2  md:min-w-[470px]  bg-white rounded-md rounded-b-none md:rounded-b-md px-4 pb-4 pt-12 md:pt-[50px]  md:p-[50px] md:pb-[50px] md:!px-8 sm:px-[50px] sm:p-[50px] flex flex-col items-center justify-center relative text-center animate__animated animate__zoomIn">
      {modalIcon ? <SVGIcon name={modalIcon} /> : null}
      {title ? (
        <h3 className=" text-xl font-semibold text-black mt-4 md:mt-10 pb-1 leading-normal">
          {t(title)}
        </h3>
      ) : null}
      <p className=" text-base text-primary-text px-5">{t(message)}</p>
      <div className=" flex w-full justify-center space-x-2.5 pt-12 rtl:space-x-reverse">
        <SecondaryButton
          className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
          title={cancelButtonText ? cancelButtonText : "global.no"}
          handleClick={() => onClose && onClose()}
        />
        {isAlert ? (
          <PrimaryButton
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
            title={proceedButtonText ? proceedButtonText : "global.yes"}
            handleClick={() => onProceed && onProceed()}
          />
        ) : (
          <PrimaryButton
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
            title={proceedButtonText ? proceedButtonText : "global.yes"}
            handleClick={() => onProceed && onProceed()}
          />
        )}
      </div>
    </div>
  );
}
