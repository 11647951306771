import { clearSidebarDataStore } from "store/useSidebarStore";
import { clearMasterDataStore } from "store/useMasterDataStore";
import { clearOrganizationDataStore } from "store/useOrganizationDataStore";
import { clearScholarProfileDataStore } from "store/useScholarProfileDataStore";
import { clearUserStore } from "store/useUserDataStore";
import { clearFundingOrganizationDataStore } from "store/useFundingOrganizationDataStore";
import { clearChallengeStore } from "store/useChallengeStore";
import { clearResearchInstituteDataStore } from "store/useResearchInstituteDataStore";
import { clearAdvisorFilterDataStore, clearFilterDataStore, clearUserTypeFilter } from "store/useFilterDataStore";
import { clearEventDataStore } from "store/useEventDataStore";
import { clearCollaborationDataStore } from "store/useCollaborationDataStore";

export function clearStores() {
  clearMasterDataStore();
  clearUserStore();
  clearScholarProfileDataStore();
  clearSidebarDataStore();
  clearOrganizationDataStore();
  clearFundingOrganizationDataStore();
  clearChallengeStore();
  clearResearchInstituteDataStore();
  clearFilterDataStore();
  clearEventDataStore();
  clearUserTypeFilter();
  clearCollaborationDataStore();
  clearAdvisorFilterDataStore();
}
