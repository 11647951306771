import Joi from "joi";
import { groupBy } from "utils/helpers";
import { EMAIL_VALIDATION_PATTERN } from "utils/constants";

const loginSchema = Joi.object({
  username: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .pattern(EMAIL_VALIDATION_PATTERN)
    .required()
    .messages({
      "string.empty": "required",
      "string.email": "global.invalid_email_error_message",
      "string.pattern.base": "global.invalid_email_error_message",
    }),
  password: Joi.string().trim().max(24).required().messages({
    "string.empty": "required",
    "string.pattern.base": "required",
    "string.pattern.name": "required",
    "string.max": "required",
  }),
}).options({ abortEarly: false });

const emailSchema = Joi.object({
  email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .pattern(EMAIL_VALIDATION_PATTERN)
    .required()
    .messages({
      "string.empty": "required",
      "string.email": "global.invalid_email_error_message",
      "string.pattern.base": "global.invalid_email_error_message",
    }),
}).options({ abortEarly: false });

export const loginSchemaValidation = (basicFormData: LoginFormData) => {
  const result = loginSchema.validate(basicFormData);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};

export const emailValidation = (email: EmailData) => {
  const result = emailSchema.validate(email, { abortEarly: false });
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
