import React from "react";
import { useTranslation } from "react-i18next";

type SubMenuProps = {
  title: string;
  type: string;
  handleSubMenuClick?: (type: string) => void;
  icon?: React.ReactNode;
  className?: string;
  isActive?: boolean;
};

export function SubMenu({
  title,
  className,
  isActive,
  icon,
  handleSubMenuClick,
  type,
}: SubMenuProps) {
  const { t } = useTranslation();

  return (
    <li
      className={` h-5 w-full flex font-light items-start relative ltr:pl-[50px] rtl:pr-[50px] lg:ltr:pl-[34px] lg:rtl:pr-[34px] hover:text-secondary-medium  text-whiter text-sm = ${className} ${isActive ? " opacity-100 " : "  opacity-50 "
        }`}
      onClick={() => {
        handleSubMenuClick && type && handleSubMenuClick(type);
      }}
    >
      {icon}
      {t(title)}
    </li>
  );
}
