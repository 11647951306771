import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function deleteResearchProject(userId: string, researchProjectId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.delete(urls.deleteResearchProject(userId, researchProjectId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response;
}

export function useDeleteResearchProject(researchScholarId: string) {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = researchScholarId ?? userInfo?.userId;
  const qc = useQueryClient();

  return useMutation(async (researchProjectId: number) => {
    if (userId) {
      const result = await deleteResearchProject(userId, researchProjectId);
      qc.invalidateQueries({ queryKey: ["research-projects"] });
      return result;
    }
  });
}
