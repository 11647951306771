import { useQuery } from "@tanstack/react-query";
import { setUserInfo, useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { USERS } from "utils/constants";
import { urls } from "utils/urls";

async function getOrganizationDetails(userInfo: UserInfo, userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.organization(userId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  const response = res.data?.data;
  if (userInfo && userInfo?.userType === USERS.Organization) {
    setUserInfo({
      ...userInfo,
      firstName: response?.organizationName ?? "",
      profileStatusId: response.profileStatusId,
      avatarUrl: response?.profileImage,
    });
  }
  return res.data?.data as OrganizationDetails | ApiError;
}

export function useGetOrganizationDetails(organizationId: string) {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = organizationId ? organizationId : userInfo?.userId;
  return useQuery(
    ["organization-details", userId],
    async () => {
      if (userInfo && userId) {
        return await getOrganizationDetails(userInfo, userId);
      } else return;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
