import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import {
  Descriptor,
  SecondaryButton,
  RichTextEditor,
  PrimaryButton,
  FileUpload,
  LoaderModal,
  InputField,
} from "components";
import { DETAILS_DISPLAY_MAX_CHAR_LENGTH, MAX_FILE_SIZE } from "utils/constants";
import { challengeUploadCaseStudyValidation } from "utils/validation/challengeUploadCaseStudyValidation";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { useUploadChallengeFile } from "features/Organization/api/useUploadChallengeDocument";
import { useSaveChallengeCaseStudy } from "features/Organization/api/useSaveCaseStudy";
import { useGetChallengeCaseStudy } from "features/Organization/api/useGetCaseStudy";
import { extractFileNameFromUrl } from "utils/helpers";
import { useUpdateChallengeCaseStudy } from "features/Organization/api/useUpdateChallengeCaseStudy";

type UploadCaseStudyProps = {
  onClose: () => void;
  challengeId: number;
  isEdit: boolean;
};

const UploadCaseStudy = ({ onClose, challengeId, isEdit }: UploadCaseStudyProps) => {
  const { t } = useTranslation();
  const { data: caseStudyData } = useGetChallengeCaseStudy(challengeId, isEdit);
  const { mutateAsync: uploadChallengeFile, isLoading } = useUploadChallengeFile();
  const { mutateAsync: uploadCaseStudy, isLoading: isUploading } = useSaveChallengeCaseStudy();
  const { mutateAsync: updateCaseStudy, isLoading: isUpdating } = useUpdateChallengeCaseStudy();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [isDescriptionReset, resetContent] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<FileDetailsItem | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>(null);

  const onDiscard = () => {
    onClose();
  };

  useEffect(() => {
    if (caseStudyData) {
      setCaseStudyData(caseStudyData);
    }
  }, [caseStudyData]);

  const setCaseStudyData = (caseStudyData: CaseStudyData) => {
    if (caseStudyData) {
      setTitle(caseStudyData.title);
      setDescription(caseStudyData.description);
      resetContent((prev) => !prev);
      const uploadedFile: FileDetailsItem = {
        id: 0,
        name: extractFileNameFromUrl(caseStudyData.uploadFileUrl),
        fileUrl: caseStudyData.uploadFileUrl,
      };
      setUploadedFile(uploadedFile);
    }
  };
  const handleSaveCaseStudy = async () => {
    try {
      const formData: UploadCaseStudyFormData = {
        description,
        title,
        uploadFileUrl: uploadedFile?.fileUrl ?? "",
      };
      const error = challengeUploadCaseStudyValidation(formData);
      if (error) {
        setValidationErrors(error);
        return;
      }
      if (uploadedFile === null) {
        showErrorToastMessage({
          message: t("organization.attach_a_case_study_document"),
        });
        return;
      }
      setValidationErrors(null);
      if (isEdit) {
        const result = await updateCaseStudy({
          challengeId: challengeId,
          formData: formData,
        });
        if (result) {
          showSuccessToastMessage({
            message: result.message,
          });
          onClose();
        }
      } else {
        const result = await uploadCaseStudy({
          challengeId: challengeId,
          formData: formData,
        });
        if (result) {
          showSuccessToastMessage({
            message: t("organization.upload_case_study_complete"),
          });
          onClose();
        }
      }
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target?.files;
    if (selectedFiles && selectedFiles?.length > 0) {
      if (selectedFiles?.[0].size > MAX_FILE_SIZE) {
        event.target.value = "";
        return showErrorToastMessage({
          message: t("admin.csv_file_size_error"),
        });
      }
      try {
        const result = await uploadChallengeFile({
          file: selectedFiles[0],
          challengeId: challengeId,
          isCoverImage: false,
        });
        if (result) {
          setUploadedFile(result);
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 1000);
        }
      } catch (error) {
        showErrorToastMessage({
          message: t("global.something_went_wrong"),
        });
      } finally {
        event.target.value = "";
      }
    }
  };

  const handleDeleteFile = () => {
    setUploadedFile(null);
  };
  return (
    <>
      <div className="bg-white  max-w-[100vw] w-[100vw] min-w-[100vw]  lg:w-auto lg:max-w-[830px] mx-0 lg:mx-2  lg:min-w-[830px] rounded-md max-h-[calc(100vh-50px)] overflow-auto animate__animated animate__zoomIn">
        <div className="flex justify-between px-6 py-[26px] border-b !border-gray-light">
          <div className="flex gap-3 items-center">
            <span onClick={onClose}>
              <SVGIcon name="uploadV3" />
            </span>
            <p className="font-semibold text-base text-black">{t("global.upload_case_study")}</p>
          </div>
          <button onClick={onDiscard}>
            <SVGIcon name="closeCurrentColor" className="text-[#072438]" />
          </button>
        </div>
        <div className="px-6 pt-6 pb-[50px]">
          <div className="mb-6">
            <InputField
              hintText={t("organization.upload_case_study_title_placeholder")}
              labelText={t("global.title")}
              fieldName={"title"}
              value={title}
              handleInputChange={function (event: ChangeEvent<HTMLInputElement>): void {
                setTitle(event.target.value);
              }}
              maxCharLength={DETAILS_DISPLAY_MAX_CHAR_LENGTH}
              isRequired
              errorMessage={validationErrors?.title}
            />
          </div>
          <div
            className={`border border-primary-light rounded-md ${validationErrors?.description ? "!border-red-200 " : "border-primary-light"
              }`}
          >
            <RichTextEditor
              key={isDescriptionReset ? "reset" : ""}
              text={description}
              placeholder={t("organization.upload_case_study_placeholder")}
              handleTextChange={(text) => {
                setDescription(text);
              }}
            />
          </div>
          <div>
            {uploadedFile ? (
              <>
                <Descriptor
                  title={t("organization.attached_files")}
                  startIcon="network"
                  className="text-primary-light font-normal text-sm mt-4 mb-3"
                />
                <div className="flex justify-center items-center gap-2 mt-2">
                  <div className="w-full flex justify-between items-center px-5 py-4 border rounded-md border-grey-light truncate">
                    <div className="flex items-center gap-3 truncate">
                      <div className=" min-w-[24px]">
                        <SVGIcon name="previewPDF" />
                      </div>
                      <span className="text-sm font-medium  text-black truncate pr-2">
                        {uploadedFile?.name}
                      </span>
                    </div>
                    <div
                      className="flex items-center gap-3 cursor-pointer"
                      onClick={handleDeleteFile}
                    >
                      <SVGIcon name="trash" />
                    </div>
                  </div>
                  <div className="w-full flex justify-between items-center px-5 py-4" />
                </div>
              </>
            ) : (
              <div>
                <div className="mt-8">
                  <FileUpload
                    multiple={false}
                    handleFileUpload={(event) => handleFileUpload(event)}
                    icon={<SVGIcon name={"upload"} />}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-center items-center mt-[47px] gap-2">
            <div className="w-[120px]">
              <SecondaryButton title={"global.close"} handleClick={onDiscard} className="w-full" />
            </div>
            <div className="w-[120px]">
              <PrimaryButton
                title={"global.upload"}
                handleClick={handleSaveCaseStudy}
                className="w-full"
              />
            </div>
          </div>
        </div>
      </div>
      {isLoading || isUploading || isUpdating ? <LoaderModal /> : null}
    </>
  );
};

export default UploadCaseStudy;
