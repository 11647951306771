import { create } from "zustand";
import { produce } from "immer";
import { persist } from "zustand/middleware";

export type UserDataStore = {
  tokenInfo?: Partial<TokenInfo>;
  userInfo?: UserInfo;
};

export const useUserDataStore = create<UserDataStore>()(
  persist(
    (set) => ({
      tokenInfo: undefined,
      userInfo: undefined,
    }),
    {
      name: "user-data",
      getStorage: () => sessionStorage,
    }
  )
);

export function setUserInfo(userInfo: UserInfo) {
  useUserDataStore.setState(
    produce((state: UserDataStore) => {
      state.userInfo = userInfo;
    })
  );
}

export function setAvatarUrl(value: string) {
  useUserDataStore.setState(
    produce((state) => {
      if (state.userInfo) {
        state.userInfo.avatarUrl = value;
      }
    })
  );
}

export function setTokenInfo(tokenInfo: TokenInfo) {
  useUserDataStore.setState(
    produce((state: UserDataStore) => {
      state.tokenInfo = tokenInfo;
    })
  );
}

export function clearUserStore() {
  useUserDataStore.setState(
    produce((state: UserDataStore) => {
      state.tokenInfo = undefined;
      state.userInfo = undefined;
    })
  );
}
