import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function verifyBulkInviteFile(file: File) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const formData = new FormData();
  formData.append("file", file);

  const response = await apiClient.put(urls.verifyBulkInviteFile(), formData, {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
  });
  return response.data.data as ApiStatus | ApiError;
}

export function useVerifyBulkInviteFile() {
  return useMutation(async (file: File) => {
    return verifyBulkInviteFile(file);
  });
}

async function uploadBulkInvite(file: File, isGlobalInvite: boolean) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const formData = new FormData();
  formData.append("file", file);

  const response = await apiClient.post(urls.uploadBulkInvite(isGlobalInvite), formData, {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
  });
  return response.data as ApiStatus | ApiError;
}

export function useUploadBulkInvite() {
  return useMutation(async ({ file, isGlobalInvite }: { file: File; isGlobalInvite: boolean }) => {
    return uploadBulkInvite(file, isGlobalInvite);
  });
}
