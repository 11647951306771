import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function allRead() {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.post(
    urls.allRead(),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res.data?.data as NotificationCount | ApiError;
}

export function useSetAllRead() {
  const qc = useQueryClient();
  return useMutation(async () => {
    const userInfo = useUserDataStore.getState().userInfo;
    const userId = userInfo?.userId;
    if (userId) {
      const result = await allRead();
      qc.resetQueries(["notifications"]);
      return result;
    }
    return null;
  });
}
