import React, { useMemo, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  SingleSelect,
  InputField,
  PrimaryButton,
  LoaderModal,
  SearchableSingleSelect,
  CheckBox,
} from "components";
import { useGetPublicMasterData } from "hooks/useGetPublicMasterData";
import { isGlobalWebsite, phoneMaxLimitChecker, setMasterDataValue } from "utils/helpers";
import { basicSchemaValidation } from "utils/validation/signUpValidation";
import {
  DEFAULT_MAX_CHAR_LENGTH,
  EMAIL_MAX_CHAR_LENGTH,
  INITIAL_SEARCH_PARAMS,
  PAGE_LIMIT,
} from "utils/constants";

import { getResearchInstitute } from "features/ResearchScholar/api/useGetResearchInstitute";
import { validateEmailAddress } from "features/ResearchScholar/api/useValidateEmail";
import { showErrorToastMessage } from "utils/toasterMessage";

type SignUpFormProps = {
  signUpFormData: BasicFormFields;
  handleChange: (
    value: string | number | boolean | ResearchInstituteItem | null,
    fieldName: string
  ) => void;
  movetoNext: () => void;
};

function SignUpForm({ signUpFormData, handleChange, movetoNext }: SignUpFormProps) {
  const { t, i18n } = useTranslation();
  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const { data: publicMasterData, isLoading: publicMasterDataLoading } = useGetPublicMasterData();

  const countryCodeOptions = useMemo(() => {
    return publicMasterData?.countryCodes?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [publicMasterData, i18n.language]);

  const titleOptions = useMemo(() => {
    return publicMasterData?.title?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [publicMasterData, i18n.language]);

  const handleContinueClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const error = basicSchemaValidation(signUpFormData);

    if (error) {
      setValidationErrors(error);
      return;
    }
    setValidationErrors(null);

    try {
      await validateEmailAddress(signUpFormData.email);
      movetoNext();
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
        position: i18n.language === "ar" ? "top-right" : "top-left",
      });
    }
  };

  const handleTitleChange = (value: string | number) => {
    handleChange(value as number, "titleId");
  };

  const handleCountryCodeChange = (value: string | number) => {
    handleChange(value as number, "countryCodeId");
  };

  const handleResearchInstituteChange = (value: ResearchInstituteItem) => {
    handleChange(value, "researchInstitute");
    handleChange(value.id, "researchInstituteId");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const value = event.target.value;
    handleChange(value, fieldName);
  };
  const isGlobal = isGlobalWebsite();

  async function loadResearchInstituteOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) {
      pageNumber = 1;
    }
    const isGlobal = isGlobalWebsite();
    const result = await getResearchInstitute({
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
      isGlobal,
    });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  return (
    <>
      <div className="  flex flex-col h-full">
        {!isGlobal ? (
          <CheckBox
            option={{
              label: t("research_scholar.advisor"),
              value: "advisor",
            }}
            tooltipContent="research_scholar.advisor_tooltip_content"
            className=" pb-5 text-white flex items-center app-c-advisor mb-4"
            handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(null, "researchInstituteId");
              handleChange(null, "researchInstitute");
              handleChange(event.target.checked, "isAdvisor");
            }}
            checked={signUpFormData.isAdvisor}
          />
        ) : null}
        <div className=" mb-auto space-y-5">
          <div className="flex space-x-3 rtl:space-x-reverse">
            <div className=" min-w-[100px] max-w-[100px]">
              <SingleSelect
                options={titleOptions ?? []}
                labelText={t("global.title")}
                hintText={t("global.select")}
                handleChange={handleTitleChange}
                selectOption={signUpFormData?.titleId}
                isRequired
                errorMessage={validationErrors?.titleId}
              />
            </div>
            <div className="w-full">
              <InputField
                value={signUpFormData.firstName}
                hintText={t("global.enter_first_name")}
                labelText={t("global.first_name")}
                handleInputChange={handleInputChange}
                fieldName="firstName"
                isRequired
                errorMessage={validationErrors?.firstName}
                maxCharLength={DEFAULT_MAX_CHAR_LENGTH}
              />
            </div>
          </div>
          <div className=" grid grid-cols-2 gap-3">
            <InputField
              value={signUpFormData.middleName}
              hintText={t("global.enter_middle_name")}
              labelText={t("global.middle_name")}
              handleInputChange={handleInputChange}
              fieldName="middleName"
              errorMessage={validationErrors?.middleName}
              className=" pr-2"
              maxCharLength={DEFAULT_MAX_CHAR_LENGTH}
            />
            <InputField
              value={signUpFormData.lastName}
              hintText={t("global.enter_last_name")}
              labelText={t("global.last_name")}
              handleInputChange={handleInputChange}
              fieldName="lastName"
              isRequired
              errorMessage={validationErrors?.lastName}
              className=" pr-2"
              maxCharLength={DEFAULT_MAX_CHAR_LENGTH}
            />
          </div>
          <InputField
            value={signUpFormData.email}
            hintText={t("global.enter_email_address")}
            labelText={t("global.email_address")}
            handleInputChange={handleInputChange}
            fieldName="email"
            isRequired
            errorMessage={validationErrors?.email}
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
          />
          {signUpFormData.isAdvisor ? (
            <InputField
              hintText={
                signUpFormData.isAdvisor ? t("research_scholar.advisor_institute_placeholder") : ""
              }
              labelText={t("research_scholar.research_institute_name")}
              handleInputChange={() => {
                //noop
              }}
              fieldName="researchInstituteName"
              value={""}
              isDisabled
              isRequired
            />
          ) : (
            <SearchableSingleSelect
              className="text-sm font-normal flex-grow w-full"
              labelText={t("research_scholar.research_institute_name")}
              hintText={
                signUpFormData.isAdvisor
                  ? t("research_scholar.advisor_institute_placeholder")
                  : t("global.select")
              }
              selectedOptions={
                signUpFormData?.researchInstitute ? signUpFormData?.researchInstitute : null
              }
              setSelectedOptions={function (option: ResearchInstituteItem | null): void {
                if (option) {
                  handleResearchInstituteChange(option);
                }
                searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
              }}
              errorToastMessage="global.create_target_industry_min_character"
              loadOptions={loadResearchInstituteOptions}
              errorMessage={validationErrors?.researchInstituteId}
              resetKey={Math.random().toString()}
              onMenuOpen={() => {
                //Commented this code to prevent duplicate entries in the list- HP-4880
                //searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
              }}
              isDisabled={signUpFormData.isAdvisor}
            />
          )}

          <SingleSelect
            options={countryCodeOptions || []}
            labelText={t("global.country_code")}
            hintText={t("global.select")}
            handleChange={handleCountryCodeChange}
            isRequired
            errorMessage={validationErrors?.countryCodeId}
            selectOption={signUpFormData.countryCodeId}
            className="country-code-selector"
          />

          <InputField
            value={signUpFormData.contactNumber}
            hintText={t("global.enter_contact_number")}
            labelText={t("global.contact_number")}
            handleInputChange={handleInputChange}
            fieldName="contactNumber"
            isRequired
            errorMessage={validationErrors?.contactNumber}
            maxCharLength={phoneMaxLimitChecker(signUpFormData.contactNumber)}
          />
        </div>
        <div className="pt-14">
          <PrimaryButton
            title={"global.continue"}
            handleClick={handleContinueClick}
            className="default-primary-button w-full mb-8"
          />
        </div>
      </div>
      {publicMasterDataLoading ? <LoaderModal /> : null}
    </>
  );
}

export default SignUpForm;
