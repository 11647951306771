import { Routes, Route } from "react-router-dom";
import { PageNotFound } from "components";
import AppOutlet from "components/AppOutlet";

import { PrivateRoute } from "router/PrivateRoute";
import ChangePassword from "features/ChangePassword";
import SearchList from "features/GlobalSearch/SearchList";

/* Admin */
import ResearchScholarInfo from "features/Admin/Users/ResearchScholars/ResearchScholarInfo";
import OrganizationInfo from "features/Admin/Users/Organizations/OrganizationInfo";
import FundingOrganizationInfo from "features/Admin/Users/FundingOrganizations/FundingOrganizationInfo";

/*FundingOrganization*/
import FundingOrganizationProfile from "features/FundingOrganization/Profile";
import FundingOrganization from "features/FundingOrganization";

/* Research Institute */
import ResearchInstituteInfo from "features/Admin/Users/ResearchInstitute/ResearchInstituteInfo";

/* Challenge */
import ChallengeDashboard from "features/Challenge/Dashboard";
import ChallengeDetailsPreview from "features/Challenge/Preview";

/* Events */
import EventDashboard from "features/Events/Dashboard";
import EventPreview from "features/Events/EventPreview";

import DiscussionForum from "features/DiscussionForum";
import Messages from "features/Messages";

export default function FundingOrganizationRoutes() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <FundingOrganization />
          </PrivateRoute>
        }
      >
        <Route
          path="home"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<ChallengeDashboard />} index />
          <Route
            path=":challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ChallengeDetailsPreview />} index />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>
        </Route>
        <Route
          path="challenges"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<ChallengeDashboard />} index />
          <Route
            path=":challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ChallengeDetailsPreview />} index />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>
        </Route>
        <Route path="forums" element={<Messages />} />
        <Route
          path="settings"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route path="profile/:fundingOrganizationId" element={<FundingOrganizationProfile />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
        <Route
          path="search-results"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<SearchList />} index />
          <Route
            path="challenges/:challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ChallengeDetailsPreview />} index />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>
          <Route path="research-scholar/:researchScholarId" element={<ResearchScholarInfo />} />
          <Route path="organization/:organizationId" element={<OrganizationInfo />} />
          <Route
            path="funding-organization/:fundingOrganizationId"
            element={<FundingOrganizationInfo />}
          />
          <Route
            path="research-institute/:researchInstituteId"
            element={<ResearchInstituteInfo />}
          />
        </Route>
        <Route
          path="events"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route index element={<EventDashboard />} />
          <Route path=":eventId" element={<EventPreview />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
