import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveEventAbout(formData: EventDetails) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  let response: ApiStatus | ApiError;

  if (!formData.id) {
    response = await apiClient.post(urls.saveEventAbout(), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    response = await apiClient.put(urls.saveEventAbout(), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  return response?.data as ApiStatus | ApiError;
}

export function useSaveAbout() {
  const qc = useQueryClient();
  return useMutation(async ({ formData }: { formData: EventDetails }) => {
    const result = await saveEventAbout(formData);
    qc.invalidateQueries({ queryKey: ["event-about", formData.id] });
    return result;
  });
}
