import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useChallengeStore } from "store/useChallengeStore";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getChallengeDetails(challengeId: number): Promise<ChallengeDetails | null> {
  try {
    if (challengeId === 0) return null;
    const tokenInfo = useUserDataStore.getState().tokenInfo;
    const token = tokenInfo?.token;
    const res = await apiClient.get(urls.challengeDetails(challengeId), {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 400) {
      throw new Error("error");
    }
    return res.data?.data as ChallengeDetails | ApiError;
  } catch (error) {
    return null;
  }
}

export function useGetChallengeDetails(): UseQueryResult<ChallengeDetails> {
  const challengeId = useChallengeStore.getState().challengeInfo?.id;
  return useQuery(
    ["challenge-details", challengeId],
    async () => {
      const result = await getChallengeDetails(Number(challengeId));
      return result;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
