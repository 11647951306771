import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { LoaderModal, SecondaryButton, Title } from "components";
import {
  addCollaborationMilestone,
  removeCollaborationMilestone,
  setMilestoneInfo,
  setMilestoneInfoItem,
  useCollaborationDataStore,
} from "store/useCollaborationDataStore";
import { showErrorToastMessage } from "utils/toasterMessage";
import { CHALLENGE_STATUS } from "utils/constants";
import MilestoneForm from "features/Collaboration/CreateCollaboration/Milestones/MilestoneForm";
import { showConfirmationModal } from "utils/confirmationModals";
import { useGetCollaborationMilestones } from "features/Collaboration/api/useGetCollaborationMilestones";

type MileStonesProps = {
  validationErrors: Array<Record<string, string>> | null;
};

export default function MileStones({ validationErrors }: MileStonesProps) {
  const { t } = useTranslation();
  const { milestones } = useCollaborationDataStore().milestones;
  const { data: milestonesData, isLoading } = useGetCollaborationMilestones();
  const { collaborationInfo } = useCollaborationDataStore();
  const isPending = collaborationInfo.collaborationStatusId === CHALLENGE_STATUS.Pending;

  const onAddMileStone = () => {
    if (milestones.length < 10) {
      addCollaborationMilestone();
    } else {
      showErrorToastMessage({
        message: t("organization.maximum_number_of_milestones"),
      });
    }
  };

  const handleDateInputChange = (key: keyof MileStone, index: number) => (date: string) => {
    setMilestoneInfoItem(date, key, index);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    key: keyof MileStone,
    index: number
  ) => {
    const value = event?.target?.value;
    setMilestoneInfoItem(value, key, index);
  };

  const handleDeleteMilestone = async (index: number) => {
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "organization.confirm_delete_milestone",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      modalIcon: "confirmDelete",
      isAlert: true,
    });
    if (!result) {
      return;
    }
    removeCollaborationMilestone(index);
  };

  const eventHandlers = {
    handleDateInputChange,
    handleInputChange,
    handleDeleteMilestone,
  };

  useEffect(() => {
    if (milestonesData) {
      setMilestoneInfo(milestonesData);
    }
  }, [milestonesData]);

  return (
    <div>
      <div className=" flex items-center justify-between mb-6 flex-wrap pb-8 p-5 !py-3 border-b border-gray-light">
        <div className="flex items-center ltr:mr-auto rtl:ml-auto">
          <SVGIcon name="milestones" />
          <Title
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            title={t("organization.milestones")}
          />
        </div>
        {milestones.length > 0 && (
          <SecondaryButton
            startIcon="addMilestone"
            className=" !h-10 !min-h-0  !text-sm  font-medium bg-white hover:!text-white flex gap-1"
            title={"organization.add_milestone"}
            handleClick={onAddMileStone}
            disabled={isPending}
          />
        )}
      </div>
      {milestones.length == 0 ? (
        <div className="px-5">
          <SecondaryButton
            title={"organization.add_milestone"}
            handleClick={onAddMileStone}
            className=" w-full !bg-gray-light/60 py-3.5 !font-normal mb-6 space-x-4 rtl:space-x-reverse border-none h-16 flex items-center !text-primary-medium text-sm  hover:!bg-primary-medium hover:!text-white"
            endIcon="plusCircle"
            disabled={isPending}
          />
        </div>
      ) : (
        milestones.map((item, index) => (
          <MilestoneForm
            key={index}
            index={index}
            validationErrors={validationErrors?.[index]}
            data={item}
            eventHandlers={eventHandlers}
            isDisabled={isPending}
          />
        ))
      )}
      {isLoading ? <LoaderModal /> : null}
    </div>
  );
}
