import { Title, Label, Chip } from "components";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { displayDateFormat } from "utils/helpers";

type ResearchScholarProfileViewModalProps = {
  onClose: () => void;
  data: ResearchInstituteUsersData;
};

export default function ResearchScholarDetailsModal({
  onClose,
  data,
}: ResearchScholarProfileViewModalProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className=" max-w-[100vw] w-[100vw] min-w-[100vw]  md:w-auto md:max-w-[750px] mx-0 md:mx-2  md:min-w-[750px]  bg-white rounded-md rounded-b-none md:rounded-b-md  flex flex-col  relative animate__animated animate__zoomIn max-h-[calc(100vh-50px)] overflow-auto">
        <div className=" flex  border-b border-gray-light relative p-4 lg:p-6">
          <SVGIcon name="personalDetails" />
          <Title
            title={t("research_institute.invited")}
            className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
          />
          <button
            className="absolute btn btn-link text-black p-0 w-auto h-auto min-h-fit ltr:right-6 rtl:left-6 rtl:right-auto"
            onClick={() => onClose && onClose()}
          >
            <SVGIcon name="close" />
          </button>
        </div>
        <div className=" p-5">
          <Label text={t("admin.created_on")} className="text-primary-light font-normal text-sm" />
          <Label
            text={displayDateFormat(data?.createdOn)}
            className=" text-black font-normal text-base mb-3"
          />
          <Label text={t("admin.name")} className="text-primary-light font-normal text-sm" />
          <div className="mb-3 flex items-center">
            <Label
              text={data?.instituteName}
              className="text-black font-normal text-base !w-auto !inline leading-5"
            />
            {data.isGlobal ? (
              <div className=" h-[20px] flex items-center px-3 ltr:!ml-1 rtl:!mr-1 rounded-xl text-xs text-white font-normal bg-primary-medium ">
                {t("global.global")}
              </div>
            ) : null}
            {data.status === "Start-Up" ? (
              <Chip
                className="!m-0 ltr:!ml-2 rtl:!mr-1 !min-w-[64px] tableChip !px-1 !inline !w-auto"
                text={data.status}
                disabled
              />
            ) : null}
          </div>
          <Label text={t("admin.type")} className="text-primary-light font-normal text-sm" />
          <Label text={data?.instituteType} className="text-black font-normal text-base mb-3" />
          <Label text={t("admin.email")} className="text-primary-light font-normal text-sm" />
          <Label text={data?.email} className="text-black font-normal text-base mb-3" />
          <Label
            text={t("organization.contact")}
            className="text-primary-light font-normal text-sm"
          />
          <Label text={data?.contactNumber} className="text-black font-normal text-base mb-3" />
        </div>
      </div>
    </>
  );
}
