import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { Title, LoaderModal, TextArea } from "components";
import { CHALLENGE_STATUS, URL_MAX_LENGTH } from "utils/constants";
import {
  setLearnMoreInfo,
  setLearnMoreInfoItem,
  useCollaborationDataStore,
} from "store/useCollaborationDataStore";
import { useGetCollaborationLearnMore } from "features/Collaboration/api/useGetCollaborationLearnMore";

type LearnMoreProps = {
  validationErrors: Record<string, string> | null;
};

const LearnMore = ({ validationErrors }: LearnMoreProps) => {
  const { t } = useTranslation();
  const { description, overview, resources, targets } = useCollaborationDataStore().learnMore;
  const { data: learnMoreData, isLoading: isCollaborationLearnMoreLoading } =
    useGetCollaborationLearnMore();
  const { collaborationInfo } = useCollaborationDataStore();
  const isPending = collaborationInfo.collaborationStatusId === CHALLENGE_STATUS.Pending;
  
  const handleInputChange =
    (key: keyof CollaborationLearnMore) => (e: ChangeEvent<HTMLTextAreaElement>) => {
      const value = e.target.value;
      setLearnMoreInfoItem(value, key);
    };

  useEffect(() => {
    if (learnMoreData) {
      setLearnMoreInfo(learnMoreData);
    }
  }, [learnMoreData]);

  return (
    <div>
      <div className=" flex items-center justify-between mb-6 flex-wrap pb-8 p-5 !py-3 border-b border-gray-light">
        <div className="flex items-center ltr:mr-auto rtl:ml-auto">
          <SVGIcon name="learnMore" />
          <Title
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            title={t("organization.challenge_learn_more")}
          />
        </div>
      </div>
      <div className="my-6 mx-5">
        <div className="mt-[22px] w-full">
          <TextArea
            textContent={overview ?? ""}
            handleChange={handleInputChange("overview")}
            labelText={t("research_institute.overview_of_collaboration")}
            hintText={t("research_institute.overview_of_collaboration_placeholder")}
            fieldName="address"
            maxCharLength={URL_MAX_LENGTH}
            errorMessage={validationErrors?.overview}
            isRequired
            isDisabled={isPending}
          />
        </div>
        <div className="mt-[22px] w-full">
          <TextArea
            textContent={description ?? ""}
            handleChange={handleInputChange("description")}
            labelText={t("research_institute.detailed_description_of_collaboration")}
            hintText={t("research_institute.detailed_description_of_collaboration_placeholder")}
            fieldName="address"
            maxCharLength={URL_MAX_LENGTH}
            errorMessage={validationErrors?.description}
            isRequired
            isDisabled={isPending}
          />
        </div>
        <div className="mt-[22px] w-full">
          <TextArea
            textContent={resources ?? ""}
            handleChange={handleInputChange("resources")}
            labelText={t("research_institute.resources_and_constraints")}
            hintText={t("research_institute.resources_and_constraints_placeholder")}
            fieldName="address"
            maxCharLength={URL_MAX_LENGTH}
            errorMessage={validationErrors?.resources}
            isRequired
            isDisabled={isPending}
          />
        </div>
        <div className="mt-[22px] w-full">
          <TextArea
            textContent={targets ?? ""}
            handleChange={handleInputChange("targets")}
            labelText={t("research_institute.desired_collaboration_outcome")}
            hintText={t("research_institute.desired_collaboration_outcome_placeholder")}
            fieldName="address"
            maxCharLength={URL_MAX_LENGTH}
            errorMessage={validationErrors?.targets}
            isRequired
            isDisabled={isPending}
          />
        </div>
        {isCollaborationLearnMoreLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
};

export default LearnMore;
