import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import {
  Title,
  DatePickerField,
  TextArea,
  InputField,
  CreatableSingleSelect,
  LoaderModal,
} from "components";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import CoverImageArea from "features/Organization/Challenge/CreateChallenge/CoverImageArea";
import { showConfirmationModal, showEventFileUploadModal } from "utils/confirmationModals";
import {
  setEventDetailsInfo,
  setEventDetailsInfoItem,
  useEventDataStore,
} from "store/useEventDataStore";
import {
  EMAIL_MAX_CHAR_LENGTH,
  GENERAL_DATA_MAX_LENGTH,
  PAGE_LIMIT,
  INITIAL_SEARCH_PARAMS,
  URL_MAX_LENGTH,
  PROFILE_DESCRIPTION_MAX_CHAR_LENGTH,
} from "utils/constants";
import { useGetAbout } from "features/Events/api/useGetAbout";

type EventDetailsProps = {
  validationErrors: Record<string, string> | null;
};

const About = ({ validationErrors }: EventDetailsProps) => {
  const { t } = useTranslation();
  const { detailsInfo } = useEventDataStore();

  const { data: eventAbout, isLoading: isEventAboutLoading } = useGetAbout(detailsInfo.id);

  const handleProfileClick = async () => {
    await showEventFileUploadModal(
      function (url: string) {
        setEventDetailsInfoItem(url, "coverImage");
      },
      true,
      detailsInfo.id
    );
  };

  const handleDeleteClick = async () => {
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.deletion_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    setEventDetailsInfoItem("", "coverImage");
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    key: keyof EventDetails
  ) => {
    const value = event?.target?.value;
    setEventDetailsInfoItem(value, key);
  };

  const handleDateInputChange = (key: keyof EventDetails) => (date: string) => {
    setEventDetailsInfoItem(date, key);
  };

  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);

  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  useEffect(() => {
    if (eventAbout) {
      setEventDetailsInfo(eventAbout);
    }
  }, [eventAbout]);

  useEffect(() => {
    searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
  }, [detailsInfo]);

  return (
    <div>
      <div className="bg-white rounded-md">
        <div className=" p-4 lg:p-5 flex items-center border-b border-gray-light">
          <SVGIcon name="personalDetails" />
          <Title
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            title={t("organization.about")}
          />
        </div>
        <div className="px-5 bg-white">
          <div className=" flex flex-col pt-6 justify-center rounded items-center mb-[34px] ">
            <Title
              className="  text-primary-light text-sm font-normal text-left w-full pb-2"
              title={t("organization.cover_image")}
            />
            <CoverImageArea
              errorMessage={""}
              url={detailsInfo?.coverImage ?? ""}
              handleClick={handleProfileClick}
              handleDeleteClick={handleDeleteClick}
            />
            <div className="mt-[30px] w-full h-full">
              <InputField
                hintText={t("admin.enter_event_title")}
                labelText={t("global.title")}
                value={detailsInfo?.title}
                handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(event, "title");
                }}
                fieldName="title"
                isRequired
                errorMessage={validationErrors?.title}
                maxCharLength={GENERAL_DATA_MAX_LENGTH}
              />
            </div>
            <div className="flex w-full gap-3 mt-[22px] targetIndustry">
              <CreatableSingleSelect
                className="text-sm font-normal flex-grow w-full"
                labelText={t("organization.target_industry")}
                hintText={t("organization.target_industry_placeholder")}
                selectedOptions={Array(detailsInfo?.targetIndustry) as OptionItem[]}
                setSelectedOptions={function (option: OptionItem | null): void {
                  if (option) {
                    setEventDetailsInfoItem(option, "targetIndustry");
                  }
                  searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
                }}
                addNew
                errorToastMessage="global.create_target_industry_min_character"
                createLabel={"global.new_target_industry"}
                loadOptions={loadResearchAreaOptions}
                errorMessage={validationErrors?.targetIndustry}
                resetKey={Math.random().toString()}
                onMenuOpen={() => {
                  searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
                }}
              />
              <InputField
                hintText={t("admin.enter_event_location")}
                labelText={t("organization.location")}
                handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(event, "location");
                }}
                fieldName="location"
                isRequired
                errorMessage={validationErrors?.location}
                maxCharLength={EMAIL_MAX_CHAR_LENGTH}
                className=" w-full !h-12 flex-grow-0"
                value={detailsInfo?.location}
              />
            </div>
            <div className="flex w-full gap-3 mt-[22px]">
              <div className="w-full flex-grow">
                <DatePickerField
                  labelText={t("global.start_date")}
                  isRequired
                  selectedDate={detailsInfo?.startDate}
                  setSelectedDate={handleDateInputChange("startDate")}
                  errorMessage={validationErrors?.startDate}
                  showFutureDate={true}
                  isDisabled={false}
                  maxDate={detailsInfo?.endDate}
                />
              </div>
              <div className="w-full flex-grow">
                <DatePickerField
                  labelText={t("global.end_date")}
                  isRequired
                  selectedDate={detailsInfo?.endDate}
                  setSelectedDate={handleDateInputChange("endDate")}
                  errorMessage={validationErrors?.endDate}
                  showFutureDate={true}
                  isDisabled={false}
                  minDate={detailsInfo?.startDate}
                />
              </div>
            </div>
            <div className="w-full flex-grow mt-[22px]">
              <InputField
                value={detailsInfo?.websiteUrl}
                hintText={t("global.enter_website_url")}
                labelText={t("global.website_url")}
                handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(event, "websiteUrl");
                }}
                fieldName="websiteUrl"
                maxCharLength={URL_MAX_LENGTH}
                errorMessage={validationErrors?.websiteUrl}
                tooltipContent={[t("global.website_url_tooltip_message")]}
              />
            </div>
            <div className="mt-[22px] w-full">
              <TextArea
                textContent={detailsInfo?.eventDescription}
                handleChange={(event) => handleInputChange(event, "eventDescription")}
                labelText={t("admin.event_description")}
                hintText={t("admin.enter_event_summary")}
                fieldName="eventDescription"
                maxCharLength={PROFILE_DESCRIPTION_MAX_CHAR_LENGTH}
                errorMessage={validationErrors?.eventDescription}
                isDisabled={false}
              />
            </div>
          </div>
        </div>
        {isEventAboutLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
};

export default About;
