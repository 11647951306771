import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { Title, Divider, Label, UploadedFileItem, LoaderModal } from "components";
import profileAvatar from "assets/images/avatar.svg";
import ReplyOptions from "features/DiscussionForum/Posts/Post/ReplyOptions";
import { convertJSONStringAsHtml, getTimeDifference } from "utils/helpers";
import { showConfirmationModal } from "utils/confirmationModals";
import ReplyView from "features/DiscussionForum/Posts/ReplyView";
import Reply from "features/DiscussionForum/Posts/Reply";
import { useDeletePost } from "features/DiscussionForum/api/useDeletePost";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { useOutsideClick } from "hooks/useOutsideClick";
import dayjs from "dayjs";
import { useUserDataStore } from "store/useUserDataStore";
import { CHALLENGE_STATUS } from "utils/constants";

type PostProps = {
  data: PostDetails;
  challengeId: number;
  isFirst: boolean;
  challengeData: ChallengeBasicDetails | undefined;
};

export default function Post({ data, challengeId, isFirst, challengeData }: PostProps) {
  const { t, i18n } = useTranslation();
  const { isOpen, setIsOpen, dropDownRef } = useOutsideClick();

  const { mutateAsync: deletePost, isLoading } = useDeletePost();
  const [showReplyPopup, setShowReplyPopup] = useState(false);
  const [showReplyOption, setShowReplyOption] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [isPrivateReply, setIsPrivateReply] = useState(false);

  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;

  const handleReplyOption = () => {
    setShowReplyOption(true);
    setShowReplyPopup(false);
  };

  const handlePrivateReply = () => {
    setIsPrivateReply(true);
    setShowReplyOption(true);
    setShowReplyPopup(false);
  };

  const handlePostDelete = async () => {
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "forum.post_delete_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      try {
        const result = await deletePost({ challengeId, postId: data?.id });
        showSuccessToastMessage({ message: result?.message });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };

  const [currentCount, setCount] = useState(0);
  const timer = () => {
    const date = dayjs.utc(data?.createdOn);
    const now = dayjs().utc();
    const timeDiff = now.diff(date);
    const oneMinute = 6;
    if (timeDiff < oneMinute) {
      setCount(currentCount + 1);
    }
  };

  useEffect(() => {
    if (currentCount < 0) {
      return;
    }
    let id: NodeJS.Timer;
    if (currentCount <= 100) {
      id = setInterval(timer, 100);
    }
    return () => clearInterval(id);
  }, [currentCount]);

  return (
    <>
      <div
        className={`flex flex-col w-full h-full items-center rounded-md justify-start bg-white mb-4 border border-white ${
          currentCount > 0 && currentCount <= 100 && isFirst ? "activeCard" : ""
        }`}
      >
        <div className="w-[calc(100%-2px)] bg-white rounded-md my-4 mb-0 chatReplay">
          <div className="w-full p-6">
            <div className="flex items-center justify-between">
              <div className="flex gap-2 items-center w-full">
                <img
                  alt="profile picture"
                  className="h-10 w-10 rounded-full"
                  src={data?.profileAvatarUrl ? data?.profileAvatarUrl : profileAvatar}
                />
                <div className="flex flex-col truncate w-full ">
                  <div className="flex items-center">
                    <Title
                      title={data?.userName}
                      className="font-semibold text-sm text-black truncate dirRTL_LTR"
                    />
                    {data?.isAdvisor ? (
                      <div className=" h-[20px] flex items-center px-3 ltr:!ml-1 rtl:!mr-1 rounded-xl text-xs text-white font-normal bg-primary-medium ">
                        {t("global.advisor")}
                      </div>
                    ) : null}
                  </div>
                  <Label
                    text={getTimeDifference(data?.createdOn, i18n.language, t)}
                    className="font-normal text-xs text-gray-107 "
                  />
                </div>
              </div>
            </div>
            <div>
              <p
                className="my-4 text-gray-109 font-normal text-sm pr-10 dangerously-set-html wB_BW"
                dangerouslySetInnerHTML={{
                  __html: convertJSONStringAsHtml(data?.post),
                }}
              />
            </div>
            {data?.attachments && data?.attachments?.length > 0 ? (
              <div>
                <Title
                  title={t("global.attachments")}
                  className="text-sm text-primary-light font-normal mb-1"
                />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2.5 w-full">
                  {data?.attachments.map((attaches) => (
                    <UploadedFileItem key={attaches.name + new Date()} isPreview file={attaches} />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          <Divider className="text-[#E8F1F6]" />
          <div className=" px-3 lg:px-6 py-4">
            <>
              <div className="flex items-center justify-between space-x-1 rtl:space-x-reverse">
                {data?.totalReplies ? (
                  <div className="flex items-center gap-4">
                    {!showReplies ? (
                      <div
                        className="text-gradient-blueDark flex gap-2 items-center"
                        onClick={() => setShowReplies(!showReplies)}
                      >
                        <SVGIcon name="replies" />
                        <span className="underline text-sm font-normal cursor-pointer">
                          {data?.totalReplies > 1
                            ? `${data?.totalReplies} ${t("forum.replies")}`
                            : `${data?.totalReplies} ${t("forum.reply")}`}
                        </span>
                      </div>
                    ) : null}
                    {showReplies ? (
                      <button
                        className="flex items-center close-replies-button gap-[2px] md:gap-[6px]"
                        onClick={() => setShowReplies(!showReplies)}
                      >
                        <SVGIcon name="close" />
                        <span className="text-primary-medium text-[14px] font-normal leading-[20px] underline">
                          {t("forum.close_replies")}
                        </span>
                      </button>
                    ) : null}
                  </div>
                ) : null}
                <div className="relative ltr:ml-auto rtl:mr-auto flex items-center">
                  {data?.canDelete ? (
                    <button
                      onClick={handlePostDelete}
                      className="border border-gradient-pinkDark md:space-x-2 rtl:space-x-reverse text-gradient-pinkDark rounded-md flex justify-center items-center h-[40px] hover:bg-red-dark hover:text-white hover:border-white px-1.5  md:px-4 text-xs md:text-sm ltr:mr-2.5 rtl:ml-2.5"
                    >
                      <span className="hidden md:block">
                        <SVGIcon name="trashV2" />
                      </span>
                      <span className="text-sm font-medium">{t("forum.delete_post")}</span>
                    </button>
                  ) : null}
                  {data?.userId === userId &&
                  challengeData?.isDiscussionForumEligible &&
                  challengeData.challengeStatusId !== CHALLENGE_STATUS.Disable &&
                  challengeData.challengeStatusId !== CHALLENGE_STATUS.Close ? (
                    <button
                      onClick={handleReplyOption}
                      className="border border-gradient-blueDark md:space-x-2 rtl:space-x-reverse text-gradient-blueDark rounded-md flex justify-center items-center h-[40px] hover:bg-secondary-medium hover:text-white hover:border-white px-1.5  md:px-4 text-xs md:text-sm"
                    >
                      <span className="hidden md:block">
                        <SVGIcon name="replies" />
                      </span>
                      <span className="text-sm font-medium">{t("forum.post_reply")}</span>
                    </button>
                  ) : null}
                  {!showReplyOption &&
                  data?.userId !== userId &&
                  challengeData?.isDiscussionForumEligible &&
                  challengeData.challengeStatusId !== CHALLENGE_STATUS.Disable &&
                  challengeData.challengeStatusId !== CHALLENGE_STATUS.Close ? (
                    <div
                      className="border border-gradient-blueDark text-gradient-blueDark rounded-md flex justify-center items-center h-[40px]"
                      onClick={() => {
                        setShowReplyPopup(true);
                        setIsOpen(!isOpen);
                      }}
                      ref={dropDownRef}
                    >
                      <button className="  font-medium hover:bg-gradient-blueDark hover:text-white px-1.5  md:px-4 text-xs md:text-sm h-full">
                        {t("forum.post_reply")}
                      </button>
                      <div className="w-[1px] bg-gradient-blueDark h-full"></div>
                      <div className="h-full hover:bg-gradient-blueDark hover:text-white flex justify-center items-center cursor-pointer">
                        <button className="px-4">
                          <SVGIcon name={`${isOpen ? "upArrow" : "downArrow"}`} />
                        </button>
                      </div>
                    </div>
                  ) : null}
                  {showReplyPopup ? (
                    <ReplyOptions
                      handleReply={handleReplyOption}
                      handlePrivateReply={handlePrivateReply}
                      isOpen={isOpen}
                    />
                  ) : null}
                </div>
              </div>
              {showReplyOption ? (
                <Reply
                  userName={data?.userName}
                  challengeId={challengeId}
                  postId={data?.id}
                  hideReplyOption={() => {
                    setShowReplyOption(false);
                    setIsPrivateReply(false);
                  }}
                  isPrivateReply={isPrivateReply}
                />
              ) : null}
              {data?.totalReplies > 0 && showReplies ? (
                <ReplyView challengeId={challengeId} postId={data?.id} />
              ) : null}
            </>
          </div>
        </div>
      </div>
      {isLoading ? <LoaderModal /> : null}
    </>
  );
}
