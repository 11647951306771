import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getViewFundingInterest(challengeId: number, interestId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.viewFundingInterest(challengeId, interestId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res?.data?.data as ViewFundingInterest | ApiError;
}

export function useGetViewFundingInterest(challengeId: number, interestId: number) {
  return useQuery(["view-funding-interest", challengeId, interestId], async () => {
    if (challengeId > 0 && interestId > 0) {
      const result = await getViewFundingInterest(challengeId, interestId);
      return result;
    } else {
      return null;
    }
  });
}
