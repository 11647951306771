import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveCollaborationAbout(collaborationFormData: CollaborationAbout) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  let response;

  if (collaborationFormData?.id) {
    response = await apiClient.put(urls.collaboration(), collaborationFormData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    response = await apiClient.post(urls.collaboration(), collaborationFormData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  return response?.data as ApiStatus | ApiError;
}

export function useSaveCollaborationAbout() {
  const queryClient = useQueryClient();

  return useMutation(async (formData: CollaborationAbout) => {
    const result = await saveCollaborationAbout(formData);
    queryClient.invalidateQueries(["collaboration-about", formData.id]);
    queryClient.invalidateQueries(["collaboration-preview-details", formData.id]);
    return result;
  });
}
