import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function manageAutoApproval(formData: ResearchInstituteAutoApproval, userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  return await apiClient.put(urls.researchInstituteAutoApproval(userId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function useManageAutoApproval() {
  const qc = useQueryClient();
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;

  return useMutation(async (formData: ResearchInstituteAutoApproval) => {
    if (userId) {
      const result = await manageAutoApproval(formData, userId);
      qc.invalidateQueries({ queryKey: ["auto-approval-info"] });
      return result;
    }
  });
}
