import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Select, {
  FormatOptionLabelMeta,
  GroupBase,
  OptionsOrGroups,
  PropsValue,
  SingleValue,
} from "react-select";
import {
  UseAsyncPaginateParams,
  withAsyncPaginate,
  ComponentProps,
} from "react-select-async-paginate";
import { CreatableProps } from "react-select/creatable";

type Option = OptionItem;

type AsyncPaginateCreatableProps<
  Option,
  Group extends GroupBase<Option>,
  Additional,
  IsMulti extends boolean
> = CreatableProps<Option, IsMulti, Group> &
  UseAsyncPaginateParams<Option, Group, Additional> &
  ComponentProps<Option, Group, IsMulti>;

type AsyncPaginateCreatableType = <
  Option,
  Group extends GroupBase<Option>,
  Additional,
  IsMulti extends boolean = false
>(
  props: AsyncPaginateCreatableProps<Option, Group, Additional, IsMulti>
) => ReactElement;

type SingleSelectProps = {
  labelText: string;
  hintText: string;
  handleChange: (value: SingleValue<Option>) => void;
  selectOption: OptionItem | null;
  isRequired?: boolean;
  isDisabled?: boolean;
  errorMessage?: string | null;
  className?: string;
  setSelectedOption: (options: OptionItem | null) => void;
  loadOptions: (
    search: string,
    prevOptions: OptionsOrGroups<Option, GroupBase<Option>>,
    additional?: { page: number }
  ) => Promise<{
    options: Option[];
    hasMore: boolean;
  }>;
  createLabel?: string;
};

const SingleSelect = withAsyncPaginate(Select) as AsyncPaginateCreatableType;

export function SingleSelectWithPagination({
  labelText,
  hintText,
  handleChange,
  selectOption,
  isRequired,
  isDisabled,
  errorMessage,
  className,
  loadOptions,
  createLabel,
}: SingleSelectProps) {
  const { t, i18n } = useTranslation();

  const NoOptionsMessage = () => {
    return <div>{t("global.no_results")}</div>;
  };

  return (
    <div className={className}>
      <label className="label p-0 pb-2">
        <span className="label-text text-primary-light text-sm font-normal leading-4 whitespace-nowrap">
          {labelText} {isRequired ? <span className=" ltr:mr-auto rtl:ml-auto text-red-100">*</span> : null}
        </span>
      </label>
      <SingleSelect
        placeholder={hintText}
        classNamePrefix={`customSelect truncate ${errorMessage ? "customSelectError" : "customSelect"
          }`}
        onChange={(newValue) => {
          if (newValue) handleChange(newValue);
        }}
        value={selectOption as PropsValue<Option>}
        isDisabled={isDisabled}
        noOptionsMessage={NoOptionsMessage}
        loadOptions={loadOptions}
        formatOptionLabel={(data: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) =>
          i18n.language === "ar"
            ? data?.name?.ar
            : data?.name?.en
              ? data?.name?.en
              : createLabel
                ? `${formatOptionLabelMeta.inputValue} (${t(createLabel)})`
                : ""
        }
        getOptionValue={(option: OptionItem) => {
          return option?.id as string;
        }}
      />
    </div>
  );
}
