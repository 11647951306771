import { Title } from "components";

type ImageProps = {
  url: string;
  fileName: string;
};

export default function Image({ url, fileName }: ImageProps) {
  return (
    <div className=" w-full">
      <Title
        className="text-base font-medium text-black p-5 px-4 ltr:pr-16 rtl:pl-16 leading-normal ltr:text-left rtl:text-right border-b border-gray-light truncate titleTextTruncate max-w-[calc(100vw-24px)]"
        title={fileName}
      />
      <div className="max-h-[calc(100vh-320px)] justify-center flex items-center flex-col">
        <img className="object-contain" src={url} alt={fileName} />
      </div>
    </div>
  );
}
