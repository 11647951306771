import React, { useState, useMemo, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import Profile from "assets/images/avatar.svg";
import linkedin from "assets/images/linkedin.png";
import researchGate from "assets/images/researchGate.png";
import googleScholar from "assets/images/googleScholar.png";
import {
  SubTitle,
  Chip,
  Title,
  TextButton,
  Descriptor,
  Divider,
  ExternalURLLink,
  ProfilePicture,
  Popup,
  UploadedFileItem,
  LoaderModal,
  Label,
  Header,
} from "components";
import { showConfirmationModal } from "utils/confirmationModals";
import { useMasterDataStore } from "store/useMasterDataStore";
import {
  ToasterIconType,
  showErrorToastMessage,
  showSuccessToastMessage,
} from "utils/toasterMessage";
import {
  CHALLENGE_USER_STATUS,
  CHALLENGE_STATUS,
  DETAILS_DISPLAY_MAX_CHAR_LENGTH,
  RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS,
  RESEARCH_SCHOLAR_CHALLENGE_REQUEST_STATUS,
} from "utils/constants";
import {
  calculateAge,
  displayDateFormat,
  getCountryNameById,
  getMasterDataValue,
  handleLinkClick,
  scrollToReference,
} from "utils/helpers";
import Navigator from "features/Admin/Users/Navigator";
import { useGetResearchScholarDetails } from "features/Admin/api/useGetResearchScholarDetails";
import { useManageScholarRequest } from "features/Organization/api/useManageScholarRequest";
import { useGetUserChallengeStatus } from "features/Organization/api/useGetUserChallengeStatus";
import { useStickyScroll } from "hooks/useStickyScroll";
import { useUserDataStore } from "store/useUserDataStore";
import { setSelectedSideMenu } from "store/useSidebarStore";

const ResearchScholarPreview = () => {
  const { challengeId, researchScholarId } = useParams();
  const { userInfo } = useUserDataStore();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { scroll } = useStickyScroll();
  const personalDetailsRef = useRef(null);
  const educationRef = useRef(null);
  const workRef = useRef(null);
  const socialRef = useRef(null);
  const researchProjectRef = useRef(null);
  const { permissions } = useMasterDataStore();

  const { masterData, countryCode, title } = useMasterDataStore();

  const challengeDataId = Number(challengeId);
  const userStatusRequestParams = {
    challengeId: challengeDataId ?? "",
    userId: researchScholarId ?? "",
  };

  const { data: userData, isLoading: userDataLoading } = useGetResearchScholarDetails(
    researchScholarId ?? "",
    challengeDataId
  );

  const { data: userStatus, isLoading: userStatusLoading } =
    useGetUserChallengeStatus(userStatusRequestParams);
  const { mutateAsync: manageRequest, isLoading: isRequestLoading } = useManageScholarRequest();
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const [isViewMore, setIsViewMore] = useState<boolean>(false);

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const getBreadCrumbTitle = () => {
    if (userStatus?.statusId === CHALLENGE_USER_STATUS.PendingApproval) {
      return "global.pending_approval";
    } else if (userStatus?.statusId === CHALLENGE_USER_STATUS.Approved) {
      return "global.accepted_scholars";
    } else {
      return "";
    }
  };

  const linkList: Array<LinkItem> = [
    {
      title: location.pathname.includes("explore")
        ? "organization.explore_challenge"
        : "admin.challenges",
    },
    {
      title: "organization.view_challenge",
    },

    {
      title: getBreadCrumbTitle(),
    },
  ];

  const handleDecline = async () => {
    try {
      const result = await manageRequest({
        challengeId: challengeDataId,
        scholarId: researchScholarId ?? "",
        status: RESEARCH_SCHOLAR_CHALLENGE_REQUEST_STATUS.Declined,
      });
      if (result) {
        navigate(-1);
      }
      showErrorToastMessage({
        message: result.message,
        iconType: ToasterIconType.DECLINED,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };
  const handleApprove = async () => {
    try {
      const result = await manageRequest({
        challengeId: challengeDataId,
        scholarId: researchScholarId ?? "",
        status: RESEARCH_SCHOLAR_CHALLENGE_REQUEST_STATUS.Approved,
      });
      if (result) {
        navigate(-1);
      }
      showSuccessToastMessage({
        message: result.message,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const handleDelete = async () => {
    const result = await showConfirmationModal({
      title: "global.remove_user",
      message: "organization.remove_user_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.remove",
      modalIcon: "disableUser",
    });
    if (!result) {
      return;
    } else {
      try {
        const result = await manageRequest({
          challengeId: challengeDataId,
          scholarId: researchScholarId ?? "",
          status: RESEARCH_SCHOLAR_CHALLENGE_REQUEST_STATUS.Remove,
        });
        if (result) {
          navigate(-1);
        }
        showSuccessToastMessage({
          message: result.message,
        });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };

  const settingsItems = useMemo(() => {
    return [{ icon: "deleteUser", label: "global.remove_user", type: "delete" }];
  }, []);

  const selectedTitle = useMemo(() => {
    return title?.filter((item) => item.id == userData?.userprofile?.titleId)?.[0];
  }, [userData, title]);

  const selectedNationality = useMemo(() => {
    return masterData?.nationality?.filter(
      (item) => item.id == userData?.userprofile?.nationalityId
    )?.[0];
  }, [masterData, userData]);

  const selectedGender = useMemo(() => {
    return masterData?.gender?.filter((item) => item.id == userData?.userprofile?.genderId)?.[0];
  }, [masterData, userData]);

  const selectedCountryCode = useMemo(() => {
    return countryCode?.filter((item) => item.id == userData?.userprofile?.countryCodeId)?.[0];
  }, [countryCode, userData]);

  const selectedCountry = useMemo(() => {
    return masterData?.country?.filter((item) => item.id == userData?.userprofile?.countryId)?.[0];
  }, [userData, masterData]);

  const selectedResearchArea = useMemo(() => {
    const lang = i18n.language;
    if (lang == "ar") {
      return userData?.userprofile?.researchArea
        ?.map((item: OptionItem) => item?.name?.ar)
        ?.join(", ");
    } else {
      return userData?.userprofile?.researchArea
        ?.map((item: OptionItem) => item?.name?.en)
        ?.join(", ");
    }
  }, [userData, i18n.language]);

  const handleSettingsMenuClick = async (type: string) => {
    if (type === "delete") {
      handleDelete();
    }
  };

  const handleViewClick = () => {
    setIsViewMore(!isViewMore);
    scrollToReference(personalDetailsRef);
  };

  const handleNavigationClick = (value: number) => {
    if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Personal) {
      scrollToReference(personalDetailsRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Education) {
      scrollToReference(educationRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Work) {
      scrollToReference(workRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Social) {
      scrollToReference(socialRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Research) {
      scrollToReference(researchProjectRef);
    }
  };

  const navigationItems: Array<RecordItem> = useMemo(() => {
    const tempItems = [
      {
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Personal,
        label: "global.personal_details",
      },
      {
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Education,
        label: "global.highest_education",
      },
      {
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Work,
        label: "global.work_experience",
      },
    ];
    if (
      userData?.usersocialprofile?.googleScholarURL ||
      userData?.usersocialprofile?.linkedInURL ||
      userData?.usersocialprofile?.researchGateURL
    ) {
      tempItems.push({
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Social,
        label: "global.social_profiles",
      });
    }
    if (userData?.researchprojects?.length) {
      tempItems.push({
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Research,
        label: "global.research_projects",
      });
    }
    return tempItems;
  }, [userData]);

  useEffect(() => {
    setSelectedSideMenu({
      type: "admin.challenges",
    });
  }, []);

  return (
    <div className={`flex  min-h-screen w-full  ${!isOpenSideMenu ? "miniSidebar" : ""}`}>
      <Header
        linkList={linkList}
        title={userStatus?.title}
        handleCollapseClick={onToggleClick}
        className={
          scroll
            ? " bg-white animate__animated animate__fadeInDown shadow-sticky !py-[26px] !h-[90px] fixed stickyHeader"
            : "fixed"
        }
      />
      <div className=" adminDetails w-full mt-[170px] mb-20 lg:mb-0 lg:mt-0 lg:pt-[118px] pb-[45px] items-baseline px-4 lg:px-10 lg:min-h-screen flex flex-col  overflow-auto ltr:pr-[30px] rtl:pl-[30px]">
        <div className="w-full flex flex-col h-full ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 ltr:lg:pr-[292px] rtl:lg:pl-[292px] space-y-3">
          {/* left section */}
          <div className="bg-white rounded-md mb-3">
            <div
              className="p-5 flex items-center border-b border-gray-light relative"
              ref={personalDetailsRef}
            >
              <SVGIcon name="contactCard" />
              <Title
                title={t("global.personal_details")}
                className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
              />
              <div className=" absolute ltr:right-[13px] rtl:left-[13px] top-[13px] flex items-center space-x-.5">
                {/* add challengeStatus node to the user status api */}
                {(userStatus?.challengeStatus === CHALLENGE_STATUS.Open ||
                  userStatus?.challengeStatus === CHALLENGE_STATUS.Live) &&
                userStatus?.statusId === CHALLENGE_USER_STATUS.Approved &&
                permissions?.Removescholars ? (
                  <div className="dropdown dropdown-bottom dropdown-end ltr:ml-[18px] rtl:mr-[18px]">
                    <label
                      tabIndex={0}
                      className="btn text-sm font-medium !border-none  text-white normal-case flex-nowrap px-0 w-[38px] min-w-[38px] py-0 flex items-center rounded-md bg-secondary-medium h-[38px] min-h-[38px] m-0 relative z-10 hover:bg-primary-medium"
                    >
                      <SVGIcon name="settings" />
                    </label>
                    <ul
                      tabIndex={0}
                      className="dropdown-content !z-0 menu w-[170px] min-w-[170px] divide-y divide-solid divide-primary-medium/10 shadow-100 text-gray-200 text-sm font-normal normal-case rounded-lg mt-2 ltr:-right-[5px] rtl:-left-[5px] rtl:right-auto py-[10px] bg-white text-left px-4 pb-0"
                    >
                      <Popup
                        items={settingsItems}
                        handleClick={(type: string) => handleSettingsMenuClick(type)}
                      />
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="px-5 py-[30px] flex">
              <div className=" min-w-[120px] max-w-[120px] rounded-md  mt-[6px]">
                <ProfilePicture
                  url={
                    userData?.userprofile?.profileActualImage
                      ? userData?.userprofile?.profileActualImage
                      : Profile
                  }
                />
              </div>
              <div className="text-section pt-5 lg:pt-0 lg:ltr:pl-[22px] lg:rtl:mr-[22px]">
                <div className=" flex items-center space-x-2 rtl:space-x-reverse mb-1 ">
                  <h3 className="text-lg font-semibold text-black WB_BW ">
                    {`${selectedTitle ? getMasterDataValue(selectedTitle, i18n.language) : ""} ${
                      userData?.userprofile?.firstName ?? ""
                    } ${userData?.userprofile?.middleName ?? ""} ${
                      userData?.userprofile?.lastName ?? ""
                    }`}
                  </h3>

                  {userData?.userprofile?.isGlobal && !userInfo?.isGlobal ? (
                    <div className=" h-[20px] flex items-center px-3 ltr:!ml-1 rtl:!mr-1 rounded-xl text-xs text-white font-normal bg-primary-medium ">
                      {t("global.global")}
                    </div>
                  ) : null}
                  {userData?.userprofile?.isAdvisor ? (
                    <div className=" h-[20px] flex items-center px-3 ltr:!ml-1 rtl:!mr-1 rounded-xl text-xs text-white font-normal bg-primary-medium ">
                      {t("global.advisor")}
                    </div>
                  ) : null}
                </div>

                <Title
                  title={userData?.userprofile?.researchInstituteName ?? ""}
                  className=" text-base font-medium text-gray-100 mb-2 -mt-1 WB_BW"
                />
                {userData?.usersocialprofile?.hIndex ? (
                  <SubTitle
                    title={
                      userData?.usersocialprofile?.hIndex
                        ? `${t("global.h_index")} : ${userData?.usersocialprofile?.hIndex}`
                        : ""
                    }
                    className=" text-gray-102 text-sm font-normal !justify-start"
                  />
                ) : null}
                {userData?.userprofile?.about ? (
                  <div className="text-black text-sm font-normal max-w-[543px] mt-2 mb-[26px] WB_BW">
                    {userData?.userprofile?.about?.length > DETAILS_DISPLAY_MAX_CHAR_LENGTH &&
                    !isViewMore ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: userData?.userprofile?.about
                            ?.slice(0, DETAILS_DISPLAY_MAX_CHAR_LENGTH)
                            .replace(/\n/g, "<br/>"),
                        }}
                      />
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: userData?.userprofile?.about.replace(/\n/g, "<br/>"),
                        }}
                      />
                    )}
                    {userData?.userprofile?.about?.length > DETAILS_DISPLAY_MAX_CHAR_LENGTH ? (
                      <TextButton
                        title={isViewMore ? "global.view_less" : "global.view_more"}
                        handleClick={handleViewClick}
                        className=" text-primary-medium font-medium underline ml-1.5 cursor-pointer"
                      />
                    ) : null}
                  </div>
                ) : null}
                <div className=" flex flex-col space-y-5">
                  <div className="flex space-x-10 rtl:space-x-reverse truncate">
                    <Descriptor
                      startIcon="marker"
                      className="!mb-0  min-w-[140px] flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse truncate"
                      title={
                        selectedNationality
                          ? getMasterDataValue(selectedNationality, i18n.language)
                          : ""
                      }
                    />
                    <Descriptor
                      startIcon="gender"
                      title={`${
                        selectedGender ? getMasterDataValue(selectedGender, i18n.language) : ""
                      }, ${
                        userData?.userprofile?.dateOfBirth
                          ? calculateAge(userData?.userprofile?.dateOfBirth)
                          : ""
                      }`}
                      className="!mb-0 flex items-baseline text-black text-sm font-normal space-x-2 WB_BW rtl:space-x-reverse "
                    />
                  </div>
                  <div className="flex space-x-10 rtl:space-x-reverse">
                    <Descriptor
                      startIcon="phone"
                      title={`${selectedCountryCode?.name?.code ?? ""} ${
                        userData?.userprofile?.contactNumber ?? ""
                      }`}
                      className="!mb-0 min-w-[140px] flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse truncate"
                    />
                    <Descriptor
                      startIcon="msg"
                      title={userData?.userprofile?.email ?? ""}
                      className=" !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
                    />
                  </div>
                  <Descriptor
                    startIcon="mapPin"
                    title={`${
                      userData?.userprofile?.address ? userData?.userprofile?.address + "," : ""
                    } ${selectedCountry ? getMasterDataValue(selectedCountry, i18n.language) : ""}`}
                    className="!mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
                  />

                  <div>
                    {selectedResearchArea ? (
                      <Descriptor
                        startIcon="location"
                        className="!mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
                        title={selectedResearchArea}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {userData?.userprofile?.fileDetails.length ? (
            <div className="flex-col bg-white w-full h-full rounded-md flex">
              <div className="p-5 flex items-center border-b border-gray-light">
                <SVGIcon name="attachments" />
                <Title
                  title={t("global.attachments")}
                  className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
                />
              </div>
              <div className=" p-5 grid grid-cols-2 gap-6 text-sm font-medium">
                {userData?.userprofile?.fileDetails?.map((item: FileDetails, index: number) => (
                  <div className="flex-1 truncate" key={index}>
                    <UploadedFileItem key={item.fileUrl + new Date()} isPreview file={item} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <div className="flex-col bg-white w-full h-full rounded-md flex" ref={educationRef}>
            <div className="p-5 flex items-center border-b border-gray-light relative">
              <SVGIcon name="highestEducation" />
              <Title
                title={t("global.highest_education")}
                className="text-black text-base font-semibold ltr:ml-[14px] rtl:mr-[14px]"
              />
              {userData?.usereducation.studyHere ? (
                <Label
                  text={t("research_scholar.still_studying")}
                  className="text-primary-medium bg-primary-500 px-[12px] !w-auto  py-[6px] text-xs font-normal rounded-[12px] absolute ltr:right-5 rtl:left-5"
                />
              ) : null}
            </div>
            {userData?.usereducation.details.map((educationInfo: HighestEducation) => (
              <div className="p-5" key={educationInfo.id}>
                <h4 className="text-sm font-medium text-black">{educationInfo.instituteName}</h4>
                <div className="flex justify-between items-center mt-[6px]">
                  <h4 className="text-sm font-normal text-gray-102 WB_BW">
                    {educationInfo.courseName}
                  </h4>
                </div>
              </div>
            ))}
          </div>
          {userData?.userwork.notWorking || userData?.userwork.details.length > 0 ? (
            <div className="flex-col bg-white w-full h-full rounded-md flex" ref={workRef}>
              <div className="p-5 flex items-center border-b border-gray-light relative">
                <div className=" flex items-center">
                  <SVGIcon name="workExperience" />
                  <Title
                    title={t("global.work_experience")}
                    className="text-black text-base font-semibold ltr:ml-[14px] rtl:mr-[14px]"
                  />
                </div>
                {userData?.userwork?.notWorking ? (
                  <Label
                    text={t("research_scholar.not_working")}
                    className="text-primary-medium bg-primary-500 !w-auto px-[12px] py-[6px] text-xs font-normal rounded-[12px] absolute ltr:right-5 rtl:left-5"
                  />
                ) : null}
              </div>

              {userData?.userwork.details.length > 0
                ? userData.userwork.details.map((workInfo: WorkExperience) => {
                    return (
                      <div className="p-5" key={workInfo.id}>
                        <h4 className="text-sm font-medium text-black">{workInfo.companyName}</h4>
                        <div className="flex justify-between items-center mt-[6px]">
                          <h4 className="text-sm font-normal text-gray-102 WB_BW">
                            {`${
                              workInfo.companyAddress ? workInfo.companyAddress + "," : ""
                            } ${getCountryNameById(
                              workInfo.companyCountryId,
                              masterData?.country ?? [],
                              i18n.language
                            )}`}
                          </h4>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          ) : null}
          {userData?.usersocialprofile.googleScholarURL ||
          userData?.usersocialprofile.linkedInURL ||
          userData?.usersocialprofile.researchGateURL ? (
            <div className="flex-col bg-white w-full h-full rounded-md flex" ref={socialRef}>
              <div className="p-5 flex items-center border-b border-gray-light">
                <SVGIcon name="socialProfiles" />
                <Title
                  title={t("global.social_profiles")}
                  className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
                />
              </div>

              <div className="p-5 grid grid-cols-3 gap-2.5 items-center ">
                {userData?.usersocialprofile?.googleScholarURL ? (
                  <ExternalURLLink
                    label="admin.google_scholar"
                    handleClick={() =>
                      handleLinkClick(userData?.usersocialprofile?.googleScholarURL)
                    }
                    imageUrl={googleScholar}
                    imageAlt="Google Scholar"
                    buttonClassName="cursor-pointer py-3 border-gray-light border px-5 rounded-md flex-1 truncate flex justify-center items-center"
                    labelClassName="text-sm font-medium px-[18px] text-black"
                  />
                ) : null}
                {userData?.usersocialprofile.linkedInURL ? (
                  <ExternalURLLink
                    label="admin.linkedin"
                    handleClick={() => handleLinkClick(userData?.usersocialprofile?.linkedInURL)}
                    imageUrl={linkedin}
                    imageAlt="linkedin"
                    buttonClassName="cursor-pointer py-3 border-gray-light border px-5 rounded-md flex-1 truncate flex justify-center items-center"
                    labelClassName="text-sm font-medium px-[18px] text-black"
                  />
                ) : null}
                {userData?.usersocialprofile?.researchGateURL ? (
                  <ExternalURLLink
                    label="admin.research_gate"
                    handleClick={() =>
                      handleLinkClick(userData?.usersocialprofile?.researchGateURL)
                    }
                    imageUrl={researchGate}
                    imageAlt="researchGate"
                    buttonClassName="cursor-pointer py-3 border-gray-light border px-5 rounded-md flex-1 truncate flex justify-center items-center"
                    labelClassName="text-sm font-medium px-[18px] text-black"
                  />
                ) : null}
              </div>
            </div>
          ) : null}
          {userData?.researchprojects?.length ? (
            <div
              className="flex-col bg-white w-full h-full rounded-md flex mb-[55px]"
              ref={researchProjectRef}
            >
              <div className="p-5 flex items-center border-b border-gray-light">
                <SVGIcon name="researchProjects" />
                <Title
                  title={t("global.research_projects")}
                  className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
                />
              </div>

              {userData?.researchprojects?.map((item: ResearchProject, index: number) => (
                <>
                  <div className="p-5 flex flex-col" key={index}>
                    <Title
                      title={item?.researchTitle ?? ""}
                      className="text-sm font-medium text-black"
                    />
                    <div className=" mt-8 flex justify-between max-w-[260px]">
                      <div className="flex flex-col gap-2">
                        <Label
                          text={t("global.start_date")}
                          className="text-primary-light font-normal text-sm"
                        />
                        <Label
                          text={item.startDate ? displayDateFormat(item.startDate) : ""}
                          className="text-primary-text font-medium text-sm"
                        />
                      </div>
                      {item?.endDate || item?.isInProgress ? (
                        <div className="flex flex-col gap-2">
                          <Label
                            text={t("global.end_date")}
                            className="text-primary-light font-normal text-sm"
                          />
                          <Label
                            text={
                              item.endDate
                                ? displayDateFormat(item.endDate)
                                : item.isInProgress
                                ? t("admin.pursuing")
                                : ""
                            }
                            className="text-primary-text font-medium text-sm"
                          />
                        </div>
                      ) : null}
                    </div>
                    {item?.areaOfExpertise?.length ? (
                      <div className="mt-8 flex flex-col gap-4">
                        <Label
                          text={t("global.selected_areas")}
                          className="text-primary-light font-normal text-sm"
                        />
                        <div className="flex gap-1 gap-y-1 flex-wrap">
                          {item.areaOfExpertise?.map((value, index) => (
                            <Chip
                              key={value.id ? value.id + "" : index + ""}
                              text={i18n.language === "ar" ? value.name.ar : value.name.en}
                              className="!m-0"
                            />
                          ))}
                        </div>
                      </div>
                    ) : null}
                    {item.projectUrl || item.publicUrl ? (
                      <div className=" mt-8  flex gap-[45.5px]">
                        {item.projectUrl ? (
                          <ExternalURLLink
                            label="research_scholar.project_url"
                            handleClick={() => handleLinkClick(item.projectUrl)}
                            buttonClassName="flex gap-5 cursor-pointer"
                            labelClassName="text-sm font-normal text-black"
                          />
                        ) : null}
                        {item.publicUrl ? (
                          <ExternalURLLink
                            label="research_scholar.public_url"
                            handleClick={() => handleLinkClick(item.publicUrl)}
                            buttonClassName="flex gap-5 cursor-pointer"
                            labelClassName="text-sm font-normal text-black"
                          />
                        ) : null}
                      </div>
                    ) : null}

                    {item?.tags?.length ? (
                      <div className="mt-8 flex flex-col gap-4">
                        <Label
                          text={t("admin.tags")}
                          className="text-primary-light font-normal text-sm"
                        />
                        <div className="flex gap-1 gap-y-1 flex-wrap">
                          {" "}
                          {item.tags?.map((value, index) => (
                            <Chip
                              key={value.id ? value.id + "" : index + ""}
                              text={i18n.language === "ar" ? value.name.ar : value.name.en}
                              className="!m-0"
                            />
                          ))}
                        </div>
                      </div>
                    ) : null}
                    <div className="mt-8 max-w-[737px]">
                      <Label
                        text={t("global.summary")}
                        className="text-primary-light font-normal text-sm"
                      />
                      <p className="text-base font-medium text-primary-text WB_BW">
                        {item.researchBrief}
                      </p>
                    </div>
                    {item?.documents?.length ? (
                      <div className="mt-8 ">
                        <Label
                          text={t("global.attachments")}
                          className="text-primary-light font-normal text-sm"
                        />
                        <div className=" grid grid-cols-2 gap-3 mt-3">
                          {item.documents?.map((item: FileDetails, index: number) => (
                            <div className=" flex-1 truncate" key={index}>
                              <UploadedFileItem
                                key={item.fileUrl + new Date()}
                                isPreview
                                file={item}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {index != userData?.researchprojects?.length - 1 ? (
                    <Divider className="mx-5 mt-2" />
                  ) : null}
                </>
              ))}
            </div>
          ) : null}
        </div>
        <Navigator
          isButtonVisible={userStatus?.statusId === CHALLENGE_USER_STATUS.PendingApproval}
          handleDecline={handleDecline}
          handleApprove={handleApprove}
          handleNavigationClick={handleNavigationClick}
          navigationItems={navigationItems}
          scroll={scroll}
        />
        {isRequestLoading || userDataLoading || userStatusLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
};

export default ResearchScholarPreview;
