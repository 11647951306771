import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-daisyui";
import SVGIcon from "assets/Icons";

type DropDownItemProps = {
  icon: string;
  label: string;
  handleSelection: (type: string) => void;
  type: string;
  disabled?: boolean;
};

export function DropdownItem({ icon, label, type, handleSelection, disabled }: DropDownItemProps) {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => {
        handleSelection(type);
      }}
      className="bg-white gap-0  flex-nowrap border-none text-black normal-case font-normal flex items-center whitespace-nowrap text-left h-auto  ltr:justify-start rtl:justify-end space-x-2.5 rtl:space-x-reverse py-0 !px-0 min-h-0  hover:text-secondary-medium !bg-transparent rounded-none"
      disabled={disabled}
    >
      <SVGIcon name={icon} />
      <span className=" text-[13px] rtl:-order-1 ltr:order-1 rtl:!ml-auto">{t(label)}</span>
    </Button>
  );
}
