import Joi from "joi";
import { groupBy } from "utils/helpers";

export const collaborationLearnMoreSchema = Joi.object({
  overview: Joi.string().trim().messages({ "string.empty": "required" }),
  description: Joi.string().trim().messages({ "string.empty": "required" }),
  targets: Joi.string().trim().messages({ "string.empty": "required" }),
  resources: Joi.string().trim().messages({ "string.empty": "required" }),
  collaborationCompletionStatusId: Joi.number().optional(),
  collaborationStatusId: Joi.number().optional(),
}).options({ abortEarly: false });

export const collaborationLearnMoreValidation = (
  collaborationLearnMore: CollaborationLearnMore
) => {
  const result = collaborationLearnMoreSchema.validate(collaborationLearnMore);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
