import { useTranslation } from "react-i18next";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { displayDateFormat, getColumns } from "utils/helpers";

type TableProps = {
  challengeData: Array<ChallengeHistoryData>;
  headers: Array<string>;
  path?: string;
};

const Table = ({ challengeData, headers }: TableProps) => {
  const { t } = useTranslation();

  const filteredData = challengeData?.map((item) => {
    return {
      createdOn: item.createdOn,
      fullName: item.fullName,
      userType: item.userType,
      email: item.email,
      status: item.status,
    };
  });

  const dataTable = useReactTable({
    data: challengeData,
    columns: filteredData && filteredData.length ? getColumns(filteredData) : [],

    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="customTable bg-white p-4 rounded-md">
      <table>
        <thead>
          {headers?.map((item) => (
            <th key={item}>{t(item)}</th>
          ))}
        </thead>
        <tbody>
          {dataTable.getRowModel().rows.map((row) => (
            <tr key={row.id} className="cursor-default">
              {row.getVisibleCells().map((cell, index) => {
                if (cell.column.columnDef.header?.toString().trim() == "createdOn") {
                  return <td key={index}>{displayDateFormat(cell.getValue() as string)}</td>;
                } else {
                  return (
                    <td key={index}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
