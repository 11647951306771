import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getUploads(organizationId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.organizationUploads(organizationId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (res.status === 400) {
    throw new Error("error");
  }
  return res.data?.data as OrganizationDocuments | ApiError;
}

export function useGetUploads(organizationId: string) {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = organizationId ? organizationId : userInfo?.userId;
  return useQuery(
    ["organization-uploads", userId],
    async () => {
      if (userId) {
        const result = await getUploads(userId);
        return result;
      } else return;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
