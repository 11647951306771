import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getChallengeBasicDetails(
  id: number,
  isExplore: boolean
): Promise<ChallengeBasicDetails | null> {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.challengeBasicDetails(id, isExplore), {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (res.status === 400) {
    throw new Error("error");
  }
  return res.data?.data as ChallengeBasicDetails | ApiError;
}

export function useGetChallengeBasicDetails(
  id: number,
  isExplore: boolean
): UseQueryResult<ChallengeBasicDetails> {
  return useQuery(["challenge-basic-details", id], async () => {
    if (id > 0) {
      const result = await getChallengeBasicDetails(id, isExplore);
      return result;
    } else return null;
  });
}
