import { useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import Header from "features/Home/Header";
import Banner from "features/Home/Banner";
import About from "features/Home/About";
import JoinUS from "features/Home/JoinUs";
import DataInsights from "features/Home/DataInsights";
import LatestChallenges from "features/Home/LatestChallenges";
import HowItWorks from "features/Home/HowItWorks";
import ContactUs from "features/Home/ContactUs";
import Footer from "features/Home/Footer";
import { scrollToReference } from "utils/helpers";
import { HOME_MENU_IDS } from "utils/constants";
import { useStickyScroll } from "hooks/useStickyScroll";
import CaseStudies from "features/Home/CaseStudies";
import NewsAndEvents from "features/Home/NewsAndEvents";

export default function Home() {
  const { scroll } = useStickyScroll();
  const aboutUsRef = useRef<HTMLDivElement | null>(null);
  const joinUsRef = useRef<HTMLDivElement | null>(null);
  const dataInsightsRef = useRef<HTMLDivElement | null>(null);
  const challengesRef = useRef<HTMLDivElement | null>(null);
  const howItWorksRef = useRef<HTMLDivElement | null>(null);
  const caseStudiesRef = useRef<HTMLDivElement | null>(null);
  const newsAndEventsRef = useRef<HTMLDivElement | null>(null);
  const contactUsRef = useRef<HTMLDivElement | null>(null);

  const [selectedMenu, setSelectedMenu] = useState(1);

  const handleMenuItemClick = (id: number) => {
    if (id === HOME_MENU_IDS.AboutUs) {
      setSelectedMenu(HOME_MENU_IDS.AboutUs);
      scrollToReference(aboutUsRef);
    } else if (id === HOME_MENU_IDS.JoinUs) {
      setSelectedMenu(HOME_MENU_IDS.JoinUs);
      scrollToReference(joinUsRef);
    } else if (id === HOME_MENU_IDS.DataInsights) {
      setSelectedMenu(HOME_MENU_IDS.DataInsights);
      scrollToReference(dataInsightsRef);
    } else if (id === HOME_MENU_IDS.Challenges) {
      setSelectedMenu(HOME_MENU_IDS.Challenges);
      scrollToReference(challengesRef);
    } else if (id === HOME_MENU_IDS.HowItWorks) {
      setSelectedMenu(HOME_MENU_IDS.HowItWorks);
      scrollToReference(howItWorksRef);
    } else if (id === HOME_MENU_IDS.CaseStudies) {
      setSelectedMenu(HOME_MENU_IDS.CaseStudies);
      scrollToReference(caseStudiesRef);
    } else if (id === HOME_MENU_IDS.NewsAndEvents) {
      setSelectedMenu(HOME_MENU_IDS.NewsAndEvents);
      scrollToReference(newsAndEventsRef);
    } else if (id === HOME_MENU_IDS.ContactUs) {
      setSelectedMenu(HOME_MENU_IDS.ContactUs);
      scrollToReference(contactUsRef);
    }
  };

  // Scroll detection
  const options = { threshold: [0.8, 0.8] };
  const { ref: aboutScrollRef } = useInView({
    onChange: (inView: boolean) => {
      if (inView) setSelectedMenu(HOME_MENU_IDS.AboutUs);
    },
    ...options,
  });

  const { ref: joinScrollRef } = useInView({
    onChange: (inView: boolean) => {
      if (inView) setSelectedMenu(HOME_MENU_IDS.JoinUs);
    },
    ...options,
  });

  const { ref: dataInsightsScrollRef, inView: dataInsightsInView } = useInView({
    onChange: (inView: boolean) => {
      if (inView) setSelectedMenu(HOME_MENU_IDS.DataInsights);
    },
    ...options,
  });

  const { ref: challengesScrollRef } = useInView({
    onChange: (inView: boolean) => {
      if (inView) setSelectedMenu(HOME_MENU_IDS.Challenges);
    },
    ...options,
  });

  const { ref: howItWorksScrollRef } = useInView({
    onChange: (inView: boolean) => {
      if (inView) setSelectedMenu(HOME_MENU_IDS.HowItWorks);
    },
    ...options,
  });

  const { ref: caseStudiesScrollRef } = useInView({
    onChange: (inView: boolean) => {
      if (inView) setSelectedMenu(HOME_MENU_IDS.CaseStudies);
    },
    ...options,
  });

  const { ref: newsAndEventsScrollRef } = useInView({
    onChange: (inView: boolean) => {
      if (inView) setSelectedMenu(HOME_MENU_IDS.NewsAndEvents);
    },
    ...options,
  });

  const { ref: contactUsScrollRef } = useInView({
    onChange: (inView: boolean) => {
      if (inView) setSelectedMenu(HOME_MENU_IDS.ContactUs);
    },
    ...options,
  });

  return (
    <div>
      {/* Header */}
      <Header
        className={scroll ? "homeStickyHeader animate__animated animate__fadeInDown" : "fixed "}
        handleClick={handleMenuItemClick}
        selectedMenu={selectedMenu}
      />

      {/* Banner */}
      <Banner />

      {/* About */}
      <About
        ref={(element) => {
          aboutUsRef.current = element;
          aboutScrollRef(element);
        }}
      />

      {/* Join Us */}
      <JoinUS
        ref={(element) => {
          joinUsRef.current = element;
          joinScrollRef(element);
        }}
        handleClick={handleMenuItemClick}
      />

      {/* Data Insights */}
      <DataInsights
        ref={(element) => {
          dataInsightsRef.current = element;
          dataInsightsScrollRef(element);
        }}
        inView={dataInsightsInView}
      />

      {/* Latest Challenges */}
      <LatestChallenges
        ref={(element) => {
          challengesRef.current = element;
          challengesScrollRef(element);
        }}
      />

      {/* How it Works */}
      <HowItWorks
        ref={(element) => {
          howItWorksRef.current = element;
          howItWorksScrollRef(element);
        }}
      />

      {/* Case Studies */}
      <CaseStudies
        ref={(element) => {
          caseStudiesRef.current = element;
          caseStudiesScrollRef(element);
        }}
      />

      {/* News and Events */}
      {/* <NewsAndEvents
        ref={(element) => {
          newsAndEventsRef.current = element;
          newsAndEventsScrollRef(element);
        }}
      /> */}

      {/* Contact us */}
      <ContactUs
        ref={(element) => {
          contactUsRef.current = element;
          contactUsScrollRef(element);
        }}
      />

      {/* Footer */}
      <Footer handleClick={handleMenuItemClick} />
    </div>
  );
}
