import { Routes, Route } from "react-router-dom";
import { PageNotFound } from "components";
import { useUserDataStore } from "store/useUserDataStore";
import { USERS } from "utils/constants";
import Login from "features/Login";
import ResetPassword from "features/ResetPassword";
import ForgotPassword from "features/ForgotPassword";
import ResearchScholarSignUp from "features/ResearchScholar/SignUp";
import OrganizationSignUp from "features/Organization/SignUp";
import FundingOrganizationSignUp from "features/FundingOrganization/SignUp";
import Home from "features/Home";
import ResearchScholarRoutes from "router/ResearchScholarRoutes";
import AdminRoutes from "router/AdminRoutes";
import OrganizationRoutes from "router/OrganizationRoutes";
import FundingOrganizationRoutes from "router/FundingOrganizationRoutes";
import ResearchInstituteRoutes from "router/ResearchInstituteRoutes";

export default function Router() {
  const { userInfo } = useUserDataStore();
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route path="/" element={<Home />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/challenges/:challengeId" element={<Login />} />
      
      {/* Routes for challenge/collaboration share feature */}
      {!userInfo ? (
        <>
          <Route path="/research-scholar/challenges/:challengeId" element={<Login />} />
          <Route path="/research-scholar/home/:challengeId" element={<Login />} />
          <Route path="/admin/challenges/:challengeId" element={<Login />} />
          <Route path="/admin/dashboard/:challengeId" element={<Login />} />
          <Route path="/funding-organization/challenges/:challengeId" element={<Login />} />
          <Route path="/organization/challenges/explore/:challengeId" element={<Login />} />
          <Route path="/organization/challenges/:challengeId" element={<Login />} />
          <Route path="/research-institute/challenges/:challengeId" element={<Login />} />
          <Route path="/research-scholar/collaborations/:collaborationId" element={<Login />} />
          <Route path="/organization/collaborations/:collaborationId" element={<Login />} />
          <Route
            path="/research-institute/collaborations/explore/:collaborationId"
            element={<Login />}
          />
          <Route path="/research-institute/collaborations/:collaborationId" element={<Login />} />
        </>
      ) : null}

      {/* Research Scholar */}
      <Route path="/research-scholar/sign-up" element={<ResearchScholarSignUp />} />
      {userInfo?.userType === USERS.ResearchScholar ? (
        <Route path="research-scholar/*" element={<ResearchScholarRoutes />} />
      ) : null}

      {/* Admin */}
      {userInfo?.userType === USERS.Admin ? (
        <Route path="admin/*" element={<AdminRoutes />} />
      ) : null}

      {/* Organization */}
      <Route path="/organization/sign-up" element={<OrganizationSignUp />} />
      {userInfo?.userType === USERS.Organization ? (
        <Route path="organization/*" element={<OrganizationRoutes />} />
      ) : null}

      {/*Funding Organization*/}
      <Route path="/funding-organization/sign-up" element={<FundingOrganizationSignUp />} />
      {userInfo?.userType === USERS.Funding ? (
        <Route path="funding-organization/*" element={<FundingOrganizationRoutes />} />
      ) : null}

      {/* Research Institute */}
      {userInfo?.userType === USERS.ResearchInstitute ? (
        <Route path="research-institute/*" element={<ResearchInstituteRoutes />} />
      ) : null}
    </Routes>
  );
}
