import { useState, useEffect, useRef } from "react";

const useLoadImage = (): [React.RefObject<HTMLImageElement>, boolean, () => void] => {
  const [isLoaded, setLoaded] = useState(false);
  const ref = useRef<HTMLImageElement>(null);

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad();
    }
  }, []);

  return [ref, isLoaded, onLoad];
};

export default useLoadImage;
