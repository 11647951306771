import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function deleteCollaborationFile(formData: FileDetails) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(urls.collaborationDeleteUpload(), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response?.data as ApiStatus | ApiError;
}

export function useDeleteUpload() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;

  return useMutation(async ({ formData }: { formData: FileDetails }) => {
    if (userId) {
      const result = await deleteCollaborationFile(formData);
      return result;
    }
  });
}
