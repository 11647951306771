import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { Chip, Title, MultiSelect, LoaderModal } from "components";
import { PAGE_LIMIT, INITIAL_SEARCH_PARAMS, CHALLENGE_STATUS } from "utils/constants";
import { setChallengeTags, useChallengeStore } from "store/useChallengeStore";
import { getTags } from "features/ResearchScholar/api/useGetTag";
import { useGetChallengeTags } from "features/Organization/api/useGetChallengeTags";

const Tags = () => {
  const { data: tagsData, isLoading: isTagsLoading } = useGetChallengeTags();
  const { t, i18n } = useTranslation();
  const { challengeTags, challengeInfo } = useChallengeStore();
  const tagSearchParamsRef = useRef(INITIAL_SEARCH_PARAMS);

  async function loadTagOptions(searchKeyword: string) {
    let pageNumber = tagSearchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== tagSearchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getTags({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    tagSearchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }
  const handleDeleteTags = (index: number) => {
    if (challengeTags) {
      const filteredContacts = [
        ...challengeTags.slice(0, index),
        ...challengeTags.slice(index + 1),
      ];
      setChallengeTags(filteredContacts);
      tagSearchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
    }
  };
  useEffect(() => {
    if (tagsData) {
      setChallengeTags(tagsData);
    }
    tagSearchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
  }, [tagsData]);

  return (
    <div className="p-4">
      <div className="flex items-center border-b border-gray-light">
        <SVGIcon name="tags" />
        <Title
          className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
          title={t("organization.tags")}
        />
      </div>

      <div>
        <MultiSelect
          labelText={""}
          hintText={t("global.search")}
          setSelectedOptions={(options: Array<OptionItem> | null) => {
            setChallengeTags(options);
            tagSearchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
          }}
          selectedOptions={challengeTags || []}
          isDisabled={challengeInfo?.challengeStatusId === CHALLENGE_STATUS.Pending}
          addNew
          errorToastMessage="global.create_tag_min_error_message"
          createLabel={"research_scholar.new_tag"}
          resetKey={Math.random().toString()}
          loadOptions={loadTagOptions}
        />
      </div>
      <div>
        <div className=" flex  flex-wrap mt-5 p-1">
          {challengeTags?.map((item, index) => (
            <Chip
              key={item.id}
              text={i18n.language === "ar" ? item.name.ar : item.name.en}
              handleClose={() => {
                handleDeleteTags(index);
              }}
              disabled={challengeInfo?.challengeStatusId === CHALLENGE_STATUS.Pending}
            />
          ))}
        </div>
      </div>
      {isTagsLoading ? <LoaderModal /> : null}
    </div>
  );
};

export default Tags;
