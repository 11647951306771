import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStickyScroll } from "hooks/useStickyScroll";
import { CreatePost } from "features/DiscussionForum/Posts/CreatePost";
import ForumHeader from "features/DiscussionForum/ForumHeader";
import Posts from "features/DiscussionForum/Posts";
import { useGetChallengeBasicDetails } from "features/Organization/api/useGetChallengeBasicDetails";
import { LoaderModal, Header } from "components";
import { useMasterDataStore } from "store/useMasterDataStore";
import { useUserDataStore } from "store/useUserDataStore";
import { setSelectedSideMenu } from "store/useSidebarStore";
import { USERS } from "utils/constants";

export default function DiscussionForum() {
  const { challengeId } = useParams();
  const pathname = window.location.pathname;
  const isExplore = pathname.includes("explore");
  const isSearchResult = pathname.includes("search-results");
  const { scroll } = useStickyScroll();
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const { permissions } = useMasterDataStore();
  const { userInfo } = useUserDataStore();

  const challengeDataId = Number(challengeId);

  const { data: challengeData, isLoading } = useGetChallengeBasicDetails(
    challengeDataId,
    isExplore
  );

  const getBreadCrumbPath = () => {
    const breadCrumb: Array<LinkItem> = [];

    if (isSearchResult) {
      return breadCrumb;
    }

    if (pathname.includes("invite-history")) {
      breadCrumb.push(
        {
          title: "organization.view_challenge",
        },
        {
          title: "organization.invite_history",
        }
      );
    } else {
      let title = "admin.challenges";

      if (pathname.includes("explore") && pathname.includes("discussion-forum")) {
        title = "organization.explore_challenge";
      } else if (pathname.includes("research-institute/requests")) {
        title = "research_institute.requests";
      }

      breadCrumb.push(
        {
          title,
        },
        {
          title: "organization.view_challenge",
        }
      );
    }

    breadCrumb.push({
      title: "forum.discussion_forum",
    });
    return breadCrumb;
  };

  const linkList: Array<LinkItem> = getBreadCrumbPath();

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  useEffect(() => {
    let type = "admin.challenges";
    if (pathname.includes("requests") && userInfo?.userType === USERS.ResearchInstitute) {
      type = "research_institute.requests";
    } else if (pathname.includes("research-scholar/home")) {
      type = "research_scholar.home";
    }
    setSelectedSideMenu({
      type,
    });
  }, [pathname, userInfo?.userType]);

  return (
    <>
      <div
        className={`w-full h-full flex flex-col msgDetails   ${
          !isOpenSideMenu ? "miniSidebar" : ""
        }`}
      >
        <Header
          linkList={linkList}
          title={challengeData?.title ?? ""}
          handleCollapseClick={onToggleClick}
          className={
            scroll
              ? "  bg-primary-medium lg:bg-white animate__animated animate__fadeInDown lg:shadow-sticky lg:!py-[26px] lg:!h-[90px] fixed stickyHeader"
              : "fixed bg-primary-medium lg:bg-gray-light"
          }
          displayGlobalTag={challengeData?.isGlobal && !userInfo?.isGlobal}
        />
        <div className="formDetails adminDetails w-full pt-[138px] lg:pt-[118px] pb-[45px] items-baseline px-4 lg:px-10 lg:ltr:pr-[30px] lg:rtl:pl-[30px] min-h-screen flex flex-col ">
          <div className="  lg:w-[calc(100%-290px)] w-full">
            {challengeData ? (
              <ForumHeader
                challengeData={challengeData}
                handleCreatePost={() => setShowCreatePost((prevState) => !prevState)}
                showCreatePost={!showCreatePost}
              />
            ) : null}
            <div className="chatReplayHolder">
              {showCreatePost && challengeData?.isDiscussionForumEligible ? (
                <CreatePost
                  challengeId={challengeDataId}
                  handleDiscardPost={() => setShowCreatePost((prevState) => !prevState)}
                  handlePost={() => setShowCreatePost((prevState) => !prevState)}
                />
              ) : null}

              {permissions?.Viewpost ? (
                <Posts
                  challengeId={challengeDataId}
                  challengeData={challengeData}
                  isShowCreatePost={showCreatePost}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {isLoading ? <LoaderModal /> : null}
    </>
  );
}
