import { TextButton, ProfileNavigationTimeline, DeclineButton } from "components";
import { useMasterDataStore } from "store/useMasterDataStore";
import { useUserDataStore } from "store/useUserDataStore";
import { USERS } from "utils/constants";

type NavigatorProps = {
  isButtonVisible: boolean | undefined;
  handleDeclineWithReason?: () => void;
  isDeclineWithReason?: boolean;
  handleDecline: () => void;
  handleApprove: () => void;
  handleNavigationClick: (value: number, index: number) => void;
  navigationItems: Array<RecordItem>;
  scroll: boolean;
  activeMenu?: number;
};

function Navigator({
  isButtonVisible,
  handleDeclineWithReason,
  handleDecline,
  handleApprove,
  handleNavigationClick,
  navigationItems,
  scroll,
  activeMenu,
  isDeclineWithReason,
}: NavigatorProps) {
  const { permissions } = useMasterDataStore();
  const { userInfo } = useUserDataStore();

  return (
    <div
      className={
        scroll
          ? "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto fixed top-[121px] lg:top-[100px]  ltr:lg:right-[30px] rtl:lg:left-[30px]  z-[5]  lg:shadow-sticky  animate__animated animate__fadeInDown animateNone lg:transition-all"
          : "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto fixed top-[121px] lg:top-auto ltr:lg:right-[30px] rtl:left-0 rtl:lg:left-[30px]  z-[5]"
      }
    >
      <div className=" bg-gray-light lg:bg-white lg:rounded-md pl-0 lg:pl-3 pt-0 lg:pt-4 lg:!p-[15px] lg:!pb-0">
        {isButtonVisible &&
        (permissions?.ApproveDeclinescholars || userInfo?.userType === USERS.Admin) ? (
          <div className="grid grid-cols-2 gap-[10px] pb-[30px]">
            {!isDeclineWithReason ? (
              <TextButton
                title={"global.decline"}
                handleClick={handleDecline}
                className="btn rounded-md hover:bg-primary-medium hover:text-white w-full bg-transparent border border-primary-medium text-primary-medium text-sm font-medium"
              />
            ) : (
              <DeclineButton
                handleDeclineAndRemove={handleDecline}
                handleDeclineWithReason={() => handleDeclineWithReason?.()}
              />
            )}
            <TextButton
              title={"global.approve"}
              handleClick={handleApprove}
              className="btn rounded-md w-full bg-secondary-medium border-none text-white text-sm font-medium  hover:bg-primary-medium hover:text-white"
            />
          </div>
        ) : null}
        <div>
          <ProfileNavigationTimeline
            handleClick={handleNavigationClick}
            navigatorList={navigationItems}
            isViewOnly
            activeMenu={activeMenu}
          />
        </div>
      </div>
    </div>
  );
}

export default Navigator;
