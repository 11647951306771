import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQueryClient } from "@tanstack/react-query";
import { Title, NoData, LoaderModal, InlineLoader } from "components";
import {
  CHALLENGE_STATUS,
  CHALLENGE_TYPES,
  COLLABORATION_DASHBOARD_INIT_PARAMS,
  PAGE_LIMIT,
} from "utils/constants";

import {
  useFilterDataStore,
  clearOrganizationFilterInfo,
  setScrollInfo,
} from "store/useFilterDataStore";
import { useSetScroll } from "hooks/useSetScroll";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import useResetQueryOnLanguageSwitch from "hooks/useResetQueryOnLanguageSwitch";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";
import { useGetCollaborationList } from "features/Collaboration/api/useGetCollaborationList";
import { setSelectedFormId } from "store/useSidebarStore";
import { CollaborationCard } from "components/CollaborationCard";

type CollaborationRequestProps = {
  activeFilter: number;
  isScholarHomePage: boolean;
};

export default function CollaborationRequests({
  activeFilter,
  isScholarHomePage,
}: CollaborationRequestProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { scrollInfo } = useFilterDataStore();
  const { organizationFilterInfo, challengeFilterInfo } = useFilterDataStore();
  const pathName = window.location.pathname;

  const [requestParams, setRequestParams] = useState<CollaborationListParams>({
    ...COLLABORATION_DASHBOARD_INIT_PARAMS,
    isOwned: isScholarHomePage ? true : false,
    isRequest: true,
    type: challengeFilterInfo?.activeTab ?? CHALLENGE_TYPES.All,
    status: challengeFilterInfo?.activeFilter ?? CHALLENGE_STATUS.All,
  });
  const {
    data: collaborationList,
    isLoading: collaborationListLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetCollaborationList(requestParams);

  const { scrollPosition } = useGetScrollPosition();
  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "collaboration-list");
  useResetQueryOnLanguageSwitch("collaboration-list");

  const collaborationDetails = useMemo(() => {
    const tempData: CollaborationListingResponse = {
      collaborations: [],
      totalCount: 0,
      totalPages: 0,
    };
    if (collaborationList?.pages && collaborationList?.pages[0]) {
      tempData.totalCount = collaborationList.pages[0]?.totalCount;
      tempData.totalPages = collaborationList.pages[0]?.totalPages;
      const collaborations = collaborationList.pages
        .map((item) => {
          return item.collaborations;
        })
        .flat();
      tempData.collaborations = collaborations;
    }
    return tempData;
  }, [collaborationList]);

  useEffect(() => {
    if (organizationFilterInfo?.pathName && pathName !== organizationFilterInfo?.pathName) {
      clearOrganizationFilterInfo();
    }
  }, [organizationFilterInfo?.pathName, pathName]);

  function handleCollaborationClick(index: number, collaborationId: number) {
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setSelectedFormId(collaborationId);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: location.pathname,
      index: pageParam,
    });
  }

  useEffect(() => {
    if (activeFilter !== requestParams.status) {
      queryClient.resetQueries(["collaboration-list"]);
      if (activeFilter === CHALLENGE_STATUS.All) {
        setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.All }));
      } else if (activeFilter === CHALLENGE_STATUS.Open) {
        setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Open }));
      } else if (activeFilter === CHALLENGE_STATUS.Close) {
        setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Close }));
      }
    }
  }, [activeFilter]);

  return (
    <>
      <>
        <div className="tab-content pt-6 w-full">
          <Title
            className=" text-primary-light text-sm leading-4 font-normal mb-3"
            title={`${
              collaborationList?.pages[0]?.totalCount
                ? collaborationList?.pages[0]?.totalCount
                : t("global.no")
            } ${t("organization.results_found")}`}
          />

          {collaborationDetails?.collaborations?.length > 0 ? (
            <InfiniteScroll
              dataLength={collaborationDetails?.collaborations?.length}
              next={fetchNextPage}
              hasMore={!!hasNextPage}
              loader={hasNextPage || collaborationListLoading ? <InlineLoader /> : null}
              scrollThreshold="200px"
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                {collaborationDetails.collaborations.map(
                  (item: CollaborationData, index: number) => (
                    <div key={index} className="">
                      <CollaborationCard
                        data={item}
                        showCollaborationSignature={true}
                        path={
                          isScholarHomePage
                            ? `/research-scholar/home/collaborations/${item?.id}`
                            : `/research-institute/requests/collaborations/${item?.id}`
                        }
                        handleCollaborationClick={() => handleCollaborationClick(index, item?.id)}
                      />
                    </div>
                  )
                )}
              </div>
            </InfiniteScroll>
          ) : null}
          {!collaborationListLoading && collaborationDetails?.collaborations?.length === 0 ? (
            <div>
              <NoData message={"global.no_collaborations_to_show"} />
            </div>
          ) : null}
        </div>
      </>

      {collaborationListLoading ? <LoaderModal /> : null}
    </>
  );
}
