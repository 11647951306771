import { useEffect, useState } from "react";

export function useStickyScroll(value?: number) {
  const [scroll, setScroll] = useState(false);
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const y = value ?? 150;
      setScroll(window.scrollY > y);
      setScrollValue(window.scrollY);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setScroll(false);
      });
    };
  }, []);

  return { scroll, scrollValue };
}
