import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function collaborationChangeStatus(status: number, collaborationId: number, specificReason?: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(
    urls.collaborationChangeStatus(status, collaborationId, specificReason),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus | ApiError;
}

export function useCollaborationChangeStatus() {
  const queryClient = useQueryClient();
  return useMutation(async ({ status, collaborationId, specificReason }: { status: number; collaborationId: number, specificReason?: string }) => {
    const result = await collaborationChangeStatus(status, collaborationId, specificReason);
    queryClient.invalidateQueries(["collaboration-details", collaborationId]);
    queryClient.invalidateQueries(["collaboration-basic-details", collaborationId]);
    return result;
  });
}