import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

type LanguageSelectionProps = {
  isDisabled?: boolean;
  className?: string;
};
export function LanguageSwitchButton({ isDisabled, className }: LanguageSelectionProps) {
  const { i18n } = useTranslation();

  const LANGUAGES = [
    {
      value: "ar",
      label: "عربي",
    },
    { value: "en", label: "English" },
  ];

  const handleClick = async (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className={className}>
      <Select
        options={LANGUAGES}
        classNamePrefix="customLangSelect"
        onChange={(newValue) => {
          if (newValue?.value) handleClick(newValue?.value);
        }}
        value={i18n.language === "ar" ? LANGUAGES[0] : LANGUAGES[1]}
        isDisabled={isDisabled}
      />
    </div>
  );
}
