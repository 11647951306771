import { useEffect, useState } from "react";
import { Sidebar } from "components";
import { Outlet, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
  setSelectedSideMenu,
  setSelectedUserType,
  useSidebarDataStore,
} from "store/useSidebarStore";
import { clearFilterDataStore, clearUserTypeFilter } from "store/useFilterDataStore";

export default function Admin() {
  const navigate = useNavigate();
  const { selectedSideMenu } = useSidebarDataStore();
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const pathName = window.location.pathname;
  const queryClient = useQueryClient();
  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const usersSectionPathNames = [
    "/admin/users",
    "/admin/users/research-scholars",
    "/admin/users/organizations",
    "/admin/users/funding-organization",
  ];

  const SidebarMenuItems = [
    {
      name: "global.dashboard",
      icon: "dashboard",
    },
    {
      name: "admin.users",
      icon: "users",
    },
    {
      name: "admin.challenges",
      icon: "challenge",
    },
    {
      name: "global.collaborations",
      icon: "collaborationIcon",
    },
    {
      name: "forum.forums",
      icon: "forums",
    },
    {
      name: "global.events",
      icon: "eventIcon",
    },
    {
      name: "global.settings",
      icon: "settings",
      subMenu: [
        {
          name: "admin.master",
        },
        {
          name: "global.password",
        },
        {
          name: "admin.permissions",
        },
      ],
    },
  ];

  const handleIsOpen = (type: string) => {
    if (selectedSideMenu?.type === type) {
      return !selectedSideMenu.isOpen;
    } else {
      return true;
    }
  };

  const handleMenuClick = (type: string) => {
    clearFilterDataStore();
    clearUserTypeFilter();
    setSelectedUserType();
    if (type === "global.dashboard") {
      queryClient.resetQueries(["challenge-list"]);
      queryClient.resetQueries(["users-details"]);
      navigate("/admin/dashboard");
    } else if (type === "admin.users") {
      queryClient.resetQueries(["users-details"]);
      setSelectedUserType();
      navigate("users");
    } else if (type === "admin.challenges") {
      queryClient.resetQueries(["challenge-list"]);
      navigate("challenges");
    } else if (type === "global.collaborations") {
      queryClient.resetQueries(["collaboration-list"]);
      navigate("collaborations");
    } else if (type === "forum.forums") {
      navigate("forums");
    } else if (type === "global.events") {
      queryClient.resetQueries(["event-list"]);
      navigate("events");
    }
    setSelectedSideMenu({
      type,
      isOpen: handleIsOpen(type),
      subMenuType: type === "admin.master" ? selectedSideMenu?.subMenuType : null,
    });
  };

  const handleSubMenuClick = (type: string) => {
    clearFilterDataStore();
    clearUserTypeFilter();
    if (selectedSideMenu?.type)
      setSelectedSideMenu({
        type: selectedSideMenu?.type,
        isOpen: true,
        subMenuType: type,
      });
    if (type === "global.password") {
      navigate("change-password");
    } else if (type === "admin.master") {
      navigate("/admin/settings");
    } else if (type === "admin.permissions") {
      navigate("permissions");
    }
  };

  useEffect(() => {
    if (usersSectionPathNames?.includes(pathName)) {
      setSelectedSideMenu({
        type: "admin.users",
        isOpen: false,
      });
    } else if (pathName === "/admin/challenges" || pathName === "/admin/challenges/details") {
      setSelectedSideMenu({
        type: "admin.challenges",
        isOpen: false,
      });
    } else if (pathName === "/admin/collaborations") {
      setSelectedSideMenu({
        type: "global.collaborations",
        isOpen: false,
      });
    } else if (pathName === "/admin") {
      setSelectedSideMenu({
        type: "global.dashboard",
        isOpen: false,
      });
    } else if (pathName === "/admin/forums") {
      setSelectedSideMenu({
        type: "forum.forums",
        isOpen: false,
      });
    } else if (pathName === "/admin/events") {
      setSelectedSideMenu({
        type: "global.events",
        isOpen: false,
      });
    }
  }, [pathName]);

  return (
    <div>
      <div className={`flex  min-h-screen   ${!isOpenSideMenu ? "miniSidebar" : ""}`}>
        <Sidebar
          menuItems={SidebarMenuItems}
          handleCollapseClick={onToggleClick}
          handleMenuClick={handleMenuClick}
          handleSubMenuClick={handleSubMenuClick}
        />

        <div className="w-full items-baseline mainContainer min-h-[calc(100vh-76px)] flex overflow-hidden lg:rtl:mr-[250px] lg:ltr:ml-[250px] flex-col">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
