import React from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";

type SecondaryButtonProps = {
  title: string;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  startIcon?: string;
  endIcon?: string;
  className?: string;
  disabled?: boolean;
};

export function SecondaryButton({
  title,
  handleClick,
  startIcon,
  endIcon,
  className,
  disabled,
}: SecondaryButtonProps) {
  const { t } = useTranslation();
  return (
    <button
      className={` btn normal-case font-medium text-sm h-12 rounded-md bg-white border border-primary-medium !text-primary-medium hover:!text-white hover:!bg-primary-medium ${className}`}
      onClick={handleClick}
      disabled={disabled}
    >
      {startIcon ? <SVGIcon name={startIcon} /> : null}
      <span> {t(title)}</span>
      {endIcon ? <SVGIcon name={endIcon} /> : null}
    </button>
  );
}
