export function TableLoader() {
  return (
    <div className=" overflow-hidden w-full mt-[-1px]">
      <div className="space-y-2.5 animate-pulse w-full">
        <div className="flex items-center w-full space-x-2 rtl:space-x-reverse bg-gray-106 h-11 px-3.5">
          <div className="h-3 bg-gray-105/[0.08] rounded w-[15%]"></div>
          <div className="h-3 bg-gray-105/[0.08] rounded w-[20%]"></div>
          <div className="h-3 bg-gray-105/[0.08] rounded w-[30%]"></div>
          <div className="h-3 bg-gray-105/[0.08] rounded w-[20%]"></div>
          <div className="h-3 bg-gray-105/[0.08] rounded w-[15%]"></div>
        </div>
        <div className="flex items-center space-x-2 rtl:space-x-reverse w-full bg-white h-11 px-3.5">
          <div className="h-3 bg-gray-105/[0.08] rounded w-[15%]"></div>
          <div className="h-3 bg-gray-105/[0.08] rounded w-[20%]"></div>
          <div className="h-3 bg-gray-105/[0.08] rounded w-[30%]"></div>
          <div className="h-3 bg-gray-105/[0.08] rounded w-[20%]"></div>
          <div className="h-3 bg-gray-105/[0.08] rounded w-[15%]"></div>
        </div>
        <div className="flex items-center space-x-2 rtl:space-x-reverse w-full bg-gray-106 h-11 px-3.5">
          <div className="h-3 bg-gray-105/[0.08] rounded w-[15%]"></div>
          <div className="h-3 bg-gray-105/[0.08] rounded w-[20%]"></div>
          <div className="h-3 bg-gray-105/[0.08] rounded w-[30%]"></div>
          <div className="h-3 bg-gray-105/[0.08] rounded w-[20%]"></div>
          <div className="h-3 bg-gray-105/[0.08] rounded w-[15%]"></div>
        </div>
      </div>
    </div>
  );
}
