import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveRolesAndPermissions(formData: PermissionData, userType: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(urls.rolesAndPermissions(userType), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useSaveRolesAndPermissions() {
  const qc = useQueryClient();
  return useMutation(
    async ({ formData, userType }: { formData: PermissionData; userType: number }) => {
      const result = await saveRolesAndPermissions(formData, userType);
      qc.invalidateQueries(["permission-list", userType]);
      return result;
    }
  );
}
