import React from "react";

type TitleProps = {
  title: string;
  children?: React.ReactNode;
  className?: string;
};

export function Title({ title, children, className }: TitleProps) {
  return (
    <>
      <h3 className={className ? className : ""}>{title}</h3>
      {children ? children : null}
    </>
  );
}
