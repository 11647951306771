import { forwardRef } from "react";
import { InlineLoader, SubTitle, Title } from "components";
import { useTranslation } from "react-i18next";
import { useGetLatestChallenges } from "features/Home/api/useGetLatestChallenges";
import ChallengeItem from "features/Home/LatestChallenges/ChallengeItem";
import { isGlobalWebsite } from "utils/helpers";

const LatestChallenges = forwardRef(function LatestChallenges(_, ref: React.Ref<HTMLDivElement>) {
  const { t } = useTranslation();
  const isGlobal = isGlobalWebsite();

  const { data: challenges, isLoading } = useGetLatestChallenges(isGlobal);
  return (
    <div className=" w-full h-full   bg-skin-primary03  " ref={ref}>
      <div className=" py-10 xl:py-[60px] max-[1023px]:pb-[25px]">
        <div className="home-container">
          <SubTitle
            title={t("home.featured_and_latest")}
            className=" relative font-semibold text-skin-textSecondary01 uppercase !justify-start text-xs leading-4  md:text-xl md:leading-[26px] "
          />
          <Title
            title={t("home.challenges")}
            className=" relative font-semibold text-skin-primaryDark text-[27px] leading-[34px] md:text-[43px] md:leading-[54px]"
          />
        </div>
        <div className="mt-10 xl:mt-[45px] max-[1023px]:mt-[17px] max-[1023px]:mb-0 relative w-full case-studies-slider challenge-slider-container max-[1023px]:overflow-auto ">
          <div className="grid grid-cols-3 gap-[30px] max-[1023px]:gap-[20px]  max-[1023px]:pr-5 max-[1023px]:w-[951px] cursor-pointer">
            {challenges &&
              challenges?.map((item: Challenge) => <ChallengeItem data={item} key={item.id} />)}
          </div>
        </div>
        {isLoading ? <InlineLoader /> : null}
      </div>
    </div>
  );
});

export default LatestChallenges;
