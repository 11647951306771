import { create } from "zustand";
import { produce } from "immer";
import {
  DEFAULT_EDITOR_EMPTY_STATE,
} from "utils/constants";
import { defaultPrimaryContact } from "store/useChallengeStore";

export type CollaborationDataStore = {
  collaborationInfo: CollaborationAbout;
  milestones: CollaborationMileStone;
  learnMore: CollaborationLearnMore;
  funding: CollaborationFunding;
  additionalInfo: CollaborationAdditionalInfo;
  contacts: CollaborationContacts;
  tags: CollaborationTags;
};

const ABOUT_INIT_STATE: CollaborationAbout = {
  id: 0,
  title: "",
  startDate: "",
  endDate: "",
  isPrivate: false,
  targetIndustry: null,
  location: "",
  summary: "",
  coverImage: "",
};

const MILESTONES_INIT_STATE: CollaborationMileStone = {
  milestones: [],
};

const LEARN_MORE_INIT_STATE: CollaborationLearnMore = {
  description: "",
  overview: "",
  resources: "",
  targets: "",
};

const FUNDING_INIT_STATE: CollaborationFunding = {
  funding: null,
};

export const ADDITIONAL_INFO_INIT_STATE: CollaborationAdditionalInfo = {
  information: DEFAULT_EDITOR_EMPTY_STATE,
  documents: [],
};

const CONTACTS_INIT_STATE: CollaborationContacts = {
  contacts: [defaultPrimaryContact],
};

const TAGS_INIT_STATE: CollaborationTags = {
  tags: [],
};

export const MILESTONE_INIT_DATA = {
  id: 0,
  startDate: "",
  endDate: "",
  milestone: "",
  description: "",
};

export const useCollaborationDataStore = create<CollaborationDataStore>()(() => ({
  collaborationInfo: ABOUT_INIT_STATE,
  milestones: MILESTONES_INIT_STATE,
  learnMore: LEARN_MORE_INIT_STATE,
  funding: FUNDING_INIT_STATE,
  additionalInfo: ADDITIONAL_INFO_INIT_STATE,
  contacts: CONTACTS_INIT_STATE,
  tags: TAGS_INIT_STATE,
}));

export function setCollaborationInfo(collaborationInfo: CollaborationAbout) {
  useCollaborationDataStore.setState(
    produce((state: CollaborationDataStore) => {
      state.collaborationInfo = collaborationInfo;
    })
  );
}

export function setMilestoneInfo(milestones: CollaborationMileStone) {
  useCollaborationDataStore.setState(
    produce((state: CollaborationDataStore) => {
      state.milestones = milestones;
    })
  );
}

export function setLearnMoreInfo(learnMore: CollaborationLearnMore) {
  useCollaborationDataStore.setState(
    produce((state: CollaborationDataStore) => {
      state.learnMore = learnMore;
    })
  );
}

export function setFundingInfo(funding: CollaborationFunding) {
  useCollaborationDataStore.setState(
    produce((state: CollaborationDataStore) => {
      state.funding = funding;
    })
  );
}

export function setAdditionalInfo(additionalInfo: CollaborationAdditionalInfo) {
  useCollaborationDataStore.setState(
    produce((state: CollaborationDataStore) => {
      state.additionalInfo = additionalInfo;
    })
  );
}

export function setContactsInfo(contacts: CollaborationContacts) {
  useCollaborationDataStore.setState(
    produce((state: CollaborationDataStore) => {
      state.contacts = contacts;
    })
  );
}

export function setTagsInfo(tags: CollaborationTags) {
  useCollaborationDataStore.setState(
    produce((state: CollaborationDataStore) => {
      state.tags = tags;
    })
  );
}

export function setCollaborationInfoItem(
  value: string | number | OptionItem | boolean | null,
  key: keyof CollaborationAbout
) {
  useCollaborationDataStore.setState(
    produce((state) => {
      if (state.collaborationInfo) {
        state.collaborationInfo[key] = value;
      }
    })
  );
}

export function setMilestoneInfoItem(
  value: string | number | null,
  key: keyof MileStone,
  index: number
) {
  useCollaborationDataStore.setState(
    produce((state) => {
      if (state.milestones && state.milestones.milestones.length > 0) {
        state.milestones.milestones[index][key] = value;
      }
    })
  );
}

export function setLearnMoreInfoItem(
  value: string | number | null,
  key: keyof CollaborationLearnMore
) {
  useCollaborationDataStore.setState(
    produce((state) => {
      if (state.learnMore) {
        state.learnMore[key] = value;
      }
    })
  );
}

export function setFundingInfoItem(value: string | number | null, key: keyof CollaborationFunding) {
  useCollaborationDataStore.setState(
    produce((state) => {
      if (state.funding) {
        state.funding[key] = value;
      }
    })
  );
}

export function setAdditionalInfoItem(
  value: string | number | Array<FileDetailsItem | File> | null,
  key: keyof CollaborationAdditionalInfo
) {
  useCollaborationDataStore.setState(
    produce((state) => {
      if (state.additionalInfo) {
        state.additionalInfo[key] = value;
      }
    })
  );
}

export function setContactsInfoItem(
  value: Array<Contact> | number | null | string,
  key: keyof Contact | keyof CollaborationContacts,
  index?: number
) {
  useCollaborationDataStore.setState(
    produce((state) => {
      if (state.contacts) {
        if (index !== undefined) {
          state.contacts.contacts[index][key] = value;
        } else {
          state.contacts[key] = value;
        }
      }
    })
  );
}

export function setTagsInfoItem(
  value: Array<OptionItem> | number | null,
  key: keyof CollaborationTags
) {
  useCollaborationDataStore.setState(
    produce((state) => {
      if (state.tags) {
        state.tags[key] = value;
      }
    })
  );
}

export function addCollaborationMilestone() {
  useCollaborationDataStore.setState(
    produce((state: CollaborationDataStore) => {
      if (state.milestones) {
        state.milestones.milestones.push(MILESTONE_INIT_DATA);
      }
    })
  );
}

export function removeCollaborationMilestone(index: number) {
  useCollaborationDataStore.setState(
    produce((state: CollaborationDataStore) => {
      if (state.milestones && state.milestones.milestones.length > 0) {
        const { milestones } = useCollaborationDataStore.getState().milestones;
        const updatedItems = [...milestones];
        updatedItems.splice(index, 1);
        state.milestones.milestones = updatedItems;
      }
    })
  );
}

export function clearCollaborationDataStore() {
  useCollaborationDataStore.setState(
    produce((state: CollaborationDataStore) => {
      state.collaborationInfo = ABOUT_INIT_STATE;
      state.milestones = MILESTONES_INIT_STATE;
      state.learnMore = LEARN_MORE_INIT_STATE;
      state.funding = FUNDING_INIT_STATE;
      state.additionalInfo = ADDITIONAL_INFO_INIT_STATE;
      state.contacts = CONTACTS_INIT_STATE;
      state.tags = TAGS_INIT_STATE;
    })
  );
}
