import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { FileUploadList, Label, LoaderModal, RichTextEditor, UploadButton } from "components";
import {
  setEventDescriptionInfo,
  setEventDescriptionInfoItem,
  useEventDataStore,
} from "store/useEventDataStore";
import { useGetLearnMore } from "features/Events/api/useGetLearnMore";
import { MAX_FILE_SIZE } from "utils/constants";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { useFileUpload } from "features/Events/api/useFileUpload";
import { showConfirmationModal } from "utils/confirmationModals";
import { useDeleteFileUpload } from "features/Events/api/useDeleteFileUpload";

type LearnMoreProps = {
  validationErrors: Record<string, string> | null;
  setIsUploading: (status: boolean) => void;
};

export default function LearnMore({ validationErrors, setIsUploading }: LearnMoreProps) {
  const { t } = useTranslation();
  const { detailsInfo, descriptionInfo } = useEventDataStore();
  const isUploadDisabled = descriptionInfo?.documents?.length >= 5;

  const { data: eventLearnMoreData } = useGetLearnMore(detailsInfo.id);
  const { mutateAsync: uploadEventFile, isLoading: isFileUploading } = useFileUpload();
  const { mutateAsync: deleteFileUpload, isLoading: isFileDeleting } = useDeleteFileUpload();

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target?.files;
    if (selectedFiles && selectedFiles?.length > 0) {
      if (selectedFiles?.[0].size > MAX_FILE_SIZE) {
        event.target.value = "";
        return showErrorToastMessage({
          message: t("research_scholar.uploaded_files_size_error_message"),
        });
      }
      const fileName = selectedFiles?.[0].name;
      if (descriptionInfo.documents) {
        const isFileExist = descriptionInfo.documents.find((item) => item.name === fileName);
        if (isFileExist) {
          showErrorToastMessage({
            message: t("research_scholar.multiple_same_name_file_error_message"),
          });
          event.target.value = "";
          return;
        }
        try {
          setIsUploading(true);
          const fileDetails = descriptionInfo.documents;
          setEventDescriptionInfoItem(
            fileDetails ? [...fileDetails, selectedFiles[0]] : [selectedFiles[0]],
            "documents"
          );
          const result = await uploadEventFile({
            file: selectedFiles[0],
            eventId: descriptionInfo?.id,
            isCoverImage: false,
          });
          if (result) {
            setEventDescriptionInfoItem(
              fileDetails ? [...fileDetails, result] : [result],
              "documents"
            );
            setTimeout(() => {
              window.scrollTo(0, document.body.scrollHeight);
            }, 1000);
          }
        } catch (error) {
          showErrorToastMessage({
            message: t("global.something_went_wrong"),
          });
        } finally {
          setIsUploading(false);
          event.target.value = "";
        }
      }
    }
  };

  const handleRemove = async (event: React.MouseEvent<HTMLButtonElement>, item: FileDetails) => {
    event.stopPropagation();
    event.preventDefault();
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.deletion_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      if (item.id) {
        const formData = {
          id: item.id,
          name: item.name,
          fileUrl: item.fileUrl,
        };
        try {
          const response = await deleteFileUpload(formData);
          const files = descriptionInfo.documents;
          const newFiles = files?.filter((file) => file.fileUrl !== item?.fileUrl);
          if (newFiles) {
            setEventDescriptionInfoItem(newFiles, "documents");
          }
          showSuccessToastMessage({
            message: response.message,
          });
        } catch (error: ApiError) {
          if (error.response.data.status === 400) {
            showErrorToastMessage({
              message: error.response.data.message,
            });
          } else {
            showErrorToastMessage({
              message: t("global.something_went_wrong"),
            });
          }
        }
      } else {
        const files = descriptionInfo.documents;
        const newFiles = files?.filter((file) => file.fileUrl !== item?.fileUrl);
        if (newFiles) {
          setEventDescriptionInfoItem(newFiles, "documents");
        }
        showSuccessToastMessage({
          message: t("global.document_deleted_successfully"),
        });
      }
    }
  };

  useEffect(() => {
    if (eventLearnMoreData) {
      setEventDescriptionInfo(eventLearnMoreData);
    }
  }, [eventLearnMoreData]);

  return (
    <div>
      <div className=" flex items-center justify-between mb-6 flex-wrap pb-8 p-5 !py-3 border-b border-gray-light">
        <div className="flex items-center ltr:mr-auto rtl:ml-auto">
          <SVGIcon name="learnMore" />
          <Label
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            text={t("organization.learn_more")}
            tooltipContent={"research_scholar.you_can_upload_a_maximum_of_5_documents"}
          />
        </div>
        <UploadButton
          label="global.upload"
          icon="uploadV2"
          handleFileUpload={handleFileUpload}
          disabled={isUploadDisabled || isFileUploading}
        />
      </div>
      <div
        className={`border border-[#7890A1] rounded-md my-6 mx-5 ${
          validationErrors ? "!border-red-200 " : "border-primary-light"
        }`}
      >
        <RichTextEditor
          key={
            descriptionInfo?.learnMore?.length !== eventLearnMoreData?.learnMore?.length
              ? "reset"
              : ""
          }
          text={descriptionInfo?.learnMore ?? ""}
          handleTextChange={(text) => {
            setEventDescriptionInfoItem(text, "learnMore");
          }}
        />
      </div>
      <div className=" p-5 pt-0 mt-8">
        {descriptionInfo?.documents?.length ? (
          <FileUploadList
            fileList={descriptionInfo?.documents}
            handleRemove={handleRemove}
            isFileUploading={isFileUploading}
          />
        ) : null}
      </div>
      {isFileDeleting ? <LoaderModal /> : null}
    </div>
  );
}
