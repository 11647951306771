import { useTranslation } from "react-i18next";
import { Title } from "components";

type ButtonWithSubtitleProps = {
  title: string;
  subtitle: string;
  handleClick: () => void;
  isDisabled?: boolean;
};

export function ButtonWithSubtitle({ title, subtitle, handleClick, isDisabled }: ButtonWithSubtitleProps) {
  const { t } = useTranslation();
  return (
    <button
      className=" w-full !bg-gray-light/60  !font-normal mb-6 space-x-4 rtl:space-x-reverse border border-dashed border-primary-medium h-16 flex items-center !text-primary-medium text-sm rounded  hover:!bg-primary-medium hover:!text-white flex-col justify-center py-5"
      onClick={handleClick}
      disabled={isDisabled}
    >
      <Title className="  text-sm font-medium" title={t(title)} />
      <p className=" text-sm font-normal">{t(subtitle)}</p>
    </button>
  );
}
