import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import DefaultCoverImage from "assets/images/challengeDefaultCover.png";
import {
  Chip,
  ContactPreviewCard,
  Descriptor,
  Divider,
  SecondaryButton,
  ProfileNavigationTimeline,
  PrimaryButton,
  UploadedFileItem,
  MilestonePreviewCard,
  LoaderModal,
  Label,
  TextButton,
  ImageLoader,
  ContentViewWithToggle,
  EditorContentPreview,
} from "components";
import {
  displayDateFormat,
  getChallengeStatus,
  convertJSONStringAsHtml,
  getMasterDataValue,
  getChallengeStatusBackground,
} from "utils/helpers";
import {
  CREATE_CHALLENGE_COMPLETION_STATUS,
  CHALLENGE_STATUS,
  DETAILS_DISPLAY_MAX_CHAR_LENGTH,
  USERS,
  CHALLENGE_VIEW_MORE_INIT_STATE,
} from "utils/constants";
import { showConfirmationModal, showShareModal } from "utils/confirmationModals";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { useChangeChallengeStatus } from "features/Organization/api/useChangeChallengeStatus";
import { useGetChallengePreviewDetails } from "features/Organization/api/useGetChallengePreviewDetails";
import { useStickyScroll } from "hooks/useStickyScroll";
import { useUserDataStore } from "store/useUserDataStore";

type ChallengePreviewProps = {
  onClose: () => void;
  challengeId: number;
  setNavigation?: () => void;
  showPrimaryButton: boolean;
};
const PreviewChallenge = ({
  onClose,
  challengeId,
  showPrimaryButton,
  setNavigation,
}: ChallengePreviewProps) => {
  const { t, i18n } = useTranslation();
  const { scroll } = useStickyScroll();
  const pathName = window.location.pathname;
  const isExplore = pathName.includes("explore");
  const { userInfo } = useUserDataStore();
  const { data: challengeDetails, isLoading: isChallengeLoading } = useGetChallengePreviewDetails(
    challengeId,
    isExplore
  );
  const { mutateAsync: publishChallenge, isLoading: isPublishLoading } = useChangeChallengeStatus();

  const [isViewMore, setIsViewMore] = useState<boolean>(false);
  const [viewMore, setViewMore] = useState(CHALLENGE_VIEW_MORE_INIT_STATE);
  const [isViewMoreSummary, setIsViewMoreSummary] = useState<boolean>(false);

  const modalContentRef = useRef<HTMLDivElement | null>(null);
  const aboutRef = useRef(null);
  const milestoneRef = useRef(null);
  const learnMoreRef = useRef(null);
  const fundingRef = useRef(null);
  const additionalInfoRef = useRef(null);
  const contactRef = useRef(null);
  const tagsRef = useRef(null);
  const [isImageLoading, setImageLoading] = useState(false);
  const shareLink = ` ${window.location.origin}/${
    userInfo?.userType === USERS.Organization ? "organization" : "research-institute"
  }/challenges/${challengeDetails?.challengeDetails?.id}`;

  const handleExitPreview = () => {
    onClose();
  };
  const handlePublish = async () => {
    const result = await showConfirmationModal({
      title: "global.submit_confirmation_title",
      message: "global.submit_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.submit",
      modalIcon: "confirmSubmissionV1",
      isAlert: true,
    });
    if (!result) {
      return;
    }
    if (result) {
      try {
        const result = await publishChallenge({
          status: CHALLENGE_STATUS.Pending,
          challengeId: challengeId,
        });
        onClose();
        setNavigation?.();
        showSuccessToastMessage({
          message: result.message,
        });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };

  const handleScroll = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const handleNavigationClick = (value: number) => {
    if (value === CREATE_CHALLENGE_COMPLETION_STATUS.About) {
      handleScroll(aboutRef);
    } else if (value === CREATE_CHALLENGE_COMPLETION_STATUS.Milestones) {
      handleScroll(milestoneRef);
    } else if (value === CREATE_CHALLENGE_COMPLETION_STATUS.LearnMore) {
      handleScroll(learnMoreRef);
    } else if (value === CREATE_CHALLENGE_COMPLETION_STATUS.Funding) {
      handleScroll(fundingRef);
    } else if (value === CREATE_CHALLENGE_COMPLETION_STATUS.Artifacts) {
      handleScroll(additionalInfoRef);
    } else if (value === CREATE_CHALLENGE_COMPLETION_STATUS.Contacts) {
      handleScroll(contactRef);
    } else if (value === CREATE_CHALLENGE_COMPLETION_STATUS.Tags) {
      handleScroll(tagsRef);
    }
  };

  const navigationItems: Array<RecordItem> = useMemo(() => {
    const tempItems = [
      {
        value: CREATE_CHALLENGE_COMPLETION_STATUS.About,
        label: "organization.about",
      },
    ];
    if (challengeDetails?.mileStone?.milestones?.length) {
      {
        tempItems.push({
          value: CREATE_CHALLENGE_COMPLETION_STATUS.Milestones,
          label: "organization.milestones",
        });
      }
    }
    if (challengeDetails?.moreDetails) {
      {
        tempItems.push({
          value: CREATE_CHALLENGE_COMPLETION_STATUS.LearnMore,
          label: "organization.learn_more",
        });
      }
    }
    if (challengeDetails?.fundingDetails?.funding) {
      {
        tempItems.push({
          value: CREATE_CHALLENGE_COMPLETION_STATUS.Funding,
          label: "organization.funding",
        });
      }
    }
    if (
      challengeDetails?.additionalInformation?.information ||
      challengeDetails?.additionalInformation?.documents?.length
    ) {
      {
        tempItems.push({
          value: CREATE_CHALLENGE_COMPLETION_STATUS.Artifacts,
          label: "organization.additional_info_and_artifacts",
        });
      }
    }

    if (challengeDetails?.contacts?.contacts) {
      {
        tempItems.push({
          value: CREATE_CHALLENGE_COMPLETION_STATUS.Contacts,
          label: "organization.contact",
        });
      }
    }
    if (challengeDetails?.tags?.tags?.length) {
      {
        tempItems.push({
          value: CREATE_CHALLENGE_COMPLETION_STATUS.Tags,
          label: "organization.tags",
        });
      }
    }
    return tempItems;
  }, [challengeDetails]);

  const handleViewClick = () => {
    if (isViewMore) {
      handleScroll(learnMoreRef);
    }
    setIsViewMore(!isViewMore);
  };
  const handleViewMoreSummary = () => {
    setIsViewMoreSummary((prev) => !prev);
  };

  const handleShare = () => {
    showShareModal(shareLink, "global.share_challenge");
  };

  const handleSummaryViewMoreClick = () => {
    setIsViewMoreSummary((prev) => !prev);
  };

  useEffect(() => {
    if (challengeDetails && challengeDetails?.challengeDetails?.coverImage.length > 0) {
      setImageLoading(true);
    }
  }, [challengeDetails]);
  const onLoaded = () => {
    setImageLoading(false);
  };

  const handleViewMoreClick = (type: keyof ChallengeViewMore) => {
    setViewMore((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  return (
    <div
      className="w-[1190px] bg-white max-h-[calc(100vh-50px)] flex flex-col justify-start rounded-md pb-16"
      ref={modalContentRef}
    >
      <div className="overflow-auto mt-1">
        {/* HEADER */}
        <div
          className="flex justify-between items-center px-10 py-5 border-b border-gray-light mb-10"
          ref={aboutRef}
        >
          <p className="font-semibold text-[22px] text-black">
            {userInfo?.userType === USERS.Organization
              ? t("organization.preview")
              : t("global.challenge_details")}
          </p>
          <div className="flex gap-2 justify-center items-center">
            <SecondaryButton
              title={
                userInfo?.userType === USERS.Organization
                  ? "organization.exit_preview"
                  : "global.close"
              }
              handleClick={handleExitPreview}
            />
            {showPrimaryButton ? (
              <PrimaryButton title={"global.submit"} handleClick={handlePublish} />
            ) : null}
          </div>
        </div>
        {/* HEADER end*/}

        <div className="w-full flex gap-10 px-10">
          <div className=" w-full">
            <div className="relative mb-10">
              {isImageLoading ? (
                <div className="w-full h-[250px]  rounded-md ">
                  <ImageLoader />
                </div>
              ) : null}
              <img
                alt="previewImage"
                className={`w-full max-h-[250px]  rounded-md ${
                  isImageLoading ? "h-[0px] overflow-hidden" : "h-[250px] "
                }`}
                src={
                  challengeDetails?.challengeDetails?.coverImage
                    ? challengeDetails?.challengeDetails?.coverImage
                    : DefaultCoverImage
                }
                onLoad={onLoaded}
              />
              {!challengeDetails?.challengeDetails?.isPrivate &&
              challengeDetails?.challengeDetails?.challengeStatusId !== CHALLENGE_STATUS.Disable &&
              !showPrimaryButton ? (
                <button
                  onClick={handleShare}
                  className="absolute -bottom-[21px] ltr:right-5 rtl:left-5 bg-[#37D3AE] border-2 border-white rounded-md max-[1025px]:hidden"
                >
                  <SVGIcon name="shareV2" className="" />
                </button>
              ) : null}
            </div>
            <div className="flex gap-4 items-center truncate">
              <div className=" flex badgeBLock gap-2 items-center">
                <Label
                  className={` !w-auto text-gray-107 text-xs bg-green-500 rounded-xl px-3 py-1.5 h-[30px] ${getChallengeStatusBackground(
                    challengeDetails?.challengeDetails?.challengeStatusId
                  )}`}
                  text={t(
                    `${getChallengeStatus(challengeDetails?.challengeDetails?.challengeStatusId)}`
                  )}
                />
                <Label
                  className=" !w-auto text-gray-107 text-xs bg-green-500 rounded-xl px-3 py-1.5  h-[30px]"
                  text={
                    challengeDetails?.challengeDetails?.isPrivate
                      ? t("organization.private")
                      : t("organization.public")
                  }
                />
              </div>
              <div className=" h-[14px] bg-gray-200 w-[1px]"></div>
              <div className=" flex badgeBLock gap-6 items-center">
                <Label
                  className=" !w-auto text-gradient-blueDark text-xs bg-primary-500 rounded-xl px-3 py-1.5  h-[30px]"
                  text={
                    challengeDetails?.challengeDetails?.targetIndustry
                      ? getMasterDataValue(
                          challengeDetails?.challengeDetails?.targetIndustry,
                          i18n.language
                        )
                      : ""
                  }
                />
              </div>
            </div>
            <div className=" flex items-center mt-2">
              <h2 className="font-bold text-3xl  text-gray-112 Word-break">
                {challengeDetails?.challengeDetails?.title}
              </h2>
              {challengeDetails?.challengeDetails?.isGlobal && !userInfo?.isGlobal ? (
                <div className=" h-[20px] flex items-center px-3 ltr:!ml-1 rtl:!mr-1 rounded-xl text-xs text-white font-normal bg-primary-medium ">
                  {t("global.global")}
                </div>
              ) : null}
            </div>
            <div className="flex gap-4 items-center mt-[10px]">
              <Descriptor
                title={`${t("global.start_date")} : ${displayDateFormat(
                  challengeDetails?.challengeDetails?.startDate
                )}`}
                startIcon="previewDate"
                className="flex gap-2 items-center text-black text-sm whitespace-nowrap"
              />
              <Descriptor
                title={`${t("global.end_date")} : ${displayDateFormat(
                  challengeDetails?.challengeDetails?.endDate
                )}`}
                startIcon="previewDate"
                className="flex gap-2 items-center text-black text-sm whitespace-nowrap"
              />
              <Descriptor
                title={challengeDetails?.challengeDetails?.location ?? ""}
                startIcon="previewLocation"
                className="flex gap-2 items-center text-black text-sm Word-break"
              />
            </div>
            <div className="max-w-[90%]  WB_BW mt-2">
              {challengeDetails?.challengeDetails?.summary.length >
                DETAILS_DISPLAY_MAX_CHAR_LENGTH && !isViewMoreSummary ? (
                <span
                  className=" text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full  mt-[30px] mb-[26px] "
                  dangerouslySetInnerHTML={{
                    __html:
                      challengeDetails?.challengeDetails?.summary
                        ?.slice(0, DETAILS_DISPLAY_MAX_CHAR_LENGTH)
                        .replace(/\n/g, "<br/>")
                        .replace(/ /g, () => "&nbsp;") + "...",
                  }}
                />
              ) : (
                <span
                  className=" text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mt-[30px] mb-[26px] "
                  dangerouslySetInnerHTML={{
                    __html:
                      challengeDetails?.challengeDetails?.summary
                        .replace(/\n/g, "<br/>")
                        .replace(/ /g, () => "&nbsp;") ?? "",
                  }}
                />
              )}
              {challengeDetails?.challengeDetails?.summary.length >
              DETAILS_DISPLAY_MAX_CHAR_LENGTH ? (
                <TextButton
                  title={isViewMoreSummary ? "global.view_less" : "global.view_more"}
                  handleClick={handleSummaryViewMoreClick}
                  className="text-primary-medium font-normal underline mt-1.5 text-sm"
                />
              ) : null}
            </div>

            {challengeDetails?.challengeDetails?.summary.length >
            DETAILS_DISPLAY_MAX_CHAR_LENGTH ? (
              <SecondaryButton
                title={isViewMoreSummary ? "global.view_less" : "global.view_more"}
                handleClick={handleViewMoreSummary}
                className="mt-4"
              />
            ) : null}
            <Divider className="my-[30px] !border-gray-light" />

            {challengeDetails?.mileStone?.milestones?.length ? (
              <div ref={milestoneRef}>
                <h3 className=" font-semibold text-lg mb-3 text-gray-112">
                  {t("organization.milestones")}
                </h3>
                <ul className="flex flex-col gap-5 preview-milestones-line">
                  {challengeDetails?.mileStone?.milestones?.map(
                    (item: ChallengeMilestoneSave, index: number) => (
                      <MilestonePreviewCard
                        key={index}
                        title={item?.milestone}
                        startDate={item?.startDate}
                        endDate={item?.endDate}
                        description={item?.description}
                      />
                    )
                  )}
                </ul>
                <Divider className="mt-[30px] !border-gray-light" />
              </div>
            ) : null}

            {challengeDetails?.moreDetails ? (
              <div ref={learnMoreRef}>
                <h3 className="mt-[30px] font-semibold text-lg text-gray-112 !mb-2.5">
                  {t("organization.challenge_learn_more")}
                </h3>
                <h3 className="font-semibold text-md text-gray-112 !mb-2.5">
                  {t("organization.overview_of_current_challenges")}
                </h3>
                <ContentViewWithToggle
                  data={challengeDetails?.moreDetails?.overviewOfCurrentChallenges}
                  handleViewMoreClick={() => handleViewMoreClick("overviewOfCurrentChallenges")}
                  isViewMore={viewMore.overviewOfCurrentChallenges}
                  maxLength={DETAILS_DISPLAY_MAX_CHAR_LENGTH}
                  className={
                    " text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] me-1"
                  }
                  contentType="textAreaContent"
                />

                <h3 className="!mt-2.5 font-semibold text-md text-gray-112 !mb-2.5">
                  {t("organization.detailed_description_of_the_challenge")}
                </h3>
                <ContentViewWithToggle
                  data={challengeDetails?.moreDetails?.detailedDescriptionOftheChallenges}
                  handleViewMoreClick={() =>
                    handleViewMoreClick("detailedDescriptionOftheChallenges")
                  }
                  isViewMore={viewMore.detailedDescriptionOftheChallenges}
                  maxLength={DETAILS_DISPLAY_MAX_CHAR_LENGTH}
                  className={
                    " text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] me-1"
                  }
                  contentType="textAreaContent"
                />

                <h3 className="!mt-2.5 font-semibold text-md text-gray-112 !mb-2.5">
                  {t("organization.desired_enhancement")}
                </h3>
                <ContentViewWithToggle
                  data={challengeDetails?.moreDetails?.desiredEnhancements}
                  handleViewMoreClick={() => handleViewMoreClick("desiredEnhancements")}
                  isViewMore={viewMore.desiredEnhancements}
                  maxLength={DETAILS_DISPLAY_MAX_CHAR_LENGTH}
                  className={
                    " text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] me-1"
                  }
                  contentType="textAreaContent"
                />

                <h3 className="!mt-2.5 font-semibold text-md text-gray-112 !mb-2.5">
                  {t("organization.resources_and_constraints")}
                </h3>
                <ContentViewWithToggle
                  data={challengeDetails?.moreDetails?.resourcesAndConstraints}
                  handleViewMoreClick={() => handleViewMoreClick("resourcesAndConstraints")}
                  isViewMore={viewMore.resourcesAndConstraints}
                  maxLength={DETAILS_DISPLAY_MAX_CHAR_LENGTH}
                  className={
                    " text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] me-1"
                  }
                  contentType="textAreaContent"
                />
                <h3 className="!mt-2.5 font-semibold text-md text-gray-112 !mb-2.5">
                  {t("organization.success_metrics")}
                </h3>
                <ContentViewWithToggle
                  data={challengeDetails?.moreDetails?.successMetrics}
                  handleViewMoreClick={() => handleViewMoreClick("successMetrics")}
                  isViewMore={viewMore.successMetrics}
                  maxLength={DETAILS_DISPLAY_MAX_CHAR_LENGTH}
                  className={
                    " text-gray-109 text-base  max-w-[90%] w-full Word-break max-[1025px]:max-w-full mb-[26px] me-1"
                  }
                  contentType="textAreaContent"
                />
              </div>
            ) : null}

            <Divider className="my-[30px] !border-gray-light" />
            {challengeDetails?.fundingDetails?.funding ? (
              <div ref={fundingRef}>
                <h3 className="mt-[30px] font-semibold text-lg text-gray-112">
                  {t("organization.funding")}
                </h3>
                <div className="flex gap-4 bg-gray-light justify-center items-center mt-[10px] p-6 rounded-md max-w-[690px]">
                  <div className="w-10 min-w-[40px]">
                    <SVGIcon name="fundingPreviewIcon" />
                  </div>
                  <p
                    className=" max-w-[90%] text-gray-109 text-sm ltr:mr-auto rtl:ml-auto Word-break funding-text dangerously-set-html"
                    dangerouslySetInnerHTML={{
                      __html: convertJSONStringAsHtml(challengeDetails?.fundingDetails?.funding),
                    }}
                  />
                </div>
                <Divider className="mt-[30px] !border-gray-light" />
              </div>
            ) : null}

            {challengeDetails?.additionalInformation?.information ? (
              <div ref={additionalInfoRef}>
                <h3 className="mt-[30px] font-semibold text-lg text-gray-112 mb-[10px]">
                  {t("organization.additional_information")}
                </h3>
                <EditorContentPreview
                  className={
                    " mt-[10px] text-gray-109  max-w-[90%] Word-break dangerously-set-html"
                  }
                  data={challengeDetails?.additionalInformation?.information}
                />
              </div>
            ) : null}

            {challengeDetails?.additionalInformation?.documents?.length ? (
              <>
                <h3 className="mt-[30px] font-semibold text-lg text-gray-112 mb-[10px]">
                  {t("organization.artifact")}
                </h3>
                <div className="grid grid-cols-2 justify-between gap-[10px] mt-1">
                  {challengeDetails?.additionalInformation?.documents?.map(
                    (item: FileDetails, index: number) => (
                      <div className="flex-1 truncate" key={index}>
                        <UploadedFileItem key={item.fileUrl + new Date()} isPreview file={item} />
                      </div>
                    )
                  )}
                </div>
                <Divider className="mt-[30px] !border-gray-light" />
              </>
            ) : null}
            <div ref={contactRef}>
              <h3 className="mt-[30px] font-semibold text-lg mb-[10px] text-gray-109">
                {t("organization.point_of_contact")}
              </h3>
              <div className="flex flex-col gap-5">
                {challengeDetails?.contacts?.contacts?.map(
                  (item: OrganizationContact, index: number) => (
                    <ContactPreviewCard
                      key={index}
                      fullName={item?.fullName}
                      designation={item?.designation}
                      contactNumber={item?.contactNumber}
                      countryCodeId={item?.countryCodeId}
                      email={item?.email}
                    />
                  )
                )}
              </div>
            </div>

            <Divider className="mt-[30px] !border-gray-light" />
            {challengeDetails?.tags?.tags?.length ? (
              <div ref={tagsRef}>
                <h3 className="mt-[30px] font-semibold text-lg text-gray-112">
                  {t("organization.tags")}
                </h3>
                <div className="flex gap-3 mt-[11px] text-xs flex-wrap">
                  {challengeDetails?.tags?.tags?.map((value: OptionItem, index: number) => (
                    <Chip
                      key={value.id ? value.id + "" : index + ""}
                      text={i18n.language === "ar" ? value.name.ar : value.name.en}
                      className="!m-0"
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          {/* Navigator Model */}
          <div
            className={
              scroll
                ? ""
                : "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto sticky top-5 rounded-md p-5 pb-0 shadow-sticky border border-gray-110  ltr:lg:right-10 rtl:left-0 rtl:lg:left-10  z-[5] animate__animated animate__fadeInDown"
            }
          >
            <ProfileNavigationTimeline
              handleClick={handleNavigationClick}
              navigatorList={navigationItems}
              isViewOnly
            />
          </div>
        </div>
      </div>
      {isChallengeLoading || isPublishLoading ? <LoaderModal /> : null}
    </div>
  );
};

export default PreviewChallenge;
