import { useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import {
  LoaderModal,
  PrimaryButton,
  SecondaryButton,
  TextArea,
  Title,
  UploadButton,
  UploadedFileItem,
} from "components";
import { showConfirmationModal } from "utils/confirmationModals";
import {
  ToasterIconType,
  showErrorToastMessage,
  showSuccessToastMessage,
} from "utils/toasterMessage";
import { MAX_FILE_SIZE } from "utils/constants";
import { useReply } from "features/Collaboration/api/useReply";
import { useForumFileUpload } from "features/Collaboration/api/useForumFileUpload";
import { useDeleteForumFile } from "features/Collaboration/api/useDeleteForumFile";

type ReplyProps = {
  userName: string;
  collaborationId: number;
  postId: number;
  hideReplyOption: () => void;
  isPrivateReply: boolean;
};

export default function Reply({
  userName,
  collaborationId,
  postId,
  hideReplyOption,
  isPrivateReply,
}: ReplyProps) {
  const { t } = useTranslation();
  const pathName = window.location.pathname;
  const isExplore = pathName.includes("explore");
  const { mutateAsync: replyToPost, isLoading: isReplying } = useReply();
  const { mutateAsync: fileUpload, isLoading: isFileUploading } = useForumFileUpload();
  const { mutateAsync: deleteFile, isLoading: isFileDeleting } = useDeleteForumFile();

  const [data, setData] = useState<Reply>({
    reply: "",
    isPrivate: isPrivateReply ?? false,
    attachments: [],
    isExplore: isExplore,
  });
  const [error, setError] = useState("");

  const handleReply = async () => {
    if (data?.reply?.trim().length <= 0) {
      setError("required");
      return;
    }
    setError("");
    try {
      const result = await replyToPost({ collaborationId, postId, formData: data });
      hideReplyOption();
      showSuccessToastMessage({ message: result?.message });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const handleInputAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event?.target?.value;
    setData((prevState) => ({ ...prevState, reply: value }));
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event?.target?.files?.[0];
    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        event.target.value = "";
        return showErrorToastMessage({
          message: t("research_scholar.uploaded_files_size_error_message"),
        });
      }
      const fileName = selectedFile.name;
      const isFileExist = data?.attachments?.find((item) => item.name === fileName);
      if (isFileExist) {
        showErrorToastMessage({
          message: t("research_scholar.multiple_same_name_file_error_message"),
        });
        event.target.value = "";
        return;
      }
      try {
        const file = {
          id: -1,
          name: selectedFile?.name,
          fileUrl: "",
          size: selectedFile?.size,
        };
        const files = data?.attachments ? [...data.attachments, file] : [file];
        setData((prevState) => ({ ...prevState, attachments: files }));
        const result = await fileUpload({
          collaborationId,
          file: selectedFile,
        });
        if (result) {
          const files = data?.attachments ? [...data.attachments, result] : [result];
          setData((prevState) => ({ ...prevState, attachments: files }));
        }
      } catch (error) {
        showErrorToastMessage({
          message: t("global.something_went_wrong"),
        });
      } finally {
        event.target.value = "";
      }
    }
  };

  const handleFileRemove = async (
    event: React.MouseEvent<HTMLButtonElement>,
    item: FileDetails
  ) => {
    event.stopPropagation();
    event.preventDefault();
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.deletion_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      if (item.id) {
        const fileDetails = {
          id: item.id,
          name: item.name,
          fileUrl: item.fileUrl,
        };
        try {
          const response = await deleteFile({ collaborationId, fileDetails });
          const files = data?.attachments;
          const newFiles = files?.filter((file) => file.fileUrl !== item?.fileUrl);
          if (newFiles) {
            setData((prevState) => ({ ...prevState, attachments: newFiles }));
          }
          showSuccessToastMessage({
            message: response.message,
          });
        } catch (error: ApiError) {
          showErrorToastMessage({
            iconType: ToasterIconType.ALERT,
            message:
              error.response.data.status === 400
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
        }
      } else {
        const files = data?.attachments;
        const newFiles = files?.filter((file) => file.fileUrl !== item?.fileUrl);
        if (newFiles) {
          setData((prevState) => ({ ...prevState, attachments: newFiles }));
        }
        showSuccessToastMessage({
          message: t("global.document_deleted_successfully"),
        });
      }
    }
  };

  return (
    <>
      <div className="mt-6">
        {isPrivateReply ? (
          <div>
            <div className="hiddentext-gray-109 flex gap-2 items-center">
              <SVGIcon name="replies" />
              <span className="text-sm font-normal">
                {t("forum.replying_privately_to")} {userName}
              </span>
              <SVGIcon name="privateReply" />
            </div>
          </div>
        ) : (
          <div>
            <div className="text-gray-109 flex gap-2 items-center">
              <SVGIcon name="replies" />
              <span className="text-sm font-normal">
                {t("forum.replying_to")} {userName}
              </span>
            </div>
          </div>
        )}
        <div>
          <TextArea
            textContent={data?.reply}
            handleChange={handleInputAreaChange}
            labelText={""}
            hintText={t("global.enter_your_text_here")}
            fieldName={""}
            className="!border-primary-light"
            errorMessage={error}
          />
        </div>
        {data?.attachments ? (
          <div>
            <Title
              title={t("global.attachments")}
              className="text-sm text-primary-light font-normal mb-1 mt-4 hidden lg:flex"
            />

            {data?.attachments?.map((attaches) => (
              <>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2.5 w-full mt-1">
                  <Title
                    title={t("global.attachments")}
                    className="text-sm text-primary-light font-normal mb-2 mt-4 flex lg:hidden"
                  />
                  <UploadedFileItem
                    key={attaches.name + new Date()}
                    isFileUploading={isFileUploading}
                    handleRemove={(event: React.MouseEvent<HTMLButtonElement>) =>
                      handleFileRemove(event, attaches)
                    }
                    file={attaches}
                  />
                </div>
              </>
            ))}
          </div>
        ) : null}
        <div className="flex justify-between items-center gap-3 mt-5">
          <UploadButton
            label={"forum.attach_files"}
            icon="upload"
            handleFileUpload={handleFileUpload}
            disabled={(data && data.attachments && data.attachments.length >= 5) || isFileUploading}
          />
          <div className="flex gap-2 justify-center items-center w-full lg:w-auto ">
            <SecondaryButton
              title={"global.discard"}
              handleClick={hideReplyOption}
              className="!h-[40px] min-h-max flex-1 lg:flex-auto px-1.5  md:px-4 text-xs md:text-sm"
              disabled={isFileUploading}
            />
            <PrimaryButton
              title={"forum.post_reply"}
              handleClick={handleReply}
              className="!h-[40px] min-h-max flex-1 lg:flex-auto px-1.5  md:px-4 text-xs md:text-sm"
              disabled={isFileUploading}
            />
          </div>
        </div>
      </div>
      {isReplying || isFileDeleting ? <LoaderModal /> : null}
    </>
  );
}
