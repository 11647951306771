import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function inviteFundingOrganization(formData: FundingOrganizationBasicFields) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(urls.inviteFundingOrganization(), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useInviteFundingOrganization() {
  return useMutation(async (formData: FundingOrganizationBasicFields) => {
    return await inviteFundingOrganization(formData);
  });
}
