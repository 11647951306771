import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveChallengeFunding(formData: ChallengeFundingSave, challengeId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(urls.challengeFunding(challengeId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useSaveChallengeFunding() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ formData, challengeId }: { formData: ChallengeFundingSave; challengeId: number }) => {
      const data = await saveChallengeFunding(formData, challengeId);
      queryClient.invalidateQueries(["challenge-funding", challengeId]);
      queryClient.invalidateQueries(["challenge-details", challengeId]);
      queryClient.invalidateQueries(["challenge-preview-details", challengeId]);
      return data;
    }
  );
}
