import { useMutation } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function signUp(formData: SignUpFormData) {
  const res = await apiClient.post(urls.signUp(), formData);

  // if (res?.response?.data.status >= 400) throw response.data;
  return res.data as SignUpResponse | ApiError;
}

export function useSignUp() {
  return useMutation(async (formData: SignUpFormData) => {
    return await signUp(formData);
  });
}
