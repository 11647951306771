import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQueryClient } from "@tanstack/react-query";
import {
  ChallengeCard,
  Title,
  NoData,
  LoaderModal,
  InlineLoader,
  NoPermissionCard,
} from "components";
import {
  CHALLENGE_STATUS,
  PAGE_LIMIT,
  USERS,
  RESEARCH_SCHOLAR_CHALLENGE_INIT_PARAMS,
  GLOBAL_PORTAL_FILTERS,
} from "utils/constants";
import { useUserDataStore } from "store/useUserDataStore";
import { useGetChallengeList } from "features/Challenge/api/useGetChallengeList";
import { setSelectedSideMenu } from "store/useSidebarStore";
import { useMasterDataStore } from "store/useMasterDataStore";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import {
  resetScroll,
  setScrollInfo,
  useFilterDataStore,
  clearOrganizationFilterInfo,
} from "store/useFilterDataStore";
import { useSetScroll } from "hooks/useSetScroll";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";
import useResetQueryOnLanguageSwitch from "hooks/useResetQueryOnLanguageSwitch";
import { getGlobalFilterParam } from "utils/helpers";

type ChallengeRequestProps = {
  activeFilter: number;
  isScholarHomePage: boolean;
};

export default function ChallengeRequests({
  activeFilter,
  isScholarHomePage,
}: ChallengeRequestProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { userInfo } = useUserDataStore();

  const { permissions } = useMasterDataStore();
  const pathname = window.location.pathname;
  const { challengeFilterInfo, scrollInfo, userType } = useFilterDataStore();

  const { organizationFilterInfo } = useFilterDataStore();
  const showChallengeSignature =
    userInfo?.userType === USERS.Organization ||
    (userInfo?.userType === USERS.ResearchInstitute && pathname === "/research-institute/requests");

  const isGlobal = userInfo?.isGlobal
    ? true
    : getGlobalFilterParam(userType ?? GLOBAL_PORTAL_FILTERS.Both);
  const [requestParams, setRequestParams] = useState<ChallengeListParams>({
    ...RESEARCH_SCHOLAR_CHALLENGE_INIT_PARAMS,
    status: challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.All,
    researchArea: challengeFilterInfo?.researchAreaId ?? [],
    isGlobal,
    isOwned: true,
  });

  const {
    data: challengeList,
    isLoading: challengeListLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetChallengeList(requestParams);

  const displayContent = isScholarHomePage
    ? permissions?.Viewopenchallenges ||
      permissions?.Viewlivechallenges ||
      permissions?.Viewclosedchallenges
    : permissions?.Viewchallengerequests;

  const { scrollPosition } = useGetScrollPosition();

  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "challenge-list");
  useResetQueryOnLanguageSwitch("challenge-list");

  const challengeDetails = useMemo(() => {
    const tempData: ChallengeListingResponse = { challenges: [], totalCount: 0, totalPages: 0 };
    if (challengeList?.pages && challengeList?.pages[0]) {
      tempData.totalCount = challengeList.pages[0]?.totalCount;
      tempData.totalPages = challengeList.pages[0]?.totalPages;
      const challenges = challengeList.pages
        .map((item) => {
          return item.challenges;
        })
        .flat();
      tempData.challenges = challenges;
    }
    return tempData;
  }, [challengeList]);

  useEffect(() => {
    setSelectedSideMenu({
      type: isScholarHomePage ? "research_scholar.home" : "research_institute.requests",
    });
  }, [isScholarHomePage, pathname]);

  function handleChallengeClick(index: number) {
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: location.pathname,
      index: pageParam,
    });
  }
  useEffect(() => {
    if (
      organizationFilterInfo?.pathName &&
      location.pathname !== organizationFilterInfo?.pathName
    ) {
      clearOrganizationFilterInfo();
    }
  }, [organizationFilterInfo?.pathName]);

  useEffect(() => {
    if (scrollInfo?.pathName && location.pathname !== scrollInfo?.pathName) {
      resetScroll();
    }
  }, [scrollInfo?.pathName]);

  useEffect(() => {
    if (activeFilter !== requestParams.status) {
      queryClient.resetQueries(["challenge-list"]);
      if (activeFilter === CHALLENGE_STATUS.All) {
        setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.All }));
      } else if (activeFilter === CHALLENGE_STATUS.Open) {
        setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Open }));
      } else if (activeFilter === CHALLENGE_STATUS.Live) {
        setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Live }));
      } else if (activeFilter === CHALLENGE_STATUS.Close) {
        setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Close }));
      }
    }
  }, [activeFilter]);

  return displayContent ? (
    <>
      <>
        <div className="tab-content pt-6 w-full">
          <div className=" flex justify-between pb-5 items-center">
            <Title
              className=" text-primary-light text-sm leading-4 font-normal"
              title={`${
                challengeList?.pages[0]?.totalCount
                  ? challengeList?.pages[0]?.totalCount
                  : t("global.no")
              } ${t("organization.results_found")}`}
            />
          </div>

          {challengeDetails?.challenges?.length > 0 ? (
            <InfiniteScroll
              dataLength={challengeDetails?.challenges?.length}
              next={fetchNextPage}
              hasMore={!!hasNextPage}
              loader={hasNextPage || challengeListLoading ? <InlineLoader /> : null}
              scrollThreshold="200px"
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                {challengeDetails.challenges.map((item: ChallengeData, index: number) => (
                  <div key={index} className="">
                    <ChallengeCard
                      data={item}
                      showChallengeSignature={showChallengeSignature}
                      path={
                        item?.statusId === CHALLENGE_STATUS.Draft
                          ? `/organization/challenges/create/${item?.id}`
                          : ""
                      }
                      handleChallengeClick={() => handleChallengeClick(index)}
                    />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          ) : null}
          {!challengeListLoading && challengeDetails?.challenges?.length === 0 ? (
            <div>
              <NoData message={"admin.no_challenges_to_show"} />
            </div>
          ) : null}
        </div>
      </>
      {challengeListLoading ? <LoaderModal /> : null}
    </>
  ) : (
    <NoPermissionCard />
  );
}
