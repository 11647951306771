import { ReactElement } from "react";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import type {
  ActionMeta,
  FormatOptionLabelMeta,
  GroupBase,
  Options,
  OptionsOrGroups,
} from "react-select";
// eslint-disable-next-line no-duplicate-imports
import { components } from "react-select";
import {
  withAsyncPaginate,
  UseAsyncPaginateParams,
  ComponentProps,
  AsyncPaginate,
} from "react-select-async-paginate";
import { SEARCH_KEY_MAX_LENGTH, SEARCH_KEY_MIN_LENGTH } from "utils/constants";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { showErrorToastMessage } from "utils/toasterMessage";

type Option = OptionItem;

type AsyncPaginateCreatableProps<
  Option,
  Group extends GroupBase<Option>,
  Additional,
  IsMulti extends boolean
> = CreatableProps<Option, IsMulti, Group> &
  UseAsyncPaginateParams<Option, Group, Additional> &
  ComponentProps<Option, Group, IsMulti>;

type AsyncPaginateCreatableType = <
  Option,
  Group extends GroupBase<Option>,
  Additional,
  IsMulti extends boolean = false
>(
  props: AsyncPaginateCreatableProps<Option, Group, Additional, IsMulti>
) => ReactElement;

type MultiSelectProps = {
  options?: Array<Option> | [];
  labelText: string;
  hintText: string;
  selectedOptions: Array<OptionItem> | null;
  setSelectedOptions: (options: Array<OptionItem> | null) => void;
  isDisabled?: boolean;
  addNew?: boolean;
  loadOptions: (
    search: string,
    prevOptions: OptionsOrGroups<Option, GroupBase<Option>>,
    additional?: { page: number }
  ) => Promise<{
    options: Option[];
    hasMore: boolean;
  }>;
  errorToastMessage?: string;
  createLabel?: string;
  resetKey?: string;
  onMenuOpen?: () => void;
};

const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect) as AsyncPaginateCreatableType;

export function MultiSelect({
  options,
  labelText,
  hintText,
  selectedOptions,
  setSelectedOptions,
  isDisabled,
  addNew,
  loadOptions,
  errorToastMessage,
  createLabel,
  resetKey,
  onMenuOpen,
}: MultiSelectProps) {
  const { t, i18n } = useTranslation();

  const handleChange = (actionMeta: ActionMeta<Option>) => {
    if (actionMeta.action == "select-option") {
      let tempOptions = selectedOptions ? structuredClone(selectedOptions) : [];
      if (actionMeta.option) {
        const isExist = tempOptions?.some((item: OptionItem) => item.id === actionMeta.option?.id);
        if (!isExist) {
          tempOptions?.push(actionMeta.option);
        } else {
          tempOptions = tempOptions?.filter(
            (item: OptionItem) => item.id !== actionMeta.option?.id
          );
        }
      }
      setSelectedOptions(tempOptions);
    } else if (actionMeta.action == "deselect-option") {
      let tempOptions = selectedOptions ? structuredClone(selectedOptions) : [];
      if (actionMeta.option) {
        const isExist = tempOptions?.some((item: OptionItem) => item.id === actionMeta?.option?.id);
        if (isExist)
          tempOptions = tempOptions?.filter(
            (item: OptionItem) => item.id !== actionMeta?.option?.id
          );
      }
      setSelectedOptions(tempOptions);
    }
  };

  const handleCreate = (inputValue: string) => {
    if (inputValue?.trim().length < SEARCH_KEY_MIN_LENGTH) {
      return showErrorToastMessage({
        message: errorToastMessage ? t(errorToastMessage) : t("global.something_went_wrong"),
      });
    }

    setTimeout(() => {
      if (
        inputValue?.trim().length >= SEARCH_KEY_MIN_LENGTH &&
        inputValue?.trim().length <= SEARCH_KEY_MAX_LENGTH
      ) {
        const newOption: Option = createOption(inputValue);
        const tempOptions = selectedOptions ? structuredClone(selectedOptions) : [];
        const isExist = tempOptions.some((item: OptionItem) =>
          i18n.language === "ar"
            ? item.name.ar === newOption.name.ar
            : item?.name?.en?.toLowerCase() === newOption?.name?.en?.toLowerCase()
        );
        if (!isExist) tempOptions?.push(newOption);
        setSelectedOptions([...tempOptions]);
      }
    }, 1000);
  };

  const createOption = (label: string) => {
    const newLabel = label.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    return {
      id: 0,
      name: {
        en: i18n.language !== "ar" ? newLabel.trim() : "",
        ar: i18n.language === "ar" ? label : "",
      },
    };
  };

  return (
    <div>
      <label className="label p-0 pb-[10px] text-primary-light text-sm">{labelText}</label>
      <SelectComponent
        addNew={addNew}
        options={options}
        value={selectedOptions}
        loadOptions={loadOptions}
        isClearable={false}
        isMulti={true}
        placeholder={hintText}
        noOptionsMessage={() => t("global.no_results")}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        onChange={(newValue: any, actionMeta: any) => handleChange(actionMeta)}
        classNamePrefix={`multipleSelect`}
        isDisabled={isDisabled}
        formatOptionLabel={(data: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) =>
          i18n.language === "ar"
            ? data?.name?.ar
            : data?.name?.en
            ? data?.name?.en
            : createLabel
            ? `${formatOptionLabelMeta.inputValue} (${t(createLabel)})`
            : ""
        }
        getOptionValue={(option: Option | any) => {
          return (option?.id as string) || `${option.value}`;
        }}
        handleCreate={handleCreate}
        createLabel={createLabel}
        resetKey={resetKey}
        onMenuOpen={onMenuOpen}
      />
    </div>
  );
}

type SelectProps = {
  isClearable: boolean;
  isMulti: boolean;
  options?: Array<Option> | [];
  placeholder: string;
  noOptionsMessage: () => string;
  controlShouldRenderValue: boolean;
  hideSelectedOptions: boolean;
  onChange: (newValue: any, actionMeta: any) => void;
  classNamePrefix?: string;
  value: Array<Option> | null;
  isDisabled?: boolean;
  addNew?: boolean;
  handleCreate: (value: string) => void;
  loadOptions: (
    search: string,
    prevOptions: OptionsOrGroups<Option, GroupBase<Option>>,
    additional?: { page: number }
  ) => Promise<{
    options: Option[];
    hasMore: boolean;
  }>;
  formatOptionLabel: (data: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) => string;
  getOptionValue: (option: Option) => string;
  createLabel?: string;
  resetKey?: string;
  onMenuOpen?: () => void;
};

function SelectComponent({
  addNew,
  handleCreate,
  loadOptions,
  formatOptionLabel,
  getOptionValue,
  options,
  resetKey,
  noOptionsMessage,
  onMenuOpen,
  ...rest
}: SelectProps) {
  const { t } = useTranslation();

  const NoOptionsMessage = () => {
    return <div>{t("global.no_results")}</div>;
  };

  function isValidNewOption(inputValue: string, selectValue: Options<Option>, selectOptions: any) {
    if (!inputValue) {
      return false;
    }

    let isValid = true;
    for (const option of selectOptions) {
      if (isOptionMatchesInputValue(option, inputValue)) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  function isOptionMatchesInputValue(option: Option, inputValue: string) {
    const name = option?.name;
    const ln = i18n.language === "ar" ? "ar" : "en";
    if (i18n.language === "en") {
      return String(name?.[ln]).toLowerCase() === String(inputValue).toLowerCase();
    } else {
      return String(name?.[ln]) === String(inputValue);
    }
  }
  if (addNew)
    return (
      <CreatableAsyncPaginate
        {...rest}
        onMenuOpen={onMenuOpen}
        loadOptionsOnMenuOpen
        getOptionValue={getOptionValue}
        formatOptionLabel={formatOptionLabel}
        loadOptions={loadOptions}
        onCreateOption={handleCreate}
        classNamePrefix="multiSelect"
        isValidNewOption={isValidNewOption}
        components={{
          Input: (props) => <components.Input {...props} maxLength={SEARCH_KEY_MAX_LENGTH} />,
        }}
        key={resetKey}
      />
    );
  else
    return (
      <AsyncPaginate
        {...rest}
        onMenuOpen={onMenuOpen}
        loadOptionsOnMenuOpen
        getOptionValue={getOptionValue}
        formatOptionLabel={formatOptionLabel}
        loadOptions={loadOptions}
        classNamePrefix="multiSelect"
        components={{
          Input: (props) => <components.Input {...props} maxLength={SEARCH_KEY_MAX_LENGTH} />,
        }}
        key={resetKey}
        noOptionsMessage={NoOptionsMessage}
      />
    );
}
