import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getUserStatus(userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.getUserStatus(userId), {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res.data as UserStatusResponse | ApiError;
}
