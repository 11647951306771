import React from "react";
import { useTranslation } from "react-i18next";

type FileUploadProps = {
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  acceptedTypes?: Array<string>;
  multiple?: boolean;
  className?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  title?: string;
};

export function FileUpload({
  handleFileUpload,
  acceptedTypes,
  multiple,
  className,
  icon,
  disabled,
  title,
}: FileUploadProps) {
  const { t } = useTranslation();
  return (
    <div
      className={`uploadContainer flex ite bg-gray-light/60 py-3.5 justify-center rounded items-center mb-[22px]  ${
        disabled ? "opacity-50 pointer-events-none" : ""
      } ${className}`}
    >
      <span className="text-primary-medium text-sm ltr:pr-6 rtl:pl-6">
        {t(title ? title : "global.upload_from_pc")}
      </span>

      <div className=" relative w-[35px] min-w-[35px] h-[35px] rounded-full bg-white border border-primary-medium border-dashed flex items-center justify-center upload-file-section">
        <label className="text-primary-medium">{icon}</label>
        <input
          type="file"
          multiple={multiple ?? false}
          accept={acceptedTypes?.join()}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event)}
          className=" opacity-0 absolute cursor-pointer w-full h-full -indent-[999px] typeFile"
          disabled={disabled}
        />
      </div>
      {/* if type  is image add image icon here 
      if type is doc add doc icon here */}
    </div>
  );
}
