import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { CoverImage, ProfilePicture, TextButton } from "components";

type CoverImageAreaProps = {
  url: string;
  errorMessage: string;
  handleClick: () => void;
  handleDeleteClick: () => void;
  isDisabled?: boolean;
};

const CoverImageArea = ({
  url,
  errorMessage,
  handleClick,
  handleDeleteClick,
  isDisabled,
}: CoverImageAreaProps) => {
  const { t } = useTranslation();
  if (url && url.length > 0) {
    return (
      <div className="h-[200px] bg-white w-full rounded coverImageChallenges overflow-hidden relative">
        <CoverImage src={url} handleDeleteClicked={handleDeleteClick} isDisabled={isDisabled}/>
        <span
            className=" btn absolute bottom-2.5 right-2.5 !bg-transparent border-none min-w-max min-h-min p-0 m-0 h-auto"
            onClick={handleDeleteClick}
          >
            {isDisabled ? null : (<SVGIcon name="roundDeleteIcon" />)}
          </span>
      </div>
    );
  } else {
    return (
      <div
        className={`w-full h-full flex flex-col py-[30px] justify-center items-center bg-gray-light/60 rounded ${
          errorMessage ? "!border-red-200" : "border-primary-light"
        }`}
      >
        <ProfilePicture
          disabled={isDisabled}
          url={""}
          handleClick={() => {
            handleClick();
          }}
        />
        <TextButton
          title={"global.upload_image"}
          className="text-primary-medium mt-6 text-sm"
          handleClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClick();
          }}
          isDisabled={isDisabled}
        />
      </div>
    );
  }
};

export default CoverImageArea;
