import React from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";

type LearnMoreModalProps = {
  data: string;
  title: string;
  onclose: () => void;
};

function LearnMoreModal({ data, title, onclose }: LearnMoreModalProps) {
  const { t } = useTranslation();

  return (
    <div className="fixed left-0 top-0 md:relative md:left-auto md:top-auto rtl:right-0">
      <div className="fixed max-h-[100vh] md:max-h-[90vh] md:relative h-screen md:h-auto  max-w-[100vw] w-[100vw] min-w-[100vw]  md:w-auto md:max-w-[754px] md:min-w-[700px] xl:max-w-[700px] xl:min-w-[700px]  bg-white py-[64px] pb-20 px-5 md:p-[60px] xl:p-20    md:!rounded-md overflow-auto !rounded-none xl:pr-[100px]">
        <div className="text-skin-textPrimary relative font-semibold mb-5 text-[26px] leading-[34px] tracking-[-0.26px] xl:text-[29px] xl:leading-[44px] xl:tracking-[-0.29px;] ">
          {t(title)}
        </div>
        <p className=" text-[15px] text-skin-textBase leading-[22px] md:text-base md:leading-[26px]">
          {t(data)}
        </p>
        <div className="modal-action">
          <button
            className="btn text-skin-textPrimary  top-5 right-5  !h-auto p-0 absolute !border-none !min-h-max !bg-transparent "
            onClick={onclose}
          >
            <SVGIcon name="h-modalClose" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default LearnMoreModal;
