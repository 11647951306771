import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputField, PrimaryButton, SingleSelect, LoaderModal } from "components";
import { useGetPublicMasterData } from "hooks/useGetPublicMasterData";
import { useGetOrganizationTypes } from "features/Admin/api/useGetOrganizationTypes";
import { DEFAULT_MAX_CHAR_LENGTH, EMAIL_MAX_CHAR_LENGTH } from "utils/constants";
import { phoneMaxLimitChecker, setMasterDataValue } from "utils/helpers";
import { organizationSignUpValidation } from "utils/validation/organizationSignUpValidation";
import { validateEmailAddress } from "features/ResearchScholar/api/useValidateEmail";
import { showErrorToastMessage } from "utils/toasterMessage";

type SignUpFormProps = {
  signUpFormData: OrganizationBasicFields;
  handleChange: (value: string | number, fieldName: string) => void;
  movetoNext: () => void;
};
export default function SignUpForm({ signUpFormData, handleChange, movetoNext }: SignUpFormProps) {
  const { t, i18n } = useTranslation();

  const { data: publicMasterData, isLoading: publicMasterDataLoading } = useGetPublicMasterData();
  const { data: organizationTypes, isLoading: isOrganizationTypesLoading } =
    useGetOrganizationTypes();
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const value = event.target.value;
    handleChange(value, fieldName);
  };

  const countryCodeOptions = useMemo(() => {
    return publicMasterData?.countryCodes?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [publicMasterData, i18n.language]);

  const organizationTypeOptions = useMemo(() => {
    return organizationTypes?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [organizationTypes, i18n.language]);

  const handleContinueClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const error = organizationSignUpValidation(signUpFormData);
    if (error) {
      setValidationErrors(error);
      return;
    }
    setValidationErrors(null);
    try {
      await validateEmailAddress(signUpFormData.email);
      movetoNext();
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
        position: i18n.language === "ar" ? "top-right" : "top-left",
      });
    }
  };

  const handleCountryCodeChange = (value: string | number) => {
    handleChange(value as number, "countryCodeId");
  };

  const handleOrganizationTypeChange = (value: string | number) => {
    handleChange(value as number, "organizationType");
  };
  return (
    <>
      <div className="  flex flex-col h-full">
        <div className=" mb-auto space-y-5">
          <InputField
            hintText={t("organization.enter_organization_name")}
            labelText={t("organization.organization_name")}
            handleInputChange={handleInputChange}
            fieldName="organizationName"
            isRequired
            errorMessage={validationErrors?.organizationName}
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
            value={signUpFormData.organizationName}
          />
          <SingleSelect
            options={organizationTypeOptions || []}
            labelText={t("organization.organization_type")}
            hintText={t("global.select")}
            handleChange={handleOrganizationTypeChange}
            isRequired
            errorMessage={validationErrors?.organizationType}
            selectOption={signUpFormData.organizationType}
          />
          <InputField
            hintText={t("organization.enter_trade_license_number")}
            labelText={t("organization.trade_license_number")}
            handleInputChange={handleInputChange}
            fieldName="tradingLicenseNumber"
            isRequired
            maxCharLength={DEFAULT_MAX_CHAR_LENGTH}
            errorMessage={validationErrors?.tradingLicenseNumber}
            value={signUpFormData.tradingLicenseNumber}
          />
          <InputField
            hintText={t("global.enter_email_address")}
            labelText={t("global.email_address")}
            handleInputChange={handleInputChange}
            fieldName="email"
            isRequired
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
            errorMessage={validationErrors?.email}
            value={signUpFormData.email}
          />
          <SingleSelect
            options={countryCodeOptions || []}
            labelText={t("global.country_code")}
            hintText={t("global.select")}
            handleChange={handleCountryCodeChange}
            isRequired
            errorMessage={validationErrors?.countryCodeId}
            selectOption={signUpFormData.countryCodeId}
          />
          <InputField
            hintText={t("global.enter_contact_number")}
            labelText={t("global.contact_number")}
            handleInputChange={handleInputChange}
            fieldName="contactNumber"
            isRequired
            errorMessage={validationErrors?.contactNumber}
            maxCharLength={phoneMaxLimitChecker(signUpFormData.contactNumber)}
            value={signUpFormData.contactNumber}
          />
        </div>
        <div className="pt-14">
          <PrimaryButton
            title={"global.continue"}
            handleClick={handleContinueClick}
            className="default-primary-button w-full mb-8"
          />
        </div>
      </div>
      {publicMasterDataLoading || isOrganizationTypesLoading ? <LoaderModal /> : null}
    </>
  );
}
