import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { USER_STATUS } from "utils/constants";
import { urls } from "utils/urls";

async function saveOrganizationDetails(formData: OrganizationDetails, userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  if (formData.profileStatusId === USER_STATUS.New) {
    return await apiClient.post(urls.organization(), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    return await apiClient.put(urls.organization(userId), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}

export function useSaveOrganizationDetails() {
  const qc = useQueryClient();
  return useMutation(
    async ({
      formData,
      organizationId,
    }: {
      formData: OrganizationDetails;
      organizationId: string;
    }) => {
      const userInfo = useUserDataStore.getState().userInfo;
      const userId = organizationId ? organizationId : userInfo?.userId;
      if (userId) {
        const result = await saveOrganizationDetails(formData, userId);
        qc.invalidateQueries({ queryKey: ["organization-details", userId] });
        if (formData.profileStatusId === USER_STATUS.New) {
          qc.invalidateQueries({ queryKey: ["organization-contacts", userId] });
          qc.invalidateQueries({ queryKey: ["organization-offices", userId] });
        }
        return result;
      }
    }
  );
}
