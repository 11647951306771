import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { USER_STATUS } from "utils/constants";
import { urls } from "utils/urls";

async function savePersonalDetails(userId: string, formData: PersonalDetails) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  if (formData.profileStatusId === USER_STATUS.New) {
    return await apiClient.post(urls.savePersonalDetails(), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    return await apiClient.put(urls.personalDetails(userId), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}

export function useSavePersonalDetails() {
  const qc = useQueryClient();
  return useMutation(
    async ({
      formData,
      researchScholarId,
    }: {
      formData: PersonalDetails;
      researchScholarId: string;
    }) => {
      const userInfo = useUserDataStore.getState().userInfo;
      const userId = researchScholarId ? researchScholarId : userInfo?.userId;
      if (userId) {
        const result = await savePersonalDetails(userId, formData);
        qc.invalidateQueries({ queryKey: ["personal-details", userId] });
        if (formData.profileStatusId === USER_STATUS.New) {
          qc.invalidateQueries({ queryKey: ["education-details", userId] });
          qc.invalidateQueries({ queryKey: ["social-profile", userId] });
          qc.invalidateQueries({ queryKey: ["work-experience-details", userId] });
        }
        return result;
      }
    }
  );
}
