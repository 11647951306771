import React from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BannerImg1 from "assets/images/home-images/BannerImg1.png";
import BannerImg2 from "assets/images/home-images/BannerImg2.png";
import BannerImg3 from "assets/images/home-images/BannerImg3.png";
import BannerImg4 from "assets/images/home-images/BannerImg4.png";
import BannerImg5 from "assets/images/home-images/BannerImg5.png";
import GlobalBannerImg1 from "assets/images/home-images/GlobalBannerImg1.png";
import GlobalBannerImg2 from "assets/images/home-images/GlobalBannerImg2.png";
import GlobalBannerImg3 from "assets/images/home-images/GlobalBannerImg3.png";
import GlobalBannerImg4 from "assets/images/home-images/GlobalBannerImg4.png";
import GlobalBannerImg5 from "assets/images/home-images/GlobalBannerImg5.png";
import BannerLogo from "assets/images/home-images/bannerLogo.svg";
import { PrimaryButton } from "components";
import { showLearnMoreModal } from "utils/confirmationModals";
import { isGlobalWebsite } from "utils/helpers";

const isGlobal = isGlobalWebsite();

const slideDetails = [
  {
    id: 1,
    SliderImage: isGlobal ? GlobalBannerImg1 : BannerImg1,
    SliderHeading: "admin.challenges",
    sliderDescription: "home.this_platform_seeks",
  },
  {
    id: 2,
    SliderImage: isGlobal ? GlobalBannerImg2 : BannerImg2,
    SliderHeading: isGlobal ? "global.collaborations" : "home.researchers",
    sliderDescription: isGlobal
      ? "home.the_platform_will_global_content"
      : "home.the_platform_will",
  },
  {
    id: 3,
    SliderImage: isGlobal ? GlobalBannerImg3 : BannerImg3,
    SliderHeading: "home.startups",
    sliderDescription: "home.the_portal_recognizes",
  },
  {
    id: 4,
    SliderImage: isGlobal ? GlobalBannerImg4 : BannerImg4,
    SliderHeading: "home.industries",
    sliderDescription: "home.the_portal_bridges",
  },
  {
    id: 5,
    SliderImage: isGlobal ? GlobalBannerImg5 : BannerImg5,
    SliderHeading: "home.funding_opportunities",
    sliderDescription: isGlobal ? "home.the_portal_acts_global_content" : "home.the_portal_acts",
  },
];
const settings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  cssEase: "linear",
  appendDots: (dots: any) => (
    <div
      style={{
        padding: "8px",
      }}
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
  customPaging: (i: any) => (
    <p className="pagination-main-container">
      <span>0</span>
      <p className="number-pagination">{i + 1}</p>
    </p>
  ),
};

const handleLearnMore = (data: string, title: string) => {
  showLearnMoreModal(data, title);
};

export default function Banner() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSignUpNavigation = () => {
    navigate("/research-scholar/sign-up");
  };

  return (
    <div className="w-full h-full bg-white">
      <div className="w-full h-screen min-h-[790px] overflow-hidden relative rounded-b-xl home-about-us-slider pt-[136px] max-[1023px]:min-h-full max-[767px]:min-h-[530px] max-[1023px]:overflow-visible banner-slider">
        <Slider {...settings}>
          {slideDetails.map((item, index) => (
            <div key={item.id} className="h-full w-full overflow-hidden relative">
              <img
                src={item.SliderImage}
                alt="banner image"
                className="w-full h-full min-h-[calc(100vh-136px)]  max-w-full  object-cover rtl:scale-x-[-1] banner-slider-image"
              />
              <div className="slide-content w-full h-full absolute top-0 left-0 right-0">
                <div className="w-full h-full relative">
                  <div className="h-[578px] w-[885px] max-[1023px]:h-[350px] max-[1023px]:w-[587px] max-[767px]:h-[323px] max-[767px]:w-[490px] slide-content-inner-wrapper">
                    <div className="relative w-full h-full overflow-hidden">
                      <img
                        src={BannerLogo}
                        alt="banner-logo"
                        className="w-full h-full rtl:scale-x-[-1]"
                      />

                      <div className="absolute ltr:left-[80px] rtl:right-[80px] bottom-[82px]  max-[1370px]:ltr:left-[40px] max-[1370px]:rtl:right-[40px] max-[1400px]:ltr:left-[60px] max-[1400px]:rtl:right-[60px]  max-[1023px]:bottom-[40px] max-[767px]:rtl:right-[20px] max-[767px]:ltr:left-[20px]  max-[767px]:bottom-[25px]">
                        <h1 className="text-[65px] font-semibold leading-[60px] text-skin-textSecondary03 mb-[30px] max-w-[360px] rtl:text-end rtl:ml-auto max-[1023px]:text-[42px] max-[1023px]:leading-[40px] max-[1023px]:tracking-[-2px] max-[1023px]:mb-[10px] max-[1023px]:max-w-[255px] max-[767px]:leading-[52px]">
                          {t(item.SliderHeading)}
                        </h1>
                        <p className="text-[20px] font-medium leading-7 tracking-[0.1px] text-white max-w-[600px] mb-[40px] rtl:text-end rtl:ml-auto max-[1023px]:text-[16px] max-[1023px]:leading-[25px] max-[1023px]:mb-6 max-[1023px]:max-w-[378px] max-[767px]:leading-[22px] max-[767px]:text-[15px] max-[767px]:mb-5 max-[767px]:w-[350px] line-clamp-3 banner-slider-description">
                          {t(item.sliderDescription)}
                        </p>
                        <div className="flex items-center gap-5  rtl:justify-end">
                          {index + 1 === 1 || index + 1 === 2 ? (
                            <PrimaryButton
                              className="!bg-skin-secondaryDark py-4 px-[30px] min-w-[170px] !text-[17px] !text-white font-semibold leading-6 min-h-[44px] h-[56px] flex flex-col items-center justify-center border-none rtl:order-1 max-[1023px]:py-[10px] max-[1023px]:px-[12px] max-[1023px]:min-w-[170px] max-[1023px]:h-[48px] max-[1023px]:!text-[16px] max-[1023px]:font-medium max-[1023px]:leading-[25px] max-[767px]:!text-[15px] max-[767px]:!leading-[20px] sign-up-button"
                              title={"global.sign_up"}
                              handleClick={handleSignUpNavigation}
                            />
                          ) : null}
                          <PrimaryButton
                            className="!bg-transparent border border-white hover:!border-white py-4 px-[30px] min-w-[170px] !text-white !text-[17px] font-semibold leading-6 min-h-[44px] h-[56px] flex flex-col items-center justify-center learn-more-button max-[1023px]:pt-[11px] max-[1023px]:pb-[12px] max-[1023px]:h-[48px] max-[1023px]:!text-[16px] max-[1023px]:font-medium max-[1023px]:leading-[25px] max-[767px]:!text-[15px] max-[767px]:!leading-[20px]"
                            title={"organization.learn_more"}
                            endIcon="rightArrow"
                            handleClick={() =>
                              handleLearnMore(item?.sliderDescription, item?.SliderHeading)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
