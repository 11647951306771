import SVGIcon from "assets/Icons";
import { useTranslation } from "react-i18next";
import {
  Divider,
  SubTitle,
  Title,
  InputField,
  PrimaryButton,
  RichTextEditor,
  SecondaryButton,
  LoaderModal,
} from "components";

import { displayDateFormat, getChallengeStatus, getChallengeStatusBackground } from "utils/helpers";
import DefaultCoverImage from "assets/images/challengeDefaultProfile.png";
import { useState } from "react";
import { challengeFundingInterestMessageValidation } from "utils/validation/challengeFundingInterestMessageValidation";
import { useSendFundingInterestMessage } from "features/Challenge/api/useSendFundingInterestMessage";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import { EMAIL_MAX_CHAR_LENGTH } from "utils/constants";

type ShareFundingInterestModalProps = {
  challengeDetails: ChallengeInfo;
  onClose: () => void;
};

const ShareFundingInterestModal = ({
  onClose,
  challengeDetails,
}: ShareFundingInterestModalProps) => {
  const { t } = useTranslation();
  const { mutateAsync: sendFundingInterestMessage, isLoading: isSaving } =
    useSendFundingInterestMessage();
  const coverImage =
    challengeDetails.coverImage.length > 0 ? challengeDetails.coverImage : DefaultCoverImage;

  const initialState: FundingInterestMessage = {
    subject: "",
    message: "",
  };
  const [formState, setFormState] = useState<FundingInterestMessage>(initialState);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof FundingInterestMessage
  ) => {
    const value = event?.target?.value;
    setFormState((previousState) => ({ ...previousState, [key]: value }));
  };
  const handleEditorInputChange = (text: string, key: keyof FundingInterestMessage) => {
    setFormState((previousState) => ({ ...previousState, [key]: text }));
  };

  const handleSave = async () => {
    if (challengeDetails) {
      try {
        const error = challengeFundingInterestMessageValidation(formState);
        if (error) {
          setValidationErrors(error);
          return;
        }
        setValidationErrors(null);
        const result = await sendFundingInterestMessage({
          challengeId: challengeDetails?.id,
          formData: formState,
        });
        if (result) {
          showInfoToastMessage({
            iconType: ToasterIconType.APPROVED,
            message: result.message,
          });
          onClose();
        }
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };
  return (
    <div className="bg-white rounded-md sm:w-[627px] lg:w-[827px]">
      <div className="flex items-center justify-between p-6">
        <div className="text-base font-semibold text-black flex items-center gap-3">
          <SVGIcon name="shareInterest" />
          <Title title={t("organization.share_funding_interest")} />
        </div>
        <span onClick={onClose} className="cursor-pointer">
          <SVGIcon name="close" />
        </span>
      </div>
      <Divider className="mr-6" />
      <div className="p-6 max-h-[calc(100vh-120px)] h-full overflow-auto">
        <div className="bg-gray-light w-full p-3 rounded-md ">
          <SubTitle
            title={t("organization.share_funding_interest_note")}
            className="text-sm font-normal text-gray-109"
          />
        </div>
        <div className="mt-6 flex items-center gap-5 p-4 shadow-100 rounded-md">
          <img src={coverImage} alt="display image" className="h-[120px] w-[120px] rounded-md" />
          <div>
            <Title
              title={challengeDetails.title}
              className="text-[22px] font-semibold text-black mb-1"
            />
            <span
              className={`px-[12px] py-[6px] rounded-[12px] text-xs font-normal bg-badge-open ${getChallengeStatusBackground(
                challengeDetails.challengeStatusId ?? 0
              )}`}
            >
              {t(`${getChallengeStatus(challengeDetails.challengeStatusId ?? 0)}`)}
            </span>
            <div className="flex gap-6 text-sm font-normal text-black mt-[18px] flex-wrap gap-y-3">
              <div className="flex items-center gap-2 WB_BW">
                <span className=" min-w-[16px]">
                  <SVGIcon name="mapPin" />
                </span>
                <Title className="WB_BW" title={challengeDetails.location} />
              </div>
              <div className="flex items-center gap-2">
                <SVGIcon name="date" />
                <Title
                  title={`${t("global.start_date")} : ${displayDateFormat(
                    challengeDetails?.startDate ?? ""
                  )}`}
                  className="truncate"
                />
              </div>
              <div className="flex items-center gap-2">
                <SVGIcon name="date" />
                <Title
                  title={`${t("global.end_date")} : ${displayDateFormat(
                    challengeDetails?.endDate ?? ""
                  )}`}
                  className="truncate"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <InputField
            className="w-full"
            hintText={t("funding_organization.funding_interest_subject_placeholder")}
            labelText={t("organization.subject")}
            fieldName={"subject"}
            handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(event, "subject")
            }
            isRequired
            errorMessage={validationErrors?.subject}
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
          />
          <div
            className={`border mt-6 rounded-md w-full ${
              validationErrors?.message ? "!border-red-200" : "border-primary-light"
            }`}
          >
            <RichTextEditor
              text={formState.message}
              handleTextChange={(text: string) => {
                handleEditorInputChange(text, "message");
              }}
              placeholder={t("funding_organization.funding_interest_message_placeholder")}
            />
          </div>
        </div>
        <div className="flex mt-8 justify-center items-center gap-2">
          <SecondaryButton title={"global.discard"} handleClick={onClose} />
          <PrimaryButton className="" title={"global.submit"} handleClick={handleSave} />
        </div>
      </div>
      {isSaving ? <LoaderModal /> : null}
    </div>
  );
};
export default ShareFundingInterestModal;
