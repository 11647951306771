import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { showErrorToastMessage } from "utils/toasterMessage";

import { urls } from "utils/urls";

async function getResearchInstituteDetails(userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.researchInstituteProfileDetails(userId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.data?.data as ResearchInstitutePreview | ApiError;
}

export function useGetResearchInstituteDetails(userId: string) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return useQuery(
    ["research-institute-details", userId],
    async () => {
      const result = await getResearchInstituteDetails(userId);
      return result;
    },
    {
      retry: 0,
      onError: (error: ApiError) => {
        showErrorToastMessage({
          message:
            error?.response?.status < 500
              ? error?.response?.data?.message
              : t("global.something_went_wrong"),
        });
        if (error?.response?.status !== 423) {
          navigate(-1);
        }
      },
      refetchOnWindowFocus: true,
    }
  );
}
