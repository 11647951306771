import React from "react";
import { useTranslation } from "react-i18next";
import { InputField, TextArea, DividerWithText, PrimaryButton } from "components";
import {
  setOfficeDetailsInfo,
  setOfficeDetailsInfoItem,
  useOrganizationDataStore,
} from "store/useOrganizationDataStore";
import { ADDRESS_MAX_CHAR_LENGTH, EMAIL_MAX_CHAR_LENGTH } from "utils/constants";
import { showConfirmationModal } from "utils/confirmationModals";

type OfficeFormProps = {
  validationErrors: Array<Record<string, string>> | null;
  removeValidationErrors: () => void;
  isReadOnly: boolean;
};

export default function OfficeForm({
  removeValidationErrors,
  validationErrors,
  isReadOnly,
}: OfficeFormProps) {
  const { officeDetails } = useOrganizationDataStore();
  const { t } = useTranslation();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    key: keyof OrganizationOffice,
    index: number
  ) => {
    const value = e.target.value;
    setOfficeDetailsInfoItem(value, key, index);
  };

  const handleDeleteOffice = async (index: number) => {
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.office_delete_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      modalIcon: "confirmDelete",
      isAlert: true,
    });
    if (!result) {
      return;
    }
    if (result) {
      const tempOfficeInfo = structuredClone(officeDetails);
      tempOfficeInfo?.offices.splice(index, 1);
      if (tempOfficeInfo)
        setOfficeDetailsInfo({
          ...tempOfficeInfo,
        });
    }
    removeValidationErrors();
  };
  return (
    <>
      {officeDetails && officeDetails?.offices?.length > 0
        ? officeDetails?.offices?.map((officeItem, index) => (
            <div className=" pb-10" key={`office ${index}`}>
              <div className=" space-y-[22px] px-4 lg:px-5 pb-8 ">
                <DividerWithText text={`${t("organization.office")} ${index + 1}`} />

                <InputField
                  hintText={t("organization.enter_office_name")}
                  labelText={t("organization.office_name")}
                  handleInputChange={(event) => handleInputChange(event, "name", index)}
                  fieldName="name"
                  isRequired
                  maxCharLength={EMAIL_MAX_CHAR_LENGTH}
                  errorMessage={validationErrors?.[index]?.name}
                  isDisabled={isReadOnly}
                  value={officeItem?.name}
                />
                <TextArea
                  textContent={officeItem?.address}
                  handleChange={(event) => handleInputChange(event, "address", index)}
                  labelText={t("global.address")}
                  hintText={t("organization.enter_office_address")}
                  fieldName="about"
                  isRequired
                  maxCharLength={ADDRESS_MAX_CHAR_LENGTH}
                  errorMessage={validationErrors?.[index]?.address}
                  isDisabled={isReadOnly}
                />
              </div>
              <div className=" flex justify-center pt-[10px]">
                <PrimaryButton
                  title={"global.delete_office"}
                  handleClick={() => handleDeleteOffice(index)}
                  startIcon="trashV1"
                  className=" !h-10 !min-h-0 !w-[150px] !min-w-[150px] !rounded-sm !mb-8 !text-xs !px-0 font-medium bg-white !text-red-dark !border-red-dark mx-auto hover:!bg-red-dark/10 hover:!border-red-dark/30"
                  disabled={isReadOnly}
                />
              </div>
            </div>
          ))
        : null}
    </>
  );
}
