import { useTranslation } from "react-i18next";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import Tippy from "@tippyjs/react";
import { displayDateFormat, getColumns } from "utils/helpers";
import SVGIcon from "assets/Icons";

type TableProps = {
  userData: Array<ChallengeUser>;
  headers: Array<string>;
  type: number;
  handleItemClick: (challengeId: number) => void;
  handleWithDraw?: (event: React.MouseEvent<HTMLDivElement>, scholarId: string) => void;
};

const NominatedScholarsTable = ({
  userData,
  headers,
  type,
  handleItemClick,
  handleWithDraw,
}: TableProps) => {
  const { t } = useTranslation();

  const filteredData = userData?.map((item) => {
    return {
      createdOn: item.createdOn,
      fullName: item.fullName,
      researchAreas: item.researchAreas,
      country: item.country,
      statusName: item.statusName,
    };
  });

  const dataTable = useReactTable({
    data: userData,
    columns: filteredData && filteredData.length ? getColumns(filteredData) : [],

    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="customTable nominated-scholars-table">
      <table>
        <thead>
          {headers?.map((item) => (
            <th key={item}>{t(item)}</th>
          ))}
          <th>{t("research_institute.withdraw")}</th>
        </thead>
        <tbody>
          {dataTable.getRowModel().rows.map((row) => (
            <tr
              className="cursor-pointer"
              key={row.id}
              onClick={() => handleItemClick(row.original?.userId)}
            >
              {row.getVisibleCells().map((cell, index) => {
                if (cell.column.columnDef.header?.toString().trim() == "createdOn") {
                  return <td key={index}>{displayDateFormat(cell.getValue() as string)}</td>;
                } else if (cell.column.columnDef.header?.toString().trim() === "fullName") {
                  return (
                    <td key={index}>
                      <p className="text-primary-medium underline truncate">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </p>
                    </td>
                  );
                } else if (cell.column.columnDef.header?.toString().trim() == "researchAreas") {
                  if (cell.getValue()) {
                    return (
                      <td key={index}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  }
                  return <td key={index}>-</td>;
                } else {
                  return (
                    <td key={index}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  );
                }
              })}
              <td>
                <div
                  className={`btn btn-link h-auto min-h-[auto] p-0 text-primary-medium rtl:justify-end ltr:justify-start ${
                    row?.original?.statusName !== t("global.pending")
                      ? "opacity-50 cursor-default"
                      : ""
                  }`}
                  onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    if (row?.original?.statusName === t("global.pending")) {
                      handleWithDraw?.(event, row?.original?.userId);
                    } else {
                      event.stopPropagation();
                    }
                  }}
                >
                  {/* {row?.original?.statusName === t("global.pending") ? ( */}
                    <div>
                      <SVGIcon name="withdrawIcon" />
                    </div>
                  {/* ) : (
                    <Tippy
                      placement="bottom"
                      arrow={true}
                      theme="light"
                      appendTo="parent"
                      allowHTML={true}
                      content={
                        <span className="font-medium text-xs">
                          {t("research_institute.withdrawal_tooltip_content")}
                        </span>
                      }
                    >
                      <div>
                        <SVGIcon name="withdrawIcon" />
                      </div>
                    </Tippy>
                  )} */}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NominatedScholarsTable;
