import React, { useMemo } from "react";
import SVGIcon from "assets/Icons";
import { Title } from "components/Title";
import { useTranslation } from "react-i18next";
import { DETAILS_DISPLAY_MAX_CHAR_LENGTH, USERS, USER_STATUS } from "utils/constants";
import { useUserDataStore } from "store/useUserDataStore";
import { Label } from "components/Label";
import { Popup } from "components/Popup";
import { useMasterDataStore } from "store/useMasterDataStore";
import { ProfilePicture } from "components/ProfilePicture";
import {
  calculateAge,
  displayDateFormat,
  getCountryNameById,
  getMasterDataValue,
  handleLinkClick,
} from "utils/helpers";
import { TextButton } from "components/TextButton";
import { Descriptor } from "components/Descriptor";
import { UploadedFileItem } from "components/FileUploadList";
import { Chip } from "components/Chip";
import { ExternalURLLink } from "components/ExternalURLLink";
import { SubTitle } from "components/SubTitle";
import Profile from "assets/images/avatar.svg";
import { Divider } from "react-daisyui";
import googleScholar from "assets/images/googleScholar.png";
import researchGate from "assets/images/researchGate.png";
import linkedin from "assets/images/linkedin.png";

type ScholarProfileDetailsProps = {
  userData: ResearchScholarDetails;
  researchProjectRef: React.RefObject<HTMLDivElement>;
  isViewMore: boolean;
  handleViewClick: () => void;
  educationRef: React.RefObject<HTMLDivElement>;
  workRef: React.RefObject<HTMLDivElement>;
  socialRef: React.RefObject<HTMLDivElement>;
  showSettingsMenu: boolean;
  isCollaborationView: boolean;
  handleSettingsMenuClick?: (type: string) => void;
};

export function ScholarProfileDetails({
  userData,
  researchProjectRef,
  handleSettingsMenuClick,
  isViewMore,
  handleViewClick,
  educationRef,
  workRef,
  socialRef,
  showSettingsMenu,
  isCollaborationView,
}: ScholarProfileDetailsProps) {
  const { t, i18n } = useTranslation();
  const { masterData, permissions, title, countryCode } = useMasterDataStore();
  const { userInfo } = useUserDataStore();

  const isAssociatedResearcher = userData?.userprofile?.researchInstituteId === userInfo?.userId;

  const settingsItems = useMemo(() => {
    const tempItems = [];
    if (isCollaborationView) {
      tempItems.push({ icon: "deleteUser", label: "global.remove_user", type: "delete" });
    } else if (permissions || userInfo?.userType === USERS.Admin) {
      if (permissions?.Editscholar || userInfo?.userType === USERS.Admin) {
        tempItems.push({ icon: "update", label: "global.update", type: "update" });
      }
      if (permissions?.Sendresetpasswordlink || userInfo?.userType === USERS.Admin) {
        tempItems.push({ icon: "resetPassword", label: "global.reset_password", type: "reset" });
      }
      if (
        (permissions?.Disableascholar || userInfo?.userType === USERS.Admin) &&
        userData?.userprofile?.profileStatusId === USER_STATUS.Approved
      ) {
        tempItems.push({ icon: "disable", label: "admin.disable", type: "disable" });
      }
      if (
        (permissions?.Enableascholar || userInfo?.userType === USERS.Admin) &&
        userData?.userprofile?.profileStatusId === USER_STATUS.Disabled
      ) {
        tempItems.push({ icon: "enable", label: "admin.enable", type: "enable" });
      }
    }
    return tempItems;
  }, [permissions, userInfo?.userType, userData?.userprofile?.profileStatusId]);

  const selectedNationality = useMemo(() => {
    return masterData?.nationality?.filter(
      (item) => item.id == userData?.userprofile?.nationalityId
    )?.[0];
  }, [masterData, userData]);

  const selectedGender = useMemo(() => {
    return masterData?.gender?.filter((item) => item.id == userData?.userprofile?.genderId)?.[0];
  }, [masterData, userData]);

  const selectedTitle = useMemo(() => {
    return title?.filter((item) => item.id == userData?.userprofile?.titleId)?.[0];
  }, [userData, title]);

  const selectedCountryCode = useMemo(() => {
    return countryCode?.filter((item) => item.id == userData?.userprofile?.countryCodeId)?.[0];
  }, [countryCode, userData]);

  const selectedCountry = useMemo(() => {
    return masterData?.country?.filter((item) => item.id == userData?.userprofile?.countryId)?.[0];
  }, [userData, masterData]);

  const selectedResearchArea = useMemo(() => {
    const lang = i18n.language;
    if (lang == "ar") {
      return userData?.userprofile?.researchArea
        ?.map((item: OptionItem) => item?.name?.ar)
        ?.join(", ");
    } else {
      return userData?.userprofile?.researchArea
        ?.map((item: OptionItem) => item?.name?.en)
        ?.join(", ");
    }
  }, [userData, i18n.language]);

  return (
    <div className="w-full flex flex-col ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 ltr:lg:pr-[292px] rtl:lg:pl-[292px] space-y-3">
      {/* Personal details start */}
      <div className="flex-col bg-white w-full h-full rounded-md flex">
        <div className="p-5 flex items-center border-b border-gray-light relative">
          <SVGIcon name="personalDetails" />
          <Title
            title={t("global.personal_details")}
            className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
          />
          {userData?.userprofile?.profileStatusId !== USER_STATUS.PendingApproval ? (
            <div className=" absolute ltr:right-[13px] rtl:left-[13px] top-[13px] flex items-center space-x-.5">
              {userData?.userprofile?.profileStatusId === USER_STATUS.Disabled ? (
                <div className="flex items-center justify-start  text-primary-medium bg-primary-500 !w-auto px-[12px] py-[6px] text-xs font-normal rounded-[12px] ">
                  <Label text={t("global.disabled")} className="whitespace-nowrap font-medium" />
                </div>
              ) : null}
              {showSettingsMenu &&
              (userInfo?.userType === USERS.Admin ||
                (userInfo?.userType === USERS.ResearchInstitute &&
                  settingsItems?.length > 0 &&
                  (isAssociatedResearcher || isCollaborationView))) ? (
                <div className="dropdown dropdown-bottom dropdown-end ltr:ml-[18px] rtl:mr-[18px]">
                  <label
                    tabIndex={0}
                    className="btn text-sm font-medium !border-none  text-white normal-case flex-nowrap px-0 w-[38px] min-w-[38px] py-0 flex items-center rounded-md bg-secondary-medium h-[38px] min-h-[38px] m-0 relative z-10 hover:bg-primary-medium"
                  >
                    <SVGIcon name="settings" />
                  </label>
                  <ul
                    tabIndex={0}
                    className="dropdown-content !z-0 menu w-[170px] min-w-[170px] divide-y divide-solid divide-primary-medium/10 shadow-100 text-gray-200 text-sm font-normal normal-case rounded-lg mt-2 ltr:-right-[5px] rtl:-left-[5px] rtl:right-auto py-[10px] bg-white text-left px-4 pb-0"
                  >
                    <Popup
                      items={settingsItems}
                      handleClick={(type: string) => handleSettingsMenuClick?.(type)}
                    />
                  </ul>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="px-5 py-5 lg:py-[30px] flex flex-col lg:flex-row">
          <div className="w-[120px] h-[120px] mx-auto lg:mx-[0]  rounded-md  basis-[120px] grow-0 shrink-0 overflow-hidden mt-[6px]">
            <ProfilePicture
              url={
                userData?.userprofile?.profileActualImage
                  ? userData?.userprofile?.profileActualImage
                  : Profile
              }
            />
          </div>
          <div className="text-section pt-5 lg:pt-0 lg:ltr:pl-[22px] lg:rtl:mr-[22px]">
            <div className=" flex items-center space-x-2 rtl:space-x-reverse mb-1 ">
              <Title
                title={`${selectedTitle ? getMasterDataValue(selectedTitle, i18n.language) : ""} ${
                  userData?.userprofile?.firstName ?? ""
                } ${userData?.userprofile?.middleName ?? ""} ${
                  userData?.userprofile?.lastName ?? ""
                }`}
                className=" text-lg font-semibold text-black WB_BW "
              />

              {userData?.userprofile?.isGlobal &&
              (userInfo?.userType === USERS.Admin || !userInfo?.isGlobal) ? (
                <div className=" h-[20px] flex items-center px-3 ltr:!ml-1 rtl:!mr-1 rounded-xl text-xs text-white font-normal bg-primary-medium ">
                  {t("global.global")}
                </div>
              ) : null}
              {userData?.userprofile?.isAdvisor ? (
                <div className=" h-[20px] flex items-center px-3 ltr:!ml-1 rtl:!mr-1 rounded-xl text-xs text-white font-normal bg-primary-medium ">
                  {t("global.advisor")}
                </div>
              ) : null}
            </div>
            <Title
              title={userData?.userprofile?.researchInstituteName ?? ""}
              className=" text-base font-medium text-gray-100 mb-2 -mt-1 WB_BW"
            />

            {userData?.usersocialprofile?.hIndex ? (
              <SubTitle
                title={
                  userData?.usersocialprofile?.hIndex
                    ? `${t("global.h_index")} : ${userData?.usersocialprofile?.hIndex}`
                    : ""
                }
                className=" text-gray-102 text-sm font-normal !justify-start"
              />
            ) : null}
            {userData?.userprofile?.about ? (
              <div className="text-black text-sm font-normal max-w-[543px] mt-2 mb-[26px] WB_BW">
                {userData?.userprofile?.about?.length > DETAILS_DISPLAY_MAX_CHAR_LENGTH &&
                !isViewMore ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: userData?.userprofile?.about
                        ?.slice(0, DETAILS_DISPLAY_MAX_CHAR_LENGTH)
                        .replace(/\n/g, "<br/>"),
                    }}
                  />
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: userData?.userprofile?.about.replace(/\n/g, "<br/>"),
                    }}
                  />
                )}
                {userData?.userprofile?.about?.length > DETAILS_DISPLAY_MAX_CHAR_LENGTH ? (
                  <TextButton
                    title={isViewMore ? "global.view_less" : "global.view_more"}
                    handleClick={handleViewClick}
                    className="text-primary-medium font-medium underline ml-1.5 cursor-pointer"
                  />
                ) : null}
              </div>
            ) : null}
            <div className=" flex flex-col space-y-4 lg:space-y-5">
              <div className="flex flex-col lg:flex-row lg:space-x-10 lg:rtl:space-x-reverse space-y-4 lg:space-y-0">
                <Descriptor
                  startIcon="marker"
                  className="!mb-0  min-w-[140px] flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse truncate"
                  title={
                    selectedNationality
                      ? getMasterDataValue(selectedNationality, i18n.language)
                      : ""
                  }
                />
                <Descriptor
                  startIcon="gender"
                  title={`${
                    selectedGender ? getMasterDataValue(selectedGender, i18n.language) : ""
                  }, ${
                    userData?.userprofile?.dateOfBirth
                      ? calculateAge(userData?.userprofile?.dateOfBirth)
                      : ""
                  }`}
                  className="!mb-0 flex items-baseline text-black text-sm font-normal space-x-2 WB_BW rtl:space-x-reverse "
                />
              </div>
              <div className="flex flex-col lg:flex-row lg:space-x-10 lg:rtl:space-x-reverse space-y-4 lg:space-y-0">
                <Descriptor
                  startIcon="phone"
                  title={`${selectedCountryCode?.name?.code ?? ""} ${
                    userData?.userprofile?.contactNumber ?? ""
                  }`}
                  className="!mb-0 min-w-[140px] flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse truncate"
                />
                <Descriptor
                  startIcon="msg"
                  title={userData?.userprofile?.email ?? ""}
                  className=" !mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
                />
              </div>
              <Descriptor
                startIcon="mapPin"
                title={`${
                  userData?.userprofile?.address ? userData?.userprofile?.address + "," : ""
                } ${selectedCountry ? getMasterDataValue(selectedCountry, i18n.language) : ""}`}
                className="!mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
              />

              <div>
                {selectedResearchArea ? (
                  <Descriptor
                    startIcon="location"
                    className="!mb-0 flex items-baseline text-black text-sm font-normal space-x-2 rtl:space-x-reverse WB_BW"
                    title={selectedResearchArea}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Personal details end */}

      {/* attachments start */}
      {userData?.userprofile?.fileDetails?.length ? (
        <div className="flex-col bg-white w-full h-full rounded-md flex">
          <div className="p-5 flex items-center border-b border-gray-light">
            <SVGIcon name="attachments" />
            <Title
              title={t("global.attachments")}
              className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            />
          </div>
          <div className=" p-5 grid grid-cols-2 gap-6 text-sm font-medium">
            {userData?.userprofile?.fileDetails?.map((item: FileDetails, index: number) => (
              <div className="flex-1 truncate" key={index}>
                <UploadedFileItem key={item.fileUrl + new Date()} isPreview file={item} />
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {/* Highest education start */}
      <div className="flex-col bg-white w-full h-full rounded-md flex" ref={educationRef}>
        <div className="p-5 flex items-center border-b border-gray-light relative">
          <SVGIcon name="highestEducation" />
          <Title
            title={t("global.highest_education")}
            className="text-black text-base font-semibold ltr:ml-[14px] rtl:mr-[14px]"
          />
          {userData?.usereducation.stillStudying ? (
            <Label
              text={t("research_scholar.still_studying")}
              className="text-primary-medium bg-primary-500 px-[12px] !w-auto  py-[6px] text-xs font-normal rounded-[12px] absolute ltr:right-5 rtl:left-5"
            />
          ) : null}
        </div>

        {userData?.usereducation.details.map((educationInfo: HighestEducation) => (
          <div className="p-5" key={educationInfo.id}>
            <h4 className="text-sm font-medium text-black">{educationInfo.instituteName}</h4>
            <div className="flex justify-between items-center mt-[6px]">
              <h4 className="text-sm font-normal text-gray-102 WB_BW">
                {educationInfo.courseName}
              </h4>
            </div>
          </div>
        ))}
      </div>
      {/* Highest education end */}

      {/* work experience start */}
      {userData?.userwork.notWorking || userData?.userwork.details.length > 0 ? (
        <div className="flex-col bg-white w-full h-full rounded-md flex" ref={workRef}>
          <div className="p-5 flex items-center border-b border-gray-light relative">
            <div className=" flex items-center">
              <SVGIcon name="workExperience" />
              <Title
                title={t("global.work_experience")}
                className="text-black text-base font-semibold ltr:ml-[14px] rtl:mr-[14px]"
              />
            </div>
            {userData?.userwork?.notWorking ? (
              <Label
                text={t("research_scholar.not_working")}
                className="text-primary-medium bg-primary-500 !w-auto px-[12px] py-[6px] text-xs font-normal rounded-[12px] absolute ltr:right-5 rtl:left-5"
              />
            ) : null}
          </div>

          {userData?.userwork.details.length > 0
            ? userData.userwork.details.map((workInfo: WorkExperience) => {
                return (
                  <div className="p-5" key={workInfo.id}>
                    <h4 className="text-sm font-medium text-black">{workInfo.companyName}</h4>
                    <div className="flex justify-between items-center mt-[6px]">
                      <h4 className="text-sm font-normal text-gray-102 WB_BW">
                        {`${
                          workInfo.companyAddress ? workInfo.companyAddress + "," : ""
                        } ${getCountryNameById(
                          workInfo.companyCountryId,
                          masterData?.country ?? [],
                          i18n.language
                        )}`}
                      </h4>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      ) : null}
      {/* work experience end */}

      {/* social profile start */}
      {userData?.usersocialprofile.googleScholarURL ||
      userData?.usersocialprofile.linkedInURL ||
      userData?.usersocialprofile.researchGateURL ? (
        <div className="flex-col bg-white w-full h-full rounded-md flex" ref={socialRef}>
          <div className="p-5 flex items-center border-b border-gray-light">
            <SVGIcon name="socialProfiles" />
            <Title
              title={t("global.social_profiles")}
              className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            />
          </div>

          <div className="p-5 grid grid-cols-3 gap-2.5 items-center ">
            {userData?.usersocialprofile?.googleScholarURL ? (
              <ExternalURLLink
                label="admin.google_scholar"
                handleClick={() => handleLinkClick(userData?.usersocialprofile?.googleScholarURL)}
                imageUrl={googleScholar}
                imageAlt="Google Scholar"
                buttonClassName="cursor-pointer py-3 border-gray-light border px-5 rounded-md flex-1 truncate flex justify-center items-center"
                labelClassName="text-sm font-medium px-[18px] text-black"
              />
            ) : null}
            {userData?.usersocialprofile.linkedInURL ? (
              <ExternalURLLink
                label="admin.linkedin"
                handleClick={() => handleLinkClick(userData?.usersocialprofile?.linkedInURL)}
                imageUrl={linkedin}
                imageAlt="linkedin"
                buttonClassName="cursor-pointer py-3 border-gray-light border px-5 rounded-md flex-1 truncate flex justify-center items-center"
                labelClassName="text-sm font-medium px-[18px] text-black"
              />
            ) : null}
            {userData?.usersocialprofile?.researchGateURL ? (
              <ExternalURLLink
                label="admin.research_gate"
                handleClick={() => handleLinkClick(userData?.usersocialprofile?.researchGateURL)}
                imageUrl={researchGate}
                imageAlt="researchGate"
                buttonClassName="cursor-pointer py-3 border-gray-light border px-5 rounded-md flex-1 truncate flex justify-center items-center"
                labelClassName="text-sm font-medium px-[18px] text-black"
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {/* social profile end */}

      {/* Research projects start */}
      {userData?.researchprojects?.length ? (
        <div
          className="flex-col bg-white w-full h-full rounded-md flex mb-[55px]"
          ref={researchProjectRef}
        >
          <div className="p-5 flex items-center border-b border-gray-light">
            <SVGIcon name="researchProjects" />
            <Title
              title={t("global.research_projects")}
              className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            />
          </div>

          {userData?.researchprojects?.map((item: ResearchProject, index: number) => (
            <>
              <div className="p-5 flex flex-col" key={index}>
                <Title
                  title={item?.researchTitle ?? ""}
                  className="text-sm font-medium text-black"
                />
                <div className=" mt-8 flex justify-between max-w-[260px]">
                  <div className="flex flex-col gap-2">
                    <Label
                      text={t("global.start_date")}
                      className="text-primary-light font-normal text-sm"
                    />
                    <Label
                      text={item.startDate ? displayDateFormat(item.startDate) : ""}
                      className="text-primary-text font-medium text-sm"
                    />
                  </div>
                  {item?.endDate || item?.isInProgress ? (
                    <div className="flex flex-col gap-2">
                      <Label
                        text={t("global.end_date")}
                        className="text-primary-light font-normal text-sm"
                      />
                      <Label
                        text={
                          item.endDate
                            ? displayDateFormat(item.endDate)
                            : item.isInProgress
                            ? t("admin.pursuing")
                            : ""
                        }
                        className="text-primary-text font-medium text-sm"
                      />
                    </div>
                  ) : null}
                </div>
                {item?.areaOfExpertise?.length ? (
                  <div className="mt-8 flex flex-col gap-4">
                    <Label
                      text={t("global.selected_areas")}
                      className="text-primary-light font-normal text-sm"
                    />
                    <div className="flex gap-1 gap-y-1 flex-wrap">
                      {item.areaOfExpertise?.map((value, index) => (
                        <Chip
                          key={value.id ? value.id + "" : index + ""}
                          text={i18n.language === "ar" ? value.name.ar : value.name.en}
                          className="!m-0"
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
                {item.projectUrl || item.publicUrl ? (
                  <div className=" mt-8  flex gap-[45.5px]">
                    {item.projectUrl ? (
                      <ExternalURLLink
                        label="research_scholar.project_url"
                        handleClick={() => handleLinkClick(item.projectUrl)}
                        buttonClassName="flex gap-5 cursor-pointer"
                        labelClassName="text-sm font-normal text-black"
                      />
                    ) : null}
                    {item.publicUrl ? (
                      <ExternalURLLink
                        label="research_scholar.public_url"
                        handleClick={() => handleLinkClick(item.publicUrl)}
                        buttonClassName="flex gap-5 cursor-pointer"
                        labelClassName="text-sm font-normal text-black"
                      />
                    ) : null}
                  </div>
                ) : null}

                {item?.tags?.length ? (
                  <div className="mt-8 flex flex-col gap-4">
                    <Label
                      text={t("admin.tags")}
                      className="text-primary-light font-normal text-sm"
                    />
                    <div className="flex gap-1 gap-y-1 flex-wrap">
                      {" "}
                      {item.tags?.map((value, index) => (
                        <Chip
                          key={value.id ? value.id + "" : index + ""}
                          text={i18n.language === "ar" ? value.name.ar : value.name.en}
                          className="!m-0"
                        />
                      ))}
                    </div>
                  </div>
                ) : null}
                <div className="mt-8 max-w-[737px]">
                  <Label
                    text={t("global.summary")}
                    className="text-primary-light font-normal text-sm"
                  />
                  <p className="text-base font-medium text-primary-text WB_BW">
                    {item.researchBrief}
                  </p>
                </div>
                {item?.documents?.length ? (
                  <div className="mt-8 ">
                    <Label
                      text={t("global.attachments")}
                      className="text-primary-light font-normal text-sm"
                    />
                    <div className=" grid grid-cols-2 gap-3 mt-3">
                      {item.documents?.map((item: FileDetails, index: number) => (
                        <div className=" flex-1 truncate" key={index}>
                          <UploadedFileItem key={item.fileUrl + new Date()} isPreview file={item} />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
              {index != userData?.researchprojects?.length - 1 ? (
                <Divider className="mx-5 mt-2" />
              ) : null}
            </>
          ))}
        </div>
      ) : null}
      {/* Research projects end */}
    </div>
  );
}
