import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { Title, SecondaryButton, LoaderModal } from "components";
import ContactsForm from "features/Organization/Profile/Contacts/ContactsForm";
import { setContactsInfo, useOrganizationDataStore } from "store/useOrganizationDataStore";
import { showErrorToastMessage } from "utils/toasterMessage";
import {
  CONTACTS_INFO_INITIAL_STATE,
  ORGANIZATION_PROFILE_COMPLETION_STATUS,
  USER_STATUS,
} from "utils/constants";
import { useGetContacts } from "features/FundingOrganization/api/useGetContacts";
import useInvalidateQuery from "hooks/useInvalidateQuery";

type ContactsProps = {
  validationErrors: Array<Record<string, string>> | null;
  removeValidationErrors: () => void;
};

export default function Contacts({ validationErrors, removeValidationErrors }: ContactsProps) {
  const { t, i18n } = useTranslation();
  const { fundingOrganizationId } = useParams();
  const { data: fundingOrganizationContacts, isLoading: isFundingOrganizationContactsLoading } =
    useGetContacts(fundingOrganizationId ?? "");
  useInvalidateQuery("funding-organization-details", fundingOrganizationId ?? "");

  const isReadOnly = fundingOrganizationContacts?.profileStatusId === USER_STATUS.PendingApproval;

  useEffect(() => {
    if (fundingOrganizationContacts && fundingOrganizationContacts?.contacts?.length > 0) {
      setContactsInfo(fundingOrganizationContacts);
    } else {
      setContactsInfo({
        contacts: [{ ...CONTACTS_INFO_INITIAL_STATE }],
        profileCompletionStatusId: ORGANIZATION_PROFILE_COMPLETION_STATUS.Details,
        profileStatusId: USER_STATUS.Draft,
      });
    }
  }, [fundingOrganizationContacts, i18n.language]);

  const handleAddContact = () => {
    const contactsInfo = useOrganizationDataStore.getState().contactsInfo;
    if (contactsInfo && contactsInfo?.contacts?.length > 0 && contactsInfo?.contacts?.length <= 4) {
      const tempContactsInfo = structuredClone(contactsInfo);
      tempContactsInfo?.contacts.push({ ...CONTACTS_INFO_INITIAL_STATE });
      setContactsInfo({
        ...tempContactsInfo,
        contacts: tempContactsInfo?.contacts,
      });
    } else if (contactsInfo && contactsInfo?.contacts?.length > 4) {
      showErrorToastMessage({
        message: t("organization.max_contact_add_limit_warning"),
      });
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-6 flex-wrap pb-8 p-5 !py-3 border-b border-gray-light">
        <div className="ltr:mr-auto rtl:ml-auto flex items-center justify-between">
          <SVGIcon name="personalDetails" />
          <Title
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            title={t("organization.contacts")}
          />
        </div>
        <SecondaryButton
          title={"organization.add_contact"}
          handleClick={handleAddContact}
          className=" !mt-0 !h-10 !min-h-[40px] space-x-2 rtl:space-x-reverse !px-6"
          startIcon="plus"
          disabled={isReadOnly}
        />
      </div>
      <ContactsForm
        validationErrors={validationErrors}
        isReadOnly={isReadOnly}
        removeValidationErrors={removeValidationErrors}
      />
      {isFundingOrganizationContactsLoading ? <LoaderModal /> : null}
    </div>
  );
}
