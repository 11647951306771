import { useTranslation } from "react-i18next";
import Select from "react-select";

type SingleSelectProps = {
  options: Array<Option> | [];
  labelText: string;
  hintText: string;
  handleChange: (value: string | number) => void;
  selectOption?: string | number;
  isRequired?: boolean;
  isDisabled?: boolean;
  errorMessage?: string | null;
  className?: string;
};
export function SingleSelect({
  options,
  labelText,
  hintText,
  handleChange,
  selectOption,
  isRequired,
  isDisabled,
  errorMessage,
  className,
}: SingleSelectProps) {
  const { t } = useTranslation();
  const selectedItem =
    selectOption === -1 || selectOption === null
      ? { label: hintText, value: "select" }
      : options.find((item) => item.value === selectOption);

  const NoOptionsMessage = () => {
    return <div>{t("global.no_results")}</div>;
  };

  return (
    <div className={className}>
      <label className="label p-0 pb-2">
        <span className="label-text text-primary-light text-sm font-normal leading-4 whitespace-nowrap">
          <span className=" ltr:float-left rtl:float-right">{labelText}</span>
          {isRequired ? (
            <span className=" ltr:float-left rtl:float-right mr-1 ml-1 text-red-200">*</span>
          ) : null}
        </span>
      </label>
      <Select
        options={options}
        placeholder={hintText}
        classNamePrefix={`customSelect truncate ${
          errorMessage ? "customSelectError" : "customSelect"
        }`}
        onChange={(newValue) => {
          if (newValue?.value) handleChange(newValue?.value);
        }}
        value={selectedItem}
        isDisabled={isDisabled}
        noOptionsMessage={NoOptionsMessage}
      />
    </div>
  );
}
