import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Button } from "react-daisyui";
import SVGIcon from "assets/Icons";
import DefaultSignUpBgImage from "assets/images/defaultSignUpBg.png";
import {
  SubTitle,
  AppLogo,
  Title,
  TextButton,
  PrimaryButton,
  LoaderModal,
  LanguageSwitchButton,
  Footer,
} from "components";
import SignUpForm from "features/FundingOrganization/SignUp/SignUpForm";
import PasswordForm from "features/FundingOrganization/SignUp/PasswordForm";
import { useSignUp } from "features/FundingOrganization/api/useSignUp";
import { passwordSchemaValidation } from "utils/validation/signUpValidation";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import { FUNDING_ORGANIZATION_INIT_STATE, PASSWORD_INITIAL_STATE } from "utils/constants";

export default function SignUp() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: signUp, isLoading } = useSignUp();

  const [signUpFormData, setSignUpFormData] = useState<FundingOrganizationBasicFields>(
    FUNDING_ORGANIZATION_INIT_STATE
  );
  const [passwordFormData, setPasswordFormData] =
    useState<PasswordFormFields>(PASSWORD_INITIAL_STATE);
  const [stepNumber, setStepNumber] = useState(1);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();
  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const error = passwordSchemaValidation(passwordFormData);
    if (error) {
      setValidationErrors(error);
      return;
    }
    setValidationErrors(null);
    try {
      const formData = prepareFormData();
      await signUp(formData);
      setStepNumber(1);
      setSignUpFormData(FUNDING_ORGANIZATION_INIT_STATE);
      setPasswordFormData(PASSWORD_INITIAL_STATE);
      return showInfoToastMessage({
        iconType: ToasterIconType.MAIL,
        message: t("global.sign_up_thanks_you_message"),
        position: i18n.language === "ar" ? "top-right" : "top-left",
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
        position: i18n.language === "ar" ? "top-right" : "top-left",
      });
    }
  };

  const prepareFormData = () => {
    const data: FundingOrganizationSignUpFormData = {
      organizationName: signUpFormData.organizationName.trim(),
      organizationType: signUpFormData.organizationType,
      email: signUpFormData.email.trim(),
      contactNumber: signUpFormData.contactNumber.trim(),
      countryCodeId: signUpFormData.countryCodeId,
      password: passwordFormData.password.trim(),
    };
    return data;
  };

  const navigateToFirstForm = () => {
    if (stepNumber === 2) {
      setStepNumber(1);
    }
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="h-screen min-w-screen flex overflow-hidden">
      <div className="h-full w-full">
        <div className="h-full w-full relative rtl:pl-[550px] ltr:pr-[550px] max-[1280px]:rtl:pl-[450px] max-[1280px]:ltr:pr-[450px] max-[1024px]:!p-0">
          <img
            src={DefaultSignUpBgImage}
            alt="SignUp background"
            className="w-full h-full object-cover max-[1024px]:hidden"
          />
          <div className="h-full bg-gradient-blueDark w-[551px] max-[1280px]:w-[451px]  absolute top-0 ltr:right-0 rtl:left-0 max-[1024px]:relative max-[1024px]:w-full">
            <div className="h-full w-full default-inputs-section">
              <div className="overflow-auto h-full">
                {/*Language selector */}
                <div className="relative z-20 pt-3.5 ltr:pr-5 rtl:pl-5 mb-[65px] flex justify-end max-[1024px]:!p-5 max-[768px]:!px-5  max-[1024px]:!px-32 max-[1024px]:mb-0">
                  <LanguageSwitchButton className="language-selector min-w-[86px] h-[38px]" />
                </div>
                {/*Reset password inputs section */}
                <div className="ltr:pr-[105px] rtl:pl-[105px] max-[1280px]:ltr:pr-[85px] max-[1280px]:rtl:pl-[85px] max-[768px]:!px-5 max-[1024px]:!px-32 relative z-10 h-[calc(100%-117px)]  max-[1024px]:h-[calc(100%-78px)] flex flex-col">
                  <div>
                    <AppLogo />
                    <div className="mt-[42px] max-[1024px]:mt-5">
                      <div className="flex  mb-8 max-[1024px]:mb-5 justify-between">
                        <Title
                          className="text-white text-xl font-semibold  max-[1024px]:text-base "
                          title={t("funding_organization.funding_organization")}
                        />
                        <div className="flex items-center">
                          <Button
                            className={`btn rtl:!rotate-180 p-0 !bg-transparent border-none h-auto min-h-[auto] text-white ${
                              stepNumber === 1
                                ? " opacity-50 cursor-not-allowed pointer-events-none text-gray-100"
                                : ""
                            }`}
                            onClick={navigateToFirstForm}
                          >
                            <SVGIcon name="leftArrow" />
                          </Button>
                          <span className=" mx-[10px] text-sm font-medium text-white">
                            <span className=" font-bold">{t(`global.${stepNumber}`)}</span>/
                            {t("global.2")}
                          </span>
                          <Button className="btn rtl:!rotate-180 p-0 !bg-transparent border-none h-auto min-h-[auto] text-gray-100 opacity-50 cursor-not-allowed pointer-events-none">
                            <SVGIcon name="rightArrow" />
                          </Button>
                        </div>
                      </div>
                      <form className="flex flex-col">
                        <>
                          {stepNumber === 1 ? (
                            <SignUpForm
                              signUpFormData={signUpFormData}
                              handleChange={(value: string | number, fieldName: string) =>
                                setSignUpFormData((prevState) => ({
                                  ...prevState,
                                  [fieldName]: value,
                                }))
                              }
                              movetoNext={() => setStepNumber(2)}
                            />
                          ) : null}
                        </>
                        {stepNumber === 2 ? (
                          <>
                            <PasswordForm
                              passwordFormData={passwordFormData}
                              handleChange={(value, fieldName) =>
                                setPasswordFormData((prevState) => ({
                                  ...prevState,
                                  [fieldName]: value,
                                }))
                              }
                              validationErrors={validationErrors}
                            />
                            <div className=" mt-auto pt-14">
                              <PrimaryButton
                                title={"global.sign_up"}
                                handleClick={onSubmit}
                                className="default-primary-button w-full mb-8"
                              />
                            </div>
                          </>
                        ) : null}
                      </form>
                      <div>
                        <SubTitle
                          className="text-white text-sm  max-[630px]:text-xs text-center "
                          title={t("global.if_you_are_an_existing_user")}
                          childElement={
                            <TextButton
                              className=" font-medium text-sm text-secondary-medium underline ml-1 cursor-pointer"
                              title={"global.please_click_here"}
                              handleClick={() => navigateToLogin()}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/*Footer section */}
                  <div className="mt-auto relative  z-10 pb-[30px] pt-12  max-[1024px]:w-full  bg-primary-medium">
                    <div>
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
}
