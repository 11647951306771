import Joi from "joi";
import { groupBy } from "utils/helpers";

const workExperienceSchema = Joi.object({
  id: Joi.number().default(0),
  companyName: Joi.string().trim().required().messages({
    "string.empty": "required",
    "string.pattern.base": "required",
    "string.base": "required",
  }),
  companyAddress: Joi.string()
    .trim()
    .optional()
    .allow("", null)
    .messages({ "string.pattern.base": "required" }),
  companyCountryId: Joi.number().required().disallow(0).messages({
    "string.empty": "required",
  }),
}).options({ abortEarly: false });

export const workExperienceValidation = (workInfo: WorkExperience) => {
  const result = workExperienceSchema.validate(workInfo);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
