import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CALENDER_START_DATE } from "utils/constants";

type DatePickerCalenderProps = {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (startDate: Date | null) => void;
  setEndDate: (endDate: Date | null) => void;
  handleDateChange: (startDate: Date | null, endDate: Date | null) => void;
};

export function DatePickerCalender({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  handleDateChange,
}: DatePickerCalenderProps) {
  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    handleDateChange(start, end);
  };

  return (
    <div className="datePicker w-full datePickerCustome">
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate ? new Date(startDate) : new Date(CALENDER_START_DATE)}
        selectsRange
        inline
        allowSameDay
        className="bg-white w-full"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        maxDate={new Date()}
      />
    </div>
  );
}
