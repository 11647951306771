import { Navigate, useNavigate } from "react-router-dom";
import { useUserDataStore } from "store/useUserDataStore";
import PageError from "assets/images/404.svg";
import { PrimaryButton } from "components/PrimaryButton";

export const PageNotFound = () => {
  const { userInfo } = useUserDataStore();
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
  };
  if (!userInfo) {
    return <Navigate to="/login" state={{ from: window.location }} />;
  } else
    return (
      <div className=" flex items-center justify-center min-h-screen">
        <div className=" flex justify-center flex-col items-center text-center p-10">
          <img alt="profile picture" src={PageError} />
          <h1 className=" text-black font-semibold text-[22px] pt-[53px] pb-4">
            Oops, page not found...!!
          </h1>
          <p className=" text-gray-109 text-sm">
            The page you are looking for might have been removed, had its name changed<br></br> or
            is temporarily unavailable
          </p>
          <PrimaryButton
            title={"Back to home"}
            handleClick={navigateToHome}
            className=" flex-1 sm:flex-[0] sm:min-w-[192px] mt-10"
          />
        </div>
      </div>
    );
};
