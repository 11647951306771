import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function organizationEmailInvite(formData: Array<string>, isGlobalInvite: boolean) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(urls.organizationEmailInvite(isGlobalInvite), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus;
}

export function useOrganizationEmailInvite() {
  return useMutation(
    async ({ formData, isGlobalInvite }: { formData: Array<string>; isGlobalInvite: boolean }) => {
      return await organizationEmailInvite(formData, isGlobalInvite);
    }
  );
}
