import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Tippy from "@tippyjs/react";
import SVGIcon from "assets/Icons";

type LabelProps = {
  text: string;
  children?: ReactElement;
  className?: string;
  tooltipContent?: string | null;
};

export function Label({ text, children, className, tooltipContent }: LabelProps) {
  const { t } = useTranslation();
  return (
    <span className={`flex items-center w-full justify-start rtl:space-x-reverse ${className}`}>
      {text} {children ?? null}
      {tooltipContent ? (
        <Tippy
          placement="bottom"
          arrow={true}
          theme="light"
          appendTo="parent"
          allowHTML={true}
          content={
            <span className=" font-medium text-xs max-w-[270px] block">{t(tooltipContent)}</span>
          }
        >
          <div className="info !inline-block ltr:pl-1.5 rtl:pr-1.5">
            <SVGIcon name="info" />
          </div>
        </Tippy>
      ) : null}
    </span>
  );
}
