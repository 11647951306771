import Joi from "joi";
import JoiPhoneNumber from "joi-phone-number";
import { groupBy } from "utils/helpers";
import { EMAIL_VALIDATION_PATTERN, INVALID_CHARACTER_VALIDATION_PATTERN } from "utils/constants";

const JoiWithPhoneNumber = Joi.extend(JoiPhoneNumber);

const profileInfoSchema = Joi.object({
  titleId: Joi.string()
    .valid(...[1, 2, 3, 4])
    .messages({ "any.only": "required" }),
  firstName: Joi.string()
    .trim()
    .required()
    .messages({ "string.empty": "required", "string.pattern.base": "required" }),
  middleName: Joi.string()
    .trim()
    .optional()
    .allow("")
    .messages({ "string.pattern.base": "required" }),
  lastName: Joi.string()
    .trim()
    .required()
    .messages({ "string.empty": "required", "string.pattern.base": "required" }),
  about: Joi.string().trim().max(10000).default(null).messages({
    "string.max": "required",
  }),
  researchArea: Joi.array()
    // items(Joi.number())
    .items({
      id: Joi.number().allow(null),
      name: Joi.object({
        en: Joi.string().allow("", null),
        ar: Joi.string().allow("", null),
      }),
    })
    .optional()
    .allow(null),
  genderId: Joi.number()
    .valid(...[1, 2])
    .messages({ "any.only": "required" }),
  dateOfBirth: Joi.string().isoDate().messages({
    "string.empty": "required",
    "string.isoDate": "required",
    "string.date.base": "required",
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .pattern(EMAIL_VALIDATION_PATTERN)
    .trim()
    .required()
    .messages({
      "string.empty": "required",
      "string.email": "global.invalid_email_error_message",
      "string.pattern.base": "global.invalid_email_error_message",
    }),
  countryCodeId: Joi.number().required().disallow(0).messages({
    "string.empty": "required",
  }),
  contactNumber: JoiWithPhoneNumber.string()
    .trim()
    .min(5)
    .when("countryCodeId", {
      is: 236, // UAE country code
      then: JoiWithPhoneNumber.string().phoneNumber({
        defaultCountry: "AE",
        format: "national",
        //strict: true,
      }),
      otherwise: JoiWithPhoneNumber.string().phoneNumber({
        defaultCountry: "US",
        format: "international",
      }), // Default phone number validation
    })
    .custom((value: string, helpers: any) => {
      if (!INVALID_CHARACTER_VALIDATION_PATTERN.test(value)) {
        return helpers.error("string.invalidCharacters", { v: value });
      }
      return value;
    }, "Custom validation for characters")
    .custom((value: string, helpers: any) => {
      const numbersOnly = value.replace(/\D/g, "");
      if (numbersOnly?.length < 5) {
        return helpers.error("string.min", { v: value });
      }
      return value;
    }, "Custom Validation")
    .custom((value: string, helpers: any) => {
      const stack = [];
      for (let i = 0; i < value.length; i++) {
        const char = value[i];
        if (char === "(") {
          stack.push(char);
        } else if (char === ")") {
          if (stack.length === 0) {
            return helpers.error("string.unbalancedParentheses", { v: value });
          }
          stack.pop();
        }
      }
      if (stack.length > 0) {
        return helpers.error("string.unbalancedParentheses", { v: value });
      }
      return value;
    }, "Custom validation for balanced parentheses")
    .required()
    .messages({
      "string.empty": "required",
      "string.pattern.base": "global.invalid_contact_number_error_message",
      "string.invalidCharacters": "global.invalid_special_character_error_message",
      "string.unbalancedParentheses": "global.phone_parenthesis_error_message",
      "string.min": "global.phone_min_character_error_message",
      "phoneNumber.invalid": "global.invalid_contact_number_error_message",
    }),
  countryId: Joi.number().required().disallow(0).messages({
    "string.empty": "required",
  }),
  address: Joi.string()
    .trim()
    .optional()
    .allow(null, "")
    .messages({ "string.pattern.base": "required" }),
  profileImage: Joi.string()
    .trim()
    .optional()
    .allow(null, "")
    .messages({ "string.pattern.base": "required" }),
  profileActualImage: Joi.string()
    .trim()
    .optional()
    .allow(null, "")
    .messages({ "string.pattern.base": "required" }),
  fileDetails: Joi.array().allow(null).optional().messages({ "string.pattern.base": "required" }),
  profileStatusId: Joi.number().optional().default(1).allow(null),
  profileCompletionStatusId: Joi.number().required().default(0).allow(null),
  nationalityId: Joi.number().required().disallow(0).messages({
    "string.empty": "required",
  }),
  researchInstituteName: Joi.string().allow(null),
  researchInstituteId: Joi.string().allow(null),
  isGlobal: Joi.boolean(),
  isAdvisor: Joi.boolean(),
}).options({ abortEarly: false });

export const profileInfoValidation = (profileInfo: PersonalDetails) => {
  const result = profileInfoSchema.validate(profileInfo);
  if (result?.error?.details) {
    const err = groupBy(result.error?.details, (i) => i.context?.key || "");
    const error: Record<string, string> = {};
    Object.entries(err).forEach(([key, value]) => {
      error[key] = value?.[0].message;
    });
    return error;
  } else {
    return null;
  }
};
