import { useInfiniteQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { PAGE_LIMIT } from "utils/constants";
import { urls } from "utils/urls";

export async function getFundingInterests({
  challengeId,
  pageNumber,
  pageLimit,
}: FundingInterestParams) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.fundingInterests(challengeId, pageNumber, pageLimit), {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res?.data?.data as FundingInterests | ApiError;
}

export function useGetChallengeFundingInterests({ challengeId }: { challengeId: number }) {
  return useInfiniteQuery(
    ["challenge-users", challengeId],
    async ({ pageParam = 1 }) => {
      const result = await getFundingInterests({
        challengeId,
        pageNumber: pageParam,
        pageLimit: PAGE_LIMIT,
      });
      return result;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.totalPages > allPages.length ? allPages?.length + 1 : undefined;
      },
      staleTime: 10 * 1000,
    }
  );
}
