import { Route, Routes } from "react-router-dom";

import AppOutlet from "components/AppOutlet";
import { PrivateRoute } from "router/PrivateRoute";
import ChangePassword from "features/ChangePassword";
import SearchList from "features/GlobalSearch/SearchList";

import ResearchScholar from "features/ResearchScholar";
import ResearchScholarProfile from "features/ResearchScholar/Profile";
import ResearchScholarHome from "features/ResearchScholar/Home";
import ResearchScholarInfo from "features/Admin/Users/ResearchScholars/ResearchScholarInfo";
import OrganizationInfo from "features/Admin/Users/Organizations/OrganizationInfo";
import FundingOrganizationInfo from "features/Admin/Users/FundingOrganizations/FundingOrganizationInfo";
import ResearchInstituteInfo from "features/Admin/Users/ResearchInstitute/ResearchInstituteInfo";

/* Challenge */
import ChallengeDashboard from "features/Challenge/Dashboard";
import ChallengeDetailsPreview from "features/Challenge/Preview";

/* Events */
import EventDashboard from "features/Events/Dashboard";
import EventPreview from "features/Events/EventPreview";

/*Collaborations*/
import CollaborationListing from "features/Collaboration/CollaborationListing";
import CollaborationDetailsPreview from "features/Collaboration/CollaborationDetailsPreview";

import DiscussionForum from "features/DiscussionForum";
import Messages from "features/Messages";
import CollaborationDiscussionForum from "features/Collaboration/DiscussionForum";
import { PageNotFound } from "components";

export default function ResearchScholarRoutes() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <ResearchScholar />
          </PrivateRoute>
        }
      >
        <Route
          path="home"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<ResearchScholarHome />} index />
          <Route
            path="collaborations/:collaborationId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route index element={<CollaborationDetailsPreview />} />
            <Route path="discussion-forum" element={<CollaborationDiscussionForum />} />
          </Route>
          <Route
            path=":challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ChallengeDetailsPreview />} index />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>
        </Route>
        <Route path="forums" element={<Messages />} />

        <Route
          path="settings"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route path="profile/:researchScholarId" element={<ResearchScholarProfile />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
        <Route
          path="challenges"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<ChallengeDashboard />} index />
          <Route
            path=":challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ChallengeDetailsPreview />} index />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>
        </Route>
        <Route
          path="search-results"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route element={<SearchList />} index />
          <Route
            path="challenges/:challengeId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route element={<ChallengeDetailsPreview />} index />
            <Route path="discussion-forum" element={<DiscussionForum />} />
          </Route>
          <Route path="research-scholar/:researchScholarId" element={<ResearchScholarInfo />} />
          <Route path="organization/:organizationId" element={<OrganizationInfo />} />
          <Route
            path="funding-organization/:fundingOrganizationId"
            element={<FundingOrganizationInfo />}
          />
          <Route
            path="research-institute/:researchInstituteId"
            element={<ResearchInstituteInfo />}
          />
        </Route>
        <Route
          path="events"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route index element={<EventDashboard />} />
          <Route path=":eventId" element={<EventPreview />} />
        </Route>
        <Route
          path="collaborations"
          element={
            <PrivateRoute>
              <AppOutlet />
            </PrivateRoute>
          }
        >
          <Route index element={<CollaborationListing />} />

          <Route
            path=":collaborationId"
            element={
              <PrivateRoute>
                <AppOutlet />
              </PrivateRoute>
            }
          >
            <Route index element={<CollaborationDetailsPreview />} />
            <Route path="discussion-forum" element={<CollaborationDiscussionForum />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
