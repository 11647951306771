import SVGIcon from "assets/Icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { clearFilterDataStore } from "store/useFilterDataStore";
import { showErrorToastMessage } from "utils/toasterMessage";

type SearchBarProps = {
  value?: string;
  hintText?: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  maxCharLength?: number;
  user?: string;
  handleEnterKeyPress?: () => void;
};

export function SearchBar({
  handleInputChange,
  value,
  className,
  hintText,
  maxCharLength,
  user,
  handleEnterKeyPress,
}: SearchBarProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const handleEnterPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && value && value?.trim()?.length >= 3) {
      clearFilterDataStore();
      navigate({ pathname: `/${user}/search-results`, search: `?key=${value}` });
      if (pathName === `/${user}/search-results`) {
        navigate(0);
      }
      handleEnterKeyPress && handleEnterKeyPress();
    } else if (event.key === "Enter" && value && value?.trim()?.length < 3) {
      showErrorToastMessage({
        message: t("global.search_error_message"),
      });
    }
  };
  return (
    <div
      className={`search hidden lg:flex items-center relative h-[38px] ml-auto lg:ml-0 lg:min-w-[268px] text-white rounded-md border overflow-hidden border-white shadow-100 ${className}`}
    >
      <div className=" text-primary-light lg:absolute ltr:right-[14px] rtl:left-[14px] w-[18px] searchIcon">
        <SVGIcon name="search" />
      </div>
      <input
        placeholder={`${hintText ? t(hintText) : ""}`}
        autoComplete="off"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
        className=" bg-white text-sm w-full text-black !outline-none h-full placeholder:text-primary-light placeholder:text-sm ltr:pr-10 ltr:pl-5 rtl:!pr-5 rtl:pl-10"
        value={value}
        maxLength={maxCharLength}
        onKeyDown={(event: React.KeyboardEvent) => handleEnterPress(event)}
      />
    </div>
  );
}
