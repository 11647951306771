import { useTranslation } from "react-i18next";

type ProfileNavigationTimelineProps = {
  navigatorList: Array<RecordItem>;
  handleClick: (i: number, index: number) => void;
  stepNumber?: number;
  profileCompletionStatusId?: number;
  isViewOnly?: boolean;
  activeMenu?: number;
};

export const ProfileNavigationTimeline = ({
  navigatorList,
  stepNumber,
  handleClick,
  profileCompletionStatusId,
  isViewOnly,
  activeMenu,
}: ProfileNavigationTimelineProps) => {
  const { t } = useTranslation();
  const handleNavigation = (index: number, value: number) => {
    if (isViewOnly) {
      handleClick(value, index);
    } else if (profileCompletionStatusId) {
      if (index <= profileCompletionStatusId) {
        handleClick(index + 1, index);
      }
    }
  };

  const getClassNames = (index: number) => {
    if (isViewOnly && activeMenu !== index) {
      return "cursor-pointer text-primary-medium selected";
    } else if (isViewOnly && activeMenu === index) {
      return "cursor-pointer text-primary-medium selected active";
    } else if (stepNumber === index + 1) {
      return "cursor-pointer text-primary-medium selected";
    } else if (profileCompletionStatusId !== null && profileCompletionStatusId !== undefined) {
      if (index < profileCompletionStatusId) {
        return "cursor-pointer text-primary-medium font-medium completed";
      } else if (index > profileCompletionStatusId) {
        return "cursor-not-allowed text-gray-200";
      } else if (index === profileCompletionStatusId) {
        return "cursor-pointer text-primary-medium";
      } else {
        return "";
      }
    }
  };

  return (
    <div className="navigate flex flex-row lg:flex-col ltr:left-0 ltr:lg:left-auto rtl:right-0 rtl:left-auto rtl:lg:right-auto navigator-mobile-view challengeNav">
      <p className="hidden lg:flex text-primary-light font-semibold text-[11px] mb-3  ">
        {t("global.navigate")}
      </p>
      <div className="timeLine rtl:pl-4 ltr:pr-4  rtl:lg:pl-0  ltr:lg:pr-0 overflow-hidden ">
        {navigatorList.map((item: RecordItem, i) => (
          <p
            className={`text-sm font-normal text-gray-200 py-2 pb-2.5 lg:py-0 lg:pb-5 px-3 lg:ltr:pl-6 lg:rtl:pr-6 ${getClassNames(
              i
            )} `}
            key={item?.value}
            onClick={() => handleNavigation(i, item?.value)}
          >
            {t(item?.label)}
          </p>
        ))}
      </div>
    </div>
  );
};
