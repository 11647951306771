import React from "react";
import { useTranslation } from "react-i18next";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import SVGIcon from "assets/Icons";
import { displayDateFormat, getColumns } from "utils/helpers";

type FundingInterestTableProps = {
  fundingInterestData: Array<FundingInterestDetails>;
  headers: Array<string>;
  handleNavigation: (interestId: number) => void;
};

const FundingInterestTable = ({
  fundingInterestData,
  headers,
  handleNavigation,
}: FundingInterestTableProps) => {
  const { t } = useTranslation();

  const filteredData = fundingInterestData?.map((item) => {
    return {
      createdOn: item.createdOn,
      organizationName: item.organizationName,
      country: item.country,
      contactNumber: item.contactNumber,
      email: item.email,
    };
  });

  const dataTable = useReactTable({
    data: fundingInterestData,
    columns: filteredData && filteredData.length ? getColumns(filteredData) : [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="customTable ">
      <table>
        <thead>
          {headers?.map((item) => (
            <th key={item}>{t(item)}</th>
          ))}
          <th>{t("admin.view")}</th>
        </thead>
        <tbody>
          {dataTable.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => handleNavigation(row.original?.interestId)}
              className="cursor-pointer"
            >
              {row.getVisibleCells().map((cell, index) => {
                if (cell.column.columnDef.header?.toString().trim() == "createdOn") {
                  return <td key={index}>{displayDateFormat(cell.getValue() as string)}</td>;
                } else if (cell.column.columnDef.header?.toString().trim() === "organizationName") {
                  return (
                    <td key={index}>
                      <p className="text-primary-medium underline truncate">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </p>
                    </td>
                  );
                } else {
                  return (
                    <td key={index}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  );
                }
              })}
              <td>
                <div className="btn btn-link h-auto min-h-[auto] p-0 text-primary-medium">
                  <SVGIcon name={"eyeCloseV1"} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FundingInterestTable;
