import { RadioButton } from "components";
import { useTranslation } from "react-i18next";
import { GLOBAL_PORTAL_FILTERS } from "utils/constants";

type PortalFilterProps = {
  activeFilter: number;
  handleRadioTypeChange: (value: string | boolean, id: number) => void;
};
export function PortalFilter({ handleRadioTypeChange, activeFilter }: PortalFilterProps) {
  const { t } = useTranslation();
  const filterItems = [
    { id: GLOBAL_PORTAL_FILTERS.Both, value: "", label: t("global.show_all") },
    { id: GLOBAL_PORTAL_FILTERS.National, value: false, label: t("global.national") },
    { id: GLOBAL_PORTAL_FILTERS.Global, value: true, label: t("global.global") },
  ];
  return (
    <div className="flex items-center ltr:mr-auto rtl:ml-auto space-x-4 mb-3 -mt-2.5 rtl:space-x-reverse">
      {filterItems?.map((item) => (
        <div key={item.id}>
          <RadioButton
            label={item?.label}
            className="relative left-2 top-[84px] lg:top-auto lg:left-0 !py-2 !rounded-none !pl-[4px] filterRadioButtons gap-[0px]"
            onChange={() => handleRadioTypeChange(item?.value, item?.id)}
            value={item?.id}
            checked={activeFilter === item?.id}
          />
        </div>
      ))}
    </div>
  );
}
