import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showConfirmationModal } from "utils/confirmationModals";
import { Header, LoaderModal } from "components";
import { Navigator } from "features/ResearchScholar/Profile/Navigator";
import PersonalDetails from "features/ResearchScholar/Profile/PersonalDetails";
import WorkExperience from "features/ResearchScholar/Profile/WorkExperience";
import HighestEducation from "features/ResearchScholar/Profile/HighestEducation";
import SocialProfiles from "features/ResearchScholar/Profile/SocialProfiles";
import { ResearchProject } from "features/ResearchScholar/Profile/ResearchProject";
import {
  HIGHEST_EDUCATION_INITIAL_STATE,
  WORK_EXPERIENCE_INITIAL_STATE,
  useScholarProfileDataStore,
} from "store/useScholarProfileDataStore";
import { useSavePersonalDetails } from "features/ResearchScholar/api/useSavePersonalDetails";
import { useSaveEducationDetails } from "features/ResearchScholar/api/useSaveEducationDetails";
import { useSaveSocialProfile } from "features/ResearchScholar/api/useSaveSocialProfile";
import { useSaveWorkDetails } from "features/ResearchScholar/api/useSaveWorkDetails";
import { useSubmitProfileDetails } from "features/ResearchScholar/api/useSubmitProfileDetails";
import { useGetProfileDetails } from "features/ResearchScholar/api/useGetProfileDetails";
import { useSaveResearchProject } from "features/ResearchScholar/api/useSaveResearchProjects";
import { useGetEducationDetails } from "features/ResearchScholar/api/useGetEducationDetails";
import { useGetWorkExperienceDetails } from "features/ResearchScholar/api/useGetWorkExperienceDetails";
import { useGetSocialProfilesDetails } from "features/ResearchScholar/api/useGetSocialProfilesDetails";
import { useGetResearchProjects } from "features/ResearchScholar/api/useGetResearchProjects";
import { profileInfoValidation } from "utils/validation/profileInfoValidation";
import { researchProjectValidation } from "utils/validation/researchProjectValidation";
import { workExperienceValidation } from "utils/validation/workExperienceValidation";
import { socialProfileValidation } from "utils/validation/socialProfileValidation";
import { educationInfoValidation } from "utils/validation/educationInfoValidation";
import {
  RESEARCH_PROJECT_INITIAL_STATE,
  RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS,
  USERS,
  USER_STATUS,
} from "utils/constants";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import { setUserInfo, useUserDataStore } from "store/useUserDataStore";
import { useStickyScroll } from "hooks/useStickyScroll";
import { setSelectedSideMenu } from "store/useSidebarStore";
import { getBreadCrumbTitle } from "utils/helpers";

export default function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userInfo } = useUserDataStore();
  const { researchScholarId } = useParams();
  const { scroll } = useStickyScroll();
  const pathname = window.location.pathname;
  const isSearchResult = pathname.includes("search-results");
  const { mutateAsync: savePersonalDetails, isLoading: isSavePersonalLoading } =
    useSavePersonalDetails();
  const { mutateAsync: saveEducationDetails, isLoading: isSaveEducationLoading } =
    useSaveEducationDetails();
  const { mutateAsync: saveSocialProfile, isLoading: isSaveSocialLoading } = useSaveSocialProfile();
  const { mutateAsync: saveWorkDetails, isLoading: isSaveWorkLoading } = useSaveWorkDetails();
  const { mutateAsync: saveResearchProject, isLoading: isSaveResearchProjectLoading } =
    useSaveResearchProject();
  const { mutateAsync: submitProfileDetails, isLoading: isSubmitLoading } =
    useSubmitProfileDetails();
  const { data: personalData, isLoading: isPersonalDetailsLoading } = useGetProfileDetails(
    researchScholarId ?? ""
  );
  const { data: educationalData, isLoading: isEducationalDetailsLoading } = useGetEducationDetails(
    researchScholarId ?? ""
  );
  const { data: workExperienceData, isLoading: isWorkDetailsLoading } = useGetWorkExperienceDetails(
    researchScholarId ?? ""
  );
  const { data: socialData, isLoading: isSocialDetailsLoading } = useGetSocialProfilesDetails(
    researchScholarId ?? ""
  );
  const { data: researchProjectData, isLoading: isResearchDetailsLoading } = useGetResearchProjects(
    researchScholarId ?? ""
  );

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  function setIsUploading(status: boolean) {
    setIsFileUploading(status);
  }
  const isReadOnly = personalData?.profileStatusId === USER_STATUS.PendingApproval;

  const [stepNumber, setStepNumber] = useState<number>(1);
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string> | null | Array<Record<string, string> | null | undefined>
  >(null);

  const checkUnsavedDetails = async (
    currentData: PersonalDetails | EducationDetails | WorkExperienceDetails | SocialProfile,
    savedData: PersonalDetails | EducationDetails | WorkExperienceDetails | SocialProfile
  ) => {
    if (JSON.stringify(currentData) === JSON.stringify(savedData)) {
      return false;
    } else {
      return true;
    }
  };

  const checkUnsavedResearchDetails = async (
    currentData: ResearchProject[],
    savedData: ResearchProject[]
  ) => {
    if (currentData?.length !== savedData?.length) {
      return true;
    }

    for (let i = 0; i < currentData?.length; i++) {
      if (JSON.stringify(currentData[i]) !== JSON.stringify(savedData[i])) {
        return true;
      }
    }
    return false;
  };

  const handleSavePersonalDetails = async (isNext = false) => {
    const personalDetails = useScholarProfileDataStore.getState().personInfo;
    if (personalDetails) {
      const error = profileInfoValidation(personalDetails);
      if (error) {
        setValidationErrors(error);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return;
      }
      setValidationErrors(null);
      const isSaveRequired = await checkUnsavedDetails(personalDetails, personalData);

      if (isSaveRequired) {
        try {
          const formData = {
            profileActualImage: personalDetails.profileActualImage,
            profileImage: personalDetails.profileImage,
            firstName: personalDetails.firstName?.trim(),
            middleName: personalDetails.middleName?.trim(),
            lastName: personalDetails.lastName?.trim(),
            about: personalDetails.about?.trim(),
            researchArea: personalDetails.researchArea,
            dateOfBirth: personalDetails.dateOfBirth,
            email: personalDetails.email?.trim(),
            contactNumber: personalDetails.contactNumber?.trim(),
            countryId: personalDetails.countryId,
            address: personalDetails.address?.trim(),
            fileDetails: personalDetails.fileDetails,
            profileStatusId: personalDetails.profileStatusId,
            profileCompletionStatusId: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Personal,
            nationalityId: personalDetails.nationalityId,
            countryCodeId: personalDetails.countryCodeId,
            genderId: personalDetails.genderId,
            titleId: personalDetails.titleId,
            researchInstituteName: personalDetails?.researchInstituteName,
          };
          const result = await savePersonalDetails({
            formData: formData,
            researchScholarId: researchScholarId ?? "",
          });
          showInfoToastMessage({
            message: result?.data?.message,
            iconType: ToasterIconType.UPDATE,
          });
          if (userInfo && userInfo?.userType === USERS.ResearchScholar) {
            setUserInfo({
              ...userInfo,
              firstName: personalDetails.firstName ?? "",
              avatarUrl: personalDetails ? personalDetails?.profileImage : "",
            });
          }

          return true;
        } catch (error) {
          showErrorToastMessage({
            message: t("global.something_went_wrong"),
          });
        }
      } else if (isNext) {
        return true;
      }
    }
  };
  const handleSaveEducationDetails = async (isNext = false) => {
    const educationDetails = useScholarProfileDataStore.getState().educationInfo;
    if (educationDetails) {
      const errors: Array<Record<string, string> | null | undefined> = [];
      educationDetails?.details?.map((item: HighestEducation) => {
        const error = educationInfoValidation(item);
        errors.push(error);
      });
      if (errors?.filter((item) => item !== null && item !== undefined).length > 0) {
        setValidationErrors(errors);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return;
      }
      setValidationErrors(null);
      const isSaveRequired = await checkUnsavedDetails(educationDetails, educationalData);
      if (isSaveRequired) {
        const formData = educationDetails?.details?.map((item: HighestEducation) => {
          const newItem = structuredClone(item);
          return {
            id: newItem?.id,
            instituteName: newItem?.instituteName?.trim(),
            courseName: newItem?.courseName?.trim(),
          };
        });
        const data = {
          details: [...formData],
          stillStudying: educationDetails.stillStudying,
          profileStatusId: educationDetails.profileStatusId,
          profileCompletionStatusId: educationDetails.profileCompletionStatusId,
        };
        try {
          const result = await saveEducationDetails({
            formData: data,
            researchScholarId: researchScholarId ?? "",
          });
          showInfoToastMessage({
            iconType: ToasterIconType.UPDATE,
            message: result?.data?.message,
          });
          return true;
        } catch (error) {
          showErrorToastMessage({
            message: t("global.something_went_wrong"),
          });
        }
      } else if (isNext) {
        return true;
      }
    }
  };

  const handleSaveWorkDetails = async (isNext = false) => {
    const workDetails = useScholarProfileDataStore.getState().workInfo;
    if (workDetails) {
      const errors: Array<Record<string, string> | null | undefined> = [];
      workDetails?.details?.map((item) => {
        const error = workExperienceValidation(item);
        errors.push(error);
      });
      if (errors?.filter((item) => item !== null && item !== undefined).length > 0) {
        setValidationErrors(errors);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return;
      }
      setValidationErrors(null);
      const isSaveRequired = await checkUnsavedDetails(workDetails, workExperienceData);
      if (isSaveRequired) {
        const formData = workDetails?.details?.map((item: WorkExperience) => {
          const newItem = structuredClone(item);
          return {
            id: newItem?.id,
            companyName: newItem?.companyName,
            companyCountryId: newItem?.companyCountryId,
            companyAddress: newItem?.companyAddress,
          };
        });
        const data = {
          details: [...formData],
          notWorking: workDetails?.notWorking,
          profileStatusId: workDetails.profileStatusId,
          profileCompletionStatusId: workDetails.profileCompletionStatusId,
        };

        try {
          const result = await saveWorkDetails({
            formData: data,
            researchScholarId: researchScholarId ?? "",
          });
          showInfoToastMessage({
            iconType: ToasterIconType.UPDATE,
            message: result?.data?.message,
          });
          return true;
        } catch (error) {
          showErrorToastMessage({
            message: t("global.something_went_wrong"),
          });
        }
      } else if (isNext) {
        return true;
      }
    }
  };

  const handleSaveSocialDetails = async (isNext = false) => {
    const socialProfilesData = useScholarProfileDataStore.getState().socialInfo;
    if (socialProfilesData) {
      const error = socialProfileValidation(socialProfilesData);
      if (error) {
        setValidationErrors(error);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return;
      }
      setValidationErrors(null);
      const isSaveRequired = await checkUnsavedDetails(socialProfilesData, socialData);
      if (isSaveRequired) {
        try {
          const data = {
            googleScholarURL: socialProfilesData.googleScholarURL,
            linkedInURL: socialProfilesData.linkedInURL,
            researchGateURL: socialProfilesData.researchGateURL,
            hIndex: !socialProfilesData.hIndex ? null : socialProfilesData.hIndex,
            profileStatusId: socialProfilesData.profileStatusId,
            profileCompletionStatusId: socialProfilesData.profileCompletionStatusId,
          };
          const result = await saveSocialProfile({
            formData: data,
            researchScholarId: researchScholarId ?? "",
          });
          showInfoToastMessage({
            iconType: ToasterIconType.UPDATE,
            message: result?.data?.message,
          });
          return true;
        } catch (error) {
          showErrorToastMessage({
            message: t("global.something_went_wrong"),
          });
        }
      } else if (isNext) {
        return true;
      }
    }
  };

  const handleSaveResearchProjects = async (isSubmit = false) => {
    const researchProjectsDetails = useScholarProfileDataStore.getState().researchProjectsInfo;
    if (researchProjectsDetails) {
      const errors: Array<Record<string, string> | null | undefined> = [];
      researchProjectsDetails?.map((item) => {
        const error = researchProjectValidation(item);
        errors.push(error);
      });
      if (errors?.filter((item) => item !== null && item !== undefined).length > 0) {
        setValidationErrors(errors);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return;
      }
      setValidationErrors(null);
      const isSaveRequired = await checkUnsavedResearchDetails(
        researchProjectsDetails,
        researchProjectData
      );
      if (isSaveRequired || isSubmit == true) {
        try {
          const projects = researchProjectsDetails?.map((item) => {
            const newItem = structuredClone(item);
            const tags =
              newItem?.tags && newItem.tags.length > 0
                ? newItem?.tags?.map((tag: OptionItem) => ({
                    id: tag.id,
                    name: {
                      en: tag.name.en !== "" ? tag.name.en : tag.name.ar,
                      ar: tag.name.ar !== "" ? tag.name.ar : tag.name.en,
                    },
                  }))
                : [];
            return {
              researchProjectId: newItem?.researchProjectId,
              researchTitle: newItem.researchTitle?.trim() || "",
              researchBrief: newItem?.researchBrief?.trim() || "",
              isInProgress: newItem?.isInProgress,
              startDate: newItem?.startDate === "" ? null : newItem?.startDate,
              endDate: newItem?.endDate === "" ? null : newItem?.endDate,
              documents: newItem?.documents,
              projectUrl: newItem?.projectUrl?.trim() || "",
              publicUrl: newItem?.publicUrl?.trim() || "",
              areaOfExpertise: newItem?.areaOfExpertise,
              tags: [...tags],
            };
          });
          const result = await saveResearchProject({
            formData: projects,
            researchScholarId: researchScholarId ?? "",
          });
          if (!isSubmit) {
            showInfoToastMessage({
              iconType: ToasterIconType.UPDATE,
              message: result?.data?.message,
            });
          }
          return true;
        } catch (error: ApiError) {
          showErrorToastMessage({
            message: error.response.data.message ?? t("global.something_went_wrong"),
          });
        }
      }
    }
  };

  const handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (stepNumber === 1) {
      handleSavePersonalDetails();
    } else if (stepNumber === 2) {
      handleSaveEducationDetails();
    } else if (stepNumber === 3) {
      handleSaveWorkDetails();
    } else if (stepNumber === 4) {
      handleSaveSocialDetails();
    } else if (stepNumber === 5) {
      handleSaveResearchProjects();
    }
  };

  const moveToNext = () => {
    if (stepNumber >= 1 && stepNumber < 5) {
      const currentStep = stepNumber;
      const nextStep = currentStep + 1;
      setStepNumber(nextStep);
    }
  };

  const removeValidationErrors = () => {
    setValidationErrors(null);
  };

  const handleNext = async () => {
    if (stepNumber === 1) {
      const success = await handleSavePersonalDetails(true);
      if (success) moveToNext();
    } else if (stepNumber === 2) {
      const success = await handleSaveEducationDetails(true);
      if (success) moveToNext();
    } else if (stepNumber === 3) {
      const success = await handleSaveWorkDetails(true);
      if (success) moveToNext();
    } else if (stepNumber === 4) {
      const success = await handleSaveSocialDetails(true);
      if (success) moveToNext();
    } else if (stepNumber === 5) {
      try {
        const success = await handleSaveResearchProjects(true);
        if (success) {
          const confirm = await showConfirmationModal({
            title: "global.submit_confirmation_title",
            message: "global.submit_confirmation_message",
            modalIcon: "confirmSubmissionV1",
            buttonIcon: "upload",
            cancelButtonText: "global.close",
            proceedButtonText: "global.submit",
          });
          if (!confirm) {
            return;
          } else {
            const submitResult = await submitProfileDetails();
            showInfoToastMessage({
              iconType: ToasterIconType.CONFIRM_SUBMISSION,
              message: submitResult.message,
            });
            if (submitResult?.data?.autoApprove) {
              navigate("/research-scholar/home");
            }
          }
        }
      } catch (error) {
        showErrorToastMessage({
          message: t("global.something_went_wrong"),
        });
      }
    }
  };
  const PROFILE_NAVIGATION_ITEM = [
    {
      value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Personal,
      label: "global.personal_details",
    },
    {
      value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Education,
      label: "global.highest_education",
    },
    {
      value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Work,
      label: "global.work_experience",
    },
    {
      value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Social,
      label: "global.social_profiles",
    },
    {
      value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Research,
      label: "global.research_projects",
    },
  ];

  const handleNavigationClick = async (step: number) => {
    if (stepNumber === step) return;
    let isSaveRequired = false;
    switch (stepNumber) {
      case 1: {
        const personalDetails = useScholarProfileDataStore.getState().personInfo;
        if (personalDetails) {
          isSaveRequired = await checkUnsavedDetails(personalDetails, personalData);
        }
        break;
      }
      case 2: {
        isSaveRequired = isUnSavedEducationDetailsExist();
        break;
      }
      case 3: {
        isSaveRequired = isUnSavedWorkDetailsExist();
        break;
      }
      case 4: {
        const socialProfilesData = useScholarProfileDataStore.getState().socialInfo;
        if (socialProfilesData) {
          isSaveRequired = await checkUnsavedDetails(socialProfilesData, socialData);
        }
        break;
      }
      case 5: {
        isSaveRequired = isUnsavedResearchProjectExist();
        break;
      }
    }
    showNavigationConfirmation(isSaveRequired, step);
  };

  const isUnSavedEducationDetailsExist = () => {
    const educationDetails = useScholarProfileDataStore.getState().educationInfo;
    if (educationDetails) {
      let unSavedForms = 0;
      if (educationDetails?.stillStudying !== educationalData?.stillStudying) return true;
      const hasUnsavedData = educationDetails?.details?.some((education: HighestEducation) => {
        if (!education.id) unSavedForms++;
        if (
          !education.id &&
          JSON.stringify(education) !== JSON.stringify(HIGHEST_EDUCATION_INITIAL_STATE)
        ) {
          return true;
        }
        if (
          education.id &&
          JSON.stringify(education) !==
            JSON.stringify(
              educationalData.details.find(
                (educationItem: HighestEducation) => educationItem.id === education.id
              )
            )
        ) {
          return true;
        }
        return false;
      });
      return (
        hasUnsavedData ||
        educationalData?.details?.length !== educationDetails?.details?.length - unSavedForms
      );
    }
    return false;
  };

  const isUnSavedWorkDetailsExist = () => {
    const workDetails = useScholarProfileDataStore.getState().workInfo;
    if (workDetails) {
      if (workDetails?.notWorking !== workExperienceData?.notWorking) return true;
      let unSavedForms = 0;
      const hasUnsavedData = workDetails?.details?.some((work: WorkExperience) => {
        if (!work.id) unSavedForms++;
        if (!work.id && JSON.stringify(work) !== JSON.stringify(WORK_EXPERIENCE_INITIAL_STATE)) {
          return true;
        }
        if (
          work.id &&
          JSON.stringify(work) !==
            JSON.stringify(
              workExperienceData.details.find(
                (workExperience: WorkExperience) => workExperience.id === work.id
              )
            )
        ) {
          return true;
        }
        return false;
      });
      return (
        hasUnsavedData ||
        workExperienceData?.details?.length !== workDetails?.details?.length - unSavedForms
      );
    }
    return false;
  };

  const showNavigationConfirmation = async (show: boolean, step: number) => {
    if (show) {
      const confirm = await showConfirmationModal({
        title: "organization.confirm_proceed",
        message: "organization.challenge_unsaved_section_confirm",
        cancelButtonText: "global.close",
        proceedButtonText: "global.proceed",
        modalIcon: "confirmSelection",
        isAlert: true,
      });
      if (confirm) {
        navigateNext(step);
      }
    } else {
      navigateNext(step);
    }
  };

  const navigateNext = (step: number) => {
    removeValidationErrors();
    setStepNumber(step);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isUnsavedResearchProjectExist = () => {
    const researchProjectsDetails = useScholarProfileDataStore.getState().researchProjectsInfo;
    if (researchProjectsDetails) {
      let emptyForms = 0;
      const hasUnsavedData = researchProjectsDetails?.some((researchProject: ResearchProject) => {
        if (!researchProject.researchProjectId) emptyForms++;
        if (
          !researchProject.researchProjectId &&
          JSON.stringify(researchProject) !== JSON.stringify(RESEARCH_PROJECT_INITIAL_STATE)
        ) {
          return true;
        }
        if (
          researchProject.researchProjectId &&
          JSON.stringify(researchProject) !==
            JSON.stringify(
              researchProjectData.find(
                (researchProjectItem: ResearchProject) =>
                  researchProjectItem.researchProjectId === researchProject.researchProjectId
              )
            )
        ) {
          return true;
        }
        return false;
      });
      return (
        hasUnsavedData ||
        researchProjectData?.length !== researchProjectsDetails?.length - emptyForms
      );
    }
    return false;
  };

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const linkList: Array<LinkItem> = useMemo(() => {
    const items: Array<LinkItem> =
      (userInfo?.userType === USERS.Admin || userInfo?.userType === USERS.ResearchInstitute) &&
      !isSearchResult
        ? [
            {
              title: "admin.research_scholars",
            },
            {
              title: getBreadCrumbTitle(personalData?.profileStatusId),
            },
            {
              title: "admin.edit_profile",
            },
          ]
        : [];
    return items;
  }, [personalData, userInfo, isSearchResult]);

  useEffect(() => {
    if (userInfo?.userType === USERS.ResearchScholar) {
      setSelectedSideMenu({
        type: "global.settings",
        isOpen: true,
        subMenuType: "global.profile",
      });
    } else {
      let type = "admin.users";
      if (userInfo?.userType === USERS.ResearchInstitute) {
        type = "home.researchers";
      }

      setSelectedSideMenu({
        type,
      });
    }
  }, [userInfo?.userType]);

  return (
    <div className="flex w-full  min-h-screen">
      <Header
        title={"global.profile"}
        linkList={linkList}
        className={
          scroll
            ? "  bg-primary-medium lg:bg-white animate__animated animate__fadeInDown lg:shadow-sticky lg:!py-[26px] lg:!h-[90px] fixed stickyHeader mobile-sticky-header"
            : "fixed bg-primary-medium lg:bg-gray-light"
        }
        handleCollapseClick={onToggleClick}
      />
      <div className=" adminDetails w-full mt-[170px] mb-20 lg:mb-0 lg:mt-0 lg:pt-[118px] pb-[45px] items-baseline px-4 lg:px-10 lg:min-h-screen flex flex-col  overflow-auto lg:ltr:pr-[30px] lg:rtl:pl-[30px]">
        <div className="w-full flex flex-col h-full ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 ltr:lg:pr-[292px] rtl:lg:pl-[292px] space-y-3">
          <div className=" flex-col bg-white w-full lg:h-full rounded-md flex mb-auto">
            {stepNumber === 1 ? (
              <PersonalDetails
                validationErrors={validationErrors as Record<string, string> | null}
                setIsUploading={setIsUploading}
              />
            ) : null}
            {stepNumber === 2 ? (
              <HighestEducation
                validationErrors={validationErrors as Array<Record<string, string>> | null}
                removeValidationErrors={removeValidationErrors}
              />
            ) : null}
            {stepNumber === 3 ? (
              <WorkExperience
                validationErrors={validationErrors as Array<Record<string, string>> | null}
                removeValidationErrors={removeValidationErrors}
              />
            ) : null}
            {stepNumber === 4 ? (
              <SocialProfiles
                validationErrors={validationErrors as Record<string, string> | null}
              />
            ) : null}
            {stepNumber === 5 ? (
              <ResearchProject
                validationErrors={validationErrors as Array<Record<string, string>> | null}
                removeValidationErrors={removeValidationErrors}
                isReadOnly={isReadOnly}
                setIsUploading={setIsUploading}
              />
            ) : null}
          </div>
        </div>
        <div
          className={
            scroll
              ? "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto fixed top-[114px] lg:top-[100px]  ltr:lg:right-[30px] rtl:lg:left-[30px]  z-[5]  lg:shadow-sticky  animate__animated animate__fadeInDown animateNone lg:transition-all"
              : "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto fixed top-[114px] lg:top-auto ltr:lg:right-[30px]  rtl:lg:left-[30px]   z-[5]"
          }
        >
          <Navigator
            navigatorList={PROFILE_NAVIGATION_ITEM}
            handleSave={handleSave}
            handleNext={handleNext}
            stepNumber={stepNumber}
            handleClick={handleNavigationClick}
            disabled={isReadOnly || isFileUploading}
            profileCompletionStatusId={personalData?.profileCompletionStatusId}
            profileStatusId={personalData?.profileStatusId}
          />
        </div>
        {isPersonalDetailsLoading ||
        isSavePersonalLoading ||
        isSaveEducationLoading ||
        isSaveSocialLoading ||
        isSaveWorkLoading ||
        isSaveResearchProjectLoading ||
        isSubmitLoading ||
        isEducationalDetailsLoading ||
        isWorkDetailsLoading ||
        isSocialDetailsLoading ||
        isResearchDetailsLoading ? (
          <LoaderModal />
        ) : null}
      </div>
    </div>
  );
}
