import { useQuery } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getLatestChallenges(isGlobal: boolean) {
  const res = await apiClient.get(urls.latestChallenges(isGlobal));
  return res?.data?.data as Array<Challenge> | ApiError;
}

export function useGetLatestChallenges(isGlobal: boolean) {
  return useQuery(
    ["latest-challenges"],
    async () => {
      return await getLatestChallenges(isGlobal);
    },
    {
      staleTime: Infinity,
    }
  );
}
