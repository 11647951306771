import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

function useInvalidateQuery(queryKey: string, id: string) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([queryKey, id]);
  }, [queryClient, queryKey, id]);

  return {};
}

export default useInvalidateQuery;
