import { create } from "zustand";
import { produce } from "immer";
import { persist } from "zustand/middleware";
import { GLOBAL_PORTAL_FILTERS } from "utils/constants";

export type FilterDataStore = {
  tableFilterInfo?: TableFilterInfo | undefined;
  userType: number | undefined;
  scrollInfo?: ScrollData | undefined;
  challengeFilterInfo?: ChallengeFilterInfo | undefined;
  searchFilterInfo?: SearchFilterInfo | undefined;
  organizationFilterInfo?: OrganizationFilterInfo | undefined;
  eventFilterInfo?: EventFilterInfo | undefined;
  collaborationRequestFilterInfo?: CollaborationRequestFilterInfo | undefined;
  advisorFilterInfo?: AdvisorFilterInfo | undefined;
};

export const CHALLENGE_INFO_INITIAL_STATE: ChallengeFilterInfo = {
  activeTab: null,
  activeFilter: null,
  exploreActiveTab: null,
  researchAreaId: [],
  selectedResearchAreas: [],
};

export const EVENT_INFO_INITIAL_STATE: EventFilterInfo = {
  activeTab: null,
  researchAreaId: [],
  selectedResearchAreas: [],
};

export const COLLABORATION_REQUEST_FILTER_INITIAL_STATE: CollaborationRequestFilterInfo = {
  activeTab: null,
  activeFilter: null,
};

export const useFilterDataStore = create<FilterDataStore>()(
  persist(
    (set) => ({
      tableFilterInfo: {
        pathName: "",
        country: "",
        researchArea: "",
        fromDate: null,
        toDate: null,
      },
      scrollInfo: undefined,
      challengeFilterInfo: CHALLENGE_INFO_INITIAL_STATE,
      searchFilterInfo: undefined,
      organizationFilterInfo: undefined,
      userType: GLOBAL_PORTAL_FILTERS.Both,
      eventFilterInfo: EVENT_INFO_INITIAL_STATE,
      collaborationRequestFilterInfo: COLLABORATION_REQUEST_FILTER_INITIAL_STATE,
      advisorFilterInfo: undefined,
    }),
    {
      name: "filter-data",
      getStorage: () => sessionStorage,
    }
  )
);

export function setTableFilterInfo(tableFilterData: TableFilterInfo) {
  useFilterDataStore.setState(
    produce((state: FilterDataStore) => {
      state.tableFilterInfo = tableFilterData;
    })
  );
}

export function setAdvisorFilterInfo(advisorFilter: AdvisorFilterInfo) {
  useFilterDataStore.setState(
    produce((state: FilterDataStore) => {
      state.advisorFilterInfo = advisorFilter;
    })
  );
}

export function setScrollInfo(scrollData: ScrollData) {
  useFilterDataStore.setState(
    produce((state: FilterDataStore) => {
      state.scrollInfo = scrollData;
    })
  );
}

export function setChallengeFilterInfoItem(
  key: keyof ChallengeFilterInfo,
  value: number | string | number[] | OptionItem[]
) {
  useFilterDataStore.setState(
    produce((state: any) => {
      if (state.challengeFilterInfo) {
        state.challengeFilterInfo[key] = value;
      }
    })
  );
}

export function setEventFilterInfoItem(
  key: keyof EventFilterInfo,
  value: number | string | number[] | OptionItem[]
) {
  useFilterDataStore.setState(
    produce((state: any) => {
      if (state.eventFilterInfo) {
        state.eventFilterInfo[key] = value;
      }
    })
  );
}

export function setSearchFilterInfo(searchFilterData: SearchFilterInfo) {
  useFilterDataStore.setState(
    produce((state: FilterDataStore) => {
      state.searchFilterInfo = searchFilterData;
    })
  );
}

export function setCollaborationRequestFilterInfo(
  collaborationData: CollaborationRequestFilterInfo
) {
  useFilterDataStore.setState(
    produce((state: FilterDataStore) => {
      state.collaborationRequestFilterInfo = collaborationData;
    })
  );
}

export function clearFilterDataStore(clearScrollInfo = true) {
  useFilterDataStore.setState(
    produce((state: FilterDataStore) => {
      state.tableFilterInfo = {
        pathName: "",
        country: "",
        researchArea: "",
        fromDate: null,
        toDate: null,
      };
      if (clearScrollInfo) {
        state.scrollInfo = undefined;
      }
      state.challengeFilterInfo = CHALLENGE_INFO_INITIAL_STATE;
      state.searchFilterInfo = undefined;
      state.organizationFilterInfo = undefined;
      state.eventFilterInfo = EVENT_INFO_INITIAL_STATE;
      state.collaborationRequestFilterInfo = COLLABORATION_REQUEST_FILTER_INITIAL_STATE;
    })
  );
}

export function setOrganizationFilterInfo(organizationFilter: OrganizationFilterInfo) {
  useFilterDataStore.setState(
    produce((state: FilterDataStore) => {
      state.organizationFilterInfo = organizationFilter;
    })
  );
}

export function setUserType(userType: number) {
  useFilterDataStore.setState(
    produce((state: FilterDataStore) => {
      state.userType = userType;
    })
  );
}

export function resetScroll() {
  useFilterDataStore.setState(
    produce((state: FilterDataStore) => {
      state.scrollInfo = undefined;
    })
  );
}

export function clearOrganizationFilterInfo() {
  useFilterDataStore.setState(
    produce((state: FilterDataStore) => {
      state.organizationFilterInfo = undefined;
    })
  );
}

export function clearUserTypeFilter() {
  useFilterDataStore.setState(
    produce((state: FilterDataStore) => {
      state.userType = GLOBAL_PORTAL_FILTERS.Both;
    })
  );
}

export function clearAdvisorFilterDataStore() {
  useFilterDataStore.setState(
    produce((state: FilterDataStore) => {
      state.advisorFilterInfo = undefined;
    })
  );
}
