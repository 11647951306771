import React from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";

type UploadButtonProps = {
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  icon?: string;
  disabled?: boolean;
};

export function UploadButton({ handleFileUpload, label, icon, disabled }: UploadButtonProps) {
  const { t } = useTranslation();
  return (
    <div
      className={` relative min-w-[50px] w-[50px]  lg:min-w-[150px] lg:w-[150px] h-10 rounded-md bg-white border border-primary-medium flex items-center justify-center  hover:bg-secondary-medium hover:text-white  hover:border-secondary-medium ${disabled ? " opacity-30 pointer-events-none " : ""
        }`}
    >
      <label className="text-primary-medium flex items-center font-normal space-x-2 rtl:space-x-reverse  hover:bg-secondary-medium hover:text-white">
        <SVGIcon name={icon ?? ""} />
        <span className=" hidden lg:block">{t(label)}</span>
        <input
          type="file"
          className=" opacity-0 absolute cursor-pointer w-full h-full -indent-[999px] typeFile left-0 mx-0"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileUpload(event)}
          disabled={disabled}
        />
      </label>
    </div>
  );
}
