import React from "react";
import Profile from "assets/images/avatar.svg";

type ProfilePictureProps = {
  url: string;
};

export default function ProfilePicture({ url }: ProfilePictureProps) {
  return (
    <div className="w-full h-full">
      <img
        alt="Profile Picture"
        src={url ? url : Profile}
        className="w-full h-full object-contain"
      />
    </div>
  );
}
