import React from "react";
import { useNavigate } from "react-router-dom";
import {
  SecondaryButton,
  ProfileNavigationTimeline,
  PrimaryButton,
  IconTypeButton,
  DropdownButton,
  TextButton,
} from "components";
import {
  CHALLENGE_USER_STATUS,
  CHALLENGE_STATUS,
  USERS,
  TRIGGER_EMAIL_NOTIFICATION_OPTIONS,
} from "utils/constants";
import { useUserDataStore } from "store/useUserDataStore";
import { useMasterDataStore } from "store/useMasterDataStore";

type NavigatorProps = {
  navigatorList: Array<RecordItem>;
  handleParticipate: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClick: (i: number, index: number) => void;
  stepNumber?: number;
  disabled?: boolean;
  userType: number | undefined;
  handleShareInterest: (event: React.MouseEvent<HTMLButtonElement>) => void;
  challengeDetails: ChallengePreview;
  activeMenu?: number;
  handleShareChallenge: () => void;
  handleNotifyItemSelection?: (type: string) => void;
  handleApprove: () => void;
  handleDeclineWithReason: () => void;
};

export const ChallengeNavigator = ({
  navigatorList,
  handleParticipate,
  stepNumber,
  handleClick,
  disabled,
  userType,
  handleShareInterest,
  challengeDetails,
  activeMenu,
  handleShareChallenge,
  handleNotifyItemSelection,
  handleApprove,
  handleDeclineWithReason
}: NavigatorProps) => {
  const { permissions } = useMasterDataStore();
  const { userInfo } = useUserDataStore();
  const navigate = useNavigate();

  const isButtonPresent =
    (challengeDetails &&
      challengeDetails?.userStatus !== CHALLENGE_USER_STATUS.Approved &&
      !challengeDetails?.challengeDetails?.isPrivate &&
      userType === USERS.ResearchScholar &&
      challengeDetails.challengeDetails.challengeStatusId !== CHALLENGE_STATUS.Disable &&
      permissions?.Participationrequest &&
      !userInfo?.isAdvisor) ||
    (userType === USERS.Funding &&
      challengeDetails &&
      permissions?.Showinterest &&
      challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Open &&
      !challengeDetails?.challengeDetails?.isGlobal) ||
    (permissions?.Viewpost && challengeDetails?.challengeDetails?.isDiscussionForumEligible) ||
    (challengeDetails &&
      (challengeDetails?.userStatus === CHALLENGE_USER_STATUS.Approved || userInfo?.isAdvisor) &&
      userType === USERS.ResearchScholar &&
      challengeDetails.challengeDetails.challengeStatusId === CHALLENGE_STATUS.Close);
  const getPrimaryButtonText = () => {
    if (challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Close) {
      return "organization.closed";
    }
    if (
      challengeDetails?.userStatus == CHALLENGE_USER_STATUS.PendingResearchInstituteApproval ||
      challengeDetails?.userStatus == CHALLENGE_USER_STATUS.PendingApproval || challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Pending
    ) {
      return "organization.approval_pending";
    } else {
      return "organization.participate";
    }
  };

  const handleParticipateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Close ||
      challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Live
    ) {
      return;
    } else {
      handleParticipate(event);
    }
  };

  return (
    <div className="challengeNav bg-gray-light lg:bg-white lg:rounded-md pl-0 lg:pl-3 pt-0 lg:pt-4 lg:!p-[15px] lg:!pb-0 max-[1025px]:bg-white">
      <div
        className={`grid gap-[10px] grid-cols-1 fixed lg:relative bottom-0 w-full  left-0 !z-30 bg-white py-4 px-10 lg:py-0 lg:px-0 shadow-footer lg:shadow-none max-[1025px]:!pb-4 max-[1025px]:px-4 max-[1025px]:z-[11] max-[1025px]:flex max-[1025px]:item-center max-[1025px]:justify-between ${
          isButtonPresent ? "!pb-[30px]" : ""
        }`}
      >
        {!challengeDetails?.challengeDetails?.isPrivate &&
        challengeDetails?.challengeDetails?.challengeStatusId !== CHALLENGE_STATUS.Disable ? (
          <IconTypeButton
            handleClick={handleShareChallenge}
            iconName="shareV4"
            className=" max-[1025px]:block hidden share-icon-button !m-0 max-[1025px]:order-none"
          />
        ) : null}
        {challengeDetails &&
        challengeDetails?.userStatus !== CHALLENGE_USER_STATUS.Approved &&
        !challengeDetails?.challengeDetails?.isPrivate &&
        userType === USERS.ResearchScholar &&
        challengeDetails.challengeDetails.challengeStatusId !== CHALLENGE_STATUS.Disable &&
        permissions?.Participationrequest && !(userType === USERS.Organization && challengeDetails.challengeDetails.challengeStatusId === CHALLENGE_STATUS.Pending) &&
        !userInfo?.isAdvisor ? (
          <PrimaryButton
            title={getPrimaryButtonText()}
            handleClick={handleParticipateClick}
            disabled={userType === USERS.Admin}
            className={
              challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Close ||
              challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Live ||
              challengeDetails?.userStatus ===
                CHALLENGE_USER_STATUS.PendingResearchInstituteApproval ||
              challengeDetails?.userStatus === CHALLENGE_USER_STATUS.PendingApproval
                ? " !mt-0 !h-12 !px-1.5 lg:!px-4 !text-xs lg:!text-sm opacity-50 pointer-events-none max-[1025px]:w-auto max-[1025px]:grow max-[1025px]:order-2"
                : "!mt-0 !h-12 !px-1.5 lg:!px-4 !text-xs lg:!text-sm max-[1025px]:w-auto max-[1025px]:grow max-[1025px]:order-2"
            }
          />
        ) : null}
        {challengeDetails &&
        (challengeDetails?.userStatus === CHALLENGE_USER_STATUS.Approved || userInfo?.isAdvisor) &&
        userType === USERS.ResearchScholar &&
        challengeDetails.challengeDetails.challengeStatusId === CHALLENGE_STATUS.Close ? (
          <PrimaryButton
            title={"organization.closed"}
            handleClick={() => {
              //No-op
            }}
            disabled={false}
            className={
              " !mt-0 !h-12 !px-1.5 lg:!px-4 !text-xs lg:!text-sm opacity-50 pointer-events-none max-[1025px]:w-auto max-[1025px]:grow max-[1025px]:order-2"
            }
          />
        ) : null}

        {userType === USERS.Funding &&
        challengeDetails &&
        permissions?.Showinterest &&
        challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Open &&
        !challengeDetails?.challengeDetails?.isGlobal ? (
          <PrimaryButton
            title={"funding_organization.share_interest"}
            handleClick={handleShareInterest}
            disabled={false}
            className="!px-1.5 lg:!px-4 !text-xs lg:!text-sm max-[1025px]:w-auto max-[1025px]:grow max-[1025px]:order-2"
          />
        ) : null}

        {challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Pending && 
        userType === USERS.Organization ? (
            <PrimaryButton
            title={getPrimaryButtonText()}
            handleClick={handleParticipateClick}
            disabled={userType === USERS.Organization}
            className={
              challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Pending
                ? " !mt-0 !h-12 !px-1.5 lg:!px-4 !text-xs lg:!text-sm opacity-50 pointer-events-none max-[1025px]:w-auto max-[1025px]:grow max-[1025px]:order-2"
                : "!mt-0 !h-12 !px-1.5 lg:!px-4 !text-xs lg:!text-sm max-[1025px]:w-auto max-[1025px]:grow max-[1025px]:order-2"
            }
          />
        ): null}


        {permissions?.Viewpost && challengeDetails?.challengeDetails?.isDiscussionForumEligible && !(challengeDetails.challengeDetails.challengeStatusId === CHALLENGE_STATUS.Pending) ? (
          <SecondaryButton
            title={"forum.discussion_forum"}
            handleClick={() => {
              navigate("discussion-forum");
            }}
            className="!h-12 w-full max-[1025px]:w-auto max-[1025px]:grow max-[1025px]:order-1 !px-1.5 lg:!px-4 !text-xs lg:!text-sm"
            disabled={disabled}
          />
        ) : null}

        {userInfo?.userType === USERS.Admin &&
        !challengeDetails?.challengeDetails?.isPrivate &&
        (challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Open ||
          challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Live) ? (
          <div>
            <DropdownButton
              handleSelection={(type: string) => {
                handleNotifyItemSelection?.(type);
              }}
              list={TRIGGER_EMAIL_NOTIFICATION_OPTIONS}
              title="admin.send_notification"
              className=" hover:bg-primary-medium hover:text-white w-full bg-transparent border border-primary-medium text-primary-medium text-sm font-medium !normal-case "
            />
          </div>
        ) : null}

        {challengeDetails?.challengeDetails?.challengeStatusId === CHALLENGE_STATUS.Pending &&
        userInfo?.userType === USERS.Admin ? (
          <div className="grid grid-cols-2 gap-[10px] pb-[0px]">
            <SecondaryButton
              title="global.decline"
              handleClick={handleDeclineWithReason}
            />

            <TextButton
              title={"global.approve"}
              handleClick={handleApprove}
              className="btn rounded-md w-full bg-secondary-medium border-none text-white text-sm font-medium  hover:bg-primary-medium hover:text-white"
            />
          </div>
        ) : null}
      </div>

      <ProfileNavigationTimeline
        navigatorList={navigatorList}
        stepNumber={stepNumber}
        handleClick={handleClick}
        isViewOnly
        activeMenu={activeMenu}
      />
    </div>
  );
};
