import { useTranslation } from "react-i18next";
import { Descriptor } from "components";
import { displayDateFormat } from "utils/helpers";

type MilestonePreviewCardProps = {
  title: string;
  startDate: string;
  endDate: string;
  description: string;
};

export function MilestonePreviewCard({
  title,
  startDate,
  endDate,
  description,
}: MilestonePreviewCardProps) {
  const { t } = useTranslation();
  return (
    <li className="preview-milestones-circle ltr:ml-5 rtl:mr-5">
      <div className="max-w-[90%] border border-[#DFE6EB] rounded-md p-6 shadow-200 max-[1025px]:p-4 max-[1025px]:max-w-full">
        <p className="font-semibold text-sm flex flex-col text-gray-109 Word-break">{title}</p>
        <div className="flex gap-5 text-gray-200 text-sm mt-1 items-end milestone-card-descriptors">
          <Descriptor
            title={`${t("global.start_date")} : ${displayDateFormat(startDate)}`}
            startIcon="previewDate"
            className="flex gap-2 items-center"
          />
          <Descriptor
            title={`${t("global.end_date")} : ${displayDateFormat(endDate)}`}
            startIcon="previewDate"
            className="flex gap-2 items-center"
          />
        </div>
        <p
          className="mt-4 text-gray-109 text-sm Word-break"
          dangerouslySetInnerHTML={{
            __html: description.replace(/\n/g, "<br/>").replace(/ /g, () => "&nbsp;"),
          }}
        />
      </div>
    </li>
  );
}
