import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { USER_STATUS } from "utils/constants";
import { urls } from "utils/urls";

async function saveFundingOrganizationDetails(
  formData: FundingOrganizationDetails,
  userId: string
) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  if (formData.profileStatusId === USER_STATUS.New) {
    return await apiClient.post(urls.fundingOrganization(), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    return await apiClient.put(urls.fundingOrganizationDetails(userId), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}

export function useSaveFundingOrganizationDetails() {
  const userInfo = useUserDataStore.getState().userInfo;
  const qc = useQueryClient();
  return useMutation(
    async ({
      formData,
      fundingOrganizationId,
    }: {
      formData: FundingOrganizationDetails;
      fundingOrganizationId: string;
    }) => {
      const userId = fundingOrganizationId ? fundingOrganizationId : userInfo?.userId;
      if (userId) {
        const result = await saveFundingOrganizationDetails(formData, userId);
        qc.invalidateQueries({ queryKey: ["funding-organization-details", userId] });
        if (formData.profileStatusId === USER_STATUS.New) {
          qc.invalidateQueries({ queryKey: ["funding-organization-contacts", userId] });
          qc.invalidateQueries({ queryKey: ["funding-organization-offices", userId] });
        }
        return result;
      }
    }
  );
}
