import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveCollaborationTags(formData: CollaborationTags, collaborationId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  const response = await apiClient.post(urls.collaborationTags(collaborationId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useSaveCollaborationTags() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ formData, collaborationId }: { formData: CollaborationTags; collaborationId: number }) => {
      const result = await saveCollaborationTags(formData, collaborationId);
      queryClient.invalidateQueries(["collaboration-about", collaborationId]);
      queryClient.invalidateQueries(["collaboration-tags", collaborationId]);
      queryClient.invalidateQueries(["collaboration-preview-details", collaborationId]);
      return result;
    }
  );
}
