import React from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { Title, UploadButton, FileUpload, FileUploadList, LoaderModal } from "components";
import { setDocumentsInfo, useOrganizationDataStore } from "store/useOrganizationDataStore";
import { showConfirmationModal } from "utils/confirmationModals";
import {
  ToasterIconType,
  showErrorToastMessage,
  showSuccessToastMessage,
} from "utils/toasterMessage";
import { MAX_FILE_SIZE } from "utils/constants";
import { useFileUpload } from "features/Organization/api/useFileUpload";
import { useDeleteFileUpload } from "features/Organization/api/useDeleteFileUpload";

type DocumentListProps = {
  isReadOnly: boolean;
  setIsUploading: (status: boolean) => void;
};

export default function DocumentList({ isReadOnly, setIsUploading }: DocumentListProps) {
  const { t } = useTranslation();
  const { mutateAsync: fileUpload, isLoading: isFileUploading } = useFileUpload();
  const { mutateAsync: deleteFileUpload, isLoading: isDeleteUploadLoading } = useDeleteFileUpload();
  const { documentsInfo } = useOrganizationDataStore();

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target?.files;
    if (selectedFiles && selectedFiles?.length > 0) {
      if (selectedFiles?.[0].size > MAX_FILE_SIZE) {
        event.target.value = "";
        return showErrorToastMessage({
          message: t("research_scholar.uploaded_files_size_error_message"),
        });
      }
      const fileName = selectedFiles?.[0].name;
      const isFileExist = documentsInfo?.find((item) => item.name === fileName);
      if (isFileExist) {
        showErrorToastMessage({
          message: t("research_scholar.multiple_same_name_file_error_message"),
        });
        event.target.value = "";
        return;
      }

      try {
        setIsUploading(true);
        const fileDetails = documentsInfo;
        setDocumentsInfo(fileDetails ? [...fileDetails, selectedFiles[0]] : [selectedFiles[0]]);

        const result = await fileUpload(selectedFiles);
        if (result) {
          setDocumentsInfo(fileDetails ? [...fileDetails, ...result] : [...result]);

          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 1000);
        }
      } catch (error) {
        const fileDetails = documentsInfo && documentsInfo;
        setDocumentsInfo(fileDetails ? [...fileDetails] : []);
        showErrorToastMessage({
          iconType: ToasterIconType.ALERT,
          message: t("global.something_went_wrong"),
        });
      } finally {
        setIsUploading(false);
        event.target.value = "";
      }
    }
  };

  const disableUpload = () => {
    if (isReadOnly) {
      return true;
    } else if (documentsInfo && documentsInfo?.length >= 5) {
      return true;
    } else if (isFileUploading) {
      return true;
    } else {
      return false;
    }
  };

  const handleRemove = async (
    event: React.MouseEvent<HTMLButtonElement>,
    item: FileDetailsItem
  ) => {
    event.stopPropagation();
    event.preventDefault();
    const result = await showConfirmationModal({
      title: "global.confirm_delete",
      message: "global.deletion_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.delete",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      if (item.id) {
        const formData = {
          id: item.id,
          name: item.name,
          fileUrl: item.fileUrl,
        };
        try {
          const response = await deleteFileUpload(formData);
          const files = documentsInfo;
          const newFiles = files?.filter((file) => file.fileUrl !== item?.fileUrl);
          if (newFiles) {
            setDocumentsInfo(newFiles);
          }
          showSuccessToastMessage({
            message: response.message,
          });
        } catch (error: ApiError) {
          if (error.response.data.status === 400) {
            showErrorToastMessage({
              message: error.response.data.message,
            });
          } else {
            showErrorToastMessage({
              iconType: ToasterIconType.ALERT,
              message: t("global.something_went_wrong"),
            });
          }
        }
      } else {
        const files = documentsInfo;
        const newFiles = files?.filter((file) => file.fileUrl !== item?.fileUrl);
        if (newFiles) {
          setDocumentsInfo(newFiles);
        }
        showSuccessToastMessage({
          message: t("global.document_deleted_successfully"),
        });
      }
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-6 flex-wrap pb-8 lg:pb-5 p-5 py-5 border-b border-gray-light">
        <div className="ltr:mr-auto rtl:ml-auto flex items-center justify-between">
          <SVGIcon name="documentsIcon" />
          <Title
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            title={t("organization.documents")}
          />
        </div>
        {documentsInfo?.length ? (
          <UploadButton
            label="global.upload"
            icon="uploadV2"
            handleFileUpload={(event) => handleFileUpload(event)}
            disabled={disableUpload()}
          />
        ) : null}
      </div>
      <div className=" p-5 pt-0 ">
        {!documentsInfo?.length ? (
          <FileUpload
            handleFileUpload={(event) => handleFileUpload(event)}
            icon={<SVGIcon name={"upload"} />}
            disabled={disableUpload()}
          />
        ) : null}
        <div className="mt-8 ">
          {documentsInfo?.length ? (
            <FileUploadList
              fileList={documentsInfo}
              handleRemove={handleRemove}
              disabled={isReadOnly}
              isFileUploading={isFileUploading}
            />
          ) : null}
        </div>
      </div>
      {isDeleteUploadLoading ? <LoaderModal /> : null}
    </div>
  );
}
