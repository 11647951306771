import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Header, LoaderModal } from "components";
import Office from "features/ResearchInstitute/Profile/Office";
import Contacts from "features/ResearchInstitute/Profile/Contacts";
import Documents from "features/ResearchInstitute/Profile/Documents";
import { Navigator } from "features/ResearchInstitute/Profile/Navigator";
import ResearchInstituteDetails from "features/ResearchInstitute/Profile/ResearchInstituteDetails";
import { useStickyScroll } from "hooks/useStickyScroll";
import {
  CONTACTS_INFO_INITIAL_STATE,
  OFFICE_DETAILS_INITIAL_STATE,
  ORGANIZATION_PROFILE_COMPLETION_STATUS,
  USERS,
  USER_STATUS,
} from "utils/constants";
import { showConfirmationModal } from "utils/confirmationModals";
import { researchInstituteDetailsValidation } from "utils/validation/researchInstituteDetailsValidation";
import { organizationContactValidation } from "utils/validation/organizationContactValidation";
import { organizationOfficeInfoValidation } from "utils/validation/organizationOfficeValidation";
import { useOrganizationDataStore } from "store/useOrganizationDataStore";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import { useResearchInstituteDataStore } from "store/useResearchInstituteDataStore";
import { setUserInfo, useUserDataStore } from "store/useUserDataStore";
import { useGetResearchInstituteDetails } from "features/ResearchInstitute/api/useGetResearchInstituteDetails";
import { useSaveResearchInstituteDetails } from "features/ResearchInstitute/api/useSaveResearchInstituteDetails";
import { useGetContacts } from "features/ResearchInstitute/api/useGetContacts";
import { useSaveContacts } from "features/ResearchInstitute/api/useSaveContacts";
import { useGetOffices } from "features/ResearchInstitute/api/useGetOffices";
import { useSaveOffices } from "features/ResearchInstitute/api/useSaveOffices";
import { useSaveDocuments } from "features/ResearchInstitute/api/useSaveDocuments";
import { useSubmitProfileDetails } from "features/ResearchInstitute/api/useSubmitProfileDetails";
import { getBreadCrumbTitle } from "utils/helpers";
import { setSelectedSideMenu } from "store/useSidebarStore";

export default function ResearchInstituteProfile() {
  const { t } = useTranslation();
  const pathname = window.location.pathname;
  const isSearchResult = pathname.includes("search-results");
  const { scroll } = useStickyScroll();
  const { userInfo } = useUserDataStore();
  const { researchInstituteId } = useParams();
  const [stepNumber, setStepNumber] = useState<number>(
    ORGANIZATION_PROFILE_COMPLETION_STATUS.Details
  );
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string> | null | Array<Record<string, string> | null>
  >(null);

  const { data: researchInstituteData, isLoading: isResearchInstituteDataLoading } =
    useGetResearchInstituteDetails(researchInstituteId ?? "");
  const {
    mutateAsync: saveResearchInstituteData,
    isLoading: isSaveResearchInstituteDetailsLoading,
  } = useSaveResearchInstituteDetails();
  const { data: researchInstituteContacts, isLoading: isResearchInstituteContactsLoading } =
    useGetContacts(researchInstituteId ?? "");
  const { mutateAsync: saveContacts, isLoading: isLoadingSaveContactsLoading } = useSaveContacts();
  const { data: officeData, isLoading: isOfficeDetailsLoading } = useGetOffices(
    researchInstituteId ?? ""
  );
  const { mutateAsync: saveOffices, isLoading: isLoadingSaveOffices } = useSaveOffices();
  const { mutateAsync: saveDocuments, isLoading: isLoadingSaveDocuments } = useSaveDocuments();
  const { mutateAsync: submitProfileDetails, isLoading: isLoadingSubmitProfileDetails } =
    useSubmitProfileDetails();

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  function setIsUploading(status: boolean) {
    setIsFileUploading(status);
  }
  const isReadOnly = researchInstituteData?.profileStatusId === USER_STATUS.PendingApproval;

  const handleNavigationClick = async (step: number) => {
    if (stepNumber === step) return;
    let isSaveRequired = false;
    switch (stepNumber) {
      case 1: {
        const researchInstituteDetails =
          useResearchInstituteDataStore.getState().researchInstituteInfo;
        if (researchInstituteDetails) {
          isSaveRequired = await checkUnsavedDetails(
            researchInstituteDetails,
            researchInstituteData
          );
        }
        break;
      }
      case 2: {
        isSaveRequired = isUnSavedContactsExist();
        break;
      }
      case 3: {
        isSaveRequired = isUnSavedOfficesExist();
        break;
      }
      case 4: {
        const uploads = useOrganizationDataStore.getState().documentsInfo;
        if (uploads) {
          isSaveRequired = await checkUnsavedUploads(uploads);
        }
        break;
      }
    }
    showNavigationConfirmationModal(isSaveRequired, step);
  };

  const showNavigationConfirmationModal = async (show: boolean, step: number) => {
    if (show) {
      const confirm = await showConfirmationModal({
        title: "organization.confirm_proceed",
        message: "organization.challenge_unsaved_section_confirm",
        cancelButtonText: "global.close",
        proceedButtonText: "global.proceed",
        modalIcon: "confirmSelection",
        isAlert: true,
      });
      if (confirm) {
        navigateNext(step);
      }
    } else {
      navigateNext(step);
    }
  };

  const navigateNext = (step: number) => {
    removeValidationErrors();
    setStepNumber(step);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isUnSavedContactsExist = () => {
    const contactsData = useOrganizationDataStore.getState().contactsInfo;
    if (contactsData) {
      let emptyForms = 0;
      const hasUnsavedData = contactsData?.contacts?.some((contact: OrganizationContact) => {
        if (!contact.id) emptyForms++;
        if (
          !contact.id &&
          JSON.stringify(contact) !== JSON.stringify(CONTACTS_INFO_INITIAL_STATE)
        ) {
          return true;
        }
        if (
          contact.id &&
          JSON.stringify(contact) !==
            JSON.stringify(
              researchInstituteContacts.contacts.find(
                (contactItem: OrganizationContact) => contactItem.id === contact.id
              )
            )
        ) {
          return true;
        }
        return false;
      });
      return (
        hasUnsavedData ||
        researchInstituteContacts?.contacts?.length !== contactsData?.contacts?.length - emptyForms
      );
    }
    return false;
  };

  const isUnSavedOfficesExist = () => {
    const officesData = useOrganizationDataStore.getState().officeDetails;
    if (officesData) {
      let emptyForms = 0;
      const hasUnsavedData = officesData?.offices?.some((office: OrganizationOffice) => {
        if (!office.id) emptyForms++;
        if (!office.id && JSON.stringify(office) !== JSON.stringify(OFFICE_DETAILS_INITIAL_STATE)) {
          return true;
        }
        if (
          office.id &&
          JSON.stringify(office) !==
            JSON.stringify(
              officeData.offices.find(
                (officeItem: OrganizationOffice) => officeItem.id === office.id
              )
            )
        ) {
          return true;
        }
        return false;
      });
      return (
        hasUnsavedData || officeData?.offices?.length !== officesData?.offices?.length - emptyForms
      );
    }
    return false;
  };

  const PROFILE_NAVIGATION_ITEM = [
    {
      value: 1,
      label: "research_institute.research_institute_details",
    },
    {
      value: 2,
      label: "organization.contacts",
    },
    {
      value: 3,
      label: "organization.office",
    },
    {
      value: 4,
      label: "organization.documents",
    },
  ];

  const removeValidationErrors = () => {
    setValidationErrors(null);
  };

  const handleSave = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (stepNumber === 1) {
      saveResearchInstituteDetails();
    } else if (stepNumber === 2) {
      handleSaveContacts();
    } else if (stepNumber === 3) {
      handleSaveOffices();
    } else if (stepNumber === 4) {
      handleSaveUploads();
    }
  };

  const handleNext = async () => {
    if (stepNumber === 1) {
      const success = await saveResearchInstituteDetails(true);
      if (success) moveToNext();
    } else if (stepNumber === 2) {
      const success = await handleSaveContacts(true);
      if (success) moveToNext();
    } else if (stepNumber === 3) {
      const success = await handleSaveOffices(true);
      if (success) moveToNext();
    } else if (stepNumber === 4) {
      try {
        const success = await handleSaveUploads(true);
        if (success) {
          const confirm = await showConfirmationModal({
            title: "global.submit_confirmation_title",
            message: "global.submit_confirmation_message",
            modalIcon: "confirmSubmissionV1",
            buttonIcon: "upload",
            cancelButtonText: "global.close",
            proceedButtonText: "global.submit",
          });
          if (!confirm) {
            return;
          } else {
            const submitResult = await submitProfileDetails();
            showInfoToastMessage({
              iconType: ToasterIconType.CONFIRM_SUBMISSION,
              message: submitResult.message,
            });
          }
        }
      } catch (error) {
        showErrorToastMessage({
          message: t("global.something_went_wrong"),
        });
      }
    }
  };

  const moveToNext = () => {
    if (stepNumber >= 1 && stepNumber < 4) {
      const currentStep = stepNumber;
      const nextStep = currentStep + 1;
      setStepNumber(nextStep);
    }
  };

  const checkUnsavedDetails = async (
    currentData: ResearchInstituteDetails,
    savedData: ResearchInstituteDetails
  ) => {
    if (JSON.stringify(currentData) === JSON.stringify(savedData)) {
      return false;
    } else {
      return true;
    }
  };

  const prepareResearchInstituteDetailsFormData = (data: ResearchInstituteDetails) => {
    const formData = {
      profileActualImage: data.profileActualImage,
      profileImage: data.profileImage,
      instituteName: data.instituteName?.trim(),
      about: data.about?.trim(),
      researchArea: data.researchArea,
      instituteTypeId: data.instituteTypeId,
      email: data.email?.trim(),
      contactNumber: data.contactNumber?.trim(),
      linkedInUrl: data.linkedInUrl?.trim(),
      websiteUrl: data.websiteUrl?.trim(),
      countryId: data.countryId,
      profileStatusId: data?.profileStatusId,
      profileCompletionStatusId:
        data?.profileCompletionStatusId ?? ORGANIZATION_PROFILE_COMPLETION_STATUS.Details,
      countryCodeId: data.countryCodeId,
      statusId: data.statusId,
    };
    return formData;
  };

  const saveResearchInstituteDetails = async (isNext = false) => {
    const researchInstituteDetails = useResearchInstituteDataStore.getState().researchInstituteInfo;

    if (researchInstituteDetails) {
      const error = researchInstituteDetailsValidation(researchInstituteDetails);
      if (error) {
        setValidationErrors(error);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return;
      }
      setValidationErrors(null);
      const isSaveRequired = await checkUnsavedDetails(
        researchInstituteDetails,
        researchInstituteData
      );

      if (isSaveRequired) {
        try {
          const formData = prepareResearchInstituteDetailsFormData(researchInstituteDetails);
          const result = await saveResearchInstituteData({
            formData: formData,
            researchInstituteId: researchInstituteId ?? "",
          });
          if (userInfo && userInfo.userType === USERS.ResearchInstitute) {
            setUserInfo({
              ...userInfo,
              firstName: researchInstituteDetails?.instituteName ?? "",
              avatarUrl: researchInstituteDetails ? researchInstituteDetails?.profileImage : "",
            });
          }
          showInfoToastMessage({
            iconType: ToasterIconType.UPDATE,
            message: result?.data?.message,
          });
          return true;
        } catch (error) {
          return showErrorToastMessage({
            message: t("global.something_went_wrong"),
          });
        }
      } else if (isNext) {
        return true;
      }
    }
  };

  const checkUnsavedContacts = async (
    currentData: OrganizationContactDetails,
    savedData: OrganizationContactDetails
  ) => {
    if (currentData?.contacts?.length !== savedData?.contacts?.length) {
      return true;
    }

    for (let i = 0; i < currentData?.contacts?.length; i++) {
      if (JSON.stringify(currentData?.contacts?.[i]) !== JSON.stringify(savedData?.contacts?.[i])) {
        return true;
      }
    }
    return false;
  };

  const prepareOrganizationContactsFormData = (data: OrganizationContactDetails) => {
    const contacts = data?.contacts?.map((item, index) => {
      const newItem: OrganizationContact = structuredClone(item);
      return {
        id: newItem.id,
        fullName: newItem.fullName?.trim(),
        designation: newItem.designation?.trim(),
        email: newItem.email?.trim(),
        countryCodeId: newItem.countryCodeId,
        contactNumber: newItem?.contactNumber?.trim(),
        isPrimary: index === 0 ? true : false,
      };
    });
    return contacts;
  };

  const handleSaveContacts = async (isNext = false) => {
    const contactsData = useOrganizationDataStore.getState().contactsInfo;

    if (contactsData) {
      const errors: Array<Record<string, string> | null> = [];
      contactsData?.contacts?.map((item: OrganizationContact) => {
        const error = organizationContactValidation(item);
        errors.push(error);
      });
      if (errors?.filter((item) => item !== null && item !== undefined).length > 0) {
        setValidationErrors(errors);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return;
      }
      setValidationErrors(null);
      const isSaveRequired = await checkUnsavedContacts(researchInstituteContacts, contactsData);
      if (isSaveRequired) {
        try {
          const contacts = prepareOrganizationContactsFormData(contactsData);
          const result = await saveContacts({
            formData: contacts,
            researchInstituteId: researchInstituteId ? researchInstituteId : "",
          });
          showInfoToastMessage({
            iconType: ToasterIconType.UPDATE,
            message: result?.data?.message,
          });
          return true;
        } catch (error: ApiError) {
          return showErrorToastMessage({
            message: error.response.data.message ?? t("global.something_went_wrong"),
          });
        }
      } else if (isNext) {
        return true;
      }
    }
  };

  const checkUnsavedOffices = async (
    currentData: OrganizationOfficeDetails,
    savedData: OrganizationOfficeDetails
  ) => {
    if (currentData?.offices?.length !== savedData?.offices?.length) {
      return true;
    }

    for (let i = 0; i < currentData?.offices?.length; i++) {
      if (JSON.stringify(currentData?.offices?.[i]) !== JSON.stringify(savedData?.offices?.[i])) {
        return true;
      }
    }
    return false;
  };

  const prepareOrganizationOfficesFormData = (data: OrganizationOfficeDetails) => {
    const offices = data?.offices?.map((item) => {
      const newItem: OrganizationOffice = structuredClone(item);
      return {
        id: newItem.id,
        name: newItem.name?.trim(),
        address: newItem.address?.trim(),
      };
    });
    return offices;
  };

  const handleSaveOffices = async (isNext = false) => {
    const officesData = useOrganizationDataStore.getState().officeDetails;
    if (officesData) {
      const errors: Array<Record<string, string> | null> = [];
      officesData?.offices?.map((item: OrganizationOffice) => {
        const error = organizationOfficeInfoValidation(item);
        errors.push(error);
      });
      if (errors?.filter((item) => item !== null && item !== undefined).length > 0) {
        setValidationErrors(errors);
        showErrorToastMessage({
          message: t("global.error_message"),
        });
        return;
      }
      setValidationErrors(null);

      const isSaveRequired = await checkUnsavedOffices(officesData, officeData);
      if (isSaveRequired) {
        try {
          const offices = prepareOrganizationOfficesFormData(officesData);
          const result = await saveOffices({
            formData: offices,
            researchInstituteId: researchInstituteId ? researchInstituteId : "",
          });
          showInfoToastMessage({
            iconType: ToasterIconType.UPDATE,
            message: result?.data?.message,
          });
          return true;
        } catch (error: ApiError) {
          return showErrorToastMessage({
            message: error.response.data.message ?? t("global.something_went_wrong"),
          });
        }
      } else if (isNext) {
        return true;
      }
    } else {
      return true;
    }
  };

  const checkUnsavedUploads = async (currentData: Array<FileDetailsItem>) => {
    for (let i = 0; i < currentData?.length; i++) {
      if (!currentData[i].id) {
        return true;
      }
    }
    return false;
  };

  const handleSaveUploads = async (isSubmit = false) => {
    const uploads = useOrganizationDataStore.getState().documentsInfo;
    if (uploads || isSubmit) {
      const uploadData = uploads ? uploads : [];
      const isSaveRequired = await checkUnsavedUploads(uploadData);
      if (isSaveRequired) {
        try {
          const result = await saveDocuments({
            formData: uploadData,
            researchInstituteId: researchInstituteId ? researchInstituteId : "",
          });
          if (!isSubmit) {
            showInfoToastMessage({
              iconType: ToasterIconType.UPDATE,
              message: result?.data?.message,
            });
          }
          return true;
        } catch (error: ApiError) {
          return showErrorToastMessage({
            message: error.response.data.message ?? t("global.something_went_wrong"),
          });
        }
      } else {
        return true;
      }
    }
  };

  const linkList: Array<LinkItem> = useMemo(() => {
    const items: Array<LinkItem> =
      userInfo?.userType === USERS.Admin && !isSearchResult
        ? [
            {
              title: "home.research_institutes",
            },
            {
              title: getBreadCrumbTitle(researchInstituteData?.profileStatusId),
            },
            {
              title: "admin.edit_profile",
            },
          ]
        : [];
    return items;
  }, [userInfo?.userType, isSearchResult, researchInstituteData?.profileStatusId]);

  useEffect(() => {
    if (userInfo?.userType === USERS.ResearchInstitute) {
      setSelectedSideMenu({
        type: "global.settings",
        isOpen: true,
        subMenuType: "global.profile",
      });
    } else if (userInfo?.userType === USERS.Admin) {
      setSelectedSideMenu({
        type: "admin.users",
      });
    }
  }, [userInfo?.userType]);

  return (
    <div className={`flex  min-h-screen w-full`}>
      <Header
        title={"global.profile"}
        linkList={linkList}
        className={
          scroll
            ? "  bg-primary-medium lg:bg-white animate__animated animate__fadeInDown lg:shadow-sticky lg:!py-[26px] lg:!h-[90px] fixed stickyHeader"
            : "fixed bg-primary-medium lg:bg-gray-light"
        }
      />
      <div className="adminDetails w-full pt-[118px] pb-[45px] items-baseline px-10 ltr:pr-[30px] rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto ">
        <div className="w-full flex flex-col h-full ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 ltr:lg:pr-[292px] rtl:lg:pl-[292px] space-y-3">
          <div className=" flex-col bg-white w-full h-full rounded-none lg:rounded-md flex mb-auto">
            {stepNumber === 1 ? (
              <ResearchInstituteDetails
                validationErrors={validationErrors as Record<string, string> | null}
              />
            ) : null}
            {stepNumber === 2 ? (
              <Contacts
                removeValidationErrors={removeValidationErrors}
                validationErrors={validationErrors as Array<Record<string, string>> | null}
              />
            ) : null}
            {stepNumber === 3 ? (
              <Office
                validationErrors={validationErrors as Array<Record<string, string>> | null}
                removeValidationErrors={removeValidationErrors}
              />
            ) : null}
            {stepNumber === 4 ? <Documents setIsUploading={setIsUploading} /> : null}
          </div>
        </div>
        <div
          className={
            scroll
              ? "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto fixed top-[100px]  ltr:lg:right-[30px] rtl:lg:left-[30px]  z-[5]  shadow-sticky  animate__animated animate__fadeInDown transition-all"
              : "w-full min-w-full lg:w-[280px] lg:min-w-[280px]   mb-auto fixed top-[76px] lg:top-auto ltr:lg:right-[30px] rtl:left-[30px]  z-[5]"
          }
        >
          <Navigator
            navigatorList={PROFILE_NAVIGATION_ITEM}
            handleSave={handleSave}
            handleNext={handleNext}
            stepNumber={stepNumber}
            handleClick={handleNavigationClick}
            disabled={isReadOnly || isFileUploading}
            profileCompletionStatusId={researchInstituteData?.profileCompletionStatusId}
            profileStatusId={researchInstituteData?.profileStatusId}
          />
        </div>
        {isResearchInstituteDataLoading ||
        isSaveResearchInstituteDetailsLoading ||
        isResearchInstituteContactsLoading ||
        isLoadingSaveContactsLoading ||
        isOfficeDetailsLoading ||
        isLoadingSaveOffices ||
        isLoadingSaveDocuments ||
        isLoadingSubmitProfileDetails ? (
          <LoaderModal />
        ) : null}
      </div>
    </div>
  );
}
