import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveCaseSummary(
  formData: UploadCaseStudyFormData,
  collaborationId: number,
  isEdit: boolean
) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  let response;

  if (isEdit) {
    response = await apiClient.put(urls.collaborationCaseSummary(collaborationId), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    response = await apiClient.post(urls.collaborationCaseSummary(collaborationId), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  return response?.data as ApiStatus | ApiError;
}

export function useSaveCaseSummary() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      formData,
      collaborationId,
      isEdit,
    }: {
      formData: UploadCaseStudyFormData;
      collaborationId: number;
      isEdit: boolean;
    }) => {
      const data = await saveCaseSummary(formData, collaborationId, isEdit);
      queryClient.invalidateQueries(["collaboration-basic-details", collaborationId]);
      return data;
    }
  );
}
