import { useQuery } from "@tanstack/react-query";
import { setUserInfo, useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { USERS } from "utils/constants";
import { urls } from "utils/urls";

async function getProfileDetails(userId: string, userInfo: UserInfo) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.personalDetails(userId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  const response = res.data?.data;
  delete response.isEdit;
  delete response.userId;
  if (userInfo && userInfo?.userType === USERS.ResearchScholar) {
    setUserInfo({
      ...userInfo,
      firstName: response?.firstName ?? "",
      profileStatusId: response.profileStatusId,
      avatarUrl: response?.profileImage,
    });
  }
  return response as PersonalDetails | ApiError;
}

export function useGetProfileDetails(researchScholarId: string) {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = researchScholarId ? researchScholarId : userInfo?.userId;

  return useQuery(
    ["personal-details", userId],
    async () => {
      if (userId && userInfo) {
        return await getProfileDetails(userId, userInfo);
      } else return;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
