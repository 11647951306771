import { useEffect, useState } from "react";
import ForumHeader from "features/DiscussionForum/ForumHeader";
import { useGetChallengeBasicDetails } from "features/Organization/api/useGetChallengeBasicDetails";
import { CreatePost } from "features/DiscussionForum/Posts/CreatePost";
import Posts from "features/DiscussionForum/Posts";
import { InlineLoader, NoData } from "components";

type ForumProps = {
  challengeId: number;
  resetTabs: () => void;
};

export default function Forum({ challengeId, resetTabs }: ForumProps) {
  const pathName = window.location.pathname;
  const isExplore = pathName.includes("explore");
  const { data: challengeData, isLoading } = useGetChallengeBasicDetails(challengeId, isExplore);

  const [showCreatePost, setShowCreatePost] = useState(false);

  useEffect(() => {
    if (challengeData?.isDiscussionForumEligible === false || challengeData?.hasPostedOrReplied === false) {
      resetTabs();
    }
  }, [challengeData?.isDiscussionForumEligible, challengeData?.hasPostedOrReplied]);

  return (
    <div className=" w-full h-full" key={challengeId}>
      {challengeData ? (
        <ForumHeader
          challengeData={challengeData}
          handleCreatePost={() => setShowCreatePost((prevState) => !prevState)}
          showCreatePost={!showCreatePost}
        />
      ) : null}
      <div className="chatReplayHolder">
        {showCreatePost && challengeData?.isDiscussionForumEligible ? (
          <CreatePost
            key={challengeId}
            challengeId={challengeId}
            handleDiscardPost={() => setShowCreatePost((prevState) => !prevState)}
            handlePost={() => setShowCreatePost((prevState) => !prevState)}
          />
        ) : null}
        {challengeId && challengeData?.isDiscussionForumEligible ? (
          <Posts
            challengeId={challengeId}
            challengeData={challengeData}
            isShowCreatePost={showCreatePost}
          />
        ) : null}
        {!challengeId && !isLoading ? (
          <div className="p-5 bg-white w-full h-full rounded-md nodata">
            <NoData
              className="!mb-0 bg-gray-111 justify-center py-0"
              iconName="noMsg"
              message="forum.select_a_post_to_show_here"
            />
          </div>
        ) : null}
      </div>
      {isLoading ? <InlineLoader /> : null}
    </div>
  );
}
