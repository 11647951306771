import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import {
  Chip,
  Descriptor,
  Header,
  InlineLoader,
  Label,
  LoaderModal,
  MultiSelect,
  NoData,
  PrimaryButton,
  RadioButton,
  SecondaryButton,
  SubTitle,
  Title,
  IconTypeButton,
  CheckBox,
} from "components";
import Select from "react-select";
import { useStickyScroll } from "hooks/useStickyScroll";
import Profile from "assets/images/avatar.svg";
import ChallengeImage from "assets/images/challengeDefault.png";
import { Modal } from "react-daisyui";
import { useGetSearchResults } from "features/GlobalSearch/api/useGetSearchResults";
import { GLOBAL_SEARCH_TYPES, INITIAL_SEARCH_PARAMS, PAGE_LIMIT, USERS } from "utils/constants";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { useMasterDataStore } from "store/useMasterDataStore";
import { displayDateFormat, setMasterDataValue } from "utils/helpers";
import { useUserDataStore } from "store/useUserDataStore";
import GlobalSearch from "features/GlobalSearch";
import { setScrollInfo, setSearchFilterInfo, useFilterDataStore } from "store/useFilterDataStore";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import { useSetScroll } from "hooks/useSetScroll";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";
import useResetQueryOnLanguageSwitch from "hooks/useResetQueryOnLanguageSwitch";
type SearchListItemProps = {
  data: SearchResultData;
  handleSearchItemClick: () => void;
};

type FilterModalProps = {
  isOpenFilterModal?: boolean;
};

export default function SearchList() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { permissions } = useMasterDataStore();
  const { userInfo } = useUserDataStore();
  const searchKeyword = searchParams.get("key");
  const { scroll } = useStickyScroll();
  const { masterData } = useMasterDataStore();
  const { scrollInfo, searchFilterInfo } = useFilterDataStore();
  const { scrollPosition } = useGetScrollPosition();

  const enableChallengeFilter =
    (permissions &&
      permissions.Viewopenchallenges &&
      permissions.Viewlivechallenges &&
      permissions.Viewclosedchallenges &&
      userInfo?.userType !== USERS.ResearchInstitute) ||
    (permissions &&
      permissions.Viewopenchallenges &&
      permissions.Viewclosedchallenges &&
      userInfo?.userType === USERS.ResearchInstitute);
  const [requestParams, setRequestParams] = useState<GlobalSearchParams>({
    pageNumber: 1,
    pageLimit: 10,
    searchValue: searchKeyword ?? "",
    countryId: searchFilterInfo?.countryId ?? [],
    researchAreaId: searchFilterInfo?.researchAreaId ?? [],
    userType: searchFilterInfo?.userType ?? 0,
    targetIndustryId: searchFilterInfo?.targetIndustryId ?? [],
    searchType: searchFilterInfo?.searchType ?? GLOBAL_SEARCH_TYPES.users,
    isGlobalSearch: searchFilterInfo?.isGlobalSearch ?? false,
  });

  const [userFilters, setUserFilters] = useState<UserSearchFilters>({
    userType: searchFilterInfo?.userType ?? 0,
    country: searchFilterInfo?.countryId ?? [],
    researchArea: searchFilterInfo?.researchAreaId ?? [],
    targetIndustry: searchFilterInfo?.targetIndustryId ?? [],
    searchType: searchFilterInfo?.searchType ?? GLOBAL_SEARCH_TYPES.users,
    isGlobalSearch: searchFilterInfo?.isGlobalSearch ?? false,
  });

  const [selectedOptions, setSelectedOptions] = useState<OptionItem[]>(
    searchFilterInfo?.selectedAreas ?? []
  );

  const userTypeFilters: Array<RecordItem> = useMemo(() => {
    const tempItems = [
      {
        value: USERS.All,
        label: "global.all",
      },
      {
        value: USERS.ResearchScholar,
        label: "global.research_scholars",
      },
      {
        value: USERS.Organization,
        label: "global.organizations",
      },
      {
        value: USERS.ResearchInstitute,
        label: "global.research_institutes",
      },
    ];
    if (!userInfo?.isGlobal) {
      tempItems.push({
        value: USERS.Funding,
        label: "global.funding_organizations",
      });
    }
    return tempItems;
  }, [userInfo]);

  const [selectedCountries, setSelectedCountries] = useState<Option[]>(
    searchFilterInfo?.selectedCountries ?? []
  );
  const {
    data,
    isLoading: searchListLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetSearchResults(requestParams);
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "search-results");
  useResetQueryOnLanguageSwitch("search-results");

  const searchData = useMemo(() => {
    const tempData: GlobalSearchResult = { searchDetails: [], totalCount: 0, totalPages: 0 };
    if (data?.pages && data?.pages[0]) {
      tempData.totalCount = data.pages[0]?.totalCount;
      tempData.totalPages = data.pages[0]?.totalPages;
      const searchDetails = data.pages
        .map((item) => {
          return item.searchDetails;
        })
        .flat();
      tempData.searchDetails = searchDetails;
    } else {
      tempData.searchDetails = [];
      tempData.totalCount = 0;
      tempData.totalPages = 0;
    }

    return tempData;
  }, [data]);

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };
  const handleUserTypeClick = (value: number) => {
    if (userFilters?.searchType === GLOBAL_SEARCH_TYPES.challenge) {
      setSelectedOptions([]);
    }
    setUserFilters((prevState) => ({
      ...prevState,
      userType: value,
      searchType: GLOBAL_SEARCH_TYPES.users,
      targetIndustry: [],
    }));
  };

  const handleChallengeClick = () => {
    setSelectedOptions([]);
    setSelectedCountries([]);
    setUserFilters((prevState) => ({
      ...prevState,
      searchType: GLOBAL_SEARCH_TYPES.challenge,
      userType: -1,
      researchArea: [],
    }));
  };

  const handleApplyFilterClick = () => {
    setSearchFilterInfo({
      searchKeyword: searchKeyword,
      userType: userFilters?.userType,
      searchType: userFilters?.searchType,
      isGlobalSearch: userFilters?.isGlobalSearch,
      countryId: userFilters?.country,
      selectedCountries: selectedCountries,
      researchAreaId: userFilters?.researchArea,
      selectedAreas: selectedOptions,
      targetIndustryId: userFilters?.targetIndustry,
    });
    closeFilterModal();
    setRequestParams((prevState) => ({
      ...prevState,
      searchType: userFilters?.searchType,
      userType: userFilters?.userType,
      countryId: userFilters?.country,
      researchAreaId: userFilters?.researchArea,
      targetIndustryId: userFilters?.targetIndustry,
      isGlobalSearch: userFilters?.isGlobalSearch,
    }));
  };

  const countryOptions = useMemo(() => {
    return masterData?.country?.map((item) => {
      return setMasterDataValue(item, i18n.language);
    });
  }, [masterData, i18n.language]);

  const NoOptionsMessage = () => {
    return <div>{t("global.no_results")}</div>;
  };

  const handleSelectChange = (selected: any) => {
    if (selected === null) {
      setSelectedOptions([]);
      setUserFilters((prevState) => ({ ...prevState, country: [] }));
    } else if (selected.length <= 5) {
      const newOptions = selected.filter(
        (option: any) =>
          !selectedOptions.some((selectedOption) => selectedOption.id === option.value)
      );
      if (newOptions.length > 0) {
        const countryId = newOptions.map((option: any) => Number(option.value));

        setUserFilters((prevState) => ({
          ...prevState,
          country: [...countryId],
        }));
      }
    }
    setSelectedCountries([...selected]);
  };

  const handleSelectedOptions = (options: OptionItem[] | null) => {
    if (options === null) {
      setSelectedOptions([]);
      if (userFilters?.searchType === GLOBAL_SEARCH_TYPES.users) {
        setUserFilters((prevState) => ({ ...prevState, researchArea: [] }));
      } else if (userFilters?.searchType === GLOBAL_SEARCH_TYPES.challenge) {
        setUserFilters((prevState) => ({ ...prevState, targetIndustry: [] }));
      }
    } else if (options.length <= 5) {
      const newOptions = options.filter(
        (option) => !selectedOptions.some((selectedOption) => selectedOption.id === option.id)
      );
      if (newOptions.length > 0) {
        setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, ...newOptions]);
        if (userFilters?.searchType === GLOBAL_SEARCH_TYPES.users) {
          const researchAreaIds = newOptions.map((option) => Number(option.id));
          setUserFilters((prevState) => ({
            ...prevState,
            researchArea: [...prevState.researchArea, ...researchAreaIds],
          }));
        } else if (userFilters?.searchType === GLOBAL_SEARCH_TYPES.challenge) {
          const targetIndustryIds = newOptions.map((option) => Number(option.id));
          setUserFilters((prevState) => ({
            ...prevState,
            targetIndustry: [...prevState.targetIndustry, ...targetIndustryIds],
          }));
        }
      }
    }
    searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
  };

  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);
  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  const handleRemoveSelectedOption = (optionToRemove: OptionItem) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    const remainingItems = selectedOptions.filter((option) => option.id !== optionToRemove.id);
    const items = remainingItems.map((option) => Number(option.id));

    if (userFilters?.searchType === GLOBAL_SEARCH_TYPES.users) {
      setUserFilters((prevState) => ({
        ...prevState,
        researchArea: items,
      }));
    } else if (userFilters?.searchType === GLOBAL_SEARCH_TYPES.challenge) {
      setUserFilters((prevState) => ({
        ...prevState,
        targetIndustry: items,
      }));
    }
    searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
  };

  const handleRemoveCountry = (optionToRemove: Option) => {
    setSelectedCountries((prevSelectedOptions) =>
      prevSelectedOptions.filter((option) => option.value !== optionToRemove.value)
    );
    const remainingItems = selectedCountries.filter(
      (option) => option.value !== optionToRemove.value
    );
    const items = remainingItems.map((option) => Number(option.value));
    setUserFilters((prevState) => ({
      ...prevState,
      country: items,
    }));
  };

  const getUserType = (userType: number) => {
    if (userType === USERS.ResearchScholar) {
      return "research-scholar";
    } else if (userType === USERS.Organization) {
      return "organization";
    } else if (userType === USERS.Admin) {
      return "admin";
    } else if (userType === USERS.Funding) {
      return "funding-organization";
    } else if (userType === USERS.ResearchInstitute) {
      return "research-institute";
    } else {
      return "";
    }
  };

  const handleCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserFilters((prevState) => ({
      ...prevState,
      isGlobalSearch: event?.target?.checked,
    }));
  };

  const openFilterModal = () => {
    setIsOpenModal(true);
    document.getElementsByTagName("html")?.[0]?.classList.add("modal-open");
  };

  const closeFilterModal = () => {
    document.getElementsByTagName("html")?.[0]?.classList.remove("modal-open");
    setIsOpenModal(false);
  };

  useEffect(() => {
    setRequestParams((prevState) => ({
      ...prevState,
      searchValue: searchKeyword ?? "",
      isGlobalSearch: searchFilterInfo?.isGlobalSearch ?? false,
      countryId: searchFilterInfo?.countryId ?? [],
      researchAreaId: searchFilterInfo?.researchAreaId ?? [],
      userType: searchFilterInfo?.userType ?? 0,
      targetIndustryId: searchFilterInfo?.targetIndustryId ?? [],
      searchType: searchFilterInfo?.searchType ?? GLOBAL_SEARCH_TYPES.users,
    }));
    setUserFilters((prevState) => ({
      ...prevState,
      isGlobalSearch: searchFilterInfo?.isGlobalSearch ?? false,
      userType: searchFilterInfo?.userType ?? 0,
      country: searchFilterInfo?.countryId ?? [],
      researchArea: searchFilterInfo?.researchAreaId ?? [],
      targetIndustry: searchFilterInfo?.targetIndustryId ?? [],
      searchType: searchFilterInfo?.searchType ?? GLOBAL_SEARCH_TYPES.users,
    }));
    setSelectedCountries(searchFilterInfo?.selectedCountries ?? []);
    setSelectedOptions(searchFilterInfo?.selectedAreas ?? []);
  }, [searchKeyword, searchFilterInfo]);

  const SearchFilters = () => {
    return (
      <div className=" lg:bg-white lg:rounded-md lg:shadow-sticky">
        <div className="px-5 pb-4 max-[1025px]:fixed max-[1025px]:bottom-0 max-[1025px]:!p-4 max-[1025px]:w-full max-[1025px]:bg-white max-[1025px]:flex max-[1025px]:items-center max-[1025px]:gap-2 z-[9]">
          <SecondaryButton
            title={"global.close"}
            handleClick={() => closeFilterModal()}
            className="hidden max-[1025px]:block w-auto min-w-[50%] grow !transition-none"
          />
          <PrimaryButton
            title={"search.apply_filters"}
            className="mt-4 w-full max-[1025px]:mt-0 max-[1025px]:w-auto max-[1025px]:min-w-[50%] max-[1025px]:grow !animate-none"
            handleClick={() => handleApplyFilterClick()}
          />
        </div>
        <CheckBox
          option={{
            value: 1,
            label: t("search.apply_filters_globally"),
          }}
          handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleCheckBoxClick(event);
          }}
          checked={userFilters?.isGlobalSearch}
          className="customCheckboxV2   mt-0 px-5 pb-4 pt-0  higher-education-checkbox   max-[1024px]:pt-4 "
        />
        <div className="p-5 pb-1 pt-0  max-[1025px]:overflow-auto max-[1025px]:mb-20 max-[1025px]:pt-0 bg-white overflow-x-hidden overflow-y-auto lg:max-h-[calc(100vh-238px)] max-h-[calc(100vh-200px)]">
          <Title
            title={t("search.filter_by")}
            className="text-primary-light text-[11px] font-semibold leading-normal uppercase mb-4"
          />
          <div className=" space-y-4">
            {userTypeFilters?.map((item: RecordItem, index: number) => (
              <div className={index === 0 ? "mt-0 relative" : ""} key={index}>
                <RadioButton
                  label={t(item?.label)}
                  className="relative  text-gray-200 top-auto left-0  !rounded-none  filter-radio-buttons gap-[2px] search-filter-radio-button"
                  onChange={() => handleUserTypeClick(item?.value)}
                  checked={userFilters?.userType === item?.value}
                  value={item?.value}
                />
              </div>
            ))}
          </div>
          {userFilters?.searchType === GLOBAL_SEARCH_TYPES.users ? (
            <>
              <div className="mt-5 researchArea">
                <Title
                  title={t("global.country")}
                  className="text-primary-light text-sm leading-4 font-normal mb-2"
                />

                <Select
                  options={countryOptions}
                  placeholder={t("search.search_country")}
                  classNamePrefix={"customSelect truncate customSelect"}
                  onChange={handleSelectChange}
                  value={selectedCountries}
                  isMulti={true}
                  noOptionsMessage={NoOptionsMessage}
                  controlShouldRenderValue={false}
                  isDisabled={selectedCountries.length >= 5}
                  isClearable={false}
                />
              </div>
              <div className="flex items-center flex-wrap mt-2">
                {selectedCountries.map((option) => (
                  <Chip
                    key={option.value}
                    className="chipSecondary"
                    text={option.label}
                    handleClose={() => handleRemoveCountry(option)}
                  />
                ))}
              </div>
              <div className=" mt-2 researchArea">
                <MultiSelect
                  labelText={t("global.research_area")}
                  hintText={t("research_scholar.challenge_search_areas")}
                  setSelectedOptions={handleSelectedOptions}
                  selectedOptions={selectedOptions ?? []}
                  loadOptions={loadResearchAreaOptions}
                  isDisabled={selectedOptions.length > 4}
                  resetKey={Math.random().toString()}
                  onMenuOpen={() => {
                    searchParamsRef.current = { ...INITIAL_SEARCH_PARAMS };
                  }}
                />
                <div className="flex items-center flex-wrap mt-2">
                  {selectedOptions.map((option) => (
                    <Chip
                      key={option.id}
                      className="chipSecondary"
                      text={option.name.en}
                      handleClose={() => handleRemoveSelectedOption(option)}
                    />
                  ))}
                </div>
              </div>
            </>
          ) : null}

          {/* challenges section  */}
          {enableChallengeFilter ? (
            <div>
              <div className="pt-4 border-t border-gray-light my-4">
                <RadioButton
                  className="search-filter-radio-button"
                  label={t("admin.challenges")}
                  value={0}
                  checked={userFilters?.searchType === GLOBAL_SEARCH_TYPES.challenge}
                  onChange={() => handleChallengeClick()}
                />
              </div>
              <div className="researchArea">
                {userFilters?.searchType === GLOBAL_SEARCH_TYPES.challenge ? (
                  <>
                    <MultiSelect
                      labelText={t("global.target_industry_search_header")}
                      hintText={t("research_scholar.challenge_search_areas")}
                      setSelectedOptions={handleSelectedOptions}
                      selectedOptions={selectedOptions}
                      loadOptions={loadResearchAreaOptions ?? []}
                      isDisabled={selectedOptions.length >= 5}
                    />
                    <div className="flex items-center flex-wrap mt-2">
                      {selectedOptions.map((option) => (
                        <Chip
                          key={option.id}
                          className="chipSecondary"
                          text={option.name.en}
                          handleClose={() => handleRemoveSelectedOption(option)}
                        />
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const SearchListItem = ({ data, handleSearchItemClick }: SearchListItemProps) => {
    return (
      <Link
        to={{
          pathname: `${getUserType(data?.userTypeId)}/${data?.userId}`,
          search: `?key=${searchKeyword}`,
        }}
        onClick={handleSearchItemClick}
      >
        <div className=" flex items-start gap-[13px]  border-b border-gray-light pt-4 pb-4 cursor-pointer">
          <div className="w-[48px] h-[48px] rounded-full overflow-hidden grow-0 shrink-0 basis-[48px]">
            <img
              src={data?.profileImage ? data?.profileImage : Profile}
              alt="coverImage"
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <Title title={data?.name} className="text-black text-sm  font-semibold Word-break" />
            <SubTitle
              title={data?.userType}
              className="text-black text-sm  font-normal !justify-start Word-break"
            />
            <Descriptor
              title={data?.contactEmail}
              startIcon="message"
              className="search-mail flex items-center gap-2"
            />
          </div>
        </div>
      </Link>
    );
  };

  const ChallengeListItem = ({ data, handleSearchItemClick }: SearchListItemProps) => {
    const { t } = useTranslation();
    return (
      <Link
        to={{
          pathname: `/${getUserType(userInfo?.userType ?? 0)}/search-results/challenges/${
            data?.challengeId
          }`,
          search: `?key=${searchKeyword}`,
        }}
        onClick={handleSearchItemClick}
      >
        <div className="flex items-start gap-5  border-b border-gray-light pt-5 pb-5 max-[1025px]:!py-4 cursor-pointer">
          <div className="w-[120px] h-[72px] overflow-hidden rounded-md grow-0 shrink-0 basis-[120px]">
            <img
              src={data?.coverImage ? data?.coverImage : ChallengeImage}
              alt="profile"
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <Title
              title={t("home.challenge")}
              className="text-black text-sm  font-normal  Word-break"
            />
            <Title
              title={data?.name}
              className="text-black text-base leading-normal  font-semibold  Word-break max-[1025px]:text-sm"
            />
            <div className="flex items-center flex-wrap gap-2 mt-[6px] mb-2">
              <Label
                className="flex items-center justify-center py-[6px] px-3 !w-auto rounded-xl bg-green-500 text-gray-107 text-xs leading-normal font-normal"
                text={data?.status}
              />
              <Label
                className="challenge-list-item-target-industry !w-auto py-[6px] px-3 rounded-xl bg-primary-500 text-primary-medium text-xs leading-normal font-normal"
                text={data?.targetIndustry}
              />
            </div>
            <div className="flex items-center gap-4 flex-wrap max-[1025px]:gap-2">
              <Descriptor
                title={`${t("global.start_date")} : ${displayDateFormat(data?.startDate ?? "")}`}
                startIcon="previewDate"
                className="flex items-center search-mail calendar-image gap-2 max-[1025px]:text-sm"
              />
              <Descriptor
                title={`${t("global.end_date")} : ${displayDateFormat(data?.endDate ?? "")}`}
                startIcon="previewDate"
                className="flex items-center search-mail calendar-image gap-2 max-[1025px]:text-sm"
              />
            </div>
          </div>
        </div>
      </Link>
    );
  };

  const FilterModal = ({ isOpenFilterModal }: FilterModalProps) => {
    return (
      <Modal open={isOpenFilterModal} className="search-filter-mobile overflow-hidden">
        <div className="">
          <SearchFilters />
        </div>
      </Modal>
    );
  };

  function handleSearchItemClick(index: number) {
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: location.pathname,
      index: pageParam,
    });
  }

  return (
    <div className={`flex  min-h-screen w-full searchDetails  `}>
      <Header
        title={"global.search"}
        handleCollapseClick={onToggleClick}
        className={
          scroll
            ? " bg-primary-medium lg:bg-white animate__animated animate__fadeInDown lg:shadow-sticky lg:!h-[90px] fixed stickyHeader smaller-devices-section max-[1025px]:hidden"
            : "fixed bg-primary-medium lg:bg-gray-light smaller-devices-section max-[1025px]:hidden"
        }
      />
      <div className=" adminDetails w-full mt-[170px] mb-20 lg:mb-0 lg:mt-0 lg:pt-[118px] pb-[45px] ltr:pr-[30px] rtl:pl-[30px] items-baseline px-4 lg:px-10 lg:min-h-screen flex flex-col  overflow-auto max-[1025px]:bg-white  max-[1025px]:mt-0 max-[1025px]:!p-0 max-[1025px]:!m-0 max-[1025px]:overflow-hidden">
        <div className="w-full flex flex-col h-full ltr:ml-0 ltr:mr-0 rtl:mr-0 rtl:ml-0 ltr:lg:pr-[292px] rtl:lg:pl-[292px] space-y-3 ">
          <div className="block lg:hidden searchDetailsSearch">
            <div className="flex items-start p-4">
              <GlobalSearch className="global-search-mobile-view" />
              <IconTypeButton
                iconName="close"
                handleClick={() => navigate(-1)}
                className="w-12 h-12 ltr:ml-4 rtl:mr-4"
              />
            </div>
          </div>
          <div className="flex-col bg-white w-full h-full rounded-none lg:rounded-md flex mb-auto searchDetailsList lg:!mt-0">
            {!searchListLoading ? (
              <div className="p-5 border-b border-gray-light w-full flex items-center gap-1 max-[1025px]:!p-0 max-[1025px]:mx-4 max-[1025px]:border-none">
                <span>{`${t("search.showing")} ${searchData?.totalCount} ${
                  !requestParams?.isGlobalSearch ? t("search.results_for") : t("search.results")
                }`}</span>
                {!requestParams?.isGlobalSearch ? (
                  <p className="text-black text-sm leading-4 font-medium max-[1025px]:text-xs">
                    ‘{searchKeyword}’
                  </p>
                ) : null}
              </div>
            ) : null}
            <div className="p-5 pt-[4px] max-[1025px]:pt-0 max-[1025px]:px-4">
              {searchData?.searchDetails?.length > 0 ? (
                <InfiniteScroll
                  dataLength={searchData?.searchDetails?.length}
                  next={() => {
                    fetchNextPage();
                  }}
                  hasMore={!!hasNextPage}
                  loader={hasNextPage || searchListLoading ? <InlineLoader /> : null}
                  scrollThreshold="200px"
                >
                  {searchData?.searchDetails?.map((item: SearchResultData, index: number) => (
                    <div key={index}>
                      {requestParams?.searchType === GLOBAL_SEARCH_TYPES.users ? (
                        <SearchListItem
                          data={item}
                          handleSearchItemClick={() => handleSearchItemClick(index)}
                        />
                      ) : requestParams?.searchType === GLOBAL_SEARCH_TYPES.challenge ? (
                        <ChallengeListItem
                          data={item}
                          handleSearchItemClick={() => handleSearchItemClick(index)}
                        />
                      ) : null}
                    </div>
                  ))}
                </InfiniteScroll>
              ) : null}
              {searchListLoading ? <LoaderModal /> : null}
              {!searchListLoading && searchData?.searchDetails?.length === 0 ? (
                <NoData message="search.no_results_found" />
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={`w-full min-w-full lg:w-[280px] lg:min-w-[280px]  mb-auto fixed max-[1025px]:hidden max-h-[calc(100vh-120px)] overflow-hidden rounded-md
         ${
           scroll
             ? " top-[100px]  ltr:lg:right-[30px] rtl:lg:left-[30px]  z-[5]  shadow-sticky  lg:animate__animated lg:animate__fadeInDown lg:transition-all"
             : " top-[100px] lg:top-auto ltr:lg:right-[30px] rtl:left-[30px]  z-[5]"
         }`}
        >
          <SearchFilters />
        </div>
        <div className="hidden max-[1025px]:block max-[1025px]:h-20 max-[1025px]:w-full shadow-sticky">
          <div className="fixed bottom-0 bg-white p-4 search-filter-footer w-full flex items-center justify-center z-50 ">
            <SecondaryButton
              title={"search.search_filters"}
              handleClick={() => openFilterModal()}
              className="min-w-[326px] max-[768px]:min-w-full"
            />
          </div>
          <FilterModal isOpenFilterModal={isOpenModal} />
        </div>
      </div>
    </div>
  );
}
