import { ChatClient } from "@azure/communication-chat";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { useQueryClient } from "react-query";

class ChatClientSingleton {
  static chatClient: ChatClient;
  static token: string;
  static async getInstance(userAccessToken: string) {
    if (!ChatClientSingleton.chatClient || ChatClientSingleton.token !== userAccessToken) {
      if (ChatClientSingleton.chatClient) {
        await ChatClientSingleton.chatClient.stopRealtimeNotifications();
      }
      const endpoint = process.env.REACT_APP_AZURE_CHAT_CONFIGURATION_ENDPOINT!;
      const chatClient = new ChatClient(
        endpoint,
        new AzureCommunicationTokenCredential(userAccessToken)
      );
      ChatClientSingleton.chatClient = chatClient;
    }
    return ChatClientSingleton.chatClient;
  }
}

export class ChatService {
  static async sendMessage(
    userAccessToken: string,
    threadId: string,
    message: string,
    attachments: Array<FileDetailsItem> | null
  ) {
    const chatClient = await ChatClientSingleton.getInstance(userAccessToken);
    const chatThreadClient = chatClient?.getChatThreadClient(threadId);
    const sendMessageRequest = {
      content: message,
    };
    const sendMessageOptions = {
      metadata: {
        hasAttachment: attachments && attachments?.length > 0 ? "true" : "false",
        attachmentJson: "",
      },
    };
    sendMessageOptions.metadata["attachmentJson"] = JSON.stringify(attachments);
    const sendChatMessageResult = await chatThreadClient?.sendMessage(
      sendMessageRequest,
      sendMessageOptions
    );
    ChatService.listenToThread(userAccessToken);
    return sendChatMessageResult;
  }
  static async listenToThread(userAccessToken: string) {
    const chatClient = await ChatClientSingleton.getInstance(userAccessToken);

    await chatClient.startRealtimeNotifications();

    chatClient.on("chatMessageReceived", (event) => {
      const qc = useQueryClient();
      qc.invalidateQueries(["messages"]);
    });
  }
}
