import React, { useMemo, useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetResearchScholarDetails } from "features/Admin/api/useGetResearchScholarDetails";

import { LoaderModal, Header, ScholarProfileDetails } from "components";
import {
  COLLABORATION_USER_STATUS,
  RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS,
} from "utils/constants";
import Navigator from "features/Admin/Users/Navigator";
import {
  showErrorToastMessage,
  showSuccessToastMessage,
  ToasterIconType,
} from "utils/toasterMessage";
import { useStickyScroll } from "hooks/useStickyScroll";
import { setSelectedSideMenu, useSidebarDataStore } from "store/useSidebarStore";

import { useManageResearchScholarRequest } from "features/Collaboration/api/useManageResearchScholarRequest";

export default function CollaborationScholarDetails() {
  const { t } = useTranslation();
  const { selectedUserStatusType } = useSidebarDataStore();
  const { collaborationId, researchScholarId } = useParams();
  const collaborationDataId = Number(collaborationId);
  const { scroll } = useStickyScroll();
  const navigate = useNavigate();
  const personalDetailsRef = useRef(null);
  const educationRef = useRef(null);
  const workRef = useRef(null);
  const socialRef = useRef(null);
  const researchProjectRef = useRef(null);

  const { mutateAsync: manageRequest, isLoading: isRequestLoading } =
    useManageResearchScholarRequest();

  const { data: userData, isLoading: userDataLoading } = useGetResearchScholarDetails(
    researchScholarId ?? ""
  );
  const [isViewMore, setIsViewMore] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState<number>(0);
  const handleDecline = async () => {
    try {
      const result = await manageRequest({
        collaborationId: collaborationDataId,
        scholarId: researchScholarId ?? "",
        status: COLLABORATION_USER_STATUS.Declined,
      });
      if (result) {
        navigate(-1);
      }
      showErrorToastMessage({
        message: result.message,
        iconType: ToasterIconType.DECLINED,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };
  const handleApprove = async () => {
    try {
      const result = await manageRequest({
        collaborationId: collaborationDataId,
        scholarId: researchScholarId ?? "",
        status: COLLABORATION_USER_STATUS.Nominated,
      });
      if (result) {
        navigate(-1);
      }
      showSuccessToastMessage({
        message: result.message,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    }
  };

  const linkList: Array<LinkItem> = useMemo(() => {
    const items: Array<LinkItem> = [
      {
        title: "research_institute.requests",
      },
      {
        title: "research_institute.view_collaboration",
      },
      {
        title:
          selectedUserStatusType === COLLABORATION_USER_STATUS.InstitutePending
            ? "global.pending_approval"
            : "research_institute.nominated_scholars",
      },
    ];

    return items;
  }, [userData]);

  const navigationItems: Array<RecordItem> = useMemo(() => {
    const tempItems = [
      {
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Personal,
        label: "global.personal_details",
      },
      {
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Education,
        label: "global.highest_education",
      },
    ];
    if (userData?.userwork.notWorking || userData?.userwork.details.length > 0) {
      tempItems.push({
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Work,
        label: "global.work_experience",
      });
    }
    if (
      userData?.usersocialprofile?.googleScholarURL ||
      userData?.usersocialprofile?.linkedInURL ||
      userData?.usersocialprofile?.researchGateURL
    ) {
      tempItems.push({
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Social,
        label: "global.social_profiles",
      });
    }
    if (userData?.researchprojects?.length) {
      tempItems.push({
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Research,
        label: "global.research_projects",
      });
    }
    return tempItems;
  }, [userData]);

  const handleScroll = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 100,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const handleNavigationClick = (value: number, index: number) => {
    setActiveMenu(index);
    if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Personal) {
      handleScroll(personalDetailsRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Education) {
      handleScroll(educationRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Work) {
      handleScroll(workRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Social) {
      handleScroll(socialRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Research) {
      handleScroll(researchProjectRef);
    }
  };

  const handleViewClick = () => {
    setIsViewMore(!isViewMore);
    handleScroll(personalDetailsRef);
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "research_institute.requests",
    });
  }, []);

  return (
    <>
      <Header
        title={t("global.profile")}
        linkList={linkList}
        className={
          scroll
            ? "  bg-primary-medium lg:bg-white animate__animated animate__fadeInDown lg:shadow-sticky lg:!py-[26px] lg:!h-[90px] fixed stickyHeader"
            : "fixed bg-primary-medium lg:bg-gray-light"
        }
      />
      <div
        className="adminDetails w-full mt-[170px] mb-20 lg:mb-0 lg:mt-0 lg:pt-[118px] pb-[45px] items-baseline px-4 lg:px-10 lg:min-h-screen flex flex-col  overflow-auto lg:ltr:pr-[30px] lg:rtl:pl-[30px]"
        ref={personalDetailsRef}
      >
        <ScholarProfileDetails
          userData={userData}
          researchProjectRef={researchProjectRef}
          isViewMore={isViewMore}
          handleViewClick={handleViewClick}
          educationRef={educationRef}
          workRef={workRef}
          socialRef={socialRef}
          showSettingsMenu={false}
          isCollaborationView={true}
        />
        <Navigator
          isButtonVisible={selectedUserStatusType === COLLABORATION_USER_STATUS.InstitutePending}
          handleDecline={handleDecline}
          handleApprove={handleApprove}
          handleNavigationClick={(value: number, index: number) =>
            handleNavigationClick(value, index)
          }
          navigationItems={navigationItems}
          scroll={scroll}
          activeMenu={activeMenu}
        />
        {isRequestLoading || userDataLoading ? <LoaderModal /> : null}
      </div>
    </>
  );
}
