import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function adminChangeChallengeStatus(status: number, challengeId: number, specificReason?: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(
    urls.adminChangeChallengeStatus(status, challengeId, specificReason),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus | ApiError;
}

export function useAdminChangeChallengeStatus() {
  const queryClient = useQueryClient();
  return useMutation(async ({ status, challengeId, specificReason }: { status: number; challengeId: number, specificReason?: string }) => {
    const result = await adminChangeChallengeStatus(status, challengeId, specificReason);
    queryClient.invalidateQueries(["challenge-details", challengeId]);
    queryClient.invalidateQueries(["challenge-basic-details", challengeId]);
    return result;
  });
}
