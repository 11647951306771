import SVGIcon from "assets/Icons";
import React from "react";

type IconButton = {
  name: string;
  title: string;
  className?: string;
  handleClick: () => void;
};

function IconButton({ name, title, className, handleClick }: IconButton) {
  return (
    <button className={`t ${className}`} onClick={handleClick}>
      <span>{title}</span>
      <SVGIcon name={name} />
    </button>
  );
}

export default IconButton;
