import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function uploadChallengeDocument(
  file: File,
  isCoverImage: boolean,
  challengeId: number
) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("isProfileImage", isCoverImage ? "true" : "false");
  const response = await apiClient.post(urls.challengeFileUpload(challengeId), formData, {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
  });
  return response.data.data as FileDetailsItem;
}

export function useUploadChallengeFile() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;

  return useMutation(
    async ({
      file,
      isCoverImage,
      challengeId,
    }: {
      file: File;
      isCoverImage: boolean;
      challengeId: number;
    }) => {
      if (userId) {
        const result = await uploadChallengeDocument(file, isCoverImage, challengeId);
        return result;
      }
    }
  );
}
