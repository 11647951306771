import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveOffices(formData: Array<OrganizationOffice>, userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  return await apiClient.put(urls.researchInstituteOffices(userId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function useSaveOffices() {
  const userInfo = useUserDataStore.getState().userInfo;
  const qc = useQueryClient();
  return useMutation(
    async ({
      formData,
      researchInstituteId,
    }: {
      formData: Array<OrganizationOffice>;
      researchInstituteId: string;
    }) => {
      const userId = researchInstituteId ? researchInstituteId : userInfo?.userId;
      if (userId) {
        const result = await saveOffices(formData, userId);
        qc.invalidateQueries({ queryKey: ["research-institute-details", userId] });
        qc.invalidateQueries({ queryKey: ["research-institute-offices", userId] });
        return result;
      }
    }
  );
}
