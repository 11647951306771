import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function addUsers(formData: UserData) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.post(urls.organizationAddUsers(), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useAddUsers() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;
  const qc = useQueryClient();
  return useMutation(async (formData: UserData) => {
    const result = await addUsers(formData);
    qc.invalidateQueries(["organization-user-list", userId]);
    return result;
  });
}
