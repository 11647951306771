import React from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import MainLogo from "assets/images/home-images/footerLogoWhite.svg";
import MobileLogo from "assets/images/HCT_LOGO_ENGLISH_WHITE.png";
import FooterItem from "features/Home/Footer/FooterItem";
import { HOME_MENU_IDS } from "utils/constants";
import { useGetLatestCaseStudies } from "features/Home/api/useGetLatestCaseStudy";
import { isGlobalWebsite } from "utils/helpers";
import globalConsortiumLogo from "assets/images/home-images/GCIEI-white.svg";

type FooterProps = {
  handleClick: (id: number) => void;
};

export default function Footer({ handleClick }: FooterProps) {
  const { t } = useTranslation();
  const isGlobal = isGlobalWebsite();
  const { data: caseStudies } = useGetLatestCaseStudies(isGlobal);
  return (
    <div className="bg-skin-primaryDark w-full footerContainer">
      <div className="border-b border-skin-primaryExtraLight pt-[30px] md:pt-5 py-5 md:py-5 xl:py-10">
        <div className="flex items-center justify-center home-container xl:justify-between">
          <div className="w-[312px] hidden xl:block">
            {isGlobal ? (
              <img
                src={globalConsortiumLogo}
                alt="Higher Colleges of technology Logo"
                className="h-[44px] "
              />
            ) : (
              <img
                src={MainLogo}
                alt="Higher Colleges of technology Logo"
                className="w-full h-full"
              />
            )}
          </div>
          <div className="flex items-center w-full md:w-auto  ">
            <ul className="grid gap-[20px] grid-cols-2 md:flex w-full items-center justify-center gap-x-[30px] max-[960px]:gap-x-[22px] home-navigation-footer-items text-white">
              <FooterItem
                text={"home.about_us"}
                handleClick={() => handleClick(HOME_MENU_IDS.AboutUs)}
              />
              <FooterItem
                text={"home.join_us"}
                handleClick={() => handleClick(HOME_MENU_IDS.JoinUs)}
              />
              <FooterItem
                text={"home.data_insights"}
                handleClick={() => handleClick(HOME_MENU_IDS.DataInsights)}
              />
              <FooterItem
                text={"admin.challenges"}
                handleClick={() => handleClick(HOME_MENU_IDS.Challenges)}
              />
              <FooterItem
                text={"home.how_works"}
                handleClick={() => handleClick(HOME_MENU_IDS.HowItWorks)}
              />
              {caseStudies?.length > 0 ? (
                <FooterItem
                  text={"home.case_studies"}
                  handleClick={() => handleClick(HOME_MENU_IDS.CaseStudies)}
                />
              ) : null}
              {/* <FooterItem
                text={"home.news_events"}
                handleClick={() => handleClick(HOME_MENU_IDS.NewsAndEvents)}
              />  */}
              <FooterItem
                text={"home.contact_us"}
                handleClick={() => handleClick(HOME_MENU_IDS.ContactUs)}
              />
            </ul>
          </div>
        </div>
      </div>
      <div className="pt-[30px] pb-[30px] xl:pt-6 xl:pb-5">
        <div className="home-container flex justify-between flex-col md:flex-row">
          <div>
            <div className="w-[208px] h-[36px] block xl:hidden">
              <img
                src={MobileLogo}
                alt="Higher Colleges of technology Logo"
                className=" w-[110px]"
              />
            </div>
            <p className=" text-white font-normal text-[13px] leading-[18px] md:text-sm md:leading-5 md:tracking-tight-[-0.1px] xl:tracking-normal xl:leading-[22px] pt-5 xl:pt-0 ">
              {isGlobal ? t("home.all_rights_reserved_global_tag") : t("home.all_rights_reserved")}
            </p>
          </div>
          <div className=" space-x-5 rtl:space-x-reverse mb-auto -order-1 md:order-none pb-[30px] md:pb-0 hidden">
            <button className="btn btn-link min-h-max h-auto p-0 m-0">
              <SVGIcon name="hFB" />
            </button>
            <button className="btn btn-link min-h-max h-auto p-0 m-0">
              <SVGIcon name="hTwitter" />
            </button>
            <button className="btn btn-link min-h-max h-auto p-0 m-0">
              <SVGIcon name="hLinkedin" />
            </button>
            <button className="btn btn-link min-h-max h-auto p-0 m-0">
              <SVGIcon name="hInsta" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
