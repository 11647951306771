import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { DropdownItem } from "components";

type DropDownProps = {
  title: string;
  list: Array<DropdownListItem>;
  handleSelection: (type: string) => void;
  className?: string;
  isOpen?: boolean;
};

export function DropDown({ className, title, list, handleSelection, isOpen }: DropDownProps) {
  const { t } = useTranslation();
  return (
    <div className={`${className}`}>
      <label
        tabIndex={0}
        className="btn text-sm font-medium !border-none  text-white normal-case flex-nowrap px-[18px] py-0 flex items-center rounded-md bg-secondary-medium h-[38px] min-h-[38px] m-0 relative z-10 hover:bg-primary-medium"
      >
        <span className=" whitespace-nowrap">{t(title)}</span>
        <div className={` w-[10px] ltr:ml-4 rtl:mr-4 mt-[2px] ${isOpen ? "rotate-180" : ""}`}>
          <SVGIcon name="downArrow" />
        </div>
      </label>
      <ul
        tabIndex={0}
        className={`${
          isOpen ? "" : "hidden"
        } dropdown-content !z-0 menu w-full divide-y divide-solid divide-primary-medium/10 shadow-dropdown text-gray-200 text-sm font-normal normal-case rounded-b-md -mt-2 py-[10px] bg-white text-left px-4 pb-0`}
      >
        {list?.map((item) => (
          <li key={item.label} className="mb-3 pt-3 gap-0 ">
            <DropdownItem
              handleSelection={handleSelection}
              type={item.type}
              icon={item.icon}
              label={item.label}
              disabled={item?.disabled}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
