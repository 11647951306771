import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { PrimaryButton, Label } from "components";
import { getChallengeStatusBackground, getChallengeStatusTitle } from "utils/helpers";
import { useMasterDataStore } from "store/useMasterDataStore";
import { CHALLENGE_STATUS } from "utils/constants";
import { useUserDataStore } from "store/useUserDataStore";

type ForumHeaderProps = {
  collaborationData: CollaborationBasicDetails;
  showCreatePost: boolean;
  handleCreatePost: () => void;
};

export default function ForumHeader({
  collaborationData,
  showCreatePost,
  handleCreatePost,
}: ForumHeaderProps) {
  const { t } = useTranslation();
  const { permissions } = useMasterDataStore();
  const { userInfo } = useUserDataStore();

  const displayGlobalTag =
    collaborationData.isGlobal &&
    !userInfo?.isGlobal &&
    window.location.pathname.includes("forums");

  return (
    <>
      <div className="max-w-[100%] w-full bg-white rounded-md flex items-center p-4 gap-4 mb-4 shadow-100 msgStatus">
        <div className=" flex badgeBLock space-x-2 rtl:space-x-reverse h-[30px]">
          {displayGlobalTag ? (
            <Label
              className=" !w-auto text-xs text-white bg-primary-medium rounded-xl px-3 py-1.5 "
              text={t("global.global")}
            />
          ) : null}
          <Label
            className={`!w-auto text-gray-107 text-xs  rounded-xl px-3 py-1.5 ${getChallengeStatusBackground(
              collaborationData?.collaborationStatusId
            )}`}
            text={t(getChallengeStatusTitle(collaborationData?.collaborationStatusId))}
          />
          <Label
            className=" !w-auto text-gray-107 text-xs bg-badge-open rounded-xl px-3 py-1.5 "
            text={t(collaborationData?.isPrivate ? "organization.private" : "organization.public")}
          />
        </div>
        <div className="flex justify-between items-center w-full">
          {collaborationData?.totalUsers > 0 ? (
            <div className="flex items-center gap-2">
              <SVGIcon name="user" />
              <span className="text-sm font-normal relative top-[2px]">
                {collaborationData?.totalUsers && collaborationData?.totalUsers > 1
                  ? `${collaborationData?.totalUsers - 1}+ ${t("forum.members")}`
                  : `${collaborationData?.totalUsers} ${t("forum.member")}`}
              </span>
            </div>
          ) : null}
          {showCreatePost &&
          permissions?.Createpost &&
          collaborationData?.isDiscussionForumEligible &&
          collaborationData.collaborationStatusId !== CHALLENGE_STATUS.Disable &&
          collaborationData.collaborationStatusId !== CHALLENGE_STATUS.Close ? (
            <div className="buttonHolder ltr:ml-auto rtl:mr-auto flex ">
              <PrimaryButton
                title={"forum.post_something"}
                handleClick={handleCreatePost}
                className="!h-[38px] min-h-max w-[163px]"
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
