import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Descriptor, InlineLoader, SubTitle, Title } from "components";
import Profile from "assets/images/avatar.svg";
import { USERS } from "utils/constants";

type SearchItemProps = {
  data: SearchResultData;
  user: string;
  keyword: string;
};

type SearchModalProps = {
  data: Array<SearchResultData>;
  keyword: string;
  user: string;
  isLoading: boolean;
  handleShowMoreClick?: () => void;
};
export default function SearchResultModal({
  data,
  keyword,
  user,
  isLoading,
  handleShowMoreClick,
}: SearchModalProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className=" absolute bg-white w-[472px] top-[40px] ltr:right-0 rtl:left-0 shadow-alertDropdown rounded-md  ltr:pr-9 rtl:pl-9 pt-6 pb-4 max-[1025px]:relative max-[1025px]:w-[calc(100%+60px)] max-[1025px]:top-4 max-[1025px]:bg-transparent max-[1025px]:shadow-none max-[1025px]:!p-0 searchListing">
        <div>
          <Title
            title={t("search.showing_results")}
            className="ltr:ml-6 rtl:mr-6 mb-4 text-black text-base leading-normal font-semibold max-[1025px]:hidden"
          />
          <Title
            title={t("search.quick_results")}
            className="text-gray-107 text-xs leading-normal font-normal max-[1025px]:block hidden"
          />
          {data?.length ? (
            <>
              {/* loader */}
              <div className=" mb-10 hidden">
                <InlineLoader />
              </div>
              <div className=" max-h-[320px] overflow-auto max-[1025px]:max-h-full">
                {data?.map((item: SearchResultData, index: number) => (
                  <div key={index}>
                    <SearchItem data={item} user={user} keyword={keyword} />
                  </div>
                ))}
              </div>

              <div className=" pt-6 pb-2 flex items-center justify-center ltr:mr-[-36px] rtl:ml-[-36px] max-[1025px]:w-full max-[1025px]:pb-6">
                <Link
                  to={{ pathname: `/${user}/search-results`, search: `?key=${keyword}` }}
                  onClick={handleShowMoreClick}
                >
                  <span className=" text-primary-medium font-medium  flex items-center space-x-1 text-sm hover:text-secondary-medium">
                    <div>{t("search.show_more_results")}</div>
                  </span>
                </Link>
              </div>
            </>
          ) : !isLoading ? (
            <>
              <div className="ltr:ml-9 rtl:mr-9 pt-3 pb-3 flex flex-col items-center justify-center gap-2 min-h-[248px] max-[1025px]:w-full max-[1025px]:!m-0">
                <Title
                  title={t("search.no_results_found")}
                  className="text-black text-sm leading-5 font-semibold"
                />
                <SubTitle
                  title={t(`search.sorry_we_couldnt_find_results_with`)}
                  childElement={<span> “{keyword}”</span>}
                  className="text-gray-102 text-sm leading-5 font-normal max-w-[293px] text-center"
                />
              </div>
              <div className=" pt-6 pb-2 flex items-center justify-center ltr:mr-[-36px] rtl:ml-[-36px] max-[1025px]:w-full max-[1025px]:pb-6">
                <Link
                  to={{ pathname: `/${user}/search-results`, search: `?key=${keyword}` }}
                  onClick={handleShowMoreClick}
                >
                  <span className=" text-primary-medium font-medium  flex items-center space-x-1 text-sm hover:text-secondary-medium">
                    <div>{t("search.advanced_search")}</div>
                  </span>
                </Link>
              </div>
            </>
          ) : (
            <InlineLoader />
          )}
        </div>
      </div>
    </>
  );
}

const SearchItem = ({ data, user, keyword }: SearchItemProps) => {
  const getUserType = (userType: number) => {
    if (userType === USERS.ResearchScholar) {
      return "research-scholar";
    } else if (userType === USERS.Organization) {
      return "organization";
    } else if (userType === USERS.Admin) {
      return "admin";
    } else if (userType === USERS.Funding) {
      return "funding-organization";
    } else if (userType === USERS.ResearchInstitute) {
      return "research-institute";
    } else {
      return "";
    }
  };

  return (
    <Link
      to={{
        pathname: `/${user}/search-results/${getUserType(data?.userTypeId)}/${data?.userId}`,
        search: `?key=${keyword}`,
      }}
    >
      <div className=" flex items-center gap-[7px]  border-b border-gray-104 pt-3 pb-3 ltr:ml-6 rtl:mr-6 max-[1025px]:!m-0 max-[1025px]:py-4 max-[1025px]:items-start max-[1025px]:gap-[13px] max-[1025px]:border-gray-light cursor-pointer">
        <div className="w-[48px] h-[48px] rounded-full overflow-hidden grow-0 shrink-0 basis-[48px]">
          <img
            src={data?.profileImage ? data?.profileImage : Profile}
            alt="profile"
            className="w-full h-full object-cover"
          />
        </div>
        <div>
          <Title
            title={data?.name}
            className="text-black text-sm leading-[20px] font-semibold Word-break"
          />
          <SubTitle
            title={data?.userType}
            className="text-sm leading-[20px] text-gray-102 font-normal Word-break !justify-start max-[1025px]:text-black"
          />
          <Descriptor
            title="Ahmed.Abdullah.Al-Mansour@ku.ac.ae"
            startIcon="message"
            className="search-mail items-center gap-2 hidden max-[1025px]:flex"
          />
        </div>
      </div>
    </Link>
  );
};
