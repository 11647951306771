import { useQuery } from "@tanstack/react-query";
import { useChallengeStore } from "store/useChallengeStore";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getChallengeArtifacts(challengeId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.challengeAdditonalInfoAndArtifacts(challengeId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (res.status === 400) {
    throw new Error("error");
  }
  return res.data?.data as ChallengeAdditionalInfo;
}

export function useGetChallengeArtifacts() {
  const challenge = useChallengeStore.getState().challengeInfo;
  return useQuery(
    ["challenge-artifacts", challenge.id],
    async () => {
      if (challenge.id) {
        const result = await getChallengeArtifacts(challenge.id);
        return result;
      } else return;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
