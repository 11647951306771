import {
  UploadProfilePic,
  ShareModal,
  ConfirmationModal,
  DocPreview,
  BulkInvite,
  IndividualInvite,
} from "components";
import { confirmAlert } from "utils/confirmAlert";
import { ReactQueryClientWrapper } from "utils/reactQueryHelper";
import EmailInvite from "features/Admin/Users/Organizations/OrganizationsDetails/EmailInvite";
import OrganizationIndividualInvite from "features/Admin/Users/Organizations/OrganizationsDetails/IndividualInvite";
import AddUser from "features/Organization/Users/AddUser";
import StatusChangeConfirmationModal from "features/Admin/Users/StatusChangeConfirmationModal";
import InviteUser from "features/Organization/Challenge/ViewChallenge/InviteUser";
import ContactDetails from "features/Organization/Challenge/ViewChallenge/ContactDetails";
import UpdateChallengeStatus from "features/Organization/Challenge/ViewChallenge/UpdateChallengeStatus";
import UploadCaseStudy from "features/Organization/Challenge/ViewChallenge/UploadCaseStudy";
import PreviewChallenge from "features/Organization/Challenge/CreateChallenge/Preview";
import FundingPreview from "features/Organization/Challenge/ViewChallenge/FundingPreview";
import UploadChallengeFile from "features/Organization/Challenge/CreateChallenge/UploadChallengeFile";
import SearchModal from "features/Home/SearchModal";
import DiscoverMoreModal from "features/Home/DiscoverMoreModal";
import LearnMoreModal from "features/Home/LearnMoreModal";
import FundingOrganizationEmailInvite from "features/Admin/Users/FundingOrganizations/FundingOrganizationDetails/EmailInvite";
import FundingOrganizationIndividualInvite from "features/Admin/Users/FundingOrganizations/FundingOrganizationDetails/IndividualInvite";
import ShareFundingInterestModal from "features/Challenge/Preview/FundingInterestModal";
import ResearchInstituteIndividualInvite from "features/Admin/Users/ResearchInstitute/ResearchInstituteDetails/IndividualInvite";
import ResearchInstituteEmailInvite from "features/Admin/Users/ResearchInstitute/ResearchInstituteDetails/EmailInvite";
import ResearchScholarProfileViewModal from "features/Messages/Chats/ResearchScholarProfileViewModal";
import ChallengeDetailsModal from "features/Home/LatestChallenges/ChallengeDetailsModal";
import ResearchScholarDetailsModal from "features/Admin/Users/ResearchInstitute/ResearchScholarDetailsModal";
import PreviewEvent from "features/Events/Preview";
import UploadEventFile from "features/Events/CreateEvent/UploadEventFile";
import UploadCollaborationFile from "features/Collaboration/CreateCollaboration/UploadCollaborationFile";
import UpdateCollaborationStatus from "features/Collaboration/UpdateCollaborationStatus";
import PreviewCollaboration from "features/Collaboration/Preview";
import UploadCaseSummary from "features/Collaboration/CollaborationDetails/UploadCaseSummary";
import CollaborationContactDetails from "features/Collaboration/CollaborationDetails/ContactDetails";
import CollaborationInviteUser from "features/Collaboration/InviteUser";
import ChallengeStatusUpdateModal from "features/Challenge/ChallengeStatusUpdateModal";
import CollaborationStatusUpdateModal from "features/Collaboration/collaborationStatusUpdate";

export const customConfirmAlert = (customComponent: (onClose: () => void) => React.ReactNode) => {
  confirmAlert({
    overlayClassName: "bg-blend-lighten",
    willUnmount: () => {
      window.onpopstate = null;
      // const html = document.getElementsByTagName("html")[0];
      // html.classList.add("overflow-auto");
      // html.style.overflow = "auto";
    },
    closeOnEscape: true,
    closeOnClickOutside: false,
    customUI: ({ onClose }: { onClose: () => void }) => {
      window.onpopstate = () => {
        onClose();
      };
      return customComponent(onClose);
    },
  });
};

export const showConfirmationModal = ({
  message,
  title,
  modalIcon,
  buttonIcon,
  cancelButtonText,
  proceedButtonText,
  isAlert,
}: ModalProps) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ConfirmationModal
          onProceed={() => {
            res(true);
            onClose();
          }}
          onClose={() => {
            res(false);
            onClose();
          }}
          message={message}
          cancelButtonText={cancelButtonText}
          proceedButtonText={proceedButtonText}
          title={title}
          modalIcon={modalIcon}
          buttonIcon={buttonIcon}
          isAlert={isAlert}
        />
      );
    });
  });
};

/* Modal with which to handle REST API Call */
export const showConfirmationRequestModal = ({
  message,
  title,
  modalIcon,
  buttonIcon,
  cancelButtonText,
  proceedButtonText,
  isAlert,
}: ConfirmationModalProps) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <ConfirmationModal
            onProceed={() => {
              res(true);
              onClose();
            }}
            onClose={() => {
              res(false);
              onClose();
            }}
            message={message}
            title={title}
            modalIcon={modalIcon}
            buttonIcon={buttonIcon}
            cancelButtonText={cancelButtonText}
            proceedButtonText={proceedButtonText}
            isAlert={isAlert}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showProfilePicUploadModal = ({
  setProfileUrl,
  url,
  handleRemove,
}: {
  setProfileUrl: (url: string) => void;
  url: string;
  handleRemove: () => void;
}) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <UploadProfilePic
            onUpload={() => {
              res(true);
              onClose();
            }}
            onClose={() => {
              res(false);
              onClose();
            }}
            setProfileUrl={setProfileUrl}
            url={url}
            handleRemove={handleRemove}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showBulkInviteModal = () => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <BulkInvite
            onCancel={() => {
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showDocPreviewModal = (url: string, fileName: string) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <DocPreview
          url={url}
          fileName={fileName}
          onClose={() => {
            res(false);
            onClose();
          }}
        />
      );
    });
  });
};

export const showResearchScholarIndividualInviteModal = () => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <IndividualInvite
            onClose={() => {
              res(false);
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showEmailInviteModal = () => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <EmailInvite
            onCancel={() => {
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showOrganizationIndividualInviteModal = () => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <OrganizationIndividualInvite
            onCancel={() => {
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showOrganizationAddUserModal = () => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <AddUser
            onClose={() => {
              res(false);
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showFundingInterestModal = ({
  challengeId,
  interestId,
}: {
  challengeId: number;
  interestId: number;
}) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <FundingPreview
            onClose={() => {
              onClose();
            }}
            challengeId={challengeId}
            interestId={interestId}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showChallengePreviewModal = ({
  challengeId,
  setNavigation,
  showPrimaryButton,
}: {
  challengeId: number;
  setNavigation?: () => void;
  showPrimaryButton: boolean;
}) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <PreviewChallenge
            onClose={() => {
              onClose();
            }}
            challengeId={challengeId}
            setNavigation={setNavigation}
            showPrimaryButton={showPrimaryButton}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showInviteUserModal = ({
  challengeId,
  isChallengeGlobal,
}: {
  challengeId: number;
  isChallengeGlobal: boolean;
}) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <InviteUser
            onClose={() => {
              onClose();
            }}
            challengeId={challengeId}
            isChallengeGlobal={isChallengeGlobal}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showContactDetailsModal = (challengeId: number) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <ContactDetails
            challengeId={challengeId}
            onClose={() => {
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showStatusChangeConfirmationModal = ({
  title,
  message,
  userId,
  status,
  userType,
}: {
  title: string;
  message: string;
  userId: string;
  status: number;
  userType: number;
}) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <StatusChangeConfirmationModal
            onProceed={() => {
              res(true);
              onClose();
            }}
            onClose={() => {
              res(false);
              onClose();
            }}
            title={title}
            message={message}
            userId={userId}
            status={status}
            userType={userType}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showUpdateChallengeStatus = ({
  challengeStatus,
  challengeId,
}: {
  challengeStatus: number;
  challengeId: number;
}) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <UpdateChallengeStatus
            challengeStatus={challengeStatus}
            challengeId={challengeId}
            onClose={() => {
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};
export const showUploadCaseStudyModal = (challengeId: number, isEdit: boolean) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <UploadCaseStudy
            isEdit={isEdit}
            onClose={() => {
              onClose();
            }}
            challengeId={challengeId}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showChallengeFileUploadModal = (
  setFileUrl: (url: string) => void,
  isCoverImage: boolean,
  challengeId: number
) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <UploadChallengeFile
            onUpload={() => {
              res(true);
              onClose();
            }}
            onRemove={() => {
              res(false);
            }}
            onClose={() => {
              res(false);
              onClose();
            }}
            setFileUrl={setFileUrl}
            isCoverImage={isCoverImage}
            challengeId={challengeId}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showShareModal = (shareLink: string, title: string) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ShareModal
          onClose={() => {
            res(false);
            onClose();
          }}
          shareLink={shareLink}
          title={title}
        />
      );
    });
  });
};

export const showSearchModal = () => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <SearchModal
          onClose={() => {
            res(false);
            onClose();
          }}
        />
      );
    });
  });
};

export const showDiscoverMoreModal = () => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return <DiscoverMoreModal onclose={onClose} />;
    });
  });
};

export const showLearnMoreModal = (data: string, title: string) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return <LearnMoreModal data={data} title={title} onclose={onClose} />;
    });
  });
};

export const showFundingEmailInviteModal = () => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <FundingOrganizationEmailInvite
            onCancel={() => {
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showFundingOrganizationIndividualInviteModal = () => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <FundingOrganizationIndividualInvite
            onClose={() => {
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showShareFundingInterestModal = (challengeDetails: ChallengeInfo) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <ShareFundingInterestModal
            challengeDetails={challengeDetails}
            onClose={() => {
              res(false);
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showResearchInstituteIndividualInviteModal = () => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <ResearchInstituteIndividualInvite
            onClose={() => {
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showResearchScholarProfileViewModal = (userId: string) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <ResearchScholarProfileViewModal
            onClose={() => {
              onClose();
            }}
            userId={userId}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showChallengeDetailsModal = (
  data: Challenge,
  handleNavigateToLogin: (challengeId: number) => void
) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ChallengeDetailsModal
          data={data}
          onclose={() => {
            res(false);
            onClose();
          }}
          handleNavigateToLogin={handleNavigateToLogin}
        />
      );
    });
  });
};

export const showResearchInstituteEmailInviteModal = () => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <ResearchInstituteEmailInvite
            onCancel={() => {
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showResearchScholarDetailsModal = (data: ResearchInstituteUsersData) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ResearchScholarDetailsModal
          onClose={() => {
            onClose();
          }}
          data={data}
        />
      );
    });
  });
};

export const showEventPreviewModal = ({
  eventId,
  setNavigation,
  showPrimaryButton,
}: {
  eventId: number;
  setNavigation?: () => void;
  showPrimaryButton: boolean;
}) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <PreviewEvent
            onClose={() => {
              onClose();
            }}
            eventId={eventId}
            setNavigation={setNavigation}
            showPrimaryButton={showPrimaryButton}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showEventFileUploadModal = (
  setFileUrl: (url: string) => void,
  isCoverImage: boolean,
  eventId: number
) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <UploadEventFile
            onUpload={() => {
              res(true);
              onClose();
            }}
            onRemove={() => {
              res(false);
            }}
            onClose={() => {
              res(false);
              onClose();
            }}
            setFileUrl={setFileUrl}
            isCoverImage={isCoverImage}
            eventId={eventId}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showCollaborationFileUploadModal = (
  setFileUrl: (url: string) => void,
  isCoverImage: boolean,
  collaborationId: number
) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <UploadCollaborationFile
            onUpload={() => {
              res(true);
              onClose();
            }}
            onRemove={() => {
              res(false);
            }}
            onClose={() => {
              res(false);
              onClose();
            }}
            setFileUrl={setFileUrl}
            isCoverImage={isCoverImage}
            collaborationId={collaborationId}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showCollaborationPreviewModal = ({
  collaborationId,
  setNavigation,
  showPrimaryButton,
}: {
  collaborationId: number;
  setNavigation?: () => void;
  showPrimaryButton: boolean;
}) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <PreviewCollaboration
            onClose={() => {
              onClose();
            }}
            collaborationId={collaborationId}
            setNavigation={setNavigation}
            showPrimaryButton={showPrimaryButton}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showUpdateCollaborationStatus = ({
  collaborationStatus,
  collaborationId,
}: {
  collaborationStatus: number;
  collaborationId: number;
}) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <UpdateCollaborationStatus
            collaborationStatus={collaborationStatus}
            collaborationId={collaborationId}
            onClose={() => {
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showCollaborationCaseSummaryModal = (collaborationId: number, isEdit: boolean) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <UploadCaseSummary
            isEdit={isEdit}
            onClose={() => {
              onClose();
            }}
            collaborationId={collaborationId}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showCollaborationInviteUserModal = ({
  collaborationId,
}: {
  collaborationId: number;
}) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <CollaborationInviteUser
            onClose={() => {
              onClose();
            }}
            collaborationId={collaborationId}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showCollaborationContactDetailsModal = (collaborationId: number) => {
  return new Promise<boolean>(() => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <CollaborationContactDetails
            collaborationId={collaborationId}
            onClose={() => {
              onClose();
            }}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
};

export const showChallengeStatusUpdateModal = ({
  title,
  message,
  challengeId,
  status,
}: {
  title: string;
  message: string;
  challengeId: number;
  status: number;
}
) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <ChallengeStatusUpdateModal
            onProceed={() => {
              res(true);
              onClose();
            }}
            onClose={() => {
              res(false);
              onClose();
            }}
            title={title}
            message={message}
            challengeId={challengeId}
            status={status}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
}

export const showCollaborationStatusUpdateModal = ({
  title,
  message,
  collaborationId,
  status,
}: {
  title: string;
  message: string;
  collaborationId: number;
  status: number;
}
) => {
  return new Promise<boolean>((res) => {
    customConfirmAlert((onClose: () => void) => {
      return (
        <ReactQueryClientWrapper>
          <CollaborationStatusUpdateModal
            onProceed={() => {
              res(true);
              onClose();
            }}
            onClose={() => {
              res(false);
              onClose();
            }}
            title={title}
            message={message}
            collaborationId={collaborationId}
            status={status}
          />
        </ReactQueryClientWrapper>
      );
    });
  });
}
