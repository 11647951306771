import { create } from "zustand";
import { produce } from "immer";
import { CONTACTS_INFO_INITIAL_STATE } from "utils/constants";

export type OrganizationDataStore = {
  organizationInfo?: OrganizationDetails | undefined;
  contactsInfo: OrganizationContactDetails | undefined;
  officeDetails: OrganizationOfficeDetails | undefined;
  documentsInfo: Array<FileDetails> | undefined;
};

export const useOrganizationDataStore = create<OrganizationDataStore>()((set) => ({
  organizationInfo: undefined,
  contactsInfo: {
    contacts: [{ ...CONTACTS_INFO_INITIAL_STATE }],
    profileCompletionStatusId: 1,
    profileStatusId: 1,
  },
  officeDetails: undefined,
  documentsInfo: undefined,
}));

export function setOrganizationInfo(organizationInfo: OrganizationDetails) {
  useOrganizationDataStore.setState(
    produce((state: OrganizationDataStore) => {
      state.organizationInfo = organizationInfo;
    })
  );
}

export function setOrganizationInfoItem(
  value: string | number | OptionItem[] | null,
  key: keyof OrganizationDetails
) {
  useOrganizationDataStore.setState(
    // TODO change any type
    produce((state: any) => {
      if (state.organizationInfo) {
        state.organizationInfo[key] = value;
      }
    })
  );
}

export function setContactsInfo(contactsInfo: OrganizationContactDetails) {
  useOrganizationDataStore.setState(
    produce((state: OrganizationDataStore) => {
      state.contactsInfo = contactsInfo;
    })
  );
}

export function setContactsInfoItem(
  value: string | number | boolean,
  key: keyof OrganizationContact,
  index: number
) {
  useOrganizationDataStore.setState(
    // TODO change any type
    produce((state: any) => {
      if (state.contactsInfo) {
        state.contactsInfo.contacts[index][key] = value;
      }
    })
  );
}

export function setOfficeDetailsInfo(officeDetails: OrganizationOfficeDetails) {
  useOrganizationDataStore.setState(
    produce((state: OrganizationDataStore) => {
      state.officeDetails = officeDetails;
    })
  );
}

export function setOfficeDetailsInfoItem(
  value: string,
  key: keyof OrganizationOffice,
  index: number
) {
  useOrganizationDataStore.setState(
    // TODO change any type
    produce((state: any) => {
      if (state.officeDetails) {
        state.officeDetails.offices[index][key] = value;
      }
    })
  );
}

export function setDocumentsInfo(documents: any) {
  useOrganizationDataStore.setState(
    produce((state: OrganizationDataStore) => {
      state.documentsInfo = documents;
    })
  );
}

export function clearOrganizationDataStore() {
  useOrganizationDataStore.setState(
    produce((state: OrganizationDataStore) => {
      state.organizationInfo = undefined;
      state.contactsInfo = undefined;
      state.officeDetails = undefined;
      state.documentsInfo = undefined;
    })
  );
}
