import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveContacts(formData: Array<OrganizationContact>, userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  return await apiClient.put(urls.organizationContacts(userId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function useSaveContacts() {
  const qc = useQueryClient();

  return useMutation(
    async ({
      formData,
      organizationId,
    }: {
      formData: Array<OrganizationContact>;
      organizationId: string;
    }) => {
      const userInfo = useUserDataStore.getState().userInfo;
      const userId = organizationId ? organizationId : userInfo?.userId;
      if (userId) {
        const result = await saveContacts(formData, userId);
        qc.invalidateQueries({ queryKey: ["organization-details", userId] });
        qc.invalidateQueries({ queryKey: ["organization-contacts", userId] });
        return result;
      }
    }
  );
}
