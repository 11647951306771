import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";
async function changeChallengeStatus(status: number, challengeId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(
    urls.changeChallengeStatus(status, challengeId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus | ApiError;
}

export function useChangeChallengeStatus() {
  const queryClient = useQueryClient();
  return useMutation(async ({ status, challengeId }: { status: number; challengeId: number }) => {
    const result = await changeChallengeStatus(status, challengeId);
    queryClient.invalidateQueries(["challenge-details", challengeId]);
    queryClient.invalidateQueries(["challenge-basic-details", challengeId]);
    queryClient.invalidateQueries(["challenge-preview-details", challengeId]);
    return result;
  });
}
