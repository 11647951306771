import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { Title, SecondaryButton, LoaderModal } from "components";
import { addChallengeMilestone, setMilestones, useChallengeStore } from "store/useChallengeStore";
import { showErrorToastMessage } from "utils/toasterMessage";
import { useGetChallengeMilestones } from "features/Organization/api/useGetChallengeMilestones";
import AddMileStoneForm from "features/Organization/Challenge/CreateChallenge/Milestones/AddMilestone";

type MilestonesProps = {
  validationErrors: Array<Record<string, string>> | null;
  isDisabled?: boolean
};

const Milestones = ({ validationErrors, isDisabled }: MilestonesProps) => {
  const { t } = useTranslation();
  const { milestones } = useChallengeStore();
  const { data: milestonesData, isLoading } = useGetChallengeMilestones();
  const onAddMileStone = () => {
    if (milestones.length < 10) {
      addChallengeMilestone();
    } else {
      showErrorToastMessage({
        message: t("organization.maximum_number_of_milestones"),
      });
    }
  };
  useEffect(() => {
    if (milestonesData) {
      setMilestones(milestonesData);
    }
  }, [milestonesData]);

  return (
    <div>
      <div className=" flex items-center justify-between mb-6 flex-wrap pb-8 p-5 !py-3 border-b border-gray-light">
        <div className="flex items-center ltr:mr-auto rtl:ml-auto">
          <SVGIcon name="milestones" />
          <Title
            className=" text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
            title={t("organization.milestones")}
          />
        </div>
        {milestones.length > 0 && (
          <SecondaryButton
            startIcon="addMilestone"
            className=" !h-10 !min-h-0  !text-sm  font-medium bg-white hover:!text-white flex gap-1"
            title={"organization.add_milestone"}
            handleClick={onAddMileStone}
            disabled={isDisabled}
          />
        )}
      </div>
      {milestones.length == 0 ? (
        <div className="px-5">
          <SecondaryButton
            title={"organization.add_milestone"}  
            handleClick={onAddMileStone}
            className=" w-full !bg-gray-light/60 py-3.5 !font-normal mb-6 space-x-4 rtl:space-x-reverse border-none h-16 flex items-center !text-primary-medium text-sm  hover:!bg-primary-medium hover:!text-white"
            endIcon="plusCircle"
            disabled={isDisabled}
          />
        </div>
      ) : (
        milestones.map((item, index) => (
          <AddMileStoneForm key={index} index={index} validationErrors={validationErrors} />
        ))
      )}
      {isLoading ? <LoaderModal /> : null}
    </div>
  );
};

export default Milestones;
