import React from "react";
import SVGIcon from "assets/Icons";
import { RadioButton } from "components";
import { Avatar } from "react-daisyui";
import { CHALLENGE_STATUS } from "utils/constants";

type FilterPopupProps = {
  filterOptions: Array<FilterOption>;
  handleClick: (selectedFilterId: number) => void;
  activeFilter: number;
};

export function FilterPopup({ filterOptions, handleClick, activeFilter }: FilterPopupProps) {
  return (
    <div
      className={`dropdown dropdown-bottom dropdown-end ltr:ml-[18px] rtl:mr-[18px]  filterDropdown relative  ${activeFilter !== CHALLENGE_STATUS.All
        ? "after:bg-red-dark after:border after:border-white after:-right-[2px] after:-top-[3px] after:absolute after:w-[9px] after:h-[9px] after:rounded-full  overflow-visible after:z-50 "
        : ""
        }`}
    >
      <label
        tabIndex={0}
        className="btn text-sm font-medium !border-none  active:text-white focus:text-white  normal-case flex-nowrap w-[38px] h-[38px] py-0 flex items-center rounded-md active:bg-secondary-medium focus:bg-secondary-medium active:!border-secondary-medium focus:!border-secondary-medium  m-0 relative z-10  !shadow-none"
      >
        <SVGIcon name="filterLines" />
      </label>
      <ul
        tabIndex={0}
        className="dropdown-content !z-0 menu w-full divide-y divide-solid divide-primary-medium/10 shadow-400 text-gray-200 text-sm font-normal normal-case rounded-b-md -mt-2 py-[10px] bg-white text-left px-4 pb-0 "
      >
        {filterOptions?.map((item: FilterOption, index: number) => (
          <li key={index}>
            <RadioButton
              label={item?.option.label}
              className="relative top-auto left-0 !py-3 !rounded-none !pl-[4px] filter-radio-buttons gap-[10px]"
              onChange={() => handleClick(item?.option?.value)}
              checked={activeFilter === item?.option?.value}
              value={item?.option?.value}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
