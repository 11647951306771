import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import Profile from "assets/images/avatar.svg";
import { Descriptor, Divider, FilterTab, LoaderModal, Title } from "components";
import FundingInterestMessage from "features/Organization/Challenge/ViewChallenge/FundingPreview/Message";
import FundingInterestAbout from "features/Organization/Challenge/ViewChallenge/FundingPreview/About";
import { useGetViewFundingInterest } from "features/Organization/api/useGetViewFundingInterest";
import { FUNDING_INTEREST_TAB } from "utils/constants";
import { getMasterDataValue } from "utils/helpers";
import { useMasterDataStore } from "store/useMasterDataStore";

type FundingPreviewProps = {
  onClose: () => void;
  challengeId: number;
  interestId: number;
};
const FundingPreview = ({ onClose, challengeId, interestId }: FundingPreviewProps) => {
  const { t, i18n } = useTranslation();
  const { data: fundingInterest, isLoading } = useGetViewFundingInterest(challengeId, interestId);
  const [selectedTab, setSelectedTab] = useState<number>(FUNDING_INTEREST_TAB.message);
  const { organizationTypes } = useMasterDataStore();

  const userTypes = [
    {
      value: 0,
      label: "home.message",
    },
    {
      value: 1,
      label: "global.about",
    },
  ];
  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };
  const selectedOrganizationType = useMemo(() => {
    return organizationTypes?.filter((item) => item.id == fundingInterest?.organizationType)?.[0];
  }, [organizationTypes, fundingInterest]);
  return (
    <>
      <div className="bg-white max-w-[100vw] w-[100vw] min-w-[100vw]  lg:w-auto lg:max-w-[786px] lg:min-w-[786px] max-h-[700px] overflow-auto rounded-md   animate__animated animate__zoomIn">
        <div className="flex justify-between px-6 py-[26px]">
          <div className="text-base font-semibold text-black flex items-center gap-3">
            <SVGIcon name="shareInterest" />
            <Title title={t("home.funding_interests")} />
          </div>
          <span onClick={onClose} className="cursor-pointer">
            <SVGIcon name="close" />
          </span>
        </div>
        <Divider className=" w-full !border-gray-light " />
        <div className=" max-w-full  flex gap-6 mt-4 ml-6">
          <div className=" h-[92px] w-[92px] min-w-[92px] rounded-full overflow-hidden  ">
            <img
              alt="profile picture"
              className=" h-full w-full object-contain rounded-full text-center"
              src={fundingInterest?.profileImage ? fundingInterest?.profileImage : Profile}
            />
          </div>
          <div className="max-w-[calc(100%-250px)]">
            <h4 className="font-semibold text-lg text-black w-full line-clamp-2 WB_BW">
              {fundingInterest?.organizationName ?? ""}
            </h4>
            <div className="grid grid-cols-2 gap-x-4 truncate">
              <div className=" mt-1 truncate">
                <Descriptor
                  title={fundingInterest?.country ?? ""}
                  startIcon="mapPin"
                  className="text-sm font-normal truncate text-black flex items-center gap-2"
                />
              </div>
              <div className=" mt-1 truncate">
                <Descriptor
                  title={
                    selectedOrganizationType
                      ? getMasterDataValue(selectedOrganizationType, i18n.language)
                      : ""
                  }
                  startIcon="location"
                  className="text-sm font-normal text-black flex items-center gap-2 truncate"
                />
              </div>
              <div className=" mt-[10px]">
                <Descriptor
                  title={fundingInterest?.contactNumber ?? ""}
                  startIcon="phone"
                  className="text-sm font-normal text-black flex items-center gap-2 truncate min-w-[160px]"
                />
              </div>
              <div className=" mt-[10px]">
                <Descriptor
                  title={fundingInterest?.email ?? ""}
                  startIcon="previewMail"
                  className="text-sm font-normal text-black flex items-center gap-2 truncate"
                />
              </div>
            </div>
          </div>
        </div>
        <Divider className=" w-full !border-gray-light my-4" />
        <div className="mx-6">
          <div className="flex gap-2">
            <div className="tabContainer w-full   flex items-baseline ">
              <FilterTab
                filterOptions={userTypes}
                activeTab={selectedTab}
                handleClick={(value) => handleTabChange(value)}
              />
            </div>
          </div>

          <div className=" mb-8">
            {selectedTab === 0 ? (
              <FundingInterestMessage challengeId={challengeId} interestId={interestId} />
            ) : null}
            {selectedTab === 1 ? (
              <FundingInterestAbout challengeId={challengeId} interestId={interestId} />
            ) : null}
          </div>
        </div>
      </div>
      {isLoading ? <LoaderModal /> : null}
    </>
  );
};

export default FundingPreview;
