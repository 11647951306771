import { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Sidebar } from "components";
import { setSelectedSideMenu, useSidebarDataStore } from "store/useSidebarStore";
import { hideSidebarMenuItem } from "utils/helpers";
import { useUserDataStore } from "store/useUserDataStore";
import { USER_STATUS } from "utils/constants";
import { clearStores } from "store";
import { clearFilterDataStore, clearUserTypeFilter } from "store/useFilterDataStore";

function FundingOrganization() {
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const { userInfo } = useUserDataStore();
  const { selectedSideMenu } = useSidebarDataStore();
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const queryClient = useQueryClient();

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const SidebarMenuItems: Array<SidebarMenuItem> = useMemo(() => {
    const tempItems = [];
    if (userInfo && hideSidebarMenuItem(userInfo?.profileStatusId)) {
      tempItems.push({
        name: "global.settings",
        icon: "settings",
        subMenu: [
          {
            name: "global.profile",
          },
          {
            name: "global.password",
          },
        ],
      });
    } else {
      tempItems.push({
        name: "research_scholar.home",
        icon: "dashboard",
      });
      tempItems.push({
        name: "admin.challenges",
        icon: "challenge",
      });
      tempItems.push({
        name: "global.events",
        icon: "eventIcon",
      });
      tempItems.push({
        name: "forum.forums",
        icon: "forums",
      });

      tempItems.push({
        name: "global.settings",
        icon: "settings",
        subMenu: [
          {
            name: "global.profile",
          },
          {
            name: "global.password",
          },
        ],
      });
    }
    return tempItems;
  }, [userInfo]);

  const handleIsOpen = (type: string) => {
    if (selectedSideMenu?.type === type) {
      return !selectedSideMenu.isOpen;
    } else {
      return true;
    }
  };

  const handleMenuClick = (type: string) => {
    clearFilterDataStore();
    clearUserTypeFilter();
    if (type === "admin.challenges") {
      queryClient.resetQueries(["challenge-list"]);
      navigate("/funding-organization/challenges");
    } else if (type === "research_scholar.home") {
      queryClient.resetQueries(["challenge-list"]);
      navigate("/funding-organization/home");
    } else if (type === "forum.forums") {
      navigate("/funding-organization/forums");
    } else if (type === "global.events") {
      queryClient.resetQueries(["event-list"]);
      navigate("events");
    }
    setSelectedSideMenu({
      type,
      isOpen: handleIsOpen(type),
      subMenuType: type === "global.settings" ? selectedSideMenu?.subMenuType : null,
    });
  };

  const handleSubMenuClick = (type: string) => {
    clearFilterDataStore();
    clearUserTypeFilter();
    if (selectedSideMenu?.type) {
      setSelectedSideMenu({
        type: selectedSideMenu?.type,
        isOpen: true,
        subMenuType: type,
      });
    }
    if (type === "global.password") {
      navigate("settings/change-password", { replace: true });
    } else if (type === "global.profile") {
      navigate(`settings/profile/${userInfo?.userId}`, { replace: true });
    }
  };

  useEffect(() => {
    if (userInfo?.profileStatusId === USER_STATUS.Disabled) {
      clearStores();
      navigate("/login");
    }
    if (pathName === "/funding-organization/forums") {
      setSelectedSideMenu({
        type: "forum.forums",
        isOpen: false,
      });
    } else if (pathName === "/funding-organization/events") {
      setSelectedSideMenu({
        type: "global.events",
        isOpen: false,
      });
    }
  }, [userInfo, pathName, navigate]);

  return (
    <div className={`flex w-full  min-h-screen   ${!isOpenSideMenu ? "miniSidebar" : ""}`}>
      <Sidebar
        menuItems={SidebarMenuItems}
        handleCollapseClick={onToggleClick}
        handleMenuClick={handleMenuClick}
        handleSubMenuClick={handleSubMenuClick}
      />
      <div className="w-full items-baseline mainContainer min-h-[calc(100vh-76px)] flex overflow-auto lg:rtl:mr-[250px] lg:ltr:ml-[250px] flex-col">
        <Outlet />
      </div>
    </div>
  );
}

export default FundingOrganization;
