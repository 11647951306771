import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import {
  COLLABORATION_PATH_PATTERN,
  COLLABORATION_SCHOLAR_PREVIEW_PATH_PATTERN,
  CREATE_CHALLENGE_PATH_PATTERN,
  EXPLORE_CHALLENGE_PATH_PATTERN,
  EXPLORE_CHALLENGE_VIEW_SCHOLAR_PATH_PATTERN,
  EXPLORE_COLLABORATION_PATH_PATTERN,
  SCHOLAR_DETAILS_PATH_PATTERN,
  VIEW_REQUEST_RESEARCH_SCHOLAR_PATH_PATTERN,
} from "utils/constants";

type BreadcrumbsProps = LinkItem & { routeIndex?: number; breadCrumbLength: number };

export function BreadCrumb({ title, breadCrumbLength }: BreadcrumbsProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const path = useMemo(() => {
    const pathName = window.location.pathname;
    const searchKeyword = searchParams.get("key");
    let paths = pathName?.split("/");
    paths.shift();
    paths.pop();
    if (breadCrumbLength === 3) {
      paths.pop();

      if (EXPLORE_CHALLENGE_VIEW_SCHOLAR_PATH_PATTERN.test(pathName)) {
        paths = paths.slice(0, -2).concat("explore");
      } else if (COLLABORATION_SCHOLAR_PREVIEW_PATH_PATTERN.test(pathName)) {
        paths = paths.slice(0, -2);
      } else if (
        pathName.includes("explore/") &&
        (pathName.includes("research-institute") ||
          (pathName.includes("organization") &&
            (pathName.includes("discussion-forum") || pathName.includes("invite-history")))) &&
        !pathName.includes("explore/invite-history/discussion-forum") &&
        !EXPLORE_COLLABORATION_PATH_PATTERN.test(pathName)
      ) {
        if (!pathName.includes("create")) {
          paths.pop();
        }
        paths.push("explore");
      } else if (
        COLLABORATION_PATH_PATTERN.test(pathName) ||
        VIEW_REQUEST_RESEARCH_SCHOLAR_PATH_PATTERN.test(pathName) ||
        pathName.includes("research-scholar/home/collaborations")
      ) {
        paths.pop();
      }
    } else if (breadCrumbLength === 2) {
      if (
        CREATE_CHALLENGE_PATH_PATTERN.test(pathName) ||
        (COLLABORATION_PATH_PATTERN.test(pathName) && !pathName.includes("discussion-forum")) ||
        VIEW_REQUEST_RESEARCH_SCHOLAR_PATH_PATTERN.test(pathName)
      ) {
        paths.pop();
      } else if (
        EXPLORE_CHALLENGE_PATH_PATTERN.test(pathName) &&
        !pathName.includes("invite-history/discussion-forum")
      ) {
        paths.pop();

        if (!EXPLORE_CHALLENGE_VIEW_SCHOLAR_PATH_PATTERN.test(pathName)) {
          paths.push("explore");
        }
      } else if (pathName.includes("search-results")) {
        paths.pop();

        if (searchKeyword) {
          const path = paths?.join("/");
          return `/${path}?key=${searchKeyword}`;
        }
      } else if (
        pathName.includes("research-scholar/home/collaborations") &&
        !pathName.includes("discussion-forum")
      ) {
        paths.pop();
      } else if (pathName.includes("preview")) {
        paths.pop();
      }
    }

    if (
      SCHOLAR_DETAILS_PATH_PATTERN.test(window.location.pathname) &&
      !pathName.includes("requests")
    ) {
      paths.pop();
    }
    return `/${paths?.join("/")}`;
  }, [searchParams, breadCrumbLength]);

  return (
    <div className="breadCrumbs truncate">
      <Link className="truncate" to={path}>
        <span className="truncate block"> {t(title)}</span>
      </Link>
    </div>
  );
}
