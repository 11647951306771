import { useEffect, useRef } from "react";
import { InlineLoader, Label, NoData, SecondaryButton, Title } from "components";
import profilePicture from "assets/images/avatar.svg";
import IncomingChat from "features/Messages/Chats/IncomingChat";
import OutgoingChat from "features/Messages/Chats/OutgoingChat";
import { showResearchScholarProfileViewModal } from "utils/confirmationModals";
import Reply from "features/Messages/Chats/Reply";
import { useGetMessages } from "features/Messages/api/useGetMessages";
import { USERS } from "utils/constants";
import { ChatService } from "features/Messages/Chats/helper";

type ChatsProps = {
  selectedChatTreadId: string;
  userAccessToken: string;
};

export default function Chats({ selectedChatTreadId, userAccessToken }: ChatsProps) {
  const { data, isLoading } = useGetMessages(selectedChatTreadId);

  const handleViewProfile = async (userId: string) => {
    await showResearchScholarProfileViewModal(userId);
  };

  const messagesRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    if (messagesRef && messagesRef.current) {
      messagesRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (data?.messages?.length > 0) scrollToBottom();
  }, [data]);

  useEffect(() => {
    if (userAccessToken) ChatService.listenToThread(userAccessToken);
  }, [userAccessToken]);

  return (
    <>
      {data ? (
        <div className="flex w-full h-full rounded-[4px]">
          <div className="w-full h-full bg-white rounded-md">
            <div className="px-[18px] flex flex-col max-h-[calc(100vh-200px)] min-h-[calc(100vh-200px)]  lg:max-h-[calc(100vh-163px)] lg:min-h-[calc(100vh-163px)] bg-white rounded-md">
              <div className="flex items-center justify-between py-5 sticky top-0 bg-white">
                <div className="flex gap-3 items-center">
                  <img
                    alt="profile picture"
                    className="h-10 w-10 rounded-full "
                    src={data?.chatUserAvatar ? data?.chatUserAvatar : profilePicture}
                  />
                  <div className="flex flex-col truncate truncateMSG">
                    <Title
                      title={data?.chatUserName}
                      className="font-semibold text-sm text-black truncate dirRTL_LTR"
                    />
                    <Label text={data?.chatUserLocation} className="truncate !text-xs" />
                  </div>
                </div>
                {data?.chatUserType === USERS.ResearchScholar ? (
                  <SecondaryButton
                    startIcon="view"
                    title={"forum.view_profile"}
                    handleClick={() => handleViewProfile(data?.chatUserId)}
                    className="!h-[38px] min-h-max flex items-center gap-2 viewIcon"
                  />
                ) : null}
              </div>
              <div className=" overflow-auto mb-auto">
                {data?.messages?.length > 0 ? (
                  <>
                    <div
                      className="bg-primary-101 w-full h-full rounded-md py-6 pl-[21px] pr-[18px] "
                      ref={messagesRef}
                    >
                      {data?.messages
                        ?.slice()
                        .reverse()
                        ?.map((messagesItem: Message, index: number) => {
                          if (messagesItem?.isSelf)
                            return (
                              <OutgoingChat
                                key={`${messagesItem.messageId}-${index}`}
                                message={messagesItem}
                                isLast={index === data?.messages?.length - 1}
                              />
                            );
                          else
                            return (
                              <IncomingChat
                                key={`${messagesItem.messageId}-${index}`}
                                message={messagesItem}
                                name={data?.chatUserName}
                                isLast={index === data?.messages?.length - 1}
                              />
                            );
                        })}
                    </div>
                  </>
                ) : null}
              </div>
              <Reply threadId={selectedChatTreadId} scrollToBottom={scrollToBottom} />
            </div>
          </div>
        </div>
      ) : null}
      {!selectedChatTreadId && !isLoading ? (
        <div className="p-5 bg-white w-full h-full rounded-md">
          <NoData
            className="!mb-0 bg-gray-111 justify-center py-0"
            iconName="noMsg"
            message="forum.select_a_conversation_to_show_here"
          />
        </div>
      ) : null}
      {isLoading ? <InlineLoader className=" m-auto" /> : null}
    </>
  );
}
