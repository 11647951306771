type ProfilePicPreview = {
  url: string;
  className?: string;
};

export function ProfilePicPreview({ url, className }: ProfilePicPreview) {
  return (
    <div className={`w-full h-full rounded-full overflow-hidden ${className}`}>
      <img
        className="w-full h-full object-cover text-transparent"
        alt="Profile picture"
        src={url}
        key={url}
      />
    </div>
  );
}
