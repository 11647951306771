import { useEffect } from "react";

export function useSetScroll(scrollPosition: number) {
  useEffect(() => {
    window.scrollTo({
      top: scrollPosition,
      behavior: "auto",
    });
  }, [scrollPosition]);
}
