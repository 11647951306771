import { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQueryClient } from "@tanstack/react-query";
import {
  FilterTab,
  Title,
  NoData,
  LoaderModal,
  InlineLoader,
  Header,
  MultiSelect,
  Chip,
} from "components";
import {
  CHALLENGE_STATUS,
  INITIAL_SEARCH_PARAMS,
  PAGE_LIMIT,
  COLLABORATION_LIST_INIT_PARAMS,
  USERS,
} from "utils/constants";
import { useStickyScroll } from "hooks/useStickyScroll";
import {
  useFilterDataStore,
  clearOrganizationFilterInfo,
  setScrollInfo,
  setChallengeFilterInfoItem,
} from "store/useFilterDataStore";
import { useSetScroll } from "hooks/useSetScroll";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import useResetQueryOnLanguageSwitch from "hooks/useResetQueryOnLanguageSwitch";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { useGetCollaborationList } from "features/Collaboration/api/useGetCollaborationList";
import { setSelectedFormId, setSelectedSideMenu } from "store/useSidebarStore";
import { useUserDataStore } from "store/useUserDataStore";
import { CollaborationCard } from "components/CollaborationCard";

export default function Collaborations() {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { scroll } = useStickyScroll();
  const { scrollInfo } = useFilterDataStore();
  const { organizationFilterInfo, challengeFilterInfo } = useFilterDataStore();
  const { userInfo } = useUserDataStore();
  const pathName = window.location.pathname;

  const [requestParams, setRequestParams] = useState<CollaborationListParams>({
    ...COLLABORATION_LIST_INIT_PARAMS,
    status: challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.All,
    researchArea: challengeFilterInfo?.researchAreaId ?? [],
  });
  const {
    data: collaborationList,
    isLoading: collaborationListLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetCollaborationList(requestParams);
  const [isOpenSideMenu, setIsOpenSideMenu] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(
    challengeFilterInfo?.activeTab ?? CHALLENGE_STATUS.All
  );

  const [selectedOptions, setSelectedOptions] = useState<OptionItem[]>(
    challengeFilterInfo?.selectedResearchAreas ?? []
  );

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const { scrollPosition } = useGetScrollPosition();
  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "collaboration-list");
  useResetQueryOnLanguageSwitch("collaboration-list");

  const handleTabChange = (type: number) => {
    setActiveTab(type);
    queryClient.resetQueries(["collaboration-list"]);
    if (type === CHALLENGE_STATUS.All && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.All }));
      setChallengeFilterInfoItem("activeTab", CHALLENGE_STATUS.All);
    } else if (type === CHALLENGE_STATUS.Open && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Open }));
      setChallengeFilterInfoItem("activeTab", CHALLENGE_STATUS.Open);
    } else if (type === CHALLENGE_STATUS.Close && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Close }));
      setChallengeFilterInfoItem("activeTab", CHALLENGE_STATUS.Close);
    } else if (type === CHALLENGE_STATUS.Pending && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Pending }));
      setChallengeFilterInfoItem("activeTab", CHALLENGE_STATUS.Pending);
    }
  };

  const collaborationDetails = useMemo(() => {
    const tempData: CollaborationListingResponse = {
      collaborations: [],
      totalCount: 0,
      totalPages: 0,
    };
    if (collaborationList?.pages && collaborationList?.pages[0]) {
      tempData.totalCount = collaborationList.pages[0]?.totalCount;
      tempData.totalPages = collaborationList.pages[0]?.totalPages;
      const collaborations = collaborationList.pages
        .map((item) => {
          return item.collaborations;
        })
        .flat();
      tempData.collaborations = collaborations;
    }
    return tempData;
  }, [collaborationList]);

  const collaborationFilterItems: Array<RecordItem> = useMemo(() => {
    const tempItems = [
      {
        value: CHALLENGE_STATUS.All,
        label: "global.all_collaborations",
      },
      {
        value: CHALLENGE_STATUS.Open,
        label: "organization.open",
      },
      {
        value: CHALLENGE_STATUS.Close,
        label: "organization.closed",
      },
    ];
    if (userInfo?.userType === USERS.Admin) {
      tempItems.push({
        value: CHALLENGE_STATUS.Pending,
        label: "global.pending",
      })
    }
    return tempItems;
  }, []);

  useEffect(() => {
    if (organizationFilterInfo?.pathName && pathName !== organizationFilterInfo?.pathName) {
      clearOrganizationFilterInfo();
    }
  }, [organizationFilterInfo?.pathName, pathName]);

  const handleSelectedOptions = (options: OptionItem[] | null) => {
    if (options === null) {
      setSelectedOptions([]);
      setRequestParams((prevState) => ({ ...prevState, researchArea: [] }));
      setChallengeFilterInfoItem("researchAreaId", []);
      setChallengeFilterInfoItem("selectedResearchAreas", []);
    } else if (options.length <= 5) {
      const newOptions = options.filter(
        (option) => !selectedOptions.some((selectedOption) => selectedOption.id === option.id)
      );
      if (newOptions.length > 0) {
        setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, ...newOptions]);
        setChallengeFilterInfoItem("selectedResearchAreas", [...selectedOptions, ...newOptions]);
        const researchAreaIds = newOptions.map((option) => Number(option.id));
        setRequestParams((prevState) => ({
          ...prevState,
          researchArea: [...prevState.researchArea, ...researchAreaIds],
        }));
        setChallengeFilterInfoItem("researchAreaId", [
          ...requestParams.researchArea,
          ...researchAreaIds,
        ]);
      }
    }
  };

  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);
  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  const handleRemoveSelectedOption = (optionToRemove: OptionItem) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    setChallengeFilterInfoItem(
      "selectedResearchAreas",
      selectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    const remainingResearchAreas = selectedOptions.filter(
      (option) => option.id !== optionToRemove.id
    );
    const researchAreaIds = remainingResearchAreas.map((option) => Number(option.id));
    setRequestParams((prevState) => ({
      ...prevState,
      researchArea: researchAreaIds,
    }));
    setChallengeFilterInfoItem("researchAreaId", [...researchAreaIds]);
  };

  function handleCollaborationClick(index: number, collaborationId: number) {
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setSelectedFormId(collaborationId);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: location.pathname,
      index: pageParam,
    });
  }

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.collaborations",
    });
  }, []);

  return (
    <div className="w-full h-full flex">
      <Header
        title={"global.collaborations"}
        handleCollapseClick={onToggleClick}
        className={
          scroll
            ? " bg-primary-medium lg:bg-white  animate__animated animate__fadeInDown lg:shadow-sticky   lg:!h-[90px] fixed stickyHeader smaller-devices-section-challenge"
            : "fixed bg-primary-medium lg:bg-gray-light smaller-devices-section-challenge"
        }
      />
      <div className=" adminDetails w-full pt-[118px] pb-10 items-baseline px-4 lg:px-10 lg:ltr:pr-[30px] lg:rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto max-[1025px]:overflow-x-hidden ">
        <>
          <div className="tabContainer w-full   flex items-baseline tabMobileView  z-10 tab-mobile-view">
            <FilterTab
              filterOptions={collaborationFilterItems}
              activeTab={activeTab}
              handleClick={(value) => handleTabChange(value)}
            />
          </div>

          <div className="tab-content  w-full">
            <div className=" flex justify-between pb-0 md:pb-5 items-center">
              <div className="flex w-full lg:w-auto flex-col lg:flex-row lg:space-x-[46px] space-x-0 rtl:space-x-reverse  pt-[22px]">
                <div className="min-w-full lg:min-w-[482px]">
                  <div className=" relative">
                    <MultiSelect
                      labelText={t("research_scholar.challenge_search_areas")}
                      hintText={t("research_scholar.add_research_areas")}
                      setSelectedOptions={handleSelectedOptions}
                      selectedOptions={selectedOptions}
                      loadOptions={loadResearchAreaOptions ?? []}
                      isDisabled={selectedOptions.length > 4}
                    />
                  </div>
                </div>
                <div className="w-full mt-3 md:mt-0">
                  {selectedOptions.length > 0 && (
                    <Title
                      className=" text-primary-light text-sm leading-4 font-normal mb-2 md:mb-6"
                      title={t("global.selected_areas")}
                    />
                  )}
                  <div className="flex flex-wrap mb-4 lg:mb-0">
                    {selectedOptions.map((option) => (
                      <Chip
                        key={option.id}
                        className="chipPrimary"
                        text={i18n.language === "en" ? option.name.en : option.name.ar}
                        handleClose={() => handleRemoveSelectedOption(option)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <Title
              className=" text-primary-light text-sm leading-4 font-normal mb-4"
              title={`${
                collaborationList?.pages[0]?.totalCount
                  ? collaborationList?.pages[0]?.totalCount
                  : t("global.no")
              } ${t("organization.results_found")}`}
            />

            {collaborationDetails?.collaborations?.length > 0 ? (
              <InfiniteScroll
                dataLength={collaborationDetails?.collaborations?.length}
                next={fetchNextPage}
                hasMore={!!hasNextPage}
                loader={hasNextPage || collaborationListLoading ? <InlineLoader /> : null}
                scrollThreshold="200px"
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                  {collaborationDetails.collaborations.map(
                    (item: CollaborationData, index: number) => (
                      <div key={index} className="">
                        <CollaborationCard
                          data={item}
                          showCollaborationSignature={true}
                          path={
                            item?.statusId === CHALLENGE_STATUS.Draft
                              ? `/research-institute/collaborations/create/${item?.id}`
                              : ""
                          }
                          handleCollaborationClick={() => handleCollaborationClick(index, item?.id)}
                        />
                      </div>
                    )
                  )}
                </div>
              </InfiniteScroll>
            ) : null}
            {!collaborationListLoading && collaborationDetails?.collaborations?.length === 0 ? (
              <div>
                <NoData message={"global.no_collaborations_to_show"} />
              </div>
            ) : null}
          </div>
        </>
        {collaborationListLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
}
