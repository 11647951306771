import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getAutoApprovalDetails(userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.researchInstituteAutoApproval(userId), {
    headers: { Authorization: `Bearer ${token}` },
  });

  return res.data.data as ResearchInstituteAutoApproval | ApiError;
}

export function useGetAutoApprovalDetails() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;

  return useQuery(["auto-approval-info"], async () => {
    if (userId) {
      return await getAutoApprovalDetails(userId);
    }
  });
}
