import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQueryClient } from "@tanstack/react-query";
import {
  ChallengeCard,
  Chip,
  FilterTab,
  Header,
  InlineLoader,
  LoaderModal,
  MultiSelect,
  NoData,
  PortalFilter,
  SecondaryButton,
  Title,
} from "components";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { clearChallengeStore } from "store/useChallengeStore";
import { useStickyScroll } from "hooks/useStickyScroll";
import { useGetChallengeList } from "features/Challenge/api/useGetChallengeList";
import {
  CHALLENGE_STATUS,
  INITIAL_SEARCH_PARAMS,
  EXPLORE_CHALLENGE_INIT_PARAMS,
  PAGE_LIMIT,
  USERS,
  GLOBAL_PORTAL_FILTERS,
} from "utils/constants";
import { useUserDataStore } from "store/useUserDataStore";
import { useMasterDataStore } from "store/useMasterDataStore";
import { setSelectedSideMenu } from "store/useSidebarStore";
import {
  resetScroll,
  setScrollInfo,
  useFilterDataStore,
  setChallengeFilterInfoItem,
  setUserType,
} from "store/useFilterDataStore";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import { useSetScroll } from "hooks/useSetScroll";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";
import useResetQueryOnLanguageSwitch from "hooks/useResetQueryOnLanguageSwitch";
import { getGlobalFilterParam } from "utils/helpers";

const ExploreChallenge = () => {
  const { userInfo } = useUserDataStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { scroll } = useStickyScroll();
  const { challengeFilterInfo, userType } = useFilterDataStore();
  const isGlobal = userInfo?.isGlobal
    ? true
    : getGlobalFilterParam(userType ?? GLOBAL_PORTAL_FILTERS.Both);
  const [requestParams, setRequestParams] = useState<ChallengeListParams>({
    ...EXPLORE_CHALLENGE_INIT_PARAMS,
    status: challengeFilterInfo?.exploreActiveTab ?? CHALLENGE_STATUS.All,
    researchArea: challengeFilterInfo?.researchAreaId ?? [],
    isGlobal,
  });
  const {
    data: challengeList,
    isLoading: challengeListLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetChallengeList(requestParams);
  const [activeTab, setActiveTab] = useState<number>(
    challengeFilterInfo?.exploreActiveTab ?? CHALLENGE_STATUS.All
  );
  const { permissions } = useMasterDataStore();
  const [selectedOptions, setSelectedOptions] = useState<OptionItem[]>(
    challengeFilterInfo?.selectedResearchAreas ?? []
  );
  const [userTypeActiveFilter, setUserTypeActiveFilter] = useState(
    userType ?? GLOBAL_PORTAL_FILTERS.Both
  );
  const { scrollPosition } = useGetScrollPosition();
  const queryClient = useQueryClient();

  const handleTabChange = (type: number) => {
    setActiveTab(type);
    queryClient.resetQueries(["challenge-list"]);
    if (type === CHALLENGE_STATUS.All && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.All }));
      setChallengeFilterInfoItem("exploreActiveTab", CHALLENGE_STATUS.All);
    } else if (type === CHALLENGE_STATUS.Open && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Open }));
      setChallengeFilterInfoItem("exploreActiveTab", CHALLENGE_STATUS.Open);
    } else if (type === CHALLENGE_STATUS.Close && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Close }));
      setChallengeFilterInfoItem("exploreActiveTab", CHALLENGE_STATUS.Close);
    }
  };

  const challengeFilterItems = [
    {
      value: CHALLENGE_STATUS.All,
      label: "organization.all_challenges",
    },
    {
      value: CHALLENGE_STATUS.Open,
      label: "organization.open",
    },
    {
      value: CHALLENGE_STATUS.Close,
      label: "organization.closed",
    },
  ];

  const { scrollInfo } = useFilterDataStore();
  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "challenge-list");
  useResetQueryOnLanguageSwitch("challenge-list");

  const handleSelectedOptions = (options: OptionItem[] | null) => {
    if (options === null) {
      setSelectedOptions([]);
      setChallengeFilterInfoItem("researchAreaId", []);
      setChallengeFilterInfoItem("selectedResearchAreas", []);
    } else if (options.length <= 5) {
      const newOptions = options.filter(
        (option) => !selectedOptions.some((selectedOption) => selectedOption.id === option.id)
      );
      if (newOptions.length > 0) {
        setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, ...newOptions]);
        setChallengeFilterInfoItem("selectedResearchAreas", [...selectedOptions, ...newOptions]);
        const researchAreaIds = newOptions.map((option) => Number(option.id));
        setRequestParams((prevState) => ({
          ...prevState,
          researchArea: [...prevState.researchArea, ...researchAreaIds],
        }));
        setChallengeFilterInfoItem("researchAreaId", [
          ...requestParams.researchArea,
          ...researchAreaIds,
        ]);
      }
    }
  };
  const handleRemoveSelectedOption = (optionToRemove: OptionItem) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    setChallengeFilterInfoItem(
      "selectedResearchAreas",
      selectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    const remainingResearchAreas = selectedOptions.filter(
      (option) => option.id !== optionToRemove.id
    );
    const researchAreaIds = remainingResearchAreas.map((option) => Number(option.id));
    setRequestParams((prevState) => ({
      ...prevState,
      researchArea: researchAreaIds,
    }));
    setChallengeFilterInfoItem("researchAreaId", [...researchAreaIds]);
  };

  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);
  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  const handleCreateChallenge = () => {
    clearChallengeStore();
    resetScroll();
    setScrollInfo({
      index: 0,
      scrollPosition: 0,
      pathName: window.location.pathname,
    });
    navigate("/organization/challenges/explore/create");
  };
  const linkList: Array<LinkItem> = [
    {
      title: "home.challenges",
      path: "/organization/challenges",
    },
    {
      title: "organization.explore_challenge",
      path: "/organization/challenges",
    },
  ];
  const challengeDetails = useMemo(() => {
    const tempData: ChallengeListingResponse = { challenges: [], totalCount: 0, totalPages: 0 };
    if (challengeList?.pages && challengeList?.pages[0]) {
      tempData.totalCount = challengeList.pages[0]?.totalCount;
      tempData.totalPages = challengeList.pages[0]?.totalPages;
      const challenges = challengeList.pages
        .map((item) => {
          return item.challenges;
        })
        .flat();
      tempData.challenges = challenges;
    }
    return tempData;
  }, [challengeList]);

  function handleChallengeClick(index: number) {
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: location.pathname,
      index: pageParam,
    });
  }

  useEffect(() => {
    setSelectedSideMenu({
      type: "admin.challenges",
    });
  }, []);

  useEffect(() => {
    if (scrollInfo?.pathName && location.pathname !== scrollInfo?.pathName) {
      resetScroll();
    }
  }, [scrollInfo?.pathName]);

  const handleRadioTypeChange = (value: boolean | string, item: number) => {
    setScrollInfo({
      index: 0,
      pathName: window.location.pathname,
      scrollPosition: 0,
    });
    queryClient.resetQueries(["challenge-list"]);
    setRequestParams((prevstate) => ({
      ...prevstate,
      isGlobal: value,
    }));
    setUserTypeActiveFilter(item);
    setUserType(item);
  };

  return (
    <div className="w-full h-full flex">
      <Header
        title={"organization.explore_challenge"}
        linkList={linkList}
        className={
          scroll
            ? " bg-gradient-blueDark md:bg-white animate__animated animate__fadeInDown shadow-sticky !py-[26px] !h[57px] md:!h-[90px] fixed stickyHeader"
            : "fixed bg-gradient-blueDark lg:bg-[#E8F1F6]"
        }
      />
      <div className=" adminDetails w-full pt-[118px] pb-10 items-baseline px-4 lg:px-10 lg:ltr:pr-[30px] lg:rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto ">
        <div className="tabContainer w-full   flex items-baseline ">
          <FilterTab
            filterOptions={challengeFilterItems}
            activeTab={activeTab}
            handleClick={(value) => handleTabChange(value)}
          />
        </div>

        {!userInfo?.isGlobal ? (
          <div className="lg:ms-auto -mt-[66px] lg:-mt-[28px] mb-[66px] lg:mb-0 -ml-3 lg:ml-0 relative -left-2.5 lg:left-0">
            <PortalFilter
              activeFilter={userTypeActiveFilter}
              handleRadioTypeChange={handleRadioTypeChange}
            />
          </div>
        ) : null}
        <div className="flex flex-col lg:flex-row lg:space-x-[46px] space-x-0 rtl:space-x-reverse w-full pt-[22px]">
          <div className="min-w-[288px] lg:min-w-[482px]">
            <div className=" relative">
              <MultiSelect
                labelText={t("research_scholar.challenge_search_areas")}
                hintText={t("research_scholar.add_research_areas")}
                setSelectedOptions={handleSelectedOptions}
                selectedOptions={selectedOptions}
                loadOptions={loadResearchAreaOptions ?? []}
                isDisabled={selectedOptions.length > 4}
              />
            </div>
          </div>
          <div className="w-full mt-6 md:mt-0">
            {selectedOptions.length > 0 && (
              <Title
                className=" text-primary-light text-sm leading-4 font-normal mb-2 md:mb-6"
                title={t("global.selected_areas")}
              />
            )}
            <div className="flex flex-wrap mb-4 lg:mb-0">
              {selectedOptions.map((option) => (
                <Chip
                  key={option.id}
                  className="chipPrimary"
                  text={option.name.en}
                  handleClose={() => handleRemoveSelectedOption(option)}
                />
              ))}
            </div>
          </div>

          <div className="tab-content pt-0 pb-6 lg:py-6 w-full">
            <div className=" flex justify-end  items-center">
              {permissions?.Createchallenge ? (
                <SecondaryButton
                  title={"organization.create_new_challenge"}
                  handleClick={handleCreateChallenge}
                  startIcon="addMilestone"
                  className="!bg-transparent space-x-1 rtl:space-x-reverse"
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="w-full pt-5">
          <Title
            className=" text-primary-light text-sm leading-4 font-normal  pb-5 "
            title={`${
              challengeList?.pages[0]?.totalCount
                ? challengeList?.pages[0]?.totalCount
                : t("global.no")
            } ${t("organization.results_found")}`}
          />
          {challengeDetails?.challenges?.length > 0 ? (
            <InfiniteScroll
              dataLength={challengeDetails?.challenges?.length}
              next={fetchNextPage}
              hasMore={!!hasNextPage}
              loader={hasNextPage || challengeListLoading ? <InlineLoader /> : null}
              scrollThreshold="200px"
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                {challengeDetails.challenges.map((item: ChallengeData, index: number) => (
                  <div key={index} className="">
                    <ChallengeCard
                      data={item}
                      showChallengeSignature={true}
                      path={
                        item?.organizationId === userInfo?.userId
                          ? `/organization/challenges/${item?.id}/explore`
                          : `/organization/challenges/explore/${item?.id}`
                      }
                      handleChallengeClick={() => handleChallengeClick(index)}
                    />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          ) : null}
          {!challengeListLoading && challengeDetails?.challenges?.length === 0 ? (
            <div>
              <NoData message={"admin.no_challenges_to_show"} />
            </div>
          ) : null}
        </div>
        {challengeListLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
};
export default ExploreChallenge;
