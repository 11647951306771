import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function collaborationUserInviteUpdate(collaborationId: number, statusId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  return await apiClient.put(
    urls.collaborationUserInviteUpdate(collaborationId, statusId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export function useCollaborationUserInviteUpdate() {
  const qc = useQueryClient();
  return useMutation(
    async ({ collaborationId, statusId }: { collaborationId: number; statusId: number }) => {
      const result = await collaborationUserInviteUpdate(collaborationId, statusId);
      qc.invalidateQueries(["collaboration-invites"]);
      qc.invalidateQueries(["collaboration-preview-details", collaborationId]);
      return result;
    }
  );
}
