import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function sendCollaborationParticipationRequest(userId: string, collaborationId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const response = await apiClient.put(
    urls.sendCollaborationParticipationRequest(userId, collaborationId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus | ApiError;
}

export function useSendCollaborationParticipationRequest() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;
  const qc = useQueryClient();
  return useMutation(async (collaborationId: number) => {
    if (userId) {
      const result = await sendCollaborationParticipationRequest(userId, collaborationId);
      qc.invalidateQueries(["collaboration-preview-details", collaborationId]);
      return result;
    }
  });
}
