import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getUserCollaborationStatus(collaborationId: number, userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.userCollaborationStatus(collaborationId, userId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (res.status === 400) {
    throw new Error("error");
  }
  return res.data?.data as ApiStatus | ApiError;
}

export function useGetUserCollaborationStatus({
  collaborationId,
  userId,
}: {
  collaborationId: number;
  userId: string;
}) {
  return useQuery(["user-collaboration-status", collaborationId, userId], async () => {
    return await getUserCollaborationStatus(collaborationId, userId);
  });
}
