import { useInfiniteQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { GLOBAL_SEARCH_TYPES } from "utils/constants";
import { urls } from "utils/urls";

export async function getSearchResults({
  pageNumber,
  pageLimit,
  searchValue,
  countryId,
  researchAreaId,
  userType,
  targetIndustryId,
  searchType,
  isGlobalSearch,
}: GlobalSearchParams) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  if (searchType === GLOBAL_SEARCH_TYPES.users) {
    const res = await apiClient.get(
      urls.getSearchResults({
        pageNumber,
        pageLimit,
        searchValue,
        countryId,
        researchAreaId,
        userType,
        isGlobalSearch,
      }),
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res?.data?.data as GlobalSearchResult | ApiError;
  } else if (searchType === GLOBAL_SEARCH_TYPES.challenge) {
    const res = await apiClient.get(
      urls.getChallengeResults({
        pageNumber,
        pageLimit,
        searchValue,
        targetIndustryId,
        isGlobalSearch,
      }),
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res?.data?.data as GlobalSearchResult | ApiError;
  }
}

export function useGetSearchResults({
  pageLimit,
  searchValue,
  countryId,
  researchAreaId,
  userType,
  targetIndustryId,
  searchType,
  isGlobalSearch,
}: GlobalSearchParams) {
  return useInfiniteQuery(
    [
      "search-results",
      userType,
      searchValue,
      countryId,
      researchAreaId,
      pageLimit,
      targetIndustryId,
      searchType,
      isGlobalSearch,
    ],
    async ({ pageParam = 1 }) => {
      if (searchValue?.trim()?.length >= 3 || isGlobalSearch) {
        const result = await getSearchResults({
          pageNumber: pageParam,
          pageLimit: pageLimit,
          searchValue: searchValue,
          countryId: countryId,
          researchAreaId: researchAreaId,
          userType: userType,
          targetIndustryId: targetIndustryId,
          searchType: searchType,
          isGlobalSearch: isGlobalSearch,
        });
        return result;
      }
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.totalPages > allPages.length ? allPages?.length + 1 : undefined;
      },
      staleTime: Infinity,
    }
  );
}
