import { useTranslation } from "react-i18next";

type FooterProps = {
  className?: string;
};
export function Footer({ className }: FooterProps) {
  const { t } = useTranslation();

  return (
    <p
      className={` w-full text-center  left-0 opacity-50 text-white font-medium text-xs ${className}`}
    >
      {t("global.copy_right_text")}
    </p>
  );
}
