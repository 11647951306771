import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveOffices(formData: Array<OrganizationOffice>, organizationId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  return await apiClient.put(urls.organizationOffices(organizationId), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export function useSaveOffices() {
  const qc = useQueryClient();
  return useMutation(
    async ({
      formData,
      organizationId,
    }: {
      formData: Array<OrganizationOffice>;
      organizationId: string;
    }) => {
      const userInfo = useUserDataStore.getState().userInfo;
      const userId = organizationId ? organizationId : userInfo?.userId;
      if (userId) {
        const result = await saveOffices(formData, organizationId);
        qc.invalidateQueries({ queryKey: ["organization-details", userId] });
        qc.invalidateQueries({ queryKey: ["organization-offices", userId] });
        return result;
      }
    }
  );
}
