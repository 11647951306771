import React from "react";
import { useTranslation } from "react-i18next";
import Tippy from "@tippyjs/react";
import SVGIcon from "assets/Icons";

type SubTitleProps = {
  title: string;
  className?: string;
  childElement?: React.ReactNode;
  tooltipContent?: string;
};
export function SubTitle({ title, className, childElement, tooltipContent }: SubTitleProps) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center ">
      <h6 className={`flex items-center w-full justify-center ${className}`}>
        <div>
          {title} {childElement ?? null}
          {tooltipContent ? (
            <Tippy
              placement="bottom"
              arrow={true}
              theme="light"
              appendTo="parent"
              allowHTML={true}
              content={
                <span className=" font-medium text-xs max-w-[250px] block">
                  {t(tooltipContent)}
                </span>
              }
            >
              <div className="info !inline-block -mb-1.5 ltr:pl-1.5 rtl:pr-1.5">
                <SVGIcon name="info" />
              </div>
            </Tippy>
          ) : null}
        </div>
      </h6>
    </div>
  );
}
