import { useTranslation } from "react-i18next";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import SVGIcon from "assets/Icons";
import { displayDateFormat, getColumns } from "utils/helpers";

type TableProps = {
  userData: Array<ChallengeUser>;
  headers: Array<string>;
  type: number;
  handleItemClick: (challengeId: number) => void;
};

const Table = ({ userData, headers, type, handleItemClick }: TableProps) => {
  const { t } = useTranslation();

  const filteredData = userData?.map((item) => {
    return {
      createdOn: item.createdOn,
      fullName: item.fullName,
      researchAreas: item.researchAreas,
      country: item.country,
    };
  });

  const dataTable = useReactTable({
    data: userData,
    columns: filteredData && filteredData.length ? getColumns(filteredData) : [],

    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="customTable ">
      <table>
        <thead>
          {headers?.map((item) => (
            <th key={item}>{t(item)}</th>
          ))}
          <th>{t("admin.view")}</th>
        </thead>
        <tbody>
          {dataTable.getRowModel().rows.map((row) => (
            <tr
              className="cursor-pointer"
              key={row.id}
              onClick={() => handleItemClick(row.original?.userId)}
            >
              {row.getVisibleCells().map((cell, index) => {
                if (cell.column.columnDef.header?.toString().trim() == "createdOn") {
                  return <td key={index}>{displayDateFormat(cell.getValue() as string)}</td>;
                } else if (cell.column.columnDef.header?.toString().trim() === "fullName") {
                  return (
                    <td key={index}>
                      <p className="text-primary-medium underline truncate">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </p>
                    </td>
                  );
                } else if (cell.column.columnDef.header?.toString().trim() == "researchAreas") {
                  if (cell.getValue()) {
                    return (
                      <td key={index}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  }
                  return <td key={index}>-</td>;
                } else {
                  return (
                    <td key={index}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  );
                }
              })}
              <td>
                <div className="btn btn-link h-auto min-h-[auto] p-0 text-primary-medium">
                  <SVGIcon name={"eyeCloseV1"} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
