import { useState } from "react";
import {
  Descriptor,
  UploadedFileItem,
  ProfilePicture,
  SubTitle,
  Title,
  InlineLoader,
} from "components";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import Profile from "assets/images/avatar.svg";
import { useGetProfile } from "features/Messages/api/useGetProfileDetails";
import { calculateAge } from "utils/helpers";
import { DETAILS_DISPLAY_MAX_CHAR_LENGTH } from "utils/constants";

type ResearchScholarProfileViewModalProps = {
  onClose: () => void;
  userId: string;
};

export default function ResearchScholarProfileViewModal({
  onClose,
  userId,
}: ResearchScholarProfileViewModalProps) {
  const { t } = useTranslation();
  const [isViewMore, setIsviewMore] = useState(false);
  const { data, isLoading } = useGetProfile(userId);

  return (
    <>
      <div className=" max-w-[100vw] w-[100vw] min-w-[100vw]  md:w-auto md:max-w-[830px] mx-0 md:mx-2  md:min-w-[830px]  bg-white rounded-md rounded-b-none md:rounded-b-md  flex flex-col  relative animate__animated animate__zoomIn max-h-[calc(100vh-50px)] overflow-auto">
        <div className=" flex  border-b border-gray-light relative p-4 lg:p-6">
          <SVGIcon name="personalDetails" />
          <Title
            title={t("forum.research_scholar_profile")}
            className="text-black text-base font-semibold ltr:ml-3 rtl:mr-3"
          />
          <button
            className="absolute btn btn-link text-black p-0 w-auto h-auto min-h-fit ltr:right-6 rtl:left-6 rtl:right-auto"
            onClick={() => onClose && onClose()}
          >
            <SVGIcon name="close" />
          </button>
        </div>
        {data ? (
          <>
            <div className=" flex p-4 lg:p-6 flex-col lg:flex-row">
              <ProfilePicture
                url={data?.profilePicture ? data?.profilePicture : Profile}
                className=" lg:!w-[120px] lg:!h-[120px] lg:min-w-[120px] !w-[60px] !h-[60px] min-w-[60px] researchScholarProfile"
              />
              <div className=" pl-0 pt-4 lg:pt-0 ltr:lg:pl-[22px] rtl:lg:pr-[22px] w-[calc(100%-60px)] lg:w-[calc(100%-120px)]">
                <div className="flex items-center">
                  <Title
                    title={`${data?.title} ${data?.firstName} ${
                      data?.middleName ? data?.middleName : ""
                    } ${data?.lastName}`}
                    className=" text-lg text-black font-semibold"
                  />
                  {data?.isAdvisor ? (
                    <div className=" h-[20px] flex items-center px-3 ltr:!ml-1 rtl:!mr-1 rounded-xl text-xs text-white font-normal bg-primary-medium ">
                      {t("global.advisor")}
                    </div>
                  ) : null}
                </div>
                <Title
                  title={data?.researchInstituteName ?? ""}
                  className=" text-base font-medium text-gray-100 mb-2 -mt-1 WB_BW"
                />
                {data?.hIndex ? (
                  <SubTitle
                    title={`${t("global.h_index")}: ${data?.hIndex}`}
                    className=" text-sm text-gray-500 font-normal !justify-start"
                  />
                ) : null}
                {data?.about ? (
                  <div className="text-black text-sm font-normal max-w-[543px] WB_BW pt-2">
                    {data?.about?.length > DETAILS_DISPLAY_MAX_CHAR_LENGTH && !isViewMore ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data?.about
                            ?.slice(0, DETAILS_DISPLAY_MAX_CHAR_LENGTH)
                            .replace(/\n/g, "<br/>"),
                        }}
                      />
                    ) : (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data?.about.replace(/\n/g, "<br/>"),
                        }}
                      />
                    )}
                    {data?.about?.length > DETAILS_DISPLAY_MAX_CHAR_LENGTH ? (
                      <span
                        onClick={() => setIsviewMore(!isViewMore)}
                        className="  text-primary-medium font-medium underline ml-1.5 cursor-pointer"
                      >
                        {isViewMore ? t("global.view_less") : t("global.view_more")}
                      </span>
                    ) : null}
                  </div>
                ) : null}
                <div className=" flex flex-col space-y-2 lg:space-y-5 pt-[26px]">
                  <div className=" grid grid-cols-2 lg:flex gap-2 lg:gap-0 space-x-0 lg:space-x-10 rtl:space-x-reverse truncate flex-col lg:flex-row">
                    <Descriptor
                      className="!mb-0 flex items-baseline text-black text-sm font-normal space-x-2 WB_BW rtl:space-x-reverse "
                      startIcon="mapPin"
                      title={data?.country}
                    />
                    <Descriptor
                      className="!mb-0 flex items-baseline text-black text-sm font-normal space-x-2 WB_BW rtl:space-x-reverse "
                      startIcon="gender"
                      title={`${data?.gender} , ${
                        data?.dateOfBirth ? calculateAge(data?.dateOfBirth) : ""
                      }`}
                    />
                    <Descriptor
                      className="!mb-0 flex items-baseline text-black text-sm font-normal space-x-2 WB_BW rtl:space-x-reverse col-span-2 profile-view-modal-email-section"
                      startIcon="msg"
                      title={data?.email}
                    />
                  </div>
                  <div className="flex space-y-2 lg:space-y-0 space-x-0 lg:space-x-10 rtl:space-x-reverse flex-col lg:flex-row ">
                    <Descriptor
                      className="!mb-0 flex items-baseline text-black text-sm font-normal space-x-2 WB_BW rtl:space-x-reverse min-w-[160px]"
                      startIcon="phone"
                      title={`${data?.countryCode ?? ""} ${data?.contactNumber}`}
                    />
                    {data?.researchAreas?.length > 0 ? (
                      <Descriptor
                        className="!mb-0 flex items-baseline text-black text-sm font-normal space-x-2 WB_BW rtl:space-x-reverse !whitespace-normal WB_BW"
                        startIcon="location"
                        title={data?.researchAreas.join(", ")}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {data?.attachments?.length > 0 ? (
              <div className=" px-6 pt-2 pb-6">
                <Title title={t("global.attachments")} className=" text-sm leading-4" />
                {data?.attachments?.length > 0 ? (
                  <div className=" grid grid-cols-2 gap-3 mt-2 max-[768px]:grid-cols-1">
                    {data?.attachments?.map((attaches: FileDetailsItem) => (
                      <div className=" flex-1 truncate" key={attaches.id}>
                        <UploadedFileItem isPreview file={attaches} />
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        ) : null}
        {isLoading ? <InlineLoader /> : null}
      </div>
    </>
  );
}
