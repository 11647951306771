import { useTranslation } from "react-i18next";
import { Button } from "react-daisyui";
import SVGIcon from "assets/Icons";
import { useUserDataStore } from "store/useUserDataStore";
import { USERS } from "utils/constants";
import { useMasterDataStore } from "store/useMasterDataStore";

type StatisticsDataCardProps = {
  data: DataCardItem;
  isActive?: boolean;
  className?: string;
  circleColor?: string;
  handleClick: () => void;
  isActiveUsers?: boolean;
  disabled?: boolean;
  subtitle?: string;
  statusCount?: Array<Option>;
  showCount?: boolean;
};

export function StatisticsDataCard({
  data,
  isActive,
  circleColor,
  className,
  handleClick,
  isActiveUsers,
  disabled,
  subtitle,
  statusCount,
  showCount,
}: StatisticsDataCardProps) {
  const { t } = useTranslation();
  const { userInfo } = useUserDataStore();
  const { permissions } = useMasterDataStore();

  const getIconName = () => {
    switch (data.type) {
      case "Research Scholar":
        return "research";
      case "Organization":
        return "organizations";
      case "Funding Organization":
        return "funding";
      case "Research Institute":
        return "institutes";
      case "Pending":
        return "pending";
      case "Active":
        return "active";
      case "Disabled":
        return "disabled";
      case "Pending approval":
        return "pending";
      case "Accepted scholars":
      case "Nominated scholars":
        return "acceptedScholars";
      case "Funding Organizations":
        return "fundingOrganizations";
      case "Users":
        return "usersIcon";
      case "Challenges":
        return "challengeIcon";
      case "Discussion forums":
        return "discussionIcon";
      case "Invited":
        return "invitedIcon";
      default:
        return "";
    }
  };

  const getActiveClassName = () => {
    switch (data.type) {
      case "Research Scholar":
        return "active-research";
      case "Organization":
        return "active-organizations";
      case "Funding Organization":
        return "active-funding";
      case "Research Institute":
        return "active-institutes";
      case "Pending":
        return "pending";
      case "Active":
      case "Nominated scholars":
        return "active";
      case "Disabled":
        return "disabled";
      case "Pending approval":
        return "pending";
      case "Accepted scholars":
        return "active";
      case "Funding Organizations":
        return "active-institutes";
      case "Users":
        return "users";
      case "Challenges":
        return "challenges";
      case "Discussion forums":
        return "discussion-forums";
      case "Invited":
        return "invited";
      default:
        return "";
    }
  };

  const getTitle = () => {
    switch (data.type) {
      case "Research Scholar":
        return "research_scholar.research_scholar_title";
      case "Organization":
        return "global.organization";
      case "Funding Organization":
        return "funding_organization.funding_organization";
      case "Research Institute":
        return "global.institutes";
      case "Pending":
        return "global.pending";
      case "Active":
        return "global.active";
      case "Disabled":
        return "global.disabled";
      case "Pending approval":
        return "global.pending_approval";
      case "Accepted scholars":
        return "global.accepted_scholars";
      case "Funding Organizations":
        return "organization.funding_interests";
      case "Users":
        return "admin.users";
      case "Challenges":
        return "admin.challenges";
      case "Discussion forums":
        return "organization.messages";
      case "Nominated scholars":
        return "research_institute.nominated_scholars";
      case "Invited":
        return "research_institute.invited";
      default:
        return "";
    }
  };
  const getSubTitle = () => {
    switch (data.type) {
      case "Research Scholar":
        return "research_scholar.research_scholar_title";
      case "Organization":
        return "global.organization";
      case "Funding Organization":
        return "funding_organization.funding_organization";
      case "Research Institute":
        return "global.institutes";
      case "Pending":
        return "global.pending";
      case "Active":
        return "global.active";
      case "Disabled":
        return "global.disabled";
      case "Pending approval":
        return "global.pending_approval";
      case "Accepted scholars":
        return "global.accepted_scholars";
      case "Funding Organizations":
        return "global.funding_organizations";
      case "Users":
        return "admin.users";
      case "Challenges":
        return "admin.challenges";
      case "Discussion forums":
        return "organization.unread";
      case "Nominated scholars":
        return "research_institute.nominated_scholars";

      default:
        return "";
    }
  };

  const displayCard = () => {
    if (
      (userInfo?.userType === USERS.Organization &&
        getTitle() === "global.pending_approval" &&
        !permissions?.Viewchallengerequests) ||
      (userInfo?.userType === USERS.Organization &&
        getTitle() === "global.accepted_scholars" &&
        !permissions?.Viewapprovedscholars) ||
      (userInfo?.userType === USERS.Organization &&
        getTitle() === "organization.funding_interests" &&
        !permissions?.ViewfundingorganizationInterests) ||
      (userInfo?.userType === USERS.Organization &&
        getTitle() === "organization.funding_interests" &&
        userInfo?.isGlobal) ||
      (userInfo?.userType === USERS.ResearchInstitute &&
        getTitle() === "research_institute.nominated_scholars" &&
        !permissions?.Viewnominatedscholars) ||
      (userInfo?.userType === USERS.ResearchInstitute &&
        getTitle() === "global.pending" &&
        !permissions?.Viewpendingscholars)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return displayCard() ? (
    <div
      className={`bg-white relative border border-white rounded-md cursor-pointer ${
        isActive ? getActiveClassName() : ""
      } ${disabled ? " tile-disabled" : ""}`}
    >
      <div className="bg-white rounded-md w-full z-10">
        <div
          className={`p-4 flex flex-col rounded-md justify-between min-h-[118px] shadow-100 ${
            className ? className : ""
          }`}
          onClick={handleClick}
        >
          <div className={`flex justify-start items-center font-normal relative z-10 `}>
            <h3 className="text-primary-light  leading-5 font-medium text-base">{t(getTitle())}</h3>
          </div>
          <div className="flex justify-between items-center mt-auto relative z-10">
            {getIconName() ? <SVGIcon name={getIconName()} /> : null}
            <div className=" flex flex-col ltr:mr-auto rtl:ml-auto ltr:pl-[14px] rtl:pr-[14px]">
              <h3 className=" font-normal text-3xl text-black">{data.value}</h3>
              {isActiveUsers ? (
                <p className=" text-gray-200 text-xs -mt-1">
                  {subtitle ? t(getSubTitle()) : t("admin.active_users")}
                </p>
              ) : null}
            </div>
            <div className=" flex flex-col startUp">
              {statusCount && statusCount.length > 0 && (showCount || data.type === "Active")
                ? statusCount.map((item, index) => {
                    return (
                      <p
                        className=" text-primary-light text-sm leading-5 flex items-center"
                        key={index}
                      >
                        <span className="min-w-[60px]">{t(item.label)} </span>
                        <span className=" text-black min-w-[15px] text-right">{item.value}</span>
                      </p>
                    );
                  })
                : null}
            </div>
            {isActiveUsers ? (
              <Button
                className={`p-0 rtl:rotate-180  btn min-h-fit  w-5 h-5 !bg-transparent !border-none flex justify-center items-center ${circleColor}`}
              >
                <SVGIcon name="rightRoundArrow" />
              </Button>
            ) : null}
            {isActive && !isActiveUsers ? (
              <Button
                className={`p-0 btn min-h-fit  w-5 h-5 !bg-transparent !border-none flex justify-center items-center`}
              >
                <SVGIcon name="downRoundArrow" />
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
