import { create } from "zustand";
import { produce } from "immer";

export type FundingOrganizationDataStore = {
  fundingOrganizationInfo?: FundingOrganizationDetails | undefined;
};

export const useFundingOrganizationDataStore = create<FundingOrganizationDataStore>()((set) => ({
  fundingOrganizationInfo: undefined,
}));

export function setFundingOrganizationInfo(fundingOrganizationInfo: FundingOrganizationDetails) {
  useFundingOrganizationDataStore.setState(
    produce((state: FundingOrganizationDataStore) => {
      state.fundingOrganizationInfo = fundingOrganizationInfo;
    })
  );
}

export function setFundingOrganizationInfoItem(
  value: string | number | OptionItem[] | null,
  key: keyof FundingOrganizationDetails
) {
  useFundingOrganizationDataStore.setState(
    produce((state: any) => {
      if (state.fundingOrganizationInfo) {
        state.fundingOrganizationInfo[key] = value;
      }
    })
  );
}

export function clearFundingOrganizationDataStore() {
  useFundingOrganizationDataStore.setState(
    produce((state: FundingOrganizationDataStore) => {
      state.fundingOrganizationInfo = undefined;
    })
  );
}
