import { useQuery } from "@tanstack/react-query";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getStatistics(isGlobal: boolean) {
  const res = await apiClient.get(urls.statistics(isGlobal));
  return res?.data?.data as StatisticsData | ApiError;
}

export function useGetStatistics(isGlobal: boolean) {
  return useQuery(
    ["statistics-data"],
    async () => {
      return await getStatistics(isGlobal);
    },
    {
      staleTime: Infinity,
    }
  );
}
