export const challengeEditorValidation = (
  challengeLearnMore: string | null | undefined
): boolean => {
  try {
    if (!challengeLearnMore) return true;
    const learnMore = JSON.stringify(JSON.parse(challengeLearnMore));
    const jsonObject = JSON.parse(learnMore);
    if (jsonObject.blocks && jsonObject.blocks.length > 0) {
      const result = jsonObject.blocks.some((item: any) => item?.text?.trim().length > 0);
      return result;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};
