import { useInfiniteQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getCollaborationList({
  pageNumber,
  pageLimit,
  userId,
  status,
  type,
  researchArea,
  isOwned,
  isExplore,
}: CollaborationListParams) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(
    urls.collaborationList({
      pageNumber,
      pageLimit,
      userId,
      status,
      type,
      researchArea,
      isOwned,
      isExplore,
    }),
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return res?.data?.data as CollaborationListingResponse | ApiError;
}

export function useGetCollaborationList({
  status,
  type,
  pageLimit,
  researchArea,
  isOwned,
  isExplore,
}: CollaborationListParams) {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;
  return useInfiniteQuery(
    ["collaboration-list", userId, status, type, researchArea, isOwned, isExplore],
    async ({ pageParam = 1 }) => {
      const result = await getCollaborationList({
        pageNumber: pageParam,
        pageLimit: pageLimit,
        userId: userId ?? "",
        status: status,
        type: type,
        researchArea: researchArea,
        isOwned: isOwned,
        isExplore: isExplore,
      });
      return result;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.totalPages > allPages.length ? allPages?.length + 1 : undefined;
      },
      staleTime: Infinity,
    }
  );
}
