import { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Sidebar } from "components";
import {
  setSelectedSideMenu,
  setSelectedUserType,
  useSidebarDataStore,
} from "store/useSidebarStore";
import { useUserDataStore } from "store/useUserDataStore";
import { USER_STATUS } from "utils/constants";
import { clearStores } from "store";
import { hideSidebarMenuItem } from "utils/helpers";
import { useMasterDataStore } from "store/useMasterDataStore";
import {
  clearFilterDataStore,
  clearUserTypeFilter,
  setAdvisorFilterInfo,
} from "store/useFilterDataStore";

function Organization() {
  const navigate = useNavigate();
  const { permissions } = useMasterDataStore();
  const { userInfo } = useUserDataStore();
  const { selectedSideMenu } = useSidebarDataStore();
  const [isOpenSideMenu, setIsOpenSideMenu] = useState(true);
  const pathName = window.location.pathname;
  const queryClient = useQueryClient();

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const SidebarMenuItems: Array<SidebarMenuItem> = useMemo(() => {
    const tempItems = [];
    if (userInfo && hideSidebarMenuItem(userInfo?.profileStatusId)) {
      tempItems.push({
        name: "global.settings",
        icon: "settings",
        subMenu: [
          {
            name: "global.profile",
          },
          {
            name: "global.password",
          },
        ],
      });
    } else {
      tempItems.push({
        name: "global.dashboard",
        icon: "dashboard",
      });
      tempItems.push({
        name: "admin.challenges",
        icon: "challenge",
      });
      if (userInfo?.isGlobal) {
        tempItems.push({
          name: "global.collaborations",
          icon: "collaborationIcon",
        });
      }
      if (permissions?.Viewprivatereplies) {
        tempItems.push({
          name: "organization.messages",
          icon: "messageV1",
        });
      }
      tempItems.push({
        name: "global.events",
        icon: "eventIcon",
      });
      tempItems.push({
        name: "global.settings",
        icon: "settings",
        subMenu: [
          {
            name: "admin.users",
          },
          {
            name: "global.profile",
          },
          {
            name: "global.password",
          },
        ],
      });
    }
    return tempItems;
  }, [userInfo, permissions?.Viewprivatereplies]);

  const handleIsOpen = (type: string) => {
    if (selectedSideMenu?.type === type) {
      return !selectedSideMenu.isOpen;
    } else {
      return true;
    }
  };

  const handleMenuClick = (type: string) => {
    clearFilterDataStore();
    clearUserTypeFilter();
    setSelectedUserType();
    setAdvisorFilterInfo({ isAdvisor: false });
    if (type === "admin.challenges") {
      queryClient.resetQueries(["challenge-list"]);
      navigate("/organization/challenges");
    } else if (type === "organization.messages") {
      navigate("messages");
    } else if (type === "global.dashboard") {
      navigate("/organization/dashboard");
    } else if (type === "global.events") {
      queryClient.resetQueries(["event-list"]);
      navigate("events");
    } else if (type === "global.collaborations") {
      queryClient.resetQueries(["collaboration-list"]);
      navigate("collaborations");
    }
    setSelectedSideMenu({
      type,
      isOpen: handleIsOpen(type),
      subMenuType: type === "global.settings" ? selectedSideMenu?.subMenuType : null,
    });
  };

  const handleSubMenuClick = (type: string) => {
    clearFilterDataStore();
    clearUserTypeFilter();
    if (selectedSideMenu?.type) {
      setSelectedSideMenu({
        type: selectedSideMenu?.type,
        isOpen: true,
        subMenuType: type,
      });
    }
    if (type === "global.password") {
      navigate("settings/change-password", { replace: true });
    } else if (type === "admin.users") {
      navigate("settings/users", { replace: true });
    } else if (type === "global.profile") {
      navigate(`settings/profile/${userInfo?.userId}`, { replace: true });
    }
  };

  useEffect(() => {
    if (userInfo?.profileStatusId === USER_STATUS.Disabled) {
      clearStores();
      navigate("/login");
    }
    if (pathName === "/organization/settings/users") {
      setSelectedSideMenu({
        type: "global.settings",
        isOpen: true,
        subMenuType: "admin.users",
      });
    } else if (pathName === "/organization/challenges") {
      setSelectedSideMenu({
        type: "admin.challenges",
        isOpen: false,
      });
    } else if (pathName === "/organization/dashboard") {
      setSelectedSideMenu({
        type: "global.dashboard",
        isOpen: false,
      });
    } else if (pathName === "/organization/events") {
      setSelectedSideMenu({
        type: "global.events",
        isOpen: false,
      });
    }
  }, [userInfo, pathName, navigate]);
  return (
    <div className={`flex  min-h-screen   ${!isOpenSideMenu ? "miniSidebar" : ""}`}>
      <Sidebar
        menuItems={SidebarMenuItems}
        handleCollapseClick={onToggleClick}
        handleMenuClick={handleMenuClick}
        handleSubMenuClick={handleSubMenuClick}
      />
      <div className="w-full overflow-auto items-baseline mainContainer min-h-[calc(100vh-76px)] flex lg:rtl:mr-[250px] lg:ltr:ml-[250px] flex-col">
        <Outlet />
      </div>
    </div>
  );
}

export default Organization;
