import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showConfirmationModal, showStatusChangeConfirmationModal } from "utils/confirmationModals";
import { getBreadCrumbTitle, handleDisableOrEnableNavigation } from "utils/helpers";
import { useManageRequest } from "features/Admin/api/useManageResearchScholarRequests";
import { useGetResearchScholarDetails } from "features/Admin/api/useGetResearchScholarDetails";
import { LoaderModal, Header, ScholarProfileDetails } from "components";
import { useSendResetPasswordLink } from "features/Admin/api/useSendResetPasswordLink";
import { RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS, USERS, USER_STATUS } from "utils/constants";
import Navigator from "features/Admin/Users/Navigator";
import {
  showErrorToastMessage,
  showInfoToastMessage,
  showSuccessToastMessage,
  ToasterIconType,
} from "utils/toasterMessage";
import { useStickyScroll } from "hooks/useStickyScroll";
import { setSelectedSideMenu } from "store/useSidebarStore";
import { useUserDataStore } from "store/useUserDataStore";

export default function ResearchScholarInfo() {
  const { t } = useTranslation();
  const { userInfo } = useUserDataStore();
  const { researchScholarId } = useParams();
  const { scroll } = useStickyScroll();
  const navigate = useNavigate();
  const personalDetailsRef = useRef(null);
  const educationRef = useRef(null);
  const workRef = useRef(null);
  const socialRef = useRef(null);
  const researchProjectRef = useRef(null);
  const pathname = window.location.pathname;
  const isSearchResult = pathname.includes("search-results");
  const { mutateAsync: manageRequest, isLoading } = useManageRequest();
  const { mutateAsync: resetLinkPassword, isLoading: isResetLinkLoading } =
    useSendResetPasswordLink();
  const { data: userData, isLoading: userDataLoading } = useGetResearchScholarDetails(
    researchScholarId ?? ""
  );
  const [isViewMore, setIsViewMore] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState<number>(0);

  const handleDeclineAndRemove = async () => {
    const result = await showConfirmationModal({
      title: "global.confirm_decline",
      message: "global.decline_confirmation_message",
      cancelButtonText: "global.close",
      proceedButtonText: "global.decline",
      isAlert: true,
      modalIcon: "confirmDelete",
    });
    if (!result) {
      return;
    }
    if (result) {
      try {
        const response = await manageRequest({
          scholarId: researchScholarId ?? "",
          statusId: USER_STATUS.Declined,
        });
        if (response) {
          navigate(-1);
          showErrorToastMessage({
            message: response.message,
            iconType: ToasterIconType.DECLINED,
          });
        }
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
        if (error.response.data.status === 409) {
          navigate(-1);
          showErrorToastMessage({
            message:
              error?.response?.data?.status < 500
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
        }
      }
    }
  };

  const handleDeclineWithReason = async () => {
    const isConfirm = await showStatusChangeConfirmationModal({
      title: t("global.confirm_decline"),
      message: t("admin.decline_with_reason_modal_content"),
      userId: researchScholarId ?? "",
      status: USER_STATUS.DeclineWithReason,
      userType: USERS.ResearchScholar,
    });
    if (!isConfirm) {
      return;
    } else {
      navigate(-1);
    }
  };

  const handleApprove = async () => {
    try {
      const result = await manageRequest({
        scholarId: researchScholarId ?? "",
        statusId: USER_STATUS.Approved,
      });
      if (result) {
        navigate(-1);
        showInfoToastMessage({
          iconType: ToasterIconType.APPROVED,
          message: result.message,
        });
      }
    } catch (error: ApiError) {
      showErrorToastMessage({
        message:
          error?.response?.data?.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
      if (error.response.data.status === 409) {
        navigate(-1);
      }
    }
  };

  const linkList: Array<LinkItem> = useMemo(() => {
    const items: Array<LinkItem> = isSearchResult
      ? [
          {
            title: "global.search_results",
          },
          {
            title: "global.view_profile",
          },
        ]
      : userInfo?.userType === USERS.Admin
      ? [
          {
            title: "admin.users",
          },
          {
            title: "admin.research_scholars",
          },
          {
            title: getBreadCrumbTitle(userData?.userprofile?.profileStatusId),
          },
        ]
      : [
          {
            title: "admin.research_scholars",
          },
          {
            title: getBreadCrumbTitle(userData?.userprofile?.profileStatusId),
          },
        ];

    return items;
  }, [userData, isSearchResult]);

  const navigationItems: Array<RecordItem> = useMemo(() => {
    const tempItems = [
      {
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Personal,
        label: "global.personal_details",
      },
      {
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Education,
        label: "global.highest_education",
      },
    ];
    if (userData?.userwork.notWorking || userData?.userwork.details.length > 0) {
      tempItems.push({
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Work,
        label: "global.work_experience",
      });
    }
    if (
      userData?.usersocialprofile?.googleScholarURL ||
      userData?.usersocialprofile?.linkedInURL ||
      userData?.usersocialprofile?.researchGateURL
    ) {
      tempItems.push({
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Social,
        label: "global.social_profiles",
      });
    }
    if (userData?.researchprojects?.length) {
      tempItems.push({
        value: RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Research,
        label: "global.research_projects",
      });
    }
    return tempItems;
  }, [userData]);

  const handleScroll = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 100,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const handleNavigationClick = (value: number, index: number) => {
    setActiveMenu(index);
    if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Personal) {
      handleScroll(personalDetailsRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Education) {
      handleScroll(educationRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Work) {
      handleScroll(workRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Social) {
      handleScroll(socialRef);
    } else if (value === RESEARCH_SCHOLARS_PROFILE_COMPLETION_STATUS.Research) {
      handleScroll(researchProjectRef);
    }
  };

  const navigateSection = (isNavigate: boolean) => {
    if (!isNavigate) {
      return;
    } else {
      const navigateTo =
        userInfo?.userType === USERS.Admin
          ? "/admin/users/research-scholar"
          : "/research-institute/researchers";
      handleDisableOrEnableNavigation(isSearchResult, navigate, navigateTo);
    }
  };

  const handleSettingsMenuClick = async (type: string) => {
    if (type === "disable") {
      const isConfirm = await showStatusChangeConfirmationModal({
        title: t("admin.disable_this_user"),
        message: t("admin.this_action_will_disable_the_user_from_the_platform"),
        userId: researchScholarId ?? "",
        status: USER_STATUS.Disabled,
        userType: USERS.ResearchScholar,
      });
      navigateSection(isConfirm);
    } else if (type === "enable") {
      const isConfirm = await showStatusChangeConfirmationModal({
        title: t("admin.enable_this_user"),
        message: t("admin.enable_a_user_confirmation_message"),
        userId: researchScholarId ?? "",
        status: USER_STATUS.Approved,
        userType: USERS.ResearchScholar,
      });
      navigateSection(isConfirm);
    } else if (type === "reset") {
      const result = await showConfirmationModal({
        title: "global.reset_password",
        message: "admin.reset_password_link_message",
        cancelButtonText: "global.close",
        proceedButtonText: "global.proceed",
        modalIcon: "resetPasswordV1",
      });
      if (!result) {
        return;
      } else {
        try {
          const result = await await resetLinkPassword(researchScholarId ?? "");
          showSuccessToastMessage({
            message: result.message,
          });
        } catch (error: ApiError) {
          showErrorToastMessage({
            message:
              error?.response?.data?.status < 500
                ? error.response.data.message
                : t("global.something_went_wrong"),
          });
        }
      }
    } else if (type === "update") {
      navigate("profile");
    }
  };

  const handleViewClick = () => {
    setIsViewMore(!isViewMore);
    handleScroll(personalDetailsRef);
  };

  useEffect(() => {
    let type = "admin.users";
    if (pathname.includes("researchers")) {
      type = "home.researchers";
    }

    setSelectedSideMenu({
      type,
    });
  }, [userInfo?.userType, pathname]);

  return (
    <>
      <Header
        title={t("global.profile")}
        linkList={linkList}
        className={
          scroll
            ? "  bg-primary-medium lg:bg-white animate__animated animate__fadeInDown lg:shadow-sticky lg:!py-[26px] lg:!h-[90px] fixed stickyHeader mobile-sticky-header"
            : "fixed bg-primary-medium lg:bg-gray-light"
        }
      />
      <div
        className="adminDetails w-full mt-[170px] mb-20 lg:mb-0 lg:mt-0 lg:pt-[118px] pb-[45px] items-baseline px-4 lg:px-10 lg:min-h-screen flex flex-col  overflow-auto lg:ltr:pr-[30px] lg:rtl:pl-[30px]"
        ref={personalDetailsRef}
      >
        <ScholarProfileDetails
          userData={userData}
          researchProjectRef={researchProjectRef}
          handleSettingsMenuClick={handleSettingsMenuClick}
          isViewMore={isViewMore}
          handleViewClick={handleViewClick}
          educationRef={educationRef}
          workRef={workRef}
          socialRef={socialRef}
          showSettingsMenu={true}
          isCollaborationView={false}
        />
        <Navigator
          isButtonVisible={
            userData?.userprofile?.profileStatusId === USER_STATUS?.PendingApproval &&
            (userInfo?.userType === USERS.Admin || userInfo?.userType === USERS.ResearchInstitute)
          }
          handleDeclineWithReason={handleDeclineWithReason}
          handleDecline={handleDeclineAndRemove}
          handleApprove={handleApprove}
          handleNavigationClick={(value: number, index: number) =>
            handleNavigationClick(value, index)
          }
          navigationItems={navigationItems}
          scroll={scroll}
          activeMenu={activeMenu}
          isDeclineWithReason={true}
        />
        {isLoading || userDataLoading || isResetLinkLoading ? <LoaderModal /> : null}
      </div>
    </>
  );
}
