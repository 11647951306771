import { useTranslation } from "react-i18next";

type NoDataProps = {
  message: string;
};

export default function NoData({ message }: NoDataProps) {
  const { t } = useTranslation();
  return <div className=" bg-gray-111 rounded-md w-full h-[60px] text-primary-light text-sm font-medium flex items-center justify-center">{t(message)}</div>;
}
