import React, { useState, useEffect } from "react";
import SVGIcon from "assets/Icons";

type DataInsightsItemProps = {
  iconName: string;
  value: number;
  label: string;
  inView: boolean;
};

function DataInsightsItem({ iconName, value, label, inView }: DataInsightsItemProps) {
  const getFormattedValue = (count: string) => {
    const convertedValue = Number(count);
    if (convertedValue === 1) {
      return convertedValue;
    } else if (convertedValue > 1000) {
      const tempValue = convertedValue - 1;
      const newValue = Math.floor(tempValue / 1000);
      if (newValue > 1) {
        return `${newValue}k`;
      } else {
        return tempValue;
      }
    } else {
      return convertedValue - 1;
    }
  };

  const duration = "2";
  const [count, setCount] = useState("0");

  useEffect(() => {
    if (inView) {
      let start = 0;
      const end = parseInt(value.toString().substring(0, 3));

      if (start === end) return;

      // find duration per increment
      const totalMilSecDur = parseInt(duration);
      const incrementTime = (totalMilSecDur / end) * 1000;

      // timer increments start counter
      // then updates count
      // ends if start reaches end
      const timer = setInterval(() => {
        start += 1;
        setCount(String(start) + value.toString().substring(3));
        if (start === end) clearInterval(timer);
      }, incrementTime);
    }
  }, [value, duration, inView]);

  return (
    <li className=" space-y-2.5 flex flex-col justify-center items-center border border-skin-primaryExtraLight rounded-xl p-5 pb-[34px] md:p-[26px] xl:p-6 xl:pb-6">
      <SVGIcon name={iconName} />
      <h1 className=" text-white font-semibold text-[32px] leading-9 md:text-[40px] md:leading-[44px] ">
        {value === 1 ? value : null}
        {value !== 1 ? getFormattedValue(count) : null}
        {value > 1 ? <span className=" font-medium">+</span> : null}
      </h1>

      <h6 className=" text-center uppercase text-white font-medium text-xs leading-[18px] md:text-sm md:tracking-[0.14px] md:leading-[21px] md:font-normal">
        {label}
      </h6>
    </li>
  );
}

export default DataInsightsItem;
