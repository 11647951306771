import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { USER_STATUS } from "utils/constants";
import { urls } from "utils/urls";

async function saveResearchInstituteDetails(formData: ResearchInstituteDetails, userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  if (formData.profileStatusId === USER_STATUS.New) {
    return await apiClient.post(urls.researchInstituteDetails(), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    return await apiClient.put(urls.researchInstitute(userId), formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}

export function useSaveResearchInstituteDetails() {
  const qc = useQueryClient();
  return useMutation(
    async ({
      formData,
      researchInstituteId,
    }: {
      formData: ResearchInstituteDetails;
      researchInstituteId: string;
    }) => {
      const userInfo = useUserDataStore.getState().userInfo;
      const userId = researchInstituteId ? researchInstituteId : userInfo?.userId;
      if (userId) {
        const result = await saveResearchInstituteDetails(formData, userId);
        qc.invalidateQueries({ queryKey: ["research-institute-details", userId] });
        if (formData.profileStatusId === USER_STATUS.New) {
          qc.invalidateQueries({ queryKey: ["research-institute-contacts", userId] });
          qc.invalidateQueries({ queryKey: ["research-institute-offices", userId] });
        }
        return result;
      }
    }
  );
}
