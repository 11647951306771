import { DatePickerField, DividerWithText, InputField, PrimaryButton, TextArea } from "components";
import { useTranslation } from "react-i18next";
import { DETAIL_DESCRIPTION_MAX_CHAR_LENGTH, EMAIL_MAX_CHAR_LENGTH } from "utils/constants";

type MilestoneFormProps = {
  data: MileStone;
  index: number;
  validationErrors: Record<string, string> | undefined;
  eventHandlers: {
    handleDateInputChange: (key: keyof MileStone, index: number) => (date: string) => void;
    handleInputChange: (
      event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
      key: keyof MileStone,
      index: number
    ) => void;
    handleDeleteMilestone: (index: number) => void;
  };
  isDisabled?: boolean;
};

export default function MilestoneForm({
  data,
  index,
  validationErrors,
  eventHandlers,
  isDisabled
}: MilestoneFormProps) {
  const { t } = useTranslation();

  return (
    <div className="px-4 lg:px-5 pt-0 pb-6">
      <DividerWithText text={`${t("organization.milestone")} ${index + 1}`} />

      <div className="flex w-full gap-3 mt-6">
        <div className="w-full">
          <DatePickerField
            labelText={t("global.start_date")}
            selectedDate={data.startDate}
            setSelectedDate={eventHandlers.handleDateInputChange("startDate", index)}
            errorMessage={validationErrors?.startDate}
            showFutureDate={true}
            isDisabled={isDisabled}
            isRequired
            maxDate={data.endDate}
          />
        </div>
        <div className="w-full">
          <DatePickerField
            labelText={t("global.end_date")}
            selectedDate={data.endDate}
            setSelectedDate={eventHandlers.handleDateInputChange("endDate", index)}
            errorMessage={validationErrors?.endDate}
            showFutureDate={true}
            isDisabled={isDisabled}
            isRequired
            minDate={data.startDate}
          />
        </div>
      </div>
      <div className="mt-[22px]">
        <InputField
          value={data.milestone}
          hintText={t("organization.milestone_title_placeholder")}
          labelText={""}
          handleInputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            eventHandlers.handleInputChange(event, "milestone", index);
          }}
          fieldName="title"
          errorMessage={validationErrors?.milestone}
          maxCharLength={EMAIL_MAX_CHAR_LENGTH}
          isDisabled={isDisabled}
        />
      </div>
      <div className="mt-[22px]">
        <TextArea
          textContent={data.description}
          handleChange={(event) => eventHandlers.handleInputChange(event, "description", index)}
          labelText={t("organization.milestone_description")}
          hintText={t("organization.milestone_description_placeholder")}
          fieldName="summary"
          maxCharLength={DETAIL_DESCRIPTION_MAX_CHAR_LENGTH}
          errorMessage={validationErrors?.description}
          isDisabled={isDisabled}
          isRequired
        />
      </div>

      <div className="flex justify-center items-center mt-6">
        <PrimaryButton
          title={"organization.remove_milestone"}
          handleClick={() => {
            eventHandlers.handleDeleteMilestone(index);
          }}
          startIcon="trashV1"
          className=" !h-10 !min-h-0 !min-w-[150px] !rounded-sm !mb-8 !text-xs !px-3 font-medium bg-white !text-red-dark !border-red-dark mx-auto hover:!bg-red-dark/10 hover:!border-red-dark/30"
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}
