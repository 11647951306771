import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getCollaborationPreviewDetails(collaborationId: number, isExplore: boolean) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.collaborationPreview(collaborationId, isExplore), {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (res.status === 400) {
    throw new Error("error");
  }
  return res.data?.data as CollaborationPreview | ApiError;
}

export function useGetCollaborationPreviewDetails(collaborationId: number, isExplore: boolean) {
  return useQuery(
    ["collaboration-preview-details", collaborationId],
    async () => {
      if (collaborationId) {
        const result = await getCollaborationPreviewDetails(collaborationId, isExplore);
        return result;
      } else return;
    },
    {
      retry: false,
    }
  );
}
