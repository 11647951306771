import Joi from "joi";
import { EMAIL_VALIDATION_PATTERN } from "utils/constants";

const systemConfiguration = Joi.object({
  supportEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .pattern(EMAIL_VALIDATION_PATTERN)
    .trim()
    .required()
    .messages({
      "string.empty": "required",
      "string.email": "global.invalid_email_error_message",
      "string.pattern.base": "global.invalid_email_error_message",
    }),
  organizationUserLimit: Joi.number().integer().strict().min(1).max(20).messages({
    "number.base": "required",
    "number.min": "admin.invalid_system_organization_message",
    "number.max": "admin.invalid_system_organization_message",
    "number.integer": "admin.invalid_system_organization_message",
  }),
});

export const systemConfigurationValidation = (basicFormData: SystemConfigurationFields) => {
  const result = systemConfiguration.validate(basicFormData, { abortEarly: false });
  if (result?.error?.details) {
    const errors: Record<string, string> = {};
    result.error.details.forEach((error) => {
      errors[error.context?.key || ""] = error.message;
    });
    return errors;
  }
  return {};
};
