import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function saveCollaborationMilestone(
  mileStoneFormData: CollaborationMileStone,
  collaborationId: number
) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;

  const response = await apiClient.post(
    urls.collaborationMilestones(collaborationId),
    mileStoneFormData,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data as ApiStatus | ApiError;
}

export function useSaveCollaborationMilestone() {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      mileStoneFormData,
      collaborationId,
    }: {
      mileStoneFormData: CollaborationMileStone;
      collaborationId: number;
    }) => {
      const result = await saveCollaborationMilestone(mileStoneFormData, collaborationId);
      queryClient.invalidateQueries(["collaboration-about", collaborationId]);
      queryClient.invalidateQueries(["collaboration-milestones", collaborationId]);
      queryClient.invalidateQueries(["collaboration-preview-details", collaborationId]);
      return result;
    }
  );
}
