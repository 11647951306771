import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { Descriptor, LoaderModal, NoData } from "components";
import { useGetChallengeContacts } from "features/Organization/api/useGetChallengeContacts";
import { useMasterDataStore } from "store/useMasterDataStore";
import { setContactMasterDataValue } from "utils/helpers";
import { useGetCollaborationContacts } from "features/Collaboration/api/useGetCollaborationContacts";

type ContactDetailsProps = {
  collaborationId: number;
  onClose: () => void;
};
const CollaborationContactDetails = ({ collaborationId, onClose }: ContactDetailsProps) => {
  const { t, i18n } = useTranslation();
  const { data: contactsDetails, isLoading } = useGetCollaborationContacts(collaborationId);
  const { countryCode } = useMasterDataStore();

  const countryCodeOptions = useMemo(() => {
    return countryCode?.map((item) => {
      return setContactMasterDataValue(item);
    });
  }, [countryCode, i18n.language]);

  const getContactNumber = (contact: ChallengeContact) => {
    const countryCode = countryCodeOptions?.find(
      (country) => country.value === parseInt(contact.countryCodeId)
    );
    return countryCode?.label + "-" + contact.contactNumber;
  };

  return (
    <div className="max-w-[100vw] w-[100vw] min-w-[100vw]  lg:w-auto lg:max-w-[574px] lg:min-w-[574px]   bg-white rounded-md border-white flex flex-col justify-center  animate__animated animate__zoomIn">
      <div className="flex justify-between px-6 py-[26px] border-b !border-gray-light">
        <div className="flex gap-3 items-center">
          <span>
            <SVGIcon name="contactCard" />
          </span>
          <p className="font-semibold text-base text-black">{t("organization.contact_details")}</p>
        </div>
        <button className=" text-primary-medium" onClick={onClose}>
          <SVGIcon name="closeCurrentColor" />
        </button>
      </div>
      {contactsDetails?.length === 0 ? <NoData message="admin.no_data_to_show" /> : null}
      <div className="overflow-y-auto max-h-[400px] grid grid-cols-2 gap-5 px-10 mt-10 pb-6">
        {contactsDetails?.contacts.map((contact: Contact) => (
          <div
            key={contact.id}
            className="border border-[#DFE6EB] rounded-md flex justify-center flex-col p-6 w-full"
          >
            <div className="flex justify-center items-center"></div>
            <h4 className="font-semibold text-lg text-black w-full truncate">{contact.fullName}</h4>
            <p className="text-gray-102 text-sm font-normal truncate">{contact.designation}</p>
            <div className=" mt-[10px] truncate">
              <Descriptor
                title={contact.email}
                startIcon="previewMail"
                className="text-sm font-normal text-black flex items-center gap-2 truncate"
              />
            </div>
            <div className=" mt-[10px] truncate">
              <Descriptor
                title={getContactNumber(contact)}
                startIcon="phone"
                className="text-sm font-normal text-black flex items-center gap-2 min-w-[160px] truncate"
              />
            </div>
          </div>
        ))}
      </div>
      {isLoading ? <LoaderModal /> : null}
    </div>
  );
};
export default CollaborationContactDetails;
