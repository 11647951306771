import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { USERS } from "utils/constants";
import { urls } from "utils/urls";

async function changeUserStatus(userId: string, status: number, specificReason?: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const formData = new FormData();
  formData.append("specificReason", specificReason ?? "");
  const token = tokenInfo?.token;
  const response = await apiClient.put(urls.changeUserStatus(userId, status), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data as ApiStatus | ApiError;
}

export function useChangeUserStatus() {
  const qc = useQueryClient();
  return useMutation(
    async ({
      userId,
      status,
      specificReason,
      userType,
    }: {
      userId: string;
      status: number;
      specificReason?: string;
      userType: number;
    }) => {
      const result = await changeUserStatus(userId, status, specificReason);
      if (userType === USERS.ResearchScholar) {
        qc.resetQueries(["research-scholar-details"]);
      } else if (userType === USERS.Organization) {
        qc.resetQueries(["organization-user-details"]);
      } else if (userType === USERS.Funding) {
        qc.resetQueries(["funding-organization-details"]);
      } else if (userType === USERS.ResearchInstitute) {
        qc.resetQueries(["research-institute-details"]);
      }
      return result;
    },
    {
      onError: () => {
        qc.resetQueries(["research-scholar-details"]);
        qc.resetQueries(["organization-user-details"]);
        qc.resetQueries(["funding-organization-details"]);
        qc.resetQueries(["research-institute-details"]);
        qc.resetQueries(["users-details"]);
      },
    }
  );
}
