import SVGIcon from "assets/Icons";
import { Title } from "components/Title";
import { useTranslation } from "react-i18next";

export function NoPermissionCard() {
  const { t } = useTranslation();
  return (
    <div className="flex h-[calc(100%-56px)] w-full min-h-[calc(100%-56px)]  flex-col items-center justify-center overflow-auto">
      <div className="mb-14 accessDenied">
        <SVGIcon name="accessDenied" className=" w-12"></SVGIcon>
      </div>
      <h2 className="w-full text-center text-2xl font-semibold leading-6 text-primary-medium">
        {t("global.permission_turned_off")}
      </h2>

      <Title
        title={t("global.please_contact_administrator")}
        className="w-full pt-3 text-center text-base  text-black"
      />
    </div>
  );
}
