import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getMessages(threadId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const formData = new FormData();
  formData.append("threadId", threadId);
  const res = await apiClient.post(urls.messages(), formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res?.data?.data as MessageData | ApiError;
}

export function useGetMessages(threadId: string) {
  return useQuery(["messages", threadId], async () => {
    if (threadId) return await getMessages(threadId);
    else return null;
  });
}
