import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Chip, LoaderModal } from "components";
import { useGetViewFundingInterest } from "features/Organization/api/useGetViewFundingInterest";
import { getMasterDataValue } from "utils/helpers";
import { useMasterDataStore } from "store/useMasterDataStore";

type FundingInterestMessageProps = {
  challengeId: number;
  interestId: number;
};
const FundingInterestAbout = ({ challengeId, interestId }: FundingInterestMessageProps) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { data: fundingInterest, isLoading } = useGetViewFundingInterest(challengeId, interestId);
  const { organizationTypes } = useMasterDataStore();

  const selectedOrganizationType = useMemo(() => {
    return organizationTypes?.filter((item) => item.id == fundingInterest?.organizationType)?.[0];
  }, [organizationTypes, fundingInterest]);
  return (
    <>
      <div>
        {fundingInterest?.about && (
          <div className=" space-y-2">
            <p className=" mt-[34px] text-primary-light text-sm">{t("funding_organization.bio")}</p>
            <p className=" text-black text-sm WB_BW" dangerouslySetInnerHTML={{ __html: (fundingInterest.about ?? "") }} />
          </div>
        )}
        {selectedOrganizationType && (
          <div className=" space-y-2  mt-6">
            <p className=" text-primary-light text-sm">{t("organization.organization_type")}</p>
            <p className="text-primary-text text-sm font-medium">
              {selectedOrganizationType
                ? getMasterDataValue(selectedOrganizationType, i18n.language)
                : ""}
            </p>
          </div>
        )}
        {fundingInterest?.researchArea?.length > 0 && (
          <div className=" space-y-2  mt-6">
            <p className=" text-primary-light text-sm">{t("funding_organization.areas")}</p>
            <div className="flex gap-3 text-xs flex-wrap">
              {fundingInterest?.researchArea?.map((value: OptionItem, index: number) => (
                <Chip
                  key={value.id ? value.id + "" : index + ""}
                  text={i18n.language === "ar" ? value.name.ar : value.name.en}
                  className="!m-0"
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {isLoading ? <LoaderModal /> : null}
    </>
  );
};

export default FundingInterestAbout;
