import React, { useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { SubTitle, Title } from "components";
import ChallengeImageWeb01 from "assets/images/home-images/challengeWeb01.png";
import ChallengeImageMob01 from "assets/images/home-images/challengeMob01.png";
import ChallengeImageWeb02 from "assets/images/home-images/challengeWeb02.png";
import ChallengeImageMob02 from "assets/images/home-images/challengeMob02.png";
import ChallengeImageWeb03 from "assets/images/home-images/challengeWeb03.png";
import ChallengeImageMob03 from "assets/images/home-images/challengeMob03.png";
import ChallengeImageWeb04 from "assets/images/home-images/challengeWeb04.png";
import ChallengeImageMob04 from "assets/images/home-images/challengeMob04.png";
import ChallengeImageWeb05 from "assets/images/home-images/challengeWeb05.png";
import ChallengeImageMob05 from "assets/images/home-images/challengeMob05.png";
import ChallengeImageWeb06 from "assets/images/home-images/challengeWeb06.png";
import ChallengeImageMob06 from "assets/images/home-images/challengeMob06.png";

const HowItWorks = forwardRef(function HowItWorks(_, ref: React.Ref<HTMLDivElement>) {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<number>(0);
  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };

  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const handleClick = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  type Item = {
    id: number;
    title: string;
    content: React.ReactNode;
  };

  const items: Item[] = [
    {
      id: 1,
      title: "home.challenges",
      content: (
        <div className="flex flex-col bg-skin-primaryDark rounded-xl overflow-hidden">
          <div className="challengeContent w-full px-5 pt-[30px] pb-10 md:p-[60px] ltr:md:pr-[104px] rtl:md:pl-[104px]">
            <ul className="m-0 p-0 space-y-5">
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.nominates_selection_current")}{" "}
                  <span className="text-skin-textSecondary01">
                    {t("home.societal_industry_challenges")}
                  </span>
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  <span className="text-skin-textSecondary01">
                    {t("home.addresses_real_world_problems")}
                  </span>{" "}
                  {t("home.and_applied_research_questions")}
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.access_detailed_information_about_each_challenge")}
                </div>
              </li>
            </ul>
          </div>
          <div className="w-full">
            <img
              className="w-full h-full object-cover"
              src={ChallengeImageMob01}
              alt="ChallengeImage"
            />
          </div>
        </div>
      ),
    },
    {
      id: 2,
      title: "home.research_scholars",
      content: (
        <div className="flex flex-col bg-skin-primaryDark rounded-xl overflow-hidden">
          <div className="challengeContent w-full px-5 pt-[30px] pb-10 md:p-[60px] ltr:md:pr-[104px] rtl:md:pl-[104px]">
            <ul className="m-0 p-0 space-y-5">
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.a_dedicated_space_for_researchers")}{" "}
                  <span className="text-skin-textSecondary01">
                    {t("home.share_their_work_and_connect")}
                  </span>
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  <span className="text-skin-textSecondary01">
                    {t("home.researchers_create_profiles_Highlighting")}
                  </span>
                  {t("home.publications_and_ongoing_projects")}
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.opportunity_participate_in_discussions")}{" "}
                  <span className="text-skin-textSecondary01">
                    {t("home.collaborate_withOther_researchers")}
                  </span>
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.a_hub_for_knowledge_exchange")}
                </div>
              </li>
            </ul>
          </div>
          <div className="w-full">
            <img
              className="w-full h-full object-cover"
              src={ChallengeImageMob02}
              alt="ChallengeImage"
            />
          </div>
        </div>
      ),
    },
    {
      id: 3,
      title: "home.research_institutes",
      content: (
        <div className="flex flex-col bg-skin-primaryDark rounded-xl overflow-hidden">
          <div className="challengeContent w-full px-5 pt-[30px] pb-10 md:p-[60px] ltr:md:pr-[104px] rtl:md:pl-[104px]">
            <ul className="m-0 p-0 space-y-5">
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className=" ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px] ">
                  {t("home.identify_challenges_that")}{" "}
                  <span className="text-skin-textSecondary01">
                    {t("home.require_collaborative_efforts")}
                  </span>
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="  ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px] ">
                  <span className="text-skin-textSecondary01">
                    {t("home.motivate_researchers_to")}
                  </span>
                  {t("home.actively_participate_offering")}
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className=" ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px] ">
                  {t("home.foster_engagement_of_research")}{" "}
                  <span className="text-skin-textSecondary01">
                    {t("home.students_resolving_industrial_challenges")}
                  </span>
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="  ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px] ">
                  <span className="text-skin-textSecondary01">
                    {t("home.promote_joint_collaboration_among")}
                  </span>
                  {t("home.researchers_address_and_resolve")}
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.enhance_the_platform_based")}
                </div>
              </li>
            </ul>
          </div>
          <div className="w-full">
            <img
              className="w-full h-full object-cover"
              src={ChallengeImageMob03}
              alt="ChallengeImage"
            />
          </div>
        </div>
      ),
    },
    {
      id: 4,
      title: "home.start_ups",
      content: (
        <div className="flex flex-col bg-skin-primaryDark rounded-xl overflow-hidden">
          <div className="challengeContent w-full px-5 pt-[30px] pb-10 md:p-[60px] ltr:md:pr-[104px] rtl:md:pl-[104px]">
            <ul className="m-0 p-0 space-y-5">
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.gain_visibility")}{" "}
                  <span className="text-skin-textSecondary01">
                    {t("home.access_resources_and_mentoring")}
                  </span>
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  <span className="text-skin-textSecondary01">
                    {t("home.create_profiles_and_showcase_your")}
                  </span>
                  {t("home.products_services_and_innovative")}
                </div>
              </li>

              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.explore_collaboration_networking_opportunities")}
                </div>
              </li>
            </ul>
          </div>
          <div className="w-full">
            <img
              className="w-full h-full object-cover"
              src={ChallengeImageMob04}
              alt="ChallengeImage"
            />
          </div>
        </div>
      ),
    },
    {
      id: 5,
      title: "home.industries",
      content: (
        <div className="flex flex-col bg-skin-primaryDark rounded-xl overflow-hidden">
          <div className="challengeContent w-full px-5 pt-[30px] pb-10 md:p-[60px] ltr:md:pr-[104px] rtl:md:pl-[104px]">
            <ul className="m-0 p-0 space-y-5">
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.forging_bridges_between")}{" "}
                  <span className="text-skin-textSecondary01">
                    {t("home.industry_stakeholders")}
                  </span>
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  <span className="text-skin-textSecondary01">
                    {t("home.engage_with_researchers_seek_innovative")}
                  </span>
                  {t("home.access_cutting_edge_research_findings")}
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.address_solve_industry_specific_challenges")}{" "}
                  <span className="text-skin-textSecondary01">
                    {t("home.reach_out_researchers_startups_propose_solutions")}
                  </span>
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.a_hub_for_industry_professionals")}
                </div>
              </li>
            </ul>
          </div>
          <div className="w-full">
            <img
              className="w-full h-full object-cover"
              src={ChallengeImageMob05}
              alt="ChallengeImage"
            />
          </div>
        </div>
      ),
    },
    {
      id: 6,
      title: "home.funding_opportunities",
      content: (
        <div className="flex flex-col bg-skin-primaryDark rounded-xl overflow-hidden">
          <div className="challengeContent w-full px-5 pt-[30px] pb-10 md:p-[60px] ltr:md:pr-[104px] rtl:md:pl-[104px]">
            <ul className="m-0 p-0 space-y-5">
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.connect_with_researchers_and_startups")}{" "}
                  <span className="text-skin-textSecondary01">
                    {t("home.seeking_financial_support")}
                  </span>
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  <span className="text-skin-textSecondary01">
                    {t("home.opportunity_to_identify_potential_projects")}
                  </span>
                  {t("home.aligned_with_your_priorities")}
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.provide_funding_opportunities_grants_or_sponsor")}{" "}
                  <span className="text-skin-textSecondary01">
                    {t("home.specific_challenges_on_the_platform")}
                  </span>
                </div>
              </li>
              <li className="m-0 p-0 flex">
                <div>
                  <SVGIcon name="challengeBulletPoint" />
                </div>
                <div className="ltr:pl-2.5 rtl:pr-2.5 text-[15px] leading-[22px] md:ltr:pl-4 md:rtl:pr-4 md:text-lg md:leading-[30px] text-white font-medium md:tracking-[-0.18px]">
                  {t("home.advance_initiatives_that_leverage")}
                </div>
              </li>
            </ul>
          </div>
          <div className="w-full">
            <img
              className="w-full h-full object-cover"
              src={ChallengeImageMob06}
              alt="ChallengeImage"
            />
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className=" w-full px-5 md:px-10  py-10 xl:py-[60px] bg-white " ref={ref}>
      <div className="mx-auto w-full max-w-[1280px]">
        <SubTitle
          title={t("home.the_process")}
          className=" relative font-semibold text-skin-textSecondary01 uppercase !justify-start text-xs leading-4  md:text-xl md:leading-[26px] "
        />
        <Title
          title={t("home.how_it_works")}
          className=" relative font-semibold text-skin-primaryDark text-[27px] leading-[34px] md:text-[43px] md:leading-[54px]"
        />
        <div className="accordion homeAccordion block lg:hidden border-t border-skin-primary01 mt-10 divide-y divide-skin-primary01">
          {items.map((item, index) => (
            <div className="accordion-item transition-all py-5" key={index}>
              <div
                className={`transition-all cursor-pointer accordion-title flex items-center justify-between text-skin-primary text-lg leading-[26px] tracking-[ -0.18px] md:text-[23px] md:leading-8 md:tracking-[-0.23px] ${activeIndex === index ? "active" : ""
                  }`}
                onClick={() => handleClick(index)}
              >
                {t(item.title)}
                <span className="icon">
                  {activeIndex === index ? (
                    <SVGIcon name="accordionMinus" />
                  ) : (
                    <SVGIcon name="accordionPlus" />
                  )}
                </span>
              </div>
              {activeIndex === index && (
                <div className={`accordion-content pt-5`}>{item.content}</div>
              )}
            </div>
          ))}
        </div>

        <div className="tabHomeContainer hidden lg:block">
          <div className=" space-x-[40px] rtl:space-x-reverse xl:ltr::space-x-[60px]  xl:rtl:space-x-reverse pt-[60px] pb-[16px] w-full border-b border-skin-primary01  flex items-baseline ">
            <div
              className={`tab ${activeTab === 0 ? "active" : ""}`}
              onClick={() => handleTabChange(0)}
            >
              {t("home.challenges")}
            </div>
            <div
              className={`tab ${activeTab === 1 ? "active" : ""}`}
              onClick={() => handleTabChange(1)}
            >
              {t("home.research_scholars")}
            </div>
            <div
              className={`tab ${activeTab === 2 ? "active" : ""}`}
              onClick={() => handleTabChange(2)}
            >
              {t("home.research_institutes")}
            </div>
            <div
              className={`tab ${activeTab === 3 ? "active" : ""}`}
              onClick={() => handleTabChange(3)}
            >
              {t("home.start_ups")}
            </div>
            <div
              className={`tab ${activeTab === 4 ? "active" : ""}`}
              onClick={() => handleTabChange(4)}
            >
              {t("home.industries")}
            </div>
            <div
              className={`tab ${activeTab === 5 ? "active" : ""}`}
              onClick={() => handleTabChange(5)}
            >
              {t("home.funding_opportunities")}
            </div>
          </div>
          <div className="tab-content pt-[30px] w-full">
            {activeTab === 0 && (
              <div className="flex bg-skin-primaryDark rounded-xl overflow-hidden">
                <div className="challengeContent w-full py-20 ltr:pl-[60px] ltr:pr-[140px]  rtl:pl-[140px] rtl:pr-[60px]">
                  <ul className="m-0 p-0 space-y-10">
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.nominates_selection_current")}{" "}
                        <span className="text-skin-textSecondary01">
                          {t("home.societal_industry_challenges")}
                        </span>
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className="  ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        <span className="text-skin-textSecondary01">
                          {t("home.addresses_real_world_problems")}
                        </span>{" "}
                        {t("home.and_applied_research_questions")}
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4 text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.access_detailed_information_about_each_challenge")}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="min-w-[490px] w-[490px]">
                  <img
                    className="w-full h-full object-cover"
                    src={ChallengeImageWeb01}
                    alt="ChallengeImage"
                  />
                </div>
              </div>
            )}
            {activeTab === 1 && (
              <div className="flex bg-skin-primaryDark rounded-xl overflow-hidden">
                <div className="challengeContent w-full py-20 ltr:pl-[60px] ltr:pr-[140px]  rtl:pl-[140px] rtl:pr-[60px]">
                  <ul className="m-0 p-0 space-y-10">
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.a_dedicated_space_for_researchers")}{" "}
                        <span className="text-skin-textSecondary01">
                          {t("home.share_their_work_and_connect")}
                        </span>
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className="  ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        <span className="text-skin-textSecondary01">
                          {t("home.researchers_create_profiles_Highlighting")}
                        </span>
                        {t("home.publications_and_ongoing_projects")}
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.opportunity_participate_in_discussions")}{" "}
                        <span className="text-skin-textSecondary01">
                          {t("home.collaborate_withOther_researchers")}
                        </span>
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4 text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.a_hub_for_knowledge_exchange")}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="min-w-[490px] w-[490px]">
                  <img
                    className="w-full h-full object-cover"
                    src={ChallengeImageWeb02}
                    alt="ChallengeImage"
                  />
                </div>
              </div>
            )}
            {activeTab === 2 && (
              <div className="flex bg-skin-primaryDark rounded-xl overflow-hidden">
                <div className="challengeContent w-full py-20 ltr:pl-[60px] ltr:pr-[140px]  rtl:pl-[140px] rtl:pr-[60px]">
                  <ul className="m-0 p-0 space-y-10">
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.identify_challenges_that")}{" "}
                        <span className="text-skin-textSecondary01">
                          {t("home.require_collaborative_efforts")}
                        </span>
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className="  ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        <span className="text-skin-textSecondary01">
                          {t("home.motivate_researchers_to")}
                        </span>
                        {t("home.actively_participate_offering")}
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.foster_engagement_of_research")}{" "}
                        <span className="text-skin-textSecondary01">
                          {t("home.students_resolving_industrial_challenges")}
                        </span>
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className="  ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        <span className="text-skin-textSecondary01">
                          {t("home.promote_joint_collaboration_among")}
                        </span>
                        {t("home.researchers_address_and_resolve")}
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4 text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.enhance_the_platform_based")}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="min-w-[490px] w-[490px]">
                  <img
                    className="w-full h-full object-cover"
                    src={ChallengeImageWeb03}
                    alt="ChallengeImage"
                  />
                </div>
              </div>
            )}
            {activeTab === 3 && (
              <div className="flex bg-skin-primaryDark rounded-xl overflow-hidden">
                <div className="challengeContent w-full py-20 ltr:pl-[60px] ltr:pr-[140px]  rtl:pl-[140px] rtl:pr-[60px]">
                  <ul className="m-0 p-0 space-y-10">
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.gain_visibility")}{" "}
                        <span className="text-skin-textSecondary01">
                          {t("home.access_resources_and_mentoring")}
                        </span>
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className="  ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        <span className="text-skin-textSecondary01">
                          {t("home.create_profiles_and_showcase_your")}
                        </span>
                        {t("home.products_services_and_innovative")}
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4 text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.explore_collaboration_networking_opportunities")}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="min-w-[490px] w-[490px]">
                  <img
                    className="w-full h-full object-cover"
                    src={ChallengeImageWeb04}
                    alt="ChallengeImage"
                  />
                </div>
              </div>
            )}

            {activeTab === 4 && (
              <div className="flex bg-skin-primaryDark rounded-xl overflow-hidden">
                <div className="challengeContent w-full py-20 ltr:pl-[60px] ltr:pr-[140px]  rtl:pl-[140px] rtl:pr-[60px]">
                  <ul className="m-0 p-0 space-y-10">
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.forging_bridges_between")}{" "}
                        <span className="text-skin-textSecondary01">
                          {t("home.industry_stakeholders")}
                        </span>
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className="  ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        <span className="text-skin-textSecondary01">
                          {t("home.engage_with_researchers_seek_innovative")}
                        </span>
                        {t("home.access_cutting_edge_research_findings")}
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.address_solve_industry_specific_challenges")}{" "}
                        <span className="text-skin-textSecondary01">
                          {t("home.reach_out_researchers_startups_propose_solutions")}
                        </span>
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4 text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.a_hub_for_industry_professionals")}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="min-w-[490px] w-[490px]">
                  <img
                    className="w-full h-full object-cover"
                    src={ChallengeImageWeb05}
                    alt="ChallengeImage"
                  />
                </div>
              </div>
            )}
            {activeTab === 5 && (
              <div className="flex bg-skin-primaryDark rounded-xl overflow-hidden">
                <div className="challengeContent w-full py-20 ltr:pl-[60px] ltr:pr-[140px]  rtl:pl-[140px] rtl:pr-[60px]">
                  <ul className="m-0 p-0 space-y-10">
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.connect_with_researchers_and_startups")}{" "}
                        <span className="text-skin-textSecondary01">
                          {t("home.seeking_financial_support")}
                        </span>
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className="  ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        <span className="text-skin-textSecondary01">
                          {t("home.opportunity_to_identify_potential_projects")}
                        </span>
                        {t("home.aligned_with_your_priorities")}
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4  text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.provide_funding_opportunities_grants_or_sponsor")}{" "}
                        <span className="text-skin-textSecondary01">
                          {t("home.specific_challenges_on_the_platform")}
                        </span>
                      </div>
                    </li>
                    <li className="m-0 p-0 flex">
                      <div>
                        <SVGIcon name="challengeBulletPoint" />
                      </div>
                      <div className=" ltr:pl-4 rtl:pr-4 text-lg text-white font-medium leading-[30px] tracking-[-0.18px] ">
                        {t("home.advance_initiatives_that_leverage")}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="min-w-[490px] w-[490px]">
                  <img
                    className="w-full h-full object-cover object-cover"
                    src={ChallengeImageWeb06}
                    alt="ChallengeImage"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
export default HowItWorks;
