import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function uploadFile(fileList: FileList) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const responses: Array<FileDetails> = [];
  if (fileList)
    for (let i = 0; i < fileList.length; i++) {
      const formData = new FormData();
      formData.append("file", fileList[i]);
      const response = await apiClient.post(urls.uploadResearchDocuments(), formData, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
      });
      responses.push(response?.data?.data as FileDetails);
    }
  return responses;
}

export function useUploadResearchDocs() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;

  return useMutation(async (fileList: FileList) => {
    if (userId) {
      const result = await uploadFile(fileList);
      return result;
    }
  });
}
