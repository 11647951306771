import { Toggle } from "react-daisyui";
import { useTranslation } from "react-i18next";
import { Label } from "components";
import { PERMISSION_LIST_TABS } from "utils/constants";

type ToggleButtonProps = {
  label: string;
  isChecked: boolean;
  handleChange: (id: number) => void;
  className?: string;
  id: number;
  disabled?: boolean;
  activeTab?: number;
  showInfoTooltip?: boolean;
};

export function ToggleButton({
  label,
  className,
  isChecked,
  handleChange,
  id,
  disabled,
  activeTab,
  showInfoTooltip = true,
}: ToggleButtonProps) {
  const { t } = useTranslation();

  return (
    <div className={`flex items-center gap-[15px] p-4 ${className || ""}`}>
      <div>
        <Toggle
          className="toggle toggle-primary"
          checked={isChecked}
          onChange={() => {
            handleChange(id);
          }}
          disabled={disabled}
        />
      </div>

      <span className="text-sm font-normal text-gray-109 flex items-center whitespace-nowrap">
        {t(label)}{" "}
        {disabled && showInfoTooltip ? (
          <Label
            className="label p-0  text-primary-light text-sm"
            text=""
            tooltipContent={
              activeTab === PERMISSION_LIST_TABS.researchInstitute
                ? "research_institute.toggle_disabled_message"
                : "organization.toggle_disabled_message"
            }
          />
        ) : null}
      </span>
    </div>
  );
}
