import { useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { SubTitle, Title, TextArea, SecondaryButton, PrimaryButton, LoaderModal } from "components";
import { DETAIL_DESCRIPTION_MAX_CHAR_LENGTH, USER_STATUS } from "utils/constants";
import { ToasterIconType, showErrorToastMessage, showInfoToastMessage } from "utils/toasterMessage";
import { useChangeUserStatus } from "features/Admin/api/useChangeUserStatus";

type StatusChangeConfirmationModalProps = {
  onProceed: () => void;
  onClose: () => void;
  title: string;
  message: string;
  userId: string;
  status: number;
  userType: number;
};

export default function StatusChangeConfirmationModal({
  onProceed,
  onClose,
  title,
  message,
  userId,
  status,
  userType,
}: StatusChangeConfirmationModalProps) {
  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState(false);
  const submitButtonLabel =
    status === USER_STATUS.DeclineWithReason
      ? "admin.decline_with_reason"
      : status === USER_STATUS.Disabled
      ? "admin.disable"
      : "admin.enable";

  const { mutateAsync: changeUserStatus, isLoading } = useChangeUserStatus();
  const [reason, setReason] = useState("");

  const handleProceed = async () => {
    if (status === USER_STATUS.DeclineWithReason) {
      if (!reason.length) {
        setValidationErrors(true);
        return;
      }
    }
    try {
      const result = await changeUserStatus({
        userId,
        status,
        specificReason: reason,
        userType,
      });
      showInfoToastMessage({
        iconType: ToasterIconType.APPROVED,
        message: result?.message,
      });
    } catch (error: ApiError) {
      showErrorToastMessage({
        iconType: ToasterIconType.ALERT,
        message:
          error.response.data.status < 500
            ? error.response.data.message
            : t("global.something_went_wrong"),
      });
    } finally {
      onProceed();
    }
  };

  return (
    <div className="fixed h-screen w-screen bg-black/[0.3] z-[99] flex justify-center items-center top-0 ltr:left-0 rtl:right-0 ">
      <div className="animate__animated animate__zoomIn flex flex-col rounded-md items-center bg-white max-w-[470px] mx-2 sm:w-[470px] py-[50px] px-8 max-h-[calc(100vh-3.125rem)] overflow-auto">
        <div>
          <SVGIcon
            name={
              status === USER_STATUS.Disabled || status === USER_STATUS.DeclineWithReason
                ? "disableUser"
                : "enableUser"
            }
          />
        </div>
        <Title
          title={title}
          className=" text-xl font-semibold text-black mt-4 lg:mt-10 pb-1 leading-normal"
        />
        <SubTitle
          title={message}
          className=" text-base text-primary-text px-5 mt-1 mb-[30px] text-center"
        />
        {status === USER_STATUS.Disabled || status === USER_STATUS.DeclineWithReason ? (
          <TextArea
            textContent={reason}
            handleChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setReason(event?.target?.value)
            }
            labelText=""
            hintText={t(
              status === USER_STATUS.Disabled
                ? "admin.enter_a_reason_for_disable"
                : "admin.enter_decline_reason"
            )}
            fieldName="reason"
            maxCharLength={DETAIL_DESCRIPTION_MAX_CHAR_LENGTH}
            errorMessage={validationErrors ? "required" : ""}
          />
        ) : null}
        <div className=" flex w-full justify-center space-x-[10px] rtl:space-x-reverse mt-[38px]">
          <SecondaryButton
            title={"global.close"}
            handleClick={onClose}
            className=" flex-1 sm:flex-[0] sm:min-w-[180px]"
          />
          <PrimaryButton
            title={submitButtonLabel}
            handleClick={handleProceed}
            className=" flex-1 sm:flex-[0] sm:min-w-[180px]"
          />
        </div>
      </div>
      {isLoading ? <LoaderModal /> : null}
    </div>
  );
}
