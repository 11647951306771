import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckBox,
  InputField,
  PrimaryButton,
  Header,
  LoaderModal,
  SubTitle,
  NoPermissionCard,
} from "components";
import { setSelectedSideMenu } from "store/useSidebarStore";
import { SUB_DOMAIN_MAX_LENGTH } from "utils/constants";
import { useGetAutoApprovalDetails } from "features/ResearchInstitute/api/useGetAutoApprovalDetails";
import { useManageAutoApproval } from "features/ResearchInstitute/api/useManageAutoApproval";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { useMasterDataStore } from "store/useMasterDataStore";

const Approval = () => {
  const { t } = useTranslation();
  const { permissions } = useMasterDataStore();
  const { data, isLoading } = useGetAutoApprovalDetails();
  const { mutateAsync: updateAutoApproval, isLoading: isUpdating } = useManageAutoApproval();

  const [isRequired, setIsRequired] = useState(false);
  const [autoApprovalData, setAutoApprovalData] = useState<ResearchInstituteAutoApproval>({
    isAutoApprove: false,
    domainName: "",
  });

  useEffect(() => {
    setAutoApprovalData(data);
  }, [data, setAutoApprovalData]);

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.settings",
      isOpen: true,
      subMenuType: "research_institute.approvals",
    });
  }, []);

  const handleCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoApprovalData((prev) => ({
      ...prev,
      isAutoApprove: event.target.checked,
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const domainName = event.target.value.trim();
    setAutoApprovalData((prev) => ({
      ...prev,
      domainName,
    }));
  };

  const isSaveRequired = () => {
    if (JSON.stringify(autoApprovalData) === JSON.stringify(data)) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsRequired(false);

    if (isSaveRequired()) {
      if (autoApprovalData?.isAutoApprove && !autoApprovalData?.domainName.length) {
        setIsRequired(true);
        return;
      }

      try {
        const data = await updateAutoApproval(autoApprovalData);
        showSuccessToastMessage({
          message: data?.data?.message,
        });
      } catch (error: ApiError) {
        showErrorToastMessage({
          message:
            error?.response?.data?.status < 500
              ? error.response.data.message
              : t("global.something_went_wrong"),
        });
      }
    }
  };

  return (
    <>
      <div className="flex  min-h-screen w-full">
        <Header title={"research_institute.approvals"} className={" fixed stickyHeader"} />
        {permissions?.Automaticapprovalforscholars ? (
          <div className="adminDetails w-full pt-[118px] pb-[45px] items-baseline px-10 ltr:pr-[30px] rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto ">
            <div className="text-black bg-white w-full rounded-md flex h-full">
              <form className="space-y-6 p-[32px]" onSubmit={handleSubmit}>
                <p className="!justify-start text-gray-109 font-semibold  pb-2.5 text-[18px] leading-[26px]">
                  {t("research_institute.domain_based_auto_approval")}
                </p>
                <p className=" text-gray-109 text-sm !mt-0 leading-[22px] pb-2">
                  {t("research_institute.auto_approve_researchers_subtext")}
                </p>
                <CheckBox
                  option={{
                    label: t("global.approve"),
                    value: "",
                  }}
                  checked={autoApprovalData?.isAutoApprove}
                  handleChange={handleCheckBoxClick}
                  className=" flex items-center gap-0 mb-2 customCheckboxV2"
                />
                <div className="app-c-domain_name">
                  <SubTitle
                    className=" text-primary-light text-sm flex justify-start items-center leading-4 font-normal whitespace-nowrap subtitle"
                    title={t("research_institute.domain_name")}
                    tooltipContent="research_institute.domain_tooltip_content"
                  />
                  <InputField
                    hintText={"academics.com"}
                    labelText={""}
                    handleInputChange={handleInputChange}
                    fieldName="domainName"
                    inputType="text"
                    maxCharLength={SUB_DOMAIN_MAX_LENGTH}
                    value={autoApprovalData?.domainName}
                    isRequired={autoApprovalData?.isAutoApprove}
                    errorMessage={isRequired ? "required" : ""}
                    className="w-full placeholder:text-gray-1100 h-12 font-medium placeholder:!text-base  text-primary-text text-base outline-none focus:border-primary-medium placeholder:font-normal !px-[16px]  truncate  border bg-white rounded defaultInput  min-w-[500px] border-primary-light "
                  />
                </div>
                <div className="!mt-6">
                  <PrimaryButton
                    title={"global.update"}
                    handleClick={() => {
                      //noop
                    }}
                    className="default-primary-button  min-w-[120px] !mt-[32px] w-[192px]"
                  />
                </div>
              </form>
            </div>
          </div>
        ) : (
          <NoPermissionCard />
        )}
      </div>
      {isLoading || isUpdating ? <LoaderModal /> : null}
    </>
  );
};
export default Approval;
