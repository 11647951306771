import { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
  FilterTab,
  Title,
  NoData,
  LoaderModal,
  InlineLoader,
  Header,
  FilterPopup,
  SecondaryButton,
  MultiSelect,
  Chip,
  PrimaryButton,
} from "components";
import {
  CHALLENGE_STATUS,
  CHALLENGE_TYPES,
  COLLABORATION_DASHBOARD_INIT_PARAMS,
  INITIAL_SEARCH_PARAMS,
  PAGE_LIMIT,
  USERS,
} from "utils/constants";
import { useStickyScroll } from "hooks/useStickyScroll";
import {
  useFilterDataStore,
  clearOrganizationFilterInfo,
  setScrollInfo,
  setChallengeFilterInfoItem,
  clearUserTypeFilter,
  clearFilterDataStore,
} from "store/useFilterDataStore";
import { useSetScroll } from "hooks/useSetScroll";
import useGetScrollPosition from "hooks/useGetScrollPosition";
import useResetQueryOnLanguageSwitch from "hooks/useResetQueryOnLanguageSwitch";
import useQueryManagementOnIndexChange from "hooks/useQueryManagementOnIndexChange";
import { getResearchAreas } from "features/ResearchScholar/api/useGetResearchArea";
import { useGetCollaborationList } from "features/Collaboration/api/useGetCollaborationList";
import { useUserDataStore } from "store/useUserDataStore";
import { setSelectedFormId, setSelectedSideMenu } from "store/useSidebarStore";
import { clearCollaborationDataStore } from "store/useCollaborationDataStore";
import { CollaborationCard } from "components/CollaborationCard";

export default function Collaborations() {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { scroll } = useStickyScroll();
  const { scrollInfo } = useFilterDataStore();
  const { organizationFilterInfo, challengeFilterInfo } = useFilterDataStore();
  const { userInfo } = useUserDataStore();
  const pathName = window.location.pathname;

  const [requestParams, setRequestParams] = useState<CollaborationListParams>({
    ...COLLABORATION_DASHBOARD_INIT_PARAMS,
    isOwned: true,
    type: challengeFilterInfo?.activeTab ?? CHALLENGE_TYPES.All,
    status: challengeFilterInfo?.activeFilter ?? CHALLENGE_STATUS.All,
    researchArea: challengeFilterInfo?.researchAreaId ?? [],
  });
  const {
    data: collaborationList,
    isLoading: collaborationListLoading,
    fetchNextPage,
    hasNextPage,
  } = useGetCollaborationList(requestParams);
  const [isOpenSideMenu, setIsOpenSideMenu] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(
    challengeFilterInfo?.activeTab ?? CHALLENGE_TYPES.All
  );
  const [activeFilter, setActiveFilter] = useState<number>(
    challengeFilterInfo?.activeFilter ?? CHALLENGE_STATUS.All
  );

  const [selectedOptions, setSelectedOptions] = useState<OptionItem[]>(
    challengeFilterInfo?.selectedResearchAreas ?? []
  );

  const navigate = useNavigate();

  const onToggleClick = () => {
    setIsOpenSideMenu(!isOpenSideMenu);
  };

  const { scrollPosition } = useGetScrollPosition();
  useSetScroll(scrollInfo?.scrollPosition ?? 0);
  useQueryManagementOnIndexChange(scrollInfo?.index, scrollInfo?.pathName, "collaboration-list");
  useResetQueryOnLanguageSwitch("collaboration-list");

  const handleTabChange = (type: number) => {
    setActiveTab(type);
    queryClient.resetQueries(["collaboration-list"]);
    if (type === CHALLENGE_TYPES.All && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, type: CHALLENGE_TYPES.All }));
      setChallengeFilterInfoItem("activeTab", CHALLENGE_TYPES.All);
    } else if (type === CHALLENGE_TYPES.Public && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, type: CHALLENGE_TYPES.Public }));
      setChallengeFilterInfoItem("activeTab", CHALLENGE_TYPES.Public);
    } else if (type === CHALLENGE_TYPES.Private && type !== activeTab) {
      setRequestParams((prevState) => ({ ...prevState, type: CHALLENGE_TYPES.Private }));
      setChallengeFilterInfoItem("activeTab", CHALLENGE_TYPES.Private);
    }
  };

  const collaborationDetails = useMemo(() => {
    const tempData: CollaborationListingResponse = {
      collaborations: [],
      totalCount: 0,
      totalPages: 0,
    };
    if (collaborationList?.pages && collaborationList?.pages[0]) {
      tempData.totalCount = collaborationList.pages[0]?.totalCount;
      tempData.totalPages = collaborationList.pages[0]?.totalPages;
      const collaborations = collaborationList.pages
        .map((item) => {
          return item.collaborations;
        })
        .flat();
      tempData.collaborations = collaborations;
    }
    return tempData;
  }, [collaborationList]);

  const collaborationFilterItems: Array<RecordItem> = useMemo(() => {
    const tempItems = [
      {
        value: CHALLENGE_TYPES.All,
        label: "global.all",
      },
      {
        value: CHALLENGE_TYPES.Public,
        label: "organization.public",
      },
      {
        value: CHALLENGE_TYPES.Private,
        label: "organization.private",
      },
    ];
    return tempItems;
  }, []);

  const filterPopupItems: Array<FilterOption> = useMemo(() => {
    const tempItems = [];
    tempItems.push({
      option: {
        value: CHALLENGE_STATUS.All,
        label: t("global.all"),
      },
    });

    tempItems.push({
      option: {
        value: CHALLENGE_STATUS.Draft,
        label: t("organization.drafts"),
      },
    });

    tempItems.push({
      option: {
        value: CHALLENGE_STATUS.Open,
        label: t("organization.open"),
      },
    });

    tempItems.push({
      option: {
        value: CHALLENGE_STATUS.Close,
        label: t("organization.closed"),
      },
    });

    tempItems.push({
      option: {
        value: CHALLENGE_STATUS.Disable,
        label: t("global.disabled"),
      },
    });

    tempItems.push({
      option: {
        value: CHALLENGE_STATUS.Pending,
        label: t("global.pending"),
      },
    });

    return tempItems;
  }, [t]);

  const handleFilterChange = (selectedFilterId: number) => {
    setActiveFilter(selectedFilterId);
    queryClient.resetQueries(["collaboration-list"]);
    if (selectedFilterId === CHALLENGE_STATUS.Draft && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Draft }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.Draft);
    } else if (selectedFilterId === CHALLENGE_STATUS.Open && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Open }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.Open);
    } else if (selectedFilterId === CHALLENGE_STATUS.Live && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Live }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.Live);
    } else if (selectedFilterId === CHALLENGE_STATUS.Close && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Close }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.Close);
    } else if (selectedFilterId === CHALLENGE_STATUS.All && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.All }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.All);
    } else if (selectedFilterId === CHALLENGE_STATUS.Disable && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Disable }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.Disable);
    } else if (selectedFilterId === CHALLENGE_STATUS.Pending && selectedFilterId !== activeFilter) {
      setRequestParams((prevState) => ({ ...prevState, status: CHALLENGE_STATUS.Pending }));
      setChallengeFilterInfoItem("activeFilter", CHALLENGE_STATUS.Pending);
    }
  };

  useEffect(() => {
    if (organizationFilterInfo?.pathName && pathName !== organizationFilterInfo?.pathName) {
      clearOrganizationFilterInfo();
    }
  }, [organizationFilterInfo?.pathName, pathName]);

  const handleCreateCollaboration = () => {
    clearCollaborationDataStore();
    setSelectedFormId(null);
    setScrollInfo({
      pathName: window.location.pathname,
      scrollPosition: 0,
      index: 0,
    });
    navigate("create");
  };

  const handleSelectedOptions = (options: OptionItem[] | null) => {
    if (options === null) {
      setSelectedOptions([]);
      setRequestParams((prevState) => ({ ...prevState, researchArea: [] }));
      setChallengeFilterInfoItem("researchAreaId", []);
      setChallengeFilterInfoItem("selectedResearchAreas", []);
    } else if (options.length <= 5) {
      const newOptions = options.filter(
        (option) => !selectedOptions.some((selectedOption) => selectedOption.id === option.id)
      );
      if (newOptions.length > 0) {
        setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, ...newOptions]);
        setChallengeFilterInfoItem("selectedResearchAreas", [...selectedOptions, ...newOptions]);
        const researchAreaIds = newOptions.map((option) => Number(option.id));
        setRequestParams((prevState) => ({
          ...prevState,
          researchArea: [...prevState.researchArea, ...researchAreaIds],
        }));
        setChallengeFilterInfoItem("researchAreaId", [
          ...requestParams.researchArea,
          ...researchAreaIds,
        ]);
      }
    }
  };

  const searchParamsRef = useRef(INITIAL_SEARCH_PARAMS);
  async function loadResearchAreaOptions(searchKeyword: string) {
    let pageNumber = searchParamsRef?.current.pageNumber + 1;
    if (searchKeyword !== searchParamsRef?.current.searchKeyword) pageNumber = 1;
    const result = await getResearchAreas({ pageLimit: PAGE_LIMIT, pageNumber, searchKeyword });
    searchParamsRef.current = {
      pageLimit: PAGE_LIMIT,
      pageNumber,
      searchKeyword,
    };
    return {
      options: result?.responseData ?? [],
      hasMore: result?.totalPages > 0 && pageNumber <= result?.totalPages,
    };
  }

  const handleRemoveSelectedOption = (optionToRemove: OptionItem) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    setChallengeFilterInfoItem(
      "selectedResearchAreas",
      selectedOptions.filter((option) => option.id !== optionToRemove.id)
    );
    const remainingResearchAreas = selectedOptions.filter(
      (option) => option.id !== optionToRemove.id
    );
    const researchAreaIds = remainingResearchAreas.map((option) => Number(option.id));
    setRequestParams((prevState) => ({
      ...prevState,
      researchArea: researchAreaIds,
    }));
    setChallengeFilterInfoItem("researchAreaId", [...researchAreaIds]);
  };

  function handleCollaborationClick(index: number, collaborationId: number) {
    const pageParam = Math.floor(index / PAGE_LIMIT);
    setSelectedFormId(collaborationId);
    setScrollInfo({
      scrollPosition: scrollPosition,
      pathName: location.pathname,
      index: pageParam,
    });
  }

  const handleExploreClick = () => {
    clearUserTypeFilter();
    clearFilterDataStore();
    navigate("explore");
  };

  const handlePath = (item: CollaborationData) => {
    if (userInfo?.userType === USERS.ResearchInstitute) {
      if (item?.statusId === CHALLENGE_STATUS.Draft) {
        return `/research-institute/collaborations/create/${item?.id}`;
      } else if (item?.statusId === CHALLENGE_STATUS.Pending) {
        return `/research-institute/collaborations/${item?.id}/preview`;
      }
    }
    return "";
  };

  useEffect(() => {
    setSelectedSideMenu({
      type: "global.collaborations",
    });
  }, []);

  return (
    <div className="w-full h-full flex">
      <Header
        title={"global.my_collaborations"}
        handleCollapseClick={onToggleClick}
        className={
          scroll
            ? " bg-primary-medium lg:bg-white  animate__animated animate__fadeInDown lg:shadow-sticky   lg:!h-[90px] fixed stickyHeader smaller-devices-section-challenge"
            : "fixed bg-primary-medium lg:bg-gray-light smaller-devices-section-challenge"
        }
      />
      <div className=" adminDetails w-full pt-[122px] pb-10 items-baseline px-4 lg:px-10 lg:ltr:pr-[30px] lg:rtl:pl-[30px] min-h-screen flex flex-col  overflow-auto max-[1025px]:overflow-x-hidden ">
        {collaborationDetails?.collaborations?.length === 0 &&
        userInfo?.userType === USERS.ResearchInstitute &&
        requestParams.status === CHALLENGE_STATUS.All &&
        requestParams.type === CHALLENGE_TYPES.All &&
        selectedOptions?.length === 0 &&
        !collaborationListLoading ? (
          <div className="flex space-x-4">
            <SecondaryButton
              title={"organization.create_your_first_collaboration"}
              handleClick={handleCreateCollaboration}
              endIcon="addChallenge"
              className="bg-white w-[262px] h-[88px] !text-base font-medium flex gap-4 p-5 rounded-md flex-nowrap !justify-start text-left"
            />
            <SecondaryButton
              className="bg-white w-[262px] h-[88px] !text-base font-medium flex gap-4 p-5 rounded-md flex-nowrap !justify-start text-left"
              startIcon="exploreChallenge"
              title={"research_institute.explore_collaborations"}
              handleClick={handleExploreClick}
            />
          </div>
        ) : (
          <>
            <div className="tabContainer w-full   flex items-baseline tabMobileView z-10">
              <FilterTab
                filterOptions={collaborationFilterItems}
                activeTab={activeTab}
                handleClick={(value) => handleTabChange(value)}
              />
              <FilterPopup
                filterOptions={filterPopupItems}
                activeFilter={activeFilter}
                handleClick={(selectedFilterId) => handleFilterChange(selectedFilterId)}
              />
            </div>

            <div className="tab-content pt-6 w-full">
              <div className=" flex justify-between pb-5 items-center flex-wrap">
                <div className="flex flex-col lg:flex-row lg:space-x-[46px] space-x-0 rtl:space-x-reverse">
                  <div className="min-w-[288px] lg:min-w-[482px]">
                    <div className=" relative">
                      <MultiSelect
                        labelText={t("research_scholar.challenge_search_areas")}
                        hintText={t("research_scholar.add_research_areas")}
                        setSelectedOptions={handleSelectedOptions}
                        selectedOptions={selectedOptions}
                        loadOptions={loadResearchAreaOptions ?? []}
                        isDisabled={selectedOptions.length > 4}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 mt-auto">
                  {userInfo?.userType === USERS.ResearchInstitute && (
                    <>
                      <PrimaryButton
                        className="bg-white border-none !text-black hover:!bg-gradient-blueDark hover:!text-white"
                        startIcon="exploreChallenge"
                        title={"research_institute.explore_collaborations"}
                        handleClick={handleExploreClick}
                      />
                      <SecondaryButton
                        title={"organization.create_new_collaboration"}
                        handleClick={handleCreateCollaboration}
                        startIcon="addMilestone"
                        className="!bg-transparent space-x-1 rtl:space-x-reverse"
                      />
                    </>
                  )}
                </div>
                <div className="w-full mt-6 md:mt-0">
                  {selectedOptions.length > 0 && (
                    <Title
                      className=" text-primary-light text-sm leading-4 font-normal mt-4 mb-2 md:mb-3"
                      title={t("global.selected_areas")}
                    />
                  )}
                  <div className="flex flex-wrap mb-4 lg:mb-0">
                    {selectedOptions.map((option) => (
                      <Chip
                        key={option.id}
                        className="chipPrimary"
                        text={i18n.language === "en" ? option.name.en : option.name.ar}
                        handleClose={() => handleRemoveSelectedOption(option)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <Title
                className=" text-primary-light text-sm leading-4 font-normal mb-3"
                title={`${
                  collaborationList?.pages[0]?.totalCount
                    ? collaborationList?.pages[0]?.totalCount
                    : t("global.no")
                } ${t("organization.results_found")}`}
              />

              {collaborationDetails?.collaborations?.length > 0 ? (
                <InfiniteScroll
                  dataLength={collaborationDetails?.collaborations?.length}
                  next={fetchNextPage}
                  hasMore={!!hasNextPage}
                  loader={hasNextPage || collaborationListLoading ? <InlineLoader /> : null}
                  scrollThreshold="200px"
                >
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                    {collaborationDetails.collaborations.map(
                      (item: CollaborationData, index: number) => (
                        <div key={index} className="">
                          <CollaborationCard
                            data={item}
                            showCollaborationSignature={true}
                            path={handlePath(item)}
                            handleCollaborationClick={() =>
                              handleCollaborationClick(index, item?.id)
                            }
                          />
                        </div>
                      )
                    )}
                  </div>
                </InfiniteScroll>
              ) : null}
              {!collaborationListLoading && collaborationDetails?.collaborations?.length === 0 ? (
                <div>
                  <NoData message={"global.no_collaborations_to_show"} />
                </div>
              ) : null}
            </div>
          </>
        )}
        {collaborationListLoading ? <LoaderModal /> : null}
      </div>
    </div>
  );
}
