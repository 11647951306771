import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getUserChallengeStatus(challengeId: number, userId: string) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.userChallengeStatus(challengeId, userId), {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (res.status === 400) {
    throw new Error("error");
  }
  return res.data?.data as ApiStatus | ApiError;
}

export function useGetUserChallengeStatus({
  challengeId,
  userId,
}: {
  challengeId: number;
  userId: string;
}) {
  return useQuery(["user-challenge-status", challengeId, userId], async () => {
    return await getUserChallengeStatus(challengeId, userId);
  });
}
