import { useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getMessageThreads() {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.messageThreads(), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res?.data?.data as MessageData | ApiError;
}

export function useGetMessageThreads() {
  return useQuery(
    ["message-threads"],
    async () => {
      return await getMessageThreads();
    },
    {
      staleTime: Infinity,
    }
  );
}
