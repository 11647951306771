import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoaderModal } from "components";
import DocumentList from "features/Organization/Profile/Documents/DocumentsList";
import { useGetDocuments } from "features/ResearchInstitute/api/useGetDocuments";
import { setDocumentsInfo } from "store/useOrganizationDataStore";
import { USER_STATUS } from "utils/constants";
import useInvalidateQuery from "hooks/useInvalidateQuery";

type DocumentsProps = {
  setIsUploading: (status: boolean) => void;
};

export default function Documents({ setIsUploading }: DocumentsProps) {
  const { i18n } = useTranslation();
  const { researchInstituteId } = useParams();
  const { data: documents, isLoading: isLoadingDocuments } = useGetDocuments(
    researchInstituteId ?? ""
  );
  useInvalidateQuery("research-institute-details", researchInstituteId ?? "");

  const isReadOnly = documents?.profileStatusId === USER_STATUS.PendingApproval;

  useEffect(() => {
    if (documents && documents?.documents?.length > 0) {
      setDocumentsInfo(documents?.documents);
    } else {
      setDocumentsInfo([]);
    }
  }, [documents, i18n.language]);

  return (
    <>
      <DocumentList isReadOnly={isReadOnly} setIsUploading={setIsUploading} />
      {isLoadingDocuments ? <LoaderModal /> : null}
    </>
  );
}
