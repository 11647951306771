import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useCollaborationDataStore } from "store/useCollaborationDataStore";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getCollaborationAbout(collaborationId: number): Promise<CollaborationAbout | null> {
  try {
    const tokenInfo = useUserDataStore.getState().tokenInfo;
    const token = tokenInfo?.token;
    const res = await apiClient.get(urls.collaborationDetails(collaborationId), {
      headers: { Authorization: `Bearer ${token}` },
    });

    return res.data?.data as CollaborationAbout | ApiError;
  } catch (error) {
    return null;
  }
}

export function useGetCollaborationAbout(): UseQueryResult<CollaborationAbout> {
  const collaborationId = useCollaborationDataStore.getState().collaborationInfo?.id;
  return useQuery(
    ["collaboration-about", collaborationId],
    async () => {
      if (collaborationId === 0) return null;

      const result = await getCollaborationAbout(Number(collaborationId));
      return result;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
