import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";

type ExternalURLLinkProps = {
  label: string;
  handleClick: () => void;
  buttonClassName: string;
  labelClassName: string;
  imageUrl?: string;
  imageAlt?: string;
};

export function ExternalURLLink({
  label,
  handleClick,
  imageUrl,
  imageAlt,
  buttonClassName,
  labelClassName,
}: ExternalURLLinkProps) {
  const { t } = useTranslation();
  return (
    <div className={buttonClassName} onClick={() => handleClick()}>
      {imageUrl && <img src={imageUrl} alt={imageAlt} />}
      <span className={labelClassName}>{t(label)}</span>
      <SVGIcon name="linkto" />
    </div>
  );
}
