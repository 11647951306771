import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function getCollaborationBasicDetails(
  id: number,
  isExplore: boolean
): Promise<CollaborationBasicDetails | null> {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(urls.collaborationBasicDetails(id, isExplore), {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (res.status === 400) {
    throw new Error("error");
  }
  return res.data?.data as CollaborationBasicDetails | ApiError;
}

export function useGetCollaborationBasicDetails(
  id: number,
  isExplore = false
): UseQueryResult<CollaborationBasicDetails> {
  return useQuery(["collaboration-basic-details", id], async () => {
    if (id > 0) {
      const result = await getCollaborationBasicDetails(id, isExplore);
      return result;
    } else return null;
  });
}
