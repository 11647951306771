import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function updateInvite(challengeId: number, statusId: number) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  return await apiClient.put(
    urls.researchScholarInviteUpdate(challengeId, statusId),
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}

export function useUpdateResearchScholarInvite() {
  const qc = useQueryClient();
  return useMutation(
    async ({ challengeId, statusId }: { challengeId: number; statusId: number }) => {
      const result = await updateInvite(challengeId, statusId);
      qc.invalidateQueries(["research-invites"]);
      qc.invalidateQueries(["challenge-preview-details", challengeId]);
      return result;
    }
  );
}
