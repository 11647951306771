import { useInfiniteQuery } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

export async function getEventList({
  pageNumber,
  pageLimit,
  status,
  targetIndustry,
}: EventListParams) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const res = await apiClient.get(
    urls.eventList({
      pageNumber,
      pageLimit,
      status,
      targetIndustry,
    }),
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return res?.data?.data as EventListingResponse | ApiError;
}

export function useGetEventList({ pageLimit, status, targetIndustry }: EventListParams) {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;

  return useInfiniteQuery(
    ["event-list", userId, status, targetIndustry],
    async ({ pageParam = 1 }) => {
      const result = await getEventList({
        pageNumber: pageParam,
        pageLimit: pageLimit,
        status: status,
        targetIndustry: targetIndustry,
      });
      return result;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.totalPages > allPages.length ? allPages?.length + 1 : undefined;
      },
      staleTime: Infinity,
    }
  );
}
