import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import {
  InputField,
  SubTitle,
  Title,
  SecondaryButton,
  PrimaryButton,
  LoaderModal,
} from "components";
import { EMAIL_MAX_CHAR_LENGTH } from "utils/constants";
import { addUserSchemaValidation } from "utils/validation/organizationAddUserValidation";
import { showErrorToastMessage, showSuccessToastMessage } from "utils/toasterMessage";
import { useAddUsers } from "features/Organization/api/useAddUsers";

type AddUserProps = {
  onClose: () => void;
};

const initialState = {
  fullName: "",
  email: "",
};

export default function AddUser({ onClose }: AddUserProps) {
  const { t } = useTranslation();
  const { mutateAsync: addUsers, isLoading: isSendInviteLoading } = useAddUsers();
  const [userFormData, setUserFormData] = useState<UserData>(initialState);
  const [validationErrors, setValidationErrors] = useState<Record<string, string> | null>();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const value = event.target.value;
    setUserFormData((prevstate) => ({ ...prevstate, [fieldName]: value }));
  };
  const prepareFormData = () => {
    const data: UserData = {
      fullName: userFormData.fullName.trim(),
      email: userFormData.email.trim(),
    };
    return data;
  };

  const onSendInvite = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const error = addUserSchemaValidation(userFormData);
    if (error) {
      setValidationErrors(error);
      return;
    }
    setValidationErrors(null);
    try {
      const formData = prepareFormData();
      const result = await addUsers(formData);
      onClose();
      return showSuccessToastMessage({
        message: result.message,
      });
    } catch (error: ApiError) {
      return showErrorToastMessage({
        message: error.response.data.message ?? t("global.something_went_wrong"),
      });
    }
  };
  return (
    <div className="fixed h-screen w-screen bg-black/[0.3] z-[99] flex justify-center items-center top-0 left-0 ">
      <div className="animate__animated animate__zoomIn flex flex-col rounded-md items-center bg-white max-w-[470px] mx-2 sm:w-[470px] py-[50px] px-8 max-h-[calc(100vh-3.125rem)] overflow-auto">
        <div>
          <SVGIcon name="individualInvite" />
        </div>
        <Title
          className="mt-10 text-black text-xl font-semibold"
          title={t("organization.invite_users")}
        />
        <SubTitle
          className="mt-[6px] text-primary-text font-normal text-base text-center"
          title={t("organization.add_user_modal_message")}
        />
        <div className="flex flex-col space-y-[22px] mt-[30px] mb-12 w-full">
          <InputField
            hintText={t("organization.enter_the_full_name")}
            labelText={t("admin.full_name")}
            handleInputChange={handleInputChange}
            value={userFormData.fullName}
            fieldName="fullName"
            isRequired
            className="col-span-full"
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
            errorMessage={validationErrors?.fullName}
          />
          <InputField
            hintText={t("organization.enter_the_email_address")}
            labelText={t("global.email_address")}
            handleInputChange={handleInputChange}
            value={userFormData.email}
            fieldName="email"
            isRequired
            className="col-span-full"
            errorMessage={validationErrors?.email}
            maxCharLength={EMAIL_MAX_CHAR_LENGTH}
          />
        </div>

        <div className=" flex w-full justify-center space-x-[10px] rtl:space-x-reverse ">
          <SecondaryButton
            title={"global.close"}
            handleClick={onClose}
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
          />
          <PrimaryButton
            title={"global.send_invite"}
            handleClick={onSendInvite}
            className=" flex-1 sm:flex-[0] sm:min-w-[120px]"
          />
        </div>
      </div>
      {isSendInviteLoading ? <LoaderModal /> : null}
    </div>
  );
}
