import SVGIcon from "assets/Icons";
type InlineLoaderProps = {
  className?: string;
};
export function InlineLoader({ className }: InlineLoaderProps) {
  return (
    <div className={`loader col-span-4 h-[120px] flex items-center justify-center rounded-md ${className}`}
    >
      <div className=" w-10 h-10  ">
        <SVGIcon name="uploading1" />
      </div>
    </div>
  );
}
