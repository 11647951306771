import { useMutation } from "@tanstack/react-query";
import { useUserDataStore } from "store/useUserDataStore";
import apiClient from "utils/apiClient";
import { urls } from "utils/urls";

async function uploadFile(userId: string, file: File) {
  const tokenInfo = useUserDataStore.getState().tokenInfo;
  const token = tokenInfo?.token;
  const formData = new FormData();
  formData.append("file", file);
  const response = await apiClient.post(urls.chatFileUpload(userId), formData, {
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
  });
  return response?.data?.data as FileDetailsItem;
}

export function useUploadChatFile() {
  const userInfo = useUserDataStore.getState().userInfo;
  const userId = userInfo?.userId;
  return useMutation(async ({ file }: { file: File }) => {
    if (userId) return await uploadFile(userId, file);
  });
}
