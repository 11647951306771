import { useTranslation } from "react-i18next";
import SVGIcon from "assets/Icons";
import { useOutsideClick } from "hooks/useOutsideClick";
import { Divider } from "components";

type DropdownButtonProps = {
  title: string;
  list: Array<DropdownButtonItem>;
  handleSelection: (type: string) => void;
  className?: string;
};

export function DropdownButton({ className, title, list, handleSelection }: DropdownButtonProps) {
  const { t } = useTranslation();
  const { isOpen, setIsOpen, dropDownRef } = useOutsideClick();

  return (
    <>
      <div
        className={`flex`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        ref={dropDownRef}
      >
        <button className={`btn rounded-md${className}`}>
          {t(title)}
          <div className="ltr:ml-2 rtl:mr-2">
            <SVGIcon name={`${isOpen ? "upArrow" : "downArrow"}`} />
          </div>
        </button>
      </div>
      <div
        className={`${
          isOpen ? "" : "hidden"
        } z-50 absolute w-full h-auto bg-white top-[110px] bottom-auto mb-[2px] text-primary-medium font-medium rounded-md shadow-400`}
      >
        {list?.map((item, index) => (
          <div key={index}>
            <DropdownButtonItem
              handleSelection={handleSelection}
              type={item.type}
              label={item.label}
            />
            <Divider className=" text-[#E8F1F6] px-[18px]" />
          </div>
        ))}
      </div>
    </>
  );
}

type DropdownButtonItemProps = {
  label: string;
  handleSelection: (type: string) => void;
  type: string;
};

const DropdownButtonItem = ({ handleSelection, label, type }: DropdownButtonItemProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex gap-[10px] cursor-pointer px-[18px] py-3 hover:bg-gradient-blueDark hover:text-white rounded-md"
      onClick={() => handleSelection(type)}
    >
      <span className="font-medium text-[13px] ">{t(label)}</span>
    </div>
  );
};
