import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-daisyui";
import Brand from "assets/images/home-images/footerLogoWhite.svg";
import HctLogo from "assets/images/HCT_LOGO_ENGLISH_WHITE.png";
import SVGIcon from "assets/Icons";
import { BreadCrumb, Title, IconTypeButton, Label } from "components";
import { useOutsideClick } from "hooks/useOutsideClick";
import GlobalSearch from "features/GlobalSearch";
import { Notifications } from "features/Notifications";
import { useUserDataStore } from "store/useUserDataStore";
import { USERS, USER_STATUS } from "utils/constants";
import { useGetUnreadCount } from "features/Notifications/api/useGetUnreadCount";

type HeaderProps = {
  title: string;
  className?: string;
  linkList?: Array<LinkItem>;
  handleCollapseClick?: () => void;
  handleBackButtonClick?: () => void;
  displayGlobalTag?: boolean;
};

type SearchModalProps = {
  handleCloseClick: () => void;
};

export function Header({
  title,
  linkList,
  handleCollapseClick,
  className,
  handleBackButtonClick,
  displayGlobalTag = false,
}: HeaderProps) {
  const { t } = useTranslation();

  const { data } = useGetUnreadCount();
  const { userInfo } = useUserDataStore();
  const { isOpen, setIsOpen, dropDownRef } = useOutsideClick();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleHamburgerClick = () => {
    document.getElementsByTagName("html")?.[0]?.classList.add("mobile-sidebar-open");
    handleCollapseClick && handleCollapseClick();
  };

  return (
    <div
      className={`navbar bg-[#e8f1f6] px-4 lg:px-10   p-0 justify-between min-h-max h-[58px] lg:h-[118px]  py-0 lg:py-[30px] top-0 z-[42] nav-header w-full lg:w-[calc(100vw-250px)] ${className}`}
    >
      <div className=" flex lg:hidden space-x-[30px] rtl:space-x-reverse sessionOne">
        <button
          className=" w-5 h-3.5 block lg:hidden  text-white items-center cursor-pointer p-0 justify-center  min-h-[auto] !outline-none menuIcon  hover:text-secondary-medium"
          onClick={handleHamburgerClick}
        >
          <SVGIcon name="hamburger" />
        </button>
        <div className="!ml-4">
          <img
            alt="Company logo"
            src={userInfo?.isGlobal ? HctLogo : Brand}
            className={userInfo?.isGlobal ? "w-[110px]" : "w-[180px]"}
          />
        </div>
      </div>
      <div className=" flex-col lg:bg-transparent bg-gray-light justify-start items-start absolute top-[58px] pt-4 pb-2 w-full lg:w-auto lg:pb-0 lg:pt-0 rtl:right-4 ltr:left-4  lg:rtl:right-0 lg:ltr:left-0  lg:top-0 lg:relative overflow-hidden smaller-devices-container truncate block">
        <button
          className=" text-primary-medium text-xs font-semibold space-x-1 hidden mb-4  items-center backBtn"
          onClick={() => {
            handleBackButtonClick && handleBackButtonClick();
          }}
        >
          <SVGIcon name="backIcon" />
          <span className=" rtl:-order-1 flex">{t("global.back")}</span>
        </button>
        <div className=" flex items-center truncate">
          <Title
            className=" font-semibold text-black text-[22px] Word-break  ltr:pr-2 rtl:pl-2 truncate"
            title={t(title)}
          />

          {displayGlobalTag ? (
            <div className=" h-[20px] flex items-center px-3 ltr:!ml-1 rtl:!mr-1 rounded-xl text-xs text-white font-normal bg-primary-medium ">
              {t("global.global")}
            </div>
          ) : null}
        </div>

        <div className="flex">
          {linkList && linkList?.length > 0
            ? linkList?.map((item, index) => (
                <BreadCrumb
                  key={item.title}
                  title={item.title}
                  breadCrumbLength={linkList?.length - index}
                  routeIndex={index - linkList?.length + 1}
                />
              ))
            : null}
        </div>
      </div>
      <div className=" relative">
        {userInfo?.profileStatusId === USER_STATUS.Approved ||
        userInfo?.userType === USERS.Admin ? (
          <>
            <Label
              text=""
              tooltipContent={"search.tooltip_message"}
              className="label p-0 !w-auto  text-primary-light text-sm header-info-icon ltr:pr-1.5 rtl:pl-1.5 block max-[1024px]:hidden"
            />
            <div className="block lg:hidden">
              <IconTypeButton
                iconName="search"
                handleClick={() => {
                  document.body.classList.add("searchOpenModal");
                  setIsOpenModal(true);
                }}
                className="btn  h-[38px] w-[38px] bg-primary-extraDark !border-primary-extraDark text-white btn-ghost hover:bg-secondary-medium hover:text-white rounded-md min-h-max  p-0 ltr:ml-[1.125rem] rtl:mr-[1.125rem] shadow-100 border"
              />
              {isOpenModal ? (
                <SearchModal
                  handleCloseClick={() => {
                    document.body.classList.remove("searchOpenModal");
                    setIsOpenModal(false);
                  }}
                />
              ) : null}
            </div>
            <GlobalSearch />
          </>
        ) : null}
        <div ref={dropDownRef}>
          <button
            className="btn bg-primary-extraDark border-primary-extraDark text-white  h-[38px] w-[38px] lg:bg-white lg:text-black btn-ghost hover:bg-secondary-medium hover:text-white rounded-md min-h-max  p-0 ltr:ml-2 rtl:mr-2  shadow-100 border lg:border-white notificationIcon"
            onClick={() => {
              document.body.classList.add("alertOpenModal");
              setIsOpen((prev) => !prev);
            }}
          >
            <div
              className={`indicator relative after:absolute after:w-[9px] after:h-[9px] after:rounded-full ${
                data?.totalCount > 0
                  ? "after:bg-red-dark after:border after:border-white after:right-0 after:top-[4px]"
                  : ""
              }`}
            >
              <SVGIcon name="alert" />
            </div>
          </button>
          {isOpen ? (
            <Notifications
              closeModal={() => {
                document.body.classList.remove("alertOpenModal");
                setIsOpen((prev) => !prev);
              }}
            />
          ) : null}
        </div>
        {/* <LanguageSwitchButton /> */}
        {/* Search Modal */}
      </div>
    </div>
  );
}

const SearchModal = ({ handleCloseClick }: SearchModalProps) => {
  return (
    <Modal open className="search-modal-mobile bg-white">
      <div className="flex items-start p-4">
        <Label
          text=""
          tooltipContent={"search.tooltip_message"}
          className="label p-0  text-primary-light text-sm header-info-icon ltr:pr-1.5 rtl:pl-1.5 block lg:hidden !w-auto mt-[14px]"
        />
        <GlobalSearch className="global-search-mobile-view" />
        <IconTypeButton
          iconName="close"
          handleClick={() => handleCloseClick()}
          className="w-12 h-12 ltr:ml-4 rtl:mr-4"
        />
      </div>
    </Modal>
  );
};
